import React from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import classNames from 'classnames/bind';

import { defaultFormikOptions } from '../../../../../models/formik';
import {
  publicEventsRequestsFiltersInitialValues,
  publicEventsRequestsFiltersValidationSchema,
} from '../../../../../models/validations/schemas/validations';
import { OCCUPATION } from '../../../../../constants/user';

//  ui
import { Button } from '../../../../ui/Button';
import { Filters } from '../../../../ui/Filters';
import { InputText } from '../../../../ui/Form/InputText';
import { Select } from '../../../../ui/Form/Select';
import { InputDate } from '../../../../ui/Form/InputDate';

import styles from './PublicEventsRequestsFilters.module.scss';
const cx = classNames.bind(styles);

type PublicEventsRequestsFiltersTypes = {
  setFilters: React.Dispatch<
    React.SetStateAction<typeof publicEventsRequestsFiltersInitialValues>
  >;
};

export const PublicEventsRequestsFilters = ({
  setFilters,
}: PublicEventsRequestsFiltersTypes) => {
  const handleFiltersSubmit = (
    values: typeof publicEventsRequestsFiltersInitialValues
  ) => {
    setFilters(values);
  };

  const formik = useFormik({
    initialValues: publicEventsRequestsFiltersInitialValues,
    validationSchema: publicEventsRequestsFiltersValidationSchema,
    onSubmit: handleFiltersSubmit,
    ...defaultFormikOptions,
  });

  const handleFiltersReset = () => {
    setFilters(publicEventsRequestsFiltersInitialValues);
    formik.resetForm();
  };

  return (
    <div className={styles.filter}>
      <Filters>
        <FormikContext.Provider value={formik}>
          <Form>
            <div className={styles.form}>
              <div className={cx('form__item', 'form__item_search')}>
                <InputText name="search" placeholder="Поиск" fullWidth={true} />
              </div>
              <div className={cx('form__item')}>
                <InputDate
                  isRange
                  name="dates"
                  fullWidth={true}
                  placeholder="Сроки проведения"
                />
              </div>
              <div className={cx('form__item')}>
                <Select
                  fullWidth={true}
                  name="status"
                  label="Статус проведения"
                  options={OCCUPATION}
                />
              </div>
              <div className={cx('form__item')}>
                <Select
                  fullWidth={true}
                  name="tags"
                  label="Тематические теги"
                  options={OCCUPATION}
                />
              </div>
              <div className={cx('form__item')}>
                <Select
                  fullWidth={true}
                  name="moderation"
                  label="Статус проверки"
                  options={OCCUPATION}
                />
              </div>
            </div>
            <div className={styles.buttons}>
              <div onClick={handleFiltersReset}>
                <Button label="Сбросить" withoutBackground={true} />
              </div>
              <div onClick={() => formik.handleSubmit()}>
                <Button label="Применить" gradient={true} />
              </div>
            </div>
          </Form>
        </FormikContext.Provider>
      </Filters>
    </div>
  );
};
