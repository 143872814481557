import { ReactNode, RefObject, useEffect } from 'react';
import { IconClose } from '../Icons/IconClose';
import styles from './ClickTooltip.module.scss';

type ClickTooltipProps = {
  isOpen: boolean;
  parentRef: RefObject<HTMLDivElement>;
  width?: number;
  handleCloseTooltip: () => void;
  children: ReactNode;
};

export const ClickTooltip = ({
  isOpen,
  handleCloseTooltip,
  parentRef,
  width,
  children,
}: ClickTooltipProps) => {
  useEffect(() => {
    const clickHandler = (e: any) => {
      if (!parentRef.current?.contains(e.target)) {
        handleCloseTooltip();
      }
    };

    document.addEventListener('click', clickHandler);

    return () => {
      document.removeEventListener('click', clickHandler);
    };
  }, []);

  if (!isOpen) return null;

  return (
    <div
      className={styles.tooltip}
      style={{ width: width ? `${width}px` : 440 }}
    >
      <span className={styles.tooltipClose} onClick={handleCloseTooltip}>
        <IconClose isLight />
      </span>
      {children}
    </div>
  );
};
