import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { frontendRoutes } from '../../../../utils/router/routes';
import { useAuthorized } from '../../../../hooks/useAuthorized';

//  ui
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { HMenu } from '../../../ui/HMenu';
import { Button } from '../../../ui/Button';
import { GradingSystemForm } from './GradingSystemForm';

import styles from './GradingSystem.module.scss';

export const GradingSystem = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { isAuthorized } = useAuthorized();

  const menuLinks = [
    {
      label: 'Редактирование мероприятия',
      onClick: () =>
        navigate(
          `${frontendRoutes.settingsEvent.settingsEvent}/${params.eventId}`
        ),
      active: false,
      access: true,
    },
    {
      label: 'Доступы и роли',
      onClick: () =>
        navigate(
          `${frontendRoutes.settingsEvent.accessesAndRole}/${params.eventId}`
        ),
      active: false,
      access: true,
    },
    {
      label: 'Система оценивания',
      onClick: () =>
        navigate(
          `${frontendRoutes.settingsEvent.gradingSystem}/${params.eventId}`
        ),
      active: true,
      access: true,
    },
  ];

  return (
    <div className={styles.gradingSystem}>
      <div className="local-container">
        <Breadcrumbs
          links={[
            {
              title: 'Мероприятие',
              link: `${frontendRoutes.publicEventsCatalog.events}/${params.eventId}`,
            },
            { title: 'Настройки мероприятия' },
          ]}
          homeLink={frontendRoutes.mainPage}
        />
      </div>
      <div className={styles.gradingSystem__header}>Настройки мероприятия</div>
      <div className={styles.gradingSystem__navigation}>
        <HMenu links={menuLinks} />
      </div>
      <div className="container">
        <GradingSystemForm />
      </div>
    </div>
  );
};
