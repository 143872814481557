// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoaderFullPage_fullPage__GbeCF {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  margin-top: calc(20% - 48px);
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Loader/LoaderFullPage/LoaderFullPage.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,4BAAA;AACF","sourcesContent":[".fullPage {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  min-height: 100%;\n  margin-top: calc(100% / 5 - 48px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullPage": `LoaderFullPage_fullPage__GbeCF`
};
export default ___CSS_LOADER_EXPORT___;
