import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { AppState } from '../types';

const selectSelf = (state: AppState) => state.authorization;

export const isAuthorized = createDraftSafeSelector(selectSelf, (store) =>
  Boolean(store.authorization?.access_token)
);

export const getToken = createDraftSafeSelector(
  selectSelf,
  (store) => store.authorization?.access_token
);

export const getRefreshToken = createDraftSafeSelector(
  selectSelf,
  (store) => store.authorization?.refresh_token
);
