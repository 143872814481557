import { IconTableEmpty } from '../../../../../ui/Table/Empty/IconTableEmpty';
import styles from './ResearchDetailsReportNoChosen.module.scss';

type ResearchDetailsReportNoChosenProps = {
  text: string;
};

export const ResearchDetailsReportNoChosen = ({
  text,
}: ResearchDetailsReportNoChosenProps): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <IconTableEmpty />
        <h3>{text}</h3>
      </div>
    </div>
  );
};
