import React from 'react';
import styles from './Security.module.scss';
import { SecurityItems } from '../../../../../constants/mainPage/security';

export const Security = () => {
  return (
    <section className={styles.security}>
      <div className={styles.security__wrapper}>
        <nav className={styles.navigation}>
          <div className={styles.navigation__title}>
            Гарантируем безопасность
          </div>
        </nav>
        <div className={styles.options}>
          {SecurityItems.map((item, index) => {
            return (
              <div key={index} className={styles.options__item}>
                <div
                  className={styles.options__item_title}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                <img src={item.img} alt="image" />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
