import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Team } from '../../../models/pages/teams';
import { TableHeader } from '../../../models/components/table';
import { useGetTeamsQuery } from '../../../store/VbudusheeAPI';
import { frontendRoutes } from '../../../utils/router/routes';
import { teamsFiltersInitialValues } from '../../../models/validations/schemas/validations';
import { dateStrToServerStr } from '../../../utils/dates';

//  ui
import { Breadcrumbs } from '../../ui/Breadcrumbs';
import { TableExpanded } from '../../ui/Table';
import { TeamsFilters } from './TeamsFilters';
import { Pagination } from '../../ui/Pagination';
import { Loader } from '../../ui/Loader';

import styles from './Teams.module.scss';

import defaultTeamLogo from '../../../assets/images/defaultTeamLogo.svg';

export const Teams = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<typeof teamsFiltersInitialValues>(
    teamsFiltersInitialValues
  );
  const API_URL = process.env.REACT_APP_API_URL?.substring(0, 25);
  const paginationOptions = useMemo(() => {
    return {
      page,
      team_name: '',
      team_creator: '',
      team_creator_organization_name: '',
      team_created_start_date: dateStrToServerStr(filters?.dates.split(';')[0]),
      team_created_end_date: dateStrToServerStr(filters?.dates.split(';')[1]),
    };
  }, [filters?.dates, page]);

  const {
    data,
    isError: isGetTeamsError,
    isLoading,
  } = useGetTeamsQuery(paginationOptions);

  const [teams, setTeams] = useState(data?.data ?? []);

  useEffect(() => {
    setTeams(data?.data ?? []);
  }, [data]);

  const headers: TableHeader[] = [
    { label: '', width: '6%' },
    { label: 'Название команды', width: '44%' },
    { label: 'Количество участников', width: '21%' },
    { label: 'Роль', width: '24%' },
  ];

  const getLocalRoleName = (role: string) => {
    switch (role) {
      case 'owner':
        return 'Создатель';
      case 'member':
        return 'Участник';
      case 'moderator':
        return 'Модератор';
      default:
        return role;
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader.FullPage>
          <Loader />
        </Loader.FullPage>
      ) : (
        <div className={styles.teams}>
          <div className="local-container">
            <Breadcrumbs links={[{ title: 'Каталог команд' }]} homeLink="/" />
          </div>
          <div className={styles.teams__header}>Каталог команд</div>
          <TeamsFilters setFilters={setFilters} />
          <div className="container">
            <TableExpanded headers={headers}>
              {isGetTeamsError || teams?.length < 1 ? (
                <TableExpanded.NoTable
                  isError={isGetTeamsError}
                  dataLength={teams?.length}
                />
              ) : (
                <>
                  {teams &&
                    teams.map((team: Team) => (
                      <TableExpanded.Row key={team.team_id} headers={headers}>
                        <TableExpanded.Col>
                          <div className={styles.badge}>
                            <img
                              src={
                                team?.team_icon_path !== '' &&
                                !(team?.team_icon_path === null)
                                  ? `${API_URL}${team.team_icon_path}`
                                  : defaultTeamLogo
                              }
                              className={styles.badge__logo}
                              alt="logo"
                            />
                          </div>
                        </TableExpanded.Col>
                        <TableExpanded.Col>
                          <span
                            onClick={() =>
                              navigate(
                                `${frontendRoutes.teams.team.events}/${team.team_id}`
                              )
                            }
                          >
                            {team.team_name}
                          </span>
                        </TableExpanded.Col>
                        <TableExpanded.Col>
                          <span>{team.team_members}</span>
                        </TableExpanded.Col>
                        <TableExpanded.Col>
                          <span>{getLocalRoleName(team.team_user_role)}</span>
                        </TableExpanded.Col>

                        <TableExpanded.Expanded
                          innerText={team.team_description}
                          items={[
                            {
                              itemTitle: 'Создатель команды',
                              itemData: `${team.team_creator.last_name} ${
                                team.team_creator.first_name
                              } ${team.team_creator.middle_name ?? ''}`,
                            },
                            {
                              itemTitle: 'Организация создателя',
                              itemData: team?.team_creator?.organization_name,
                            },
                            {
                              itemTitle: 'Дата создания',
                              itemData: new Date(
                                team.team_creation_date
                              ).toLocaleDateString(),
                            },
                          ]}
                        />
                      </TableExpanded.Row>
                    ))}
                </>
              )}
            </TableExpanded>
          </div>
          <div className="pagination">
            {data?.meta?.per_page && teams?.length > data?.meta?.per_page && (
              <Pagination
                totalPages={data?.meta.last_page}
                currentPage={data?.meta.current_page}
                setPage={setPage}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
