// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("SBSansText.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("SBSansTextBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("SFProDisplay-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("SFProDisplay-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("SFProDisplay-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("TTCommons-Medium.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'SBSansText';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff');
}

@font-face {
  font-family: 'SBSansText';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
}

@font-face {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff');
}

@font-face {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff');
}

@font-face {
  font-family: 'SFProDisplay';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff');
}

@font-face {
  font-family: 'TTComons';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff');
}`, "",{"version":3,"sources":["webpack://./src/assets/fonts/style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,2DAAyC;AAC3C;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,2DAA6C;AAC/C;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,2DAAmD;AACrD;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,2DAAgD;AAClD;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,2DAAkD;AACpD;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,2DAA+C;AACjD","sourcesContent":["@font-face {\r\n  font-family: 'SBSansText';\r\n  font-style: normal;\r\n  font-weight: normal;\r\n  font-display: swap;\r\n  src: url('SBSansText.ttf') format('woff');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'SBSansText';\r\n  font-style: normal;\r\n  font-weight: 600;\r\n  font-display: swap;\r\n  src: url('SBSansTextBold.ttf') format('woff');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'SFProDisplay';\r\n  font-style: normal;\r\n  font-weight: normal;\r\n  font-display: swap;\r\n  src: url('SFProDisplay-Regular.ttf') format('woff');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'SFProDisplay';\r\n  font-style: normal;\r\n  font-weight: 700;\r\n  font-display: swap;\r\n  src: url('SFProDisplay-Bold.ttf') format('woff');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'SFProDisplay';\r\n  font-style: normal;\r\n  font-weight: 500;\r\n  font-display: swap;\r\n  src: url('SFProDisplay-Medium.ttf') format('woff');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'TTComons';\r\n  font-style: normal;\r\n  font-weight: 500;\r\n  font-display: swap;\r\n  src: url('TTCommons-Medium.ttf') format('woff');\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
