import React, { ReactElement, useState } from 'react';
import { TableHeader } from '../../../../models/components/table';
import { IconArrow } from '../../Icons/IconArrow';
import classNames from 'classnames/bind';
import styles from './Row.module.scss';
const cx = classNames.bind(styles);

type RowTypes = {
  children: (ReactElement | null)[];
  headers: TableHeader[];
  notExpandable?: boolean;
  notClickable?: boolean;
};

export const Row = ({
  children,
  headers,
  notExpandable,
  notClickable,
}: RowTypes) => {
  const [expanded, setExpanded] = useState(false);

  const cols = [...children];
  const innerContent = cols.pop();

  const handleExpand = () => {
    if (!notExpandable) {
      setExpanded((prev) => !prev);
    }
  };

  return (
    <div
      className={cx('row__wrapper', {
        row__wrapper_hidden: !expanded,
      })}
    >
      <div
        className={cx('row', {
          row__expanded: expanded,
          row__clickable: !notClickable,
        })}
        onClick={handleExpand}
      >
        {!notExpandable && (
          <div className={styles.row__arrow}>
            <IconArrow />
          </div>
        )}

        {cols.map((c, key) => (
          <div
            className={styles.col}
            key={key}
            style={{ width: headers[key]?.width ?? 'auto' }}
          >
            {c}
          </div>
        ))}
      </div>

      <div className={styles.row__content}>{innerContent}</div>
    </div>
  );
};
