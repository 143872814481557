import { useState, useEffect, useMemo, useRef } from 'react';
import { useField } from 'formik';
import classNames from 'classnames/bind';

import { parseDateIntoInputStr } from '../../../../utils/components/calendar';

//  ui
import { DatePicker } from '../../DatePicker';
import { IconCalendar } from '../../Icons/IconCalendar';

import styles from './InputDate.module.scss';

const cx = classNames.bind(styles);

type InputDateProps = {
  name: string;
  placeholder?: string;
  initialDate?: string;
  initialFinishDate?: string;
  fullWidth?: boolean;
  align?: 'left' | 'right';
  isRange?: boolean;
  disabled?: boolean;
  allDate?: string | undefined;
  minDate?: Date;
  required?: boolean;
};

export const InputDate = ({
  name,
  placeholder,
  initialDate,
  initialFinishDate,
  fullWidth,
  align = 'left',
  isRange,
  disabled,
  minDate,
  required,
}: InputDateProps): JSX.Element => {
  /*
    "startDate" - starting or single date value for the date input:
    "finishDate"- if it is a range option, then it is end date value for the date input
  */

  const [startDate, setStartDate] = useState<Date | undefined>(
    initialDate ? new Date(initialDate) : undefined
  );
  useEffect(() => {
    setStartDate(initialDate ? new Date(initialDate) : undefined);
  }, [initialDate]);

  const [finishDate, setFinishDate] = useState<Date | undefined>(
    initialFinishDate ? new Date(initialFinishDate) : undefined
  );
  useEffect(() => {
    setFinishDate(initialFinishDate ? new Date(initialFinishDate) : undefined);
  }, [initialFinishDate]);

  const datePickerCalendar = useRef<HTMLDivElement>(null);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [field, { error, touched }, { setValue, setTouched }] =
    useField<string>(name);

  const isInvalid = useMemo(() => {
    return Boolean(error && touched);
  }, [error, touched]);

  const handleChange = (): void => {
    setValue(`${startDate};${finishDate}`);
  };

  //  Handle click outside of the calendar
  useEffect(() => {
    const onClick = (e: MouseEvent) => {
      datePickerCalendar?.current?.contains(e.target as Node) ||
        setIsCalendarVisible(false);
    };

    if (!isCalendarVisible && startDate && finishDate) {
      setValue(`${startDate};${finishDate}`);
    }

    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, [isCalendarVisible]);

  useEffect(() => {
    if (!field.value) {
      setStartDate(undefined);
      setFinishDate(undefined);
    }
  }, [field.value]);

  useEffect(() => {
    if (finishDate && startDate) {
      setTouched(true);
    }
  }, [touched]);

  return (
    <div className={styles.wrapper} ref={datePickerCalendar}>
      <div
        className={cx('field', {
          field_disabled: disabled,
          field_fullwidth: fullWidth,
        })}
      >
        <div
          className={cx('input', {
            input_disabled: disabled,
            placeholder: !startDate,
            input_invalid: isInvalid,
          })}
          onClick={() => !disabled && setIsCalendarVisible(!isCalendarVisible)}
        >
          {startDate ? (
            parseDateIntoInputStr(startDate)
          ) : required ? (
            <p>
              {placeholder?.slice(0, placeholder.length - 1)}
              <span style={{ color: 'red' }}>
                {placeholder?.slice(placeholder.length - 1, placeholder.length)}{' '}
              </span>
            </p>
          ) : (
            placeholder
          )}
          {finishDate && ` - ${parseDateIntoInputStr(finishDate)}`}
        </div>
        <div className={cx('icon')}>
          <IconCalendar />
        </div>
      </div>
      {isInvalid && <div className={cx('field__error')}>{error}</div>}
      {isCalendarVisible && (
        <div
          className={cx('calendar', {
            calendar_left: align === 'left',
            calendar_right: align === 'right',
            calendar_single: !isRange,
          })}
          onBlur={() => {
            handleChange();
          }}
        >
          <DatePicker
            isRange={isRange}
            startDate={startDate}
            setStartDate={setStartDate}
            finishDate={finishDate}
            setFinishDate={setFinishDate}
            setOpen={setIsCalendarVisible}
            handleChange={handleChange}
            minDate={minDate}
          />
        </div>
      )}
    </div>
  );
};
