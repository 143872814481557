import React from 'react';
import { Layout } from '../../../components/Layout';
import { RegistrationCompany } from '../../../components/pages/auth/RegistrationCompany';

export const CompanyRegistrationPage = () => {
  return (
    <Layout>
      <RegistrationCompany />
    </Layout>
  );
};
