import { useRef, useEffect } from 'react';
import * as d3 from 'd3';

import { PIE_COLORS } from '../../../../mocks/research';

//  ui
import styles from './DonutChart.module.scss';
import { DonutChartData } from '../../../../models/components/charts';
import { getAmountWord } from '../../../../utils/components/materialsCard';

type DonutChartProps = {
  isLoading: boolean;
  data?: DonutChartData[];
  result?: number;
};

export const DonutChart = ({
  data = [],
  result = 0,
  isLoading,
}: DonutChartProps): JSX.Element => {
  const svgRef = useRef<SVGSVGElement>(null);

  //  Create pie chart
  useEffect(() => {
    const drawPieChart = (pieData: DonutChartData[]) => {
      // dimensions
      const width = 160;
      const height = 160;
      const radius = 100;

      const svg = d3
        .select(svgRef?.current)
        .attr('width', width)
        .attr('height', height);

      svg.selectAll('g').remove();

      const g = svg
        .append('g')
        .attr('transform', `translate(${width / 2}, ${height / 2})`);

      // scales
      const color = d3.scaleOrdinal(PIE_COLORS);

      // pie
      const pie = d3.pie<DonutChartData>().value((d: any) => {
        return d.value;
      });

      // arcs
      const path = d3
        .arc()
        .outerRadius(radius - 20)
        .innerRadius(53);
      const arc = g
        .selectAll('.arc')
        .data(pie(pieData))
        .enter()
        .append('g')
        .attr('class', 'arc');

      const gradient = svg
        .append('defs')
        .append('linearGradient')
        .attr('id', 'myGradient')
        .attr('gradientTransform', 'rotate(45)');

      gradient
        .append('stop')
        .attr('offset', '0%')
        .attr('stop-color', '#EEF181');
      gradient
        .append('stop')
        .attr('offset', '51.47%')
        .attr('stop-color', '#92DD90');
      gradient
        .append('stop')
        .attr('offset', '100%')
        .attr('stop-color', '#4DE9CB');

      arc
        .append('path')
        .attr('d', path as unknown as string)

        .style('opacity', 0) // Set initial opacity to 0 for fade-in animation
        .transition() // Apply the transition
        .duration(800) // Set the duration of the transition
        .style('opacity', 1) // Animate the opacity to 1
        .attrTween('d', function (d: any) {
          const interpolate = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
          return function (t: number) {
            return path(interpolate(t) as any) || '';
          };
        })
        .attr('fill', (item: any, i: number) => item.data.color)
        .style('fill', !data.length ? 'url(#myGradient)' : '');

      // add bold main text at the center of a donut chart
      svg
        .append('g')
        .attr('transform', `translate(${width / 2}, ${height / 2})`)
        .append('text')
        .text(`${Number(result).toLocaleString('ru-RU')}`)
        .attr('class', `${styles.test}`);

      // add additional small text at the center of a donut chart
      svg
        .append('g')
        .attr('transform', `translate(${width / 2}, ${height / 2 + 20})`)
        .append('text')
        .text(getAmountWord('ответ', result))
        .attr('class', `${styles.small}`);
    };

    const pieData = data.length ? data : [{ key: 1, value: 1, color: '' }];

    if (!isLoading) {
      drawPieChart(pieData);
    }
  }, [data, result]);

  return (
    <div>
      <svg ref={svgRef}></svg>
    </div>
  );
};
