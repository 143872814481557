// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PublicEventParticipate_SuccessUpload__c4J0V {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 40px;
}

.PublicEventParticipate_uploadSection__90IXN {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
}

.PublicEventParticipate_criterias__BPEMk {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  border-bottom: 1px solid #d0d7dd;
  padding-bottom: 32px;
}

.PublicEventParticipate_criteriaText__ktIgR {
  font-size: 12px;
  margin-top: 12px;
}

.PublicEventParticipate_documents__G-Dvu {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/publicEventsCatalog/PublicEventParticipate/PublicEventParticipate.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;EACA,gCAAA;EACA,oBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,SAAA;AACF","sourcesContent":[".SuccessUpload {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin-bottom: 40px;\n}\n\n.uploadSection {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  row-gap: 5px;\n}\n\n.criterias {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 24px;\n  border-bottom: 1px solid #d0d7dd;\n  padding-bottom: 32px;\n}\n\n.criteriaText {\n  font-size: 12px;\n  margin-top: 12px;\n}\n\n.documents {\n  margin-top: 40px;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SuccessUpload": `PublicEventParticipate_SuccessUpload__c4J0V`,
	"uploadSection": `PublicEventParticipate_uploadSection__90IXN`,
	"criterias": `PublicEventParticipate_criterias__BPEMk`,
	"criteriaText": `PublicEventParticipate_criteriaText__ktIgR`,
	"documents": `PublicEventParticipate_documents__G-Dvu`
};
export default ___CSS_LOADER_EXPORT___;
