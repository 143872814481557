// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AutomaticAnalysisResults_wrapper__4Xo0a {
  padding: 0 204px 100px;
}

.AutomaticAnalysisResults_top__Hg125 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 42px 0 40px;
}
.AutomaticAnalysisResults_topTitle__-cSwD {
  font-size: 24px;
  font-weight: 600;
}
.AutomaticAnalysisResults_topIcon__ZWQhc {
  height: 32px;
  cursor: pointer;
}

.AutomaticAnalysisResults_menuWrapper__XjnHc {
  display: flex;
  column-gap: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/automaticAnalysisResults/AutomaticAnalysisResults.module.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AAEA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AACI;EACI,eAAA;EACA,gBAAA;AACR;AAEI;EACI,YAAA;EACA,eAAA;AAAR;;AAKA;EACI,aAAA;EACA,gBAAA;AAFJ","sourcesContent":[".wrapper {\n    padding: 0 204px 100px;\n}\n\n.top {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin: 42px 0 40px;\n\n    &Title {\n        font-size: 24px;\n        font-weight: 600;\n    }\n\n    &Icon {\n        height: 32px;\n        cursor: pointer;\n    }\n}\n\n\n.menuWrapper {\n    display: flex;\n    column-gap: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `AutomaticAnalysisResults_wrapper__4Xo0a`,
	"top": `AutomaticAnalysisResults_top__Hg125`,
	"topTitle": `AutomaticAnalysisResults_topTitle__-cSwD`,
	"topIcon": `AutomaticAnalysisResults_topIcon__ZWQhc`,
	"menuWrapper": `AutomaticAnalysisResults_menuWrapper__XjnHc`
};
export default ___CSS_LOADER_EXPORT___;
