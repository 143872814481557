import { IconTableEmpty } from '../../../../ui/Table/Empty/IconTableEmpty';
import styles from './ComparisonContentBarChart.module.scss';
import {
  AddResearchVersus,
  CommonResponse,
  ComparisonResearchRequest,
} from '../../../../../models/research';
import { Select } from '../../../../ui/Form/Select';
import { FormikContext, useFormik, Form } from 'formik';
import { researchVersusFiltersInitialValues } from '../../../../../models/validations/schemas/validations';
import { defaultFormikOptions } from '../../../../../models/formik';
import { Box } from '../../../../ui/Box';
import { CheckBox } from '../../../../ui/Form/CheckBox';
import { Typography } from '../../../../ui/Typography';
import { useEffect, useState } from 'react';
import { BarChartComparison } from '../../../../ui/charts/BarChartComparison';
import { Loader } from '../../../../ui/Loader';

type ComparisonContentBarChartProps = {
  researches: number[];
  compare: CommonResponse;
  researchCompare: (body: ComparisonResearchRequest) => void;
  researchVersus?: any;
  loading: boolean;
};
export const ComparisonContentBarChart = ({
  researches,
  compare,
  researchCompare,
  loading = false,
  researchVersus,
}: ComparisonContentBarChartProps) => {
  const [sizeWidth, setSizeWidth] = useState<any>(0);
  const handleFiltersSubmit = async () => {
    const obj = {
      researches: researches,
      compare_with_public: formikFilter.values.compare_with_public,
      method_id: formikFilter.values.method_id,
    };
    if (formikFilter.values.question_id) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      obj['question_id'] = formikFilter.values.question_id;
    }

    researchCompare(obj);
  };

  const initialValues = {
    researches: [],
    compare_with_public: false,
    method_id: '',
    question_id: '',
  };

  const formikFilter = useFormik({
    initialValues,
    onSubmit: handleFiltersSubmit,
    ...defaultFormikOptions,
  });

  useEffect(() => {
    if (formikFilter.values.method_id) {
      handleFiltersSubmit();
    }
  }, [formikFilter.values]);

  return (
    <div className={styles.wrapper}>
      <FormikContext.Provider value={formikFilter}>
        <Form>
          <Select
            name="method_id"
            label="Название методики"
            options={compare.methods.map((el) => {
              return { key: el.id, value: el.method_name };
            })}
            fullWidth
          />
          <div className="grid-row-2 vm-20">
            <Select
              name="question_id"
              label="Выберите вопрос"
              options={[
                { key: 0, value: 'Все вопросы' },
                ...compare.questions.map((el) => {
                  return { key: el.id, value: el.question_name };
                }),
              ]}
              disabled={!formikFilter.values.method_id}
              fullWidth
            />
            <Box flex>
              <CheckBox
                checked={formikFilter.values.compare_with_public}
                onChange={() =>
                  formikFilter.setFieldValue(
                    'compare_with_public',
                    !formikFilter.values.compare_with_public
                  )
                }
                themeGradient
                disabled={!formikFilter.values.method_id}
                big
              />
              <Box flex ml={8}>
                <Typography color="gray6" fontWeight={400} size="sm">
                  Добавить в сравнение средние по всем исследованиям
                </Typography>
              </Box>
            </Box>
          </div>
        </Form>
      </FormikContext.Provider>
      {loading ? (
        <Loader.FullPage>
          <Loader />
        </Loader.FullPage>
      ) : researchVersus && formikFilter.values.method_id ? (
        <BarChartComparison
          statComparisonResearch={researchVersus}
          compareWithPublic={formikFilter.values.compare_with_public}
          question={formikFilter.values.question_id}
        />
      ) : (
        <>
          <h2 className={styles.title}>Данных пока нет</h2>
          <p className={styles.subTitle}>
            {formikFilter.values.method_id
              ? 'Данные отсутствуют'
              : 'Выберете методику, чтобы сравнить их'}
          </p>
        </>
      )}
    </div>
  );
};
