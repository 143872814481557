import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { Tag } from '../../models/tags/tag';

type GetTagsForEvents = {
  tags: Tag[] | null;
};

const initialState: GetTagsForEvents = {
  tags: null,
};

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    getTags: (state, action: PayloadAction<Tag[]>) => {
      state.tags = action.payload;
    },
  },
});

export const { getTags } = tagsSlice.actions;
export const { reducer: tagsReducer } = tagsSlice;
