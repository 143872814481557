import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, FormikContext } from 'formik';
import { useDispatch } from 'react-redux';

import { frontendRoutes } from '../../../../utils/router/routes';
import { UploadableFile } from '../../../../models/files';
import {
  useGetMyDocumentsQuery,
  useUploadDocumentWithProgressMutation,
} from '../../../../store/VbudusheeAPI';

//  ui
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
// import { Button } from '../../../ui/Button';
import { Typography } from '../../../ui/Typography';
import { Box } from '../../../ui/Box';
// import { UploadModal } from '../../../ui/UploadFile/UploadModal';
import Docxtemplater from 'docxtemplater';
import PizZip, { LoadData } from 'pizzip';
import { UploadFile } from '../../../ui/UploadFile';
import { useTypedSelector } from '../../../../store';
import { Button } from '../../../ui/Button';
import styles from './PublicEventParticipate.module.scss';
import { resetUploadProgress } from './../../../../store/documentSlice';
import { GetDictionaryResponse } from '../../../../models/research';
import { PublicEventEstimateCategiries } from '../../../../models/pages/events';
import { PublicEventParticipateDoc } from './PublicEventParticipateDoc';

type PublicEventParticipateProps = {
  eventId?: string;
  eventEstimateOn?: boolean;
  dictionary?: GetDictionaryResponse;
  documentType: string;
  setState: (value: 'info' | 'upload') => void;
  eventCriterias?: PublicEventEstimateCategiries[];
  documentRequirements?: string;
};

export const PublicEventParticipate = ({
  eventId,
  eventEstimateOn,
  // dictionary,
  documentRequirements,
  documentType,
  eventCriterias,
  setState,
}: PublicEventParticipateProps): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [files, setFiles] = useState<UploadableFile[]>([]);
  const [uploadedFileId, setUploadedFileId] = useState<string | null>(null);
  const [
    uploadFile,
    { isLoading: isUploadLoading, isSuccess: isUploadSuccess },
  ] = useUploadDocumentWithProgressMutation();

  const { data } = useGetMyDocumentsQuery(
    {
      event_id: Number(eventId),
    },
    { skip: !eventId }
  );

  const loadBtn = useRef<HTMLDivElement>(null);

  const uploadProgress = useTypedSelector(
    (state) => state.uploadDoc.uploadProgress
  );

  const emptyStringArray = '["null"]';

  useEffect(() => {
    if (files.length > 0) {
      const reader = new FileReader();
      reader.onload = function () {
        const doc = new Docxtemplater(
          new PizZip(reader.result as unknown as LoadData)
        );
        const text = doc.getFullText();
        localStorage.setItem('doc', JSON.stringify(text));
      };
      reader.readAsBinaryString(files[0].file);
    }
  }, [files]);

  const handleSubmit = async () => {
    const formData = new FormData();

    formData.append('document', files[0].file);
    formData.append('event', eventId || '');
    formData.append('tags', emptyStringArray);

    const result = await uploadFile(formData);

    if ('data' in result) {
      setUploadedFileId(result.data.data.document_id.toString());
    }
  };

  const toMarkupButtonClick = () => {
    dispatch(resetUploadProgress());
    navigate(`${frontendRoutes.document.edit}/${uploadedFileId}`);
  };

  const handleBack = (): void => {
    setState('info');
  };

  const formik = useFormik({
    initialValues: {},
    onSubmit: handleSubmit,
  });

  return (
    <div className="details">
      <div className="local-container">
        <Breadcrumbs
          links={[
            {
              title: 'Мероприятиe',
              cb: handleBack,
            },
            { title: 'Участие в мероприятии' },
          ]}
          homeLink="/"
        />
      </div>
      <FormikContext.Provider value={formik}>
        <div className="details__header">
          <Typography
            color="main"
            size="xxxl"
            font="SFPRoDisplay"
            fontWeight={600}
          >
            Участие в мероприятии
          </Typography>
          <div ref={loadBtn} className={styles.uploadSection}>
            {isUploadSuccess ? (
              <div>
                <Button
                  gradient
                  clicked={toMarkupButtonClick}
                  label="Готово, перейти к разметке"
                />
              </div>
            ) : (
              <UploadFile
                uploadProgress={uploadProgress}
                isLoading={isUploadLoading}
                uploadableFiles={files}
                setUploadableFiles={setFiles}
                isSingleFile
                align="left"
                label="Загрузить документ"
              />
            )}
          </div>
        </div>
        <div className="details__body">
          <Typography color="main" fontWeight={600} size="s" upperCase>
            Тип документа и требования
          </Typography>
          <Box mb={33} />
          <div className="row">
            <div className="detail detail_full">
              <div className="detail__content">
                <div className="detail__content-title">
                  <Typography color="gray" size="s">
                    Требования к загрузке документа
                  </Typography>
                </div>
                <Typography color="main" size="sm">
                  {documentRequirements
                    ? documentRequirements
                    : 'Требования не установлены'}
                </Typography>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="detail detail_full">
              <div className="detail__content">
                <div className="detail__content-title">
                  <Typography color="gray" size="s">
                    Тип загружаемого документа
                  </Typography>
                </div>
                <Typography color="main" size="sm">
                  {documentType}
                </Typography>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="detail detail_full">
              <div className="detail__content">
                <div className="detail__content-title">
                  <Typography color="gray" size="s">
                    Критерии оценивания документа
                  </Typography>
                </div>
                <div className={styles.criterias}>
                  {eventEstimateOn && eventCriterias?.length
                    ? eventCriterias.map((criteria, idx) => (
                        <div key={criteria.id}>
                          <Typography
                            color="main"
                            fontWeight={600}
                            size="s"
                            upperCase
                          >
                            Критерий {idx + 1}
                          </Typography>
                          <p className={styles.criteriaText}>
                            {criteria.category_name}
                          </p>
                        </div>
                      ))
                    : '-'}
                </div>
                <div className={styles.documents}>
                  {data?.data.map((doc) => (
                    <PublicEventParticipateDoc
                      key={doc.document_id}
                      docId={doc.document_id}
                      title={doc.document_name}
                      status={doc.document_status}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormikContext.Provider>
    </div>
  );
};
