// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RestoreForm_form__JPxmz {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.RestoreForm_form__submit__N97Kd {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/auth/RestorePasswordAccept/RestoreForm/RestoreForm.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EACE,gBAAA;EACA,aAAA;EACA,uBAAA;AACJ","sourcesContent":[".form {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n\n  &__submit {\n    margin-top: 24px;\n    display: flex;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `RestoreForm_form__JPxmz`,
	"form__submit": `RestoreForm_form__submit__N97Kd`
};
export default ___CSS_LOADER_EXPORT___;
