import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useField } from 'formik';
import classNames from 'classnames/bind';

import { Company } from '../../../../models/daData';

//  ui
import styles from './InputCompanyName.module.scss';
import debounce from 'lodash.debounce';
import { Box } from '../../Box';
const cx = classNames.bind(styles);

type InputTextTypes = {
  name: string;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  onChange?: () => void;
  setCompany: React.Dispatch<React.SetStateAction<Company | undefined>>;
  localityKladr?: string;
  organization_category?: string;
  fullWidth?: boolean;
};

export const InputCompanyName = ({
  name,
  placeholder,
  type,
  disabled,
  setCompany,
  localityKladr,
  organization_category,
  fullWidth,
}: InputTextTypes) => {
  const DADATA_TOKEN = process.env.REACT_APP_DADATA_TOKEN;
  const [expanded, setExpanded] = useState(false);
  const [field, { error, touched, value }, { setValue, setError, setTouched }] =
    useField<string>(name);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [localCompany, setLocalCompany] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>(value || '');

  const isInvalid = useMemo(() => {
    return Boolean(error) && touched;
  }, [error, touched]);

  const rootEl = useRef<any>(null);

  const handleClick = (company: Company) => {
    setCompany(company);
    setInputValue(company.value);
    setValue(company.value, true);
    setLocalCompany(company.value);
  };

  const handleBlur = () => {
    setTouched(true);
    if (!localCompany || localCompany !== inputValue) {
      setCompany(undefined);
      setInputValue('');
      setValue('');
      setLocalCompany('');
      // setCompanies([]);
    }
  };

  const debouncedRequest = (val: string) => {
    const url =
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party';
    const token = DADATA_TOKEN;
    const query = val;
    const okved = getOKVED(organization_category);

    const locations = [{ kladr_id: localityKladr }];

    const body = JSON.stringify({
      query,
      okved,
      locations: Number.isNaN(Number(localityKladr)) ? locations : null,
    });

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + token,
      },
      body,
    };

    if (val) {
      fetch(url, options)
        .then(async (res) => {
          return res.json().then((resJson: { suggestions: Company[] }) => ({
            ok: res.ok,
            status: res.status,
            body: resJson,
          }));
        })
        .then((result) => {
          setCompanies(result.body.suggestions);
        })
        .catch(() => setError('Ошибка запроса к сервису DaData'));
    } else {
      setCompanies([]);
    }
    setTouched(true);
  };

  const debouncedChangeHandler = useCallback(
    debounce(debouncedRequest, 500),
    []
  );

  const handleValueChange = (val: string) => {
    setValue(val);
  };

  const debouncedValueChange = useCallback(
    debounce(handleValueChange, 500),
    []
  );

  const getOKVED = (organization_category: string | undefined) => {
    switch (organization_category) {
      case '85.1':
        return ['85', '85.1', '85.11', '85.12', '85.13', '85.13', '85.14'];
      case '85.21':
        return ['85.21'];
      case '85.22':
        return ['85.22', '85.22.1', '85.22.2', '85.22.3'];
      case '85.3':
        return ['85.3', '85.30'];
      case '85.4':
        return [
          '85.4',
          '85.41',
          '85.41.1',
          '85.41.2',
          '85.41.9',
          '85.42',
          '85.42.1',
          '85.42.2',
          '85.42.9',
        ];
      default:
        return [];
    }
  };

  // useEffect(() => {
  //   setInputValue(value);
  // }, [value]);

  useEffect(() => {
    if (expanded) {
      const clickOutsideElement = (e: any) => {
        if (!rootEl.current) return;
        if (!rootEl.current.contains(e.target)) {
          setExpanded(false);
        }
      };

      document.addEventListener('click', clickOutsideElement);

      return () => {
        document.removeEventListener('click', clickOutsideElement);
      };
    }
  }, [expanded]);

  return (
    <div
      className={cx('input', {
        input_invalid: isInvalid,
        input_disabled: disabled,
        input_fullwidth: fullWidth,
      })}
      onFocus={() => setExpanded(true)}
    >
      <input
        {...field}
        value={inputValue}
        onChange={(e) => {
          setCompanies([]);
          handleValueChange(e.target.value);
          setInputValue(e.target.value);
          debouncedChangeHandler(e.target.value);
        }}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        onBlur={handleBlur}
        ref={rootEl}
      />
      {isInvalid && <div className={cx('input__error')}>{error}</div>}

      <div
        className={cx('select', {
          select_hidden: companies.length === 0 || !expanded,
        })}
      >
        {companies.map((c) => (
          <div
            className={styles.select__item}
            key={c.data.kpp + '_' + c.data.inn}
            onClick={() => handleClick(c)}
          >
            <Box>
              <div>
                <b>Название: </b>
                {c.value}
              </div>
              <div>
                <b>ИНН: </b>
                {c.data.inn}
              </div>
              <div>
                <b>Адрес: </b>
                {c.data.address.value}
              </div>
            </Box>
          </div>
        ))}
      </div>
    </div>
  );
};
