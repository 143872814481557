import { useNavigate } from 'react-router-dom';
import { useGetDictionaryQuery } from '../../../../../store/VbudusheeAPI';
import { IconArrow } from '../../../../ui/Icons/IconArrow';
import styles from './PublicEventParticipateDoc.module.scss';

type PublicEventParticipateDocProps = {
  docId: number;
  title: string;
  status: string;
};

export const PublicEventParticipateDoc = ({
  docId,
  title,
  status,
}: PublicEventParticipateDocProps): JSX.Element => {
  const { data } = useGetDictionaryQuery({});

  const navigate = useNavigate();

  return (
    <div
      className={styles.document}
      onClick={() => navigate(`/document/${docId}`)}
    >
      <div className={styles['document-title__wrapper']}>
        <h2 className={styles['document-title']} title={title}>
          {title}
        </h2>
        <div className={styles['document-title__arrow']}>
          <IconArrow />
        </div>
      </div>
      <p className={styles['document-status__text']}>
        Текущий статус документа
      </p>
      <p
        className={`${styles['document-status']} ${
          styles[`document-status__${status}`]
        }`}
      >
        {data?.documents.document_status[status] || 'Нет Данных'}
      </p>
    </div>
  );
};
