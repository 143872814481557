export enum Roles {
  OWNER = 'owner',
  MEMBER = 'member',
  EXPERT = 'expert',
}

export const roles = {
  owner: 'Создатель',
  member: 'Участник',
  expert: 'Эксперт',
};
