import React from 'react';
import styles from './CopyLink.module.scss';

type CopyLinkProps = {
  text?: string;
};
export const CopyLink = (props: CopyLinkProps) => {
  const { text = 'Скопировано в буфер обмена' } = props;
  return (
    <div className={styles.copyLink}>
      <div>{text}</div>
    </div>
  );
};
