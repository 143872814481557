import { useState, useEffect } from 'react';

import styles from './Toggle.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

export type ToggleTypes = {
  isActive?: boolean;
  clicked: () => void;
  name?: string;
  disable?: boolean;
};

export const Toggle = ({
  clicked,
  isActive,
  disable,
}: ToggleTypes): JSX.Element => {
  const [checked, setChecked] = useState(isActive || false);

  const handleClick = () => {
    if (!disable) {
      if (typeof isActive === 'boolean') {
        clicked();
      } else {
        clicked();
        setChecked((prevState) => !prevState);
      }
    }
  };

  useEffect(() => {
    if (typeof isActive === 'boolean') {
      setChecked(isActive);
    }
  }, [isActive]);

  return (
    <div
      className={cx(
        'toggle',
        { toggle_on: checked },
        { toggle_disable: disable }
      )}
      onClick={() => handleClick()}
    >
      <div
        className={
          checked ? `${styles.circle} ${styles.circle_on}` : `${styles.circle}`
        }
        onClick={() => handleClick}
      />
    </div>
  );
};
