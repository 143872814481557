import React, { useEffect, useMemo } from 'react';
import styles from './QuestionsForMethods.module.scss';

//ui
import { QuestionsForMethodsForm } from './QuestionsForMethodsForm';
import { Box } from '../../ui/Box';
import { MethodDictItem } from '../../../models/research';
import { Button } from '../../ui/Button';
import { useFormikContext } from 'formik';

export type QuestionnaireProps = {
  questionnaireDescription?: string;
  methods?: MethodDictItem[];
  requirement?: number[];
  setStep: (value: string) => void;
  paginationMethodStep: number;
  setPaginationMethodStep: (value: number) => void;
  errorConfirm?: boolean;
};

export const QuestionsForMethods = (props: QuestionnaireProps) => {
  const {
    questionnaireDescription,
    methods = [],
    requirement = [],
    setStep,
    paginationMethodStep,
    setPaginationMethodStep,
    errorConfirm = false,
  } = props;

  const formikContext = useFormikContext();

  const paginationMethods = useMemo(() => {
    const start = paginationMethodStep;
    if (methods) {
      const res = [];
      for (let i = 0; i < methods.length; i++) {
        if (i === start) {
          res.push(methods[i]);
        } else {
          continue;
        }
      }
      return res;
    }
    return methods;
  }, [methods, paginationMethodStep]);

  const validMethod = useMemo(() => {
    if (paginationMethods.length > 0) {
      const methodsRequired = requirement.find(
        (element) => element === paginationMethods[0].id
      );
      if (methodsRequired) {
        if (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          formikContext.values[methodsRequired]
            .flat(Infinity)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .filter((el) => el).length !==
          Object.values(paginationMethods[0].survey.instruction)[0]
            .survey_question_ids.length
        ) {
          return true;
        }
        return false;
      }
      return false;
    }
    return false;
  }, [paginationMethods, formikContext]);

  useEffect(() => {
    if (paginationMethods?.length === 0) {
      setStep('complete');
    }
  }, [paginationMethods]);

  return (
    <div className={styles.questionnaireForMethods}>
      <div className={styles.questionnaireForMethods__form}>
        {paginationMethods &&
          paginationMethods.map((m, i: number) => (
            <Box mb={36} key={`method${m.id}`}>
              <QuestionsForMethodsForm
                id={m.id}
                methodsList={m.survey}
                // methodTitle={m.survey.name}
                methodInstruction={Object.values(m.survey.instruction)[0].text}
              />
            </Box>
          ))}
      </div>
      <div className={styles.questionnaireForMethods__submit}>
        <Button
          label="Назад"
          withoutBackground
          clicked={() => {
            paginationMethodStep > 0
              ? setPaginationMethodStep(paginationMethodStep - 1)
              : setStep('quiz');
          }}
        />
        <Button
          label="Далее"
          type="submit"
          gradient
          disabled={validMethod || errorConfirm}
          clicked={() => {
            paginationMethodStep >= methods.length
              ? setStep('complete')
              : setPaginationMethodStep(paginationMethodStep + 1);
          }}
        />
      </div>
    </div>
  );
};
