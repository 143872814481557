import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, FormikContext, useFormik } from 'formik';

import { VALIDATION_ERRORS } from '../../../../../models/validations/errors/errors';
import {
  authInitialValues,
  authValidationSchema,
} from '../../../../../models/validations/schemas/validations';
import {
  useLoginMutation,
  useGetCurrentUserQuery,
} from '../../../../../store/VbudusheeAPI';
import { frontendRoutes } from '../../../../../utils/router/routes';
import { getCurrentUser } from '../../../../../store/userSlice';

//  ui
import { Button } from '../../../../ui/Button';
import { InputPassword } from '../../../../ui/Form/InputPassword';
import { InputText } from '../../../../ui/Form/InputText';
import { LinkTo } from '../../../../ui/LinkTo';
import { Loader } from '../../../../ui/Loader';

import styles from './LoginForm.module.scss';
import classNames from 'classnames/bind';
import { ModalError } from '../../../../ui/ModalError';

const cx = classNames.bind(styles);

export const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { isLoading, error, isSuccess }] = useLoginMutation();
  const { data: userData } = useGetCurrentUserQuery(
    {},
    {
      skip: !isSuccess,
    }
  );

  const [serverError, setServerError] = useState<string | undefined>();

  const handleSubmit = async (values: typeof authInitialValues) => {
    if (isLoading) {
      return;
    }
    setServerError(undefined);

    const result = await login(values);
    if ('data' in result) {
      localStorage.setItem('email', values.email);
      if (localStorage.getItem('servey')) {
        const sur = localStorage.getItem('servey');
        localStorage.removeItem('servey');
        navigate(`${frontendRoutes.survey.survey}/${sur}`);
      } else {
        navigate(frontendRoutes.user.profile.index);
      }
    }
  };

  useEffect(() => {
    if (error && 'data' in error) {
      const serverError = error.data as { message: string };
      setServerError(serverError.message);
    }
  }, [error]);

  const formik = useFormik({
    initialValues: authInitialValues,
    validationSchema: authValidationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (userData) {
      dispatch(getCurrentUser(userData));
      if (isSuccess) {
        navigate(frontendRoutes.user.profile.index);
      }
    } else {
      dispatch(getCurrentUser(null));
      if (isSuccess) {
        navigate(frontendRoutes.user.profile.index);
      }
    }
  }, [dispatch, isSuccess, navigate, userData]);

  return (
    <>
      <FormikContext.Provider value={formik}>
        <Form>
          {isLoading ? (
            <Loader />
          ) : (
            <div className={styles.form}>
              <InputText name="email" placeholder="Email" />
              <InputPassword name="password" placeholder="Пароль" />
              <div className={styles.nav}>
                <LinkTo
                  label="Забыли пароль?"
                  link={frontendRoutes.user.restorePass}
                />
                <div className={styles.form__submit}>
                  <Button
                    label="Войти"
                    type="submit"
                    gradient={true}
                    disabled={isLoading}
                  />
                </div>
              </div>
              <div className={styles.form__divider} />
              <div className={styles.form__signUp}>
                У вас ещё нет аккаунта?
                <LinkTo
                  label="Зарегистрироваться"
                  link={frontendRoutes.user.chooseRegType}
                />
              </div>
            </div>
          )}
        </Form>
      </FormikContext.Provider>
      <ModalError
        isOpen={!!serverError}
        errorText={serverError}
        onClose={() => {
          setServerError('');
        }}
      />
    </>
  );
};
