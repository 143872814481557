import React, { Dispatch, SetStateAction, useEffect } from 'react';
import styles from './CriteriaItem.module.scss';
import delete_icon from '../../../../../../assets/images/svg/events/delete_icon.svg';
import edit_icon from '../../../../../../assets/images/svg/events/edit_icon.svg';
import { Typography } from '../../../../../ui/Typography';
import { InputText } from '../../../../../ui/Form/InputText';
import { Button } from '../../../../../ui/Button';
import { FormikContext, useFormik } from 'formik';
import { object, string } from 'yup';
import { CriteriaType } from '../GradingSystemForm';

type CriteriaItemProps = {
  id: number;
  idx: number;
  description: string;
  onClick: () => void;
  editCriteria: number | null;
  setEditCriteria: (value: number | null) => void;
  setCriteries: Dispatch<SetStateAction<CriteriaType[]>>;
  maxLength?: number;
  disabled?: boolean;
};

export const CriteriaItem = (props: CriteriaItemProps) => {
  const {
    id,
    idx,
    description,
    onClick,
    editCriteria,
    setEditCriteria,
    setCriteries,
    maxLength,
    disabled,
  } = props;
  const editInitialValues = {
    edit_criteria: description,
  };

  const editValidationSchema = object({
    edit_criteria: string(),
  });

  const handleSubmit = async (values: typeof editInitialValues) => {};

  const formik = useFormik({
    initialValues: editInitialValues,
    validationSchema: editValidationSchema,
    onSubmit: handleSubmit,
  });

  const handleEditChange = (value: string) => {
    formik.setFieldValue('edit_criteria', value);
  };

  const handleDelete = () => {
    onClick();
    setEditCriteria(null);
  };

  useEffect(() => {
    handleEditChange(description);
  }, [description]);

  return (
    <FormikContext.Provider value={formik}>
      <div className={styles.criteriaItem}>
        <div className={styles.criteriaItem__header}>
          <div className={styles.criteriaItem__header_title}>{`критерий ${
            idx + 1
          }`}</div>
          <div className={styles.criteriaItem__header_settings}>
            {editCriteria !== id && !disabled && (
              <div
                className={styles.icon}
                onClick={() => editCriteria === null && setEditCriteria(id)}
              >
                <img src={edit_icon} alt="edit_icon" />
                Редактировать
              </div>
            )}
            {!disabled && (
              <div className={styles.icon} onClick={handleDelete}>
                <img src={delete_icon} alt="delete_icon" />
                Удалить
              </div>
            )}
          </div>
        </div>
        {editCriteria !== id ? (
          <div className={styles.criteriaItem__subHeader}>
            Описание критерия
            <Typography
              size={'sm'}
              customLineHeight={22}
              color={'main'}
              className={styles.criteriaItem__subHeader_description}
            >
              {description}
            </Typography>
          </div>
        ) : (
          <div style={{ marginTop: 20 }}>
            <InputText
              name="edit_criteria"
              placeholder="Описание критерия"
              fullWidth
              maxLength={maxLength}
            />
            <div style={{ display: 'flex', gap: 16 }}>
              <Button
                label="Сохранить"
                gradient
                size={'medium'}
                disabled={!formik.values.edit_criteria || !formik.dirty}
                clicked={() => {
                  setCriteries((prev: CriteriaType[]) =>
                    prev.map((el) => {
                      if (el.id === id) {
                        return {
                          id: el.id,
                          category_name: formik.values.edit_criteria,
                        };
                      }
                      return el;
                    })
                  );
                  setEditCriteria(null);
                }}
              />
              <Button
                label="Отмена"
                withoutBackground
                size={'medium'}
                clicked={() => {
                  setEditCriteria(null);
                  handleEditChange(description);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </FormikContext.Provider>
  );
};
