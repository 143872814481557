import { useNavigate } from 'react-router-dom';

//  ui
import { Layout } from '../../../components/Layout';
import { MaterialsResearch } from '../../../components/pages/materials/MaterialsResearch';

export const MaterialsResearchPage = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <MaterialsResearch />
    </Layout>
  );
};
