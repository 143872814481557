// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RadioButtonChoiceType_radioButtonChoice__GvxP8 {
  display: flex;
  flex-direction: column;
  position: relative;
}
.RadioButtonChoiceType_radioButtonChoice__title__ijl-W {
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/SurveyQuestion/RadioButtonChoiceType/RadioButtonChoiceType.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AACJ","sourcesContent":[".radioButtonChoice {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n\n  &__title {\n    font-weight: 700;\n    font-size: 14px;\n    line-height: 22px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioButtonChoice": `RadioButtonChoiceType_radioButtonChoice__GvxP8`,
	"radioButtonChoice__title": `RadioButtonChoiceType_radioButtonChoice__title__ijl-W`
};
export default ___CSS_LOADER_EXPORT___;
