export const IconRar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7365 2.76172H8.08449C6.02449 2.76172 4.25049 4.43072 4.25049 6.49072V17.3397C4.25049 19.5157 5.90849 21.1147 8.08449 21.1147H16.0725C18.1325 21.1147 19.8025 19.3997 19.8025 17.3397V8.03772L14.7365 2.76172Z"
        stroke="#FF8A00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4739 2.75V5.659C14.4739 7.079 15.6229 8.231 17.0429 8.234C18.3589 8.237 19.7059 8.238 19.7969 8.232"
        stroke="#FF8A00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="12" y="11" width="12" height="7" fill="white" />
      <path
        d="M8 17.2396V11.6636C8.352 11.6476 8.67733 11.6343 8.976 11.6236C9.27467 11.6076 9.584 11.5996 9.904 11.5996C10.3413 11.5996 10.7093 11.6396 11.008 11.7196C11.3067 11.7943 11.544 11.9089 11.72 12.0636C11.9013 12.2129 12.0293 12.4049 12.104 12.6396C12.184 12.8689 12.224 13.1383 12.224 13.4476C12.224 13.6769 12.208 13.8849 12.176 14.0716C12.1493 14.2529 12.0987 14.4156 12.024 14.5596C11.9547 14.7036 11.8613 14.8289 11.744 14.9356C11.6267 15.0423 11.48 15.1329 11.304 15.2076L12.672 17.2396H11.224L10.008 15.3516H9.312V17.2396H8ZM9.896 14.3196C10.0827 14.3196 10.2373 14.3036 10.36 14.2716C10.488 14.2396 10.5893 14.1916 10.664 14.1276C10.7387 14.0636 10.792 13.9809 10.824 13.8796C10.856 13.7783 10.872 13.6583 10.872 13.5196C10.872 13.3863 10.856 13.2716 10.824 13.1756C10.7973 13.0796 10.7467 13.0023 10.672 12.9436C10.5973 12.8796 10.496 12.8343 10.368 12.8076C10.24 12.7756 10.0773 12.7596 9.88 12.7596C9.76267 12.7596 9.65867 12.7623 9.568 12.7676C9.48267 12.7729 9.38667 12.7809 9.28 12.7916V14.3196H9.896Z"
        fill="#FF8A00"
      />
      <path
        d="M16.7444 16.0636H14.6964L14.3124 17.2396H13.0084L14.9764 11.6396H16.5284L18.4884 17.2396H17.1444L16.7444 16.0636ZM15.0564 14.9836H16.3844L15.7204 12.9916L15.0564 14.9836Z"
        fill="#FF8A00"
      />
      <path
        d="M19.3047 17.2396V11.6636C19.6567 11.6476 19.982 11.6343 20.2807 11.6236C20.5794 11.6076 20.8887 11.5996 21.2087 11.5996C21.646 11.5996 22.014 11.6396 22.3127 11.7196C22.6114 11.7943 22.8487 11.9089 23.0247 12.0636C23.206 12.2129 23.334 12.4049 23.4087 12.6396C23.4887 12.8689 23.5287 13.1383 23.5287 13.4476C23.5287 13.6769 23.5127 13.8849 23.4807 14.0716C23.454 14.2529 23.4034 14.4156 23.3287 14.5596C23.2594 14.7036 23.166 14.8289 23.0487 14.9356C22.9314 15.0423 22.7847 15.1329 22.6087 15.2076L23.9767 17.2396H22.5287L21.3127 15.3516H20.6167V17.2396H19.3047ZM21.2007 14.3196C21.3874 14.3196 21.542 14.3036 21.6647 14.2716C21.7927 14.2396 21.894 14.1916 21.9687 14.1276C22.0434 14.0636 22.0967 13.9809 22.1287 13.8796C22.1607 13.7783 22.1767 13.6583 22.1767 13.5196C22.1767 13.3863 22.1607 13.2716 22.1287 13.1756C22.102 13.0796 22.0514 13.0023 21.9767 12.9436C21.902 12.8796 21.8007 12.8343 21.6727 12.8076C21.5447 12.7756 21.382 12.7596 21.1847 12.7596C21.0674 12.7596 20.9634 12.7623 20.8727 12.7676C20.7874 12.7729 20.6914 12.7809 20.5847 12.7916V14.3196H21.2007Z"
        fill="#FF8A00"
      />
    </svg>
  );
};
