import React, { useState } from 'react';

//  ui
import { IconTooltip } from '../Icons/IconTooltip';
import { Typography } from '../Typography';
import { Box } from '../Box';

import styles from './Tooltip.module.scss';
import { IconCloseRound } from '../Icons/IconCloseRound';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

type TooltipProps = {
  title?: string;
  children: React.ReactNode;

  clickable?: boolean;
};

export const Tooltip = ({
  title,
  children,
  clickable,
}: TooltipProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div
      className={cx('tooltip', {
        tooltip__hover: !clickable,
        tooltip__clickable: clickable && isOpen,
      })}
    >
      <div className={styles.icon} onClick={() => setIsOpen((prev) => !prev)}>
        <IconTooltip />
      </div>
      <div className={styles.message}>
        <Typography
          color="main"
          size="xxl"
          font="SFPRoDisplay"
          fontWeight={600}
        >
          {title}
        </Typography>
        {clickable && (
          <span
            className={styles.topIcon}
            onClick={() => {
              setIsOpen((prev) => !prev);
            }}
          >
            <IconCloseRound />
          </span>
        )}
        <Box mt={20} />
        <Typography color="main" size="s" customLineHeight={22} tag="span">
          {children}
        </Typography>
      </div>
    </div>
  );
};
