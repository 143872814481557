import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../../components/Layout';
import { PublicEventsRequests } from '../../../components/pages/publicEventsCatalog/PublicEventsRequests';

export const PublicEventsRequestsPage = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <PublicEventsRequests />
    </Layout>
  );
};
