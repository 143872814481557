import { ContentModal } from '../ContentModal';
import { Typography } from '../Typography';
import styles from './ResearchDetailsModal.module.scss';

export const ResearchDetailsModal = ({
  isOpen,
  onClose,
  details,
}: {
  isOpen: boolean;
  onClose: () => void;
  details?: Record<string, number>;
}) => {
  return (
    <ContentModal
      width="xl"
      isOpen={isOpen}
      onClose={() => onClose()}
      titleText="Детализированная сводка заполнения опроса"
    >
      <div className={styles.container}>
        <div className={styles.separator} />

        {details && Object.entries(details).length > 0 ? (
          <table className={styles.table}>
            <thead>
              <tr className={styles.row}>
                <th className={styles.cell}>
                  <span>Вопросы</span>
                </th>
                <th className={styles.cell}>
                  <span>Количество респондентов</span>
                </th>
              </tr>
            </thead>
            <tbody className={styles.tbody}>
              {Object.entries(details).map(([key, value], index) => (
                <tr key={index} className={styles.row}>
                  <td className={styles.cell}>{key}</td>
                  <td className={styles.cell}>{value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className={styles.empty}>
            <Typography color="black2" size="lg">
              Нет данных
            </Typography>
          </div>
        )}
      </div>
    </ContentModal>
  );
};
