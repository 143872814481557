import { ResearchTeam } from '../../../../../../models/research';
import { useDeleteTeamResearchMutation } from '../../../../../../store/VbudusheeAPI';
import { Box } from '../../../../../ui/Box';

import styles from './ResearchTeamCard.module.scss';

export const ResearchTeamCard = (team: ResearchTeam) => {
  const [deleteTeam] = useDeleteTeamResearchMutation();

  const deleteParticipantHandler = async () => {
    const result = await deleteTeam({
      research_id: team.researchId,
      body: {
        team_id: team.team_id.toString(),
        access: 0,
      },
    });
  };

  return (
    <div>
      <div className={styles.card_wraper__description}>{team.team_name}</div>
      <div
        className={styles.card_wraper__name}
      >{`${team.team_description}`}</div>
      <a
        className={styles.card_wraper__link}
        onClick={deleteParticipantHandler}
      >
        Удалить
      </a>
      <Box mb={20} />
    </div>
  );
};
