import { useCallback } from 'react';
import classNames from 'classnames/bind';

import {
  getMonthDays,
  isOtherMonth,
  isSameDate,
  isInRange,
  isOtherDay,
} from '../../../../utils/components/calendar';

//  ui
import styles from './CalendarMonth.module.scss';
const cx = classNames.bind(styles);

type CalendarMonthProps = {
  startDate: Date | undefined;
  handleChange: (date: Date) => void;
  finishDate: Date | undefined;
  actualDate: Date;
  isRange?: boolean;
  minDate?: Date;
};

export const CalendarMonth = ({
  startDate,
  handleChange,
  finishDate,
  actualDate,
  isRange,
  minDate,
}: CalendarMonthProps): JSX.Element => {
  return (
    <div className={cx('month')}>
      {getMonthDays(actualDate).map((day, i) => (
        <div
          key={day.toLocaleDateString()}
          className={cx('month__day', {
            month__day_other:
              isOtherMonth(day, actualDate) ||
              (minDate && isOtherDay(day, minDate)),
            month__day_today:
              !isOtherMonth(day, actualDate) &&
              new Date().toDateString() === day.toDateString() &&
              !isInRange(startDate, finishDate, day),
            month__day_today_inRange:
              !isOtherMonth(day, actualDate) &&
              new Date().toDateString() === day.toDateString() &&
              isInRange(startDate, finishDate, day),
            month__day_inRange: isInRange(startDate, finishDate, day),
            month__day_inRange_first:
              !isOtherMonth(day, actualDate) &&
              isInRange(startDate, finishDate, day) &&
              isSameDate(startDate, day),
            month__day_inRange_last:
              !isOtherMonth(day, actualDate) &&
              isInRange(startDate, finishDate, day) &&
              isSameDate(finishDate, day),
          })}
          onClick={
            !isOtherMonth(day, actualDate) ? () => handleChange(day) : undefined
          }
        >
          <span>{day.getDate()}</span>
          {!isRange && (
            <div
              className={cx('month__day_active', {
                visible: isSameDate(startDate, day),
              })}
            >
              <span>{day.getDate()}</span>
            </div>
          )}
          {isRange && startDate && (
            <div
              className={cx('month__day_active', {
                visible:
                  isSameDate(startDate, day) && !isOtherMonth(day, actualDate),
              })}
            >
              <span>{day.getDate()}</span>
            </div>
          )}
          {isRange && finishDate && (
            <div
              className={cx('month__day_active', {
                visible:
                  isSameDate(finishDate, day) && !isOtherMonth(day, actualDate),
              })}
            >
              <span>{day.getDate()}</span>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
