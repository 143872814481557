import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import styles from './CreateEventForm.module.scss';

//  ui
import { InputText } from '../../../ui/Form/InputText';
import {
  createEventInitialValues,
  createEventValidationSchema,
} from '../../../../models/validations/schemas/validations';
import { Textarea } from '../../../ui/Form/Textarea/Textarea';

import { Select } from '../../../ui/Form/Select';
import { TYPE_DOCUMENTS } from '../../../../constants/newEvent/select';
import { InputDate } from '../../../ui/Form/InputDate';
import { Button } from '../../../ui/Button';
import { SelectSearch } from '../../../ui/Form/SelectSearch';
import { TransformTag } from '../../../../models/tags/tag';
import {
  useAddMediafilesMutation,
  useCreateEventsMutation,
} from '../../../../store/VbudusheeAPI';
import { frontendRoutes } from '../../../../utils/router/routes';
import { IconClip } from '../../../ui/Icons/IconClip';
import { Tooltip } from '../../../ui/Tooltip';
import { Box } from '../../../ui/Box';
import { useToasts } from '../../../../hooks/useToasts';
import { ElementFile } from '../../../ui/ElementFile';

type CreateEventFormProps = {
  transformedTagsData: TransformTag[] | undefined;
};
export const CreateEventForm = (props: CreateEventFormProps) => {
  const { transformedTagsData } = props;
  const navigate = useNavigate();
  const { onShowToast } = useToasts();
  const [createEvents, { isSuccess, data }] = useCreateEventsMutation();
  const [
    addEventMediafiles,
    {
      isSuccess: isSuccessEventMediafiles,
      isError: isErrorEventMediafiles,
      data: dataEventMediafiles,
    },
  ] = useAddMediafilesMutation();

  const filePicker = useRef(null);

  const [serverError] = useState<string | undefined>();
  const [selectedFile, useSelectedFile] = useState<File[]>([]);

  const arrType = [
    'ffff0000',
    'feff0000',
    'efbbbf00',
    '255044462d',
    '25504446',
    '504b0304',
    '2082071722416117726225',
    '89504e47',
    'ffd8ffe0',
    'ffd8ffe1',
    'ffd8ffe2',
    'ffd8ffe3',
    'ffd8ffe8',
    '526172211a0700',
    '504b34',
    '6950686f',
    '52617221',
  ];

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = (event.target as HTMLInputElement).files;
    let errorFile = false;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (Object.values(files).length > 10) {
      errorFile = true;
      onShowToast({
        type: 'danger',
        name: 'files_length',
        title: 'Ошибка',
        description: `Выберите не более 10 файлов`,
        timer: 3000,
      });
    } else if (
      selectedFile.length >= 10 ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Object.values(files).length + selectedFile.length > 10
    ) {
      errorFile = true;
      onShowToast({
        type: 'danger',
        name: 'general_files_length',
        title: 'Ошибка',
        description: `В мероприятие можно добавить до 10 медиафайлов`,
        timer: 3000,
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Object.values(files).map((el) => {
        if (el.size / 1000 >= 15000) {
          onShowToast({
            type: 'danger',
            name: 'file_size',
            title: 'Ошибка',
            description: `Превышен размер добавляемого файла - ${el.name}. Можно добавить файлы не более 15 мб`,
            timer: 3000,
          });
          errorFile = true;
        }
      });
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    for (let i = 0; i < files.length; i++) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const blob = files[i];
      const fileReader = new FileReader();
      fileReader.onloadend = function (e) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const arr = new Uint8Array(e.target.result).subarray(0, 4);
        let header = '';
        for (let y = 0; y < arr.length; y++) {
          header += arr[y].toString(16);
        }
        if (!arrType.includes(header)) {
          errorFile = true;
          onShowToast({
            type: 'danger',
            name: 'files_type',
            title: 'Ошибка',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            description: `Недопустимый тип медиафайла -${files[i].name}`,
            timer: 3000,
          });
        }
      };
      fileReader.readAsArrayBuffer(blob);
    }

    setTimeout(() => {
      if (!errorFile) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useSelectedFile((prev) => [...prev, ...Object.values(files)]);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        event.target.value = null;
      }
    }, 150);
  };

  const handlePick = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    filePicker.current.click();
  };

  const handleSubmit = async (values: typeof createEventInitialValues) => {
    const date = values.event_start_date
      .split(';')
      .map((item) => new Date(item).toLocaleDateString('en-CA'));

    const result = await createEvents({
      data: {
        event_name: values.event_name,
        event_description: values.event_description,
        event_document_type: values.event_document_type,
        event_start_date: date[0],
        event_end_date: date[1],
        event_theme: values.event_theme,
      },
    });
    if ('data' in result) {
      if (selectedFile.length > 0) {
        handleDownloadFile(result.data?.event_id);
      } else {
        navigate(
          `${frontendRoutes.publicEventsCatalog.events}/${result.data?.event_id}`
        );
      }
    }
  };

  const handleDownloadFile = async (eventId: number | undefined) => {
    const formData: any = new FormData();
    formData.append('object_id', eventId ? eventId : 0);
    for (let i = 0; i < selectedFile.length; i++) {
      formData.append(`mediafiles[${i}]`, selectedFile[i]);
    }
    formData.append('mediafile_type', 'event');

    const result = await addEventMediafiles(formData);

    if (result) {
      if (selectedFile.length > 0) {
        navigate(`${frontendRoutes.publicEventsCatalog.events}/${eventId}`);
      }
    }
  };

  const formik = useFormik({
    initialValues: createEventInitialValues,
    validationSchema: createEventValidationSchema,
    onSubmit: handleSubmit,
  });

  const cancelCreateEvent = () => {
    formik.resetForm();
    navigate(-1);
  };

  const isDelete = (index: number) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSelectedFile((prev) => prev.filter((el, i) => i !== index));
  };

  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <div className={styles.form}>
          <div className={styles.form__block}>
            <InputText
              name="event_name"
              placeholder="Название мероприятия*"
              fullWidth
              redRequired
            />
            <Textarea
              name="event_description"
              placeholder="Описание мероприятия*"
              height={120}
              maxLength={2000}
              redRequired
            />
            <div className={styles.pickContainer}>
              <button
                type="button"
                onClick={handlePick}
                className={styles.buttonPick}
              >
                <IconClip />
                Прикрепить файлы
              </button>
              <Box mt={8} ml={8}>
                <Tooltip
                  title="Допускаемые форматы импортируемых файлов"
                  clickable
                >
                  <div
                    style={{ color: 'rgba(51, 63, 72, 1)' }}
                    className={styles.tooltip}
                  >
                    <div style={{ marginBottom: '16px' }}>
                      Вы можете прикрепить к описанию мероприятия следующие
                      медиафайлы:
                    </div>
                    <ul>
                      <li>Изображения формата PNG, JPEG.</li>
                      <li>Архивы RAR, ZIP.</li>
                      <li>Текстовые документы TXT, DOCX, PDF, PPTX.</li>
                      <li>Таблицы XSLX.</li>
                    </ul>
                    <div>Ограничение на размер файла до 15 мб.</div>
                  </div>
                </Tooltip>
              </Box>
              <input
                type="file"
                ref={filePicker}
                className={styles.hiddenInput}
                onChange={(event) => handleChange(event)}
                multiple
                accept="text/plain,application/pdf,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,image/png,image/jpeg,application/vnd.rar,application/rar,application/zip,.rar"
              />
            </div>
            {selectedFile && (
              <div className={styles.containerFile}>
                {selectedFile.map((el: File, i) => (
                  <div key={`${el.name}-${i}`} style={{ display: 'flex' }}>
                    <ElementFile
                      name={el.name}
                      file={el}
                      type={el.type}
                      index={i}
                      isDelete={(index) => {
                        isDelete(index);
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
            <div className={styles.form__block_selects}>
              <div className={styles.select}>
                <Select
                  name="event_document_type"
                  label="Типы собираемых документов*"
                  options={TYPE_DOCUMENTS}
                  fullWidth
                  redRequired
                />
              </div>
              <div className={styles.select}>
                <InputDate
                  isRange
                  name="event_start_date"
                  fullWidth={true}
                  placeholder="Сроки проведения*"
                  align="right"
                  required
                />
              </div>
              <div className={styles.select}>
                <SelectSearch
                  fullWidth={true}
                  name="event_theme"
                  placeholder="Тематические теги*"
                  options={transformedTagsData ? transformedTagsData : []}
                  required
                />
              </div>
            </div>

            <div className={styles.form__block_divider} />
            <div className={styles.buttonBlock}>
              <div
                className={styles.buttonBlock__cancelEvent}
                onClick={cancelCreateEvent}
              >
                <Button label="Отмена" withoutBackground />
              </div>
              <div>
                <Button
                  label="Сохранить"
                  type="submit"
                  disabled={!formik.dirty || !formik.isValid}
                  gradient
                />
              </div>
            </div>
          </div>

          {serverError && (
            <div className={styles.form__error}>{serverError}</div>
          )}
        </div>
      </Form>
    </FormikContext.Provider>
  );
};
