import { useTypedSelector } from '../store';
import { isAuthorized } from '../store/authSlice';

export const useAuthorized = (): { isAuthorized: boolean } => {
  const isUserAuthorized = useTypedSelector(isAuthorized);
  const storageToken = localStorage.getItem('token');
  return {
    isAuthorized: isUserAuthorized || !!storageToken,
  };
};
