import React, { useEffect, useState } from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import classNames from 'classnames/bind';

import { useUserValidatePasswordMutation } from '../../../../store/VbudusheeAPI';
import { frontendRoutes } from '../../../../utils/router/routes';
import { VALIDATION_ERRORS } from '../../../../models/validations/errors/errors';

import {
  changePasswordInitialValues,
  changePasswordValidationSchema,
} from '../../../../models/validations/schemas/validations';

//ui
import { Button } from '../../../ui/Button';
import { useNavigate } from 'react-router-dom';
import { InputPassword } from '../../../ui/Form/InputPassword';

import styles from './ChangePasswordForm.module.scss';

const cx = classNames.bind(styles);

type IChangePasswordForm = {
  setIsChangePassword: (b: boolean) => void;
};

export const ChangePasswordForm = (props: IChangePasswordForm) => {
  const { setIsChangePassword } = props;
  const [userValidatePassword, { isLoading, error, isSuccess }] =
    useUserValidatePasswordMutation();
  const [serverError, setServerError] = useState<string | undefined>();
  const messagePasswordInvalid = 'Invalid password';
  const navigate = useNavigate();

  const handleSubmit = async (values: typeof changePasswordInitialValues) => {
    await userValidatePassword({ password: values.password });
  };

  const formik = useFormik({
    initialValues: changePasswordInitialValues,
    validationSchema: changePasswordValidationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (isSuccess) {
      setIsChangePassword(true);
      navigate(frontendRoutes.user.confirmPassword);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error && 'data' in error) {
      const serverError = error.data as { message: string };
      if (serverError.message) {
        setServerError(serverError.message);
      } else {
        setServerError(VALIDATION_ERRORS.server.error);
      }
    }
  }, [error]);

  useEffect(() => {
    if (serverError) {
      formik.setErrors({ password: serverError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverError]);

  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <div className={styles.form}>
          <InputPassword name="password" placeholder="Текущий пароль" />
          <div className={styles.nav}>
            <div className={styles.form__submit}>
              <Button
                label="Далее"
                type="submit"
                gradient={true}
                size={'large'}
              />
            </div>
          </div>
        </div>
      </Form>
    </FormikContext.Provider>
  );
};
