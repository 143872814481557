import { useEffect, useRef, useState } from 'react';
import { IconArrowFilled } from '../../../../ui/Icons/IconArrowFilled';
import { ComparisonDownloadBar } from './ComparisonDownloadBar';
import classNames from 'classnames/bind';
import styles from './ComparisonDownloadButton.module.scss';
import { downloadResearchComparisonFile } from '../../../../../utils/api/downloadDetailsRaw';
const cx = classNames.bind(styles);

type ComparisonDownloadButtonProps = {
  researches: number[];
};

export const ComparisonDownloadButton = ({
  researches,
}: ComparisonDownloadButtonProps) => {
  const [isBarOpen, setBarOpen] = useState<boolean>(false);

  const downloadBarRef = useRef(document.createElement('div'));

  const handleToggleBar = () => setBarOpen((prev) => !prev);

  const handleDownloadComparisonFile = async () => {
    try {
      if (researches.length < 2) {
        return;
      }

      downloadResearchComparisonFile(researches, 'первичные данные');
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  useEffect(() => {
    const onClick = (e: MouseEvent) =>
      downloadBarRef?.current?.contains(e.target as Node) || setBarOpen(false);
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  return (
    <div className={styles.contentTopButtonWrapper} ref={downloadBarRef}>
      <div className={styles.contentTopButton} onClick={handleToggleBar}>
        <span className={styles.contentTopButtonText}>Скачать данные</span>
        <span
          className={cx('contentTopButtonIcon', {
            contentTopButtonIconActive: isBarOpen,
          })}
        >
          <IconArrowFilled />
        </span>
      </div>
      {isBarOpen && (
        <ComparisonDownloadBar
          handleToggleBar={handleToggleBar}
          handleDownloadComparisonFile={handleDownloadComparisonFile}
        />
      )}
    </div>
  );
};
