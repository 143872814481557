import { Typography } from '../../../../../ui/Typography';
import styles from './SurveyPageLayout.module.scss';

type SurveyPageLabelProps = {
  pageNumber: number;
  children: React.ReactNode;
};

export const SurveyPageLayout = ({
  pageNumber,
  children,
}: SurveyPageLabelProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>
        <Typography color="gray6" size="s">
          Страница {pageNumber}
        </Typography>
      </div>
      {children}
    </div>
  );
};
