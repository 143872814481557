export const VALIDATION_ERRORS = {
  wrongSelect: 'Необходимо выбрать значение из предложенных вариантов',
  required: 'Заполните поле',
  regExp: 'Недопустимые символы',
  incorrect: 'Введите корректное значение',
  question: 'Выберите тип анкетного вопроса',
  mandatoryQuestion: 'Пожалуйста, ответьте на обязательный вопрос',
  questionLength: 'Вопрос должен содержать больше 10 символов',
  email: {
    incorrect: 'Некорректный email или пароль',
    incorrectEmail: 'Некорректный email',
    confirm: 'PasswordForm не совпадают',
    isEmailReserve: 'Электронная почта уже занята',
  },
  code: {
    incorrectLenght: 'Длина кода должна быть равна 6',
    incorrectType: 'Код состоит только из цифр',
    required: 'Код обязателен',
  },
  password: {
    short: 'Пароль должен состоять минимум из 8 символов',
    capitalLetter:
      'Пароль должен содержать не менее 8 символов, цифры, спецсимволы, заглавные и строчные буквы',
    confirm: 'Пароли не совпадают',
    notRightPassword: 'Неправильный пароль',
  },
  names: {
    onlyRu: 'Только кириллические буквы',
    min: 'Слишком короткое значение',
  },
  policy: 'Необходимо дать согласие на обработку персональных данных',
  policy2: 'Необходимо дать согласие на прохождение исследования',
  server: {
    error: 'Ошибка сервера',
    emailIncorrect: 'Email не зарегистрирован',
    emailNotRegistered: 'Email не зарегистрирован',
    passIncorrect: 'Неверные логин или пароль',
    emailAlreadyTaken: 'E-mail уже зарегистрирован',
    requiredData: 'Необходимо заполнить обязательные поля',
    wrongData: 'Некорректный формат данных',
    innExists: 'Пользователь с данным ИНН уже существует',
    invalidToken: 'Токен из ссылки недействителен',
    invalidEmail:
      'Электронная почта должна быть действительным адресом электронной почты',
  },
  teams: {
    requiredTeamName: 'Необходимо заполнить поле "Название команды"',
    requiredTeamDescription: 'Необходимо заполнить поле "Описание команды"',
    permitted: 'Доступ ограничен для этой операции',
    invalidUserLogin: 'Пользователь с таким логином не найден',
    required: 'Это обязательное поле',
  },
  research: {
    requiredResearchName: 'Необходимо заполнить поле "Название исследования"',
    requiredResearchNameMinLength:
      '"Название исследования" должно содержать больше 1 символова',
    requiredResearchNameMaxLength:
      '"Название исследования" не должно содержать более 100 символов',
    requiredResearch: 'Это поле обязательно для заполнения',
    requiredEmailUser: 'Должен быть корректный адрес электронной почты',
    requiredResearchDescription:
      'Необходимо заполнить поле "Описание исследования"',
    requiredResearchDescriptionMinLength:
      '"Описание исследования" должно содержать больше 10 символов',
    requiredResearchDescriptionMaxLength:
      '"Описание исследования" не должно содержать более 1000 символов',
    requiredResearchDate: 'Установите срок проведения иследования',
    requiredResearchStartDate:
      'Необходимо выставить дату старта не позднее текущей',
    requiredResearchStartDateUndefined:
      'Установите дату начала проведения иследования',
    requiredResearchEndDate:
      'Срок проведения исследования должен составлять не менее двух дней',
    requiredResearchEndDateUndefined:
      'Установите конечный срок проведения иследования',
    permitted: 'Доступ ограничен для этой операции',
    requiredStepResearch: 'Укажите количество этапов от 1 до 100',
    requiredInteger: 'Укажите целое число',
    requiredIntegerType: 'Этап исследования должно быть числом',
  },
  event: {
    event_name: {
      minLength: 'Должно содержать более 1 символова',
      maxLength: 'Не должно содержать более 100 символов',
    },
    event_description: {
      minLength: 'Должно содержать более 10 символова',
      maxLength: 'Не должно содержать более 1000 символов',
    },
  },
  survey: {
    greeting: 'Необходимо заполнить поле "Приветствия"',
    greetingMinLength: '"Приветствие" должно содержать хотябы 3 символов',
    greetingMaxLength: '"Приветствие" не должно содержать более 1000 символов',
    minLength: 'Должно содержать хотябы 3 символов',
    maxLength: 'Не должно содержать более 100 символов',
    gratitude: 'Необходимо заполнить поле "Благодарность за участие"',
    gratitudeMinLength:
      '"Благодарность за участие" должно содержать хотябы 3 символов',
    gratitudeMaxLength:
      '"Благодарность за участие" не должно содержать более 1000 символов',
    methods: 'Выберите Методику',
  },
  organization_inn: {
    invalid: 'Указан некорректный ИНН для Организации',
  },
  organization_name: {
    invalid: 'Некорректное название организации',
  },
};
