export const IconLineArrow = (): JSX.Element => {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.81706 11.1666L4.81706 1.16663"
        stroke="#EC1B28"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.80083 7.13342L4.81683 11.1668L8.8335 7.13342"
        stroke="#EC1B28"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
