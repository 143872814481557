import React, { useEffect, useMemo, useState } from 'react';

import { CurrentUser } from '../../models/user/auth';
import { getValueFromDictionary } from '../../utils/array';
import { OCCUPATION, ORGANIZATION_CATEGORY } from '../../constants/user';
import {
  useGetKladrByRegionMutation,
  useGetRegionDataQuery,
} from '../../store/VbudusheeAPI';

//  ui
import { Breadcrumbs } from '../ui/Breadcrumbs';
import { Button } from '../ui/Button';
import { ContentModal } from '../ui/ContentModal';
import { ProfileEditForm } from './ProfileEditForm';
import { ProfilePopupContent } from './ProfilePopupContent';
import { Typography } from '../ui/Typography';
import { Box } from '../ui/Box';

import { ConfirmEmailPopup } from './ConfirmEmailPopup';
import { Region } from '../../models/research';

export type AddressType = {
  address: string;
  kladr_id: string;
};

type ProfileTypes = {
  user?: CurrentUser;
};

export const Profile = ({ user }: ProfileTypes) => {
  const [getKladr, { error, isLoading }] = useGetKladrByRegionMutation();
  const { data: regions } = useGetRegionDataQuery({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [isProfileEdit, setProfileEdit] = useState(false);
  const [address, setAddress] = useState<AddressType>({
    address: '',
    kladr_id: '',
  });
  const [isConfirmEmailMessage, setIsConfirmEmailMessage] =
    useState<boolean>(true);

  const regionName = regions?.find((item) => {
    if (user?.user.region === item.region_number) {
      return item.region_name;
    }
  });

  const getAddress = async () => {
    const result: any = await getKladr({
      region_number: regionName?.region_number ?? '',
    });

    result.data.find((item: AddressType) => {
      if (item.kladr_id === user?.user.locality) {
        setAddress(item);
      }
    });
  };

  const region = useMemo(() => {
    if (regions && user) {
      const result = regions.find(
        (el: Region) => el.region_number === user.user.region
      );
      return result ? result.region_name : 'Нет данных';
    }
    return 'Нет данных';
  }, [regions, user]);

  useEffect(() => {
    getAddress();
  }, [user]);

  return (
    <div className="details">
      <div className="local-container">
        <Breadcrumbs links={[{ title: 'Мой профиль' }]} homeLink="/" />
      </div>
      <div className="details__header">
        <Typography
          color="main"
          size="xxxl"
          font="SFPRoDisplay"
          fontWeight={600}
        >
          Мой профиль
        </Typography>
        <div onClick={() => setModalOpen(true)}>
          <Button label="Редактировать" gradient={true} />
        </div>
      </div>
      <div className="details__body">
        <Typography color="main" fontWeight={600} size="s" upperCase>
          Основная информация
        </Typography>
        <Box mb={33} />
        <div className="row">
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Фамилия
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {user?.user?.last_name || 'Нет данных'}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Имя
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {user?.user?.first_name || 'Нет данных'}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Отчество
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {user?.user?.middle_name || 'Нет данных'}
              </Typography>
            </div>
          </div>
          {user?.user?.role?.name &&
          user?.user?.role?.name.toLowerCase() !== 'респондент' ? (
            <>
              <div className="detail">
                <div className="detail__content">
                  <div className="detail__content-title">
                    <Typography color="gray" size="s">
                      Название должности
                    </Typography>
                  </div>
                  <Typography color="main" size="sm">
                    {user?.user?.position_name || 'Нет данных'}
                  </Typography>
                </div>
              </div>
              <div className="detail">
                <div className="detail__content">
                  <div className="detail__content-title">
                    <Typography color="gray" size="s">
                      Профессиональная категория
                    </Typography>
                  </div>
                  <Typography color="main" size="sm">
                    {getValueFromDictionary(
                      OCCUPATION,
                      user?.user?.professional_category
                    ) || 'Нет данных'}
                  </Typography>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="detail">
                <div className="detail__content">
                  <div className="detail__content-title">
                    <Typography color="gray" size="s">
                      Электронная почта
                    </Typography>
                  </div>
                  <Typography color="main" size="sm">
                    {user?.user?.email || 'Нет данных'}
                  </Typography>
                </div>
              </div>
            </>
          )}
        </div>
        {user?.user?.role?.name &&
          user?.user?.role?.name.toLowerCase() !== 'респондент' && (
            <>
              <div className="details__divider" />

              <Typography color="main" fontWeight={600} size="s" upperCase>
                Организация
              </Typography>
              <Box mb={33} />
              <div className="row">
                <div className="detail">
                  <div className="detail__content">
                    <div className="detail__content-title">
                      <Typography color="gray" size="s">
                        Регион
                      </Typography>
                    </div>
                    <Typography color="main" size="sm">
                      {user?.user.region ?? 'Нет данных'}
                    </Typography>
                  </div>
                </div>
                <div className="detail">
                  <div className="detail__content">
                    <div className="detail__content-title">
                      <Typography color="gray" size="s">
                        Населенный пункт
                      </Typography>
                    </div>
                    <Typography color="main" size="sm">
                      {user?.user.locality ?? 'Нет данных'}
                    </Typography>
                  </div>
                </div>
                <div className="detail">
                  <div className="detail__content">
                    <div className="detail__content-title">
                      <Typography color="gray" size="s">
                        Категория организации
                      </Typography>
                    </div>
                    <Typography color="main" size="sm">
                      {getValueFromDictionary(
                        ORGANIZATION_CATEGORY,
                        user?.user?.organization_category
                      ) || 'Нет данных'}
                    </Typography>
                  </div>
                </div>
                <div className="detail">
                  <div className="detail__content">
                    <div className="detail__content-title">
                      <Typography color="gray" size="s">
                        Название организации
                      </Typography>
                    </div>
                    <Typography color="main" size="sm">
                      {user?.user?.organization_name || 'Нет данных'}
                    </Typography>
                  </div>
                </div>
              </div>
            </>
          )}
      </div>
      <ContentModal
        isOpen={isModalOpen}
        titleText="Редактирование профиля"
        onClose={() => setModalOpen(false)}
      >
        <ProfilePopupContent
          openProfileEdit={setProfileEdit}
          setModalOpen={setModalOpen}
        />
      </ContentModal>

      <ContentModal
        isOpen={isProfileEdit}
        titleText="Персональная информация"
        onClose={() => setProfileEdit(false)}
      >
        <ProfileEditForm onReject={setProfileEdit} address={address} />
      </ContentModal>

      {user === undefined && (
        <ContentModal
          isOpen={isConfirmEmailMessage}
          titleText="Подтверждение почты"
          onClose={() => setIsConfirmEmailMessage(false)}
        >
          <ConfirmEmailPopup
            openPopup={setIsConfirmEmailMessage}
            setModalOpen={setIsConfirmEmailMessage}
          />
        </ContentModal>
      )}
    </div>
  );
};
