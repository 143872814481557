export const thousandsToK = (num: number): string => {
  if (num < 100) {
    return num.toString();
  }

  const thousands = num / 1000;
  const rounded = Math.floor(thousands * 10) / 10;
  return `${rounded}K`;
};

export const getPercentage = (part: number, sum: number): number =>
  Math.round((part / sum) * 100);
