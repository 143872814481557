import React from 'react';
import styles from './Header.module.scss';

//ui
import { Button } from '../../../ui/Button';
import { IconArrowRight } from '../../../ui/Icons/MainPage/IconArrowRight';
import { Typography } from '../../../ui/Typography/Typography';

//image
import MainPageImage from '../../../../assets/images/MainPage/MainPage.png';

export const Header = () => {
  const handleClick = () => {
    //TODO added new route
    // navigate('/about');
  };
  const buttonTitle = 'Как это работает';
  return (
    <header className={styles.wrapper}>
      <div className={styles.wrapper__header}>
        <div className={styles.wrapper__header_title}>
          Инструменты
          <div> доказательного образования, доступные каждому</div>
        </div>
        <Typography color="gray" fontWeight={400} size="s" vMargin={23}>
          Создано по инициативе Благотворительного фонда “Вклад в будущее”
        </Typography>
        <Button
          label={buttonTitle}
          type={'button'}
          icon={<IconArrowRight />}
          gradient
          color={'gradient'}
          prefixIcon
          width={228}
          clicked={handleClick}
        />
        <img
          className={styles.wrapper__header_image}
          src={MainPageImage}
          alt="mainPage"
        />
      </div>
    </header>
  );
};
