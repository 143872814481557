import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import { useFormik, FormikContext } from 'formik';
import styles from './DocumentExpert.module.scss';
import {
  useApproveExpertDocumentMutation,
  useGetDocumentMarkupQuery,
  useGetDocumentQuery,
  useGetEventQuery,
  useGetSemanticStructQuery,
} from '../../../../store/VbudusheeAPI';
import {
  Docs,
  DocumentEstimate,
  EventRatingType,
  Markup,
} from '../../../../models/document';

//  ui
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { Button } from '../../../ui/Button';
import { Typography } from '../../../ui/Typography';
import { Box } from '../../../ui/Box';
import { Tooltip } from '../../../ui/Tooltip';
import { DocumentSettingsBlock } from '../DocumentSettingsBlock';
import { StructureMenuItem } from '../StructureMenuItem';
import { DocumentRating } from '../DocumentRating';
import { ContentModal } from '../../../ui/ContentModal';
import { object } from 'yup';
import { DocumentExpertPopupEdit } from './DocumentExpertPopupEdit';
import { ModalDocumentStatus } from '../../../ui/ModalDocumentStatus/ModalDocumentStatus';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '../../../ui/Loader';
import { frontendRoutes } from '../../../../utils/router/routes';
import { IconArrowMenu } from '../../../ui/Icons/Research/IconArrowMenu';
import { SemanticStructure } from '../../../../models/pages/events';
const cx = classNames.bind(styles);

export const DocumentExpert = (): JSX.Element => {
  //TODO mock id document
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isError } = useGetDocumentQuery({ id: id ?? '' });

  const [approveDocument] = useApproveExpertDocumentMutation();

  const { data: semanticStructure } = useGetSemanticStructQuery(
    { id: String(data?.data.event_id) ?? '' },
    { skip: !data?.data.event_id }
  );

  const { data: markup } = useGetDocumentMarkupQuery(
    { id: data?.data.document_id ?? '' },
    { skip: !data?.data.event_id }
  );

  const { data: event } = useGetEventQuery(
    {
      id: String(data?.data.event_id) ?? '',
    },
    { skip: !data?.data.event_id }
  );

  const url = process.env.REACT_APP_API_URL?.slice(0, -3);
  const docs: Docs = {
    file_url: `${url}${data?.data.document_path}`,
    fileType: 'docx',
  };

  const [isModalOpenStatus, setIsModalOpenStatus] = useState(false);
  const [modalTitle] = useState('');
  const [arrMarkup, setArrMarkup] = useState<Markup[]>([]);
  const [structure, setStructure] = useState(-1);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [_, setIsDocumentExpert] = useState(false);
  const [modalStatus, setModalStatus] = useState('');
  const [correctSemanticStructure, setCorrectSemanticStructure] = useState<
    SemanticStructure | undefined
  >(undefined);

  // const [isOpenModalAccess, setIsOpenModalAccess] = useState(false);
  // const [isDocumentExpertAccess, setIsDocumentExpertAccess] = useState(false);

  const [value, setValue] = useState<DocumentEstimate[]>([]);

  const documentStructures = useMemo(() => {
    if (!correctSemanticStructure || !arrMarkup) return [];
    const res = JSON.parse(JSON.stringify(correctSemanticStructure));
    arrMarkup.map((el) => {
      res[el.title].correct = !!el.text;
    });
    return Object.entries(res);
  }, [correctSemanticStructure, arrMarkup]);

  // const onChange = (e: EventRatingType) => {
  //   console.log(e);
  //   if (e.id) {
  //     const res = value.filter((el) => el.id === e.id);
  //     handleAccess(res);
  //   }
  // };

  const elementStructureClick = (label: string, idx: number) => {
    if (structure !== idx) {
      setStructure(idx);
    } else {
      setStructure(-1);
    }
  };

  const filterStructuresMarkup = useMemo(() => {
    if (structure !== -1) {
      return arrMarkup.filter((el, i) => i === structure);
    }
    return arrMarkup;
  }, [structure, arrMarkup]);

  const sum = useMemo(() => {
    let res = 0;
    // eslint-disable-next-line array-callback-return
    value.map((el) => {
      if (el.score) {
        res += el.score;
      }
    });
    return res;
  }, [value]);

  const handleSubmit = async (values: typeof initialValues) => {
    /**
     TODO: search for another solution.
     Maybe with help of isolated iframe or html, or dangerouslySetInnerHtml
     */
  };
  const initialValues = {
    rating: [],
  };
  const documentEditorValidationSchema = object({});
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: documentEditorValidationSchema,
    onSubmit: handleSubmit,
  });

  const handleAccess = async (arr: EventRatingType) => {
    const res = {
      category: arr.id,
      score: arr.scale,
    };

    await approveDocument({
      id: id,
      body: {
        status: data?.data.document_status,
        comment: '',
        estimates: [res],
      },
    });
  };

  const onModalStatusClose = () => {
    setIsModalOpenStatus(false);
  };

  useEffect(() => {
    if (
      isError ||
      !data?.are_you_an_expert ||
      !data?.data.document_analyse?.total
    ) {
      navigate('/404');
    }
  }, [data, isError]);

  useEffect(() => {
    if (markup) {
      setArrMarkup(markup.parts);
    }
  }, [markup]);

  useEffect(() => {
    if (semanticStructure) {
      setCorrectSemanticStructure(semanticStructure);
    }
  }, [semanticStructure]);

  useEffect(() => {
    if (data?.event_estimate && data?.event_estimate.length > 0) {
      setValue(data.event_estimate);
    }
  }, [data]);

  return (
    <>
      {isLoading ? (
        <Loader.FullPage>
          <Loader />
        </Loader.FullPage>
      ) : (
        <FormikContext.Provider value={formik}>
          <div className="details">
            <div className="local-container">
              <Breadcrumbs
                links={[
                  {
                    title: 'Мероприятие',
                    link: `/events`,
                  },
                  { title: 'Информация о документе', link: `/events` },
                  {
                    title: 'Проверка документа',
                  },
                ]}
                homeLink="/"
              />
            </div>
            <div
              className="local-container"
              style={{ marginBottom: '14px', paddingBottom: '0' }}
            >
              <div
                className={styles.saveExit}
                onClick={() =>
                  navigate(`${frontendRoutes.document.inner}/${id}`)
                }
              >
                <div className={styles.arrow}>
                  <IconArrowMenu color="#00809c" />
                </div>
                <div className={styles.saveExitText}>Сохранить и выйти</div>
              </div>
            </div>
            <div className="details__header">
              <Box flex>
                <Typography
                  color="main"
                  size="xxxl"
                  font="SFPRoDisplay"
                  fontWeight={600}
                >
                  Проверка документа
                </Typography>
                <Box mt={8} ml={12}>
                  <Tooltip title="Как проверить документ?" clickable>
                    <div
                      style={{ color: 'rgba(51, 63, 72, 1)' }}
                      className={styles.tooltip}
                    >
                      <div style={{ marginBottom: '16px' }}>
                        Предварительная разметка документа участником помогает
                        ускорить прочтение и проверку работы. Чтобы начать,
                        следуйте шагам в левой части экрана.
                      </div>
                      <ul>
                        <li>
                          Изучите требования к работе и критерии оценивания.
                        </li>
                        <li>
                          Определите, все ли элементы структуры, необходимые для
                          указанного типа документа, присутствуют в работе.
                        </li>
                        <li>
                          {' '}
                          Ознакомьтесь с результатами автоматического анализа,
                          который показывает, насколько содержание работы
                          соответствует концепции развития личностного
                          потенциала.
                        </li>
                        <li>
                          {' '}
                          В случае, если балльная система оценивания не
                          предусмотрена, вы можете оценить работу при помощи
                          кнопок в правом верхнем углу экрана, где “Допустить”
                          свидетельствует о высоком качестве работы и готовности
                          ее принять; “Требует доработки” – о необходимости
                          доработать документ в соответствии с Вашими
                          комментариями и повторно прислать на проверку;
                          “Отклонить” – о низком качестве работы без возможности
                          ее улучшения.
                        </li>
                        <li>
                          В случае, если требуется применить балльную систему,
                          оцените работу по каждому из критериев, а затем
                          нажмите на одну из трех кнопок в правом верхнем углу
                          экрана.
                        </li>
                      </ul>
                    </div>
                  </Tooltip>
                </Box>
              </Box>
              <Box flex>
                <Box mr={16}>
                  <div
                    onClick={() => {
                      setIsOpenModal(true);
                      setModalStatus('rejected');
                    }}
                  >
                    <Button label="Отклонить" color={'gradientDanger'} />
                  </div>
                </Box>
                <Box mr={16}>
                  <div
                    onClick={() => {
                      setIsOpenModal(true);
                      setModalStatus('rework');
                    }}
                  >
                    <Button
                      label="Отправить на доработку"
                      color={'gradientOrange'}
                    />
                  </div>
                </Box>
                <div
                  onClick={() => {
                    setIsOpenModal(true);
                    setModalStatus('approved');
                  }}
                >
                  <Button label="Допустить" gradient={true} />
                </div>
              </Box>
            </div>
            <div className="details__body">
              <Box mb={18}>
                <Typography color="main" fontWeight={700} size="sm">
                  Требования к загрузке документа
                </Typography>
              </Box>
              <div className="row">
                <div className="full-width">
                  <div className="detail__content">
                    <Typography color="main" size="sm">
                      {event?.data.event_document_requirements
                        ? event.data.event_document_requirements
                        : 'Требования не установлены'}
                    </Typography>
                  </div>
                </div>
              </div>
              <Box mb={26} />
              <div className="details__divider" />
              <div className={styles.editor}>
                <div className={styles.panelWrapper}>
                  <>
                    <DocumentSettingsBlock
                      title="Проверьте структурные элементы"
                      height={147}
                      stepNumber={1}
                    >
                      <>
                        {documentStructures.map(
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          ([label, { correct, required }], idx) => {
                            return (
                              <StructureMenuItem
                                key={idx}
                                label={label}
                                required={required}
                                isActive={correct}
                                onClick={() => {
                                  elementStructureClick(label, idx);
                                }}
                              />
                            );
                          }
                        )}
                      </>
                    </DocumentSettingsBlock>

                    <DocumentSettingsBlock
                      title={`Ознакомьтесь с результатами \n автоматического анализа`}
                      stepNumber={2}
                    >
                      <div className={styles.neuroAnalysis}>
                        <div className={styles.neuroAnalysis__title}>
                          Статус
                        </div>
                        <div
                          className={cx('neuroAnalysis__status', {
                            neuroAnalysis__status_true:
                              data?.data.document_analyse?.total?.status === 0,
                          })}
                        >
                          {data?.data.document_analyse?.total?.status === 0
                            ? 'Содержание работы соответствует заявленной ее автором теме'
                            : 'Содержание работы не соответствует заявленной ее автором теме'}
                        </div>
                        <div
                          className={styles.neuroAnalysis__detailed}
                          onClick={() =>
                            navigate(
                              `${frontendRoutes.document.automaticAnalysisResults}/${id}`
                            )
                          }
                        >
                          Смотреть подробнее
                        </div>
                      </div>
                    </DocumentSettingsBlock>

                    {/*grading block*/}
                    {data?.event_estimate && data?.event_estimate.length > 0 && (
                      <>
                        <div className={styles.gradingBlock}>
                          <Typography color="main" size="sm" fontWeight={700}>
                            Оцените работу участника
                          </Typography>
                          <div className={styles.gradingBlock__description}>
                            <Typography
                              color="prGreen"
                              size="s"
                              fontWeight={500}
                              customLineHeight={18}
                            >
                              Выберите оценку, где 0 - наименьшее соответствие
                              критерию, а 10 - наибольшее
                            </Typography>
                          </div>
                        </div>
                        <div>
                          {event?.data.event_estimate_on &&
                            data?.event_estimate.map((el, i) => (
                              <DocumentRating
                                key={el.id}
                                id={el.id}
                                name={'rating'}
                                withRating={true}
                                number={i + 1}
                                description={el.category_name}
                                value={el.score ?? 0}
                                onChange={handleAccess}
                              />
                            ))}
                        </div>
                        <Typography color="main" size="sm" fontWeight={700}>
                          Итоговая оценка
                        </Typography>
                        <div className={styles.finalGrade}>
                          <Typography
                            color="main"
                            size="sm"
                            fontWeight={400}
                            customLineHeight={22}
                          >
                            {`${sum === 0 ? 0 : sum}`}
                          </Typography>
                        </div>
                      </>
                    )}
                    {/*grading block*/}
                  </>
                </div>
                <div className={styles.editor__docx}>
                  <b style={{ marginBottom: '12px' }}>Название документа</b>
                  <div style={{ marginBottom: '16px' }}>
                    {data?.data?.document_name
                      ? data.data.document_name.split('.')[0]
                      : 'Нет данных'}
                  </div>
                  <div className={styles.markupContainer}>
                    {filterStructuresMarkup.map((el, i) => (
                      <div key={i} className={styles.markupSection}>
                        <b className={styles.markupTitle}>{el.title}</b>
                        <div className={styles.markupText}>{el.text}</div>
                        <b
                          style={{
                            marginBottom: '10px',
                            height: '26px',
                            display: 'block',
                          }}
                        >
                          Тематические теги
                        </b>
                        <div className={styles.markupTags}>
                          {el.tags.map((tag, y) => {
                            if (el.fullTags || y < 8) {
                              return (
                                <div key={y} className={styles.markupTag}>
                                  {tag}
                                </div>
                              );
                            }
                          })}
                          {el.tags.length > 7 && (
                            <div
                              className={styles.buttonOverlayTags}
                              onClick={() =>
                                setArrMarkup(
                                  arrMarkup.map((el, index) => {
                                    if (index === i) {
                                      return {
                                        ...el,
                                        fullTags: !el.fullTags,
                                      };
                                    } else {
                                      return el;
                                    }
                                  })
                                )
                              }
                            >
                              {el.fullTags ? 'Свернуть' : 'Все теги'}
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {isModalOpenStatus && (
                  <ModalDocumentStatus
                    data={data}
                    docs={docs}
                    title={modalTitle}
                    onClose={onModalStatusClose}
                  />
                )}
              </div>
            </div>
          </div>
          <ContentModal
            isOpen={isOpenModal}
            titleText={
              'Здесь Вы можете дать обратную связь ' +
              '\r\n' +
              'участнику о качестве его работы. Укажите' +
              '\r\n' +
              'сильные стороны, а также то, что он мог бы улучшить.'
            }
            onClose={() => setIsOpenModal(false)}
          >
            <DocumentExpertPopupEdit
              openPopup={setIsDocumentExpert}
              setModalOpen={setIsOpenModal}
              documentId={data?.data.document_id}
              documentStatus={modalStatus}
              setModalStatus={setModalStatus}
              routId={data?.data.event_id}
            />
          </ContentModal>
        </FormikContext.Provider>
      )}
    </>
  );
};
