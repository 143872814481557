import React from 'react';
import styles from './Support.module.scss';
import { Button } from '../../../../ui/Button';
import Background from '../../../../../assets/images/MainPage/registration/Block.png';
import { useNavigate } from 'react-router-dom';
import { frontendRoutes } from '../../../../../utils/router/routes';

export const Support = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    //TODO fixed route
    // navigate('/support');
  };
  return (
    <section className={styles.support}>
      <div className={styles.support__block}>
        <div className={styles.info}>
          <div className={styles.info__title}>Техподдержка</div>
          <div className={styles.info__subTitle}>
            Готовы помочь, если возникли трудности
          </div>
        </div>
        <div className={styles.info__submit} onClick={handleClick}>
          <Button
            label="Обратиться"
            gradient
            size={'large'}
            clicked={() => {
              const a = document.createElement('a');
              a.href = 'mailto:research@vbudushee.ru';
              a.click();
              a.remove();
            }}
          />
        </div>
      </div>
    </section>
  );
};
