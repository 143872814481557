import React, { useMemo, useState } from 'react';
import { useField } from 'formik';
import classNames from 'classnames/bind';

import styles from './Textarea.module.scss';
const cx = classNames.bind(styles);

type TextareaProps = {
  height?: number;
  name: string;
  placeholder?: string;
  required?: boolean;
  editable?: boolean;
  maxLength?: number;
  redRequired?: boolean;
};

export const Textarea = ({
  name,
  height = 100,
  placeholder,
  editable = true,
  required = true,
  maxLength,
  redRequired,
}: TextareaProps) => {
  const [field, { error, touched }] = useField<string>(name);

  const isInvalid = useMemo(() => {
    return Boolean(error && touched);
  }, [error, touched]);

  return (
    <div
      className={cx('textarea', {
        textarea_invalid: isInvalid && required,
      })}
    >
      <textarea
        {...field}
        placeholder={!redRequired ? placeholder : ''}
        disabled={!editable}
        style={{ height }}
        maxLength={maxLength}
        className={cx({
          textarea_disabled: !editable,
        })}
      />
      {!field.value && redRequired && (
        <span className={styles.textarea__required}>
          {placeholder?.slice(0, placeholder.length - 1)}
          <span className={styles.textarea__required__sign}>
            {placeholder?.slice(placeholder.length - 1, placeholder.length)}
          </span>
        </span>
      )}
      {isInvalid && required && (
        <div className={cx('textarea__error')}>{error}</div>
      )}
    </div>
  );
};
