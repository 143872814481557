import { useState, useMemo, useRef } from 'react';
import classNames from 'classnames/bind';
import { useField } from 'formik';

import { SelectOption } from '../../../../models/basic';
import { useClickOutside } from '../../../../hooks/useClickOutside';

//  ui
import { IconArrow } from '../../Icons/IconArrow';
import { Loader } from '../../Loader';

import styles from './Select.module.scss';
const cx = classNames.bind(styles);

type SelectTypes = {
  name: string;
  label: string;
  options: SelectOption<string | number>[];
  short?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  isOptionsLoading?: boolean;
  required?: boolean;
  redRequired?: boolean;
};

export const Select = ({
  name,
  label,
  options,
  short,
  disabled,
  fullWidth,
  isOptionsLoading,
  required = true,
  redRequired,
}: SelectTypes) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [field, { error, touched }, { setValue }] = useField<string | number>(
    name
  );
  const selectRef = useRef<HTMLDivElement>(null);
  useClickOutside([selectRef], () => {
    setIsExpanded(false);
  });

  const isInvalid = useMemo(() => {
    return Boolean(error) && touched;
  }, [error, touched]);

  const handleValueChange = (value: string | number): void => {
    setValue(value, true);
  };

  const getFieldLabel = useMemo(() => {
    if (!isOptionsLoading) {
      const updatedLabel = !redRequired ? (
        label
      ) : (
        <>
          {label.slice(0, label.length - 1)}
          <span style={{ color: 'red' }}>
            {label.slice(label.length - 1, label.length)}
          </span>
        </>
      );

      if (field.value === undefined || field.value === '') {
        return updatedLabel;
      } else {
        const newLabel = options.find(
          (option) => option?.value === field.value
        )?.value;
        return newLabel ?? updatedLabel;
      }
    } else {
      return '...';
    }
  }, [options, field.value]);

  const getFieldValue = (key: string | number): string => {
    const foundValue = options.find((option) => option?.key === key)
      ?.value as string;
    return foundValue ? foundValue : label;
  };

  return (
    <div
      className={cx('wrapper', {
        wrapper_fullwidth: fullWidth,
      })}
    >
      <div
        className={cx('select', {
          select_invalid: isInvalid,
          select_short: short,
          select_disabled: disabled,
          select_fullwidth: fullWidth,
        })}
        onClick={() => !disabled && setIsExpanded(!isExpanded)}
        ref={selectRef}
      >
        <div
          className={cx('select__value', {
            select__value_active: options.find(
              (option) => option?.key === field.value
            ),
          })}
          title={field.value ? getFieldValue(field.value) : ''}
        >
          {/* 
            field.value === options[i].key 
            So, in order to define value we must loop through the options array
            and find value by the key (field.value)
          */}
          {field.value ? getFieldValue(field.value) : getFieldLabel}
        </div>
        <div
          className={cx('select__arrow', {
            select__arrow_expanded: isExpanded,
          })}
        >
          <IconArrow />
        </div>

        <div
          className={cx('select__options', {
            select__options_expanded: isExpanded,
          })}
        >
          {isOptionsLoading ? (
            <Loader.FullPage>
              <Loader />
            </Loader.FullPage>
          ) : (
            options.map((option) => (
              <div
                className={cx('select__option', {
                  select__option_expanded: isExpanded,
                })}
                key={option?.key}
                onClick={() =>
                  handleValueChange(option?.key ? option?.key : '')
                }
              >
                <p className={cx('select__option-text')} title={option?.value}>
                  {option?.value}
                </p>
              </div>
            ))
          )}
        </div>
      </div>
      {isInvalid && required && (
        <div className={cx('select__error')}>{error}</div>
      )}
    </div>
  );
};
