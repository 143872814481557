export const IconLogoFooter = () => {
  return (
    <svg
      width="228"
      height="75"
      viewBox="0 0 228 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.7036 59.171L45.4117 72.4717C44.6555 73.2283 43.4287 73.2283 42.6725 72.4717L29.3806 59.171L44.0421 44.4998L58.7036 59.171Z"
        fill="#1F853F"
      />
      <path
        d="M28.9498 29.4003L14.2881 44.0718L28.9498 58.7432L43.6115 44.0718L28.9498 29.4003Z"
        fill="#1E6055"
      />
      <path
        d="M28.5214 28.9687L13.8599 43.6399L0.56797 30.3422C-0.188184 29.5856 -0.188184 28.3579 0.56797 27.6013L13.8599 14.3005L28.5214 28.9687Z"
        fill="#1F6B46"
      />
      <path
        d="M59.1317 29.4013L44.47 44.0728L59.1317 58.7442L73.7934 44.0728L59.1317 29.4013Z"
        fill="#43AC3C"
      />
      <path
        d="M44.0386 14.3002L29.3768 28.9717L44.0386 43.6431L58.7003 28.9717L44.0386 14.3002Z"
        fill="#20A038"
      />
      <path
        d="M43.6141 13.8689L28.9496 28.5401L14.2881 13.8689L27.58 0.568224C28.3361 -0.188431 29.563 -0.188431 30.3191 0.568224L43.6141 13.8689Z"
        fill="#F7E81F"
      />
      <path
        d="M87.5134 30.3422L74.2215 43.6429L59.5601 28.9717L74.2215 14.3005L87.5134 27.6013C88.2696 28.3549 88.2696 29.5825 87.5134 30.3422Z"
        fill="#43AC3C"
      />
      <path
        d="M73.7933 13.8689L59.1318 28.5401L44.4703 13.8689L57.7592 0.568224C58.5154 -0.188431 59.7422 -0.188431 60.4984 0.568224L73.7933 13.8689Z"
        fill="#65B444"
      />
      <path
        d="M104.79 22.423V4.51855H112.561C116.581 4.51855 118.592 6.00148 118.592 8.97036C118.592 9.99443 118.379 10.8453 117.954 11.529C117.526 12.2097 116.855 12.7324 115.935 13.0909C117.043 13.2793 117.854 13.7169 118.364 14.4067C118.874 15.0965 119.132 16.0325 119.132 17.2085C119.132 18.1292 118.968 18.9223 118.646 19.5878C118.321 20.2533 117.857 20.7942 117.253 21.2136C116.648 21.6329 115.913 21.9398 115.054 22.1343C114.195 22.3318 113.217 22.4291 112.126 22.4291H104.79V22.423ZM112.099 12.0912C113.123 12.0912 113.927 11.8785 114.516 11.453C115.106 11.0276 115.397 10.429 115.397 9.6632C115.397 8.94605 115.145 8.40211 114.644 8.0253C114.14 7.65153 113.32 7.46313 112.178 7.46313H107.984V12.0942H112.099V12.0912ZM112.33 19.4815C113.557 19.4815 114.462 19.2809 115.039 18.8798C115.619 18.4787 115.907 17.8436 115.907 16.9745C115.907 16.1054 115.601 15.4916 114.987 15.133C114.374 14.7744 113.505 14.5951 112.379 14.5951H107.981V19.4815H112.33Z"
        fill="#333F48"
      />
      <path
        d="M122.248 9.63306H125.239V14.6227H127.31L130.89 9.63306H134.213L129.739 15.6711L134.926 22.4233H131.297L127.286 17.1297H125.239V22.4233H122.248V9.63306Z"
        fill="#333F48"
      />
      <path
        d="M145.768 12.2676H141.625L141.55 13.8781C141.464 15.8746 141.288 17.4517 141.027 18.6095C140.763 19.7703 140.435 20.6424 140.043 21.232C139.652 21.8215 139.217 22.1922 138.741 22.3441C138.264 22.4991 137.778 22.5751 137.283 22.5751C137.043 22.5751 136.809 22.5629 136.578 22.5356C136.348 22.5113 136.165 22.4809 136.029 22.4444V19.354C136.232 19.4239 136.454 19.4573 136.694 19.4573C136.967 19.4573 137.225 19.4026 137.474 19.2902C137.72 19.1808 137.939 18.8951 138.127 18.4332C138.315 17.9713 138.473 17.2724 138.601 16.3365C138.728 15.3975 138.819 14.0939 138.868 12.4225L138.971 9.63599H148.738V22.4262H145.774V12.2676H145.768Z"
        fill="#333F48"
      />
      <path
        d="M152.695 10.5292C153.12 10.2041 153.712 9.93365 154.471 9.70878C155.23 9.48695 156.163 9.37451 157.271 9.37451C159.145 9.37451 160.542 9.72397 161.462 10.4229C162.382 11.1218 162.84 12.3069 162.84 13.9783V22.42H160.028V20.1166C159.722 20.8672 159.199 21.4719 158.458 21.9338C157.717 22.3957 156.834 22.6236 155.813 22.6236C154.517 22.6236 153.508 22.2772 152.783 21.5873C152.057 20.8975 151.695 19.9221 151.695 18.658C151.695 17.9256 151.823 17.3209 152.078 16.8408C152.333 16.3637 152.691 15.9838 153.153 15.7012C153.612 15.4217 154.167 15.2211 154.814 15.0996C155.461 14.9811 156.178 14.9203 156.961 14.9203H159.849V14.3611C159.849 13.4647 159.63 12.8478 159.196 12.5105C158.762 12.1732 158.118 12.0031 157.265 12.0031C156.09 12.0031 155.142 12.2097 154.429 12.623C153.712 13.0362 153.132 13.5832 152.691 14.2578V10.5292H152.695ZM156.758 19.9434C157.268 19.9434 157.708 19.8796 158.073 19.7519C158.44 19.6243 158.75 19.4632 159.005 19.2657C159.26 19.0712 159.457 18.8525 159.594 18.6124C159.731 18.3723 159.816 18.1444 159.849 17.9226V16.8742H157.32C156.433 16.8742 155.777 16.9775 155.352 17.1811C154.927 17.3847 154.714 17.7798 154.714 18.3571C154.714 18.8525 154.887 19.2384 155.236 19.521C155.586 19.8036 156.093 19.9434 156.758 19.9434Z"
        fill="#333F48"
      />
      <path
        d="M166.345 19.7127C166.515 19.2508 166.673 18.7463 166.818 18.1902C166.964 17.6372 167.086 17.0082 167.189 16.3092C167.292 15.6103 167.368 14.8294 167.42 13.9694C167.471 13.1094 167.496 12.1492 167.496 11.0917V9.63306H177.31V19.7127H179.254V25.7507H176.545V22.4233H167.571V25.7477H164.863V19.7127H166.345ZM170.28 12.5776C170.247 14.0606 170.153 15.3703 169.998 16.5037C169.843 17.6372 169.588 18.7068 169.23 19.7127H174.316V12.2677H170.277V12.5776H170.28Z"
        fill="#333F48"
      />
      <path
        d="M104.279 33.6272H110.72C112.254 33.6272 113.344 33.8794 113.994 34.3808C114.641 34.8822 114.966 35.6206 114.966 36.593C114.966 37.3588 114.774 37.997 114.392 38.4984C114.009 39.0028 113.465 39.3371 112.767 39.5103C113.177 39.5619 113.557 39.65 113.906 39.7777C114.255 39.9053 114.553 40.0907 114.802 40.3277C115.048 40.5678 115.245 40.8747 115.391 41.2484C115.537 41.6222 115.609 42.0841 115.609 42.6311C115.609 43.8587 115.206 44.7977 114.395 45.445C113.584 46.0923 112.336 46.4174 110.65 46.4174H104.285V33.6272H104.279ZM110.004 38.7931C110.602 38.7931 111.079 38.6868 111.437 38.4771C111.795 38.2644 111.974 37.8906 111.974 37.3497C111.974 36.8756 111.813 36.5566 111.489 36.3864C111.164 36.2162 110.678 36.1342 110.031 36.1342L107.27 36.1585V38.7931H110.004ZM110.362 43.9104C111.164 43.9104 111.741 43.7889 112.099 43.5427C112.457 43.2966 112.636 42.9228 112.636 42.4123C112.636 41.8532 112.463 41.4703 112.114 41.2576C111.765 41.0448 111.24 40.9415 110.541 40.9415H107.27V43.9074H110.362V43.9104Z"
        fill="#333F48"
      />
      <path
        d="M136.256 29.6614C136.001 29.7982 135.524 29.9258 134.826 30.0443C134.128 30.1628 133.25 30.2661 132.193 30.3512C131.17 30.4545 130.332 30.6338 129.676 30.8891C129.02 31.1443 128.494 31.4999 128.103 31.9496C127.711 32.3994 127.425 32.9585 127.246 33.6209C127.067 34.2834 126.937 35.0765 126.848 35.9942C127.343 35.2801 127.987 34.7088 128.78 34.2743C129.572 33.8397 130.547 33.624 131.707 33.624C132.491 33.624 133.232 33.7303 133.93 33.9431C134.629 34.1558 135.239 34.5052 135.758 34.9914C136.278 35.4776 136.691 36.1158 136.997 36.9089C137.304 37.702 137.456 38.6775 137.456 39.8383C137.456 42.1234 136.891 43.8343 135.755 44.9677C134.623 46.1012 133.04 46.6694 131.015 46.6694C128.831 46.6694 127.158 45.9918 125.992 44.6365C124.823 43.2812 124.24 41.1267 124.24 38.1761C124.24 36.4197 124.364 34.9124 124.61 33.6483C124.856 32.3872 125.242 31.3327 125.761 30.488C126.281 29.6432 126.937 28.9929 127.729 28.531C128.522 28.0721 129.472 27.747 130.581 27.5586C131.076 27.4735 131.598 27.4006 132.154 27.3398C132.706 27.279 133.244 27.2152 133.763 27.1484C134.282 27.0815 134.762 27.0025 135.209 26.9174C135.652 26.8323 136.001 26.7229 136.256 26.5862V29.6614ZM127.31 39.8413C127.31 41.0416 127.605 42.0292 128.191 42.8011C128.78 43.5729 129.651 43.9589 130.811 43.9589C131.322 43.9589 131.792 43.8859 132.217 43.7401C132.643 43.5942 133.013 43.3663 133.329 43.0594C133.645 42.7525 133.891 42.3544 134.07 41.8651C134.249 41.3789 134.337 40.7833 134.337 40.0814C134.337 38.7474 134.04 37.7749 133.441 37.1672C132.846 36.5594 132.011 36.2556 130.936 36.2556C129.812 36.2556 128.926 36.5534 128.279 37.152C127.632 37.7506 127.31 38.6501 127.31 39.8413Z"
        fill="#333F48"
      />
      <path
        d="M138.379 33.6272H141.729L145.23 42.4001L148.094 33.6272H151.264L146.153 47.7727C145.862 48.5385 145.573 49.1675 145.285 49.6537C144.993 50.1399 144.692 50.5228 144.377 50.8054C144.061 51.088 143.724 51.2794 143.368 51.3797C143.01 51.483 142.618 51.5347 142.193 51.5347C141.801 51.5347 141.41 51.483 141.018 51.3797C140.626 51.2764 140.335 51.1397 140.149 50.9695V48.1829C140.335 48.3713 140.565 48.5233 140.839 48.6448C141.112 48.7633 141.391 48.8241 141.683 48.8241C142.145 48.8241 142.548 48.6479 142.898 48.2984C143.247 47.9489 143.542 47.3381 143.781 46.4691L138.379 33.6272Z"
        fill="#333F48"
      />
      <path
        d="M152.795 43.7036C152.965 43.2448 153.123 42.7373 153.268 42.1812C153.414 41.6282 153.539 40.9991 153.639 40.3002C153.742 39.6013 153.818 38.8203 153.87 37.9604C153.921 37.1004 153.945 36.1401 153.945 35.0826V33.624H163.76V43.7036H165.704V49.7417H162.995V46.4173H154.021V49.7417H151.313V43.7036H152.795ZM156.733 36.5686C156.7 38.0515 156.606 39.3612 156.451 40.4947C156.296 41.6282 156.041 42.6978 155.683 43.7036H160.769V36.2586H156.73V36.5686H156.733Z"
        fill="#333F48"
      />
      <path
        d="M166.217 33.6272H169.567L173.068 42.4001L175.932 33.6272H179.102L173.991 47.7727C173.703 48.5385 173.411 49.1675 173.123 49.6537C172.831 50.1399 172.53 50.5228 172.215 50.8054C171.899 51.088 171.562 51.2794 171.206 51.3797C170.848 51.483 170.456 51.5347 170.031 51.5347C169.639 51.5347 169.248 51.483 168.856 51.3797C168.464 51.2764 168.173 51.1397 167.987 50.9695V48.1829C168.176 48.3713 168.404 48.5233 168.677 48.6448C168.95 48.7633 169.23 48.8241 169.521 48.8241C169.983 48.8241 170.387 48.6479 170.736 48.2984C171.085 47.9489 171.38 47.3381 171.616 46.4691L166.217 33.6272Z"
        fill="#333F48"
      />
      <path
        d="M181.067 33.6272H184.058V43.7038H187.994V33.6272H190.961V43.7038H194.896V33.6272H197.888V43.7038H199.755V49.7418H197.071V46.4174H181.067V33.6272Z"
        fill="#333F48"
      />
      <path
        d="M212.637 45.1379C212.482 45.3263 212.264 45.5086 211.972 45.6879C211.681 45.8672 211.334 46.0343 210.924 46.1862C210.518 46.3412 210.059 46.4597 209.558 46.5448C209.054 46.6299 208.513 46.6724 207.936 46.6724C205.804 46.6876 204.152 46.1315 202.977 44.9981C201.802 43.8646 201.213 42.1902 201.213 39.9719C201.213 38.9144 201.371 37.9724 201.687 37.1459C202.002 36.3193 202.449 35.6235 203.029 35.0613C203.609 34.4991 204.295 34.0737 205.085 33.782C205.877 33.4933 206.758 33.3474 207.73 33.3474C209.585 33.3656 211.04 33.9187 212.088 35.0096C213.135 36.1005 213.661 37.6534 213.661 39.665V40.8927H204.177C204.414 43.0259 205.701 44.0895 208.036 44.0895C209.212 44.0895 210.187 43.892 210.964 43.5C211.738 43.108 212.297 42.6309 212.637 42.0687V45.1379ZM207.654 35.979C206.682 35.979 205.908 36.216 205.328 36.6901C204.748 37.1641 204.383 37.857 204.228 38.7686H210.669C210.636 37.9056 210.354 37.2279 209.825 36.7296C209.297 36.2282 208.571 35.979 207.654 35.979Z"
        fill="#333F48"
      />
      <path
        d="M226.977 45.1379C226.822 45.3263 226.603 45.5086 226.312 45.6879C226.02 45.8672 225.674 46.0343 225.264 46.1862C224.857 46.3412 224.399 46.4597 223.897 46.5448C223.393 46.6299 222.853 46.6724 222.273 46.6724C220.141 46.6876 218.489 46.1315 217.314 44.9981C216.139 43.8646 215.549 42.1902 215.549 39.9719C215.549 38.9144 215.707 37.9724 216.023 37.1459C216.339 36.3193 216.785 35.6235 217.365 35.0613C217.945 34.4991 218.632 34.0737 219.421 33.782C220.214 33.4933 221.095 33.3474 222.066 33.3474C223.922 33.3656 225.376 33.9187 226.424 35.0096C227.472 36.1005 227.997 37.6534 227.997 39.665V40.8927H218.513C218.753 43.0259 220.038 44.0895 222.373 44.0895C223.548 44.0895 224.523 43.892 225.3 43.5C226.075 43.108 226.634 42.6309 226.974 42.0687V45.1379H226.977ZM221.993 35.979C221.022 35.979 220.247 36.216 219.667 36.6901C219.087 37.1641 218.723 37.857 218.568 38.7686H225.009C224.976 37.9056 224.693 37.2279 224.165 36.7296C223.636 36.2282 222.914 35.979 221.993 35.979Z"
        fill="#333F48"
      />
      <path
        d="M140.983 64.5726L142.699 63.3025H136.958V70.9293H142.699V69.6592H138.596V67.7069H142.095V66.4368H138.596V64.5726H140.983Z"
        fill="#21A038"
      />
      <path
        d="M132.624 66.2756H130.63V64.5726H133.813L135.528 63.3025H128.992V70.9293H132.413C134.332 70.9293 135.432 70.0559 135.432 68.5335C135.432 67.0773 134.435 66.2756 132.624 66.2756ZM132.339 69.6592H130.63V67.5451H132.338C133.378 67.5451 133.862 67.899 133.862 68.6022C133.863 69.3208 133.35 69.6592 132.339 69.6592Z"
        fill="#21A038"
      />
      <path
        d="M147.35 63.3025H144.224V70.9293H145.863V68.7689H147.35C149.345 68.7689 150.584 67.7153 150.584 66.0317C150.584 64.3486 149.345 63.3025 147.35 63.3025ZM147.315 67.4989H145.863V64.5726H147.315C148.365 64.5726 148.943 65.0921 148.943 66.0357C148.943 66.9793 148.365 67.4989 147.315 67.4989Z"
        fill="#21A038"
      />
      <path
        d="M126.179 69.2777C125.758 69.5107 125.269 69.6336 124.764 69.6336C123.287 69.6336 122.215 68.5745 122.215 67.1154C122.215 65.6562 123.287 64.5971 124.764 64.5971C125.323 64.5971 125.824 64.7549 126.254 65.0654L127.43 64.1955L127.352 64.1268C126.666 63.5212 125.749 63.2012 124.701 63.2012C123.563 63.2012 122.531 63.5864 121.795 64.2856C121.053 64.9893 120.646 65.9787 120.646 67.0726C120.646 68.1714 121.052 69.1757 121.791 69.9008C122.531 70.6279 123.561 71.0286 124.69 71.0286C125.871 71.0286 126.903 70.615 127.602 69.8625C127.062 69.4634 126.541 69.0777 126.541 69.0777L126.179 69.2777Z"
        fill="#21A038"
      />
      <path
        d="M115.534 63.2649C115.834 63.6579 116.087 64.0849 116.29 64.5373L110.542 68.8344L108.14 67.3072V65.47L110.542 66.9971L115.534 63.2649Z"
        fill="#21A038"
      />
      <path
        d="M105.688 67.1777C105.688 67.0953 105.69 67.0134 105.694 66.932L104.238 66.8596C104.233 66.9651 104.23 67.0717 104.23 67.1792C104.229 68.0194 104.392 68.8514 104.71 69.6275C105.027 70.4036 105.492 71.1086 106.078 71.702L107.111 70.6552C106.66 70.1992 106.302 69.6573 106.057 69.0605C105.813 68.4637 105.688 67.8239 105.688 67.1777Z"
        fill="url(#paint0_linear_1983_45695)"
      />
      <path
        d="M110.54 62.2562C110.622 62.2562 110.702 62.2593 110.783 62.2633L110.856 60.7864C110.751 60.7813 110.646 60.7788 110.54 60.7788C109.712 60.7778 108.891 60.9428 108.125 61.2644C107.359 61.586 106.664 62.0578 106.078 62.6527L107.111 63.7C107.561 63.242 108.095 62.8787 108.684 62.631C109.272 62.3832 109.903 62.2558 110.54 62.2562Z"
        fill="url(#paint1_linear_1983_45695)"
      />
      <path
        d="M110.541 72.099C110.459 72.099 110.378 72.099 110.298 72.0925L110.225 73.5689C110.33 73.5743 110.435 73.577 110.541 73.577C111.369 73.5778 112.189 73.4127 112.955 73.091C113.72 72.7694 114.415 72.2975 115 71.7025L113.97 70.6558C113.52 71.1135 112.985 71.4766 112.397 71.7243C111.809 71.972 111.178 72.0993 110.541 72.099Z"
        fill="url(#paint2_linear_1983_45695)"
      />
      <path
        d="M113.276 63.1151L114.503 62.198C113.382 61.2771 111.982 60.7758 110.539 60.7783V62.2563C111.516 62.255 112.47 62.5545 113.276 63.1151Z"
        fill="url(#paint3_linear_1983_45695)"
      />
      <path
        d="M116.851 67.1775C116.852 66.793 116.818 66.4092 116.752 66.0308L115.394 67.0459C115.394 67.0896 115.394 67.1333 115.394 67.1775C115.394 67.8651 115.252 68.5451 114.976 69.1734C114.701 69.8018 114.298 70.3644 113.795 70.8249L114.775 71.9229C115.429 71.3236 115.952 70.5915 116.31 69.774C116.668 68.9565 116.852 68.0719 116.851 67.1775Z"
        fill="#21A038"
      />
      <path
        d="M110.541 72.099C109.862 72.0992 109.192 71.955 108.572 71.6757C107.953 71.3965 107.398 70.9884 106.944 70.4778L105.861 71.4708C106.452 72.1347 107.174 72.6653 107.98 73.0282C108.786 73.391 109.659 73.5779 110.541 73.5769V72.099Z"
        fill="url(#paint4_linear_1983_45695)"
      />
      <path
        d="M107.287 63.5301L106.307 62.4321C105.653 63.0313 105.129 63.7634 104.771 64.5809C104.414 65.3984 104.229 66.2831 104.23 67.1775H105.688C105.688 66.4899 105.83 65.8099 106.105 65.1816C106.381 64.5533 106.783 63.9907 107.287 63.5301Z"
        fill="url(#paint5_linear_1983_45695)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1983_45695"
          x1="106.373"
          y1="71.5175"
          x2="104.74"
          y2="66.8627"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.14" stopColor="#F1E813" />
          <stop offset="0.3" stopColor="#E6E418" />
          <stop offset="0.58" stopColor="#C9DA26" />
          <stop offset="0.89" stopColor="#A2CC39" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1983_45695"
          x1="106.494"
          y1="62.914"
          x2="110.611"
          y2="61.367"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#0FA7DF" />
          <stop offset="0.54" stopColor="#0098F8" />
          <stop offset="0.92" stopColor="#0290EA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1983_45695"
          x1="110.129"
          y1="72.685"
          x2="114.774"
          y2="71.6501"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12" stopColor="#A2CC39" />
          <stop offset="0.28" stopColor="#86C239" />
          <stop offset="0.87" stopColor="#219F38" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1983_45695"
          x1="110.236"
          y1="61.2733"
          x2="114.211"
          y2="62.4831"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#0290EA" />
          <stop offset="0.79" stopColor="#0C89CA" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1983_45695"
          x1="106.227"
          y1="71.2909"
          x2="110.559"
          y2="72.8872"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.13" stopColor="#F1E813" />
          <stop offset="0.3" stopColor="#EAE616" />
          <stop offset="0.53" stopColor="#D8DF1F" />
          <stop offset="0.8" stopColor="#BAD52D" />
          <stop offset="0.98" stopColor="#A2CC39" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_1983_45695"
          x1="104.773"
          y1="67.3564"
          x2="106.549"
          y2="62.8016"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stopColor="#A2CC39" />
          <stop offset="0.26" stopColor="#81C45E" />
          <stop offset="0.92" stopColor="#0FA7DF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
