import React, { ReactElement } from 'react';
import classNames from 'classnames/bind';
import { IconCross } from '../Icons/IconCross';
import { Popup } from '../Popup';
import styles from './ContentModal.module.scss';

const cx = classNames.bind(styles);

type ContentModalTypes = {
  isOpen?: boolean;
  titleText?: string;
  children: ReactElement;
  onClose: () => void;
  width?: 'auto' | 'xl';
};

export const ContentModal = ({
  isOpen,
  titleText = '',
  children,
  onClose,
  width = 'auto',
}: ContentModalTypes) => {
  return (
    <Popup onClose={onClose} isOpen={isOpen}>
      <div className={cx(styles.modal, { xl: width === 'xl' })}>
        <div className={styles.modal__close} onClick={onClose}>
          <IconCross />
        </div>
        <div className={styles.modal__header}>{titleText}</div>
        <>{children}</>
      </div>
    </Popup>
  );
};
