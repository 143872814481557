import 'antd/dist/antd.min.css';
import { Checkbox, FormItem } from 'formik-antd';

import { useCallback, useEffect, useState } from 'react';
import './index.css';

import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useField } from 'formik';
const CheckboxGroup = Checkbox.Group;

type CheckboxSurveyProps = {
  values: number[];
  name: string;
  disabled?: boolean;
  required?: boolean;
};

export const CheckboxSurvey = (props: CheckboxSurveyProps) => {
  const { values, name, disabled, required = true } = props;
  // const [value, setValue] = useState<CheckboxValueType[]>([]);
  const [field, { error, touched }, { setValue, setError, setTouched }] =
    useField<CheckboxValueType[]>(name || '');

  const handleChange = useCallback((checkedValues: CheckboxValueType[]) => {
    setValue(checkedValues);
  }, []);

  useEffect(() => {
    if (!required) {
      setTouched(false);
      setError('');
    }
  }, [error, touched]);

  return (
    <FormItem name={name}>
      <CheckboxGroup
        name={name}
        options={values}
        value={field.value}
        onChange={handleChange}
        disabled={disabled}
      />
    </FormItem>
  );
};
