import React from 'react';
import { Button } from '../Button';
import { IconCross } from '../Icons/IconCross';
import { Popup } from '../Popup';
import styles from './InfoModal.module.scss';

type InfoModalTypes = {
  isOpen?: boolean;
  titleText: string;
  message: string;
  email?: string;
  buttonText?: string;
  onAccept: () => void;
  onReject: () => void;
};

export const InfoModal = ({
  isOpen,
  titleText,
  message,
  email,
  buttonText,
  onAccept,
  onReject,
}: InfoModalTypes) => {
  return (
    <Popup onClose={onReject} isOpen={isOpen}>
      <div className={styles.modal}>
        <div className={styles.modal__close} onClick={onReject}>
          <IconCross />
        </div>
        <div className={styles.modal__header}>{titleText}</div>
        <div className={styles.modal__text}>
          {message}
          <span> {email}</span>
        </div>
        <div onClick={onAccept}>
          <Button label={buttonText ?? 'Ок'} gradient={true} />
        </div>
      </div>
    </Popup>
  );
};
