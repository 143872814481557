import React, { useEffect, useState } from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

import { useUserResetPasswordMutation } from '../../../../store/VbudusheeAPI';
import { frontendRoutes } from '../../../../utils/router/routes';
import { VALIDATION_ERRORS } from '../../../../models/validations/errors/errors';

import {
  confirmPasswordInitialValues,
  confirmPasswordValidationSchema,
} from '../../../../models/validations/schemas/validations';

//ui
import { Button } from '../../../ui/Button';
import { InputPassword } from '../../../ui/Form/InputPassword';

import styles from './ConfirmPasswordForm.module.scss';

const cx = classNames.bind(styles);

export const ConfirmPasswordForm = () => {
  const [userResetPassword, { isLoading, error, isSuccess }] =
    useUserResetPasswordMutation();
  const [serverError, setServerError] = useState<string | undefined>();
  const messagePasswordInvalid = 'Invalid password';
  const navigate = useNavigate();

  const handleSubmit = async (values: typeof confirmPasswordInitialValues) => {
    await userResetPassword({
      password: values.password,
      password_confirmation: values.password_confirmation,
    });
  };

  const formik = useFormik({
    initialValues: confirmPasswordInitialValues,
    validationSchema: confirmPasswordValidationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (isSuccess) {
      navigate(frontendRoutes.user.profile.index);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error && 'data' in error) {
      const serverError = error.data as { message: string };
      if (serverError.message) {
        setServerError(serverError.message);
      }
    }
  }, [error]);

  useEffect(() => {
    if (serverError) {
      formik.setErrors({ password: serverError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverError]);

  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <div className={styles.form}>
          <InputPassword name="password" placeholder="Новый пароль" />
          <InputPassword
            name="password_confirmation"
            placeholder="Подтверждение"
          />
          <div className={styles.nav}>
            <div className={styles.form__submit}>
              <Button
                label="Подтвердить"
                type="submit"
                gradient={true}
                size={'large'}
              />
            </div>
          </div>
        </div>
      </Form>
    </FormikContext.Provider>
  );
};
