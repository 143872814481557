import React, { ReactElement } from 'react';
import styles from './Col.module.scss';

type ColTypes = {
  children: ReactElement;
};

export const Col = ({ children }: ColTypes) => {
  return <div className={styles.col}>{children}</div>;
};
