import React, { useEffect } from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import styles from './MaterialsEventsFilters.module.scss';
import classNames from 'classnames/bind';

import { defaultFormikOptions } from '../../../../../models/formik';
import {
  materialEventsFiltersInitialValues,
  materialEventsFiltersValidationSchema,
} from '../../../../../models/validations/schemas/validations';
import { useDispatch } from 'react-redux';
import { getTags } from '../../../../../store/tagsSlice';
import { useGetTagsQuery } from '../../../../../store/VbudusheeAPI';
import { useLocation } from 'react-router-dom';
import { frontendRoutes } from '../../../../../utils/router/routes';

//  ui
import { Button } from '../../../../ui/Button';
import { Filters } from '../../../../ui/Filters';
import { InputText } from '../../../../ui/Form/InputText';
import { Select } from '../../../../ui/Form/Select';
import { InputDate } from '../../../../ui/Form/InputDate';
import { SelectSearchRegions } from '../../../../ui/Form/SelectSearchRegions';
import { toKeyAndValue } from '../../../../../utils/array';
import { EVENT_PUBLICATION_STATUSES } from '../../../../../constants/events';

const cx = classNames.bind(styles);

type MaterialsEventsFiltersTypes = {
  eventsRolesDict?: Record<string, string>;
  eventsStatusDict?: Record<string, string>;
  setFilters: React.Dispatch<
    React.SetStateAction<typeof materialEventsFiltersInitialValues>
  >;
  handleResetPage: () => void;
};

export const MaterialsEventsFilters = ({
  eventsRolesDict,
  eventsStatusDict,
  setFilters,
  handleResetPage,
}: MaterialsEventsFiltersTypes) => {
  const handleFiltersSubmit = (
    values: typeof materialEventsFiltersInitialValues
  ) => {
    setFilters(values);
    handleResetPage();
  };

  const { data: tagsData } = useGetTagsQuery({});

  const location = useLocation();
  const dispatch = useDispatch();
  const isMaterialsPage = location.pathname === frontendRoutes.materials.events;

  const tagsSelectOptions = tagsData
    ? tagsData.map((tags) => ({
        region_name: tags.name,
        region_number: tags.id.toString(),
      }))
    : [];

  useEffect(() => {
    if (tagsData) {
      dispatch(getTags(tagsData));
    }
  }, [isMaterialsPage, dispatch, tagsData]);

  const formik = useFormik({
    initialValues: materialEventsFiltersInitialValues,
    validationSchema: materialEventsFiltersValidationSchema,
    onSubmit: handleFiltersSubmit,
    ...defaultFormikOptions,
  });

  const handleFiltersReset = () => {
    setFilters(materialEventsFiltersInitialValues);
    formik.resetForm();
    handleResetPage();
  };

  return (
    <div className={styles.filter}>
      <Filters>
        <FormikContext.Provider value={formik}>
          <Form>
            <div className={styles.form}>
              <div className={cx('form__item', 'form__item_search')}>
                <InputText
                  name="search"
                  placeholder="Поиск по названию"
                  fullWidth={true}
                />
              </div>
              <div className={cx('form__item')}>
                <Select
                  fullWidth={true}
                  name="role"
                  label="Моя роль"
                  options={toKeyAndValue(eventsRolesDict)}
                />
              </div>
              <div className={cx('form__item')}>
                <InputDate
                  isRange
                  name="dates"
                  fullWidth={true}
                  placeholder="Сроки проведения"
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <SelectSearchRegions
                  fullWidth
                  name="tags"
                  placeholder="Тематические теги"
                  options={tagsSelectOptions}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <Select
                  fullWidth={true}
                  name="status"
                  label="Статус проведения"
                  options={toKeyAndValue(eventsStatusDict)}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <Select
                  fullWidth={true}
                  name="publicationStatus"
                  label="Статус публикации"
                  options={toKeyAndValue(EVENT_PUBLICATION_STATUSES)}
                />
              </div>
            </div>
            <div className={styles.buttons}>
              <div onClick={handleFiltersReset}>
                <Button label="Сбросить" withoutBackground={true} />
              </div>
              <div onClick={() => formik.handleSubmit()}>
                <Button label="Применить" gradient={true} />
              </div>
            </div>
          </Form>
        </FormikContext.Provider>
      </Filters>
    </div>
  );
};
