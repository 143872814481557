import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  useGetDictionaryEducationQuery,
  useGetResearchEditIdQuery,
  useGetResearchMethodsQuery,
  useGetResearchQuestionnairesQuery,
  useGetResearchSurveyQuery,
} from '../../../store/VbudusheeAPI';

//  ui
import { Layout } from '../../../components/Layout';
import { ResearchFormCreateAndEdit } from '../../../components/pages/research/ResearchForm/ResearchFormCreateAndEdit';
import { ResearchSurveyMenu } from '../../../components/pages/research/ResearchForm/ResearchSurveyMenu';
import { NotFoundPage } from '../../NotFoundPage';
import { useDispatch } from 'react-redux';
import {
  getMethod,
  getQuiz,
  getDictionaryEducation,
} from '../../../store/surveySlice';
import { QuestionItemType } from '../../../models/research';

export const ResearchEditPage = () => {
  const { researchId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMenu, setIsMenu] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [isSaveTemplate, setIsSaveTemplate] = useState(false);
  const [isDownloadTemplate, setIsDownloadTemplate] = useState(false);

  const [requiredQuestion, setRequiredQuestion] = useState<number[]>([]);
  const [chosenQuestion, setChosenQuestion] = useState<QuestionItemType[]>([]);

  const {
    data: researchData,
    isLoading: isRDetailsLoading,
    error: isRDetailsError,
    refetch,
  } = useGetResearchEditIdQuery({
    researchId: researchId,
  });

  const {
    data: survey,
    isLoading: isSurveyLoading,
    error: isSurveyError,
  } = useGetResearchSurveyQuery({ researchId: researchId });
  const {
    data: dictionaryEducation,
    isLoading: isDEducationLoading,
    error: isDEducationError,
  } = useGetDictionaryEducationQuery({});
  const {
    data: methodsData,
    isLoading: isMethodsLoading,
    isError: isMethodsError,
  } = useGetResearchMethodsQuery({});
  const {
    data: qData,
    isLoading: isQLoading,
    isError: isQError,
  } = useGetResearchQuestionnairesQuery({ researchId: researchId });

  // const filterQuestionnaires = useMemo(() => {
  //   if (qData?.questionnaires && dictionaryEducation && researchData?.data.research_education) {
  //     return qData?.questionnaires.filter()
  //   }
  //   return qData?.questionnaires;
  // }, [formik.values.research_target])
  const dataRefetch = () => {
    refetch();
  };

  useEffect(() => {
    if (methodsData?.methods) {
      dispatch(getMethod(methodsData.methods));
    }
  }, [dispatch, methodsData]);

  useEffect(() => {
    if (qData?.questionnaires) {
      dispatch(getQuiz(qData.questionnaires));
    }
  }, [dispatch, qData, researchData]);

  useEffect(() => {
    if (dictionaryEducation) {
      dispatch(getDictionaryEducation(dictionaryEducation));
    }
  }, [dispatch, dictionaryEducation]);

  useEffect(() => {
    if (isRDetailsError) {
      navigate('/404');
    }
  }, [navigate, isRDetailsError]);

  const isTheAuthor = useMemo(() => {
    if (researchData) {
      if (researchData.data.research_user_role === 'owner') {
        return true;
      }
      return false;
    }
    return false;
  }, [researchData]);

  const disable = useMemo(() => {
    if (isTheAuthor) {
      if (researchData?.data.research_publication_status !== 'unpublished') {
        if (researchData?.data.research_publication_status === 'rework') {
          return false;
        }
        if (researchData?.data.research_publication_status === 'rejected') {
          return false;
        }
        return true;
      }
      return false;
    }
    return true;
  }, [researchData, isTheAuthor]);

  return (
    <Layout>
      {researchId ? (
        <DndProvider backend={HTML5Backend}>
          <div className={isMenu ? 'flex' : undefined}>
            <>
              {isMenu && (
                // TODO: replace with SidePanel abstract component
                <ResearchSurveyMenu
                  methods={methodsData?.methods || []}
                  questions={
                    qData || {
                      questionnaires: [],
                      questionnaires_custom: [],
                    }
                  }
                  isMenuOpen={isMenuOpen}
                  setIsMenuOpen={setIsMenuOpen}
                  saveTemplate={setIsSaveTemplate}
                  downloadTemplate={setIsDownloadTemplate}
                  disable={
                    disable || researchData?.data.research_interview_open
                  }
                  requiredQuestion={requiredQuestion}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setRequiredQuestion={setRequiredQuestion}
                  chosenQuestion={chosenQuestion}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setChosenQuestion={setChosenQuestion}
                />
              )}
            </>
            <ResearchFormCreateAndEdit
              researchId={researchId}
              researchData={researchData}
              researchSurvey={survey}
              refetch={dataRefetch}
              isQLoading={isQLoading}
              isMenu={isMenu}
              setIsMenu={setIsMenu}
              isMenuOpen={isMenuOpen}
              setSaveTemplate={setIsSaveTemplate}
              saveTemplate={isSaveTemplate}
              setDownloadTemplate={setIsDownloadTemplate}
              downloadTemplate={isDownloadTemplate}
              requiredQuestion={requiredQuestion}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setRequiredQuestion={setRequiredQuestion}
              chosenQuestion={chosenQuestion}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setChosenQuestion={setChosenQuestion}
            />
          </div>
        </DndProvider>
      ) : (
        <NotFoundPage />
      )}
    </Layout>
  );
};
