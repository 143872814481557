import { useState, useEffect } from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import { object, string } from 'yup';
import classNames from 'classnames/bind';

import { frontendRoutes } from '../../utils/router/routes';
import {
  RESEARCH_TARGET_AUDIENCE,
  RESEARCH_EDUCATION_LEVEL,
} from '../../constants/research';
import { useGetInteractiveMapDataQuery } from '../../store/VbudusheeAPI';
import { ResearchStatisticsResultItem } from '../../models/interactiveMap';
import { REGIONS } from '../../models/daData';

//  ui
import { Layout } from '../../components/Layout';
import { Breadcrumbs } from '../../components/ui/Breadcrumbs';
import { Typography } from '../../components/ui/Typography';
import { Box } from '../../components/ui/Box';
import { Card } from '../../components/ui/Card';
import { Button } from '../../components/ui/Button';
import { RuMap } from '../../components/ui/RuMap';
import { IconDownloadBtn } from '../../components/ui/Icons/IconDownloadBtn';
import { IconFilerBtn } from '../../components/ui/Icons/IconFilterBtn';
import { IconLineArrow } from '../../components/ui/Icons/Map/IconLineArrow';
import { IconLineArrowUp } from '../../components/ui/Icons/Map/IconLineArrowUp';
import { IconPanelArrow } from '../../components/ui/Icons/Map/IconPanelArrow';
import { IconFilterMenuClose } from '../../components/ui/Icons/Map/IconFiltersMenuClose';
import { DropdownBlock } from '../../components/ui/DropdownBlock';
import { DropdownFilter } from '../../components/ui/Filters/DropdownFilter';
import { NumberFilter } from '../../components/ui/Filters/NumberFilter';
import { HorizontalBarChart } from '../../components/ui/charts/HorizontalBarChart';
import { generateOptionsArr } from '../../utils/array';

import styles from './InteractiveMap.module.scss';
const cx = classNames.bind(styles);

export const InteractiveMapPage = (): JSX.Element => {
  const [researchId, setResearchId] = useState<string>('');
  const [isRegionStatMenuOpen, setIsRegionStatMenuOpen] = useState(false);
  const [region, setRegion] = useState<number | undefined>(undefined);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isFiltersMenuOpen, setIsFiltersMenuOpen] = useState(false);
  const [pickedRegion, setPickedRegion] = useState<
    ResearchStatisticsResultItem | undefined
  >(undefined);
  const requestParams = {
    map_method: 2,
    map_year: undefined,
    map_respondents_audience: undefined,
    map_respondents_education: undefined,
    map_region: undefined,
    map_locality: undefined,
    map_organization_inn: undefined,
  };
  const { data } = useGetInteractiveMapDataQuery({
    researchId,
    params: requestParams,
  });
  const SCALE_DATA = [0, 4, 7, 10, 13, 16];
  const scaleColors = [
    '#D5E7F3',
    '#ABCEE7',
    '#6DAAD5',
    '#4391C9',
    '#2F7DB5',
    '#145F97',
  ];

  const formik = useFormik({
    initialValues: {
      regSearch: '',
    },
    validationSchema: object({
      regSearch: string(),
    }),
    onSubmit: () => {},
  });

  const handleFilter = (): void => {
    setIsFiltersMenuOpen(false);
  };

  useEffect(() => {
    if (region) setIsRegionStatMenuOpen(true);
    const regions = data?.filtered_quizzes_answers_data?.statistics;
    if (regions) {
      const foundReg: ResearchStatisticsResultItem | undefined = regions.find(
        (reg: ResearchStatisticsResultItem) => reg.region === `${region}`
      );
      setPickedRegion(foundReg);
    }
  }, [data?.filtered_quizzes_answers_data?.statistics, region]);

  // useEffect(() => {
  //   // eslint-disable-next-line no-console
  //   console.log('data', data);
  // }, [data]);

  useEffect(() => {}, [region]);

  return (
    <Layout>
      <FormikContext.Provider value={formik}>
        <Form>
          <div className={styles.wrapper}>
            {/* Left side */}
            <div className={cx('side', { side_open: isRegionStatMenuOpen })}>
              <div className={styles.absoluteWrapper}>
                <div className={styles.containerLeft}>
                  <DropdownFilter
                    isSearchable
                    label="Поиск по региону"
                    options={Object.entries(REGIONS).map((region) => {
                      return {
                        key: region[0],
                        value: region[1],
                      };
                    })}
                    option={Object.entries(REGIONS)
                      .map((region) => {
                        return {
                          key: region[0],
                          value: region[1],
                        };
                      })
                      .find((el) => el.key === `${region}`)}
                    setValue={setRegion as () => (val: number | string) => void}
                  />
                  <div className={styles.infoBlock}>
                    <div className={styles.infoBlockBtns}>
                      <Button
                        label="Фильтры"
                        color="black"
                        display="service"
                        icon={<IconFilerBtn />}
                        clicked={() => setIsFiltersMenuOpen(true)}
                      />
                    </div>
                    {pickedRegion && pickedRegion?.data?.length > 4 && (
                      <div
                        className={cx('togglePanel', {
                          togglePanel_open: isPanelOpen,
                        })}
                      >
                        <Button
                          color="black"
                          display="service"
                          icon={<IconPanelArrow />}
                          clicked={() => setIsPanelOpen(!isPanelOpen)}
                        />
                      </div>
                    )}
                    <Typography color="main" size="sm" fontWeight={700}>
                      {region ? REGIONS[region] : ''}
                    </Typography>
                    <Box mt={16} mb={32}>
                      <Card padding="small">
                        <div className={styles.box}>
                          <Box>
                            <Typography
                              align="center"
                              color="main"
                              size="m"
                              fontWeight={700}
                            >
                              н/д
                              {/* Нет данных */}
                              {/* {pickedRegion?.ed_orgs} */}
                            </Typography>
                            <Box mt={8} />
                            <Typography align="center" color="gray" size="s">
                              Всего респондентов
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              align="center"
                              color="main"
                              size="m"
                              fontWeight={700}
                            >
                              {pickedRegion?.ed_orgs
                                ? pickedRegion?.ed_orgs
                                : 'н/д'}
                            </Typography>
                            <Box mt={8} />
                            <Typography align="center" color="gray" size="s">
                              Всего организаций
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              align="center"
                              color="main"
                              size="m"
                              fontWeight={700}
                            >
                              {pickedRegion?.ed_orgs_percentage
                                ? `${pickedRegion?.ed_orgs_percentage}%`
                                : 'н/д'}
                            </Typography>
                            <Box mt={8} />
                            <Typography align="center" color="gray" size="s">
                              от организаций по региону
                            </Typography>
                          </Box>
                        </div>
                      </Card>
                    </Box>
                    {pickedRegion && (
                      <DropdownFilter
                        label="Населенный пункт"
                        options={generateOptionsArr(
                          pickedRegion?.data?.map((city) => city.locality)
                        )}
                        setValue={() => {}}
                      />
                    )}
                  </div>
                  <div
                    className={cx('chartsBlock', {
                      chartsBlockWide:
                        pickedRegion && pickedRegion?.data?.length > 4,
                      chartsBlockClose: !isPanelOpen,
                    })}
                  >
                    {pickedRegion?.data.map((city, i) => (
                      <DropdownBlock
                        key={`${city.locality}_${i}`}
                        title={city.locality}
                      >
                        <HorizontalBarChart data={city} />
                      </DropdownBlock>
                    ))}
                  </div>
                </div>
              </div>
              {/* Filters menu */}
              <div
                className={cx('filtersMenu', {
                  filtersMenu_open: isFiltersMenuOpen,
                })}
              >
                <div className={styles.filtersMenu__header}>
                  <Typography size="sm" color="main" fontWeight={700}>
                    Фильтры
                  </Typography>
                  <Button
                    display="service"
                    icon={<IconFilterMenuClose />}
                    clicked={() => setIsFiltersMenuOpen(false)}
                  />
                </div>
                <div className={styles.filtersMenu__body}>
                  <Box mt={24} mb={16}>
                    <NumberFilter
                      placeholder="Номер исследования"
                      setOuterValue={setResearchId}
                    />
                  </Box>
                  <Box mb={16}>
                    <DropdownFilter
                      label="Аудитория респондентов"
                      options={RESEARCH_TARGET_AUDIENCE}
                      setValue={() => {}}
                    />
                  </Box>
                  <Box mb={16}>
                    <DropdownFilter
                      label="Уровень образования"
                      options={RESEARCH_EDUCATION_LEVEL}
                      setValue={() => {}}
                    />
                  </Box>
                  <Box mb={16}>
                    <DropdownFilter
                      label="Методики"
                      options={RESEARCH_TARGET_AUDIENCE}
                      setValue={() => {}}
                    />
                  </Box>
                  <Box mb={24}>
                    <DropdownFilter
                      label="Год исследования"
                      options={[
                        {
                          key: '2018',
                          value: '2018',
                        },
                        {
                          key: '2019',
                          value: '2019',
                        },
                        {
                          key: '2020',
                          value: '2020',
                        },
                        {
                          key: '2021',
                          value: '2021',
                        },
                        {
                          key: '2022',
                          value: '2022',
                        },
                      ]}
                      setValue={() => {}}
                    />
                  </Box>
                  <Button
                    label="Показать"
                    display="fill"
                    color="gradient"
                    fullWidth
                    clicked={handleFilter}
                  />
                </div>
              </div>
            </div>
            {/* Right side */}
            <section className={styles.containerRight}>
              <div className={styles.containerRight__breadcrumbs}>
                <Breadcrumbs
                  links={[{ title: 'Карта открытых результатов исследований' }]}
                  homeLink={frontendRoutes.mainPage}
                />
              </div>
              <div className={styles.containerRight__header}>
                <Typography
                  color="main"
                  size="xxxl"
                  font="SFPRoDisplay"
                  fontWeight={600}
                >
                  Карта открытых результатов исследований
                </Typography>
              </div>
              <div className={styles.containerRight__body}>
                <div className={styles.topPanel}>
                  <div className={styles.card}>
                    <Card>
                      <>
                        <Typography
                          align="center"
                          color="main"
                          size="m"
                          fontWeight={600}
                        >
                          {data?.total_ed_orgs.toLocaleString('ru') ??
                            'Нет данных'}
                        </Typography>
                        <Box mt={8} />
                        <Typography align="center" color="gray" size="s">
                          Общее количество респондентов
                        </Typography>
                      </>
                    </Card>
                  </div>
                  <Button
                    label="Скачать данные"
                    display="service"
                    color="black"
                    icon={<IconDownloadBtn />}
                  />
                </div>
                {/*<RuMap*/}
                {/*  isTempRate*/}
                {/*  setRegion={setRegion}*/}
                {/*  selectedRegion={region}*/}
                {/*/>*/}
                <div className={styles.bottomPanel}>
                  <Card>
                    <>
                      <Typography
                        color="main"
                        size="sm"
                        fontWeight={700}
                        align="center"
                      >
                        Рейтинг
                      </Typography>
                      <div className={styles.scale}>
                        {SCALE_DATA.map((el, i) => (
                          <div
                            key={i}
                            className={styles.scaleItem}
                            style={{
                              background: scaleColors[i],
                              width: `calc(100% / ${SCALE_DATA.length} - 1px)`,
                            }}
                          >
                            <div className={styles.scaleItemLegend}>{el}</div>
                          </div>
                        ))}
                      </div>
                      <Box mt={64}>
                        <Typography color="gray" size="s">
                          4 – удовлетворены полностью; 7 – в основном
                          удовлетворены; 10 – средне удовлетворены; 13 – мало
                          удовлетворены; 16 – не удовлетворены
                        </Typography>
                      </Box>
                    </>
                  </Card>
                  <Card>
                    <div className={styles.indicators}>
                      <Typography
                        color="main"
                        size="sm"
                        fontWeight={700}
                        align="center"
                      >
                        Показатели
                      </Typography>
                      <div className={styles.lines}>
                        <div className={styles.line}>
                          <Typography size="s" color="gray">
                            Выше среднего по РФ
                          </Typography>
                          <div className={styles.lineArrow}>
                            <IconLineArrowUp />
                          </div>
                        </div>
                        <div className={styles.line}>
                          <Typography size="s" color="gray">
                            Ниже среднего по РФ
                          </Typography>
                          <div className={styles.lineArrow}>
                            <IconLineArrow />
                          </div>
                        </div>
                        <div className={styles.line}>
                          <Typography size="s" color="gray">
                            Равен среднему по РФ
                          </Typography>
                          <div className={styles.lineArrow}>
                            <IconLineArrow />
                            <IconLineArrowUp />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </section>
          </div>
        </Form>
      </FormikContext.Provider>
    </Layout>
  );
};
