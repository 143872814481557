// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Box_box__flex__kCOYP {
  display: flex;
  align-items: center;
}
.Box_box__disabled__f45VP {
  opacity: 0.3;
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Box/Box.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;AAAJ;AAGE;EACE,YAAA;EACA,oBAAA;AADJ","sourcesContent":[".box {\n  &__flex {\n    display: flex;\n    align-items: center;\n  }\n\n  &__disabled {\n    opacity: 0.3;\n    pointer-events: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box__flex": `Box_box__flex__kCOYP`,
	"box__disabled": `Box_box__disabled__f45VP`
};
export default ___CSS_LOADER_EXPORT___;
