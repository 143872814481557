import { FieldArray, Form, FormikContext, useFormik } from 'formik';
import classNames from 'classnames/bind';

import { questionsMethodsInitialValues } from '../../../../models/validations/schemas/validations';
import {
  GroupQuestions,
  MainSurvey,
  Question,
  Survey,
  SurveyQuestionType,
} from '../../../../models/survey/survey';

//  ui
import { Button } from '../../../ui/Button';
import { SurveyQuestion } from '../../../ui/SurveyQuestion/SurveyQuestion';

import styles from './QuestionsForMethodsForm.module.scss';
import { useNavigate } from 'react-router-dom';
import { frontendRoutes } from '../../../../utils/router/routes';
import { Box } from '../../../ui/Box';
import React from 'react';
import { Typography } from '../../../ui/Typography';
import {
  MethodDictItem,
  MethodDictItemSurvey,
} from '../../../../models/research';

const cx = classNames.bind(styles);

type QuestionnaireFormProps = {
  id: number;
  methodsList: MethodDictItemSurvey;
  methodTitle?: string;
  methodInstruction: string;
  disabled?: boolean;
};

export const QuestionsForMethodsForm = (props: QuestionnaireFormProps) => {
  const { id, methodsList, methodTitle, methodInstruction, disabled } = props;

  // 29

  const navigate = useNavigate();
  const groupQuestion: Survey[] = [];
  const singleQuestion: Survey[] = [];

  Object.values(methodsList.survey).filter((question, index) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (question.group || question.field.min_label) {
      if (!question.group) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return groupQuestion.push({
          ...question,
          group: question.text,
          index: index + 1,
          continuousNumbering: true,
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return groupQuestion.push({ ...question, index: index + 1 });
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return singleQuestion.push(question);
    }
  });

  const groupQuestions: GroupQuestions[] = [];

  const groupsStr = groupQuestion
    .map((x) => x.group)
    .filter((v, i, a) => a.indexOf(v) === i);

  for (let i = 0; i < groupsStr.length; i++) {
    const arrWithGroup = groupQuestion.filter(
      (item) => item.group === groupsStr[i]
    );
    const res: GroupQuestions = {
      group: groupsStr[i],
      items: arrWithGroup,
    };
    groupQuestions.push(res);
  }

  const singleQuestionToGroup: GroupQuestions[] = [
    {
      group: 'single_to_group',
      items: singleQuestion.filter(
        (item) => item.field.type_label === 'integer_from_to'
      ),
    },
  ];

  const handleClick = () => {
    navigate(frontendRoutes.survey.complete);
  };

  const handleSubmit = async (values: typeof questionsMethodsInitialValues) => {
    //TODO неообходимо уточнить формат собираемых данных по каждому типу вопроса
    // const result: number[] = values.questions.map((item, index) => {
    //   return Number(JSON.stringify(item).match(ONLY_NUMBER)?.join(''));
    // });
    // if (result.length < questionsList.length) {
    //   // eslint-disable-next-line no-console
    //   console.log('Not all fields are checked');
    // } else {
    //   // eslint-disable-next-line no-console
    //   console.log('All right', result);
    // }
    // eslint-disable-next-line no-console
    // console.log(values);
    // const result = await login(values);
    // eslint-disable-next-line no-console
  };

  // const formik = useFormik({
  //   initialValues: questionsMethodsInitialValues,
  //   validationSchema: questionsMethodsValidationSchema,
  //   onSubmit: handleSubmit,
  // });

  return (
    <FieldArray name={`${id}`}>
      {(fieldArrayProps) => {
        const { push, remove, name } = fieldArrayProps;
        // const { values } = form;
        // const { question_input_type, question_answers } = values;

        return (
          <div className={styles.form}>
            <div className={styles.form__block}>
              {methodTitle && (
                <div className={styles.form__header}>{methodTitle}</div>
              )}
              <Typography
                size="sm"
                color="main"
                fontWeight={600}
                font="SFPRoDisplay"
              >
                {methodInstruction}
              </Typography>
              {/*одиночный вопрос объединенный в групповой*/}
              {singleQuestionToGroup.map((question, index) => {
                if (question.items.find((i) => !i.field.min_label)) {
                  return (
                    <SurveyQuestion
                      name={`${name}[${index}].`}
                      type={'group'}
                      questionTitle={''}
                      questions={question.items}
                      key={index}
                      position={'vertical'}
                      disabled={disabled}
                    />
                  );
                }
              })}
              {/*одиночный вопрос*/}
              {singleQuestion.map((question, index) => {
                if (question.field.choices) {
                  return (
                    <SurveyQuestion
                      name={`${name}[${index}]`}
                      type={question.field.type_label as SurveyQuestionType}
                      questionTitle={question.text}
                      question={question}
                      questions={singleQuestion}
                      key={index}
                      position={'vertical'}
                      max={question.field.max}
                      min={question.field.min}
                      disabled={disabled}
                    />
                  );
                }
              })}

              {/*групповой вопрос */}
              {groupQuestions.map((question, index) => {
                if (
                  question.items.find(
                    (i) => !i.field.min_label && !i.field.choices
                  )
                ) {
                  return (
                    <SurveyQuestion
                      name={`${name}[${index}]`}
                      type={'group'}
                      questionTitle={question.group || ''}
                      questions={question.items}
                      key={index}
                      position={'vertical'}
                      disabled={disabled}
                    />
                  );
                }
              })}
              {/*групповой вопрос */}
              {groupQuestions.map((question, index) => {
                if (
                  question.items.find(
                    (i) => i.field.min_label && i.field.choices
                  )
                ) {
                  return question.items.map((i) => {
                    return (
                      <SurveyQuestion
                        name={`${name}[${index}]`}
                        type={'choice'}
                        questionTitle={question.group || ''}
                        question={i}
                        key={index}
                        position={'vertical'}
                        disabled={disabled}
                        questions={[]}
                      />
                    );
                  });
                }
              })}
              {groupQuestions.map((question, index) => {
                if (
                  question.items.find(
                    (i) => !i.field.min_label && i.field.choices
                  )
                ) {
                  return question.items.map((i, iIndex) => {
                    return (
                      <SurveyQuestion
                        name={`${name}[${index}][${iIndex}]`}
                        type={'choice'}
                        questionTitle={
                          question.items[iIndex].text || question.group || ''
                        }
                        question={i}
                        key={iIndex + index}
                        position={'vertical'}
                        disabled={disabled}
                        questions={[]}
                      />
                    );
                  });
                }
              })}
              {/*групповой вопрос со шкалой от и до*/}
              {groupQuestions.map((question, index) => {
                if (
                  question.items.find(
                    (i) => i.field.min_label && !i.field.choices
                  )
                ) {
                  return (
                    <SurveyQuestion
                      name={`${name}[${index}]`}
                      type={'scale_group'}
                      questionTitle={question.group || ''}
                      questions={question.items}
                      key={index}
                      position={'horizontal'}
                      disabled={disabled}
                    />
                  );
                }
              })}
            </div>
          </div>
        );
      }}
    </FieldArray>
  );
};
