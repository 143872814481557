export const PROF_CATEGORIES = [
  { key: '1', value: 'Первая' },
  { key: '2', value: 'Вторая' },
  { key: '3', value: 'Третья' },
  { key: '4', value: 'Четвертая' },
  { key: '5', value: 'Пятая' },
  { key: '6', value: 'Шестая' },
];

export const AGE_CATEGORIES = [
  { key: '1', value: 'Первая' },
  { key: '2', value: 'Вторая' },
  { key: '3', value: 'Третья' },
  { key: '4', value: 'Четвертая' },
  { key: '5', value: 'Пятая' },
  { key: '6', value: 'Шестая' },
];
