// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ServerErrorPage_wrapper__DjixH {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  margin-top: 180px;
  margin-bottom: 220px;
}
.ServerErrorPage_wrapper__text_block__zzDSZ {
  display: flex;
  flex-direction: column;
  margin: 0 25px;
}
.ServerErrorPage_wrapper__number__thIH7 {
  display: flex;
  flex-grow: 0;
  width: 37px;
  height: 28px;
  margin-bottom: 24px;
}
.ServerErrorPage_wrapper__header_text__PkW3\\+ {
  flex-grow: 0;
  width: 400px;
  margin-bottom: 14px;
}
.ServerErrorPage_wrapper__main_text__OdaSX {
  flex-grow: 0;
  width: 416px;
}
.ServerErrorPage_wrapper__button__YTuR\\+ {
  display: flex;
  flex-grow: 0;
  margin-top: 32px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ServerErrorPage/ServerErrorPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,6BAAA;EACA,YAAA;EACA,iBAAA;EACA,oBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,cAAA;AACJ;AAEE;EACE,aAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AAAJ;AAGE;EACE,YAAA;EACA,YAAA;EACA,mBAAA;AADJ;AAIE;EACE,YAAA;EACA,YAAA;AAFJ;AAKE;EACE,aAAA;EACA,YAAA;EACA,gBAAA;AAHJ","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-evenly;\n  height: 100%;\n  margin-top: 180px;\n  margin-bottom: 220px;\n\n  &__text_block {\n    display: flex;\n    flex-direction: column;\n    margin: 0 25px;\n  }\n\n  &__number {\n    display: flex;\n    flex-grow: 0;\n    width: 37px;\n    height: 28px;\n    margin-bottom: 24px;\n  }\n\n  &__header_text {\n    flex-grow: 0;\n    width: 400px;\n    margin-bottom: 14px;\n  }\n\n  &__main_text {\n    flex-grow: 0;\n    width: 416px;\n  }\n\n  &__button {\n    display: flex;\n    flex-grow: 0;\n    margin-top: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ServerErrorPage_wrapper__DjixH`,
	"wrapper__text_block": `ServerErrorPage_wrapper__text_block__zzDSZ`,
	"wrapper__number": `ServerErrorPage_wrapper__number__thIH7`,
	"wrapper__header_text": `ServerErrorPage_wrapper__header_text__PkW3+`,
	"wrapper__main_text": `ServerErrorPage_wrapper__main_text__OdaSX`,
	"wrapper__button": `ServerErrorPage_wrapper__button__YTuR+`
};
export default ___CSS_LOADER_EXPORT___;
