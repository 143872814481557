//  Auth
export enum ServerErrors {
  INVALID_PASSWORD = 'Invalid password',
  UNAUTHORISED = 'Unauthorised',
  PASSWORD_FORM_NOT_REGISTERED = 'Error: PasswordForm not registered',
  INCORRECT_PASSWORD = 'Error: Incorrect password',
  SELECTED_EMAIL_IS_INVALID = 'The selected email is invalid.',
  EMAIL_NOT_REGISTERED = 'Error: Email not registered',
}

//  File errors
export const FILE_ERROR_DICTIONARY = {
  type: 'Некорректный тип файла',
  size: 'Превышен максимальный размер файла',
};
