import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { frontendRoutes } from '../../../utils/router/routes';

//  ui
import { IconArrow } from '../../ui/Icons/IconArrow';
import { IconLock } from '../../ui/Icons/IconLock';
import { IconMessage } from '../../ui/Icons/IconMessage';
import { IconProfile } from '../../ui/Icons/IconProfile';

import styles from './ProfilePopupContent.module.scss';

type ProfilePopupContentTypes = {
  openProfileEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ProfilePopupContent = ({
  openProfileEdit,
  setModalOpen,
}: ProfilePopupContentTypes) => {
  const navigate = useNavigate();

  const handleProfileEdit = () => {
    openProfileEdit(true);
    setModalOpen(false);
  };

  return (
    <div className={styles.popup}>
      <div className={styles.item} onClick={handleProfileEdit}>
        <div className={styles.item__icon}>
          <IconProfile />
        </div>
        <span>Изменить персональную информацию</span>
        <div className={styles.item__arrow}>
          <IconArrow />
        </div>
      </div>
      <NavLink className={styles.item} to={frontendRoutes.user.changePassword}>
        <div className={styles.item__icon}>
          <IconLock />
        </div>
        <span>Изменить пароль</span>
        <div className={styles.item__arrow}>
          <IconArrow />
        </div>
      </NavLink>
      <NavLink className={styles.item} to={frontendRoutes.user.changeMail}>
        <div className={styles.item__icon}>
          <IconMessage />
        </div>
        <span>Изменить электронную почту</span>
        <div className={styles.item__arrow}>
          <IconArrow />
        </div>
      </NavLink>
    </div>
  );
};
