export const IconXsl = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7365 2.76172H8.08449C6.02449 2.76172 4.25049 4.43072 4.25049 6.49072V17.3397C4.25049 19.5157 5.90849 21.1147 8.08449 21.1147H16.0725C18.1325 21.1147 19.8025 19.3997 19.8025 17.3397V8.03772L14.7365 2.76172Z"
        stroke="#12826E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4739 2.75V5.659C14.4739 7.079 15.6229 8.231 17.0429 8.234C18.3589 8.237 19.7059 8.238 19.7969 8.232"
        stroke="#12826E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="12" y="11" width="12" height="7" fill="white" />
      <path
        d="M9.632 14.4316L8.12 11.7036H9.64L10.656 13.5756L11.712 11.7036H13.208L11.6 14.4076L13.296 17.3036H11.752L10.6 15.3116L9.504 17.3036H8L9.632 14.4316Z"
        fill="#12826E"
      />
      <path
        d="M16.6818 15.7516C16.6818 15.5916 16.6124 15.4689 16.4738 15.3836C16.3404 15.2929 16.1724 15.2156 15.9698 15.1516C15.7671 15.0823 15.5457 15.0103 15.3057 14.9356C15.0657 14.8609 14.8444 14.7569 14.6418 14.6236C14.4391 14.4903 14.2684 14.3143 14.1298 14.0956C13.9964 13.8716 13.9297 13.5809 13.9297 13.2236C13.9297 12.9729 13.9671 12.7489 14.0417 12.5516C14.1217 12.3489 14.2471 12.1783 14.4177 12.0396C14.5938 11.8956 14.8204 11.7863 15.0978 11.7116C15.3751 11.6369 15.7138 11.5996 16.1138 11.5996C16.4711 11.5996 16.7964 11.6263 17.0898 11.6796C17.3884 11.7276 17.6337 11.7916 17.8258 11.8716V13.1596C17.6231 13.0209 17.3804 12.9063 17.0977 12.8156C16.8151 12.7196 16.5218 12.6716 16.2178 12.6716C16.0258 12.6716 15.8684 12.6849 15.7458 12.7116C15.6231 12.7383 15.5244 12.7756 15.4497 12.8236C15.3804 12.8716 15.3298 12.9276 15.2977 12.9916C15.2711 13.0556 15.2578 13.1249 15.2578 13.1996C15.2578 13.3543 15.3271 13.4743 15.4657 13.5596C15.6097 13.6449 15.7857 13.7223 15.9938 13.7916C16.2071 13.8556 16.4364 13.9249 16.6818 13.9996C16.9324 14.0689 17.1617 14.1729 17.3698 14.3116C17.5831 14.4449 17.7591 14.6209 17.8978 14.8396C18.0418 15.0583 18.1138 15.3463 18.1138 15.7036C18.1138 16.2423 17.9324 16.6609 17.5697 16.9596C17.2071 17.2583 16.6204 17.4076 15.8098 17.4076C15.4471 17.4076 15.0978 17.3729 14.7618 17.3036C14.4258 17.2343 14.1564 17.1409 13.9538 17.0236V15.6876C14.0231 15.7516 14.1137 15.8209 14.2257 15.8956C14.3377 15.9703 14.4684 16.0396 14.6178 16.1036C14.7671 16.1676 14.9351 16.2236 15.1217 16.2716C15.3084 16.3143 15.5138 16.3356 15.7378 16.3356C16.0737 16.3356 16.3138 16.2823 16.4578 16.1756C16.6071 16.0689 16.6818 15.9276 16.6818 15.7516Z"
        fill="#12826E"
      />
      <path
        d="M19.2549 11.7036H20.5669V16.1036H23.0309V17.3036H19.2549V11.7036Z"
        fill="#12826E"
      />
    </svg>
  );
};
