export const IconUser = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99784 10.1165C5.1225 10.1165 2.6665 10.5698 2.6665 12.3831C2.6665 14.1971 5.10717 14.6665 7.99784 14.6665C10.8732 14.6665 13.3292 14.2138 13.3292 12.3998C13.3292 10.5858 10.8892 10.1165 7.99784 10.1165Z"
        fill="white"
      />
      <path
        d="M7.99773 8.38913C9.95639 8.38913 11.5257 6.81913 11.5257 4.86113C11.5257 2.90313 9.95639 1.33313 7.99773 1.33313C6.03973 1.33313 4.46973 2.90313 4.46973 4.86113C4.46973 6.81913 6.03973 8.38913 7.99773 8.38913Z"
        fill="white"
      />
    </svg>
  );
};
