//  ui
import { IconTableEmpty } from './IconTableEmpty';

import styles from './Empty.module.scss';

type EmptyTypes = {
  title?: string;
  text?: string;
};

export const Empty = ({
  title = 'Данных пока нет',
  text = 'Создавайте мероприятия, исследования и активно используйте возможности\n' +
    '        сервиса. Тогда материалы появятся в этом блоке',
}: EmptyTypes): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div>
        <IconTableEmpty />
      </div>
      <h3>{title}</h3>
      <p>{text}</p>
    </div>
  );
};
