import classNames from 'classnames/bind';
import styles from './RatingItem.module.scss';
const cx = classNames.bind(styles);

type RatingItemProps = {
  value: number;
  active: boolean;
  onActive: (value: number) => void;
};

export const RatingItem = ({
  value,
  active,
  onActive,
}: RatingItemProps): JSX.Element => {
  return (
    <div
      className={cx('rate', {
        rate__active: active,
      })}
      onClick={() => onActive(value)}
    >
      {value}
    </div>
  );
};
