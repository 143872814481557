import classNames from 'classnames/bind';

import { WEEKDAYS_SHORT } from '../../../../constants/dates';
import {
  generateMonthsOptions,
  generateYearsOptions,
  getActualYear,
  getActualMonth,
} from '../../../../utils/components/calendar';
import { SelectOption } from '../../../../models/basic';

//  ui
import { DropdownFilter } from '../../Filters/DropdownFilter';
import { CalendarMonth } from '../CalendarMonth';

import styles from './Calendar.module.scss';
const cx = classNames.bind(styles);

type CalendarProps = {
  startDate: Date | undefined;
  handleChange: (date: Date) => void;
  finishDate?: Date | undefined;
  actualDate: Date;
  minDate?: Date;
  isRange?: boolean;
  setMonth: (value: number) => void;
  setYear: (value: number) => void;
};

export const Calendar = ({
  startDate,
  handleChange,
  finishDate,
  actualDate,
  isRange,
  setMonth,
  setYear,
  minDate,
}: CalendarProps): JSX.Element => {
  const monthsOptions: SelectOption<number>[] = generateMonthsOptions();
  const yearsOptions: SelectOption<number>[] = generateYearsOptions();

  return (
    <div
      className={cx('wrapper', {
        wrapper_range: isRange,
      })}
    >
      <div
        className={cx('header', {
          header_single: !isRange,
        })}
      >
        <div className={cx('headerMonth')}>
          <DropdownFilter
            options={monthsOptions}
            option={getActualMonth(monthsOptions, actualDate)}
            setValue={setMonth as (val: number | string) => void}
          />
        </div>
        <div className={cx('headerYear')}>
          <DropdownFilter
            options={yearsOptions}
            option={getActualYear(actualDate)}
            setValue={setYear as (val: number | string) => void}
          />
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.weekdaysNames}>
          {WEEKDAYS_SHORT.map((wd, i) => (
            <div key={`${wd}_${i}`} className={cx('weekdayName')}>
              {wd}
            </div>
          ))}
        </div>
        {/* Generate months */}
        <CalendarMonth
          startDate={startDate}
          handleChange={handleChange}
          finishDate={isRange ? finishDate : undefined}
          actualDate={actualDate}
          isRange={isRange}
          minDate={minDate}
        />
      </div>
    </div>
  );
};
