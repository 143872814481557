import { useState } from 'react';
import styles from './Rating.module.scss';
import { EventRatingType } from '../../../models/document';
//  ui
import { RatingItem } from './RatingItem';

type isActiveItemType = {
  id: number;
  isActive: boolean;
};

type RatingProps = {
  id: number;
  scale: number;
  value: number;
  onChange: (e: EventRatingType) => void;
};

export const Rating = ({
  scale,
  onChange,
  id,
  value,
}: RatingProps): JSX.Element => {
  const [items, setItems] = useState<number>(value);

  const arrayRate: number[] = [];

  for (let i = 0; i < scale; i++) {
    arrayRate.push(i);
  }

  const handleChange = (e: number) => {
    onChange({ id: id, title: `Критерий ${id}`, scale: e });
    setItems(e);

    if (items) {
      setItems(e);
    }
  };

  const activeItems: isActiveItemType[] = [];

  for (let i = 0; i < arrayRate.length; i++) {
    if (arrayRate[i] === items - 1) {
      activeItems.push({ id: i, isActive: true });
    } else {
      activeItems.push({ id: i, isActive: false });
    }
  }

  return (
    <div className={styles.rating}>
      {activeItems.map((rate, i) => {
        return (
          <RatingItem
            key={i}
            value={i + 1}
            active={rate.isActive}
            onActive={(e) => handleChange(e)}
          />
        );
      })}
    </div>
  );
};
