export const TIME_HH_MM = /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/;
export const RU = /[ЁёА-я]/;
export const IP4 = /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/;
export const NO_SPACES = /^[\S]*$/;
export const COORDS = /^\d+(\.\d+)?$/;
export const PHONE =
  /^((\+[1-9]{1}[ -]?)|(\([0-9]{3}\)[ -]?)|([0-9]{3})[ -]?)*?[0-9]{2}[ -]?[0-9]{2}$/;
export const NO_SPECIAL_SYMBOLS = /^[а-яА-ЯёЁa-zA-Z0-9\s]+$/;
export const ONLY_NUMBER = /\d+/g;
export const ONLY_INT = /^([1-9][0-9]{0,3}|[1-9])$/;
export const RU_NAMES = /^[а-яё -]+$/i;
export const RU_REGION =
  /^(([а-яА-ЯёЁ]*(\s*)\([а-яА-ЯёЁ\s]*\))|([а-яА-ЯёЁ\-0-9]*)|([а-яА-ЯёЁ]+[-|\s]?[а-яА-ЯёЁ]*[-|\s]?[а-яА-ЯёЁ]*[-|\s]?[а-яА-ЯёЁ]*))$/;
export const VALID_EMAIL = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
export const CAPITAL_LETTER = /^[A-ZА-Я][a-zа-я]/;
export const PASSWORD = /^(?=.*[a-zа-я])(?=.*[A-ZА-Я])(?=.*[0-9])/;
