import { Layout } from '../../../components/Layout';
import { ComparisonContent } from '../../../components/pages/researchComparison/ComparisonContent';
import { ComparisonSidePanel } from '../../../components/pages/researchComparison/ComparisonSidePanel';
import styles from './ResearchComparison.module.scss';
import { useGetResearchVersusCommonMutation } from '../../../store/VbudusheeAPI';
import { ResearchComparison } from '../../../models/research';
import { useState } from 'react';

export const ResearchComparisonPage = (): JSX.Element => {
  const [researches, setResearches] = useState<number[]>([]);

  const [getResearchCompare, { data, isLoading }] =
    useGetResearchVersusCommonMutation();

  const researchCompare = (body: { researches: number[] }) => {
    setResearches(body.researches);
    getResearchCompare(body);
  };

  const handleToggleResearch = (res: ResearchComparison) => {
    // const containsResearch = addedResearch.find(
    //   (item) => item.research_id === res.research_id
    // );
    //
    // setAddedResearch((prev) =>
    //   containsResearch
    //     ? prev.filter((item) => item.research_id !== res.research_id)
    //     : [...prev, res]
    // );
    //
    // setAvailableResearch((prev) =>
    //   containsResearch
    //     ? [res, ...prev]
    //     : prev.filter((item) => item.research_id !== res.research_id)
    // );
  };
  return (
    <Layout>
      <div className={styles.wrapper}>
        <ComparisonSidePanel getResearchCompare={researchCompare} />
        <ComparisonContent
          researches={researches}
          compare={data}
          loading={isLoading}
        />
      </div>
    </Layout>
  );
};
