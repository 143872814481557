import { ColoredDictItem, DictItem } from '../../../../../../models/basic';
import { Loader } from '../../../../../ui/Loader';
import { ResearchDetailsReportDonutCheckbox } from '../ResearchDetailsReportDonutCheckbox';
import { ResearchDetailsReportNoChosen } from '../ResearchDetailsReportNoChosen';
import styles from './ResearchDetailsReportCheckboxList.module.scss';

type ResearchDetailsReportCheckboxListProps = {
  surveyActiveQuestion: { key: string; value: any } | null;
  activeQuestionAnswers: { key: string; value: any; color: string }[] | null;
  checkedAnswers: DictItem[];
  handleToggleAnswer: (answer: ColoredDictItem) => void;
  activeQuestionAnswersValueSum?: number;
  isLoading: boolean;
};

export const ResearchDetailsReportCheckboxList = ({
  activeQuestionAnswers,
  surveyActiveQuestion,
  activeQuestionAnswersValueSum,
  checkedAnswers,
  handleToggleAnswer,
  isLoading,
}: ResearchDetailsReportCheckboxListProps): JSX.Element => {
  return !isLoading ? (
    <>
      {surveyActiveQuestion && !!activeQuestionAnswers?.length && (
        <div className={styles.checkboxes}>
          {activeQuestionAnswers?.map((answer, i) => (
            <ResearchDetailsReportDonutCheckbox
              handleToggleAnswer={handleToggleAnswer}
              checkedAnswers={checkedAnswers}
              answer={answer}
              color={answer.color}
              activeQuestionAnswersValueSum={activeQuestionAnswersValueSum}
              key={i}
            />
          ))}
        </div>
      )}
      {!surveyActiveQuestion && (
        <ResearchDetailsReportNoChosen
          text="Для просмотра информации о данных выберите вопрос в выпадающем списке
            выше"
        />
      )}
      {surveyActiveQuestion && !activeQuestionAnswers?.length && (
        <ResearchDetailsReportNoChosen text="Нет данных по выбранному вопросу" />
      )}
    </>
  ) : (
    <div className={styles.loaderWrapper}>
      <Loader />
    </div>
  );
};
