import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import { VbudusheeAPI } from './VbudusheeAPI';
import { authReducer } from './authSlice';
import { userReducer } from './userSlice';
import { tagsReducer } from './tagsSlice';
import { surveyReducer } from './surveySlice';
import { uploadDocumentReducer } from './documentSlice';
import { toastReducer } from './toast';

const persistUserConfig = {
  key: 'root',
  storage,
  whitelist: ['authorization'],
};

const persistedUserReducer = persistReducer(persistUserConfig, authReducer);

const reducers = combineReducers({
  authorization: persistedUserReducer,
  user: userReducer,
  tags: tagsReducer,
  uploadDoc: uploadDocumentReducer,
  survey: surveyReducer,
  toast: toastReducer,
  [VbudusheeAPI.reducerPath]: VbudusheeAPI.reducer,
});

//  Caching reducers
// const persistedReducer = persistReducer(persistUserConfig, reducers);

/**
 * App store
 */
export const createStore = () =>
  configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(VbudusheeAPI.middleware),
  });
