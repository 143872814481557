import { DateConfig } from '../../models/date';

export const formatDateStr = (
  dateStr: string | undefined,
  config?: DateConfig
): string => {
  if (dateStr) {
    const date = new Date(dateStr);
    const hours = date.getHours() > 9 ? date.getHours() : '0' + date.getHours();
    const minutes =
      date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes();
    const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
    const month = date.getMonth() + 1;
    const printMonth = month > 9 ? month : `0${month}`;
    const year = date.getFullYear();

    const outputDate = `${day}.${printMonth}.${year}`;
    const outputTime = `${hours}:${minutes}`;

    if (config?.isDateOnly) return outputDate;
    if (config?.isTimeOnly) return outputTime;
    return outputDate + ', ' + outputTime;
  }
  return '';
};

export const formatDateRange = (startDate: string, endDate: string) => {
  return `${formatDateStr(startDate, {
    isDateOnly: true,
  })} - ${formatDateStr(endDate, {
    isDateOnly: true,
  })}`;
};

export const dateStrToServerStr = (dateStr: string): string => {
  if (dateStr) {
    const newDate = new Date(dateStr);
    newDate.setHours(0, 0, 0, 0);
    return newDate.toISOString();
  }
  return '';
};

export const getEndOfDay = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);
  return newDate;
};

export const getStartOfDay = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

export const getOnlyDateByYear = (date: Date) => {
  const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
  const month = date.getMonth() + 1;
  const printMonth = month > 9 ? month : `0${month}`;
  const year = date.getFullYear();

  return `${year}-${printMonth}-${day}`;
};

export const validateDateFromCalendar = (date: string) =>
  date !== 'undefined' ? getOnlyDateByYear(new Date(date)) : undefined;
