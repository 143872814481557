/* eslint-disable no-console */
import React, { useEffect, useMemo, useState } from 'react';
import { Form, FormikContext, useFormik } from 'formik';

import { PIE_COLORS } from '../../../../../mocks/research';

//  ui
import { DropdownFilter } from '../../../../ui/Filters/DropdownFilter';
import { Card } from '../../../../ui/Card';
import { Typography } from '../../../../ui/Typography';
import { Box } from '../../../../ui/Box';
import { HalfDonutChart } from '../../../../ui/charts/HalfDonutChart';
import { BarChart } from '../../../../ui/charts/BarChart';
import { DonutChart } from '../../../../ui/charts/DonutChart';

import ParticipantsPic from '../../../../../assets/images/participants.png';
import ClockPic from '../../../../../assets/images/svg/clock.svg';

import styles from './ResearchDetailsReport.module.scss';
import { Button } from '../../../../ui/Button';
import { downloadDetailsStatsRow } from '../../../../../utils/api/downloadDetailsRaw';
import {
  useGetRegionDataQuery,
  useGetResearchDetailsMutation,
  useGetResearchFiltersMutation,
} from '../../../../../store/VbudusheeAPI';
import { sumObjValues, toKeyAndValue } from '../../../../../utils/array';
import { ColoredDictItem, DictItem } from '../../../../../models/basic';
import { ResearchDetailsReportCheckboxList } from './ResearchDetailsReportCheckboxList';
import { ResearchDetailsModal } from '../../../../ui/ResearchDetailsModal';
import {
  ResearchDetailsProgress,
  ResearchStatsResponse,
  ResearchStatsSurveyStatisticsAggr,
  SurveyStatisticsAggr,
} from '../../../../../models/research';
import { Company } from '../../../../../models/daData';
import { filterResearchProgress } from '../../../../../models/validations/schemas/research';
import { defaultFormikOptions } from '../../../../../models/formik';
import { Select } from '../../../../ui/Form/Select';
import { BarChartMethods } from '../../../../ui/charts/BarChartMethods';
import line from '../../../../../assets/images/line.svg';
import { lightenHexColor } from '../../../../../utils/colors';

type ResearchDetailsReportProps = {
  researchId?: string;
  excelDownload: boolean;
  researchDetailsProgress?: ResearchDetailsProgress;
  submitHandler: () => void;
  setInn: (value: string) => void;
  setRegionNumber: (value: string) => void;
};

export const ResearchDetailsReport = ({
  researchId,
  excelDownload,
  researchDetailsProgress,
  submitHandler,
  setInn,
  setRegionNumber,
}: ResearchDetailsReportProps): JSX.Element => {
  const [
    fetchResearchFilter,
    { data: researchFilterData, isLoading: isResearchFilterLoading },
  ] = useGetResearchFiltersMutation();

  const [resDataOtherStats, setResDataOtherStats] = useState<
    ResearchDetailsProgress | undefined
  >(undefined);
  const [isResearchDetailsModalOpen, setIsResearchDetailsModalOpen] =
    useState(false);

  const surveyProgress = useMemo(() => {
    const surveyStatisticsAggr =
      researchDetailsProgress?.survey_statistics_aggr;
    if (surveyStatisticsAggr && surveyStatisticsAggr.length > 0) {
      return surveyStatisticsAggr[0].data?.completion_progress;
    }
  }, [researchDetailsProgress]);

  const researchQuestions = researchFilterData?.research_questionnaries;

  const combinedQuestionsDetails = useMemo(() => {
    if (!researchQuestions || !surveyProgress?.detailed) return;
    const { resp_data_cnts: surveyProgressDetails, ...methods } =
      surveyProgress.detailed;

    const result = {} as Record<string, number>;
    for (const item in researchQuestions) {
      if (
        (Number(item) < 64 || Number(item) > 66) &&
        (Number(item) !== 13 || Number(item) !== 14) &&
        (Number(item) !== 49 || Number(item) !== 50) &&
        (Number(item) !== 33 || Number(item) !== 34) &&
        Number(item)
      ) {
        console.log(researchQuestions[item]);
        result[researchQuestions[item]] = surveyProgressDetails[item];
      }
    }

    console.log(researchQuestions);
    console.log(surveyProgressDetails);

    console.log(result);

    return { ...result, ...methods };
  }, [researchQuestions, surveyProgress?.detailed]);

  const surveyProgressBarData = useMemo(() => {
    if (!surveyProgress?.total) return [];

    return [
      {
        x: String(surveyProgress?.total.started.count),
        y: surveyProgress?.total.started.name,
      },
      {
        x: String(surveyProgress?.total.partial.count),
        y: surveyProgress?.total.partial.name,
      },
      {
        x: String(surveyProgress?.total.completed.count),
        y: surveyProgress?.total.completed.name,
      },
    ];
  }, [surveyProgress]);

  useEffect(() => {
    if (researchId) {
      fetchResearchFilter({ researchId });
      getResearchStats({ researchId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [researchId]);

  const initialValues = {
    organization_name: '',
    organization_region_number: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: filterResearchProgress,
    onSubmit: submitHandler,
    enableReinitialize: true,
    ...defaultFormikOptions,
  });

  useEffect(() => {
    setRegionNumber(formik.values.organization_region_number);
  }, [formik.values.organization_region_number]);

  useEffect(() => {
    if (formik.values.organization_name) {
      setInn(String(formik.values.organization_name));
    } else {
      setInn('');
    }
  }, [formik.values.organization_name]);

  const [researchStatsState, setResearchStatsState] =
    useState<ResearchStatsResponse>();
  const [isQuestionDataGotten, setQuestionDataGotten] =
    useState<boolean>(false);

  const [surveyActiveQuestionId, setSurveyActiveQuestionId] = useState<
    string | number | null
  >(null);
  const [checkedAnswers, setCheckedAnswers] = useState<ColoredDictItem[]>([]);

  const [getResearchStats, { data: researchStats, isLoading, isSuccess }] =
    useGetResearchDetailsMutation();

  useEffect(() => {
    if (!isQuestionDataGotten && researchStats) {
      setResearchStatsState(researchStats);
      setQuestionDataGotten(true);
    }
  }, [isSuccess]);

  const getResearchStatsArgs = () => {
    const arg = { researchId, body: {} };
    if (surveyActiveQuestionId && Number(surveyActiveQuestionId) !== 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      arg.body['question_id'] = surveyActiveQuestionId;
    }
    if (+formik.values.organization_region_number) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      arg.body['organization_region'] =
        formik.values.organization_region_number;
    }
    if (formik.values.organization_name && researchFilterData) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      arg.body['organization_name'] =
        researchFilterData.organizations_names[formik.values.organization_name];
    }
    if (checkedAnswers?.length > 0) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      arg.body['question_answer_bag'] = checkedAnswers.map((el) => {
        return el.key;
      });
    }

    return arg;
  };

  const questionsAnswersData = researchStatsState?.result
    ?.survey_statistics_aggr
    ? researchStatsState?.result?.survey_statistics_aggr[1]?.data
    : null;

  const donutDiagramData = (() => {
    if (checkedAnswers.length) {
      return checkedAnswers.map((answer, index) => ({
        key: index + 1,
        value: Number(answer.value),
        color: answer.color,
      }));
    }
  })();

  const surveyQuestions = toKeyAndValue(
    researchFilterData?.research_questionnaries
  );

  const surveyActiveOption =
    surveyQuestions.find((options) => options.key === surveyActiveQuestionId) ||
    null;

  const assignColorToAnswer = (answers: DictItem[]): ColoredDictItem[] => {
    let colorIdx = -1;
    const colors = [...PIE_COLORS];

    const result: ColoredDictItem[] = [];

    answers.forEach((answer) => {
      colorIdx++;

      if (!colors[colorIdx]) {
        colorIdx = 0;
      }

      const foundDublicate = result.find(
        (foundAnswer) => foundAnswer.color === colors[colorIdx]
      );

      if (!foundDublicate) {
        result.push({ ...answer, color: colors[colorIdx] });
      } else {
        const lightenColor = lightenHexColor(foundDublicate.color, 15);

        result.push({
          ...answer,
          color: lightenColor,
        });

        colors[colorIdx] = lightenColor;
      }
    });

    return result;
  };

  const activeQuestionAnswers =
    questionsAnswersData &&
    surveyActiveOption &&
    assignColorToAnswer(
      toKeyAndValue(questionsAnswersData[surveyActiveOption.key]).filter(
        (answer) => answer.key !== 'Нет ответа'
      )
    );

  const activeQuestionAnswersValueSum = activeQuestionAnswers?.reduce(
    (acc, answer) => acc + Number(answer.value),
    0
  );

  const surveyActiveQuestion =
    surveyQuestions.find((options) => options.key === surveyActiveQuestionId) ||
    null;

  useEffect(() => {
    getResearchStats(getResearchStatsArgs());
  }, [
    // surveyActiveQuestionId,
    checkedAnswers,
    formik.values.organization_region_number,
    formik.values.organization_name,
  ]);

  useEffect(() => {
    getResearchStats(getResearchStatsArgs())
      .unwrap()
      .then((res) => {
        setResearchStatsState(res);
      });
  }, [
    formik.values.organization_region_number,
    formik.values.organization_name,
  ]);

  const donutChartResult = checkedAnswers.length
    ? checkedAnswers.reduce((acc, answer) => acc + Number(answer.value), 0)
    : sumObjValues(questionsAnswersData ? questionsAnswersData['1'] : []);

  const handleToggleAnswer = (answer: ColoredDictItem) => {
    setCheckedAnswers((prev) => {
      const foundAnswer = prev.find((item) => item.key === answer.key);

      return foundAnswer
        ? prev.filter((item) => item.key !== answer.key)
        : [...prev, answer];
    });
  };

  const visibleRegions = useMemo(() => {
    if (researchFilterData) {
      return toKeyAndValue(researchFilterData?.organizations_regions);
    }
    return [];
  }, [researchFilterData]);

  const visibleOrganizations = useMemo(() => {
    if (researchFilterData) {
      return toKeyAndValue(researchFilterData?.organizations_names);
    }
    return [];
  }, [researchFilterData]);

  useEffect(() => {
    activeQuestionAnswers && setCheckedAnswers(activeQuestionAnswers);
  }, [surveyActiveQuestionId]);

  const members = useMemo(() => {
    let res = 0;
    if (resDataOtherStats?.survey_statistics_aggr) {
      resDataOtherStats.survey_statistics_aggr.map(
        (el: SurveyStatisticsAggr) => {
          if (el.name !== 'error') {
            res += el.data.other_stats.members;
          }
        }
      );
    }
    return res;
  }, [resDataOtherStats]);

  const statMeth = useMemo(() => {
    let res = null;
    if (researchStats?.result?.survey_statistics_aggr) {
      researchStats?.result?.survey_statistics_aggr.map(
        (el: ResearchStatsSurveyStatisticsAggr) => {
          if (el.name === 'meth_key_percent_confint') {
            res = el.data;
          }
        }
      );
    }
    return res;
  }, [researchStats]);

  const passPercent = useMemo(() => {
    let res = 0;
    if (resDataOtherStats?.survey_statistics_aggr) {
      const arr: number[] = [];
      resDataOtherStats.survey_statistics_aggr.map(
        (el: SurveyStatisticsAggr) => {
          if (el.name !== 'error') {
            arr.push(el.data.other_stats.pass_percent);
          }
        }
      );
      if (arr.length) {
        res = arr.reduce((acc, number) => acc + number, 0) / arr.length;
      }
    }
    return res;
  }, [resDataOtherStats]);

  const surveyMeanTime = useMemo(() => {
    let res = 0;
    if (resDataOtherStats?.survey_statistics_aggr) {
      const arr: number[] = [];
      resDataOtherStats.survey_statistics_aggr.map(
        (el: SurveyStatisticsAggr) => {
          if (el.name !== 'error') {
            arr.push(el?.data?.other_stats?.survey_mean_time);
          }
        }
      );
      if (arr.length) {
        res = arr.reduce((acc, number) => acc + number, 0) / arr.length;
      }
    }

    if (res === 0) return 'Нет данных';

    if (res >= 60) return `${Math.floor(res / 60)} мин.`;

    return `${Math.floor(res)} сек.`;
  }, [resDataOtherStats]);

  useEffect(() => {
    if (researchDetailsProgress) {
      setResDataOtherStats(researchDetailsProgress);
    }
  }, [researchDetailsProgress]);

  return (
    <div>
      <FormikContext.Provider value={formik}>
        <Form>
          <div className={styles.selects} style={{ zIndex: '4' }}>
            <Select
              name="organization_region_number"
              label="Регион исследования"
              options={visibleRegions}
              fullWidth
            />
            <Select
              name="organization_name"
              label="Название исследуемой организации"
              options={visibleOrganizations}
              fullWidth
            />
            {/*<SelectSearch*/}
            {/*  name="organization_region_number"*/}
            {/*  placeholder="Регион исследования"*/}
            {/*  options={*/}
            {/*    regions*/}
            {/*      ? regions.map((region) => {*/}
            {/*          return {*/}
            {/*            option: region.region_name,*/}
            {/*            value: region.region_number,*/}
            {/*          };*/}
            {/*        })*/}
            {/*      : []*/}
            {/*  }*/}
            {/*  fullWidth*/}
            {/*/>*/}
            {/*<InputCompanyName*/}
            {/*  name="organization_name"*/}
            {/*  placeholder="Название исследуемой организации"*/}
            {/*  setCompany={setCompany}*/}
            {/*  fullWidth*/}
            {/*/>*/}
          </div>
        </Form>
      </FormikContext.Provider>
      <div className={styles.blocks}>
        <Card>
          <div className={styles.wrapper}>
            <div className={styles.block}>
              <div>
                <Typography size="lg" color="main" fontWeight={600}>
                  Участники
                </Typography>
                <Box mt={10} />
                <Typography size="sm" color="main">
                  Количество ответивших на один вопрос и более
                </Typography>
                <Box mt={12} />
                <Typography size="xxxl" color="main" fontWeight={600}>
                  {researchDetailsProgress ? members : 'Нет данных'}
                </Typography>
              </div>
              <div className={styles.pic}>
                <div
                  className={styles.picture}
                  style={{
                    background: `url(${ParticipantsPic}) no-repeat center center`,
                    backgroundSize: 'contain',
                  }}
                ></div>
              </div>
            </div>
          </div>
        </Card>
        <Card>
          <div className={styles.wrapper}>
            <div className={styles.block}>
              <div>
                <Typography size="lg" color="main" fontWeight={600}>
                  Средний процент
                </Typography>
                <Box mt={10} />
                <Typography size="sm" color="main">
                  заполнения анкеты
                </Typography>
                <Box mt={12} />
                <Typography size="xxxl" color="main" fontWeight={600}>
                  {researchDetailsProgress
                    ? `${Math.round(passPercent * 100)} %`
                    : 'Нет данных'}
                </Typography>
              </div>
              <div className={styles.graphic}>
                <HalfDonutChart
                  width="200px"
                  height="100px"
                  percents={
                    researchDetailsProgress
                      ? +Number(passPercent * 100).toFixed(1)
                      : +Number(0 * 100).toFixed(1)
                  }
                />
              </div>
            </div>
          </div>
        </Card>
        <Card>
          <div className={styles.wrapper}>
            <div className={styles.block}>
              <div>
                <Typography size="lg" color="main" fontWeight={600}>
                  Прогресс заполнения опроса
                </Typography>
                <Box mt={10} />
                <ul className={styles.terms}>
                  {surveyProgressBarData && surveyProgressBarData.length > 0 ? (
                    surveyProgressBarData.map(({ y }) => (
                      <li className={styles.term} key={y}>
                        {y}
                      </li>
                    ))
                  ) : (
                    <Typography size="xxxl" color="main" fontWeight={600}>
                      Нет данных
                    </Typography>
                  )}
                </ul>
              </div>
              <div className={styles.graphWrapper}>
                <div
                  onClick={() => setIsResearchDetailsModalOpen(true)}
                  className={styles.detailsButton}
                >
                  Раскрыть детализацию
                </div>

                <div>
                  <div className={styles.graphic}>
                    <BarChart
                      data={surveyProgressBarData}
                      defaultMax={100}
                      height={110}
                      width={230}
                      margin={{ bottom: 10, left: 5, right: 40, top: 20 }}
                    />
                  </div>
                  <p className={styles.chartInfo}>кол-во респондентов</p>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <Card>
          <div className={styles.wrapper}>
            <div className={styles.block}>
              <div>
                <Typography size="lg" color="main" fontWeight={600}>
                  Прохождение опроса
                </Typography>
                <Box mt={10} />
                <Typography size="sm" color="main">
                  среднее время
                </Typography>
                <Box mt={12} />
                <Typography size="xxxl" color="main" fontWeight={600}>
                  {researchDetailsProgress ? surveyMeanTime : 'Нет данных'}
                </Typography>
              </div>
              <div className={styles.pic}>
                <div
                  className={styles.picture}
                  style={{
                    background: `url(${ClockPic}) no-repeat center center`,
                    backgroundSize: 'contain',
                  }}
                ></div>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <Box mt={24}>
        <Card>
          <div className={styles.wrapper}>
            <div className={styles.container}>
              <Typography size="lg" color="main" fontWeight={600}>
                Данные по вопросам
              </Typography>
              <Box mt={20} mb={16}>
                <DropdownFilter
                  label="Выберите вопрос"
                  options={surveyQuestions}
                  option={surveyActiveOption}
                  setValue={setSurveyActiveQuestionId}
                />
              </Box>
              <div className={styles.stat}>
                <DonutChart
                  data={donutDiagramData}
                  result={donutChartResult}
                  isLoading={isLoading}
                />
                <ResearchDetailsReportCheckboxList
                  activeQuestionAnswers={activeQuestionAnswers}
                  activeQuestionAnswersValueSum={activeQuestionAnswersValueSum}
                  checkedAnswers={checkedAnswers}
                  surveyActiveQuestion={surveyActiveQuestion}
                  handleToggleAnswer={handleToggleAnswer}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </Card>
      </Box>
      <Box mt={24}>
        <Card>
          <div className={styles.wrapper}>
            <div className={styles.block}>
              <div>
                <Box flex justifyContent={'space-between'}>
                  <Typography size="lg" color="main" fontWeight={600}>
                    Данные по методикам
                  </Typography>
                  <Box flex justifyContent={'space-between'}>
                    <Box flex>
                      <Box mr={8}>
                        <div className={styles.midlePicent}>61.6</div>
                      </Box>
                      <Typography size="s" color="main">
                        Средний балл
                      </Typography>
                    </Box>
                    <Box ml={30} flex>
                      <Box mr={8}>
                        <Typography
                          size="s"
                          color="main"
                          fontWeight={600}
                          align={'right'}
                        >
                          max
                        </Typography>
                      </Box>
                      <Typography size="s" color="main">
                        Максимальный балл
                      </Typography>
                    </Box>
                    <Box ml={30} flex>
                      <Box mr={8}>
                        <img
                          src={line}
                          alt="line"
                          style={{ transform: 'rotate(90deg)' }}
                        />
                      </Box>
                      <Typography size="s" color="main">
                        Доверительный интервал
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </div>
              <div className={styles.graphWrapper}>
                <div>
                  <div className={styles.graphic}>
                    {statMeth ? (
                      <Box mt={25} mb={30}>
                        <Typography
                          size="s"
                          color="main"
                          fontWeight={600}
                          align={'right'}
                        >
                          max
                        </Typography>
                        <BarChartMethods
                          data={surveyProgressBarData}
                          defaultMax={100}
                          statMeth={statMeth}
                        />
                      </Box>
                    ) : (
                      <Box mt={24} mb={24}>
                        <Typography size="lg" color="main" fontWeight={600}>
                          Нет данных
                        </Typography>
                      </Box>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Button
              label="Скачать первичные данные"
              gradient={true}
              disabled={!excelDownload}
              clicked={() => {
                if (researchId) {
                  downloadDetailsStatsRow(researchId);
                }
              }}
            />
          </div>
        </Card>
      </Box>

      <ResearchDetailsModal
        details={combinedQuestionsDetails}
        isOpen={isResearchDetailsModalOpen}
        onClose={() => setIsResearchDetailsModalOpen(false)}
      />
    </div>
  );
};
