export const IconDownload = (): JSX.Element => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.08171 9.29076L7.08171 1.26343" stroke="currentColor" />
      <path
        d="M9.02539 7.33899L7.08139 9.29099L5.13739 7.33899"
        stroke="currentColor"
      />
      <path
        d="M10.17 4.4187H10.792C12.1487 4.4187 13.248 5.51803 13.248 6.87537L13.248 10.1314C13.248 11.4847 12.1514 12.5814 10.798 12.5814L3.37138 12.5814C2.01471 12.5814 0.914713 11.4814 0.914713 10.1247L0.914713 6.86803C0.914713 5.51537 2.01205 4.4187 3.36471 4.4187H3.99271"
        stroke="currentColor"
      />
    </svg>
  );
};
