import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { ToastParams, ToastAction } from '../../models/components/toast';

type GetToastEvents = {
  toastlist: ToastParams[];
};

const initialState: GetToastEvents = {
  toastlist: [],
};

const tostColor = {
  success: 'rgba(222, 245, 227, 1)',
  danger: 'rgba(250, 221, 221, 1)',
  warning: 'rgba(255, 229, 205, 1)',
};

const toastSlice = createSlice({
  name: 'toast',
  initialState: initialState,
  reducers: {
    showToast: (state, action: PayloadAction<ToastAction>) => {
      if (action.payload.maxCount && action.payload.name) {
        if (
          state.toastlist.filter((el) => el.name === action.payload.name)
            .length < action.payload.maxCount
        ) {
          state.toastlist = [
            ...state.toastlist,
            {
              ...action.payload,
              id: state.toastlist.length + 1,
              backgroundColor: tostColor[action.payload.type],
              position: action.payload.position
                ? action.payload.position
                : 'top-center',
            },
          ];
        }
      } else if (action.payload.autocorrect && action.payload.name) {
        state.toastlist = [
          ...state.toastlist.filter((el) => el.name !== action.payload.name),
          {
            ...action.payload,
            id: state.toastlist.length + 1,
            backgroundColor: tostColor[action.payload.type],
            position: action.payload.position
              ? action.payload.position
              : 'top-center',
          },
        ];
      } else {
        state.toastlist = [
          ...state.toastlist,
          {
            ...action.payload,
            id: state.toastlist.length + 1,
            backgroundColor: tostColor[action.payload.type],
            position: action.payload.position
              ? action.payload.position
              : 'top-center',
          },
        ];
      }
    },
    deleteToast: (state, action: PayloadAction<number>) => {
      state.toastlist = state.toastlist.filter((e) => e.id !== action.payload);
    },
  },
});

export const { showToast, deleteToast } = toastSlice.actions;
export const { reducer: toastReducer } = toastSlice;
