import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
  useGetResearchIdQuery,
  // useGetResearchStatQuery,
} from '../../../store/VbudusheeAPI';

//  ui
import { Layout } from '../../../components/Layout';
import { Loader } from '../../../components/ui/Loader';
import { ResearchDetails } from '../../../components/pages/research/ResearchDetails';

export const ResearchPage = (): JSX.Element => {
  const { researchId } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isError } = useGetResearchIdQuery(
    { researchId },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (!researchId) {
      navigate('/404');
    }
  }, [navigate, researchId]);

  return (
    <Layout>
      {isLoading ? (
        <Loader.FullPage>
          <Loader />
        </Loader.FullPage>
      ) : (
        <>
          {data ? (
            <ResearchDetails details={data} researchId={researchId} />
          ) : (
            <div className="container">
              Здесь должен быть компонент-заглушка на случай ошибки запроса
              данных
            </div>
          )}
        </>
      )}
    </Layout>
  );
};
