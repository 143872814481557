import { useField } from 'formik';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Company } from '../../../../models/daData';
import classNames from 'classnames/bind';
import styles from './InputTin.module.scss';
import debounce from 'lodash.debounce';
const cx = classNames.bind(styles);

type InputTinTypes = {
  name: string;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  onChange?: () => void;
  setCompany: React.Dispatch<React.SetStateAction<Company | undefined>>;
  localityKladr?: string;
  organization_category?: string;
  fullWidth?: boolean;
};

export const InputTin = ({
  name,
  placeholder,
  type,
  disabled,
  setCompany,
  localityKladr,
  organization_category,
  fullWidth,
}: InputTinTypes) => {
  const DADATA_TOKEN = process.env.REACT_APP_DADATA_TOKEN;

  const [expanded, setExpanded] = useState(false);
  const [field, { error, touched, value }, { setValue, setError, setTouched }] =
    useField<string>(name);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [inputValue, setInputValue] = useState<string>(value || '');

  const isInvalid = useMemo(() => {
    return Boolean(error) && touched;
  }, [error, touched]);

  const handleClick = (company: Company) => {
    setCompany(company);
    setValue(company.value);
    setInputValue(String(company.data.inn));
  };

  const handleBlur = () => {
    setTimeout(() => setExpanded(false), 100);
  };

  const debouncedRequest = (val: string) => {
    const url =
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party';
    const token = DADATA_TOKEN;
    const query = val;
    const okved = getOKVED(organization_category);
    const locations = [{ kladr_id: localityKladr }];

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token ' + token,
      },
      body: JSON.stringify({ query, okved, locations }),
    };

    if (val) {
      fetch(url, options)
        .then(async (res) => {
          return res.json().then((resJson: { suggestions: Company[] }) => ({
            ok: res.ok,
            status: res.status,
            body: resJson,
          }));
        })
        .then((result) => {
          setCompanies(result.body.suggestions);
        })
        .catch(() => setError('Ошибка запроса к сервису DaData'));
    } else {
      setCompanies([]);
    }
    setTouched(true);
  };

  const debouncedChangeHandler = useCallback(
    debounce(debouncedRequest, 300),
    []
  );

  const handleValueChange = (val: string) => {
    setValue(val);
  };

  const debouncedValueChange = useCallback(
    debounce(handleValueChange, 500),
    []
  );

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const getOKVED = (organization_category: string | undefined) => {
    switch (organization_category) {
      case '85.1':
        return ['85', '85.1', '85.11', '85.12', '85.13', '85.13', '85.14'];
      case '85.2':
        return [
          '85.2',
          '85.21',
          '85.22',
          '85.22.1',
          '85.22.2',
          '85.22.3',
          '85.23',
        ];
      case '85.3':
        return ['85.3', '85.30'];
      case '85.4':
        return [
          '85.4',
          '85.41',
          '85.41.1',
          '85.41.2',
          '85.41.9',
          '85.42',
          '85.42.1',
          '85.42.2',
          '85.42.9',
        ];
      default:
        return [];
    }
  };

  return (
    <div
      className={cx('input', {
        input_invalid: isInvalid,
        input_disabled: disabled,
        input_fullwidth: fullWidth,
      })}
    >
      <input
        {...field}
        value={inputValue}
        onChange={(e) => {
          setCompanies([]);
          debouncedValueChange(e.target.value);
          setInputValue(e.target.value);
          debouncedChangeHandler(e.target.value);
        }}
        placeholder={placeholder}
        disabled={disabled}
        onBlur={handleBlur}
        onFocus={() => setExpanded(true)}
      />
      {isInvalid && <div className={cx('input__error')}>{error}</div>}

      <div
        className={cx('select', {
          select_hidden: companies.length === 0 || !expanded,
        })}
      >
        {companies.map((c) => (
          <div
            className={styles.select__item}
            key={c.data.inn}
            onClick={() => handleClick(c)}
          >
            {c.value}
          </div>
        ))}
      </div>
    </div>
  );
};
