import { IconTableEmpty } from '../../../../ui/Table/Empty/IconTableEmpty';
import styles from './ComparisonContentEmptyMethods.module.scss';

export const ComparisonContentEmptyMethods = () => {
  return (
    <div className={styles.wrapper}>
      <IconTableEmpty />
      <h2 className={styles.title}>Выберите другие исследования</h2>
      <p className={styles.subTitle}>
        В выбранных исследованиях нет одинаковых методик. Для просмотра
        <br />
        результатов на графике выберите такие исследования, в которых хотя бы
        <br />
        одна методика присутствует как в одном из них, так и в другом.
      </p>
    </div>
  );
};
