import React, { useCallback, useEffect } from 'react';
import styles from './Toast.module.scss';
import { ToastParams } from '../../../models/components/toast';
import { useTypedSelector } from '../../../store';
import { getToast } from '../../../store/toast/selectors';
import { useDispatch } from 'react-redux';
import { deleteToast } from '../../../store/toast';
import { IconCross } from '../Icons/IconCross';

export const Toast = () => {
  const dispatch = useDispatch();
  const toasts = useTypedSelector(getToast);
  const deleteIdToasts = useCallback(
    (id: number) => {
      dispatch(deleteToast(id));
    },
    [toasts, deleteToast]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (toasts.length) {
        deleteIdToasts(toasts[0].id);
      }
    }, toasts[0]?.timer || 2000);

    return () => {
      clearInterval(interval);
    };
  }, [toasts, deleteIdToasts]);

  return (
    <div
      className={`${styles.container} ${
        styles[`${toasts[0]?.position}-container` || '']
      }`}
    >
      {toasts.map((toast: ToastParams, i) => (
        <div
          key={`${toast.id}_${i}`}
          className={`${styles.notification} ${styles.toast} ${
            styles[toasts[0]?.position || '']
          }`}
          style={{ backgroundColor: toast.backgroundColor }}
        >
          <button onClick={() => deleteIdToasts(toast.id)}>
            <IconCross />
          </button>
          <div className={styles.containerText}>
            {toast.title && (
              <p
                className={`${styles.title}, ${
                  styles[`${toasts[0]?.position}-title` || '']
                }`}
              >
                {toast.title}
              </p>
            )}
            <p className={styles.description}>{toast.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
