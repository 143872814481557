export const IconHome = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.10477 13.8476V11.8032C6.10476 11.2831 6.5287 10.8606 7.05397 10.8571H8.97802C9.50579 10.8571 9.93363 11.2807 9.93363 11.8032V11.8032V13.854C9.93351 14.2955 10.2895 14.6564 10.7353 14.6667H12.018C13.2967 14.6667 14.3333 13.6405 14.3333 12.3746V12.3746V6.5586C14.3265 6.06059 14.0903 5.59294 13.6919 5.28873L9.30511 1.79024C8.53658 1.18108 7.4441 1.18108 6.67557 1.79024L2.30798 5.29508C1.90813 5.59806 1.67155 6.06648 1.66663 6.56495V12.3746C1.66663 13.6405 2.70321 14.6667 3.9819 14.6667H5.2646C5.72153 14.6667 6.09194 14.3 6.09194 13.8476V13.8476"
        stroke="#878B90"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
