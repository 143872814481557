export const IconZip = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7365 2.76172H8.08449C6.02449 2.76172 4.25049 4.43072 4.25049 6.49072V17.3397C4.25049 19.5157 5.90849 21.1147 8.08449 21.1147H16.0725C18.1325 21.1147 19.8025 19.3997 19.8025 17.3397V8.03772L14.7365 2.76172Z"
        stroke="#FF8A00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4739 2.75V5.659C14.4739 7.079 15.6229 8.231 17.0429 8.234C18.3589 8.237 19.7059 8.238 19.7969 8.232"
        stroke="#FF8A00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="12" y="11" width="12" height="7" fill="white" />
      <path
        d="M11 16.4956L13.656 12.7756H11.096V11.6396H15.408V12.3836L12.752 16.1036H15.456V17.2396H11V16.4956Z"
        fill="#FF8A00"
      />
      <path
        d="M16.5711 11.6396H17.8751V17.2396H16.5711V11.6396Z"
        fill="#FF8A00"
      />
      <path
        d="M19.3442 11.6556C19.5202 11.6503 19.6775 11.6449 19.8162 11.6396C19.9602 11.6289 20.1042 11.6209 20.2482 11.6156C20.3922 11.6103 20.5415 11.6076 20.6962 11.6076C20.8562 11.6023 21.0402 11.5996 21.2482 11.5996C21.6909 11.5996 22.0642 11.6369 22.3682 11.7116C22.6775 11.7809 22.9282 11.8929 23.1202 12.0476C23.3122 12.2023 23.4509 12.4049 23.5362 12.6556C23.6269 12.9009 23.6722 13.2023 23.6722 13.5596C23.6722 14.2849 23.4775 14.8183 23.0882 15.1596C22.6989 15.5009 22.0802 15.6716 21.2322 15.6716C21.1255 15.6716 21.0295 15.6716 20.9442 15.6716C20.8589 15.6663 20.7629 15.6583 20.6562 15.6476V17.2396H19.3442V11.6556ZM20.6562 14.5676C20.7629 14.5783 20.8562 14.5863 20.9362 14.5916C21.0215 14.5916 21.1202 14.5916 21.2322 14.5916C21.6375 14.5916 21.9202 14.5116 22.0802 14.3516C22.2402 14.1916 22.3202 13.9489 22.3202 13.6236C22.3202 13.4636 22.3042 13.3249 22.2722 13.2076C22.2402 13.0903 22.1815 12.9916 22.0962 12.9116C22.0109 12.8316 21.8962 12.7729 21.7522 12.7356C21.6135 12.6983 21.4375 12.6796 21.2242 12.6796C21.1175 12.6796 21.0215 12.6823 20.9362 12.6876C20.8562 12.6876 20.7629 12.6929 20.6562 12.7036V14.5676Z"
        fill="#FF8A00"
      />
    </svg>
  );
};
