import React from 'react';
import styles from './Content.module.scss';

//ui
import { About } from './About/About';
import { Options } from './Options/Options';
import { Functionality } from './Functionality/Functionality';
import { Registration } from './Registration/Registration';
import { InteractiveMap } from './InteractiveMap/InteractiveMap';
import { Security } from './Security/Security';
import { News } from './News/News';
import { Support } from './Support/Support';
import { useAuthorized } from '../../../../hooks/useAuthorized';

export const Content = () => {
  const { isAuthorized } = useAuthorized();

  return (
    <div className={styles.content}>
      <About />
      <Options />
      <Functionality />
      {!isAuthorized && <Registration />}
      <InteractiveMap />
      <Security />
      <News />
      <Support />
    </div>
  );
};
