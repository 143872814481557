import { Layout } from '../../components/Layout';
import { Typography } from '../../components/ui/Typography';
import { Button } from '../../components/ui/Button';
import { useNavigate } from 'react-router-dom';
import { frontendRoutes } from '../../utils/router/routes';
import styles from './NotFoundPage.module.scss';
import image from '../../assets/images/404_image.svg';

export const NotFoundPage = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Layout>
      <div className={styles.wrapper}>
        <div className={styles.wrapper__text_block}>
          <Typography
            className={styles.wrapper__number}
            font={'TTCommons'}
            size={'xxl'}
            fontWeight={500}
            customLineHeight={28}
            color={'gray5'}
          >
            404
          </Typography>
          <Typography
            className={styles.wrapper__header_text}
            // font={'SBSansDisplay'}
            size={'xxxxl'}
            fontWeight={600}
            customLineHeight={56}
            color={'totalBlack'}
          >
            Что-то пошло не так
          </Typography>
          <Typography
            font={'SBSansText'}
            size={'m'}
            fontWeight={400}
            customLineHeight={24}
            color={'black'}
          >
            Такой страницы больше нет или вы указали {<br />} неправильный
            адрес.
          </Typography>
          <div className={styles.wrapper__button}>
            <Button
              label="На главную"
              type={'button'}
              color={'gradient'}
              size={'medium'}
              clicked={() => navigate(frontendRoutes.mainPage)}
            ></Button>
          </div>
        </div>
        <div>
          <img src={image} alt="Lifebuoy custom image" />
        </div>
      </div>
    </Layout>
  );
};
