import { useEffect, useRef } from 'react';
import katex from 'katex';
import 'katex/dist/katex.min.css';

type MathExpressionProps = {
  expression: string;
};

export const MathExpression = ({ expression }: MathExpressionProps) => {
  const elRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    katex.render(expression, elRef.current!, {
      throwOnError: false,
      strict: false,
    });
  }, [expression]);

  return <div ref={elRef} />;
};
