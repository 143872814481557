import { useState } from 'react';
import classNames from 'classnames/bind';

//  ui
import { Typography } from '../Typography';
import { IconDropArrow } from '../Icons/Map/IconDropArrow';

import styles from './DropdownBlock.module.scss';
const cx = classNames.bind(styles);

type DropdownBlockProps = {
  isInitiallyOpen?: boolean;
  title: string;
  children: JSX.Element;
};

export const DropdownBlock = ({
  isInitiallyOpen = true,
  title,
  children,
}: DropdownBlockProps): JSX.Element => {
  const [isOpen, setOpen] = useState(isInitiallyOpen);

  return (
    <div className={styles.dropBlock}>
      <div className={styles.header}>
        <Typography color="main" size="sm" fontWeight={700}>
          {title}
        </Typography>
        <div
          className={cx('icon', {
            icon_open: isOpen,
          })}
          onClick={() => setOpen(!isOpen)}
        >
          <IconDropArrow />
        </div>
      </div>
      <div
        className={cx('body', {
          body_open: isOpen,
        })}
      >
        {children}
      </div>
    </div>
  );
};
