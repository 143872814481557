export const ArrowLeft = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.48302 6.56254L6.37502 10.4546C6.59469 10.6742 6.59469 11.0304 6.37501 11.25C6.15535 11.4697 5.79921 11.4697 5.57955 11.25L0.753795 6.42431C0.519479 6.18999 0.519479 5.81009 0.753794 5.57578L5.57953 0.750044C5.7992 0.530374 6.15535 0.530374 6.37503 0.750045C6.59469 0.969715 6.59469 1.32587 6.37502 1.54554L2.48302 5.43755L11.0625 5.43754C11.3732 5.43754 11.625 5.68938 11.625 6.00004C11.625 6.3107 11.3732 6.56254 11.0625 6.56254L2.48302 6.56254Z"
        fill="white"
        fillOpacity="0."
      />
    </svg>
  );
};
