export const ArrowRight = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.51698 6.56254L5.62498 10.4546C5.40531 10.6742 5.40531 11.0304 5.62499 11.25C5.84465 11.4697 6.20079 11.4697 6.42045 11.25L11.2462 6.42431C11.4805 6.18999 11.4805 5.81009 11.2462 5.57578L6.42047 0.750044C6.2008 0.530374 5.84465 0.530374 5.62497 0.750045C5.40531 0.969715 5.40531 1.32587 5.62498 1.54554L9.51698 5.43755L0.937475 5.43754C0.626815 5.43754 0.374975 5.68938 0.374975 6.00004C0.374975 6.3107 0.626815 6.56254 0.937475 6.56254L9.51698 6.56254Z"
        fill="white"
      />
    </svg>
  );
};
