import { Layout } from '../../../components/Layout';
import { DocumentExpert } from '../../../components/pages/document/DocumentExpert';

export const ExpertDocument = (): JSX.Element => {
  return (
    <Layout>
      <DocumentExpert />
    </Layout>
  );
};
