export const IconSurvey = () => {
  return (
    <svg
      width="141"
      height="158"
      viewBox="0 0 141 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2929 0.150667C30.9926 -0.936372 25.26 4.00051 25.1831 10.3853C24.9445 30.2036 22.8757 49.3069 19.0404 67.5665C14.8632 87.4285 8.62185 105.895 1.01 122.236C-1.73126 128.122 1.31818 135.19 7.61071 136.815C17.7385 139.429 32.2365 143.092 47.3012 146.693C64.3963 150.774 82.5802 155.115 94.6264 157.769C98.8998 158.71 103.276 156.724 105.285 152.841C114.829 134.393 122.613 112.903 127.87 90.4522C133.142 67.937 135.148 44.8344 133.362 23.8593C132.995 19.5487 129.73 16.0921 125.474 15.2828C121.467 14.5207 116.458 13.595 110.888 12.6313C88.9099 8.89181 57.5782 3.65061 37.2929 0.150667Z"
        fill="white"
      />
      <path
        d="M19.8233 67.7311L19.8233 67.7309C23.6699 49.418 25.7438 30.2628 25.9831 10.395C26.0543 4.4847 31.359 -0.0613327 37.1569 0.939019C49.638 3.09245 66.3025 5.90535 82.3958 8.62183C92.4591 10.3205 102.299 11.9814 110.753 13.4198C116.317 14.3825 121.321 15.3073 125.325 16.0687C129.246 16.8144 132.23 19.9919 132.565 23.9272C134.343 44.8071 132.346 67.8248 127.091 90.2698C121.847 112.663 114.085 134.09 104.574 152.473C102.736 156.026 98.7275 157.853 94.7985 156.987C82.7727 154.339 64.621 150.005 47.5377 145.927L47.4872 145.915C32.4284 142.315 17.9351 138.653 7.81065 136.04C2.03736 134.55 -0.810336 128.039 1.73519 122.574C9.37334 106.176 15.6337 87.6517 19.8233 67.7311Z"
        stroke="#333F48"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.578 115.912L85.2244 111.115L95.2398 136.788L91.6148 139.099C88.9158 140.82 85.3174 139.596 84.2263 136.587L83.2349 133.853L76.578 115.912Z"
        fill="url(#paint0_linear_11319_144268)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M137.351 98.6115L137.563 111.448L91.9364 138.754C89.1533 140.42 85.5454 139.036 84.5904 135.936L83.3791 132.005L137.351 98.6115Z"
        fill="url(#paint1_linear_11319_144268)"
      />
      <path
        d="M107.421 36.6874C107.343 36.6874 107.304 36.6874 107.227 36.6874L43.9723 24.9634C43.4284 24.847 43.0787 24.3423 43.1953 23.7988C43.3118 23.2553 43.8169 22.9059 44.3609 23.0224L107.615 34.7463C108.159 34.8628 108.509 35.3675 108.392 35.911C108.314 36.3768 107.887 36.6874 107.421 36.6874Z"
        fill="#333F48"
      />
      <path
        d="M116.762 56.5708C116.722 56.5708 116.683 56.5708 116.683 56.5708L40.9185 42.5952C40.6465 42.5564 40.4523 42.2847 40.53 42.0129C40.6077 41.7411 40.8408 41.5471 41.1128 41.6247L116.878 55.6002C117.15 55.6391 117.344 55.9109 117.266 56.1826C117.189 56.4155 116.995 56.5708 116.762 56.5708Z"
        fill="#333F48"
      />
      <path
        d="M111.387 82.3124C111.348 82.3124 111.309 82.3124 111.27 82.3124L37.0204 67.4054C36.7484 67.3666 36.5931 67.0948 36.6319 66.8231C36.6708 66.5513 36.9428 66.396 37.2147 66.4349L111.504 81.3034C111.775 81.3425 111.931 81.6137 111.891 81.8857C111.853 82.1578 111.62 82.3124 111.387 82.3124Z"
        fill="#333F48"
      />
      <path
        d="M104.144 67.4116C104.106 67.4116 104.066 67.4116 104.066 67.4116L38.8309 54.5231C38.559 54.4842 38.4036 54.2125 38.4424 53.9408C38.4812 53.669 38.7532 53.5138 39.0252 53.5525L104.26 66.4412C104.533 66.4799 104.688 66.7517 104.649 67.0235C104.572 67.2564 104.377 67.4116 104.144 67.4116Z"
        fill="#333F48"
      />
      <path
        d="M98.7678 93.153C98.7296 93.153 98.6904 93.153 98.6513 93.153L34.8924 79.333C34.6205 79.2557 34.4651 79.0228 34.504 78.7507C34.5816 78.4787 34.8148 78.324 35.0868 78.3622L98.8462 92.1831C99.1175 92.2604 99.2732 92.4934 99.2341 92.7654C99.2341 92.9984 99.001 93.153 98.7678 93.153Z"
        fill="#333F48"
      />
      <path
        d="M50.7363 115.337C50.6974 115.337 50.6585 115.337 50.6197 115.337L23.4609 109.242C23.1889 109.164 23.0334 108.931 23.0723 108.659C23.15 108.388 23.3831 108.233 23.6551 108.272L50.8139 114.366C51.086 114.444 51.2413 114.677 51.2025 114.948C51.1636 115.181 50.9694 115.337 50.7363 115.337Z"
        fill="#333F48"
      />
      <path
        d="M38.7825 123.645C38.7436 123.645 38.7048 123.645 38.6659 123.645L19.3944 119.258C19.1225 119.18 18.967 118.947 19.0059 118.676C19.0836 118.404 19.3167 118.248 19.5887 118.287L38.8602 122.674C39.1322 122.752 39.2876 122.985 39.2487 123.256C39.2098 123.489 39.0156 123.645 38.7825 123.645Z"
        fill="#333F48"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11319_144268"
          x1="86.2698"
          y1="141.062"
          x2="78.2341"
          y2="116.545"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CBF8EB" />
          <stop offset="0.496309" stopColor="#CAF8EA" />
          <stop offset="1" stopColor="#C7F7E9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11319_144268"
          x1="133.582"
          y1="126.304"
          x2="88.1672"
          y2="114.135"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E0F1FE" />
          <stop offset="0.496309" stopColor="#D4F8EF" />
          <stop offset="1" stopColor="#C7F7E9" />
        </linearGradient>
      </defs>
    </svg>
  );
};
