import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableHeader } from '../../../../models/components/table';
import { materialEventsFiltersInitialValues } from '../../../../models/validations/schemas/validations';
import { frontendRoutes } from '../../../../utils/router/routes';
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { HMenu } from '../../../ui/HMenu';
import styles from './MaterialsEvents.module.scss';
import { MaterialsEventsFilters } from './MaterialsEventsFilters';
import {
  useGetDictionaryQuery,
  useGetEventsQuery,
} from '../../../../store/VbudusheeAPI';
import { PublicEvent } from '../../../../models/pages/events';
import {
  formatDateStr,
  validateDateFromCalendar,
} from '../../../../utils/dates';
import { TableExpanded } from '../../../ui/Table';
import { Loader } from '../../../ui/Loader';
import { Region } from '../../../../models/research';
import { Pagination } from '../../../ui/Pagination';

export const MaterialsEvents = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<
    typeof materialEventsFiltersInitialValues
  >(materialEventsFiltersInitialValues);

  const paginationOptions = useMemo(() => {
    return {
      event_name: filters.search || undefined,
      event_user_role: filters.role || undefined,
      event_status: filters.status || undefined,
      event_publication: filters.publicationStatus || undefined,
      event_start_date: filters.dates
        ? validateDateFromCalendar(filters.dates.split(';')[0])
        : undefined,
      event_end_date: filters.dates
        ? validateDateFromCalendar(filters.dates.split(';')[1])
        : undefined,
      event_tags: filters.tags.length
        ? filters.tags.map((tag: Region) => tag.region_number)
        : undefined,
      isMyEvents: true,
      page,
    };
  }, [filters, page]);

  const { data, isFetching, isError } = useGetEventsQuery(paginationOptions, {
    refetchOnMountOrArgChange: true,
  });
  const { data: dictionary } = useGetDictionaryQuery({});

  const eventsData = data?.data || [];

  const totalEvents = data?.meta.total || 0;
  const eventsPerPage = data?.meta.per_page || 1;
  const totalPages = Math.ceil(totalEvents / eventsPerPage);

  const currentPage = data?.meta.current_page || 0;

  const handleResetPage = () => {
    setPage(1);
  };

  const headers: TableHeader[] = [
    { label: 'Название мероприятия', width: '29%' },
    { label: 'Сроки проведения', width: '28%' },
    { label: 'Статус проведения', width: '22%' },
    { label: 'Статус публикации', width: '21%' },
  ];

  const menuLinks = [
    {
      label: 'Мероприятия',
      onClick: () => navigate(frontendRoutes.materials.events),
      active: true,
      access: true,
    },
    {
      label: 'Исследования',
      onClick: () => navigate(frontendRoutes.materials.research),
      active: false,
      access: true,
    },
    {
      label: 'Документы',
      onClick: () => navigate(frontendRoutes.materials.docs),
      active: false,
      access: true,
    },
    {
      label: 'Обратная связь',
      onClick: () => navigate(frontendRoutes.materials.feedback),
      active: false,
      access: true,
    },
  ];

  return (
    <div className={styles.team}>
      <div className="local-container">
        <Breadcrumbs links={[{ title: 'Мои материалы' }]} homeLink="/" />
      </div>
      <div className={styles.team__header}>Мои материалы</div>
      <div className={styles.team__navigation}>
        <HMenu links={menuLinks} />
      </div>
      <div className={styles.team__filters}>
        <MaterialsEventsFilters
          eventsRolesDict={dictionary?.events.role}
          eventsStatusDict={dictionary?.events.event_status}
          setFilters={setFilters}
          handleResetPage={handleResetPage}
        />
      </div>
      <div className="container">
        <TableExpanded headers={headers}>
          {isFetching ? (
            <Loader.FullPage>
              <Loader />
            </Loader.FullPage>
          ) : isError || eventsData?.length < 1 ? (
            <TableExpanded.NoTable
              isError={isError}
              errorTitle="Ошибка загрузки данных"
              errorText="Мы не смогли загрузить данные таблицы. Попробуйте перезагрузить страницу или выбрать другие фильтры."
              dataLength={eventsData?.length}
            />
          ) : (
            <>
              {eventsData.map((pEvent: PublicEvent) => (
                <TableExpanded.Row key={pEvent.event_id} headers={headers}>
                  <TableExpanded.Col>
                    <span
                      className="long-string"
                      onClick={() =>
                        navigate(
                          `${frontendRoutes.publicEventsCatalog.events}/${pEvent.event_id}`
                        )
                      }
                      title={pEvent.event_name}
                    >
                      {pEvent.event_name}
                    </span>
                  </TableExpanded.Col>
                  <TableExpanded.Col>
                    <span className="long-string">
                      {formatDateStr(pEvent?.event_start_date, {
                        isDateOnly: true,
                      })}{' '}
                      -{' '}
                      {formatDateStr(pEvent?.event_end_date, {
                        isDateOnly: true,
                      })}
                    </span>
                  </TableExpanded.Col>
                  <TableExpanded.Col>
                    <span className="long-string">
                      {
                        dictionary?.events.event_status[
                          pEvent?.event_status || ''
                        ]
                      }
                    </span>
                  </TableExpanded.Col>
                  <TableExpanded.Col>
                    <span className="long-string">
                      {pEvent?.event_publication
                        ? 'Опубликовано'
                        : 'Не опубликовано'}
                    </span>
                  </TableExpanded.Col>
                  <TableExpanded.Expanded
                    items={[
                      {
                        itemTitle: 'Роль',
                        itemData: Array.isArray(pEvent?.event_user_role)
                          ? pEvent?.event_user_role
                              ?.map(
                                (role) => dictionary?.events.role[role] ?? ''
                              )
                              .join(', ') ?? 'Нет роли'
                          : dictionary?.events.role[
                              pEvent?.event_user_role ?? ''
                            ] ?? 'Нет роли',
                      },
                      {
                        itemTitle: 'Количество участников',
                        itemData: `${pEvent?.event_members ?? 0}`,
                      },
                      {
                        itemTitle: 'Документы',
                        itemData:
                          dictionary?.events.event_document_type[
                            pEvent?.event_document_type || ''
                          ] || 'Нет данных',
                      },
                      {
                        itemTitle: 'Тематические теги',
                        itemData:
                          pEvent?.event_tags
                            .map((tag) => tag.name)
                            .join(', ') || 'Нет тегов',
                      },
                    ]}
                  />
                </TableExpanded.Row>
              ))}
            </>
          )}
        </TableExpanded>
        <div className={styles.pagination}>
          {!isFetching && (
            <Pagination
              setPage={setPage}
              currentPage={currentPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>
    </div>
  );
};
