import { forwardRef, useState } from 'react';
import styles from './DocumentPreview.module.scss';
import { Document, Page, pdfjs } from 'react-pdf';
// eslint-disable-next-line import/no-unresolved
import 'react-pdf/dist/Page/TextLayer.css';
// eslint-disable-next-line import/no-unresolved
import 'react-pdf/dist/Page/AnnotationLayer.css';

type DocumentPreviewProps = {
  id: number;
  width: React.IframeHTMLAttributes<HTMLIFrameElement>['width'];
  height: React.IframeHTMLAttributes<HTMLIFrameElement>['height'];
};

export const DocumentPreview = forwardRef<
  HTMLIFrameElement,
  DocumentPreviewProps
>(function DocumentPreview({ id, width, height }, iframeRef) {
  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url
  ).toString();
  const [error, setError] = useState<string>();
  const [numPages, setNumPages] = useState<number>(0);
  const [options] = useState({
    httpHeaders: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  const translateErrors: { [k: string]: string } = {
    MissingPDFException: 'Оригинал файла не найден',
  };
  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }
  function onDocumentLoadError(error: Error): void {
    setError(error.name);
  }

  return (
    <div className={styles.iframeWrapper} style={{ width, height }}>
      <Document
        onLoadError={onDocumentLoadError}
        file={`${process.env.REACT_APP_API_URL}/documents/${id}/pdf`}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
        error={error && translateErrors[error]}
        className={styles.iframeDocument}
      >
        {Array.from({ length: numPages }).map((i, index) => (
          <Page key={index} pageNumber={index + 1} />
        ))}
      </Document>
    </div>
  );
});
