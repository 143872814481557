import classNames from 'classnames/bind';

//ui
import { RadioButton } from '../../Form/RadioButton';
import { Survey } from '../../../../models/survey/survey';
import styles from './RadioButtonScaleType.module.scss';
import { FieldArray } from 'formik';

const cx = classNames.bind(styles);

type RadioButtonScaleProps = {
  questionTitle: string;
  questions: Survey[];
  position: 'vertical' | 'horizontal';
  name: string;
  disabled?: boolean;
};

export const RadioButtonScaleType = (props: RadioButtonScaleProps) => {
  const { questionTitle, questions, position, name, disabled } = props;

  const arrNumbers: Array<number> = [];
  if (questions[0].field.min_alias && questions[0].field.max_alias) {
    for (
      let i = questions[0].field.min_alias;
      i <= questions[0].field.max_alias;
      i++
    ) {
      arrNumbers.push(i);
    }
  } else {
    for (let i = questions[0].field.min!; i <= questions[0].field.max!; i++) {
      arrNumbers.push(i);
    }
  }

  function getFirstWord(string: string) {
    const firstWord: Array<string> = [];
    for (let i = 0; i < string.length; i++) {
      if (string[i] !== ' ') {
        firstWord.push(string[i]);
      } else {
        break;
      }
    }
    return firstWord.join('');
  }
  const firstWord = getFirstWord(questionTitle);

  return (
    <FieldArray name={name}>
      {(fieldArrayProps) => {
        const { push, remove, name } = fieldArrayProps;
        // const { values } = form;
        // const { question_input_type, question_answers } = values;

        return (
          <div className={cx('radiobuttonScaleType')}>
            <div className={cx('radiobuttonScaleType__title')}>
              {/*<span className={cx('firstWord')}>{firstWord}</span>*/}
              {/*{questionTitle.replace(firstWord, '')}*/}
              {questionTitle}
            </div>
            {questions.map((question, index) => {
              return (
                <div key={index} className={cx('radiobuttonScaleType__item')}>
                  <div className={cx('questionNumber')}>
                    {question.continuousNumbering ? question.index : index + 1}
                  </div>
                  <div className={cx('minQuestion')}>
                    {question.field.min_label}
                  </div>
                  <div className={cx('wrapperRadioButton')}>
                    <div className={cx('wrapperRadioButton__item')}>
                      <div className={cx('indexNumber')}>
                        {arrNumbers.map((number: number, index: number) => (
                          <div key={index} className={cx('indexNumber__item')}>
                            {Math.abs(number)}
                            <div className={cx('divider')} />
                          </div>
                        ))}
                      </div>
                      <div className={cx('groupRadioButton')}>
                        <RadioButton
                          name={`${name}[${index}]`}
                          isRow={true}
                          withoutVisibleValue
                          number={question.field.max! - question.field.min! + 1}
                          disabled={disabled}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={cx('maxQuestion')}>
                    {question.field.max_label}
                  </div>
                </div>
              );
            })}
          </div>
        );
      }}
    </FieldArray>
  );
};
