import { Box } from '../Box';
import { Typography } from '../Typography';
import { Button } from '../Button';
import { Textarea } from '../Form/Textarea/Textarea';
import { CheckBox } from '../Form/CheckBox';
import { RadioButton } from '../Form/RadioButton';
import { InputText } from '../Form/InputText';
import { Select } from '../Form/Select';
import { Toggle } from '../Toggle';
import { QuestionItemDadata } from '../QuestionItemDadata';

type PropsTypes = {
  id: number;
  title: string;
  description?: string[];
  questionType: string;
  questionNumber?: number | string;
  active?: boolean;
  required?: boolean;
  sizeTitle?: 's' | 'xs' | 'sm' | 'm' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'xxxxl';
  upperCase?: boolean;
  sizeAnswers?:
    | 's'
    | 'xs'
    | 'sm'
    | 'm'
    | 'lg'
    | 'xl'
    | 'xxl'
    | 'xxxl'
    | 'xxxxl';
  disable?: boolean;
  questionOrder?: string | null;
  setIsDelete?: (value: string | number) => void;
  setRequired?: () => void;
  daDataId?: { [x: string]: number } | undefined;
  questionSlug?: string;
  showSwitch?: boolean;
  isStructure?: boolean;
};

export const QuestionItem = ({
  id,
  title,
  description,
  questionType,
  questionNumber,
  active = true,
  sizeTitle = 'sm',
  upperCase = false,
  sizeAnswers = 'sm',
  disable = false,
  questionOrder,
  setIsDelete,
  required,
  setRequired,
  daDataId,
  questionSlug,
  showSwitch = true,
  isStructure,
}: PropsTypes) => {
  return (
    <Box mb={20}>
      <Box flex justifyContent={'space-between'}>
        <>
          {questionNumber && (
            <Box flex>
              <Box mr={20}>
                <Typography size="sm" color="gray">
                  {`${questionNumber} вопрос`}
                </Typography>
              </Box>
              {showSwitch ? (
                <Box flex>
                  <Toggle
                    isActive={required}
                    clicked={() => setRequired?.()}
                    disable={!!questionOrder || disable}
                  />
                  <Box ml={10}>
                    <span>Обязательный вопрос</span>
                  </Box>
                </Box>
              ) : (
                <></>
              )}
            </Box>
          )}
          {setIsDelete && !disable && (
            <Button
              label="Удалить"
              display="service"
              type={'button'}
              color="danger"
              size="s"
              clicked={() => setIsDelete(id)}
            />
          )}
        </>
      </Box>
      <Box mb={12} />
      <Typography
        size={sizeTitle}
        color="main"
        upperCase={upperCase}
        fontWeight={600}
      >
        {title}
        {required && (
          <span style={{ display: 'inline', color: '#ee505a' }}>*</span>
        )}
      </Typography>
      <Box mt={12}>
        <>
          {
            questionType?.trim() === 'checkbox' && (
              // item.description.map((el, i) => (
              //   <Box key={`var-${i}`} flex mb={12}>
              //     <Box key={`var-${i}`} mr={10}>
              <CheckBox
                themeGradient={true}
                type="survey"
                name={`${id}`}
                values={description}
                disabled={!active}
                required={required}
              />
            )
            // </Box>
            // <Typography size={sizeAnswers} color="main">
            //   {el}
            // </Typography>
            // </Box>
            // ))
          }
          {questionType?.trim() === 'radio' && (
            <RadioButton
              name={`${id}`}
              values={description?.map((el, i) => {
                return {
                  key: `${i + 1}`,
                  value: el,
                };
              })}
              disabled={!active}
            />
          )}
          {!isStructure && questionType?.trim() === 'input' && (
            <Textarea
              name={`${id}`}
              placeholder="Ввод значения"
              editable={active && !disable}
              required={required}
            />
          )}
          {!isStructure && questionType?.trim() === 'integer' && (
            <InputText
              name={`${id}`}
              type="number"
              placeholder="Ввод значения"
              disabled={!active}
              required={required}
            />
          )}
          {questionType?.trim() === 'select' && !active && (
            <RadioButton
              name={`${id}`}
              values={description?.map((el, i) => {
                return {
                  key: `${i + 1}`,
                  value: el,
                };
              })}
              disabled={!active}
            />
          )}

          {questionType?.trim() === 'select' && active && (
            <Select
              name={`${id}`}
              label="Выберите вариант"
              options={
                description
                  ? description.map((el, i) => {
                      return {
                        key: String(el),
                        value: el,
                      };
                    })
                  : []
              }
              fullWidth
              disabled={!active}
              required={required}
            />
          )}
          {!isStructure && questionType?.includes('dadata') && !active && (
            <InputText
              name={`${id}`}
              type="text"
              placeholder="Ввод значения"
              disabled
            />
          )}
          {!isStructure && questionType?.includes('dadata') && active && (
            <QuestionItemDadata
              id={id}
              daDataId={daDataId}
              questionSlug={questionSlug}
            />
          )}
        </>
      </Box>
    </Box>
  );
};
