import { useMemo } from 'react';
import { useField } from 'formik';

import classNames from 'classnames/bind';
import styles from './InputText.module.scss';

const cx = classNames.bind(styles);

type InputTextTypes = {
  name: string;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  modifiedClassName?: boolean;
  value?: string;
  absolute?: boolean;
  required?: boolean;
  redRequired?: boolean;
  maxLength?: number;
};

export const InputText = ({
  name,
  placeholder,
  type,
  disabled,
  fullWidth,
  absolute = false,
  required = true,
  redRequired,
  maxLength,
}: InputTextTypes) => {
  const [field, { error, touched }] = useField<string>(name);

  const isInvalid = useMemo(() => {
    return Boolean(error) && touched;
  }, [error, touched]);

  return (
    <div
      className={cx('input', {
        input_invalid: isInvalid && required,
        input_disabled: disabled,
        input_fullwidth: fullWidth,
      })}
    >
      <input
        {...field}
        type={type}
        placeholder={!redRequired ? placeholder : ''}
        disabled={disabled}
        maxLength={maxLength}
      />
      {!field.value && redRequired && (
        <span className={styles.input__required}>
          {placeholder?.slice(0, placeholder.length - 1)}
          <span className={styles.input__required__sign}>
            {placeholder?.slice(placeholder.length - 1, placeholder.length)}
          </span>
        </span>
      )}
      {isInvalid && required && (
        <div
          className={cx('input__error', {
            input__error_absolute: absolute,
          })}
        >
          {error}
        </div>
      )}
    </div>
  );
};
