import React, { useEffect, useState } from 'react';
import styles from './SurveyLogin.module.scss';
import { Breadcrumbs } from '../../ui/Breadcrumbs';
import { frontendRoutes } from '../../../utils/router/routes';
import { EmailForm } from './EmailForm/EmailForm';
import { PasswordForm } from './PasswordForm/PasswordForm';

export const SurveyLogin = () => {
  const [isEmail, setIsEmail] = useState<boolean>(false);
  const title = 'Вход';

  return (
    <div className={styles.surveyLogin}>
      <div className="local-container">
        <Breadcrumbs
          links={[
            {
              title: 'Ввод логина респондента',
              link: frontendRoutes.survey.login,
            },
          ]}
          homeLink={frontendRoutes.mainPage}
        />
      </div>
      <span className={styles.surveyLogin__header}>{title}</span>
      <div className={styles.surveyLogin__form}>
        {!isEmail ? <EmailForm setIsEmail={setIsEmail} /> : <PasswordForm />}
      </div>
    </div>
  );
};
