import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { DocType, DocEditType } from '../../models/document';

type DocumentEditType = {
  document: DocEditType;
  uploadProgress: number;
};

const initialState: DocumentEditType = {
  uploadProgress: 0,
  document: {
    parts: [],
  },
};
const uploadDocumentSlice = createSlice({
  name: 'uploadDocument',
  initialState,
  reducers: {
    uploadDocument: (
      state,
      action: PayloadAction<DocType & { index: number }>
    ) => {
      const { index, ...info } = action.payload;
      state.document.parts[index] = info;
    },
    setUploadProgress: (state, action) => {
      return {
        ...state,
        uploadProgress: action.payload,
      };
    },
    resetUploadProgress: (state) => {
      return {
        ...state,
        uploadProgress: 0,
      };
    },
    resetMarkup: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  uploadDocument,
  resetMarkup,
  setUploadProgress,
  resetUploadProgress,
} = uploadDocumentSlice.actions;
export const { reducer: uploadDocumentReducer } = uploadDocumentSlice;
