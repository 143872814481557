import styles from './DocumentRating.module.scss';
import { EventRatingType } from '../../../../models/document';

//  ui
import { Typography } from '../../../ui/Typography';
import { Box } from '../../../ui/Box';
import { Rating } from '../../../ui/Rating';

type DocumentRatingProps = {
  name: string;
  number: number;
  id: number;
  description?: string;
  withRating?: boolean;
  value?: number;
  onChange: (e: EventRatingType) => void;
};

export const DocumentRating = ({
  id,
  number,
  description,
  withRating = false,
  value = 0,
  onChange,
}: DocumentRatingProps): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <Box mb={12}>
        <Typography
          size="s"
          color="main"
          fontWeight={600}
          upperCase
          customLineHeight={22}
        >
          Критерий {number}
        </Typography>
      </Box>
      <Box mb={20}>
        <Typography size="s" color="main" customLineHeight={16}>
          {description ? description : ''}
        </Typography>
      </Box>
      {withRating && (
        <Box mb={24}>
          <Rating id={id} scale={10} onChange={onChange} value={value} />
        </Box>
      )}
    </div>
  );
};
