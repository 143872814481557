import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { AppState } from '../types';

const selectSelf = (state: AppState) => state.toast;

export const getToast = createDraftSafeSelector(
  selectSelf,
  (store) => store.toastlist
);
