import { useNavigate } from 'react-router-dom';

import { frontendRoutes } from '../../../utils/router/routes';

//  ui
import { Typography } from '../Typography';
import { Box } from '../Box';
import { Button } from '../Button';

import styles from './AccessDeniedModal.module.scss';

export const AccessDeniedModal = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div className={styles.wrapper}>
      <Box mt={20} mb={40}>
        <Typography color="main" size="xl">
          Для участия вы должны зарегистрироваться как исследователь
        </Typography>
      </Box>
      <div className={styles.bottom}>
        <Button
          label="Зарегистрироваться"
          clicked={() => navigate(frontendRoutes.user.chooseRegType)}
        />
      </div>
    </div>
  );
};
