import classNames from 'classnames/bind';

import styles from './Card.module.scss';
const cx = classNames.bind(styles);

type CardProps = {
  children: JSX.Element;
  color?: 'main' | 'primary';
  shadow?: boolean;
  padding?: 'primary' | 'small';
};

export const Card = ({
  children,
  color,
  shadow,
  padding = 'primary',
}: CardProps): JSX.Element => {
  return (
    <div
      className={cx('card', {
        card_main: color === 'main',
        card_shadow: shadow,
        card_small: padding === 'small',
      })}
    >
      {children}
    </div>
  );
};
