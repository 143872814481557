import { useNavigate } from 'react-router-dom';
import { frontendRoutes } from '../../../../utils/router/routes';
import { Box } from '../../../ui/Box';
import { Button } from '../../../ui/Button';
import { Typography } from '../../../ui/Typography';

import styles from './TeamDescription.module.scss';
import defaultTeamLogo from '../../../../assets/images/defaultTeamLogo.svg';

export const TeamDescription = (team: any): JSX.Element => {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL?.substring(0, 25);

  const editAccess =
    team.team_user_role === 'owner' || team.team_user_role === 'moderator';

  const formatingDate = (rawDate: string) => {
    const formatedDate = new Date(rawDate);
    let month = '' + (formatedDate.getMonth() + 1);
    let day = '' + formatedDate.getDate();
    const year = formatedDate.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('.');
  };

  const getLocalRoleName = (role: string) => {
    switch (role) {
      case 'owner':
        return 'Создатель';
      case 'member':
        return 'Участник';
      case 'moderator':
        return 'Модератор';
      default:
        return role;
    }
  };

  return (
    <>
      <div className={styles.headers_wraper}>
        <div className={styles.headers_wraper__header}>
          <Typography
            color="main"
            size="xxxl"
            font="SFPRoDisplay"
            fontWeight={600}
          >
            {team.team_name}
          </Typography>
        </div>
        <div className={styles.buttons}>
          <div
            className={styles.buttons__elements}
            onClick={() => navigate(frontendRoutes.teams.teams)}
          >
            <Button
              label="Каталог команд"
              withoutBackground={true}
              size={'medium'}
            />
          </div>
          {editAccess && (
            <div
              className={styles.buttons__elements}
              onClick={() =>
                navigate(`${frontendRoutes.teams.team.edit}/${team?.team_id}`)
              }
            >
              <Button label="Редактировать" gradient={true} size={'medium'} />
            </div>
          )}
        </div>
      </div>

      <div className="details__body">
        <Typography color="main" fontWeight={600} size="s" upperCase>
          Основная информация о команде
        </Typography>
        <Box mb={33} />
        <div className="row">
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Фотография
                </Typography>
              </div>
              <div className={styles.logo_wraper}>
                <img
                  src={
                    team?.team_icon_path !== '' &&
                    !(team?.team_icon_path === null) &&
                    !team.team_icon_path.includes('/static/media')
                      ? `${API_URL}${team?.team_icon_path}`
                      : defaultTeamLogo
                  }
                  className={styles.logo_wraper__logo}
                  alt="Team logo"
                ></img>
              </div>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Роль пользователя в команде
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {getLocalRoleName(team.team_user_role ?? '')}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Количество участников
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {team.team_members_count ?? 0}
              </Typography>
            </div>
          </div>
          <div className="detail__content-title">
            <Typography color="gray" size="s">
              Описание
            </Typography>
          </div>
        </div>
        <Typography color="main" size="sm">
          {team.team_description ?? ''}
        </Typography>
        <Box mb={33} />
        <div className="row">
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Создатель команды
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {`${team?.team_creator.last_name ?? ''} ${
                  team?.team_creator.first_name ?? ''
                } ${team?.team_creator.middle_name ?? ''}`}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Организация создателя
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {team?.team_creator.organization_name ?? ''}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Дата создания
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {formatingDate(team.team_creation_date ?? '')}
              </Typography>
            </div>
          </div>
          <div className="details__divider" />
          <Typography color="main" fontWeight={600} size="s" upperCase>
            Материалы команды
          </Typography>
          <Box mt={20} mb={15} />
        </div>
      </div>
    </>
  );
};
