export const IconTooltip = (): JSX.Element => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.00001 0.666626C4.39763 0.666626 0.666672 4.39759 0.666672 8.99996C0.666672 13.6023 4.39763 17.3333 9.00001 17.3333C13.6024 17.3333 17.3333 13.6023 17.3333 8.99996C17.3333 4.39759 13.6024 0.666626 9.00001 0.666626ZM6.50001 7.12944C6.51465 6.4373 6.75269 5.88615 7.21412 5.47599C7.69019 5.04753 8.34205 4.83329 9.16968 4.83329C9.94971 4.83329 10.5814 5.03105 11.0648 5.42655C11.5409 5.8184 11.7789 6.32926 11.7789 6.95915C11.7789 7.73918 11.3871 8.3599 10.6034 8.82133C10.2372 9.03373 9.99 9.22416 9.86182 9.39262C9.73365 9.56108 9.66956 9.7808 9.66956 10.0518V10.3429H8.21387L8.20289 10.0243C8.16627 9.59953 8.22669 9.24613 8.38416 8.96415C8.53797 8.69682 8.80713 8.44963 9.19166 8.22258C9.54688 8.01017 9.78858 7.81974 9.91675 7.65129C10.0486 7.48283 10.1145 7.2686 10.1145 7.00859C10.1145 6.74491 10.0193 6.52885 9.82886 6.36039C9.63477 6.19194 9.38209 6.10771 9.07081 6.10771C8.75587 6.10771 8.50135 6.20109 8.30726 6.38786C8.11316 6.57096 8.00696 6.81816 7.98865 7.12944H6.50001ZM10.0417 12.125C10.0417 12.7003 9.5753 13.1666 9.00001 13.1666C8.42471 13.1666 7.95834 12.7003 7.95834 12.125C7.95834 11.5497 8.42471 11.0833 9.00001 11.0833C9.5753 11.0833 10.0417 11.5497 10.0417 12.125Z"
        fill="#D0D7DD"
      />
    </svg>
  );
};
