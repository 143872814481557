import React from 'react';
import styles from './Registration.module.scss';
import { Button } from '../../../../ui/Button';
import Background from '../../../../../assets/images/MainPage/registration/Block.png';
import { useNavigate } from 'react-router-dom';
import { frontendRoutes } from '../../../../../utils/router/routes';
export const Registration = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(frontendRoutes.user.chooseRegType);
  };
  return (
    <section className={styles.registration}>
      <img
        className={styles.registration__image}
        src={Background}
        alt="image"
      />
      <div className={styles.registration__block}>
        <div className={styles.registration__block_title}>
          Создай свое исследование!
        </div>
        <div
          className={styles.registration__block_submit}
          onClick={handleClick}
        >
          <Button label="Зарегистрироваться" gradient size={'large'} />
        </div>
      </div>
    </section>
  );
};
