import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { frontendRoutes } from '../../../../utils/router/routes';
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { Button } from '../../../ui/Button';
import { Typography } from '../../../ui/Typography';
import { Box } from '../../../ui/Box';

import styles from './TeamNew.module.scss';
import defaultTeamLogo from '../../../../assets/images/defaultTeamLogo.svg';

import { Form, FormikContext, useFormik } from 'formik';
import {
  teamCreateNewInitialValues,
  teamCreateNewSchema,
} from '../../../../models/validations/schemas/validations';
import {
  useCreateTeamMutation,
  useGetCurrentUserQuery,
} from '../../../../store/VbudusheeAPI';
import { Textarea } from '../../../ui/Form/Textarea/Textarea';
import { EditErrors } from '../../../../models/pages/teams';
import { VALIDATION_ERRORS } from '../../../../models/validations/errors/errors';

export const TeamNew = () => {
  const navigate = useNavigate();

  const [createTeam, { isLoading, error }] = useCreateTeamMutation();
  const currentUserData = useGetCurrentUserQuery({});

  const [teamLogo, setTeamLogo] = useState<string | ArrayBuffer | null>(
    defaultTeamLogo
  );
  const [logoForReq, setLogoForReq] = useState<any>(''); // change type
  const [serverError, setServerError] = useState<string | undefined>();

  const currentUser = currentUserData.data?.user;
  const creatorFullName = `${currentUser?.last_name} ${currentUser?.first_name} ${currentUser?.middle_name}`;
  const today = new Date().toLocaleDateString();

  const formData = new FormData();

  const onSelectImageHandler = (element: any) => {
    const file = element[0];

    if (file) {
      setLogoForReq(file);

      const reader = new FileReader();
      reader.onloadend = function () {
        setTeamLogo(reader.result);
      };
      reader.onerror = function () {
        // TO DO THIS FLOW
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (values: typeof teamCreateNewInitialValues) => {
    formData.append('team_name', values.team_name);
    formData.append('team_description', values.team_description);
    if (logoForReq !== '' && logoForReq !== defaultTeamLogo) {
      formData.append('team_icon_image', logoForReq);
    }

    const newTeam = await createTeam(formData);

    if ('data' in newTeam) {
      navigate(frontendRoutes.teams.teams);
    }
  };

  const formik = useFormik({
    initialValues: teamCreateNewInitialValues,
    validationSchema: teamCreateNewSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (error && 'data' in error) {
      const serverErrors = error.data as {
        errors: EditErrors;
        message: string;
      };
      if (
        serverErrors.message.search(/The team name field is required/i) !== -1
      ) {
        setServerError(VALIDATION_ERRORS.teams.requiredTeamName);
      } else if (
        serverErrors.message.search(
          /The team description field is required/i
        ) !== -1
      ) {
        setServerError(VALIDATION_ERRORS.teams.requiredTeamDescription);
      } else if (serverErrors.message.search(/Not permitted/i) !== -1) {
        setServerError(VALIDATION_ERRORS.teams.permitted);
      } else {
        setServerError(VALIDATION_ERRORS.server.error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <>
      <FormikContext.Provider value={formik}>
        <Form
          autoComplete="off"
          onKeyPress={(e) => {
            e.which === 13 && e.preventDefault();
          }}
        >
          <div className={styles.team}>
            <div className={styles.breadcrumbs_wrapper}>
              <Breadcrumbs
                links={[
                  { title: 'Новая команда', link: frontendRoutes.teams.teams },
                ]}
                homeLink="/"
              />
            </div>
            <div className={styles.buttons}>
              <div
                className={styles.buttons__elements}
                onClick={() => navigate(frontendRoutes.teams.teams)}
              >
                <Button label="Каталог команд" withoutBackground={true} />
              </div>
              <div className={styles.buttons__elements}>
                <Button label="Сохранить" gradient={true} type="submit" />
              </div>
            </div>
            <div className="details__body">
              <Typography color="main" fontWeight={600} size="s" upperCase>
                Основная информация о команде
              </Typography>
              {serverError && <div className={styles.error}>{serverError}</div>}
              <Box mb={33} />
              <div className={styles.row}>
                <div className={styles.detail}>
                  <div className={styles.detail__content}>
                    <div className={styles.detail__content_title}>
                      <Typography color="gray" size="s">
                        Фотография
                      </Typography>
                    </div>
                    <div className={styles.logo_wraper}>
                      <img
                        src={teamLogo?.toString()}
                        className={styles.logo_wraper__logo}
                        alt="Team logo"
                      ></img>
                      <div className={styles.logo_wraper__links}>
                        <label className="input-file">
                          <input
                            onChange={(e) => {
                              onSelectImageHandler(e.target.files);
                            }}
                            name="team_icon_image"
                            type="file"
                            accept="image/*"
                            className={styles.logo_wraper__hider_input}
                          />
                          <span className={styles.logo_wraper__edit}>
                            Изменить
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.detail}>
                  <div className={styles.detail__content}>
                    <div className={styles.detail__content_title}>
                      <Typography color="gray" size="s">
                        Роль пользователя в команде
                      </Typography>
                    </div>
                    <Typography color="main" size="sm">
                      Создатель
                    </Typography>
                  </div>
                </div>
                <div className={styles.detail}>
                  <div className={styles.detail__content}>
                    <div className={styles.detail__content_title}>
                      <Typography color="gray" size="s">
                        Количество участников
                      </Typography>
                    </div>
                    <Typography color="main" size="sm">
                      0
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={styles.form_wraper__small}>
                <Textarea
                  name="team_name"
                  placeholder="Название команды *"
                  height={62}
                />
              </div>
              <Box mb={24} />
              <div className={styles.row}>
                <div className={styles.detail}>
                  <div className={styles.detail__content}>
                    <div className={styles.detail__content_title}>
                      <Typography color="gray" size="s">
                        Создатель команды
                      </Typography>
                    </div>
                    <Typography color="main" size="sm">
                      {creatorFullName}
                    </Typography>
                  </div>
                </div>
                <div className={styles.detail}>
                  <div className={styles.detail__content}>
                    <div className={styles.detail__content_title}>
                      <Typography color="gray" size="s">
                        Организация создателя
                      </Typography>
                    </div>
                    <Typography color="main" size="sm">
                      {currentUser?.organization_name}
                    </Typography>
                  </div>
                </div>
                <div className={styles.detail}>
                  <div className={styles.detail__content}>
                    <div className={styles.detail__content_title}>
                      <Typography color="gray" size="s">
                        Дата создания
                      </Typography>
                    </div>
                    <Typography color="main" size="sm">
                      {today}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={styles.form_wraper__large}>
                <Textarea
                  name="team_description"
                  placeholder="Описание команды *"
                />
              </div>
            </div>
          </div>
        </Form>
      </FormikContext.Provider>
    </>
  );
};
