import classNames from 'classnames/bind';

import styles from './Box.module.scss';
const cx = classNames.bind(styles);

type BoxProps = {
  flex?: boolean;
  mt?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  justifyContent?: 'space-between' | 'center' | 'flex-start' | 'flex-end';
  children?: JSX.Element | JSX.Element[];
  disabled?: boolean;
};

export const Box = ({
  children,
  mt = 0,
  mr = 0,
  mb = 0,
  ml = 0,
  flex,
  justifyContent = 'flex-start',
  disabled = false,
}: BoxProps): JSX.Element => {
  return (
    <div
      style={{
        margin: `${mt}px ${mr}px ${mb}px ${ml}px`,
        justifyContent: justifyContent,
      }}
      className={cx('box', {
        box__flex: flex,
        box__disabled: disabled,
      })}
    >
      {children}
    </div>
  );
};
