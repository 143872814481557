import { useRef, useEffect } from 'react';
import * as d3 from 'd3';

interface DataPoint {
  label: string;
  value: number;
}

interface AnalysisResultsIntroductionChartProps {
  data: DataPoint[];
}

export const AnalysisResultsContentChart = ({
  data,
}: AnalysisResultsIntroductionChartProps) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);
    const margin = { top: 40, right: 30, bottom: 30, left: 80 };
    const width = +svg.attr('width')! - margin.left - margin.right;
    const height = +svg.attr('height')! - margin.top - margin.bottom;

    svg.selectAll('g').remove();

    const x = d3.scaleBand().range([0, width]).padding(0.1);
    const y = d3.scaleLinear().range([height, 0]);

    const g = svg
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    x.domain(data.map((d) => d.label));
    y.domain([
      0,
      d3.max(data, (d) => d.value)! > 100 ? d3.max(data, (d) => d.value)! : 100,
    ]);

    g.selectAll('.bar')
      .data(data)
      .enter()
      .append('path')
      .attr('class', 'bar')
      .attr('d', (d) => {
        const cornerRadius = 4;
        return `
      M ${x(d.label)} ${y(0)}
      L ${x(d.label)} ${y(d.value) + cornerRadius}
      Q ${x(d.label)} ${y(d.value)} ${x(d.label)! + cornerRadius} ${y(d.value)}
      L ${x(d.label)! + x.bandwidth() - cornerRadius} ${y(d.value)}
      Q ${x(d.label)! + x.bandwidth()} ${y(d.value)} ${
          x(d.label)! + x.bandwidth()
        } ${y(d.value) + cornerRadius}
      L ${x(d.label)! + x.bandwidth()} ${y(0)}
      Z
    `;
      })
      .style('fill', 'url(#gradient)');

    g.selectAll('.bar-label')
      .data(data)
      .enter()
      .append('text')
      .attr('class', 'bar-label')
      .attr('x', (d) => x(d.label)! + x.bandwidth() / 2)
      .attr('y', (d) => y(d.value) + (height - y(d.value)) / 2)
      .style('text-anchor', 'middle')
      .style('dominant-baseline', 'middle')
      .style('font-size', '10px')
      .style('fill', 'black')
      .text((d) => d.value);

    const xAxis = d3.axisBottom(x).tickSize(0).tickPadding(12);
    const yAxis = d3
      .axisLeft(y)
      .ticks(6)
      .tickFormat(d3.format('.0f'))
      .tickSize(0)
      .tickPadding(42);

    g.append('g')
      .attr('class', 'axis')
      .attr('transform', `translate(0,${height})`)
      .style('font-size', '10px')
      .call(xAxis)
      .call((g) => g.select('.domain').remove());

    g.append('g')
      .attr('class', 'axis')
      .style('font-size', '12px')
      .style('color', '#7D838A')
      .call(yAxis)
      .call((g) => g.select('.domain').remove());

    svg
      .selectAll('line.horizontal-grid')
      .data(y.ticks(5))
      .enter()
      .append('line')
      .attr('class', 'horizontal-grid')
      .attr('y1', function (d) {
        return y(d) + margin.top;
      })
      .attr('x1', margin.left - 30)
      .attr('y2', function (d) {
        return y(d) + margin.top;
      })
      .attr('x2', width + margin.left + margin.right)
      .style('stroke', '#E1E5EA')
      .style('stroke-width', 1);
  }, [data]);

  return (
    <svg ref={svgRef} width={530} height={320}>
      <defs>
        <clipPath id="round-corner">
          <rect x="0" y="0" width="10" height="56" rx="5" ry="5" />
        </clipPath>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop stopColor="#89e0cf" />
          <stop offset="0.501635" stopColor="#A1D6E1" />
          <stop offset="1" stopColor="#8bbee2" />
        </linearGradient>
      </defs>
    </svg>
  );
};
