import classNames from 'classnames/bind';
import { ResearchComparison } from '../../../../../models/research';
import { ComparisonSidePanelResearchItem } from './ComparisonSidePanelResearchItem';
import styles from './ComparisonSidePanelResearchList.module.scss';
import InfinityScroll from 'react-infinite-scroll-component';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Loader } from '../../../../ui/Loader';
const cx = classNames.bind(styles);

type ComparisonSidePanelResearchListProps = {
  title: string;
  subTitle: string;
  status: 'available' | 'added';
  research: ResearchComparison[];
  isAddingPossible: boolean;
  filterHeight: number;
  handleToggleResearch: (res: ResearchComparison) => void;
  fetchMore?: (filter: any) => any;
  isLoading?: boolean;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
};

export const ComparisonSidePanelResearchList = ({
  title,
  subTitle,
  status,
  research,
  isAddingPossible,
  filterHeight,
  handleToggleResearch,
  fetchMore,
  isLoading,
  setPage,
}: ComparisonSidePanelResearchListProps) => {
  // const [page, setPage] = useState<number>(1);
  const observer: any = useRef();
  const lastResearchElementRef = useCallback(
    (node: unknown) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && fetchMore) {
          // Here need to fetch
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading]
  );

  return (
    <div className={styles.researchList}>
      <h4 className={styles.researchListTitle}>{title}</h4>
      <p className={styles.text}>{subTitle}</p>
      <div
        id="scrollableDiv"
        style={{
          // height: 300,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          height: `calc(100vh - ${filterHeight}px - 150px)`,
        }}
      >
        <InfinityScroll
          dataLength={research.length}
          next={() => (setPage ? setPage((prev) => ++prev) : {})}
          style={{ display: 'flex', flexDirection: 'column' }} //To put endMessage and loader to the top.
          hasMore={true}
          className={cx('researchListScroll')}
          loader={
            isLoading ? (
              <div>
                <Loader.FullPage>
                  <Loader />
                </Loader.FullPage>
              </div>
            ) : (
              <></>
            )
          }
          scrollableTarget="scrollableDiv"
        >
          {research.map((item, index) => {
            if (research.length === index + 1) {
              return (
                // eslint-disable-next-line react/jsx-key
                <div
                  key={`${item.research_id}-${index}`}
                  ref={lastResearchElementRef}
                >
                  <ComparisonSidePanelResearchItem
                    status={status}
                    research={item}
                    isAddingPossible={isAddingPossible}
                    handleToggleResearch={handleToggleResearch}
                  />
                </div>
              );
            }

            return (
              <ComparisonSidePanelResearchItem
                status={status}
                key={item.research_id}
                research={item}
                isAddingPossible={isAddingPossible}
                handleToggleResearch={handleToggleResearch}
              />
            );
          })}
        </InfinityScroll>
      </div>
    </div>
  );
};
