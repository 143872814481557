import Slide1 from '../../assets/images/MainPage/sliders/Slide1.png';
import Slide2 from '../../assets/images/MainPage/sliders/Slide2.png';
import Slide3 from '../../assets/images/MainPage/sliders/Slide3.png';
import Slide4 from '../../assets/images/MainPage/sliders/Slide4.png';
import Slide5 from '../../assets/images/MainPage/sliders/Slide5.png';

export const Sliders = [
  {
    title: 'Пополняемая база надежных и валидных методик',
    description:
      'Обширная база содержит инструменты диагностики и мониторинга образовательной среды, личностных результатов, мотивации и благополучия всех участников образовательных отношений — детей дошкольного и младшего школьного возрастов, подростков, педагогов и управленцев образовательных организаций.',
    text: 'Можете выбирать методики, которые подходят под ваши задачи, и дополнять анкету своими вопросами.',
    image: Slide1,
  },
  {
    title: 'Автоматизированная обработка собранных данных',
    description:
      'Сервис обеспечивает автоматическую обработку данных: подсчет баллов в соответствии с ключами методик, формирование удобной базы для выгрузки и визуализации результатов.',
    text: 'Не тратьте время на обработку данных, сфокусируйтесь на их интерпретации.',
    image: Slide2,
  },
  {
    title: 'Статистический анализ и сравнение результатов',
    description:
      'Сервис позволяет анализировать и сопоставлять результаты групп респондентов в рамках одного исследования, этапов лонгитюдного исследования и отдельных исследований, использующих те же методики.',
    text: 'Для анализа доступны не только собственные данные, но и база открытых данных, собранных и опубликованных другими пользователями.',
    image: Slide3,
  },
  {
    title: 'Организация экспертизы практик в сферах образования и воспитания',
    description:
      'Сервис упрощает проведение экспертизы различных типов материалов по теме развития личностного потенциала и качества образовательной среды. Он позволяет организовать их сбор, задать формальные и содержательные требования к работам и критерии их оценивания, провести  первичный семантический анализ на соответствие тематической рамке и передать на дальнейшую проверку и оценку экспертам.',
    text: '<span>Отберите лучшие практики и решения и поделитесь ими с другими пользователями, опубликовав в </span><a href="/events">каталоге</a><span>.</span>',
    image: Slide4,
  },
  {
    title: 'Автоматическая отправка обратной связи респондентам',
    description:
      'При создании исследования пользователи имеют возможность настроить предъявление обратной связи респондентам по результатам прохождения диагностики. Обратная связь генерируется автоматически и доступна респондентам сразу после завершения опроса.',
    text: 'Шаблоны обратной связи разработаны авторами методик и соответствуют стандартам психодиагностики.',
    image: Slide5,
  },
];
