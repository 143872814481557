import { useState } from 'react';
import { useDeleteMemberResearchMutation } from '../../../../../../store/VbudusheeAPI';
import { Box } from '../../../../../ui/Box';
import { Button } from '../../../../../ui/Button';
import { ContentModal } from '../../../../../ui/ContentModal';
import { Typography } from '../../../../../ui/Typography';
import styles from './ResearchEditRespondentsItem.module.scss';

type ResearchEditRespondentsItemTypes = {
  item: any;
  researchId: string;
  refetch?: () => void;
};

export const ResearchEditRespondentsItem = ({
  item,
  researchId,
  refetch,
}: ResearchEditRespondentsItemTypes) => {
  const [isRemoveOpen, setRemoveOpen] = useState<boolean>(false);

  const [deleteMemberResearch] = useDeleteMemberResearchMutation();

  const handleToggleModal = () => setRemoveOpen((prev) => !prev);

  const removeRespondentHandler = async () => {
    await deleteMemberResearch({
      research_id: researchId ?? '',
      body: {
        email: item.respondent_email,
        role: '',
      },
    });

    if (refetch) {
      refetch();
    }

    handleToggleModal();
  };

  return (
    <>
      <div key={`email-${item.respondent_id}`}>
        <div className={styles.respondent}>
          <Typography color="main" size="sm" customLineHeight={38}>
            {item.respondent_email}
          </Typography>
          <button
            className={styles['respondent-remove__btn']}
            onClick={handleToggleModal}
          >
            Удалить
          </button>
        </div>
        <div className="divider-0"></div>
      </div>
      <ContentModal
        isOpen={isRemoveOpen}
        onClose={handleToggleModal}
        titleText="Подтверждение удаления"
      >
        <>
          <p>Вы уверены, что хотите удалить аккаунт {item.respondent_email}?</p>
          <br />
          <Box flex>
            <Button
              label="Удалить"
              gradient={true}
              clicked={removeRespondentHandler}
            />
            <Box mr={16} />
            <Button
              label="Отмена"
              withoutBackground
              clicked={handleToggleModal}
            />
          </Box>
        </>
      </ContentModal>
    </>
  );
};
