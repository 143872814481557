import React from 'react';

import styles from './Typography.module.scss';

export type TypographyColors =
  | 'primary'
  | 'main'
  | 'action'
  | 'secondary'
  | 'lightGray'
  | 'gray'
  | 'black'
  | 'black2'
  | 'gray5'
  | 'gray6'
  | 'gradientLight'
  | 'totalBlack'
  | 'danger'
  | 'prGreen'
  | 'greyDef';

type TypographyProps = {
  align?: 'left' | 'center' | 'right';
  vMargin?: number;
  size?:
    | 'xxs'
    | 's'
    | 'xs'
    | 'sm'
    | 'm'
    | 'lg'
    | 'xl'
    | 'xxl'
    | 'xxxl'
    | 'xxxxl';
  children: React.ReactNode;
  color?: TypographyColors;
  upperCase?: boolean;
  className?: string;
  font?:
    | 'SBSansText'
    | 'SBSansTextBold'
    | 'SFPRoDisplay'
    | 'SBSansDisplay'
    | 'TTCommons';
  fontWeight?: 400 | 500 | 600 | 700;
  customLineHeight?: number;
  tag?: string;
};

export const Typography = ({
  align = 'left',
  size = 'm',
  tag = 'p',
  vMargin,
  children,
  color = 'primary',
  upperCase = false,
  className,
  font = 'SBSansText',
  fontWeight = 400,
  customLineHeight,
}: TypographyProps): JSX.Element => {
  const calcSize = (s: string): number => {
    switch (s) {
      case 'xxs':
        return 8;
      case 'xs':
        return 10;
      case 's':
        return 12;
      case 'sm':
        return 14;
      case 'm':
        return 16;
      case 'lg':
        return 18;
      case 'xl':
        return 20;
      case 'xxl':
        return 24;
      case 'xxxl':
        return 32;
      case 'xxxxl':
        return 36;
      default:
        return 16;
    }
  };

  const calcLineHeight = (s: string): string => {
    switch (s) {
      case 'xs':
        return '12px';
      case 's':
        return '16px';
      case 'sm':
        return '22px';
      case 'm':
        return '24px';
      case 'lg':
        return '24px';
      case 'xl':
        return '24px';
      case 'xxl':
        return '32px';
      case 'xxxl':
        return '41px';
      default:
        return '24px';
    }
  };

  const getColor = (color: string) => {
    switch (color) {
      case 'primary':
        return styles.primary;
      case 'main':
        return styles.title;
      case 'action':
        return styles.action;
      case 'secondary':
        return styles.titleSecondary;
      case 'lightGray':
        return styles.darkGray;
      case 'gray':
        return styles.gray;
      case 'gray5':
        return styles.gray5;
      case 'gray6':
        return styles.gray6;
      case 'black':
        return styles.black;
      case 'black2':
        return styles.black2;
      case 'danger':
        return styles.danger;
      case 'totalBlack':
        return styles.totalBlack;
      case 'gradientLight':
        return styles.gradientLight;
      case 'prGreen':
        return styles.prGreen;
      case 'greyDef':
        return styles.greyDef;
      default:
        return styles.title;
    }
  };

  const CustomTag = `${tag}` as keyof JSX.IntrinsicElements;

  return (
    <CustomTag
      style={{
        marginTop: vMargin,
        marginBottom: vMargin,
        textAlign: align,
        fontSize: calcSize(size),
        textTransform: upperCase ? 'uppercase' : 'none',
        fontFamily: font,
        lineHeight: customLineHeight
          ? customLineHeight + 'px'
          : calcLineHeight(size),
        fontWeight,
        wordBreak: 'break-word',
      }}
      className={`${getColor(color)} ${className} text-wrap`}
    >
      {children}
    </CustomTag>
  );
};
