// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RestoreForm_form__submit__zhQCm {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/auth/RestorePass/RestoreForm/RestoreForm.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;AAAJ","sourcesContent":[".form {\n  &__submit {\n    display: flex;\n    justify-content: center;\n    margin-top: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form__submit": `RestoreForm_form__submit__zhQCm`
};
export default ___CSS_LOADER_EXPORT___;
