import React from 'react';
import { Layout } from '../../../components/Layout';
import { RestorePass } from '../../../components/pages/auth/RestorePass';

export const RestorePassword = () => {
  return (
    <Layout>
      <RestorePass />
    </Layout>
  );
};
