import { Layout } from '../../../../../components/Layout';
import { VerifyEmail } from '../../../../../components/Profile/VerifyEmail/VerifyEmail';

export const VerifyEmailPage = () => {
  return (
    <Layout>
      <VerifyEmail />
    </Layout>
  );
};
