import React from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import classNames from 'classnames/bind';

import { defaultFormikOptions } from '../../../../../models/formik';
import {
  teamResearchFiltersInitialValues,
  teamResearchFiltersValidationSchema,
} from '../../../../../models/validations/schemas/validations';
import { OCCUPATION } from '../../../../../constants/user';

//  ui
import { Filters } from '../../../../ui/Filters';
import { InputText } from '../../../../ui/Form/InputText';
import { Select } from '../../../../ui/Form/Select';
import { SelectSearch } from '../../../../ui/Form/SelectSearch';
import { Button } from '../../../../ui/Button';
import { InputDate } from '../../../../ui/Form/InputDate';

import styles from './TeamResearchFilters.module.scss';
const cx = classNames.bind(styles);

type TeamResearchFiltersTypes = {
  setFilters: React.Dispatch<
    React.SetStateAction<typeof teamResearchFiltersInitialValues>
  >;
};

export const TeamResearchFilters = ({
  setFilters,
}: TeamResearchFiltersTypes) => {
  const handleFiltersSubmit = (
    values: typeof teamResearchFiltersInitialValues
  ) => {
    setFilters(values);
  };

  const formik = useFormik({
    initialValues: teamResearchFiltersInitialValues,
    validationSchema: teamResearchFiltersValidationSchema,
    onSubmit: handleFiltersSubmit,
    ...defaultFormikOptions,
  });

  const handleFiltersReset = () => {
    setFilters(teamResearchFiltersInitialValues);
    formik.resetForm();
  };

  return (
    <div className={styles.filter}>
      <Filters>
        <FormikContext.Provider value={formik}>
          <Form>
            <div className={styles.form}>
              <div className={cx('form__item', 'form__item_search')}>
                <InputText name="search" placeholder="Поиск" fullWidth={true} />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <Select
                  fullWidth={true}
                  name="type"
                  label="Тип исследования"
                  options={OCCUPATION}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <InputDate
                  isRange
                  name="dates"
                  fullWidth={true}
                  placeholder="Сроки проведения"
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <Select
                  fullWidth={true}
                  name="target"
                  label="Целевая аудитория"
                  options={OCCUPATION}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <Select
                  fullWidth={true}
                  name="creator_name"
                  label="Создатель исследования"
                  options={OCCUPATION}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <Select
                  fullWidth={true}
                  name="status"
                  label="Статус исследования"
                  options={OCCUPATION}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <SelectSearch
                  fullWidth={true}
                  name="researcherOrg"
                  placeholder="Организация исследователя"
                  // TODO: replace with real data
                  options={[
                    {
                      option: 'a',
                      value: 'а',
                    },
                    {
                      option: 'b',
                      value: 'б',
                    },
                    {
                      option: 'c',
                      value: 'в',
                    },
                  ]}
                  // disabled={kladrs.length === 0}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <Select
                  fullWidth={true}
                  name="region"
                  label="Регион исследования"
                  options={OCCUPATION}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <Select
                  fullWidth={true}
                  name="theme"
                  label="Темы исследований"
                  options={OCCUPATION}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <Select
                  fullWidth={true}
                  name="publication"
                  label="Статус публикации"
                  options={OCCUPATION}
                />
              </div>
            </div>
            <div className={styles.buttons}>
              <div onClick={handleFiltersReset}>
                <Button label="Сбросить" withoutBackground={true} />
              </div>
              <div onClick={() => formik.handleSubmit()}>
                <Button label="Применить" gradient={true} />
              </div>
            </div>
          </Form>
        </FormikContext.Provider>
      </Filters>
    </div>
  );
};
