export const IconFooterYoutube = () => {
  return (
    <svg
      width="22"
      height="17"
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9287 0.25C7.75236 0.25 4.80117 0.476312 3.11719 0.828125C3.1136 0.829075 3.11002 0.830052 3.10645 0.831055C1.83774 1.11535 0.643095 2.02412 0.391602 3.44727C0.391268 3.44922 0.390943 3.45117 0.390625 3.45312C0.197418 4.60013 0 6.09535 0 8.25C0 10.4006 0.198095 11.8562 0.442383 13.0635C0.700038 14.4446 1.86711 15.3809 3.15527 15.6689C3.16144 15.6707 3.16762 15.6723 3.17383 15.6738C4.93135 16.0201 7.79923 16.25 10.9756 16.25C14.1519 16.25 17.0213 16.0201 18.7793 15.6738C18.7855 15.6723 18.7917 15.6707 18.7979 15.6689C20.0666 15.3847 21.2612 14.4759 21.5127 13.0527C21.5134 13.0492 21.514 13.0456 21.5146 13.042C21.7049 11.8716 21.9514 10.3778 22 8.21973C22.0001 8.21419 22.0001 8.20866 22 8.20312C22 6.04385 21.7529 4.53934 21.5117 3.34766C21.2561 1.96354 20.0877 1.02474 18.7979 0.736328C18.7781 0.731947 18.7583 0.728364 18.7383 0.725586C17.0232 0.484475 14.1056 0.25 10.9287 0.25ZM9.07812 4.50098C9.26333 4.49715 9.45477 4.54095 9.63477 4.64258L13.9365 7.07324C14.662 7.48324 14.662 8.51773 13.9365 8.92773L9.63477 11.3584C8.91527 11.7644 8.01855 11.2502 8.01855 10.4307V5.56934C8.01855 4.95471 8.52252 4.51246 9.07812 4.50098Z"
        fill="#FC0D1B"
      />
    </svg>
  );
};
