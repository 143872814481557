import { useMemo } from 'react';
import { Question, ResearchSurvey } from '../../../../../models/research';
import { useGetResearchSurveyQuery } from '../../../../../store/VbudusheeAPI';
import { Typography } from '../../../../ui/Typography';
import classNames from 'classnames/bind';

//  ui
import { QuestionBlockList } from './QuestionBlockList';

import styles from './ResearchDetailsStructure.module.scss';
import { Loader } from '../../../../ui/Loader';
import { QuestionnaireBlockList } from './QuestionnaireBlockList';

const cx = classNames.bind(styles);

type ResearchDetailsStructureProps = {
  survey?: ResearchSurvey;
  isSurveyLoading: boolean;
  isSurveyError: boolean;
  researchId?: string;
};

export const ResearchDetailsStructure = ({
  isSurveyError,
  isSurveyLoading,
  survey,
  researchId,
}: ResearchDetailsStructureProps): JSX.Element => {
  const researchMethods = useMemo(() => {
    return survey?.research_methods.map((method) => ({
      ...method,
      required: survey.research_methods_answer_requirement.includes(method.id),
    }));
  }, [survey]);

  const researchQuestionnaires = useMemo(() => {
    return survey?.research_questionnaires.filter(
      (question) => question.survey.question_input_type !== 'dadata.hidden'
    );
  }, [survey]);

  if (isSurveyLoading) {
    return <Loader />;
  }

  if (isSurveyError) {
    return <>При загрузке структуры опроса произошла ошибка</>;
  }

  return (
    <div className={styles.root}>
      {survey?.research_greeting_text && (
        <div>
          <Typography
            className={styles.title}
            size="lg"
            color={'black'}
            fontWeight={600}
          >
            Приветствие
          </Typography>
          <div className={cx(styles.contentWrapper, styles.text)}>
            {survey.research_greeting_text}
          </div>
        </div>
      )}
      {researchQuestionnaires && researchQuestionnaires.length > 0 && (
        <div>
          <Typography
            color={'black'}
            size="lg"
            fontWeight={600}
            className={styles.title}
          >
            Анкетные вопросы
          </Typography>
          <div className={styles.contentWrapper}>
            <QuestionnaireBlockList
              questions={researchQuestionnaires}
              questionnairesAnswerRequirement={
                survey?.research_questionnaires_answer_requirement
              }
            />
          </div>
        </div>
      )}
      {researchMethods && researchMethods.length > 0 && (
        <div>
          <Typography
            className={styles.title}
            size="lg"
            color={'black'}
            fontWeight={600}
          >
            Методики
          </Typography>
          <div className={styles.questionListWrapper}>
            {researchMethods.map((method) => (
              <div key={method.id}>
                <QuestionBlockList
                  survey={method.survey}
                  required={method.required}
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {survey?.research_gratitude && (
        <div>
          <Typography
            className={styles.title}
            size="lg"
            color={'black'}
            fontWeight={600}
          >
            Благодарность за участие
          </Typography>

          <div className={cx(styles.contentWrapper, styles.text)}>
            {survey.research_gratitude}
          </div>
        </div>
      )}
      {!survey && (
        <Typography color="main" size="sm">
          Нет исследования
        </Typography>
      )}
    </div>
  );
};
