import { fileSize } from '../../../../utils/files';
import { formatDateStr } from '../../../../utils/dates';

//  ui
import { FileIcon } from '../../Icons/File/FileIcon';
import { Typography } from '../../Typography';
import { Box } from '../../Box';

import styles from './FileItem.module.scss';
import { FILE_ERROR_DICTIONARY } from '../../../../constants/errors';
import { IconLoader } from '../../Icons/IconLoader';

type FileItemProps = {
  file: File;
  onDelete: (file: File) => void;
  errors: string[];
  isLoading: boolean;
  uploadProgress?: number;
};

export const FileItem = ({
  file,
  onDelete,
  errors,
  isLoading,
  uploadProgress,
}: FileItemProps): JSX.Element => {
  return (
    <div className={styles.fileWrapper}>
      <div className={styles.row}>
        <div className={styles.left}>
          {isLoading && (
            <div className={styles.loaderIcon}>
              <IconLoader />
            </div>
          )}

          {isLoading && (
            <Typography color="gray" size="s">
              Загружено {uploadProgress}%
            </Typography>
          )}
        </div>
      </div>
      {errors.map((type, index) => (
        <Box key={index} mt={12}>
          <Typography size="s" color="danger">
            {FILE_ERROR_DICTIONARY[type as 'type' | 'size']}
          </Typography>
        </Box>
      ))}
    </div>
  );
};
