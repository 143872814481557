import React from 'react';
import classNames from 'classnames/bind';

//  ui
import { IconPaginationArrow } from '../Icons/IconPaginationArrow';

import styles from './Pagination.module.scss';
const cx = classNames.bind(styles);

type PaginationTypes = {
  totalPages?: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage?: number;
};

export const Pagination = ({
  totalPages = 0,
  setPage,
  currentPage = 1,
}: PaginationTypes) => {
  const isFirst = currentPage === 1;
  const isLast = totalPages === currentPage;

  const handleChangePage = (value: number) => setPage(value);
  const handleNextPage = () => {
    if (currentPage !== totalPages) {
      setPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage !== 1) {
      setPage(currentPage - 1);
    }
  };

  if (totalPages < 2) {
    return null;
  }

  const getRange = () => {
    if (currentPage > 2 && currentPage < totalPages - 1) {
      return [currentPage - 1, currentPage, currentPage + 1];
    } else if (currentPage === 2) {
      if (currentPage + 1 < totalPages) {
        return [currentPage, currentPage + 1];
      } else if (totalPages === 2) {
        return [];
      } else {
        return [currentPage];
      }
    } else if (currentPage === totalPages - 1 && totalPages > 2) {
      return [currentPage - 1, currentPage];
    } else if (currentPage === totalPages) {
      return [currentPage - 1];
    } else if (currentPage === 1 && totalPages > 2) {
      return [currentPage + 1];
    } else {
      return [];
    }
  };
  const range = getRange();

  return (
    <div className={styles.pagination}>
      <div
        className={cx('arrow', {
          arrow_inactive: isFirst,
        })}
        onClick={handlePrevPage}
      >
        <IconPaginationArrow />
      </div>
      <div
        className={cx('item', {
          item_active: currentPage === 1,
        })}
        onClick={() => handleChangePage(1)}
      >
        1
      </div>

      {range[0] > 2 && <div className={cx('item', 'dots')}>...</div>}

      {range.map((page) => (
        <div
          key={page}
          className={cx('item', {
            item_active: page === currentPage,
          })}
          onClick={() => handleChangePage(page)}
        >
          {page}
        </div>
      ))}

      {range[range.length - 1] < totalPages - 1 && (
        <div className={cx('item', 'dots')}>...</div>
      )}

      <div
        className={cx('item', {
          item_active: currentPage === totalPages,
        })}
        onClick={() => handleChangePage(totalPages)}
      >
        {totalPages}
      </div>
      <div
        className={cx('arrow', {
          arrow_inactive: isLast,
        })}
        onClick={handleNextPage}
      >
        <IconPaginationArrow />
      </div>
    </div>
  );
};
