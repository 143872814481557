import { useDrag } from 'react-dnd';

import { cardTypes } from '../types';

//  ui
import { Card } from '../../../../components/ui/Card';

type TaskProps = {
  id: string;
  name: string;
};

export const Task = ({ id, name }: TaskProps): JSX.Element => {
  const [{ isDragging }, drag] = useDrag({
    type: cardTypes.CARD,
    item: {
      id,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });
  return (
    <div
      ref={drag}
      style={{ zIndex: 2, opacity: isDragging ? 0.5 : 1, cursor: 'move' }}
    >
      <Card>
        <span>{`Task ${name}`}</span>
      </Card>
    </div>
  );
};
