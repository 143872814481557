import { ResearchComparison } from '../../../../../../models/research';
import { useGetDictionaryQuery } from '../../../../../../store/VbudusheeAPI';
import { IconArrowMenu } from '../../../../../ui/Icons/IconArrowMenu';
import { frontendRoutes } from '../../../../../../utils/router/routes';
import { formatDateRange } from '../../../../../../utils/dates';
import styles from './ComparisonSidePanelResearchItem.module.scss';

type ComparisonSidePanelResearchItemProps = {
  status: 'available' | 'added';
  research: ResearchComparison;
  isAddingPossible: boolean;
  handleToggleResearch: (res: ResearchComparison) => void;
};

export const ComparisonSidePanelResearchItem = ({
  status,
  research,
  isAddingPossible,
  handleToggleResearch,
}: ComparisonSidePanelResearchItemProps) => {
  const { data: dictionary } = useGetDictionaryQuery({});

  return (
    <div className={styles.researchItem}>
      <div className={styles.researchItemTop}>
        <a
          className={styles.researchItemTopTitle}
          href={`${frontendRoutes.research.details}/${research.research_id}`}
          target="_blank"
          rel="noreferrer"
        >
          {research.research_name} <IconArrowMenu />
        </a>
        {research.research_type === 'longitudinal' && (
          <p className={styles.researchItemTopStep}>
            Этап {research.research_step}
          </p>
        )}
      </div>
      <div className={styles.researchItemContent}>
        <div className={styles.researchItemContentItem}>
          <p className={styles.itemTitle}>Дата проведения</p>
          <p className={styles.itemText}>
            {formatDateRange(
              research.research_start_date,
              research.research_end_date
            )}
          </p>
        </div>
        <div className={styles.researchItemContentItem}>
          <p className={styles.itemTitle}>Тип исследования</p>
          <p className={styles.itemText}>
            {dictionary?.research.research_type[research.research_type] ||
              'Нет данных'}
          </p>
        </div>
      </div>

      <div className={styles.researchItemContent}>
        <div className={styles.researchItemContentItem}>
          <p className={styles.itemTitle}>Регион</p>
          <p className={styles.itemText}>
            {research.research_region
              .map((region) => region.region_name)
              .join(', ') || 'Нет данных'}
          </p>
        </div>
      </div>

      <div className={styles.researchItemContent}>
        <div className={styles.researchItemContentItem}>
          <p className={styles.itemTitle}>Аудитория респондентов</p>
          <p className={styles.itemText}>
            {dictionary?.research.research_target[research.research_target] ||
              'Нет данных'}
          </p>
        </div>
      </div>

      {status === 'available' ? (
        <div className={styles.researchItemBottom}>
          <button
            className={`${styles.button} ${styles.addText}`}
            onClick={() => handleToggleResearch(research)}
            disabled={!isAddingPossible}
          >
            Добавить к сравнению
          </button>
        </div>
      ) : null}

      {status === 'added' ? (
        <div className={styles.researchItemBottom}>
          <button
            className={`${styles.button} ${styles.deleteText}`}
            onClick={() => handleToggleResearch(research)}
          >
            Удалить из сравнения
          </button>
          <div className={styles.indicator}></div>
        </div>
      ) : null}
    </div>
  );
};
