import classNames from 'classnames/bind';
import { useDrag } from 'react-dnd';

import {
  MethodDictItem,
  MethodDictItemSurvey,
  QuestionItemType,
  SurveyConstructorType,
} from '../../../../../../models/research';

//  ui
import { Typography } from '../../../../../ui/Typography';
import { Button } from '../../../../../ui/Button';
import { IconDrag } from '../../../../../ui/Icons/Research/IconDrag';

import styles from './ResearchSurveyMenuItem.module.scss';
import { ModalCustomQuestion } from '../../../../../ui/ModalCustomQuestion';
import { useState } from 'react';
import { isError } from 'util';
import { ModalError } from '../../../../../ui/ModalError';
import { IconArrowLink } from '../../../../../ui/Icons/IconArrowLink';

const cx = classNames.bind(styles);

type ResearchSurveyMenuItemProps = {
  id: number;
  title: string;
  description?: string[];
  methodDescription?: MethodDictItemSurvey;
  questionType?: string;
  questionOrder?: string | null;
  disable?: boolean;
  type: SurveyConstructorType.METHOD | SurveyConstructorType.QUESTION;
  isSavedQuestion?: boolean;
  requiredQuestion?: number[];
  setRequiredQuestion?: () => void;
  chosenQuestion?: QuestionItemType[];
  setChosenQuestion?: () => void;
  methodLink?: string;
  isMethod?: boolean;
  questionRequired?: boolean;
};

export const ResearchSurveyMenuItem = ({
  id,
  title = 'Нет данных',
  description = [],
  methodDescription = undefined,
  questionType = '',
  questionOrder,
  type,
  disable,
  isSavedQuestion = false,
  requiredQuestion,
  setRequiredQuestion,
  chosenQuestion,
  setChosenQuestion,
  methodLink,
  isMethod,
  questionRequired,
}: ResearchSurveyMenuItemProps) => {
  const [{ isDragging }, drag] = useDrag({
    type,
    item: {
      id,
      title,
      description,
      methodDescription,
      questionType,
      questionOrder,
      questionRequired,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [selectedItem, setSelectedItem] = useState<{
    id: number;
    title: string;
    description?: string[];
    methodDescription?: any;
    questionType?: string;
    questionOrder?: string | null;
    questionRequired?: boolean;
  } | null>(null);

  const handleClick = (item: any) => {
    setSelectedItem(item);
    // Откройте модальное окно здесь
  };

  return (
    <>
      <ModalCustomQuestion
        isOpen={selectedItem !== null}
        onClose={() => setSelectedItem(null)}
        addQuestion={() => {}}
        item={selectedItem}
        isSavedQuestion={isSavedQuestion}
        requiredQuestion={requiredQuestion}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setRequiredQuestion={setRequiredQuestion}
        chosenQuestion={chosenQuestion}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setChosenQuestion={setChosenQuestion}
      />
      <div
        className={cx('dragItem', {
          dragItem__drag: isDragging,
          dragItem__disable: disable,
        })}
        // eslint-disable-next-line no-console
        onClick={
          isSavedQuestion
            ? () =>
                handleClick({
                  id,
                  title,
                  description,
                  methodDescription,
                  questionType,
                  questionOrder,
                  questionRequired,
                })
            : undefined
        }
        ref={drag}
      >
        <div className={styles.dragItemContent}>
          <Button display="service" icon={<IconDrag />} />
          <Typography color="main" size="sm" className={styles.p}>
            {title}
          </Typography>
        </div>
        {isMethod && methodLink && (
          <a href={methodLink} className={styles.dragItemLink}>
            <IconArrowLink />
          </a>
        )}
      </div>
    </>
  );
};
