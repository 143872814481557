import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TableHeader } from '../../../../models/components/table';
import { publicEventsRequestsFiltersInitialValues } from '../../../../models/validations/schemas/validations';
import { frontendRoutes } from '../../../../utils/router/routes';
import {
  EventsPaginationRequest,
  PublicEvent,
} from '../../../../models/pages/events';
import {
  useGetDictionaryQuery,
  useGetEventsQuery,
} from '../../../../store/VbudusheeAPI';
import { dateStrToServerStr, formatDateStr } from '../../../../utils/dates';

//  ui
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { HMenu } from '../../../ui/HMenu';
import { PublicEventsRequestsFilters } from './PublicEventsRequestsFilters';
import { Loader } from '../../../ui/Loader';
import { TableExpanded } from '../../../ui/Table';
import { Pagination } from '../../../ui/Pagination';

import styles from './PublicEventsRequests.module.scss';

// 'Заявленные на публикацию' tab component
export const PublicEventsRequests = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<
    typeof publicEventsRequestsFiltersInitialValues
  >(publicEventsRequestsFiltersInitialValues);
  const paginationOptions: EventsPaginationRequest = useMemo(() => {
    return {
      page,
      event_name: filters.search || undefined,
      event_member_role: filters.role || undefined,
      event_start_date:
        dateStrToServerStr(filters.dates.split(';')[0]) || undefined,
      event_end_date:
        dateStrToServerStr(filters.dates.split(';')[1]) || undefined,
      event_status: '' || undefined,
    };
  }, [page, filters.search, filters.role, filters.dates]);

  const { data, isLoading, isError } = useGetEventsQuery(paginationOptions);
  const { data: dictionary } = useGetDictionaryQuery({});
  //  Check if event not published and thus display only requested for publication events
  const publicEventsData =
    data?.data?.filter((pEvent) => pEvent?.event_publication === false) ?? [];

  const headers: TableHeader[] = [
    { label: 'Название мероприятия', width: '25%' },
    { label: 'Создатель мероприятия', width: '25%' },
    { label: 'Организация создателя', width: '30%' },
    { label: 'Сроки проведения', width: '20%' },
  ];

  const menuLinks = [
    {
      label: 'Опубликованные',
      onClick: () => navigate(frontendRoutes.publicEventsCatalog.events),
      active: false,
      access: true,
    },
    {
      label: 'Заявленные на публикацию',
      onClick: () => navigate(frontendRoutes.publicEventsCatalog.eventsRequest),
      active: true,
      access: true,
    },
  ];

  return (
    <>
      {isLoading ? (
        <Loader.FullPage>
          <Loader />
        </Loader.FullPage>
      ) : (
        <div className={styles.team}>
          <div className="local-container">
            <Breadcrumbs
              links={[{ title: 'Каталог публичных мероприятий' }]}
              homeLink="/"
            />
          </div>
          <div className={styles.team__header}>
            Каталог публичных мероприятий
          </div>
          <div className={styles.team__navigation}>
            <HMenu links={menuLinks} />
          </div>
          <div className={styles.team__filters}>
            <PublicEventsRequestsFilters setFilters={setFilters} />
          </div>
          <div className="container">
            <TableExpanded headers={headers}>
              {isError || publicEventsData?.length < 1 ? (
                <TableExpanded.NoTable
                  isError={isError}
                  dataLength={publicEventsData.length}
                />
              ) : (
                <>
                  {publicEventsData.map((pEvent: PublicEvent) => (
                    <TableExpanded.Row key={pEvent.event_id} headers={headers}>
                      <TableExpanded.Col>
                        <span className="long-string">{pEvent.event_name}</span>
                      </TableExpanded.Col>
                      <TableExpanded.Col>
                        <span className="long-string">
                          {`
                            ${pEvent?.event_creator?.first_name} 
                            ${pEvent?.event_creator?.middle_name} 
                            ${pEvent?.event_creator?.last_name}
                          `}
                        </span>
                      </TableExpanded.Col>
                      <TableExpanded.Col>
                        <span className="long-string">
                          {pEvent?.event_creator?.organization_name}
                        </span>
                      </TableExpanded.Col>
                      <TableExpanded.Col>
                        <span className="long-string">
                          {formatDateStr(pEvent?.event_start_date, {
                            isDateOnly: true,
                          })}{' '}
                          -{' '}
                          {formatDateStr(pEvent?.event_end_date, {
                            isDateOnly: true,
                          })}
                        </span>
                      </TableExpanded.Col>

                      <TableExpanded.Expanded
                        innerText={pEvent?.event_theme}
                        items={[
                          {
                            itemTitle: 'Роль',
                            itemData: Array.isArray(pEvent?.event_user_role)
                              ? pEvent?.event_user_role
                                  ?.map(
                                    (role) =>
                                      dictionary?.events.role[role] ?? ''
                                  )
                                  .join(', ') ?? 'Нет роли'
                              : dictionary?.events.role[
                                  pEvent?.event_user_role ?? ''
                                ] ?? 'Нет роли',
                          },
                          {
                            itemTitle: 'Количество участников',
                            itemData: `${pEvent?.event_members ?? 0}`,
                          },
                          {
                            itemTitle: 'Документы',
                            itemData: '',
                          },
                          {
                            itemTitle: 'Тематические теги',
                            itemData: pEvent?.event_tags.join(', '),
                          },
                        ]}
                      />
                    </TableExpanded.Row>
                  ))}
                </>
              )}
            </TableExpanded>
          </div>
          <div className="pagination">
            {data?.meta?.per_page &&
              publicEventsData?.length > data?.meta?.per_page && (
                <Pagination
                  totalPages={data?.meta?.last_page}
                  currentPage={data?.meta?.current_page}
                  setPage={setPage}
                />
              )}
          </div>
        </div>
      )}
    </>
  );
};
