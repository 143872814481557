import React from 'react';
import { useNavigate } from 'react-router-dom';
import { frontendRoutes } from '../../../../utils/router/routes';
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { Button } from '../../../ui/Button';
import { IconBadge } from '../../../ui/Icons/IconBadge';
import { IconBag } from '../../../ui/Icons/IconBag';
import styles from './RegistrationChoose.module.scss';
import { IconSurvey } from '../../../ui/Icons/IconSurvey';

export const RegistrationChoose = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.reg}>
      <div className="outer-container">
        <Breadcrumbs links={[{ title: 'Вход' }]} homeLink="/" />
      </div>
      <span className={styles.reg__header}>Выберите тип пользователя</span>
      <div className={styles.container}>
        <div className={styles.container__border}>
          <div className={styles.container__header}>
            Регистрация <br />
            исследователя <br />
            от своего имени
          </div>

          <div
            className={styles.container__icon}
            style={{ marginBottom: '45px' }}
          >
            <IconBadge />
          </div>
          <div
            className={styles.container__button}
            onClick={() => navigate(frontendRoutes.user.registration)}
          >
            <Button label="Выбрать" gradient={true} />
          </div>
        </div>

        <div className={styles.container__border}>
          <div className={styles.container__header}>
            Регистрация <br />
            исследователя <br />
            от имени организации
          </div>

          <div className={styles.container__icon}>
            <IconBag />
          </div>
          <div
            className={styles.container__button}
            onClick={() => navigate(frontendRoutes.user.registrationCompany)}
          >
            <Button label="Выбрать" gradient={true} />
          </div>
        </div>

        <div className={styles.container__border}>
          <div className={styles.container__header}>
            Регистрация <br />в качестве респондента
          </div>

          <div className={styles.container__icon}>
            <IconSurvey />
          </div>
          <div
            className={styles.container__button}
            onClick={() => navigate(frontendRoutes.user.registrationRespondent)}
          >
            <Button label="Выбрать" gradient={true} />
          </div>
        </div>
      </div>
    </div>
  );
};
