import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import {
  QuestionItemType,
  ResearchDetails,
  ResearchMember,
  ResearchSurvey,
  ResearchTeam,
} from '../../../../models/research';
import { frontendRoutes } from '../../../../utils/router/routes';

//  ui
import { Box } from '../../../ui/Box';
import { Typography } from '../../../ui/Typography';
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { Loader } from '../../../ui/Loader';
import { ResearchEditPermissions } from './ResearchEditPermissions';
import { ResearchEditRespondents } from './ResearchEditRespondents';
import { ResearchEditSettings } from './ResearchEditSettings';
import { ResearchEditSurvey } from './ResearchEditSurvey';
import { HMenu } from '../../../ui/HMenu';

import styles from './ResearchEdit.module.scss';
import { PopupMessage } from '../../../ui/PopupMessage';
import { CopyLink } from '../../../ui/CopyLink/CopyLink';
const cx = classNames.bind(styles);

type ResearchEditTypes = {
  researchId?: string;
  researchData?: ResearchDetails;
  researchSurvey?: { data: ResearchSurvey } | undefined;
  isQLoading?: boolean;
  isMenu: boolean;
  setIsMenu: (value: boolean) => void;
  isMenuOpen: boolean;
  setSaveTemplate: (value: boolean) => void;
  saveTemplate: boolean;
  setDownloadTemplate: (value: boolean) => void;
  downloadTemplate: boolean;
  refetch?: () => void;
  requiredQuestion?: number[];
  setRequiredQuestion?: () => void;
  chosenQuestion?: QuestionItemType[];
  setChosenQuestion?: () => void;
};

export const ResearchFormCreateAndEdit = ({
  researchId,
  researchData,
  researchSurvey,
  isQLoading,
  isMenu,
  setIsMenu,
  isMenuOpen,
  setSaveTemplate,
  saveTemplate,
  setDownloadTemplate,
  downloadTemplate,
  refetch,
  requiredQuestion,
  setRequiredQuestion,
  chosenQuestion,
  setChosenQuestion,
}: ResearchEditTypes) => {
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.split('/');

  const [researchDetailsData, setResearchDetailsData] = useState<
    ResearchDetails | undefined
  >();

  const [members, setMembers] = useState<ResearchMember[]>(
    researchDetailsData?.data.members ?? []
  );

  const [teams, setTeams] = useState<ResearchTeam[]>(
    researchDetailsData?.data.teams ?? []
  );

  const [isCopyLink, setIsCopyLink] = useState(false);

  useEffect(() => {
    setResearchDetailsData(researchData);
  }, [researchData?.data]);

  useEffect(() => {
    setMembers(researchData?.data.members ?? []);
    setTeams(researchData?.data.teams ?? []);
  }, [researchDetailsData]);

  const defineStyle = (isOpen: boolean): string => {
    if (isOpen) return `${styles.res} ${styles.res__open}`;
    return styles.res;
  };

  const breadcrumbsLinks = researchId
    ? [
        {
          title: 'Страница исследования',
          link: frontendRoutes.research.details + `/${researchId}`,
        },
        { title: 'Редактирование исследования' },
      ]
    : [{ title: 'Создание исследования' }];

  const researchType = useMemo(() => {
    if (researchDetailsData) {
      return {
        longitudinal: researchDetailsData.data.research_type === 'longitudinal',
        step: researchDetailsData.data.research_step,
      };
    }
  }, [researchDetailsData]);

  const disable = useMemo(() => {
    if (
      researchDetailsData?.data.research_publication_status !== 'unpublished'
    ) {
      if (researchDetailsData?.data.research_publication_status === 'rework') {
        return false;
      }
      return (
        researchDetailsData?.data.research_publication_status !== 'rejected'
      );
    }
    return false;
  }, [researchDetailsData]);

  const isTheAuthor = useMemo(() => {
    if (researchDetailsData) {
      return researchDetailsData.data.research_user_role === 'owner';
    }
    return false;
  }, [researchDetailsData]);

  const menuLinks = useMemo(() => {
    if (researchId) {
      return [
        {
          label: 'Настройки исследования',
          onClick: () =>
            navigate(`${frontendRoutes.research.edit.settings}/${researchId}`),
          active: path[2] === 'settings',
          access: true,
        },
        {
          label: 'Доступ к исследованию',
          onClick: () =>
            navigate(
              `${frontendRoutes.research.edit.permissions}/${researchId}`
            ),
          active: path[2] === 'permissions',
          access: true,
        },
        {
          label: 'Распространение опроса',
          onClick: () =>
            navigate(
              `${frontendRoutes.research.edit.respondents}/${researchId}`
            ),
          active: path[2] === 'respondents',
          access: true,
        },
        {
          label: 'Конструктор опроса',
          onClick: () =>
            navigate(`${frontendRoutes.research.edit.survey}/${researchId}`),
          active: path[2] === 'survey',
          access: true,
          // disable: disable || researchDetailsData?.data.research_interview_open,
        },
      ];
    }
    return [
      {
        label: 'Настройки исследования',
        onClick: () => navigate(`${frontendRoutes.research.new}`),
        active: path[2] === 'new',
        access: true,
      },
    ];
  }, [researchId, researchDetailsData, path, disable]);

  const showLinkCopiedTip = () => {
    setIsCopyLink(true);
    setTimeout(setIsCopyLink, 4000, false);
  };

  useEffect(() => {
    // if (disable || researchDetailsData?.data.research_interview_open) {
    //   setIsMenu(false);
    // } else {
    setIsMenu(path[2] === 'survey');
    // }
  }, [disable, researchDetailsData, path]);

  return (
    <section className={isMenu ? defineStyle(isMenuOpen) : 'details'}>
      <div
        className={cx('res__breadcrumbs', {
          res__breadcrumbs_wMenu: isMenu,
        })}
      >
        <Breadcrumbs
          links={breadcrumbsLinks}
          homeLink={frontendRoutes.mainPage}
        />
      </div>
      <PopupMessage isOpen={isCopyLink}>
        <CopyLink />
      </PopupMessage>
      <div className="details__header">
        <Typography
          color="main"
          size="xxxl"
          font="SFPRoDisplay"
          fontWeight={600}
        >
          {breadcrumbsLinks[breadcrumbsLinks.length - 1].title}
        </Typography>
      </div>
      <div className={isMenu ? styles.res__body : 'details__body'}>
        {isQLoading ? (
          <Loader.FullPage>
            <Loader />
          </Loader.FullPage>
        ) : (
          <>
            <div>
              {researchId && <Box mb={40}>{<HMenu links={menuLinks} />}</Box>}
              <div className={styles.res__content}>
                {researchId && path[2] === 'survey' && researchDetailsData ? (
                  <ResearchEditSurvey
                    researchId={researchId}
                    details={researchDetailsData}
                    survey={researchSurvey}
                    setSaveTemplate={setSaveTemplate}
                    saveTemplate={saveTemplate}
                    setDownloadTemplate={setDownloadTemplate}
                    downloadTemplate={downloadTemplate}
                    setIsMenu={setIsMenu}
                    isTheAuthor={isTheAuthor}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    requiredQuestion={requiredQuestion}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setRequiredQuestion={setRequiredQuestion}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    chosenQuestion={chosenQuestion}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setChosenQuestion={setChosenQuestion}
                  />
                ) : researchId && path[2] === 'permissions' ? (
                  <ResearchEditPermissions
                    researchId={researchId}
                    researchSurvey={researchSurvey}
                    members={members}
                    teams={teams}
                    researchType={researchType}
                    publicationStatus={
                      researchDetailsData?.data.research_publication_status
                    }
                    interviewOpen={
                      researchDetailsData?.data.research_interview_open
                    }
                    startDate={researchDetailsData?.data.research_start_date}
                    endDate={researchDetailsData?.data.research_end_date}
                    clickedCopy={showLinkCopiedTip}
                    isTheAuthor={isTheAuthor}
                  />
                ) : researchId && path[2] === 'respondents' ? (
                  <ResearchEditRespondents
                    researchId={researchId}
                    respondents={researchDetailsData?.data.respondents || []}
                    clickedCopy={showLinkCopiedTip}
                    isTheAuthor={isTheAuthor}
                    refetch={refetch}
                  />
                ) : (
                  <ResearchEditSettings
                    researchId={researchId}
                    details={researchDetailsData}
                    researchSurvey={researchSurvey}
                    refetch={refetch}
                    isTheAuthor={isTheAuthor}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};
