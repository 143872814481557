import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { CurrentUser } from '../../models/user/auth';

type AuthorizedUser = {
  currentUser: CurrentUser | null;
};

const initialState: AuthorizedUser = {
  currentUser: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getCurrentUser: (state, { payload }: PayloadAction<CurrentUser | null>) => {
      state.currentUser = payload;
    },
    removeCurrentUser: (state) => {
      state.currentUser = null;
    },
  },
});

export const { getCurrentUser, removeCurrentUser } = userSlice.actions;
export const { reducer: userReducer } = userSlice;
