import { useParams } from 'react-router-dom';
import { Layout } from '../../components/Layout';
import { Breadcrumbs } from '../../components/ui/Breadcrumbs';
import { frontendRoutes } from '../../utils/router/routes';
import styles from './DocumentPage.module.scss';
import { DocumentContent } from '../../components/pages/document/DocumentContent';

export const DocumentPage = (): JSX.Element => {
  const { token } = useParams();

  return (
    <Layout>
      <div className={styles.survey}>
        <div className="local-container">
          <Breadcrumbs
            links={[
              {
                title: 'Участие в мероприятии',
                link: frontendRoutes.publicEventsCatalog.events,
              },
              { title: 'Информация о документе' },
            ]}
            homeLink={frontendRoutes.mainPage}
          />
        </div>
        <div className={styles.survey__layout}>
          <div className={styles.survey__form}>
            <DocumentContent documentId={token || ''} />
          </div>
        </div>
      </div>
    </Layout>
  );
};
