import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TableHeader } from '../../../../models/components/table';
import { publicEventsFiltersInitialValues } from '../../../../models/validations/schemas/validations';
import { frontendRoutes } from '../../../../utils/router/routes';
import {
  PublicEvent,
  EventsPaginationRequest,
} from '../../../../models/pages/events';
import {
  useGetDictionaryQuery,
  useGetEventsQuery,
} from '../../../../store/VbudusheeAPI';
import {
  formatDateStr,
  validateDateFromCalendar,
} from '../../../../utils/dates';
import { useAuthorized } from '../../../../hooks/useAuthorized';

//  ui
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { PublicEventsCatalogFilters } from './PublicEventsCatalogFilters';
import { Loader } from '../../../ui/Loader';
import { TableExpanded } from '../../../ui/Table';
import { Pagination } from '../../../ui/Pagination';

import styles from './PublicEventsCatalog.module.scss';
import { Region } from '../../../../models/research';

export const PublicEventsCatalog = () => {
  const navigate = useNavigate();
  const { isAuthorized } = useAuthorized();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<
    typeof publicEventsFiltersInitialValues
  >(publicEventsFiltersInitialValues);
  const paginationOptions: EventsPaginationRequest = useMemo(() => {
    return {
      event_status: filters.status || undefined,
      event_name: filters.search || undefined,
      event_user_role: filters.role || undefined,
      event_start_date: filters.dates
        ? validateDateFromCalendar(filters.dates.split(';')[0])
        : undefined,
      event_end_date: filters.dates
        ? validateDateFromCalendar(filters.dates.split(';')[1])
        : undefined,
      event_tags: filters.tags.length
        ? filters.tags.map((tag: Region) => tag.region_number)
        : undefined,
      page,
    };
  }, [page, filters]);
  const { data, isFetching, isError } = useGetEventsQuery(paginationOptions);
  const { data: dictionary } = useGetDictionaryQuery({});

  const eventsData = data?.data || [];
  const perPage = data?.meta?.per_page;
  const totalPages = data?.meta?.last_page;
  const currentPage = data?.meta?.current_page;

  const headers: TableHeader[] = [
    { label: 'Название мероприятия', width: '30%' },
    { label: 'Создатель мероприятия', width: '30%' },
    { label: 'Организация создателя', width: '20%' },
    { label: 'Сроки проведения', width: '20%' },
  ];

  // const menuLinks = [
  //   {
  //     label: 'Опубликованные',
  //     onClick: () => navigate(frontendRoutes.publicEventsCatalog.events),
  //     active: true,
  //     access: true,
  //   },
  //   {
  //     label: 'Заявленные на публикацию',
  //     onClick: () => navigate(frontendRoutes.publicEventsCatalog.eventsRequest),
  //     active: false,
  //     access: isAuthorized,
  //   },
  // ];

  return (
    <div className={styles.team}>
      <div className="local-container">
        <Breadcrumbs
          links={[{ title: 'Каталог публичных мероприятий' }]}
          homeLink="/"
        />
      </div>
      <div className={styles.team__header}>Каталог публичных мероприятий</div>
      <div className={styles.team__navigation}>
        {/* <HMenu links={menuLinks} /> */}
      </div>
      <div className={styles.team__filters}>
        <PublicEventsCatalogFilters setFilters={setFilters} />
      </div>
      <div className="container">
        <TableExpanded headers={headers}>
          {isFetching ? (
            <Loader.FullPage>
              <Loader />
            </Loader.FullPage>
          ) : isError || eventsData?.length < 1 ? (
            <TableExpanded.NoTable
              isError={isError}
              dataLength={eventsData.length}
            />
          ) : (
            <>
              {eventsData.map((pEvent: PublicEvent) => (
                <TableExpanded.Row key={pEvent.event_id} headers={headers}>
                  <TableExpanded.Col>
                    <span
                      className="long-string"
                      onClick={() =>
                        navigate(
                          `${frontendRoutes.publicEventsCatalog.events}/${pEvent.event_id}`
                        )
                      }
                    >
                      {pEvent.event_name}
                    </span>
                  </TableExpanded.Col>
                  <TableExpanded.Col>
                    <span className="long-string">
                      {`
                            ${pEvent?.event_creator?.first_name} 
                            ${pEvent?.event_creator?.middle_name} 
                            ${pEvent?.event_creator?.last_name}
                          `}
                    </span>
                  </TableExpanded.Col>
                  <TableExpanded.Col>
                    <span className="long-string">
                      {pEvent?.event_creator?.organization_name}
                    </span>
                  </TableExpanded.Col>
                  <TableExpanded.Col>
                    <span className="long-string">
                      {formatDateStr(pEvent?.event_start_date, {
                        isDateOnly: true,
                      })}{' '}
                      -{' '}
                      {formatDateStr(pEvent?.event_end_date, {
                        isDateOnly: true,
                      })}
                    </span>
                  </TableExpanded.Col>

                  <TableExpanded.Expanded
                    innerText={pEvent?.event_theme}
                    items={[
                      {
                        itemTitle: 'Роль',
                        itemData: Array.isArray(pEvent?.event_user_role)
                          ? pEvent?.event_user_role
                              ?.map(
                                (role) => dictionary?.events.role[role] ?? ''
                              )
                              .join(', ') ?? 'Нет роли'
                          : dictionary?.events.role[
                              pEvent?.event_user_role ?? ''
                            ] ?? 'Нет роли',
                      },
                      {
                        itemTitle: 'Количество участников',
                        itemData: `${pEvent?.event_members ?? 0}`,
                      },
                      {
                        itemTitle: 'Документы',
                        itemData: '',
                      },
                      {
                        itemTitle: 'Тематические теги',
                        itemData: pEvent?.event_tags.join(', '),
                      },
                    ]}
                  />
                </TableExpanded.Row>
              ))}
            </>
          )}
        </TableExpanded>
      </div>
      <div className="pagination">
        {perPage && eventsData?.length > perPage && (
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            setPage={setPage}
          />
        )}
      </div>
    </div>
  );
};
