import styles from './InteractiveMap.module.scss';
import { useNavigate } from 'react-router-dom';
import { RuMap } from '../../../../ui/RuMap';

export const InteractiveMap = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    //TODO added new route
    navigate('/interactive-map');
  };

  return (
    <section className={styles.interactiveMap}>
      <div className={styles.interactiveMap__wrapper}>
        <nav className={styles.navigation}>
          <div className={styles.navigation__title}>
            Доступ к открытым данным
          </div>
          <div className={styles.navigation__link} onClick={handleClick}>
            Перейти к карте
          </div>
        </nav>
        <div className={styles.content}>
          <div className={styles.content__title}>23 000+</div>
          <div className={styles.content__subTitle}>
            респондентов приняли участие в исследованиях
          </div>
        </div>
        <div className={styles.interactiveMap__wrapper_map}>
          {/*<RuMap setRegion={() => {}} />*/}
        </div>
      </div>
    </section>
  );
};
