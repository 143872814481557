//  ui
import { LoaderFullPage } from './LoaderFullPage';

import styles from './Loader.module.scss';

export const Loader = (): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.loader}>
        <div className={styles.blocker}></div>
        <div className={styles.bottomLeft}></div>
        <div className={styles.bottomRight}></div>
        <div className={styles.topLeft}></div>
      </div>
    </div>
  );
};

Loader.FullPage = LoaderFullPage;
