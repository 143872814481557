import React from 'react';

import styles from './Functionality.module.scss';
import { Slider } from './Slider/Slider';

export const Functionality = () => {
  return (
    <section className={styles.functionality}>
      <nav className={styles.navigation}>
        <div className={styles.navigation__title}>
          Функциональные возможности
        </div>
        <Slider />
      </nav>
    </section>
  );
};
