import Image1 from '../../assets/images/MainPage/security/Image1.png';
import Image2 from '../../assets/images/MainPage/security/Image2.png';
import Image3 from '../../assets/images/MainPage/security/Image3.png';

export const SecurityItems = [
  {
    img: Image1,
    title:
      '<strong>Защищаем персональные данные</strong><br><br>Собираем только релевантные данные и используем исключительно в целях, заявленных в исследовании',
  },
  {
    img: Image2,
    title:
      '<strong>Учитываем анонимность</strong><br><br>Исследователь получает ответы респондентов без возможности идентифицировать конкретного человека',
  },
  {
    img: Image3,
    title:
      '<strong>Уведомляем о процедуре проведения</strong><br><br>Респондент дает согласие на участие в исследовании перед началом опроса',
  },
];
