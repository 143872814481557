import { Layout } from '../../../components/Layout';
import { DocumentEditor } from '../../../components/pages/document/DocumentEditor';

export const EditDocument = (): JSX.Element => {
  return (
    <Layout>
      <DocumentEditor />
    </Layout>
  );
};
