import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { frontendRoutes } from '../../../../utils/router/routes';
import { Box } from '../../../ui/Box';
import { Loader } from '../../../ui/Loader';
import { Typography } from '../../../ui/Typography';
import { Button } from '../../../ui/Button';
import image from '../../../../assets/images/404_image.svg';
import styles from './DocumentContent.module.scss';
import {
  useAddExpertDocumentMutation,
  useGetDictionaryQuery,
  useGetDocumentQuery,
  useGetEventQuery,
  useGetExpertDocumentsQuery,
} from '../../../../store/VbudusheeAPI';
import classNames from 'classnames/bind';
import { DocumentContentActions } from './DocumentContentActions';
import { ContentModal } from '../../../ui/ContentModal';
import { Form, FormikContext, useFormik } from 'formik';
import { SelectSearch } from '../../../ui/Form/SelectSearch';
const cx = classNames.bind(styles);

type DocumentContentProps = {
  documentId: string;
};

export const DocumentContent = ({ documentId }: DocumentContentProps) => {
  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenModalExpert, setIsOpenModalExpert] = useState<boolean>(false);

  const { data, isLoading, isError } = useGetDocumentQuery({ id: documentId });

  const { data: event } = useGetEventQuery(
    {
      id: String(data?.data.event_id) ?? '',
    },
    { skip: !data?.data.event_id }
  );
  const { data: members } = useGetExpertDocumentsQuery(
    {
      id: data?.data.event_id ?? '',
    },
    { skip: !data?.data.event_id }
  );
  const { data: dictionary } = useGetDictionaryQuery({});

  const [isExpert, { isLoading: isLoadingExpert }] =
    useAddExpertDocumentMutation();
  const isDelete = (event: boolean) => {
    if (data?.data.document_status !== 'new') {
      setIsOpenModal(event);
    }
  };

  const userRole = useMemo(() => {
    if (data?.are_you_an_expert) {
      return 'expert';
    }
    if (
      event?.data.event_user_role &&
      event.data.event_user_role[0] === 'owner'
    ) {
      return 'author';
    }
    return 'participant';
  }, [event, data]);

  const arrMembers = useMemo(() => {
    return members?.members
      ? members?.members
          .filter(
            (member) =>
              member.event_role === 'expert' &&
              member.user_id !== data?.document_expert?.id
          )
          .map((el) => {
            return {
              value: String(el.user_id),
              option: `${el.first_name} ${el.last_name} ${el.middle_name}`,
            };
          })
      : [];
  }, [members, data]);

  const expertName = useMemo(() => {
    if (data?.document_expert) {
      return (
        `${data?.document_expert.last_name ?? ''}` +
        ' ' +
        `${data?.document_expert.first_name ?? ''}` +
        ' ' +
        `${data?.document_expert.middle_name ?? ''}`
      );
    }
  }, [data]);

  const submitHandler = async () => {
    if (!formikExpert.isValid) return;

    const result = await isExpert({
      id: documentId,
      body: {
        user_id: formikExpert.values.login,
        is_expert: true,
      },
    });
    if ('data' in result) {
      // eslint-disable-next-line no-console
      console.log('Эксперт успешно назначен');
    }
    setIsOpenModalExpert(false);
    formikExpert.resetForm();
  };

  const formikExpert = useFormik({
    initialValues: { login: '' },
    onSubmit: submitHandler,
  });

  return (
    <>
      {isLoading ? (
        <Loader.FullPage>
          <Loader />
        </Loader.FullPage>
      ) : isError ? (
        <Box flex>
          <Box>
            <Typography
              className={styles.wrapper__header_text}
              size={'xxxxl'}
              fontWeight={600}
              customLineHeight={56}
              color={'totalBlack'}
            >
              Что-то пошло не так
            </Typography>
            <Box mt={30}>
              <Button
                label="На главную"
                type={'button'}
                color={'gradient'}
                size={'medium'}
                clicked={() => navigate(frontendRoutes.mainPage)}
              ></Button>
            </Box>
          </Box>
          <div>
            <img src={image} alt="Lifebuoy custom image" />
          </div>
        </Box>
      ) : (
        <>
          <div className={styles.documentWrapper}>
            <h1 className={styles.documentTitle}>Информация о документе</h1>
            <div className={styles.documentInfoWrapper}>
              <div className={styles.documentInfo}>
                <p className={styles.documentInfoHeader}>Название документа</p>
                <p
                  className={styles.documentInfoText}
                  title={data?.data?.document_name}
                >
                  {data?.data?.document_name}
                </p>
              </div>
              <div className={styles.documentInfo}>
                <p className={styles.documentInfoHeader}>
                  Тип загружаемого документа
                </p>
                <p className={styles.documentInfoText}>
                  {dictionary?.documents?.document_type[
                    data?.data?.document_type || ''
                  ] || 'Нет Данных'}
                </p>
              </div>
              <div className={styles.documentInfo}>
                <p className={styles.documentInfoHeader}>
                  Текущий статус документа
                </p>
                <p
                  className={cx(
                    'documentInfoStatus',
                    `documentInfoStatus__${data?.data?.document_status}`
                  )}
                >
                  {dictionary?.documents?.document_status[
                    data?.data?.document_status || ''
                  ] || 'Нет Данных'}
                </p>
              </div>
            </div>
            {userRole === 'author' ? (
              <div className={styles.documentInfoWrapper}>
                <div className={styles.documentInfo}>
                  <p className={styles.documentInfoHeader}>Имя эксперта</p>
                  <p className={styles.documentInfoText}>
                    {expertName ? expertName : '—'}
                  </p>
                </div>
                <div className={styles.documentInfo}>
                  <p className={styles.documentInfoHeader}>Оценка</p>
                  <p className={styles.documentInfoText}>
                    {data?.data.total_estimate || '0'}
                  </p>
                </div>
              </div>
            ) : (
              <div className={styles.documentInfoWrapper}>
                <div className={styles.documentInfo}>
                  <p className={styles.documentInfoHeader}>Оценка</p>
                  <p className={styles.documentInfoText}>
                    {data?.data.total_estimate || '0'}
                  </p>
                </div>
              </div>
            )}
            <div>
              <p className={styles.documentInfoHeader}>
                Комментарий от эксперта
              </p>
              <p>{data?.data.document_comment || '—'}</p>
            </div>
            {data && (
              <DocumentContentActions
                data={data}
                userRole={userRole}
                documentId={documentId}
                expertName={expertName}
                isDelete={isDelete}
                setIsOpenModalExpert={setIsOpenModalExpert}
              />
            )}
          </div>
          <ContentModal
            isOpen={isOpenModal}
            onClose={() => {
              setIsOpenModal(false);
            }}
            titleText="Удаление документа"
          >
            <>
              <div>Вы уверены, что хотите удалить документ?</div>
              <Box flex mt={32}>
                <Button
                  label="Подтвердить"
                  gradient={true}
                  clicked={() => {
                    setIsOpenModal(false);
                  }}
                />
                <Box mr={16} />
                <Button
                  label="Отмена"
                  withoutBackground
                  clicked={() => {
                    setIsOpenModal(false);
                  }}
                />
              </Box>
            </>
          </ContentModal>
          <ContentModal
            isOpen={isOpenModalExpert}
            onClose={() => {
              setIsOpenModalExpert(false);
              formikExpert.resetForm();
            }}
            titleText="Выберите эксперта для проверки документа"
          >
            <>
              <FormikContext.Provider value={formikExpert}>
                <Form autoComplete="off">
                  <div>
                    <SelectSearch
                      name="login"
                      placeholder="Поиск"
                      options={arrMembers}
                      fullWidth
                    />
                    <Box mt={24} mb={24}></Box>
                    {isLoadingExpert ? (
                      <Loader.FullPage>
                        <Loader />
                      </Loader.FullPage>
                    ) : (
                      <>
                        {expertName && data?.document_expert?.email && (
                          <div>
                            <Typography color="main" size="sm" align="left">
                              {data?.document_expert?.email || 'Нет данных'}
                            </Typography>
                            <Typography color="greyDef" size="s" align="left">
                              {expertName || 'Нет данных'}
                            </Typography>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </Form>
              </FormikContext.Provider>
              <div className="divider"></div>
              <Box flex>
                <Button
                  label="Выбрать"
                  gradient={true}
                  disabled={!formikExpert.isValid || !formikExpert.dirty}
                  clicked={submitHandler}
                />
                <Box mr={16} />
                <Button
                  label="Отмена"
                  withoutBackground
                  clicked={() => {
                    setIsOpenModalExpert(false);
                    formikExpert.resetForm();
                  }}
                />
              </Box>
            </>
          </ContentModal>
        </>
      )}
    </>
  );
};
