// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AccessDeniedModal_wrapper__qoz1q .AccessDeniedModal_bottom__Yh3Kr {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/AccessDeniedModal/AccessDeniedModal.module.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAJ","sourcesContent":[".wrapper {\n  .bottom {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `AccessDeniedModal_wrapper__qoz1q`,
	"bottom": `AccessDeniedModal_bottom__Yh3Kr`
};
export default ___CSS_LOADER_EXPORT___;
