import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { IconArrowMenu } from '../../../ui/Icons/Research/IconArrowMenu';
import { ComparisonSidePanelResearchList } from './ComparisonSidePanelResearchList';
import { ResearchComparison } from '../../../../models/research';
import { ComparisonSidePanelFilter } from './ComparisonSidePanelFilter';
import { useGetComparisonResearchMutation } from '../../../../store/VbudusheeAPI';
import { useParams } from 'react-router-dom';
import styles from './ComparisonSidePanel.module.scss';
const cx = classNames.bind(styles);

type ComparisonSidePanelProps = {
  getResearchCompare: (body: any) => void;
};

export const ComparisonSidePanel = ({
  getResearchCompare,
}: ComparisonSidePanelProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(true);
  const [availableResearch, setAvailableResearch] = useState<
    ResearchComparison[]
  >([]);
  const [addedResearch, setAddedResearch] = useState<ResearchComparison[]>([]);
  const [filterHeight, setFilterHeight] = useState<number>(0);
  const [isResearchAdded, setResearchAdded] = useState<boolean>(false);

  const [page, setPage] = useState(1);

  const [getResearch, { data, isLoading }] = useGetComparisonResearchMutation();

  const research = data?.data;
  const researchSteps = data?.filters?.research_step;

  const filterRef = useRef<HTMLDivElement>(null);

  const { researchId } = useParams();

  useEffect(() => {
    if (researchId && data?.selected_research) {
      setAddedResearch([data.selected_research]);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      if (page > 1) {
        setAvailableResearch((prew) => {
          return [...prew, ...data.data];
        });
      } else {
        setAvailableResearch(data.data);
      }
    }
  }, [data]);

  const fetchMore = async (filter: unknown) => {
    const data = (await getResearch(filter).unwrap()).data;
    const av = availableResearch;
    setAvailableResearch([...av, ...data]);
    // eslint-disable-next-line no-console
    console.log(availableResearch);
  };

  useEffect(() => {
    if (researchId && availableResearch.length && !isResearchAdded) {
      setAvailableResearch((prev) =>
        prev.filter((research) => research.research_id !== +researchId)
      );

      setResearchAdded(true);
    }
  }, [availableResearch]);

  const updateResearch = (filters: any) => {
    getResearch(filters);
  };

  const handleResearchCompare = () => {
    const arg = {
      researches: addedResearch.map((el) => {
        return el.research_id;
      }),
    };

    getResearchCompare(arg);
  };

  const handleToggleResearch = (res: ResearchComparison) => {
    const containsResearch = addedResearch.find(
      (item) => item.research_id === res.research_id
    );

    setAddedResearch((prev) =>
      containsResearch
        ? prev.filter((item) => item.research_id !== res.research_id)
        : [...prev, res]
    );

    setAvailableResearch((prev) =>
      containsResearch
        ? [res, ...prev]
        : prev.filter((item) => item.research_id !== res.research_id)
    );
  };

  // useEffect(() => {
  //   if (research) {
  //     const addedResearchIds = addedResearch.map(
  //       (research) => research.research_id
  //     );
  //     const filteredResearch = research.filter(
  //       (research) => !addedResearchIds.includes(research.research_id)
  //     );
  //
  //     setAvailableResearch(filteredResearch);
  //   }
  // }, [research]);

  return (
    <div className={cx('sidebar', { active: isOpen })}>
      <div className={styles.sidebarTop}>
        <p
          className={styles.sidebarTopText}
          onClick={() => setIsOpenFilter(!isOpenFilter)}
        >
          {isOpenFilter ? 'Скрыть фильтры' : 'Показать фильтры'}
        </p>
        <span
          className={cx('sidebarTopBtn', { active: isOpen })}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <IconArrowMenu />
          <div className={styles.sidebarTopBtnBorder} />
        </span>
      </div>
      <ComparisonSidePanelFilter
        isOpenFilter={isOpenFilter}
        isOpen={isOpen}
        researchSteps={researchSteps}
        updateResearch={updateResearch}
        filterRef={filterRef}
        setFilterHeight={setFilterHeight}
        page={page}
        setPage={setPage}
      />
      <div className={styles.sidebarContent}>
        {isOpen && (
          <ComparisonSidePanelResearchList
            title="Доступные"
            subTitle="Выберите исследования, которые нужно сравнить"
            status="available"
            research={availableResearch}
            handleToggleResearch={handleToggleResearch}
            isAddingPossible={addedResearch.length < 2}
            filterHeight={filterHeight}
            fetchMore={fetchMore}
            isLoading={isLoading}
            setPage={setPage}
          />
        )}
        {isOpen && <div className={styles.divider} />}
        <ComparisonSidePanelResearchList
          title="Добавленные"
          subTitle="Добавляйте сюда исследования, чтобы сравнить их"
          status="added"
          research={addedResearch}
          handleToggleResearch={handleToggleResearch}
          isAddingPossible={addedResearch.length < 2}
          filterHeight={filterHeight}
        />
      </div>

      {isOpen && (
        <button
          className={styles.sidebarCompareBtn}
          onClick={() => handleResearchCompare()}
          disabled={addedResearch.length < 2}
        >
          Сравнить исследования
        </button>
      )}
    </div>
  );
};
