//  ui
import { Layout } from '../../../components/Layout';
import { GradingSystem } from '../../../components/pages/settingsEvent/GradingSystem/GradingSystem';

export const GradingSystemPage = () => {
  return (
    <Layout>
      <GradingSystem />
    </Layout>
  );
};
