// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-checkbox {
    top: 0;
}

.ant-checkbox-wrapper {
    align-items: center;
    margin-top: 12px;
}

.ant-checkbox-group{
    display: flex;
    flex-direction: column;
}

.ant-checkbox-inner {
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 2px solid #E1E5EA;
    border-radius: 4px;
}

.ant-checkbox-checked .ant-checkbox-inner {
    border-color: #5BCAB5;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background: linear-gradient(56.46deg, #A4EEDC -45.58%, #5BCAB5 19.1%, #6BAAE4 100%);
    border: none;
}

.ant-checkbox-inner::after {
    top: 45%;
    left: 32%;
    width: 6.7px;
    height: 10px;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #E1E5EA;
}

.ant-checkbox-checked::after {
    border: none;
}

.ant-checkbox + span {
    padding-left: 10px;
}



`, "",{"version":3,"sources":["webpack://./src/components/ui/Form/CheckBox/CheckboxSurvey/index.css"],"names":[],"mappings":"AAAA;IACI,MAAM;AACV;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mFAAmF;IACnF,YAAY;AAChB;;AAEA;IACI,QAAQ;IACR,SAAS;IACT,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".ant-checkbox {\n    top: 0;\n}\n\n.ant-checkbox-wrapper {\n    align-items: center;\n    margin-top: 12px;\n}\n\n.ant-checkbox-group{\n    display: flex;\n    flex-direction: column;\n}\n\n.ant-checkbox-inner {\n    width: 20px;\n    height: 20px;\n    background-color: #fff;\n    border: 2px solid #E1E5EA;\n    border-radius: 4px;\n}\n\n.ant-checkbox-checked .ant-checkbox-inner {\n    border-color: #5BCAB5;\n}\n\n.ant-checkbox-checked .ant-checkbox-inner {\n    background: linear-gradient(56.46deg, #A4EEDC -45.58%, #5BCAB5 19.1%, #6BAAE4 100%);\n    border: none;\n}\n\n.ant-checkbox-inner::after {\n    top: 45%;\n    left: 32%;\n    width: 6.7px;\n    height: 10px;\n}\n\n.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {\n    border-color: #E1E5EA;\n}\n\n.ant-checkbox-checked::after {\n    border: none;\n}\n\n.ant-checkbox + span {\n    padding-left: 10px;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
