import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { ComparisonContentEmpty } from './ComparisonContentEmpty';
import {
  CommonResponse,
  ComparisonResearchRequest,
} from '../../../../models/research';
import { Loader } from '../../../ui/Loader';
import { ComparisonContentBarChart } from './ComparisonContentBarChart';
import { ComparisonContentEmptyMethods } from './ComparisonContentEmptyMethods';
import { useAddResearchVersusMutation } from '../../../../store/VbudusheeAPI';
import { ComparisonDownloadButton } from './ComparisonDownloadButton';
import styles from './ComparisonContent.module.scss';

type ComparisonContentProps = {
  researches: number[];
  compare?: CommonResponse;
  loading: boolean;
};
export const ComparisonContent = ({
  researches,
  compare,
  loading = false,
}: ComparisonContentProps) => {
  const [addResearchVersus, { data, isLoading }] =
    useAddResearchVersusMutation();

  const researchCompare = (body: ComparisonResearchRequest) => {
    addResearchVersus(body);
  };

  return (
    <div className={styles.content}>
      <Breadcrumbs
        links={[{ title: 'Сравнение результатов исследований' }]}
        homeLink="/"
      />
      <div className={styles.contentTop}>
        <h1 className={styles.contentTitle}>
          Сравнение результатов исследований
        </h1>
        {(compare && compare.methods.length && (
          <ComparisonDownloadButton researches={researches} />
        )) ||
          null}
      </div>
      {loading ? (
        <Loader.FullPage>
          <Loader />
        </Loader.FullPage>
      ) : compare && compare?.methods.length > 0 ? (
        <ComparisonContentBarChart
          researches={researches}
          compare={compare}
          researchCompare={researchCompare}
          researchVersus={data}
          loading={isLoading}
        />
      ) : compare && compare?.methods.length === 0 ? (
        <ComparisonContentEmptyMethods />
      ) : (
        <ComparisonContentEmpty />
      )}
    </div>
  );
};
