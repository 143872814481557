/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/rules-of-hooks */
import { object, ref, string, array, number, lazy } from 'yup';
import { VALIDATION_ERRORS } from '../errors/errors';
import {
  NO_SPACES,
  NO_SPECIAL_SYMBOLS,
  VALID_EMAIL,
  RU_NAMES,
  RU_REGION,
  PASSWORD,
} from '../redExp/regExp';
import * as yup from 'yup';
import mapValues from 'lodash/mapValues';
import { Company } from '../../daData';
import { MaterialResearchFilters } from '../../research';
import { useCheckEmailRegistrationMutation } from '../../../store/VbudusheeAPI';
/**
 * Схема валидации формы входа
 */
export const authValidationSchema = object({
  email: string()
    // .email(VALIDATION_ERRORS.email.incorrectEmail)
    // .matches(VALID_EMAIL, VALIDATION_ERRORS.email.incorrectEmail)
    .required(VALIDATION_ERRORS.required),
  password: string()
    .min(8, VALIDATION_ERRORS.password.short)
    .required(VALIDATION_ERRORS.required),
});

export const authInitialValues = {
  email: '',
  password: '',
};

export const changePasswordInitialValues = {
  password: '',
};

export const confirmPasswordInitialValues = {
  password: '',
  password_confirmation: '',
};

export const confirmEmailInitialValues = {
  // password: '',
  email: '',
  email_confirmation: '',
};

/**
 * Схема валидации формы смены пароля в профиле пользователя
 */
export const changePasswordValidationSchema = object({
  password: string()
    .min(8, VALIDATION_ERRORS.password.short)
    .matches(NO_SPACES, VALIDATION_ERRORS.regExp)
    .required(VALIDATION_ERRORS.required),
});

/**
 * Схема валидации формы подтверждения смены пароля в профиле пользователя
 */
export const confirmPasswordValidationSchema = object({
  password: string()
    .min(8, VALIDATION_ERRORS.password.short)
    .matches(PASSWORD, VALIDATION_ERRORS.password.capitalLetter)
    .matches(NO_SPACES, VALIDATION_ERRORS.regExp)
    .required(VALIDATION_ERRORS.required),
  password_confirmation: string()
    .oneOf([yup.ref('password')], VALIDATION_ERRORS.password.confirm)
    .min(8, VALIDATION_ERRORS.password.short)
    .required(VALIDATION_ERRORS.required),
});

/**
 * Схема валидации формы смены почты в профиле пользователя
 */
export const changeEmailValidationSchema = object({
  email: string()
    .email(VALIDATION_ERRORS.email.incorrectEmail)
    .matches(VALID_EMAIL, VALIDATION_ERRORS.email.incorrectEmail)
    .required(VALIDATION_ERRORS.required),
});

/**
 * Схема валидации формы подтверждения смены почты в профиле пользователя
 */
export const confirmEmailValidationSchema = object({
  email: string()
    .email(VALIDATION_ERRORS.email.incorrectEmail)
    .required(VALIDATION_ERRORS.required),
  email_confirmation: string()
    .oneOf([yup.ref('email')], VALIDATION_ERRORS.email.confirm)
    .email(VALIDATION_ERRORS.email.incorrectEmail)
    .matches(VALID_EMAIL, VALIDATION_ERRORS.email.incorrectEmail)
    .required(VALIDATION_ERRORS.required),
});

export const verifyEmailValidationSchema = object({
  code: string()
    .required(VALIDATION_ERRORS.code.required)
    .min(6, VALIDATION_ERRORS.code.incorrectLenght)
    .max(6, VALIDATION_ERRORS.code.incorrectLenght)
    .matches(/\D*/g, VALIDATION_ERRORS.code.required),
});

/**
 * Схема валидации формы регистрации
 */

export const registrationValidationSchema = object({
  email: string()
    .email(VALIDATION_ERRORS.email.incorrectEmail)
    .matches(VALID_EMAIL, VALIDATION_ERRORS.email.incorrectEmail)
    .matches(NO_SPACES, VALIDATION_ERRORS.regExp)
    .required(VALIDATION_ERRORS.required),
  password: string()
    .min(8, VALIDATION_ERRORS.password.short)
    .matches(PASSWORD, VALIDATION_ERRORS.password.capitalLetter)
    .required(VALIDATION_ERRORS.required)
    .matches(NO_SPACES, VALIDATION_ERRORS.regExp),
  last_name: string()
    .min(2, VALIDATION_ERRORS.names.min)
    .required(VALIDATION_ERRORS.required)
    .matches(NO_SPACES, VALIDATION_ERRORS.regExp)
    .matches(NO_SPECIAL_SYMBOLS, VALIDATION_ERRORS.regExp)
    .matches(RU_NAMES, VALIDATION_ERRORS.names.onlyRu),
  first_name: string()
    .min(2, VALIDATION_ERRORS.names.min)
    .required(VALIDATION_ERRORS.required)
    .matches(NO_SPACES, VALIDATION_ERRORS.regExp)
    .matches(RU_NAMES, VALIDATION_ERRORS.names.onlyRu)
    .matches(NO_SPECIAL_SYMBOLS, VALIDATION_ERRORS.regExp),
  middle_name: string()
    .min(2, VALIDATION_ERRORS.names.min)
    .matches(NO_SPACES, VALIDATION_ERRORS.regExp)
    .matches(NO_SPECIAL_SYMBOLS, VALIDATION_ERRORS.regExp)
    .matches(RU_NAMES, VALIDATION_ERRORS.names.onlyRu),
  professional_category: string().required(VALIDATION_ERRORS.required),
  position_name: string().min(3, VALIDATION_ERRORS.names.min),
  // .required(VALIDATION_ERRORS.required),
  region: string().required(VALIDATION_ERRORS.required),
  // .matches(RU_REGION, VALIDATION_ERRORS.regExp),
  // .matches(NO_SPECIAL_SYMBOLS, VALIDATION_ERRORS.regExp),
  locality: string()
    // .matches(NO_SPECIAL_SYMBOLS, VALIDATION_ERRORS.regExp)
    .required(VALIDATION_ERRORS.required),
  organization_category: string().required(VALIDATION_ERRORS.required),
  organization_name: string().required(VALIDATION_ERRORS.required),
});

export const registrationInitialValues = {
  email: '',
  password: '',
  last_name: '',
  professional_category: '',
  first_name: '',
  position_name: '',
  middle_name: '',
  region: '',
  organization_category: '',
  locality: '',
  organization_name: '',
  user_role: 'user',
};

// emailCheck
// const [checkEmailRegistration, { error, isSuccess }] =
//   useCheckEmailRegistrationMutation();

// const validateEmail = async (email: string) => {
//   try {
//     const response = await checkEmailRegistration(email);
//     // @ts-ignore
//     const data = await response.json();
//     // @ts-ignore
//     if (response?.data?.result) {
//       return ''; // Email is valid
//     } else {
//       return data.errors.email[0]; // Return the error message
//     }
//   } catch (error) {
//     console.error('Error validating email:', error);
//     return 'Error validating email';
//   }
// };

/**
 * Схема валидации формы регистрации от имени организации
 */
export const companyRegistrationValidationSchema = object({
  email: string()
    .email(VALIDATION_ERRORS.email.incorrectEmail)
    .matches(VALID_EMAIL, VALIDATION_ERRORS.email.incorrectEmail)
    .matches(NO_SPACES, VALIDATION_ERRORS.regExp)
    .required(VALIDATION_ERRORS.required),
  // .test('unique', 'Email already exists', async function (value) {
  //   if (value) {
  //     const errorMessage = await validateEmail(value);
  //     return !errorMessage;
  //   }
  //   return true;
  // }),
  password: string()
    .min(8, VALIDATION_ERRORS.password.short)
    .matches(PASSWORD, VALIDATION_ERRORS.password.capitalLetter)
    .matches(NO_SPACES, VALIDATION_ERRORS.regExp)
    .required(VALIDATION_ERRORS.required),
  last_name: string()
    .min(2, VALIDATION_ERRORS.names.min)
    .required(VALIDATION_ERRORS.required)
    .matches(NO_SPECIAL_SYMBOLS, VALIDATION_ERRORS.regExp)
    .matches(NO_SPACES, VALIDATION_ERRORS.regExp)
    .matches(RU_NAMES, VALIDATION_ERRORS.names.onlyRu),
  first_name: string()
    .min(2, VALIDATION_ERRORS.names.min)
    .required(VALIDATION_ERRORS.required)
    .matches(NO_SPECIAL_SYMBOLS, VALIDATION_ERRORS.regExp)
    .matches(NO_SPACES, VALIDATION_ERRORS.regExp)
    .matches(RU_NAMES, VALIDATION_ERRORS.names.onlyRu),
  middle_name: string()
    .min(2, VALIDATION_ERRORS.names.min)
    .matches(NO_SPECIAL_SYMBOLS, VALIDATION_ERRORS.regExp)
    .matches(NO_SPACES, VALIDATION_ERRORS.regExp)
    .matches(RU_NAMES, VALIDATION_ERRORS.names.onlyRu),
  position_name: string()
    .min(3, VALIDATION_ERRORS.names.min)
    .required(VALIDATION_ERRORS.required),
  professional_category: string().required(VALIDATION_ERRORS.required),
  region: string().required(VALIDATION_ERRORS.required),
  locality: string().required(VALIDATION_ERRORS.required),
  organization_category: string().required(VALIDATION_ERRORS.required),
  organization_name: string().required(VALIDATION_ERRORS.required),
  organization_inn: string().required(VALIDATION_ERRORS.required),
});

export const respondentRegistrationValidationSchema = object({
  email: string()
    .email(VALIDATION_ERRORS.email.incorrectEmail)
    .matches(VALID_EMAIL, VALIDATION_ERRORS.email.incorrectEmail)
    .required(VALIDATION_ERRORS.required),
  password: string()
    .min(8, VALIDATION_ERRORS.password.short)
    .matches(PASSWORD, VALIDATION_ERRORS.password.capitalLetter)
    .matches(NO_SPACES, VALIDATION_ERRORS.regExp)
    .required(VALIDATION_ERRORS.required),
  confirmPassword: string()
    .oneOf([ref('password'), null], VALIDATION_ERRORS.password.confirm)
    .matches(NO_SPACES, VALIDATION_ERRORS.regExp)
    .required(VALIDATION_ERRORS.required),
  last_name: string()
    .min(2, VALIDATION_ERRORS.names.min)
    .required(VALIDATION_ERRORS.required)
    .matches(NO_SPECIAL_SYMBOLS, VALIDATION_ERRORS.regExp)
    .matches(NO_SPACES, VALIDATION_ERRORS.regExp)
    .matches(RU_NAMES, VALIDATION_ERRORS.names.onlyRu),
  first_name: string()
    .min(2, VALIDATION_ERRORS.names.min)
    .required(VALIDATION_ERRORS.required)
    .matches(NO_SPECIAL_SYMBOLS, VALIDATION_ERRORS.regExp)
    .matches(NO_SPACES, VALIDATION_ERRORS.regExp)
    .matches(RU_NAMES, VALIDATION_ERRORS.names.onlyRu),
  middle_name: string()
    .min(2, VALIDATION_ERRORS.names.min)
    .matches(NO_SPECIAL_SYMBOLS, VALIDATION_ERRORS.regExp)
    .matches(NO_SPACES, VALIDATION_ERRORS.regExp)
    .matches(RU_NAMES, VALIDATION_ERRORS.names.onlyRu),
  // professional_category: string().required(VALIDATION_ERRORS.required),
});

export const companyRegistrationInitialValues = {
  email: '',
  password: '',
  last_name: '',
  first_name: '',
  middle_name: '',
  position_name: '',
  professional_category: '',
  region: '',
  locality: '',
  organization_category: '',
  organization_name: '',
  organization_inn: '',
  member: false,
  member_date: '',
  user_role: 'corp_user',
};

export const respondentRegistrationInitialValues = {
  email: '',
  password: '',
  confirmPassword: '',
  last_name: '',
  first_name: '',
  middle_name: '',
  professional_category: '',
};

/**
 * Схема валидации формы восстановления пароля
 */
export const restorePassValidationSchema = object({
  email: string()
    .email(VALIDATION_ERRORS.email.incorrectEmail)
    .matches(VALID_EMAIL, VALIDATION_ERRORS.email.incorrectEmail)
    .required(VALIDATION_ERRORS.required),
});

export const restorePassInitialValues = {
  email: '',
};

/**
 * Схема валидации формы восстановления пароля
 */
export const restorePassAcceptValidationSchema = object({
  password: string()
    .min(8, VALIDATION_ERRORS.password.short)
    .matches(PASSWORD, VALIDATION_ERRORS.password.capitalLetter)
    .matches(NO_SPACES, VALIDATION_ERRORS.regExp)
    // .matches(NO_SPECIAL_SYMBOLS, VALIDATION_ERRORS.regExp)
    .required(VALIDATION_ERRORS.required),
  password_confirmation: string()
    .oneOf([ref('password'), null], VALIDATION_ERRORS.password.confirm)
    .required(VALIDATION_ERRORS.required),
});

export const restorePassAcceptInitialValues = {
  password: '',
  password_confirmation: '',
};

/**
 * Схема валидации формы фильтрации команд
 */
export const teamsFiltersValidationSchema = object({});

export const teamsFiltersInitialValues = {
  team_name: '',
  team_creator: '',
  team_creator_organization_name: '',
  dates: '',
};

/**
 * Схема валидации формы фильтрации мероприятий команды
 */
export const teamEventsFiltersValidationSchema = object({});

export const teamEventsFiltersInitialValues = {
  search: '',
  role: '',
  dates: '',
  status: '',
  publication: '',
  tags: '',
};

/**
 * Схема валидации формы фильтрации исследований команды
 */
export const teamResearchFiltersValidationSchema = object({});

export const teamResearchFiltersInitialValues = {
  search: '',
  type: '',
  dates: '',
  target: '',
  creator_name: '',
  status: '',
  researcherOrg: '',
  region: '',
  theme: '',
  publication: '',
};

/**
 * Схема валидации формы фильтрации документов команды
 */
export const teamDocsFiltersValidationSchema = object({});

export const teamDocsFiltersInitialValues = {
  search: '',
  owner: '',
  event_name: '',
  oo_name: '',
  oo_type: '',
  dates: '',
  tags: '',
  type: '',
};

export const researchVersusFiltersInitialValues = {
  research_name: '',
  research_region: [],
  date: '',
  research_start_date: '',
  research_end_date: '',
  research_education: '',
  research_target: '',
  research_step: '',
  method_id: '',
  is_longitudinal: false,
};

/**
 * Схема валидации формы фильтрации мероприятий
 */
export const materialEventsFiltersValidationSchema = object({});

export const materialEventsFiltersInitialValues = {
  search: '',
  role: '',
  dates: '',
  status: '',
  publicationStatus: '',
  tags: [],
};

/**
 * Схема валидации формы фильтрации исследований
 */
export const materialResearchFiltersValidationSchema = object({});

export const materialResearchFiltersInitialValues: MaterialResearchFilters = {
  search: '',
  dates: '',
  target: '',
  type: '',
  publication: '',
  status: '',
  region: [],
  public: '',
  theme: '',
};

/**
 * Схема валидации формы фильтрации документов
 */
export const materialDocsFiltersValidationSchema = object({});

export const materialDocsFiltersInitialValues = {
  search: '',
  event_name: '',
  dates: '',
  status: '',
  type: '',
  tags: [],
  public: '',
};

/**
 * Схема валидации формы фильтрации документов мероприятия для ёа
 */
export const materialDocsExpertFiltersValidationSchema = object({});

export const materialDocsExpertFiltersInitialValues = {
  search: '',
  typeDocument: '',
  author: '',
  prof: '',
  reg: [],
  puf: [],
  tags: '',
  organisatyon: '',
  dates: '',
};

/**
 * Схема валидации формы фильтрации обратной связи
 */
export const materialFeedbackFiltersValidationSchema = object({});

export const materialFeedbackFiltersInitialValues = {
  search: '',
  author: '',
  organization_name: '',
  dates: '',
};

/**
 * Схема валидации формы фильтрации каталога публичных мероприятий
 */
export const publicEventsFiltersValidationSchema = object({
  search: string(),
  role: string(),
  dates: string(),
});

export const publicEventsFiltersInitialValues = {
  search: '',
  role: '',
  dates: '',
  status: '',
  tags: [],
};

/**
 * Схема валидации формы фильтрации заявлений на публикацию каталога публичных мероприятий
 */
export const publicEventsRequestsFiltersValidationSchema = object({});

export const publicEventsRequestsFiltersInitialValues = {
  search: '',
  dates: '',
  status: '',
  tags: '',
  moderation: '',
  role: '',
};

/**
 * Схема валидации формы обновления профиля пользователя
 */
// export const updateProfileInitialValues = {
//   last_name: '',
//   first_name: '',
//   professional_category: '',
//   position_name: '',
//   locality: '',
//   organization_name: '',
//   region: '',
//   organization_category: '',
// };

export const updateProfileValidationSchema = object({
  last_name: string()
    .min(2, VALIDATION_ERRORS.names.min)
    .matches(NO_SPECIAL_SYMBOLS, VALIDATION_ERRORS.regExp)
    .matches(RU_NAMES, VALIDATION_ERRORS.names.onlyRu)
    .required(VALIDATION_ERRORS.required),
  first_name: string()
    .min(2, VALIDATION_ERRORS.names.min)
    .matches(NO_SPECIAL_SYMBOLS, VALIDATION_ERRORS.regExp)
    .matches(RU_NAMES, VALIDATION_ERRORS.names.onlyRu)
    .required(VALIDATION_ERRORS.required),
  professional_category: string().required(VALIDATION_ERRORS.required),
  position_name: string()
    .min(3, VALIDATION_ERRORS.names.min)
    .matches(NO_SPECIAL_SYMBOLS, VALIDATION_ERRORS.regExp)
    .matches(RU_NAMES, VALIDATION_ERRORS.names.onlyRu)
    .required(VALIDATION_ERRORS.required),
  locality: string()
    .matches(NO_SPECIAL_SYMBOLS, VALIDATION_ERRORS.regExp)
    .required(VALIDATION_ERRORS.required),
  organization_name: string().required(VALIDATION_ERRORS.required),
  region: string()
    .matches(NO_SPECIAL_SYMBOLS, VALIDATION_ERRORS.regExp)
    .required(VALIDATION_ERRORS.required),
  organization_category: string().required(VALIDATION_ERRORS.required),
});

export const startSurveyInitialValues = {
  // region: '',
  // organization_name: '',
  // organization_category: '',
  // locality: '',
  // prof_categories: '',
  // age_categories: '',
  processing_of_personal_data: false,
};

/**
 * Схема валидации формы начало опроса
 **/
export const startSurveyValidationSchema = object({
  region: string().required(VALIDATION_ERRORS.required),
  organization_name: string().required(VALIDATION_ERRORS.required),
  locality: string().required(VALIDATION_ERRORS.required),
  prof_categories: string().required(VALIDATION_ERRORS.required),
  age_categories: string().required(VALIDATION_ERRORS.required),
});

export const surveyEmailInitialValues = {
  email: '',
};

/**
 * Схема валидации формы смены пароля в профиле пользователя
 */
export const surveyEmailValidationSchema = object({
  email: string()
    .email(VALIDATION_ERRORS.email.incorrect)
    .matches(VALID_EMAIL, VALIDATION_ERRORS.email.incorrectEmail)
    .required(VALIDATION_ERRORS.required),
});

export const surveyPasswordInitialValues = {
  password: '',
};

/**
 * Схема валидации формы смены пароля в профиле пользователя
 */
export const surveyPasswordValidationSchema = object({
  password: string()
    .min(8, VALIDATION_ERRORS.password.short)
    .matches(NO_SPACES, VALIDATION_ERRORS.regExp)
    .required(VALIDATION_ERRORS.required),
});

/**
 * Схема валидации формы анкетных вопросов
 */
export const questionnaireInitialValues = {
  radiobutton: '',
  checkbox: '',
  textarea: '',
  select: '',
};

export const questionnaireValidationSchema = lazy((obj) =>
  object(
    mapValues(obj, (value, key) => {
      if (Array.isArray(value)) {
        return array().min(1, VALIDATION_ERRORS.mandatoryQuestion);
      }
      return string().required(VALIDATION_ERRORS.mandatoryQuestion);
    })
  )
);

export const questionsMethodsInitialValues = {
  questions: [],
};

// export const questionsMethodsValidationSchema = lazy((obj) =>
//   object(
//     mapValues(obj, (value, key) => {
//       return array()
//         .of(array().of({}).required(VALIDATION_ERRORS.required)
//         .min(1, VALIDATION_ERRORS.mandatoryQuestion);
//     })
//   )
// );

//     object().shape({
//   questions: array()
//     .of(
//       object()
//         .shape({
//           question: number().required(VALIDATION_ERRORS.required),
//         })
//         .required(VALIDATION_ERRORS.required)
//     )
//     .required(VALIDATION_ERRORS.required),
// });
/**
 *Схема валидации формы создания новой команды
 * */

export const teamCreateNewSchema = object({
  team_name: string().required(VALIDATION_ERRORS.teams.required),
  team_description: string().required(VALIDATION_ERRORS.teams.required),
  team_icon_path: string(),
});

export const teamCreateNewInitialValues = {
  team_name: '',
  team_description: '',
  team_icon_image: '',
};

/**
 * Схема валидации формы редактирования информации о команде
 */

export const teamEditSchema = object({
  team_name: string().required(VALIDATION_ERRORS.teams.requiredTeamName),
  team_description: string().required(
    VALIDATION_ERRORS.teams.requiredTeamDescription
  ),
  team_icon_image: string() || undefined,
  team_icon_path: string() || undefined,
  member: string()
    .email(VALIDATION_ERRORS.email.incorrectEmail)
    .matches(VALID_EMAIL, VALIDATION_ERRORS.email.incorrectEmail),
  // .required(VALIDATION_ERRORS.required),
  moderator: string()
    .email(VALIDATION_ERRORS.email.incorrectEmail)
    .matches(VALID_EMAIL, VALIDATION_ERRORS.email.incorrectEmail),
  // .required(VALIDATION_ERRORS.required),
});

export const teamEditInitialValues = {
  team_name: '',
  team_description: '',
  team_icon_image: '',
  team_icon_path: '',
  member: '',
  moderator: '',
};

/**
 * Схема валидации добавления учатсников команды
 */

// export const addParticipantsSchema = object({
//   member: string()
//     .email(VALIDATION_ERRORS.email.incorrect)
//     .matches(VALID_EMAIL, VALIDATION_ERRORS.email.incorrectEmail),
//   moderator: string()
//     .email(VALIDATION_ERRORS.email.incorrect)
//     .matches(VALID_EMAIL, VALIDATION_ERRORS.email.incorrectEmail),
// });

// export const addParticipantsInitialValues = {
//   member: '',
//   moderator: '',
// };

export const createEventInitialValues = {
  event_name: '',
  event_description: '',
  event_document_type: '',
  event_start_date: '',
  event_theme: '',
};

/**
 * Схема валидации добавления учатсников команды
 */

export const createEventValidationSchema = object({
  event_name: string()
    .required(VALIDATION_ERRORS.required)
    .min(2, VALIDATION_ERRORS.event.event_name.minLength)
    .max(100, VALIDATION_ERRORS.event.event_name.maxLength),
  event_description: string()
    .required(VALIDATION_ERRORS.required)
    .min(10, VALIDATION_ERRORS.event.event_description.minLength)
    .max(1000, VALIDATION_ERRORS.event.event_description.maxLength),
  event_document_type: string().required(VALIDATION_ERRORS.required),
  event_start_date: string().required(VALIDATION_ERRORS.required),
  event_theme: string().required(VALIDATION_ERRORS.required),
});

/**
 * Схема валидации системы оценивания
 */

export const gradingSystemValidationSchema = object({
  rules_load_document: string(),
  description_criteria: string(),
});
