export const IconJpg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7365 2.76172H8.08449C6.02449 2.76172 4.25049 4.43072 4.25049 6.49072V17.3397C4.25049 19.5157 5.90849 21.1147 8.08449 21.1147H16.0725C18.1325 21.1147 19.8025 19.3997 19.8025 17.3397V8.03772L14.7365 2.76172Z"
        stroke="#16A086"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4739 2.75V5.659C14.4739 7.079 15.6229 8.231 17.0429 8.234C18.3589 8.237 19.7059 8.238 19.7969 8.232"
        stroke="#16A086"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="12" y="11" width="12" height="7" fill="white" />
      <path
        d="M9.456 11.7036H10.768V15.5916C10.768 15.9116 10.736 16.1836 10.672 16.4076C10.6133 16.6316 10.512 16.8156 10.368 16.9596C10.2293 17.0983 10.0453 17.2023 9.816 17.2716C9.592 17.3356 9.31467 17.3676 8.984 17.3676C8.75467 17.3676 8.56267 17.3543 8.408 17.3276C8.25867 17.3063 8.12267 17.2769 8 17.2396V15.9836C8.07467 16.0423 8.184 16.0983 8.328 16.1516C8.47733 16.1996 8.62933 16.2236 8.784 16.2236C9.024 16.2236 9.19467 16.1703 9.296 16.0636C9.40267 15.9569 9.456 15.7436 9.456 15.4236V11.7036Z"
        fill="#16A086"
      />
      <path
        d="M12.2233 11.7196C12.3993 11.7143 12.5566 11.7089 12.6953 11.7036C12.8393 11.6929 12.9833 11.6849 13.1273 11.6796C13.2713 11.6743 13.4206 11.6716 13.5753 11.6716C13.7353 11.6663 13.9193 11.6636 14.1273 11.6636C14.57 11.6636 14.9433 11.7009 15.2473 11.7756C15.5566 11.8449 15.8073 11.9569 15.9993 12.1116C16.1913 12.2663 16.33 12.4689 16.4153 12.7196C16.506 12.9649 16.5513 13.2663 16.5513 13.6236C16.5513 14.3489 16.3566 14.8823 15.9673 15.2236C15.578 15.5649 14.9593 15.7356 14.1113 15.7356C14.0046 15.7356 13.9086 15.7356 13.8233 15.7356C13.738 15.7303 13.642 15.7223 13.5353 15.7116V17.3036H12.2233V11.7196ZM13.5353 14.6316C13.642 14.6423 13.7353 14.6503 13.8153 14.6556C13.9006 14.6556 13.9993 14.6556 14.1113 14.6556C14.5166 14.6556 14.7993 14.5756 14.9593 14.4156C15.1193 14.2556 15.1993 14.0129 15.1993 13.6876C15.1993 13.5276 15.1833 13.3889 15.1513 13.2716C15.1193 13.1543 15.0606 13.0556 14.9753 12.9756C14.89 12.8956 14.7753 12.8369 14.6313 12.7996C14.4926 12.7623 14.3166 12.7436 14.1033 12.7436C13.9966 12.7436 13.9006 12.7463 13.8153 12.7516C13.7353 12.7516 13.642 12.7569 13.5353 12.7676V14.6316Z"
        fill="#16A086"
      />
      <path
        d="M20.0294 14.1436H22.1894V16.9836C22.0987 17.0316 21.9867 17.0796 21.8534 17.1276C21.72 17.1756 21.5654 17.2209 21.3894 17.2636C21.2187 17.3063 21.0267 17.3409 20.8134 17.3676C20.6054 17.3943 20.384 17.4076 20.1494 17.4076C19.1734 17.4076 18.456 17.1809 17.9974 16.7276C17.5387 16.2743 17.3094 15.5489 17.3094 14.5516C17.3094 14.0503 17.3627 13.6156 17.4694 13.2476C17.5814 12.8743 17.7547 12.5649 17.9894 12.3196C18.2294 12.0743 18.536 11.8929 18.9094 11.7756C19.288 11.6583 19.744 11.5996 20.2774 11.5996C20.672 11.5996 20.9947 11.6263 21.2454 11.6796C21.496 11.7329 21.6854 11.7889 21.8134 11.8476V13.1036C21.6214 12.9863 21.4054 12.8956 21.1654 12.8316C20.9254 12.7623 20.6587 12.7276 20.3654 12.7276C20.056 12.7276 19.792 12.7623 19.5734 12.8316C19.36 12.8956 19.184 12.9969 19.0454 13.1356C18.912 13.2743 18.8134 13.4556 18.7494 13.6796C18.6907 13.9036 18.6614 14.1703 18.6614 14.4796C18.6614 15.1303 18.7894 15.5943 19.0454 15.8716C19.3014 16.1436 19.704 16.2796 20.2534 16.2796C20.4347 16.2796 20.5867 16.2689 20.7094 16.2476C20.8374 16.2263 20.9494 16.2023 21.0454 16.1756V15.1996H20.0294V14.1436Z"
        fill="#16A086"
      />
    </svg>
  );
};
