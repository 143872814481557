export type ItemSubMenu = {
  title: string;
  url: string;
};

export type ItemMenuType = {
  title: string;
  url: string;
  submenu?: ItemSubMenu[] | null;
  disabled?: boolean;
  condition?: Condition;
};

export type Condition = [string, string];

export const researchMenu: ItemMenuType[] = [
  { title: 'Новое исследование', url: '/research/new' },
  { title: 'Анализ результатов', url: '/research/comparison' },
  { title: 'Карта открытых данных', url: '/interactive-map' },
  {
    title: 'Заявки на публикацию исследования',
    url: '/research/requests-on-publish',
    condition: ['user.role.slug', 'fund_user'],
  },
];

export const teamsMenu: ItemMenuType[] = [
  { title: 'Новая команда', url: '/team/new' },
  {
    title: 'Каталог команд',
    url: '/teams',
    submenu: [
      {
        title: 'Команда 1',
        url: '/team_1',
      },
      {
        title: 'Команда 2',
        url: '/team_2',
      },
      {
        title: 'Команда 3',
        url: '/team_3',
      },
    ],
  },
];
