import { HMenuLink } from '../../../models/components/menu';
import classNames from 'classnames/bind';
import styles from './HMenu.module.scss';
const cx = classNames.bind(styles);

type HMenuTypes = {
  links: HMenuLink[];
};

export const HMenu = ({ links }: HMenuTypes) => {
  return (
    <div className={styles.menu}>
      {links.map((link) => {
        if (link.access) {
          return (
            <div
              className={cx('menu__item', {
                menu__item_active: link.active,
                menu__item_disable: link.disable,
              })}
              key={link.label}
              onClick={link.onClick}
            >
              {link.label}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};
