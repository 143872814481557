export const IconLoader = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 9.625C15.1655 9.625 9.625 15.1655 9.625 22C9.625 28.8345 15.1655 34.375 22 34.375C28.8345 34.375 34.375 28.8345 34.375 22H38.5C38.5 31.1127 31.1127 38.5 22 38.5C12.8873 38.5 5.5 31.1127 5.5 22C5.5 12.8873 12.8873 5.5 22 5.5C23.1391 5.5 24.0625 6.42341 24.0625 7.5625C24.0625 8.70159 23.1391 9.625 22 9.625Z"
        fill="url(#paint0_radial_11282_9280)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_11282_9280"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.8448 20.9091) rotate(116.792) scale(40.6581 20.3417)"
        >
          <stop offset="0.140625" stopColor="#107F8C" stopOpacity="0" />
          <stop offset="1" stopColor="#5CC9B7" />
        </radialGradient>
      </defs>
    </svg>
  );
};
