import React, { ReactElement, useMemo } from 'react';
import { ContentModal } from '../ContentModal';
import { MODAL_TEXT_CONSTANT } from '../../../constants/modal';
import { Button } from '../../ui/Button/Button';
import { Typography } from '../../ui/Typography/Typography';
import { Box } from '../Box';

type ContentModalTypes = {
  isOpen?: boolean;
  errorText?: string;
  onClose: () => void;
  onDelete?: () => Promise<void>;
  titleText?: string;
  children?: ReactElement;
};

export const ModalError = ({
  isOpen,
  errorText,
  onClose,
  onDelete,
  titleText = 'Ошибка',
  children,
}: ContentModalTypes) => {
  const searchError = useMemo(() => {
    if (errorText) {
      const res = MODAL_TEXT_CONSTANT.ERROR.variable.find(
        (el) => el.key === errorText
      );
      return res ? res.text : errorText;
    }
    return MODAL_TEXT_CONSTANT.ERROR.default;
  }, [errorText]);

  return (
    <ContentModal
      isOpen={isOpen}
      onClose={() => onClose()}
      titleText={titleText}
    >
      <>
        {errorText && (
          <Typography color="main" size="m">
            {searchError}
          </Typography>
        )}
        <div>{children}</div>
      </>
    </ContentModal>
  );
};
