//  declOfNum(files.length, ['файл', 'файла', 'файлов'])
export const declOfNum = (number: number, words: string[]): string => {
  const cases = [2, 0, 1, 1, 1, 2];
  return words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

//  Check if boolean (true or false) value exist
//  (to parse responses with boolean value)
export const isBoolean = (value: unknown): boolean => {
  if (typeof value === 'boolean') {
    return true;
  }
  return false;
};
