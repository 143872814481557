import { object, string, array, number, date, boolean } from 'yup';
import { VALIDATION_ERRORS } from '../errors/errors';
import { ONLY_INT } from '../redExp/regExp';

export const researchSettingsSchema = object({
  research_name: string()
    .required(VALIDATION_ERRORS.research.requiredResearchName)
    .min(2, VALIDATION_ERRORS.research.requiredResearchNameMinLength)
    .max(100, VALIDATION_ERRORS.research.requiredResearchNameMaxLength),
  research_description: string()
    .required(VALIDATION_ERRORS.research.requiredResearchDescription)
    .min(11, VALIDATION_ERRORS.research.requiredResearchDescriptionMinLength)
    .max(1000, VALIDATION_ERRORS.research.requiredResearchDescriptionMaxLength),
  date: string(),
  research_start_date: date().when('date', (date, field) => {
    // console.log(date);
    // console.log(new Date(new Date().getTime() - 24 * 60 * 60 * 1000));
    return field
      .required(VALIDATION_ERRORS.research.requiredResearchDate)
      .min(
        new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
        VALIDATION_ERRORS.research.requiredResearchStartDate
      );
  }),
  research_end_date: date().required(
    VALIDATION_ERRORS.research.requiredResearchEndDateUndefined
  ),
  // .when('research_start_date', (date, field) => {
  //   if (date) {
  //     new Date(date).getTime() <
  //       new Date(new Date().toLocaleDateString('en-US')).getTime();
  //     //     return field.min(
  //     //       new Date(date.getTime() + 24 * 60 * 60 * 1000),
  //     //       VALIDATION_ERRORS.research.requiredResearchEndDate
  //     //     );
  //   }
  // }),
  research_type: string().required(VALIDATION_ERRORS.research.requiredResearch),
  research_region: array()
    .of(
      object().shape({
        region_number: string().required(),
        region_name: string().required(),
      })
    )
    .min(1, VALIDATION_ERRORS.research.requiredResearch),
  research_target: string().required(
    VALIDATION_ERRORS.research.requiredResearch
  ),
  research_frequency: string().when('research_type', (date, field) => {
    if (date === 'longitudinal') {
      return field
        .required(VALIDATION_ERRORS.research.requiredResearch)
        .test(
          'Special symbols check',
          VALIDATION_ERRORS.incorrect,
          (value: string) =>
            typeof value !== 'undefined' &&
            value.match(/\D*/g)?.join('').length === 0
        )
        .matches(ONLY_INT, VALIDATION_ERRORS.incorrect);
    }
  }),
  research_education: string().when('research_target', (date, field) => {
    if (date !== 'other') {
      return field.required(VALIDATION_ERRORS.research.requiredResearch);
    }
  }),
  research_steps_count: string().when('research_type', (date, field) => {
    if (date === 'longitudinal') {
      return field
        .test(
          'Special symbols check',
          VALIDATION_ERRORS.research.requiredIntegerType,
          (value: string) =>
            typeof value !== 'undefined' &&
            value.match(/\D*/g)?.join('').length === 0
        )
        .test(
          'Is Int',
          VALIDATION_ERRORS.research.requiredInteger,
          (value: string) =>
            typeof value !== 'undefined' && Number.isInteger(+value)
        )
        .test(
          'Is not NaN',
          VALIDATION_ERRORS.research.requiredIntegerType,
          (value: string) =>
            !(typeof value !== 'undefined' && Number.isNaN(value))
        )
        .test(
          'Min and Max',
          VALIDATION_ERRORS.research.requiredStepResearch,
          (value: string) =>
            typeof value !== 'undefined' && +value <= 100 && +value >= 1
        )
        .required(VALIDATION_ERRORS.research.requiredStepResearch);
    }
  }),
});

export const researchPermissionsSchema = object({
  login: string().email(VALIDATION_ERRORS.research.requiredEmailUser),
  joinTeam: string(),
});

export const filterResearchProgress = object({
  organization_inn: string(),
  organization_region_number: string(),
});

export const researchRespondentsSchema = object({
  login: string().email(VALIDATION_ERRORS.research.requiredEmailUser),
});

export const researchEventRole = object({
  user_login: string().email(VALIDATION_ERRORS.research.requiredEmailUser),
});

export const accessTeamEvent = object({
  access_team: string().required(),
});

export const researchSurveySchema = object({
  research_greeting_text: string()
    .required(VALIDATION_ERRORS.survey.greeting)
    .min(3, VALIDATION_ERRORS.survey.greetingMinLength)
    .max(1000, VALIDATION_ERRORS.survey.greetingMaxLength),
  research_gratitude_text: string()
    .required(VALIDATION_ERRORS.survey.gratitude)
    .min(3, VALIDATION_ERRORS.survey.gratitudeMinLength)
    .max(1000, VALIDATION_ERRORS.survey.gratitudeMaxLength),
  research_questionnaires: array(),
  //   .when(['research_methods'], {
  // is: (research_methods) => {
  //   return research_methods.length === 0;
  // },
  // then: array()
  //   .of(number())
  //   .required(VALIDATION_ERRORS.survey.methods)
  //   .min(1, VALIDATION_ERRORS.survey.methods),
  // }),
  research_methods: array().when(['research_questionnaires'], {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    is: (research_questionnaires) => {
      return research_questionnaires.length === 0;
    },
    then: array()
      .of(number())
      .required(VALIDATION_ERRORS.survey.methods)
      .min(1, VALIDATION_ERRORS.survey.methods),
  }),
  // .of(number())
  // .required(VALIDATION_ERRORS.survey.methods)
  // .min(1, VALIDATION_ERRORS.survey.methods),
});

export const researchVersusTemplate = object({
  method_id: string().required(VALIDATION_ERRORS.research.requiredResearch),
});

export const researchSaveTemplate = object({
  quizzes_name: string()
    .required(VALIDATION_ERRORS.research.requiredResearch)
    .min(3, VALIDATION_ERRORS.survey.minLength)
    .max(100, VALIDATION_ERRORS.survey.maxLength),
});

export const researchQuestion = object({
  question_name: string()
    .required(VALIDATION_ERRORS.required)
    .min(10, VALIDATION_ERRORS.questionLength)
    .trim(VALIDATION_ERRORS.regExp),
  question_required: boolean(),
  question_input_type: string().required(VALIDATION_ERRORS.question),
  question_answers: array().of(
    string().required(VALIDATION_ERRORS.required).trim(VALIDATION_ERRORS.regExp)
    // .min(10, VALIDATION_ERRORS.questionLength)
  ),
});
