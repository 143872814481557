import React, { useEffect, useState } from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import classNames from 'classnames/bind';

import { defaultFormikOptions } from '../../../../../models/formik';
import {
  materialFeedbackFiltersInitialValues,
  materialFeedbackFiltersValidationSchema,
} from '../../../../../models/validations/schemas/validations';

//  ui
import { Button } from '../../../../ui/Button';
import { Filters } from '../../../../ui/Filters';
import { InputText } from '../../../../ui/Form/InputText';
import { SelectSearch } from '../../../../ui/Form/SelectSearch';
import { InputDate } from '../../../../ui/Form/InputDate';

import styles from './MaterialsFeedbackFilters.module.scss';
import {
  FilterFeedbackOrganization,
  FilterFeedbackResearchCreator,
} from '../../../../../models/feedback';
import { Opt } from '../../../../../models/components/form';
const cx = classNames.bind(styles);

type MaterialsFeedbackFiltersTypes = {
  setFilters: React.Dispatch<
    React.SetStateAction<typeof materialFeedbackFiltersInitialValues>
  >;
  researchAuthors: FilterFeedbackResearchCreator[];
  organizations: FilterFeedbackOrganization[];
  handleResetPage: () => void;
};

export const MaterialsFeedbackFilters = ({
  setFilters,
  researchAuthors,
  organizations,
  handleResetPage,
}: MaterialsFeedbackFiltersTypes) => {
  const [researchAuthorsOptions, setResearchAuthorsOptions] = useState<Opt[]>(
    []
  );
  const [organizationsOptions, setOrganizationsOptions] = useState<Opt[]>([]);

  const handleFiltersSubmit = (
    values: typeof materialFeedbackFiltersInitialValues
  ) => {
    setFilters(values);
    handleResetPage();
  };

  const formik = useFormik({
    initialValues: materialFeedbackFiltersInitialValues,
    validationSchema: materialFeedbackFiltersValidationSchema,
    onSubmit: handleFiltersSubmit,
    ...defaultFormikOptions,
  });

  useEffect(() => {
    if (researchAuthors.length) {
      const researchAuthorsData = researchAuthors.map((author) => ({
        option: `${author.last_name} ${author.first_name} ${author.middle_name}`,
        value: author.id.toString(),
      }));

      setResearchAuthorsOptions(researchAuthorsData);
    }
  }, [researchAuthors]);

  useEffect(() => {
    if (organizations.length) {
      const organizationsData = organizations.map((organization) => ({
        option: organization.organization_name,
        value: organization.organization_name,
      }));

      setOrganizationsOptions(organizationsData);
    }
  }, [organizations]);

  const handleFiltersReset = () => {
    setFilters(materialFeedbackFiltersInitialValues);
    formik.resetForm();
    handleResetPage();
  };

  // useEffect(() => {
  //   if (formik.values.author) {
  //     formik.setFieldValue('organization_name', '');
  //   }
  // }, [formik.values.author]);
  //
  // useEffect(() => {
  //   if (formik.values.organization_name) {
  //     formik.setFieldValue('author', '');
  //   }
  // }, [formik.values.organization_name]);

  return (
    <div className={styles.filter}>
      <Filters>
        <FormikContext.Provider value={formik}>
          <Form>
            <div className={styles.form}>
              <div className={cx('form__item', 'form__item_search')}>
                <InputText
                  name="search"
                  placeholder="Поиск по названию исследования"
                  fullWidth
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <SelectSearch
                  fullWidth
                  name="author"
                  placeholder="Автор исследования"
                  options={researchAuthorsOptions}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <SelectSearch
                  fullWidth
                  name="organization_name"
                  placeholder="Название организации"
                  options={organizationsOptions}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <InputDate
                  isRange
                  name="dates"
                  fullWidth={true}
                  placeholder="Сроки предоставления"
                  align="right"
                />
              </div>
            </div>
            <div className={styles.buttons}>
              <div onClick={handleFiltersReset}>
                <Button label="Сбросить" withoutBackground={true} />
              </div>
              <div onClick={() => formik.handleSubmit()}>
                <Button label="Применить" gradient={true} />
              </div>
            </div>
          </Form>
        </FormikContext.Provider>
      </Filters>
    </div>
  );
};
