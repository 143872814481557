import { getPercentage } from '../../../../../../utils/numbers';
import { Box } from '../../../../../ui/Box';
import { CheckBox } from '../../../../../ui/Form/CheckBox';
import { Typography } from '../../../../../ui/Typography';
import styles from './ResearchDetailsReportDonutCheckbox.module.scss';
import { DictItem } from '../../../../../../models/basic';
import { getAmountWord } from '../../../../../../utils/components/materialsCard';

type ResearchDetailsReportDonutCheckboxProps = {
  handleToggleAnswer: (answer: any) => void;
  checkedAnswers: DictItem[];
  answer: DictItem;
  color: string;
  activeQuestionAnswersValueSum?: number;
};

export const ResearchDetailsReportDonutCheckbox = ({
  checkedAnswers,
  handleToggleAnswer,
  answer,
  color,
  activeQuestionAnswersValueSum,
}: ResearchDetailsReportDonutCheckboxProps): JSX.Element => {
  const checkedAnswer = checkedAnswers.find((answ) => answ.key === answer.key);

  return (
    <Box>
      <div className={styles.checkLine}>
        <Box mt={3}>
          <CheckBox
            checked={!!checkedAnswer}
            onChange={() => handleToggleAnswer(answer)}
            checkedColor={color}
          />
        </Box>
        <Box ml={10}>
          <Typography size="sm" color="main">
            {answer.key}
          </Typography>
        </Box>
        <Box ml={20}>
          <p className={styles.checkLineText}>
            {`${getPercentage(
              Number(answer.value),
              activeQuestionAnswersValueSum || 0
            )}%`}
          </p>
        </Box>
        <Box ml={8}>
          <p className={styles.checkLineText}>
            {Number(answer.value)}{' '}
            {getAmountWord('ответ', Number(answer.value))}
          </p>
        </Box>
      </div>
    </Box>
  );
};
