export const IconPpt = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7365 2.76196H8.0845C6.0245 2.76196 4.2505 4.43096 4.2505 6.49096V17.34C4.2505 19.516 5.9085 21.115 8.0845 21.115H16.0725C18.1325 21.115 19.8025 19.4 19.8025 17.34V8.03796L14.7365 2.76196Z"
        stroke="#851F82"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4738 2.75024V5.65924C14.4738 7.07924 15.6228 8.23124 17.0428 8.23424C18.3588 8.23724 19.7058 8.23824 19.7968 8.23224"
        stroke="#851F82"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="12" y="11" width="12" height="7" fill="white" />
      <path
        d="M8 11.656C8.176 11.6506 8.33333 11.6453 8.472 11.64C8.616 11.6293 8.76 11.6213 8.904 11.616C9.048 11.6106 9.19733 11.608 9.352 11.608C9.512 11.6026 9.696 11.6 9.904 11.6C10.3467 11.6 10.72 11.6373 11.024 11.712C11.3333 11.7813 11.584 11.8933 11.776 12.048C11.968 12.2026 12.1067 12.4053 12.192 12.656C12.2827 12.9013 12.328 13.2026 12.328 13.56C12.328 14.2853 12.1333 14.8186 11.744 15.16C11.3547 15.5013 10.736 15.672 9.888 15.672C9.78133 15.672 9.68533 15.672 9.6 15.672C9.51467 15.6666 9.41867 15.6586 9.312 15.648V17.24H8V11.656ZM9.312 14.568C9.41867 14.5786 9.512 14.5866 9.592 14.592C9.67733 14.592 9.776 14.592 9.888 14.592C10.2933 14.592 10.576 14.512 10.736 14.352C10.896 14.192 10.976 13.9493 10.976 13.624C10.976 13.464 10.96 13.3253 10.928 13.208C10.896 13.0906 10.8373 12.992 10.752 12.912C10.6667 12.832 10.552 12.7733 10.408 12.736C10.2693 12.6986 10.0933 12.68 9.88 12.68C9.77333 12.68 9.67733 12.6826 9.592 12.688C9.512 12.688 9.41867 12.6933 9.312 12.704V14.568Z"
        fill="#851F82"
      />
      <path
        d="M13.4141 11.656C13.5901 11.6506 13.7474 11.6453 13.8861 11.64C14.0301 11.6293 14.1741 11.6213 14.3181 11.616C14.4621 11.6106 14.6114 11.608 14.7661 11.608C14.9261 11.6026 15.1101 11.6 15.3181 11.6C15.7607 11.6 16.1341 11.6373 16.4381 11.712C16.7474 11.7813 16.9981 11.8933 17.1901 12.048C17.3821 12.2026 17.5207 12.4053 17.6061 12.656C17.6967 12.9013 17.7421 13.2026 17.7421 13.56C17.7421 14.2853 17.5474 14.8186 17.1581 15.16C16.7687 15.5013 16.1501 15.672 15.3021 15.672C15.1954 15.672 15.0994 15.672 15.0141 15.672C14.9287 15.6666 14.8327 15.6586 14.7261 15.648V17.24H13.4141V11.656ZM14.7261 14.568C14.8327 14.5786 14.9261 14.5866 15.0061 14.592C15.0914 14.592 15.1901 14.592 15.3021 14.592C15.7074 14.592 15.9901 14.512 16.1501 14.352C16.3101 14.192 16.3901 13.9493 16.3901 13.624C16.3901 13.464 16.3741 13.3253 16.3421 13.208C16.3101 13.0906 16.2514 12.992 16.1661 12.912C16.0807 12.832 15.9661 12.7733 15.8221 12.736C15.6834 12.6986 15.5074 12.68 15.2941 12.68C15.1874 12.68 15.0914 12.6826 15.0061 12.688C14.9261 12.688 14.8327 12.6933 14.7261 12.704V14.568Z"
        fill="#851F82"
      />
      <path
        d="M19.987 12.768H18.371V11.64H22.907V12.768H21.291V17.24H19.987V12.768Z"
        fill="#851F82"
      />
    </svg>
  );
};
