// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Customize antD radio button */

.ant-radio-inner {
    width: 20px;
    height: 20px;
    border-color: #E1E5EA;
    border-style: solid;
    border-width: 2px;
    top: 2px;

}

.ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
}

.ant-radio-checked .ant-radio-inner {
    border-color: #5BCAB5;
}

.ant-radio-group-outline  .ant-space.ant-space-vertical{
    padding-bottom: 30px;
    display: grid;
    gap: 8px;
    height: 100%;
    align-items: center;
    justify-items: center;
}

.ant-radio-inner::after {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    background: linear-gradient(56.46deg, #A4EEDC -45.58%, #5BCAB5 19.1%, #6BAAE4 100%);
}

.ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.6);
}

.ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
    border-color: #5BCAB5;
}

.ant-radio-input:hover + .ant-radio-inner {
    box-shadow: none;
    border-color: #5BCAB5;
}

.ant-radio-wrapper {
    position: relative;
    color: #000000;
}

.groupQuestions .ant-radio{
    position: absolute;
    left: 30px;
    top: 12px;
    /*transform: translateY(-50%);*/
}

.ant-radio-group-outline .ant-space.ant-space-vertical {
    justify-items: start;
}




`, "",{"version":3,"sources":["webpack://./src/components/ui/Form/RadioButton/index.css"],"names":[],"mappings":"AAAA,gCAAgC;;AAEhC;IACI,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,mBAAmB;IACnB,iBAAiB;IACjB,QAAQ;;AAEZ;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,oBAAoB;IACpB,aAAa;IACb,QAAQ;IACR,YAAY;IACZ,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,mFAAmF;AACvF;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,+BAA+B;AACnC;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":["/* Customize antD radio button */\n\n.ant-radio-inner {\n    width: 20px;\n    height: 20px;\n    border-color: #E1E5EA;\n    border-style: solid;\n    border-width: 2px;\n    top: 2px;\n\n}\n\n.ant-radio-input:focus + .ant-radio-inner {\n    box-shadow: none;\n}\n\n.ant-radio-checked .ant-radio-inner {\n    border-color: #5BCAB5;\n}\n\n.ant-radio-group-outline  .ant-space.ant-space-vertical{\n    padding-bottom: 30px;\n    display: grid;\n    gap: 8px;\n    height: 100%;\n    align-items: center;\n    justify-items: center;\n}\n\n.ant-radio-inner::after {\n    width: 20px;\n    height: 20px;\n    margin-top: -10px;\n    margin-left: -10px;\n    background: linear-gradient(56.46deg, #A4EEDC -45.58%, #5BCAB5 19.1%, #6BAAE4 100%);\n}\n\n.ant-radio-checked .ant-radio-inner::after {\n    transform: scale(0.6);\n}\n\n.ant-radio-input:focus + .ant-radio-inner {\n    box-shadow: none;\n    border-color: #5BCAB5;\n}\n\n.ant-radio-input:hover + .ant-radio-inner {\n    box-shadow: none;\n    border-color: #5BCAB5;\n}\n\n.ant-radio-wrapper {\n    position: relative;\n    color: #000000;\n}\n\n.groupQuestions .ant-radio{\n    position: absolute;\n    left: 30px;\n    top: 12px;\n    /*transform: translateY(-50%);*/\n}\n\n.ant-radio-group-outline .ant-space.ant-space-vertical {\n    justify-items: start;\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
