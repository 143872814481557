import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

//  ui
import styles from './RollMenu.module.scss';
import { ItemMenuType } from '../../../constants/header/constants';
import { ItemMenu } from './ItemMenu/ItemMenu';

const cx = classNames.bind(styles);

type IRollMenu = {
  title: string;
  option?: ItemMenuType[];
  disabled?: boolean | undefined;
  isAuthorized?: boolean;
};

export const RollMenu = (props: IRollMenu) => {
  const { title, option, disabled, isAuthorized } = props;
  const [isSelectActive, setIsSelectActive] = useState<boolean>(false);

  const navigate = useNavigate();

  const onSelectOver = () => {
    if (!disabled) {
      setIsSelectActive(true);
    }
  };
  const onSelectLeave = (): void => {
    setIsSelectActive(false);
  };

  return (
    <div
      className={cx('rollMenu', { rollMenu__active: isSelectActive })}
      onMouseOver={onSelectOver}
      onMouseLeave={onSelectLeave}
    >
      {title}
      {isSelectActive && <div className={cx('rollMenu__border')} />}
      <div className={cx('wrapper')}>
        <div
          className={cx('droplist', {
            droplist_hidden: !isSelectActive,
          })}
        >
          {option?.map((item, index) => {
            return (
              <ItemMenu
                key={index}
                url={item.url}
                title={item.title}
                condition={item.condition}
                submenu={item.submenu}
                disabled={item.disabled}
                isAuthorized={isAuthorized}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
