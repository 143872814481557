export const IconFooterVk = () => {
  return (
    <svg
      width="22"
      height="14"
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8814 12.6011C20.9829 10.9841 18.6684 9.04111 17.7129 8.19561C17.4514 7.96411 17.4234 7.5636 17.6614 7.3076C19.4849 5.34811 20.9434 3.09661 21.4454 1.73611C21.6669 1.13511 21.2084 0.500105 20.5624 0.500105H18.6859C18.0674 0.500105 17.7054 0.722105 17.5329 1.07561C16.0174 4.18111 14.7174 5.52511 13.8074 6.31061C13.2979 6.75061 12.5034 6.38611 12.5034 5.71661C12.5034 4.42661 12.5034 2.75911 12.5034 1.57661C12.5034 1.00311 12.0344 0.539105 11.4559 0.539105L8.02794 0.500105C7.59644 0.500105 7.34994 0.988605 7.60894 1.33111L8.17494 2.14361C8.38794 2.42511 8.50294 2.76761 8.50294 3.11911L8.49994 6.7781C8.49994 7.4146 7.72844 7.7256 7.27044 7.2796C5.72094 5.77061 4.37644 2.68911 3.89244 1.21561C3.75244 0.789105 3.35294 0.501105 2.89994 0.500105L1.05144 0.495605C0.357944 0.495605 -0.149056 1.15311 0.0394439 1.81511C1.72844 7.74361 5.19394 13.3836 11.3699 13.9951C11.9784 14.0551 12.5034 13.5651 12.5034 12.9586V11.0356C12.5034 10.4841 12.9359 10.0101 13.4919 9.9961C13.5114 9.9956 13.5309 9.9956 13.5504 9.9956C15.1839 9.9956 17.0134 12.3731 17.6534 13.4851C17.8374 13.8051 18.1814 14.0001 18.5534 14.0001H21.0399C21.7654 14.0001 22.2309 13.2306 21.8814 12.6011Z"
        fill="#2F8AF2"
      />
    </svg>
  );
};
