import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Form, FormikContext, useFormik } from 'formik';

import {
  surveyPasswordInitialValues,
  surveyPasswordValidationSchema,
} from '../../../../models/validations/schemas/validations';

import { useUserChangeEmailMutation } from '../../../../store/VbudusheeAPI';
import { frontendRoutes } from '../../../../utils/router/routes';

//  ui
import { Button } from '../../../ui/Button';
import { InputText } from '../../../ui/Form/InputText';

import styles from './PasswordForm.module.scss';
import { LinkTo } from '../../../ui/LinkTo';

const cx = classNames.bind(styles);

// export type PasswordForm = {};

export const PasswordForm = () => {
  // const {} = props;
  const [userChangePassword, { isLoading, error, isSuccess }] =
    useUserChangeEmailMutation();
  const [serverError, setServerError] = useState<string | undefined>();

  const navigate = useNavigate();

  const handleSubmit = async (values: typeof surveyPasswordInitialValues) => {
    // eslint-disable-next-line no-console
    console.log(values);
  };
  const formik = useFormik({
    initialValues: surveyPasswordInitialValues,
    validationSchema: surveyPasswordValidationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (isSuccess) {
      navigate(frontendRoutes.user.profile.index);
    }
  }, [isSuccess]);

  // useEffect(() => {
  //   if (error && 'data' in error) {
  //     const serverError = error.data as { message: string };
  //     if (serverError.message === massageEmailReserve) {
  //       setServerError(VALIDATION_ERRORS.email.isEmailReserve);
  //     } else {
  //       setServerError(VALIDATION_ERRORS.server.error);
  //     }
  //   }
  // }, [error]);
  //
  // useEffect(() => {
  //   if (serverError) {
  //     formik.setErrors({ email_confirmation: serverError });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [serverError]);

  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <div className={styles.form}>
          <InputText name="password" placeholder="Пароль" modifiedClassName />
          <div className={styles.nav}>
            <div className={styles.form__submit}>
              <LinkTo
                label="Забыли пароль?"
                link={frontendRoutes.user.restorePass}
              />
              <Button label="Войти" type="submit" gradient={true} />
            </div>
          </div>
        </div>
      </Form>
    </FormikContext.Provider>
  );
};
