import React, { useState } from 'react';
import styles from './TeamInfo.module.scss';
import { IconClose } from '../../../../../ui/Icons/IconClose';

type UserInfoProps = {
  teamName: string;
  teamInfo: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  deleteClicked: Function;
};

export const TeamInfo = (props: UserInfoProps) => {
  const { teamName, teamInfo, deleteClicked } = props;
  const [isExpert, setIsExpert] = useState<boolean>(false);

  const deleteTeam = 'Удалить';

  return (
    <div className={styles.teamInfo}>
      <div className={styles.teamInfo__email}>{teamName}</div>
      <div className={styles.teamInfo__fullName}>{teamInfo}</div>
      <div className={styles.teamInfo__expert}>
        <div
          onClick={() => deleteClicked()}
          className={styles.teamInfo__expert_del}
        >
          {deleteTeam}
        </div>
      </div>
    </div>
  );
};
