import React from 'react';
import styles from './News.module.scss';
import { NewItems } from '../../../../../constants/mainPage/news';

export const News = () => {
  return (
    <section className={styles.news}>
      <div className={styles.news__wrapper}>
        <nav className={styles.navigation}>
          <div className={styles.navigation__title}>Новости</div>
          <div className={styles.navigation__link}>Все новости</div>
        </nav>
        <div className={styles.content}>
          {NewItems.map((news, index) => {
            return (
              <div key={index} className={styles.content__item}>
                <div className={styles.content__item_date}>{news.date}</div>
                <div className={styles.content__item_title}>{news.title}</div>
                <div className={styles.content__item_description}>
                  {news.description}
                </div>
                <div className={styles.content__item_link}>{news.link}</div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
