import { useNavigate } from 'react-router-dom';

//  ui
import { Layout } from '../../../components/Layout';
import { MaterialsFeedback } from '../../../components/pages/materials/MaterialsFeedback';

export const MaterialsFeedbackPage = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <MaterialsFeedback />
    </Layout>
  );
};
