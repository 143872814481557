import { scaleBand, scaleLinear, max } from 'd3';
import classNames from 'classnames/bind';
import styles from './BarChart.module.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
const cx = classNames.bind(styles);

type ChartData = {
  x: string;
  y: string;
};

type HorizontalBarChartProps = {
  width: number;
  height: number;
  margin: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  data: ChartData[] | undefined;
  defaultMax?: number;
};

const COLORS_BY_INDEX = ['GREEN', 'BLUE', 'ORANGE'] as const;

export const BarChart = ({
  height,
  width,
  margin,
  data,
  defaultMax = 100,
}: HorizontalBarChartProps) => {
  const fallBackData = Array.from(Array(defaultMax + 1).keys())
    .slice(1)
    .map((value) => ({ x: String(value), y: '' }));

  const dataToRender = useMemo(() => {
    if (!data || data.length === 0) return fallBackData;
    return data;
  }, [data, fallBackData]);

  const [marksWidth, setMarksWidth] = useState(() => dataToRender.map(() => 0));

  const innerHeight = height - margin.top - margin.bottom;
  const innerWidth = width - margin.left - margin.right;

  const yValue = (d: ChartData) => d.y;
  const xValue = useCallback((d: ChartData) => Number(d.x), []);

  const yScale = scaleBand()
    .domain(dataToRender.map(yValue))
    .range([0, innerHeight])
    .paddingInner(0.2)
    .paddingOuter(0.34);

  const xScale = scaleLinear()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    .domain([0, max(dataToRender, xValue)])
    .range([0, innerWidth]);

  useEffect(() => {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        setMarksWidth(() => dataToRender.map((d) => xScale(xValue(d)) + 2.5));
      });
    });
  }, [dataToRender, xValue, xScale]);

  return (
    <svg width={width} height={height}>
      <g transform={`translate(${margin.left},${margin.top})`}>
        {/* Axis bottom */}
        {xScale.ticks(4).map((tickValue) => (
          <g
            className={styles.tick}
            key={tickValue}
            transform={`translate(${xScale(tickValue)},0)`}
          >
            <line className={styles.line} y2={innerHeight} />
            <text
              className={styles.xAxisLabel}
              style={{ textAnchor: 'middle' }}
              dy=".71em"
              y={innerHeight + 3}
            >
              {tickValue}
            </text>
          </g>
        ))}

        <defs>
          <linearGradient
            id="GREEN"
            x1="188"
            y1="0"
            x2="183.792"
            y2="39.5524"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#EEF181" />
            <stop offset="0.514718" stopColor="#92DD90" />
            <stop offset="1" stopColor="#4DE9CB" />
          </linearGradient>

          <linearGradient
            id="BLUE"
            x1="132"
            y1="10.0621"
            x2="-4.84789e-10"
            y2="10.0621"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E0F1FE" />
            <stop offset="0.496309" stopColor="#D4F8EF" />
            <stop offset="1" stopColor="#C7F7E9" />
          </linearGradient>

          <linearGradient
            id="ORANGE"
            x1="-42.6043"
            y1="16.254"
            x2="79.1943"
            y2="-51.8657"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFEC9E" />
            <stop offset="1" stopColor="#FFBB86" />
          </linearGradient>
        </defs>

        {data?.map((d, i) => (
          <g key={yValue(d)}>
            <rect
              fill={`url(#${COLORS_BY_INDEX[i]})`}
              className={styles.mark}
              key={yValue(d)}
              rx={3}
              x={-2.5}
              y={yScale(yValue(d))}
              width={marksWidth[i]}
              height={yScale.bandwidth()}
            >
              <title>{xValue(d)}</title>
            </rect>
            <text
              className={cx(styles.markLabel, {
                [styles.isReady]: marksWidth[i] > 0,
              })}
              x={marksWidth[i] + 6}
              y={yScale(yValue(d))}
            >
              {xValue(d)}
            </text>
          </g>
        ))}
        <line
          className={styles.line}
          y1={innerHeight}
          y2={innerHeight}
          x1={0}
          x2={width}
        />
      </g>
    </svg>
  );
};
