import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import { defaultFormikOptions } from '../../../../../models/formik';
import {
  GetKladrByRegionResponse,
  RegistrationErrors,
} from '../../../../../models/user/registration';
import { VALIDATION_ERRORS } from '../../../../../models/validations/errors/errors';
import {
  companyRegistrationInitialValues,
  companyRegistrationValidationSchema,
} from '../../../../../models/validations/schemas/validations';
import {
  useGetKladrByRegionMutation,
  useRegistrationCompanyMutation,
} from '../../../../../store/VbudusheeAPI';
import { frontendRoutes } from '../../../../../utils/router/routes';
import {
  OCCUPATION,
  ORGANIZATION_CATEGORY,
} from '../../../../../constants/user';

//  ui
import { Button } from '../../../../ui/Button';
import { CheckBox } from '../../../../ui/Form/CheckBox';
import { InputPassword } from '../../../../ui/Form/InputPassword';
import { InputText } from '../../../../ui/Form/InputText';
import { Select } from '../../../../ui/Form/Select';
import { LinkTo } from '../../../../ui/LinkTo';
import { InputCompanyName } from '../../../../ui/Form/InputCompanyName';
import { Company, REGIONS } from '../../../../../models/daData';
import { InputTin } from '../../../../ui/Form/InputTin';
import { SelectSearch } from '../../../../ui/Form/SelectSearch';

import styles from './RegForm.module.scss';
import debounce from 'lodash.debounce';
const cx = classNames.bind(styles);

export const RegForm = () => {
  const navigate = useNavigate();
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const [isMember, setIsMember] = useState(false);
  const [registration, { error, isSuccess }] = useRegistrationCompanyMutation();
  const [getKladr, { isLoading }] = useGetKladrByRegionMutation();
  const [kladrs, setKladrs] = useState<GetKladrByRegionResponse>([]);
  const [serverError, setServerError] = useState<string | undefined>();
  const [isDataValid, setDataValid] = useState(false);
  const regions = [];
  for (const region in REGIONS) regions.push(region);

  const validHref = useMemo(() => {
    const API_URL = process.env.REACT_APP_API_URL?.slice(0, -3);
    return `${API_URL}files/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%B2_%D0%BE%D1%82%D0%BD%D0%BE%D1%88%D0%B5%D0%BD%D0%B8%D0%B8_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8_%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_1.pdf`;
  }, []);

  const handleSubmit = async (
    values: typeof companyRegistrationInitialValues
  ) => {
    setServerError(undefined);
    if (isLoading) {
      return;
    }

    if (!isDataValid) {
      setServerError(VALIDATION_ERRORS.policy);
    } else {
      const url = process.env.REACT_APP_API_URL;

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ email: formik.values.email }),
      };

      const res = await fetch(`${url}/validate-email`, options);
      const resJson = await res.json();
      if (!res.ok) {
        formik.errors.email = resJson.message;
        return;
      }
      const userData = {
        ...values,
        organization_inn: Number(company?.data.inn) ?? values.organization_inn,
        organization_name: company?.data.name.full ?? values.organization_name,
        member: isMember,
      };
      const result: any = await registration(userData);
      if (result?.error?.status === 500) {
        navigate('/500');
      }
      localStorage.setItem('email', values.email);
      if ('data' in result) {
        localStorage.setItem('token', result.data.access_token);
        setTimeout(() => {
          navigate(frontendRoutes.user.confirmCode);
        }, 0);
      }
    }
  };

  const formik = useFormik({
    initialValues: companyRegistrationInitialValues,
    validationSchema: companyRegistrationValidationSchema,
    onSubmit: handleSubmit,
    ...defaultFormikOptions,
  });

  // const handleRegionChange = async () => {
  //   formik.setFieldValue('organization_name', '');
  //   formik.setFieldValue('locality', '');
  //   formik.setFieldValue('organization_inn', '');
  //   const region = formik.values.region;
  //   const regionKladr =
  //     region.toString().length > 1
  //       ? region.toString()
  //       : `0${region.toString()}`;
  //   const result = await getKladr({ region_number: regionKladr });
  //   if ('data' in result) {
  //     setKladrs(result.data);
  //   }
  // };
  //
  // useEffect(() => {
  //   handleRegionChange();
  // }, [formik.values.region]);

  useEffect(() => {
    if (error && 'data' in error) {
      const serverErrors = error.data as {
        errors: RegistrationErrors;
        message: string;
      };

      if (
        serverErrors.message.search(/The email has already been taken/i) !==
          -1 ||
        serverErrors.message.search(/Email already registred/i) !== -1
      ) {
        formik.setErrors({
          email: VALIDATION_ERRORS.server.emailAlreadyTaken,
        });
      } else if (
        serverErrors.message.search(/Required data are not filled/i) !== -1
      ) {
        setServerError(VALIDATION_ERRORS.server.requiredData);
      } else if (serverErrors.message.search(/Wrong data type/i) !== -1) {
        setServerError(VALIDATION_ERRORS.server.wrongData);
      } else if (
        serverErrors.message.search(
          /organizationTin was already created in database/i
        ) !== -1
      ) {
        setServerError(VALIDATION_ERRORS.server.innExists);
      } else if (
        serverErrors.message.search(/email must be a valid email address/i) !==
        -1
      ) {
        setServerError(VALIDATION_ERRORS.server.invalidEmail);
      } else {
        setServerError(serverErrors.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (!company) {
      formik.setFieldValue('locality', '');
      formik.setFieldValue('region', '');
    }
    formik.setFieldValue('organization_inn', company?.data.inn ?? '');
    formik.setFieldValue(
      'locality',
      company?.data.address.data.city
        ? company?.data.address.data.city
        : company?.data?.address.data.settlement_with_type
        ? company?.data?.address.data.settlement_with_type
        : ''
    );
    formik.setFieldValue(
      'region',
      company?.data.address.data.region ? company?.data.address.data.region : ''
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    setTimeout(() => {
      formik.validateField('organization_name');
      formik.validateField('organization_inn');
      formik.validateField('locality');
      formik.validateField('region');
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.organization_inn,
    formik.values.organization_name,
    formik.values.locality,
    formik.values.region,
  ]);

  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <div className={styles.form}>
          <div className={styles.form__block}>
            <div className={styles.form__header}>Пользователь</div>
            <InputText name="email" placeholder="Email*" />
            <InputPassword name="password" placeholder="Пароль*" />
          </div>

          <div className={styles.form__block}>
            <div className={styles.form__header}>Основная информация</div>
            <InputText
              name="last_name"
              placeholder="Фамилия контактного лица*"
            />
            <Select
              name="professional_category"
              label="Деятельность*"
              options={OCCUPATION}
            />
            <InputText name="first_name" placeholder="Имя контактного лица*" />
            <InputText
              name="position_name"
              placeholder="Название должности контактного лица*"
            />
            <InputText
              name="middle_name"
              placeholder="Отчество контактного лица"
            />
          </div>

          <div className={styles.form__block}>
            <div className={styles.form__header}>Организация</div>
            <Select
              name="organization_category"
              label="Категория организации*"
              options={ORGANIZATION_CATEGORY}
            />
            <InputCompanyName
              name="organization_name"
              placeholder="Название организации*"
              setCompany={setCompany}
              localityKladr={formik.values.locality}
              organization_category={formik.values.organization_category}
            />
            <InputText name="region" placeholder="Регион*" disabled={true} />
            <InputText
              name="locality"
              placeholder="Населенный пункт*"
              disabled={true}
            />
            <InputTin
              name="organization_inn"
              placeholder="ИНН организации*"
              setCompany={setCompany}
              localityKladr={formik.values.locality}
              organization_category={formik.values.organization_category}
              disabled={true}
            />
          </div>

          <div className={styles.form__block}>
            <div className={styles.form__member}>
              <div
                className={styles.form__memberCheck}
                onClick={() => setIsMember(!isMember)}
              >
                <CheckBox checked={isMember} onChange={setIsMember} />
                <span>
                  Являюсь участником «Программы по развитию личностного
                  потенциала (РЛП)»
                </span>
              </div>
              <div
                className={cx('form__memberYear', {
                  form__memberYear_active: isMember,
                })}
              >
                <span>Год присоединения</span>
                <Select
                  short={true}
                  name="member_date"
                  label="Год*"
                  options={[
                    { key: '2018-01-01', value: '2018' },
                    { key: '2019-01-01', value: '2019' },
                    { key: '2020-01-01', value: '2020' },
                    { key: '2021-01-01', value: '2021' },
                    { key: '2022-01-01', value: '2022' },
                  ]}
                />
              </div>
            </div>
          </div>

          {serverError && (
            <div className={styles.form__error}>{serverError}</div>
          )}

          <div className={styles.form__policy_wrapper}>
            <CheckBox
              checked={isDataValid}
              onChange={() => setDataValid(!isDataValid)}
            />

            <div className={styles.form__policy}>
              <div>
                Я даю согласие на обработку персональных данных в соответствии с
                {/*<span*/}
                <a
                  href={validHref}
                  style={{ display: 'inline', width: 'auto' }}
                >
                  &nbsp;Политикой&nbsp;
                </a>
                по обработке персональных данных
              </div>
            </div>
          </div>

          <div className={styles.form__submit}>
            <Button
              label="Зарегистрироваться"
              type="submit"
              disabled={!formik.dirty || !formik.isValid || !isDataValid}
              gradient={true}
            />
          </div>

          <div className={styles.form__divider} />
          <LinkTo
            label="Регистрация  исследователя от своего имени"
            link={frontendRoutes.user.registration}
          />
          <LinkTo
            label="Регистрация в качестве респондента"
            link={frontendRoutes.user.registrationRespondent}
          />
        </div>
      </Form>
    </FormikContext.Provider>
  );
};
