// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextareaType_textareaType__MoFSD {
  display: flex;
  flex-direction: column;
}
.TextareaType_textareaType__title__ynUup {
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
}
.TextareaType_textareaType__item__t8\\+af {
  margin-top: 12px;
}
.TextareaType_textareaType__item_title__bwUkN {
  display: flex;
  margin-left: 10px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/SurveyQuestion/TextareaType/TextareaType.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;AAEI;EACE,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAAN","sourcesContent":[".textareaType {\n  display: flex;\n  flex-direction: column;\n\n  &__title {\n    font-weight: 600;\n    font-size: 12px;\n    line-height: 22px;\n  }\n\n  &__item {\n    margin-top: 12px;\n\n    &_title {\n      display: flex;\n      margin-left: 10px;\n      align-items: center;\n      font-weight: 400;\n      font-size: 14px;\n      line-height: 22px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textareaType": `TextareaType_textareaType__MoFSD`,
	"textareaType__title": `TextareaType_textareaType__title__ynUup`,
	"textareaType__item": `TextareaType_textareaType__item__t8+af`,
	"textareaType__item_title": `TextareaType_textareaType__item_title__bwUkN`
};
export default ___CSS_LOADER_EXPORT___;
