import React, { useEffect, useMemo, useState } from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import styles from './SettingsEventForm.module.scss';
import classNames from 'classnames/bind';
import { TYPE_DOCUMENTS } from '../../../../constants/newEvent/select';
import { TransformTag } from '../../../../models/tags/tag';
import { GetPublicEventResponse } from '../../../../models/pages/events';

//  ui
import { InputText } from '../../../ui/Form/InputText';
import { Textarea } from '../../../ui/Form/Textarea/Textarea';
import { Select } from '../../../ui/Form/Select';
import { InputDate } from '../../../ui/Form/InputDate';
import { Button } from '../../../ui/Button';
import { SelectSearch } from '../../../ui/Form/SelectSearch';
import {
  useEditingSettingsEventMutation,
  useGetEventDocumentsQuery,
  useGetEventQuery,
} from '../../../../store/VbudusheeAPI';
import { defaultFormikOptions } from '../../../../models/formik';
import { object, string } from 'yup';
import { formatDateStr } from '../../../../utils/dates';
import { useToasts } from '../../../../hooks/useToasts';

const cx = classNames.bind(styles);

type NewEventFormProps = {
  event?: GetPublicEventResponse;
  transformedTagsData: TransformTag[] | undefined;
};
export const SettingsEventForm = (props: NewEventFormProps) => {
  const { transformedTagsData, event } = props;
  const { data: eventData } = useGetEventQuery({
    id: `${event?.data.event_id}`,
  });
  const [editing, { isLoading }] = useEditingSettingsEventMutation();
  const {
    data: dataDefault,
    isFetching: isFetchingDefault,
    isError: isErrorDefault,
  } = useGetEventDocumentsQuery({
    id: `${event?.data.event_id}`,
    params: {},
  });
  const { onShowToast } = useToasts();

  const navigate = useNavigate();

  const [serverError, setServerError] = useState<string | undefined>();
  const handleSubmit = async (values: typeof settingsEventInitialValues) => {
    //TODO fixed mock id
    const { date, ...data } = values;
    const result = await editing({
      id: +`${event?.data.event_id}`,
      data,
    });
    onShowToast({
      title: 'Успешно',
      name: 'event_edit_toast',
      type: 'success',
      description: 'Мероприятие успешно сохранено',
      maxCount: 1,
      timer: 5000,
    });
    navigate(`/events/${event?.data.event_id}`);
  };

  const settingsEventInitialValues = {
    event_name: eventData?.data.event_name ?? '',
    event_description: eventData?.data.event_description ?? '',
    event_document_type: eventData?.data.event_document_type ?? '',
    event_start_date: eventData?.data.event_start_date ?? '',
    event_end_date: eventData?.data.event_end_date ?? '',
    date: eventData
      ? `${new Date(eventData?.data.event_start_date)};${new Date(
          eventData?.data.event_end_date
        )}`
      : '',
    event_theme: eventData?.data.event_theme ?? '',
  };

  const settingsEventValidationSchema = object({
    event_name: string()
      .required('Заполните поле')
      .max(100, 'Длина названия должна быть меньше 100 символов')
      .min(2, 'Длина названия должна быть больше 1 символа'),
    event_description: string()
      .required('Заполните поле')
      .min(10, 'Длина описания должна быть больше 10 символов')
      .max(1000, 'Длина описания должна быть меньше 1000 символов'),
    date: string().required('Заполните поле'),
    event_document_type: string().required('Заполните поле'),
    event_start_date: string().required('Заполните поле'),
    event_end_date: string().required('Заполните поле'),
    event_theme: string().required('Заполните поле'),
  });

  const formik = useFormik({
    initialValues: settingsEventInitialValues,
    validationSchema: settingsEventValidationSchema,
    onSubmit: handleSubmit,
    ...defaultFormikOptions,
  });

  const isEditable = !(
    eventData?.data.event_publication_status !== 'new' &&
    eventData?.data.event_publication_status !== 'rework' &&
    eventData?.data.event_publication_status !== 'rejected'
  );

  const touch = useMemo(() => {
    let count = 0;
    if (!event) {
      for (const key in formik.touched) {
        count++;
      }
      return !!count;
    }
    return true;
  }, [formik.touched]);

  function clearData() {
    if (eventData) {
      formik.setFieldValue('event_name', eventData.data.event_name ?? '');
      formik.setFieldValue(
        'event_description',
        eventData.data.event_description ?? ''
      );
      formik.setFieldValue(
        'event_document_type',
        eventData.data.event_document_type ?? ''
      );
      formik.setFieldValue(
        'event_start_date',
        eventData.data.event_start_date ?? ''
      );
      navigate('/events/' + event?.data.event_id);
    }
  }

  useEffect(() => {
    if (eventData) {
      formik.setFieldValue('event_name', eventData.data.event_name ?? '');
      formik.setFieldValue(
        'event_description',
        eventData.data.event_description ?? ''
      );
      formik.setFieldValue(
        'event_document_type',
        eventData.data.event_document_type ?? ''
      );
      formik.setFieldValue(
        'event_start_date',
        eventData.data.event_start_date ?? ''
      );
      formik.setFieldValue(
        'event_end_date',
        eventData.data.event_end_date ?? ''
      );
      formik.setFieldValue('event_theme', eventData.data.event_theme ?? '');
    }
  }, [eventData]);

  const convertToDate = (el: string) => {
    return el.slice(0, 10).split('.').reverse().join('-');
  };

  useEffect(() => {
    if (formik.values.date !== '') {
      const dates: string[] = [];
      const rawDates = formik.values.date.split(';');
      rawDates.map((date) => dates.push(date));

      if (dates[0] && dates[0] !== 'undefined') {
        formik.setFieldValue(
          'event_start_date',
          `${convertToDate(formatDateStr(dates[0]))}`
        );
      } else {
        formik.setFieldValue('event_start_date', '');
      }
      setTimeout(() => formik.setFieldTouched('event_start_date', true));

      if (dates[1] && dates[1] !== 'undefined') {
        formik.setFieldValue(
          'event_end_date',
          `${convertToDate(formatDateStr(dates[1]))}`
        );
      } else {
        formik.setFieldValue('event_end_date', '');
      }
      setTimeout(() => formik.setFieldTouched('event_end_date', true));
      // }
    }
  }, [formik.values.date]);

  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <div className={styles.form}>
          <div className={styles.form__block}>
            <InputText
              name="event_name"
              placeholder="Название мероприятия"
              disabled={!isEditable}
              fullWidth
            />
            <Textarea
              name="event_description"
              placeholder="Описание мероприятия"
              editable={isEditable}
              height={120}
            />
            <div className={styles.form__block_selects}>
              <div className={styles.select}>
                <Select
                  name="event_document_type"
                  label="Типы собираемых документов"
                  disabled={!isEditable || !!dataDefault?.data.length}
                  options={TYPE_DOCUMENTS}
                  fullWidth
                />
              </div>
              <div className={styles.select}>
                <InputDate
                  isRange
                  name="date"
                  fullWidth={true}
                  placeholder="Сроки проведения"
                  disabled={!isEditable}
                  align="right"
                  initialDate={formik.values.event_start_date}
                  initialFinishDate={formik.values.event_end_date}
                />
              </div>
              <div className={styles.select}>
                <SelectSearch
                  fullWidth={true}
                  name="event_theme"
                  placeholder="Тематические теги"
                  disabled={!isEditable}
                  options={transformedTagsData ? transformedTagsData : []}
                />
              </div>
            </div>

            <div className={styles.form__block_divider} />
            <div className={styles.form__blockButton}>
              <Button
                disabled={!isEditable}
                label="Отменить"
                clicked={clearData}
                withoutBackground
              />
              <div className={styles.form__blockButton_submit}>
                <Button
                  disabled={
                    !isEditable || !formik.isValid || !formik.dirty || !touch
                  }
                  label="Сохранить"
                  type="submit"
                  gradient
                />
              </div>
            </div>
          </div>

          {serverError && (
            <div className={styles.form__error}>{serverError}</div>
          )}
        </div>
      </Form>
    </FormikContext.Provider>
  );
};
