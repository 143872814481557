import classNames from 'classnames/bind';
import styles from './AnalysisResultsMenu.module.scss';
import { DocumentAnalyseBlocksData } from '../../../../models/document';
const cx = classNames.bind(styles);

type AnalysisResultsMenuProps = {
  availableParts: DocumentAnalyseBlocksData;
  handleDocActivePart: (part: string) => void;
};

export const AnalysisResultsMenu = ({
  availableParts,
  handleDocActivePart,
}: AnalysisResultsMenuProps) => {
  return (
    <div className={styles.menu}>
      <h4 className={styles.menuTitle}>
        Выберите структурный элемент документа
      </h4>
      <ul className={styles.menuList}>
        {Object.entries(availableParts).map((item, idx) => (
          <li
            className={cx('menuListItem', {
              menuListItemTextInactive: !Object.keys(item[1]).length,
            })}
            onClick={() => handleDocActivePart(item[0])}
            key={idx}
          >
            <span
              className={cx('menuListItemText', {
                menuListItemTextInactive: !Object.keys(item[1]).length,
              })}
            >
              {item[0]}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};
