import { SurveyKeyItem } from '../research';

export enum SurveyQuestionEnum {
  INT_FROM_TO = 'integer_from_to',
  GROUP = 'group',
  SCALE_GROUP = 'scale_group',
  CHOICE = 'choice',
  CHECKBOX = 'checkbox',
  SELECT = 'select',
  INPUT = 'input',
}

export type SurveyQuestionType =
  | 'integer_from_to'
  | 'group'
  | 'scale_group'
  | 'choice'
  | 'checkbox'
  | 'select'
  | 'input';

export type Choice = {
  text: string;
  value: number;
};

export type Question = {
  type: string;
  type_label: string;
  min?: number;
  max?: number;
  apply_operation?: string;
  max_label?: string;
  min_label?: string;
  max_alias?: number;
  min_alias?: number;
  choices: Choice[];
  hide_label?: boolean;
};

export type Survey = {
  text: string;
  field: Question;
  group?: string;
  index?: number;
  continuousNumbering?: boolean;
};

export type IntervalTextMap = {
  // interval: any[];
  text: string;
};

export type Interpretation = {
  type: string;
  key_name: any;
  interval_text_map: IntervalTextMap[];
  text: string;
};

export type Instruction = {
  text: string;
  survey_question_ids: string[];
};

export type MainSurvey = {
  name: string;
  survey: Survey[];
  key?: SurveyKeyItem[];
  interpretation: Interpretation[];
  instruction: Instruction[];
};

export type Method = {
  id: number;
  survey: MainSurvey;
};

export type ResponseMethods = {
  methods: Method[];
};

export type GroupQuestions = {
  group: string | undefined;
  items: Survey[];
};
