import { Form, FormikContext, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VALIDATION_ERRORS } from '../../../../../models/validations/errors/errors';
import {
  restorePassAcceptInitialValues,
  restorePassAcceptValidationSchema,
} from '../../../../../models/validations/schemas/validations';
import { useForgotPassAcceptMutation } from '../../../../../store/VbudusheeAPI';
import { frontendRoutes } from '../../../../../utils/router/routes';
import { Button } from '../../../../ui/Button';
import { InputPassword } from '../../../../ui/Form/InputPassword';
import styles from './RestoreForm.module.scss';

type RestorePasswordAcceptTypes = {
  token: string;
};

export const RestoreForm = ({ token }: RestorePasswordAcceptTypes) => {
  const navigate = useNavigate();
  const [forgot, { isLoading, error }] = useForgotPassAcceptMutation();
  const [serverError, setServerError] = useState<string | undefined>();

  const handleSubmit = async (
    values: typeof restorePassAcceptInitialValues
  ) => {
    if (isLoading) {
      return;
    }

    setServerError(undefined);
    const result = await forgot({ ...values, token });

    if ('data' in result) {
      navigate(frontendRoutes.user.login);
    }
  };

  useEffect(() => {
    if (error && 'data' in error) {
      const serverError = error.data as { error: string };
      if (serverError.error === 'The selected token is invalid.') {
        setServerError(VALIDATION_ERRORS.server.invalidToken);
      } else {
        setServerError(serverError.error);
      }
    }
  }, [error]);

  const formik = useFormik({
    initialValues: restorePassAcceptInitialValues,
    validationSchema: restorePassAcceptValidationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (serverError) {
      formik.setErrors({ password: serverError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverError]);

  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <div className={styles.form}>
          <InputPassword name="password" placeholder="Новый пароль" />
          <InputPassword
            name="password_confirmation"
            placeholder="Подтверждение"
          />
        </div>
        <div className={styles.form__submit}>
          <Button
            label={isLoading ? 'Загрузка...' : 'Подтвердить'}
            type="submit"
            disabled={isLoading}
            gradient={true}
          />
        </div>
      </Form>
    </FormikContext.Provider>
  );
};
