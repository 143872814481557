import { useParams } from 'react-router-dom';

import { useGetTeamQuery } from '../../../../store/VbudusheeAPI';

import { Layout } from '../../../../components/Layout';
import { TeamEdit } from '../../../../components/pages/team/TeamEdit/TeamEdit';
import { Loader } from '../../../../components/ui/Loader';

export const TeamEditPage = () => {
  const { teamId } = useParams();
  const { data, isLoading } = useGetTeamQuery({ id: teamId ?? '' });

  return (
    <>
      <Layout>
        {isLoading ? (
          <Loader.FullPage>
            <Loader />
          </Loader.FullPage>
        ) : (
          <TeamEdit team={data?.data} />
        )}
      </Layout>
    </>
  );
};
