// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocumentEditor_editor__HRlBP {
  display: grid;
  grid-template-columns: 376px 1fr;
  column-gap: 24px;
  min-height: 1000px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/document/DocumentEditor/DocumentEditor.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gCAAA;EACA,gBAAA;EACA,kBAAA;AACF","sourcesContent":[".editor {\n  display: grid;\n  grid-template-columns: 376px 1fr;\n  column-gap: 24px;\n  min-height: 1000px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editor": `DocumentEditor_editor__HRlBP`
};
export default ___CSS_LOADER_EXPORT___;
