import { SelectOption } from '../../models/basic';
import {
  MONTHS,
  MONTHS_GENITIVE,
  VISIBLE_DAYS_COUNT,
  CalendarStartEndPoints,
} from '../../constants/dates';

export const generateMonthsOptions = (): SelectOption<number>[] => {
  const arr = [];

  for (let i = 0; i < MONTHS.length; i++) {
    const option = {
      key: i,
      value: MONTHS[i],
    };
    arr.push(option);
  }
  return arr;
};

export const generateYearsOptions = (): SelectOption<number>[] => {
  const arr = [];

  for (
    let i = CalendarStartEndPoints.BEGIN;
    i < CalendarStartEndPoints.END;
    i++
  ) {
    const option = {
      key: i,
      value: `${i}`,
    };
    arr.push(option);
  }

  return arr;
};

export const getActualYear = (date: Date): SelectOption<number> => {
  const currentYear = new Date(date).getFullYear();
  return {
    key: currentYear,
    value: `${currentYear}`,
  };
};

export const createFirstCalendarRow = (date: Date, month: Date[]) => {
  const monthFirstDay = new Date(date);
  //  set up first day of month to the incoming date object
  monthFirstDay.setDate(1);

  //  Search for closest monday
  if (monthFirstDay.getDay() > 1) {
    /* 
      If first day of month not a monday,
      then get last days of the previous month.
    */
    monthFirstDay.setDate(
      monthFirstDay.getDate() - (monthFirstDay.getDay() - 1)
    );
  } else if (monthFirstDay.getDay() === 0) {
    monthFirstDay.setDate(monthFirstDay.getDate() - 6);
  }

  //  Fill in month with first week's days
  const day = monthFirstDay;
  for (let days = 0; days < 7; days++) {
    const nextDay = new Date(day);
    month.push(nextDay);
    day.setDate(day.getDate() + 1);
  }
};

export const fillCalendarMonthWithRestOfDays = (
  monthDays: Date[],
  daysConstant: number
) => {
  const lastDayOfFirstWeek = new Date(monthDays[monthDays.length - 1]);
  /*
    Now monthDays = 7, because we fill month only with first week.
    Add the rest of the month days to the month.
  */
  for (let days = monthDays.length; days < daysConstant; days++) {
    const day = lastDayOfFirstWeek.setDate(lastDayOfFirstWeek.getDate() + 1);
    const nextDay = new Date(day);
    monthDays.push(nextDay);
  }
};

export const getMonthDays = (date: Date): Date[] => {
  const monthDays: Date[] = [];
  createFirstCalendarRow(date, monthDays);
  fillCalendarMonthWithRestOfDays(monthDays, VISIBLE_DAYS_COUNT);
  return monthDays;
};

export const isOtherMonth = (day: Date, actualDay: Date): boolean => {
  return day.getMonth() !== actualDay.getMonth();
};

export const isOtherDay = (day: Date, minDate: Date): boolean => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const date = new Date(day) - new Date(minDate);
  if (date >= 0) {
    return false;
  }
  return true;
};

export const getActualMonth = (
  arr: SelectOption<number>[],
  date: Date
): SelectOption<number> => {
  const actualMonth = arr.find(
    (monthOption) => monthOption?.key === date.getMonth()
  );
  if (actualMonth) return actualMonth;
  return arr[0];
};

export const isSameDate = (
  firstDate: Date | undefined,
  secondDate: Date
): boolean => {
  if (firstDate) {
    return (
      firstDate.getFullYear() === secondDate.getFullYear() &&
      firstDate.getMonth() === secondDate.getMonth() &&
      firstDate.getDate() === secondDate.getDate()
    );
  }
  return false;
};

export const isSameCalendarDate = (
  currentCalendarDate: Date,
  date: Date
): boolean => {
  if (
    currentCalendarDate.getFullYear() === date.getFullYear() &&
    currentCalendarDate.getMonth() === date.getMonth()
  ) {
    return true;
  }
  return false;
};

export const isInRange = (
  startDate: Date | undefined,
  finishDate: Date | undefined,
  date: Date | undefined
): boolean => {
  if (startDate && finishDate && date) {
    return date >= startDate && date <= finishDate;
  }
  return false;
};

export const parseDateIntoInputStr = (date: Date): string => {
  const today = new Date();
  const isToday = isSameDate(today, date ?? today);
  const todayTitle = isToday ? 'Сегодня,' : '';
  const calendarDay = date?.getDate();
  const calendarMonth = date && MONTHS_GENITIVE[date?.getMonth()];
  const calendarYear = date?.getFullYear();
  const actualDateStartValue = date
    ? `${todayTitle} ${calendarDay} ${calendarMonth} ${calendarYear}`
    : '';
  return actualDateStartValue;
};
