import React from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import classNames from 'classnames/bind';

import { defaultFormikOptions } from '../../../../../models/formik';
import {
  materialResearchFiltersInitialValues,
  materialResearchFiltersValidationSchema,
} from '../../../../../models/validations/schemas/validations';
import { OCCUPATION } from '../../../../../constants/user';

//  ui
import { Button } from '../../../../ui/Button';
import { Filters } from '../../../../ui/Filters';
import { InputText } from '../../../../ui/Form/InputText';
import { Select } from '../../../../ui/Form/Select';
import { InputDate } from '../../../../ui/Form/InputDate';

import styles from './MaterialsResearchFilters.module.scss';
import { GetDictionaryResponse, Region } from '../../../../../models/research';
import { toKeyAndValue } from '../../../../../utils/array';
import { SelectSearchRegions } from '../../../../ui/Form/SelectSearchRegions';
const cx = classNames.bind(styles);

type MaterialsResearchFiltersTypes = {
  dictionary?: GetDictionaryResponse;
  regions?: Region[];
  setFilters: React.Dispatch<
    React.SetStateAction<typeof materialResearchFiltersInitialValues>
  >;
  handleResetPage: () => void;
};

export const MaterialsResearchFilters = ({
  dictionary,
  regions,
  setFilters,
  handleResetPage,
}: MaterialsResearchFiltersTypes) => {
  const handleFiltersSubmit = (
    values: typeof materialResearchFiltersInitialValues
  ) => {
    setFilters(values);
    handleResetPage();
  };

  const formik = useFormik({
    initialValues: materialResearchFiltersInitialValues,
    validationSchema: materialResearchFiltersValidationSchema,
    onSubmit: handleFiltersSubmit,
    ...defaultFormikOptions,
  });

  const handleFiltersReset = () => {
    setFilters(materialResearchFiltersInitialValues);
    formik.resetForm();
    handleResetPage();
  };

  return (
    <div className={styles.filter}>
      <Filters>
        <FormikContext.Provider value={formik}>
          <Form>
            <div className={styles.form}>
              <div className={cx('form__item', 'form__item_search')}>
                <InputText
                  name="search"
                  placeholder="Поиск по названию"
                  fullWidth={true}
                />
              </div>
              <div className={cx('form__item')}>
                <InputDate
                  isRange
                  name="dates"
                  fullWidth={true}
                  placeholder="Сроки проведения"
                />
              </div>
              <div className={cx('form__item')}>
                <Select
                  fullWidth={true}
                  name="target"
                  label="Целевая аудитория респондентов"
                  options={toKeyAndValue(dictionary?.research.research_target)}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <Select
                  fullWidth={true}
                  name="type"
                  label="Тип исследования"
                  options={toKeyAndValue(dictionary?.research.research_type)}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <Select
                  fullWidth={true}
                  name="publication"
                  label="Статус публикации"
                  options={toKeyAndValue(
                    dictionary?.research.research_publication_status
                  )}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <Select
                  fullWidth={true}
                  name="status"
                  label="Статус исследования"
                  options={toKeyAndValue(dictionary?.research.research_status)}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <SelectSearchRegions
                  name="region"
                  placeholder="Регион"
                  options={
                    regions
                      ? regions.map((region) => ({
                          region_name: region.region_name,
                          region_number: region.region_number,
                        }))
                      : []
                  }
                  fullWidth
                />
              </div>
            </div>
            <div className={styles.buttons}>
              <div onClick={handleFiltersReset}>
                <Button label="Сбросить" withoutBackground={true} />
              </div>
              <div onClick={() => formik.handleSubmit()}>
                <Button label="Применить" gradient={true} />
              </div>
            </div>
          </Form>
        </FormikContext.Provider>
      </Filters>
    </div>
  );
};
