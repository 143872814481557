import { ResponseWithPagination } from '../api';
import { Instruction } from '../survey/survey';

export type ResearchEditParts =
  | 'settings'
  | 'permissions'
  | 'respondents'
  | 'survey';

export type Question = {
  title: string;
  questions: {
    id: number;
    data: string;
  }[];
};

export type ResearchCreator = {
  last_name: string;
  middle_name: string;
  first_name: string;
};

export interface ResearchRegion {
  region_number: string;
  region_name: string;
}

export type Research = {
  research_id: number;
  research_name: string;
  research_description: string;
  research_status: string;
  research_publication_status: string;
  research_start_date: string;
  research_end_date: string;
  research_theme: string;
  research_type: string;
  research_target: string;
  research_frequency: number;
  research_creator: ResearchCreator;
  organization_name: string;
  organization_category: number;
  research_region: ResearchRegion[];
  research_step: number;
};

export interface ResearchComparison {
  research_id: number;
  research_name: string;
  research_type: string;
  research_target: string;
  research_education: string;
  research_step: number;
  research_start_date: string;
  research_end_date: string;
  research_region: ResearchRegion[];
}

export type ResearchUpdate = {
  research_id: number;
  research_name: string;
  research_description: string;
  research_frequency: number;
  research_region: string;
  research_login_requirement: boolean;
  research_target: string;
  research_method_feedback: boolean;
  research_interview_open: boolean;
  research_start_date: string;
  research_end_date: string;
  research_notification_frequency: string;
  research_status: string;
};

export type GetResearchesResponse = ResponseWithPagination<{
  data: Research[];
}>;

export type GetResearchesRequest = {
  research_publication_status?: string;
  research_creator?: number;
  research_name?: string;
  research_creation_date?: string;
  research_publication?: string;
  research_theme?: string;
  research_type?: string;
  research_start_date?: string;
  research_end_date?: string;
  research_status?: string;
  research_target?: string;
  research_region?: string[];
};

export interface ResearchStatsSurveyStatisticsAggr {
  name: string;
  data: Record<string, Record<string, number>>;
}

export interface ResearchStatsResult {
  survey_statistics_multiple: Array<unknown[]>;
  survey_statistics_aggr: ResearchStatsSurveyStatisticsAggr[];
}

export interface ResearchStatsResponse {
  result: ResearchStatsResult;
}

export interface ResearchFilterResponse {
  organizations_regions: { [key: string]: string };
  organizations_names: { [key: string]: string };
  research_questionnaries: { [key: string]: string };
}

export type Stap = {
  research_start_date: string;
  research_end_date: string;
  research_step: number;
};

export type ResearchData = {
  research_step: number;
  research_steps_count: number;
  excel_download: boolean;
  id: number;
  research_creator: {
    organization_name: string;
    organization_category: string;
  } & ResearchCreator;
  research_name: string;
  research_level: string;
  research_description: string;
  research_type: string;
  research_frequency: number;
  research_region: {
    region_number: string;
    region_name: string;
  }[];
  research_login_requirement: boolean;
  research_target: string;
  research_method_feedback: boolean;
  research_interview_open: boolean;
  research_status: 'open' | 'archive';
  research_publication_status: string;
  research_start_date: string;
  research_end_date: string;
  research_education: string;
  research_notification_frequency: string;
  research_publication_request: boolean;
  respondents: [];
  teams: [];
  members: ResearchMember[];
  research_user_role: string;
  steps: Stap[];
  research_quiz_has_answers: boolean;
};

export type ResearchDetails = {
  research_user_role: string;
  research_steps_count: number;
  data: ResearchData;
};

export type OtherStats = {
  survey_mean_time: number;
  pass_percent: number;
  members: number;
};

type ProgressTotalItem = {
  name: string;
  count: number;
};

export type SurveyStatisticsAggrData = {
  other_stats: OtherStats;
  completion_progress: {
    total: {
      completed: ProgressTotalItem;
      partial: ProgressTotalItem;
      started: ProgressTotalItem;
    };
    detailed: {
      resp_data_cnts: { [x: string]: number };
    };
  };
};

export type SurveyStatisticsAggr = {
  name: string;
  data: SurveyStatisticsAggrData;
};

export type ResearchDetailsProgress = {
  survey_statistics_multiple: any[];
  survey_statistics_aggr: SurveyStatisticsAggr[];
};

export type ResearchDetailsProgressResponse = {
  result: ResearchDetailsProgress;
};

export type QuestionnaireDictItem = {
  id: number;
  survey: {
    question_name: string;
    question_required: boolean;
    question_type: string;
    question_group?: string;
    question_input_type: string;
    question_input_answer: string;
    question_answers: string[];
    question_order: string | null;
    question_slug: string;
  };
};

export type GetQuestionnairesDictResponse = {
  questionnaires: QuestionnaireDictItem[];
  questionnaires_custom: QuestionnaireDictItem[];
};

export type SurveyKeyItem = {
  type: string;
  args: string[];
  return_to: string;
};

export type SurveyTextMapItem = {
  interval: [number | null, number | null];
  text: string;
};

export type SurveyInterpretationItem = {
  type: string;
  key_name: string;
  interval_text_map: SurveyTextMapItem[];
};

export type SurveyDictItem = {
  text: string;
  field: Question;
  group?: string;
};

export type MethodDictItemSurvey = {
  name: string;
  survey: SurveyDictItem[];
  key: SurveyKeyItem[];
  interpretation: SurveyInterpretationItem[];
  instruction: Instruction[];
};

export type MethodDictItem = {
  id: number;
  link: string;
  survey: MethodDictItemSurvey;
};

export type SurveyRequest = {
  research_id: number;
  quizzes_name: string;
  research_method_sociality: boolean;
  research_questionnaires_answer_requirement: boolean;
  research_greeting_text: string;
  research_gratitude_text: string;
  research_method_feedback: boolean;
  research_methods: number[] | null;
  research_questionnaires: number[] | null;
};

export type GetMethodsDictResponse = {
  methods: MethodDictItem[];
  // TODO: replace any with suitable type
  questionnaires_custom: any[];
};

//  DnD
export enum SurveyConstructorType {
  METHOD = 'method',
  QUESTION = 'question',
}

export type MethodItemType = {
  id: number;
  title: string;
  methodDescription: MethodDictItemSurvey;
};

export type QuestionItemType = {
  id: number;
  title: string;
  description: string[];
  questionType: string;
  questionOrder: string | null;
  questionRequired?: boolean;
};

export type EditResearchRequest = {
  research_id: number;
  research_name?: string;
  research_description?: string;
  research_type?: string;
  research_frequency?: string | number;
  research_region?: string[];
  research_login_requirement?: boolean;
  research_target?: string;
  research_method_feedback?: boolean;
  research_education?: string;
  research_interview_open?: boolean;
  research_publication_status?: string[];
  research_start_date?: string;
  research_end_date?: string;
  research_status?: string;
  research_questionnaires_answer_requirement?: boolean;
  research_greeting?: string;
  research_gratitude?: string;
  research_custom_question?: string[];
  research_notification_frequency?: string;
};
export type EditResearchResponse = {
  result: string;
  research: {
    id: number;
    research_creator: {
      organization_name: string;
      organization_category: string;
    } & ResearchCreator;
    research_name: string;
    research_description: string;
    research_type: string;
    research_frequency: string;
    research_region: string[];
    research_login_requirement: string;
    research_target: string;
    research_method_feedback: string;
    research_interview_open: string;
    research_publication_status: string;
    research_start_date: Date;
    research_end_date: string;
    research_notification_frequency: string;
    research_publication_request: string;
    research_step: number;
  };
};

export type ResearchMember = {
  member_id: number;
  member_email: string;
  member_last_name?: string;
  member_middle_name?: string;
  member_first_name?: string;
  researchId?: string;
};

export type ResearchTeam = {
  team_id: string;
  team_name: string;
  team_description: string;
  researchId: string;
};

export type addMemberResearchResponse = {
  result?: string;
  message?: string;
};

export type addMemberResearchRequest = {
  email: string;
  role: string;
};

export type addTeamResearchRequest = {
  team_id: string;
  access: number;
};

export type addResearchRequest = {
  research_name: string;
  research_description: string;
  research_type: string[] | string;
  research_frequency?: string | number;
  research_region: string[];
  research_login_requirement: boolean;
  research_target: string;
  research_method_feedback: boolean;
  research_start_date: string;
  research_education?: string;
  research_end_date: string;
  research_notification_frequency?: string;
  research_steps_count: number;
};

export type AddResearchVersus = {
  research_name?: '';
  research_regions?: [];
  research_start_date?: '';
  research_end_date?: '';
  respondent_education?: '';
  respondent_audience?: '';
  method_id?: '';
  research_phase?: '';
  page?: number;
};

export type getResearchRequest = {
  id: string;
  type: string;
};

export type DictionaryResearch = {
  [key: string]: {
    high_school?: string;
    higher?: string;
    preschool?: string;
    primary_school?: string;
    professional?: string;
    secondary_school?: string;
  };
};

export type DictionaryTeams = {
  role: Record<string, string>;
};

export type DictionaryResResearch = {
  research_education: Record<string, string>;
  research_publication_status: Record<string, string>;
  research_status: Record<string, string>;
  research_target: Record<string, string>;
  research_type: Record<string, string>;
  research_frequency?: Record<string, string>;
  role: Record<string, string>;
};

export type DictionaryEvents = {
  role: Record<string, string>;
  event_type: Record<string, string>;
  event_status: Record<string, string>;
  event_document_type: Record<string, string>;
};

export type DictionaryUser = {
  professional_category: Record<string, string>;
  organization_category: Record<string, string>;
};

export type DictionaryDocuments = {
  document_type: Record<string, string>;
  document_status: Record<string, string>;
};

export type GetDictionaryResponse = {
  teams: DictionaryTeams;
  research: DictionaryResResearch;
  events: DictionaryEvents;
  user: DictionaryUser;
  documents: DictionaryDocuments;
};

export type Region = { region_number: string; region_name: string };

export type ResearchSurvey = {
  id: number;
  research_id: number;
  quizzes_name: string;
  research_method_sociality: boolean;
  research_questionnaires_answer_requirement: number[];
  research_methods_answer_requirement: number[];
  research_greeting_text: string;
  research_gratitude: string;
  research_method_feedback: boolean;
  research_methods: MethodDictItem[];
  research_questionnaires: QuestionnaireDictItem[];
};

export type GetQuestionnairesResponse = {
  id: number;
  research_id: number;
  quiz_answer_id: string;
  quizzes_name: string;
  research_method_sociality: boolean;
  research_questionnaires_answer_requirement: number[];
  research_methods_answer_requirement: number[];
  research_greeting_text: string;
  research_gratitude: string;
  research_method_feedback: boolean;
  research_methods: MethodDictItem[];
  research_questionnaires: QuestionnaireDictItem[];
};

export type editResearchSurvey = {
  research_id?: number;
  quizzes_name?: string;
  research_method_sociality: boolean;
  research_questionnaires_answer_requirement: number[];
  research_methods_answer_requirement: number[];
  research_greeting_text: string;
  research_gratitude_text: string;
  research_method_feedback: boolean;
  research_methods: number[];
  research_questionnaires: number[];
};

export type addCustomQuestionRequest = {
  question_name: string;
  question_required: boolean;
  question_input_type: string;
  question_answers: string[];
};

export type MaterialResearchFilters = {
  search: string;
  dates: string;
  target: string;
  type: string;
  publication: string;
  status: string;
  region: Region[];
  public: string;
  theme: string;
};

export type ResearchFilterRequest = {
  researchId: string;
  body?: {
    organization_region: number;
    organization_name: number;
  };
};

export type ComparisonResearchFilters = {
  research_step: number[];
};
export type ComparisonResearchRequest = {
  researches: number[];
  compare_with_public: boolean;
  method_id: number | string;
  question_id?: number | string;
};

export type ComparisonResearchResponse = {
  data: ResearchComparison[];
  filters: ComparisonResearchFilters;
  selected_research: ResearchComparison | null;
};

export type CommonMethodsResponse = {
  id: number;
  method_name: string;
};

export type CommonQuestionsResponse = {
  id: number;
  question_name: string;
};

export type CommonResponse = {
  methods: CommonMethodsResponse[];
  questions: CommonQuestionsResponse[];
};

export type addResearchResponse = ResearchDetails;
export type requestForPublicationResponse = addMemberResearchResponse;

export type addTeamResearchResponse = addMemberResearchResponse;
export type addCustomQuestionResponse = QuestionnaireDictItem;

export type deleteMemberResearchResponse = addMemberResearchResponse;
export type deleteMemberResearchRequest = addMemberResearchRequest;

export type deleteTeamResearchResponse = addTeamResearchResponse;
export type deleteTeamResearchRequest = addTeamResearchRequest;

export type deleteResearchResponse = addMemberResearchResponse;
export type deleteResearchRequest = { id: string | undefined; force?: boolean };

export type deleteResearchQuestionnairesResponse = addMemberResearchResponse;
export type deleteResearchQuestionnairesRequest = { id: string | undefined };
