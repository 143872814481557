import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconArrowUp } from '../../ui/Icons/Footer/IconArrowUp';
import { IconFooterOdnoklassniki } from '../../ui/Icons/Footer/IconFooterOdnoklassniki';
import { IconFooterVk } from '../../ui/Icons/Footer/IconFooterVk';
import { IconFooterYoutube } from '../../ui/Icons/Footer/IconFooterYoutube';
import { IconLogoFooter } from '../../ui/Icons/Footer/IconLogoFooter';
import styles from './Footer.module.scss';

export const Footer = () => {
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={styles.footer}>
      <div className={styles.footer__info}>
        <div className={styles.footer__logo} onClick={() => navigate('/')}>
          <IconLogoFooter />
        </div>
        <div className={styles.footer__docs}>
          <div className={styles.footer__header}>Документы</div>
          <div className={styles.footer__link}>Условия пользования сайтом</div>
          <div className={styles.footer__link}>
            Политика обработки персональных данных
          </div>
        </div>

        <div className={styles.footer__socials}>
          <div className={styles.footer__header}>Фонд в соцсетях</div>
          <div className={styles.footer__icons}>
            <div className={styles.footer__icon}>
              <IconFooterVk />
            </div>
            <div className={styles.footer__icon}>
              <IconFooterYoutube />
            </div>
            <div className={styles.footer__icon}>
              <IconFooterOdnoklassniki />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer__divider} />
      <div className={styles.footer__downline}>
        <div className={styles.footer__email}>research@vbudushee.ru</div>
        <div className={styles.footer__copyright}>
          © 2022 Благотворительный фонд «Вклад в будущее»
        </div>
        <div className={styles.footer__up}>
          <span>Подняться наверх</span>
          <div className={styles.footer__icon} onClick={scrollToTop}>
            <IconArrowUp />
          </div>
        </div>
      </div>
    </div>
  );
};
