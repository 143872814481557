// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocumentSettingsBlock_wrapper__ZEqiz {
  width: 100%;
  margin-bottom: 32px;
}
.DocumentSettingsBlock_wrapper__ZEqiz .DocumentSettingsBlock_header__iEloC {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 6px;
}
.DocumentSettingsBlock_wrapper__ZEqiz .DocumentSettingsBlock_body__qoR7o {
  overflow-x: hidden;
  overflow-y: auto;
}
.DocumentSettingsBlock_wrapper__ZEqiz .DocumentSettingsBlock_body__wrapper__vipzm {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 376px;
  max-height: 360px;
}
.DocumentSettingsBlock_wrapper__ZEqiz .DocumentSettingsBlock_body__wrapper__vipzm > div {
  margin-right: 10px;
}

.DocumentSettingsBlock_steps__8Ywgl {
  white-space: nowrap;
  background: linear-gradient(56.46deg, #A4EEDC -45.58%, #5BCAB5 19.1%, #6BAAE4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/document/DocumentSettingsBlock/DocumentSettingsBlock.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;EACA,uBAAA;EACA,8BAAA;EACA,kBAAA;AACJ;AAEE;EACE,kBAAA;EACA,gBAAA;AAAJ;AAGI;EACE,aAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AADN;AAGM;EACE,kBAAA;AADR;;AAOA;EACE,mBAAA;EACA,mFAAA;EACA,6BAAA;EACA,oCAAA;AAJF","sourcesContent":[".wrapper {\n  width: 100%;\n  margin-bottom: 32px;\n\n  .header {\n    display: flex;\n    align-items: flex-start;\n    justify-content: space-between;\n    margin-bottom: 6px;\n  }\n\n  .body {\n    overflow-x: hidden;\n    overflow-y: auto;\n\n\n    &__wrapper {\n      display: flex;\n      flex-direction: column;\n      flex-wrap: wrap;\n      max-width: 376px;\n      max-height: 360px;\n\n      &>div {\n        margin-right: 10px;\n      }\n    }\n  }\n}\n\n.steps {\n  white-space: nowrap;\n  background: linear-gradient(56.46deg, #A4EEDC -45.58%, #5BCAB5 19.1%, #6BAAE4 100%);\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `DocumentSettingsBlock_wrapper__ZEqiz`,
	"header": `DocumentSettingsBlock_header__iEloC`,
	"body": `DocumentSettingsBlock_body__qoR7o`,
	"body__wrapper": `DocumentSettingsBlock_body__wrapper__vipzm`,
	"steps": `DocumentSettingsBlock_steps__8Ywgl`
};
export default ___CSS_LOADER_EXPORT___;
