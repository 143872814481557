// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PublicEventComponent_blockButton__9I5E4 {
  display: flex;
}
.PublicEventComponent_blockButton__settingsButton__jChF4 {
  margin-left: 16px;
}

.PublicEventComponent_files__4A-wM {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  row-gap: 4px;
  column-gap: 4px;
  max-height: 86px;
  overflow: hidden;
}
.PublicEventComponent_files__4A-wM.PublicEventComponent_isOpen__PalSB {
  max-height: 200px;
  overflow-y: scroll;
}
.PublicEventComponent_files__4A-wM .PublicEventComponent_file__9KspH {
  width: 33%;
  display: flex;
  align-items: center;
  column-gap: 6px;
  padding: 8px 6px;
  border-radius: 6px;
  transition: 0.2s ease;
  cursor: pointer;
}
.PublicEventComponent_files__4A-wM .PublicEventComponent_file__9KspH:hover {
  background-color: #f4f4f4;
}
.PublicEventComponent_files__4A-wM .PublicEventComponent_file__9KspH.PublicEventComponent_png__jab1\\+, .PublicEventComponent_files__4A-wM .PublicEventComponent_file__9KspH.PublicEventComponent_jpg__xgbu6 {
  color: #16a086;
}
.PublicEventComponent_files__4A-wM .PublicEventComponent_file__9KspH.PublicEventComponent_zip__qlbCs, .PublicEventComponent_files__4A-wM .PublicEventComponent_file__9KspH.PublicEventComponent_rar__H1Ebk {
  color: #ff8a00;
}
.PublicEventComponent_files__4A-wM .PublicEventComponent_file__9KspH.PublicEventComponent_txt__Dsb1W, .PublicEventComponent_files__4A-wM .PublicEventComponent_file__9KspH.PublicEventComponent_doc__T4hOy, .PublicEventComponent_files__4A-wM .PublicEventComponent_file__9KspH.PublicEventComponent_docx__DjEtq {
  color: #185ac5;
}
.PublicEventComponent_files__4A-wM .PublicEventComponent_file__9KspH.PublicEventComponent_xlsx__0cTCO, .PublicEventComponent_files__4A-wM .PublicEventComponent_file__9KspH.PublicEventComponent_xsl__SAJUm {
  color: #12826e;
}
.PublicEventComponent_files__4A-wM .PublicEventComponent_file__9KspH.PublicEventComponent_pdf__wd371 {
  color: #ff3b30;
}
.PublicEventComponent_files__4A-wM .PublicEventComponent_file__9KspH.PublicEventComponent_ppt__f8iXf, .PublicEventComponent_files__4A-wM .PublicEventComponent_file__9KspH.PublicEventComponent_pptx__VqqzF {
  color: #851f82;
}
.PublicEventComponent_files__4A-wM .PublicEventComponent_file__9KspH span {
  overflow: hidden;
  max-width: 90%;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/publicEventsCatalog/PublicEventComponent/PublicEventComponent.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,iBAAA;AACJ;;AAGA;EACE,aAAA;EACA,8BAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAAF;AAEE;EACE,iBAAA;EACA,kBAAA;AAAJ;AAEE;EACE,UAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,qBAAA;EACA,eAAA;AAAJ;AAEI;EACE,yBAAA;AAAN;AAGI;EAEE,cAAA;AAFN;AAKI;EAEE,cAAA;AAJN;AAOI;EAGE,cAAA;AAPN;AAUI;EAEE,cAAA;AATN;AAYI;EACE,cAAA;AAVN;AAYI;EAEE,cAAA;AAXN;AAaI;EACE,gBAAA;EACA,cAAA;AAXN","sourcesContent":[".blockButton {\n  display: flex;\n\n  &__settingsButton {\n    margin-left: 16px;\n  }\n}\n\n.files {\n  display: flex;\n  justify-content: space-between;\n  flex-wrap: wrap;\n  width: 100%;\n  row-gap: 4px;\n  column-gap: 4px;\n  max-height: 86px;\n  overflow: hidden;\n\n  &.isOpen {\n    max-height: 200px;\n    overflow-y: scroll;\n  }\n  .file {\n    width: 33%;\n    display: flex;\n    align-items: center;\n    column-gap: 6px;\n    padding: 8px 6px;\n    border-radius: 6px;\n    transition: 0.2s ease;\n    cursor: pointer;\n\n    &:hover {\n      background-color: #f4f4f4;\n    }\n\n    &.png,\n    &.jpg {\n      color: #16a086;\n    }\n\n    &.zip,\n    &.rar {\n      color: #ff8a00;\n    }\n\n    &.txt,\n    &.doc,\n    &.docx {\n      color: #185ac5;\n    }\n\n    &.xlsx,\n    &.xsl {\n      color: #12826e;\n    }\n\n    &.pdf {\n      color: #ff3b30;\n    }\n    &.ppt,\n    &.pptx {\n      color: #851f82;\n    }\n    span {\n      overflow: hidden;\n      max-width: 90%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blockButton": `PublicEventComponent_blockButton__9I5E4`,
	"blockButton__settingsButton": `PublicEventComponent_blockButton__settingsButton__jChF4`,
	"files": `PublicEventComponent_files__4A-wM`,
	"isOpen": `PublicEventComponent_isOpen__PalSB`,
	"file": `PublicEventComponent_file__9KspH`,
	"png": `PublicEventComponent_png__jab1+`,
	"jpg": `PublicEventComponent_jpg__xgbu6`,
	"zip": `PublicEventComponent_zip__qlbCs`,
	"rar": `PublicEventComponent_rar__H1Ebk`,
	"txt": `PublicEventComponent_txt__Dsb1W`,
	"doc": `PublicEventComponent_doc__T4hOy`,
	"docx": `PublicEventComponent_docx__DjEtq`,
	"xlsx": `PublicEventComponent_xlsx__0cTCO`,
	"xsl": `PublicEventComponent_xsl__SAJUm`,
	"pdf": `PublicEventComponent_pdf__wd371`,
	"ppt": `PublicEventComponent_ppt__f8iXf`,
	"pptx": `PublicEventComponent_pptx__VqqzF`
};
export default ___CSS_LOADER_EXPORT___;
