export const IconTableError = (): JSX.Element => {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="44" height="44" rx="22" fill="#FC9696" />
      <path
        d="M25.2366 12.762H18.4786C16.4196 12.762 14.7496 14.431 14.7496 16.491V27.34C14.7496 29.516 16.5136 31.115 18.6896 31.115H26.5726C28.6326 31.115 30.3016 29.4 30.3016 27.34V18.038L25.2366 12.762Z"
        stroke="white"
      />
      <path
        d="M24.9739 12.7502V15.6592C24.9739 17.0792 26.1229 18.2312 27.5419 18.2342C28.8589 18.2372 30.2059 18.2382 30.2969 18.2322"
        stroke="white"
      />
      <path d="M24.0765 24.6476L20.6105 21.1816" stroke="white" />
      <path d="M20.6106 24.6476L24.0766 21.1816" stroke="white" />
    </svg>
  );
};
