export const MODAL_TEXT_CONSTANT = {
  TITLE: {
    ADD_RESPONDENT_ACCOUNTS: 'Подтверждение добавления',
    DELETE_STUDY: 'Удаление исследования',
    DELETE_STUDY_LONGITUDINAL: 'Удаление лонгитюдного исследования',
    DELETE_STUDY_STEP: 'Удаление этапа исследования',
    PUBLICATION_REQUEST: 'Запрос на публикацию',
    ERROR: 'Ошибка',
  },
  TEXT_CONTENT: {
    ADD_ACCOUNT_TO_THE_RESPONDENTS:
      'Вы уверены что хотите добавить аккаунт ${account} в респонденты?',
    DELETE_STUDY: 'Вы уверены, что хотите удалить иследование?',
    DELETE_STUDY_LONGITUDINAL_ALL_STEP:
      'Вы уверены, что хотите полностью удалить лонгитюдное исследование?',
    DELETE_STUDY_LONGITUDINAL:
      'Вы пытаетесь удалить первый этап исследования. Обратите внимание, что это приведёт к удалению всего лонгитюдного исследования.',
    SUBMIT_A_RESEARCH_PUBLICATION_REQUEST:
      'Вы уверены, что хотите отправить запрос на публикацию исследования? Редактирование иследования будет ограничено',
    ERROR_DEFAULT: 'Что-то пошло не так',
  },
  BUTTON: {
    SAVE: 'Сохранить',
    CONFIRM: 'Подтвердить',
    CANCEL: 'Отмена',
    OK: 'Ок',
  },

  ERROR: {
    default: 'Что-то пошло не так',
    variable: [
      {
        key: 'Not permitted',
        text: 'Недостаточно прав для совершения этого действия',
      },
      {
        key: 'Unauthenticated',
        text: 'Требуется авторизация пользователя',
      },
      {
        key: 'The selected email is invalid.',
        text: 'Добавление незарегистрированной почты',
      },
      {
        key: 'This team is invited',
        text: 'Команда уже приглашена ',
      },
      {
        key: 'This email is invited',
        text: 'Данный пользователь уже добавлен в команду',
      },
      {
        key: 'Error: Email not registered',
        text: 'Email не зарегистрирован',
      },
      {
        key: 'Error: Incorrect password',
        text: 'Некорректные данные для входа',
      },
    ],
  },
};
