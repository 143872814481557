import React from 'react';

import styles from './DocumentPopupAccess.module.scss';
import { Button } from '../../../../ui/Button';
import { Typography } from '../../../../ui/Typography';

type ProfilePopupContentTypes = {
  handleAccess: () => void;
};

export const DocumentPopupAccess = ({
  handleAccess,
}: ProfilePopupContentTypes) => {
  return (
    <div className={styles.popup}>
      <div className={styles.text}>
        <Typography
          color="black2"
          size="m"
          font={'SFPRoDisplay'}
          customLineHeight={22}
        >
          Вы уверены что хотите подтвердить отправку документа?
        </Typography>
      </div>
      <div onClick={handleAccess}>
        <Button label="Подтвердить" gradient />
      </div>
    </div>
  );
};
