import React, { useState } from 'react';

import styles from './TextFilter.module.scss';

type TextFilterProps = {
  placeholder?: string;
  disabled?: boolean;
  setOuterValue?: (value: string) => void;
};

export const TextFilter = ({
  placeholder,
  disabled,
  setOuterValue,
}: TextFilterProps): JSX.Element => {
  const [value, setValue] = useState('');
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setValue(value);
    if (setOuterValue) setOuterValue(value);
  };
  return (
    <input
      type="text"
      placeholder={placeholder}
      disabled={disabled}
      onChange={handleChange}
      value={value}
      className={styles.textFilter}
    />
  );
};
