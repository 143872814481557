type Props = {
  width?: number;
  height?: number;
};

export const IconArrow = ({ width = 10, height = 6 }: Props) => {
  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.48275 0.50356C8.76174 0.22523 9.21334 0.225146 9.49243 0.503374C9.77255 0.782627 9.77269 1.23626 9.49274 1.51568L5.70644 5.29488C5.31607 5.68452 4.68393 5.68452 4.29356 5.29488L0.507261 1.51568C0.227313 1.23626 0.22745 0.782627 0.507567 0.503374C0.786656 0.225146 1.23826 0.22523 1.51725 0.50356L5 3.97813L8.48275 0.50356Z"
        fill="currentColor"
      />
    </svg>
  );
};
