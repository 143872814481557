export const IconTxt = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7365 2.76172H8.08449C6.02449 2.76172 4.25049 4.43072 4.25049 6.49072V17.3397C4.25049 19.5157 5.90849 21.1147 8.08449 21.1147H16.0725C18.1325 21.1147 19.8025 19.3997 19.8025 17.3397V8.03772L14.7365 2.76172Z"
        stroke="#185AC5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4739 2.75V5.659C14.4739 7.079 15.6229 8.231 17.0429 8.234C18.3589 8.237 19.7059 8.238 19.7969 8.232"
        stroke="#185AC5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="12" y="11" width="12" height="7" fill="white" />
      <path
        d="M9.616 12.7276H8V11.5996H12.536V12.7276H10.92V17.1996H9.616V12.7276Z"
        fill="#185AC5"
      />
      <path
        d="M14.6154 14.3276L13.1034 11.5996H14.6234L15.6394 13.4716L16.6954 11.5996H18.1914L16.5834 14.3036L18.2794 17.1996H16.7354L15.5834 15.2076L14.4874 17.1996H12.9834L14.6154 14.3276Z"
        fill="#185AC5"
      />
      <path
        d="M20.3113 12.7276H18.6953V11.5996H23.2313V12.7276H21.6153V17.1996H20.3113V12.7276Z"
        fill="#185AC5"
      />
    </svg>
  );
};
