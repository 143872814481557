import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './ModalDocumentEdit.module.scss';
import classNames from 'classnames/bind';
import { Form, FormikContext, useFormik } from 'formik';
import { useGetDocumentTagsQuery } from '../../../store/VbudusheeAPI';
import { object } from 'yup';

//  ui
import { Textarea } from '../Form/Textarea/Textarea';
import { Button } from '../Button';
import { IconCross } from '../Icons/IconCross';
import { InputSearch } from '../Form/InputSearch';
import { Tag } from '../Tag';
import { DocType, Docs, DocumentResponse } from '../../../models/document';
import { uploadDocument } from '../../../store/documentSlice';
import { useTypedSelector } from '../../../store';
import { DocumentPreview } from '../../pages/document/DocumentEditor/DocumentPreview';

const cx = classNames.bind(styles);

type ModalDocumentEditProps = {
  index: number;
  title: string;
  onClose: () => void;
  onChange?: (e: any) => void;
  data: DocumentResponse | undefined;
  docs: Docs;
};

export const ModalDocumentEdit = (props: ModalDocumentEditProps) => {
  const { title, onClose, data, docs, index } = props;

  const dispatch = useDispatch();

  const { data: tagsData } = useGetDocumentTagsQuery({});

  const currentDocument = useTypedSelector(
    (state) => state.uploadDoc.document.parts
  );

  const [filter, setFilter] = useState<string>('');
  const [isOpenAllTags, setIsOpenAllTags] = useState<boolean>(false);

  const currentElementData = currentDocument?.find(
    (element) => element?.title === title
  );

  const tags = useMemo(() => {
    if (!tagsData) {
      return [] as { id: number; text: string; isActive: boolean }[];
    }

    if (!currentElementData?.tags) {
      return tagsData.map((tag, index) => ({
        id: index,
        text: tag.text,
        isActive: false,
      }));
    }

    return tagsData.map((tag, index) => {
      for (let i = 0; i < currentElementData.tags.length; i++) {
        if (tag.text === currentElementData.tags[i]) {
          return { id: index, text: tag.text, isActive: true };
        }
      }
      return { id: index, text: tag.text, isActive: false };
    });
  }, [currentElementData, tagsData]);

  const handleSubmit = async (values: typeof initialValues) => {
    dispatch(uploadDocument({ ...values, index }));
    onClose();
  };

  const initialValues = {
    title: title,
    text: currentElementData?.text ?? '',
    tags: currentElementData?.tags ?? ([] as DocType['tags']),
  };

  const documentValidationSchema = object({
    // text: string(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: documentValidationSchema,
    onSubmit: handleSubmit,
  });

  const [selectedTags, setSelectedTags] = useState<string[]>(
    currentElementData?.tags ?? []
  );

  const filteredTags = useMemo(() => {
    if (!filter) return tags;
    return tags?.filter((tag) =>
      tag.text.toLowerCase().includes(filter.toLowerCase().trim())
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, filter]);

  const handleClick = () => {
    setIsOpenAllTags(!isOpenAllTags);
  };

  const addTag = (newTag: any) => {
    if (selectedTags.includes(newTag)) {
      return selectedTags;
    } else {
      setSelectedTags([...selectedTags, newTag]);
    }
  };

  const deleteTag = (tag: string) => {
    const arr = [...selectedTags];
    const indexToRemove = arr.indexOf(tag);
    if (indexToRemove > -1) {
      arr.splice(indexToRemove, 1);
    }
    return setSelectedTags(arr);
  };

  useEffect(() => {
    setSelectedTags(selectedTags);
  }, [selectedTags]);

  const removeAllTags = () => {
    if (selectedTags.length > 0) {
      setSelectedTags([]);
    }
  };

  const textField = useRef(null);

  const onScroll = () => {
    if (textField.current) {
      const { scrollTop, scrollHeight, clientHeight } = textField.current;
      if (scrollTop + clientHeight === scrollHeight) {
        const res = document.getElementById('text');
        if (res) {
          res.style.display = 'none';
        }
      }
      if (scrollTop + clientHeight !== scrollHeight) {
        const res = document.getElementById('text');
        if (res) {
          res.style.display = 'flex';
        }
      }
    }
  };

  useEffect(() => {
    formik.setFieldValue(`tags`, selectedTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags]);

  const isSubmitDisabled =
    formik.values.tags.length === 0 || formik.values.text.trim() === '';

  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <div className={styles.modalDocumentEdit}>
          <div className={styles.content}>
            <div className={styles.content__header}>
              <div className={styles.content__header_title}>{title}</div>
              <div
                className={styles.content__header_modalClose}
                onClick={onClose}
              >
                <IconCross />
              </div>
            </div>
            <div className={styles.content__subHeader}>
              Скопируйте соответствующий фрагмент из загруженного текста
            </div>
            <div
              className={[
                styles.content__block,
                styles.content__block__flex,
              ].join(' ')}
            >
              <div>
                <div className={styles.content__block_textTitle}>
                  Загруженный текст
                </div>
                <div>
                  {data?.data.document_path && (
                    <div className="docx">
                      <DocumentPreview
                        ref={textField}
                        width="100%"
                        height="400"
                        id={data.data.document_id}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={styles.content__block}>
                  <div className={styles.content__block_textTitle}>
                    Выбранный текст
                  </div>
                  <Textarea name={`text`} height={400} />
                </div>
              </div>
            </div>
            <div className={styles.content__divider} />
            <div className={styles.content__block}>
              <div className={styles.content__block_textTitle}>
                Тематические теги
              </div>
              <InputSearch
                name={'input'}
                value={filter}
                setFilter={setFilter}
                fullWidth
                placeholder={'Поиск по названию тега'}
              />
            </div>
            <div className={cx('content__block_tags')}>
              <div
                className={cx('tagsList', {
                  tagsList__all: isOpenAllTags,
                })}
                ref={textField}
                onScroll={() => onScroll()}
              >
                {filteredTags?.map((tag) => (
                  <Tag
                    key={tag.id}
                    name={'tags'}
                    deleteTag={deleteTag}
                    onChange={addTag}
                    title={tag.text}
                    active={selectedTags.includes(tag.text)}
                  />
                ))}
              </div>
              <div className={styles.tagsSetting}>
                <div
                  className={styles.tagsSetting__showAllTag}
                  onClick={handleClick}
                >
                  {isOpenAllTags ? 'Скрыть' : 'Все теги'}
                </div>
                <div
                  className={styles.tagsSetting__removeTags}
                  onClick={removeAllTags}
                >
                  {'Очистить'}
                </div>
              </div>
              {isOpenAllTags && (
                <div id={'text'} className={styles.content__block_opacity} />
              )}
            </div>
            <div className={styles.content__divider} />
            <div className={styles.content__buttons}>
              <Button
                type="submit"
                gradient
                label={'Сохранить'}
                disabled={isSubmitDisabled}
              />
              <Button withoutBackground label={'Отменить'} clicked={onClose} />
            </div>
          </div>
        </div>
      </Form>
    </FormikContext.Provider>
  );
};
