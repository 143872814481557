import classNames from 'classnames/bind';

//  ui
import { Typography } from '../../../ui/Typography';

import styles from './StructureMenuItem.module.scss';
import { string } from 'yup';
import { Tag } from '../../../ui/Tag';
import React, { useState } from 'react';

const cx = classNames.bind(styles);

type StructureMenuItemProps = {
  label: string;
  required?: boolean;
  onClick: (e: string) => void;
  isActive?: boolean;
  tags?: Array<string>;
};

export const StructureMenuItem = ({
  label,
  required,
  onClick,
  isActive,
  tags,
}: StructureMenuItemProps): JSX.Element => {
  const [isShowAllTag, setIsShowAllTag] = useState<boolean>(false);

  const handleClick = () => {
    setIsShowAllTag(!isShowAllTag);
  };
  const countTags = tags ? tags?.length : 0;

  return (
    <div
      className={cx('wrapper', {
        wrapper__bottom: isShowAllTag,
      })}
    >
      <div
        className={cx('elem', {
          elem__required: required,
          elem__active: isActive,
        })}
        onClick={() => onClick(label)}
      >
        <Typography size="sm" color={isActive ? 'prGreen' : 'gray'}>
          {label}
          {required && <span className={cx(styles.elem__requiredMark)}>*</span>}
        </Typography>
      </div>
      {/* TODO: delete the commented block if it won't need after redesign */}
      {/* <div className={styles.tags}>
        {isActive && (
          <div
            className={cx('tags__list', {
              tags__list_showAllTag: isShowAllTag,
            })}
          >
            {tags?.map((tag, index) => {
              return (
                <div className={styles.tagWrapper} key={index}>
                  <Tag
                    className={styles.tag}
                    name={'tags'}
                    deleteTag={() => {}}
                    onChange={() => {}}
                    title={tag}
                    active={false}
                    readonly
                  />
                </div>
              );
            })}
          </div>
        )}
        {!isShowAllTag && (
          <div onClick={handleClick}>
            <Typography
              size="s"
              color="prGreen"
              className={cx(styles.tags__showAllTag)}
            >
              {countTags <= 1 ? null : `Ещё ${countTags - 1}`}
            </Typography>
          </div>
        )}
        {isShowAllTag && (
          <div onClick={handleClick} className={cx(styles.tags__closeAllTag)}>
            <Typography size="s" color="prGreen">
              Скрыть
            </Typography>
          </div>
        )}
      </div> */}
    </div>
  );
};
