import React, { useEffect } from 'react';

//  ui
import styles from './VerifyEmail.module.scss';
import verify_email_icon from '../../../assets/images/svg/verifyEmail/verify-email.svg';
import { Typography } from '../../ui/Typography';
import { useGetVerifyEmailQuery } from '../../../store/VbudusheeAPI';
import { useNavigate, useParams } from 'react-router-dom';
import { frontendRoutes } from '../../../utils/router/routes';

export const VerifyEmail = () => {
  const params = useParams();
  const navigate = useNavigate();

  // generating a verification code
  const queryParams = new URLSearchParams(window.location.search);
  const expires = queryParams.get('expires');
  const signature = queryParams.get('signature');
  const verifyCode = `${params.hash}?expires=${expires}&signature=${signature}`;

  const verify = useGetVerifyEmailQuery({
    id: params.id,
    params: verifyCode,
  });

  useEffect(() => {
    setTimeout(() => {
      navigate(frontendRoutes.user.profile.index);
    }, 4000);
  }, [verify]);

  return (
    <div className={styles.verifyEmail}>
      <img
        className={styles.verifyEmail__icon}
        src={verify_email_icon}
        alt="verify_email_icon"
      />
      <Typography size={'m'} color={'main'}>
        Email успешно подтверждён
      </Typography>
    </div>
  );
};
