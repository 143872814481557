import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GetKladrByRegionResponse } from '../../../models/user/registration';
import { SelectSearch } from '../Form/SelectSearch';
import {
  useGetKladrByRegionMutation,
  useGetRegionDataQuery,
} from '../../../store/VbudusheeAPI';
import { useFormikContext } from 'formik';
import { InputCompanyName } from '../Form/InputCompanyName';
import { Company } from '../../../models/daData';
import { InputText } from '../Form/InputText';

type PropsTypes = {
  id: number;
  daDataId?: { [x: string]: number } | undefined;
  questionSlug?: string;
};

export const QuestionItemDadata = ({
  id,
  daDataId,
  questionSlug,
}: PropsTypes) => {
  const [getKladr] = useGetKladrByRegionMutation();
  const [kladrs, setKladrs] = useState<GetKladrByRegionResponse>([]);
  const [company, setCompany] = useState<Company | undefined>(undefined);
  const { values, submitForm, setFieldValue } = useFormikContext();

  // const handleRegionChange = useCallback(async () => {
  //   if (company) {
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     // @ts-ignore
  //     const region = values[`${regionId}`];
  //     if (region) {
  //       const regionKladr =
  //         region.toString().length > 1
  //           ? region.toString()
  //           : `0${region.toString()}`;
  //
  //       const result = await getKladr({ region_number: regionKladr });
  //       if ('data' in result) {
  //         setKladrs(result.data);
  //       }
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [values, company, questionSlug]);

  // useEffect(() => {
  //   handleRegionChange();
  // }, [handleRegionChange]);

  const passObj: any = (str: string, obj: { [x: string]: any }) => {
    if (obj[str]) {
      return obj[str];
    }
    let res;
    for (const key in obj) {
      if (obj[key] !== null && typeof obj[key] === 'object') {
        res = passObj(str, obj[key]);
      }
    }
    return res;
  };

  useEffect(() => {
    if (company) {
      for (const key in daDataId) {
        const res = passObj(key, company.data);
        if (res) {
          setFieldValue(`${daDataId[key]}`, res);
        }
      }
      // if (values && values[`${regionId}`] === '' && regionId !== id) {
      //   setFieldValue(`${id}`, '');
      // }
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // if (values && values[`${regionId}`] === '' && regionId !== id) {
    //   setFieldValue(`${id}`, '');
    // }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  }, [company, daDataId]);
  return (
    <>
      {questionSlug !== 'organization' && (
        <InputText
          name={`${id}`}
          fullWidth
          // placeholder="Регион*"
          // options={
          //   regions
          //     ? regions.map((region) => ({
          //         option: region.region_name,
          //         value: region.region_number,
          //       }))
          //     : []
          // }
          disabled
        />
      )}
      {questionSlug === 'organization' && (
        <InputCompanyName
          name={`${id}`}
          placeholder="Введите название организации*"
          setCompany={setCompany}
          fullWidth
          // localityKladr={formik.values.locality}
          // organization_category={formik.values.organization_category}
        />
      )}
    </>
  );
};
