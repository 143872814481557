import React, { useEffect } from 'react';
import { Layout } from '../../components/Layout';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { frontendRoutes } from '../../utils/router/routes';
import { Loader } from '../../components/ui/Loader';
import { VbudusheeAPI } from '../../store/VbudusheeAPI';
import { useAuthorized } from '../../hooks/useAuthorized';

export const InviteToResearch = (): JSX.Element => {
  const { isAuthorized } = useAuthorized();
  const { token, type } = useParams();
  const navigate = useNavigate();

  const [triggerRequest] =
    VbudusheeAPI.endpoints.getRequestActivateInvite.useLazyQuery();

  const [triggerSurvey] =
    VbudusheeAPI.endpoints.getQuizIdToInvite.useLazyQuery();

  const requestActivateInvite = async (token: string) => {
    triggerRequest({ token })
      .unwrap()
      .then((res) => {
        if (res.result) {
          navigate(`${frontendRoutes.research.details}/${res.research_id}`);
        }
      })
      .catch((error) => {
        if (error.data.message === 'Unauthenticated.') {
          localStorage.setItem('servey', token);
          navigate(`${frontendRoutes.user.login}`);
        } else {
          navigate(`${frontendRoutes.mainPage}`);
        }
      });
  };

  const surveyActivateInvite = async (token: string) => {
    triggerSurvey({ invite: token })
      .unwrap()
      .then((res) => {
        if (res) {
          navigate(`${frontendRoutes.survey.survey}/${res.research_id}`);
        }
      })
      .catch((error) => {
        if (error.data.message === 'This action is unauthorized.') {
          localStorage.setItem('servey', token);
          navigate(`${frontendRoutes.user.login}`);
        } else {
          navigate(`${frontendRoutes.mainPage}`);
        }
      });
  };

  useEffect(() => {
    if (token) {
      if (type === 'respondents') {
        surveyActivateInvite(token);
      }
      if (type === 'permissions') {
        requestActivateInvite(token);
      }
    }
  }, [token, type]);

  return (
    <Layout>
      <Loader.FullPage>
        <Loader />
      </Loader.FullPage>
    </Layout>
  );
};
