export const IconPaginationArrow = () => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.46967 0.469635L0.46967 4.46963C0.176777 4.76253 0.176777 5.2374 0.46967 5.53029L4.46967 9.53029L5.53033 8.46964L2.80909 5.74839L11.9999 5.7501L12.0001 4.2501L2.81223 4.24839L5.53033 1.5303L4.46967 0.469635Z"
        fill="currentColor"
      />
    </svg>
  );
};
