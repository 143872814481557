import styles from './ElementFile.module.scss';
import { IconPdf } from '../Icons/IconPdf';
import { IconDoc } from '../Icons/IconDoc';
import { IconTxt } from '../Icons/IconTxt';
import { IconPng } from '../Icons/IconPng';
import { IconRar } from '../Icons/IconRar';
import { IconPpt } from '../Icons/IconPpt';
import { IconZip } from '../Icons/IconZip';
import { IconJpg } from '../Icons/IconJpg';
import { IconXsl } from '../Icons/IconXsl';
import { IconUncnouDoc } from '../Icons/IconUncnouDoc';

type ElementFileProps = {
  name: string;
  file: File;
  type: string;
  index: number;
  isDelete: (index: number) => void;
};

export const ElementFile = ({
  name,
  file,
  type,
  index,
  isDelete,
}: ElementFileProps): JSX.Element => {
  const fileName = name.slice(0, name.length - 4);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const fileType = name.match(/\.([^.]+)$|$/)[1].toLowerCase();

  const downloadBlobFile = () => {
    const blob = new Blob([file], { type: type });
    const url = URL.createObjectURL(blob);
    const aHTMLElement = document.createElement('a');
    aHTMLElement.href = url;
    aHTMLElement.download = name;

    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 150);
    };

    aHTMLElement.addEventListener('click', clickHandler, false);

    aHTMLElement.click();

    aHTMLElement.removeEventListener('click', clickHandler);
    aHTMLElement.remove();
  };

  const elementColor = (): string => {
    switch (true) {
      case fileType.includes('pdf'):
        return 'rgba(255, 59, 48, 1)';
      case fileType.includes('txt'):
        return 'rgba(24, 90, 197, 1)';
      case fileType.includes('doc'):
        return 'rgba(24, 90, 197, 1)';
      case fileType.includes('xsl'):
        return 'rgba(18, 130, 110, 1)';
      case fileType.includes('xls'):
        return 'rgba(18, 130, 110, 1)';
      case fileType.includes('zip'):
        return 'rgba(255, 138, 0, 1)';
      case fileType.includes('rar'):
        return 'rgba(255, 138, 0, 1)';
      case fileType.includes('jpg'):
        return 'rgba(22, 160, 134, 1)';
      case fileType.includes('png'):
        return 'rgba(22, 160, 134, 1)';
      default:
        return 'rgba(133, 31, 130, 1)';
    }
  };

  const elementIcon = (): JSX.Element => {
    switch (true) {
      case fileType.includes('pdf'):
        return <IconPdf />;
      case fileType.includes('txt'):
        return <IconTxt />;
      case fileType.includes('doc'):
        return <IconDoc />;
      case fileType.includes('xsl'):
        return <IconXsl />;
      case fileType.includes('xls'):
        return <IconXsl />;
      case fileType.includes('zip'):
        return <IconZip />;
      case fileType.includes('rar'):
        return <IconRar />;
      case fileType.includes('jpg'):
        return <IconJpg />;
      case fileType.includes('png'):
        return <IconPng />;
      case fileType.includes('ppt'):
        return <IconPpt />;
      default:
        return <IconUncnouDoc />;
    }
  };

  const del = () => {
    isDelete(index);
  };

  return (
    <div className={styles.container} style={{ color: elementColor() }}>
      {elementIcon()}
      <div className={styles.containerText} onClick={downloadBlobFile}>
        <div style={{ minWidth: '20px' }}>
          <div className={styles.name}>{fileName}</div>
        </div>
        <div>
          <div>{`.${fileType}`}</div>
        </div>
      </div>
      <div className={styles.close} onClick={del} />
    </div>
  );
};
