import React, { useMemo } from 'react';
import styles from './UserInfo.module.scss';
import { IconClose } from '../../../../../ui/Icons/IconClose';

type UserInfoProps = {
  email: string;
  fullName: string;
  userRole: string;
  setEditExpert: (role: string, email: string) => void;
};

export const UserInfo = (props: UserInfoProps) => {
  const { email, fullName, userRole, setEditExpert } = props;

  const addExpert = () => {
    setEditExpert('expert', email);
  };
  const deleteExpert = () => {
    setEditExpert('member', email);
  };

  const removeUserFromEvent = () => {
    setEditExpert('', email);
  };
  const titleExpert = 'Назначить экспертом';
  const deleteUser = 'Удалить';

  const isUserAnExpert = useMemo(() => {
    return userRole === 'expert';
  }, [userRole]);

  return (
    <div className={styles.userInfo}>
      <div className={styles.userInfo__email}>{email}</div>
      <div className={styles.userInfo__fullName}>{fullName}</div>
      <div className={styles.userInfo__expert}>
        {isUserAnExpert ? (
          <div className={styles.expertButton}>
            <div className={styles.expertButton__exp}>Эксперт</div>
            <div className={styles.expertButton__icon} onClick={deleteExpert}>
              <IconClose />
            </div>
          </div>
        ) : (
          <div className={styles.userInfo__expert_add} onClick={addExpert}>
            {titleExpert}
          </div>
        )}
        <div
          className={styles.userInfo__expert_del}
          onClick={removeUserFromEvent}
        >
          {deleteUser}
        </div>
      </div>
    </div>
  );
};
