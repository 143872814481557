import { useParams } from 'react-router-dom';

import { useGetTeamQuery } from '../../../../store/VbudusheeAPI';

//  ui
import { Layout } from '../../../../components/Layout';
import { TeamDocs } from '../../../../components/pages/team/TeamDocs';
import { Loader } from '../../../../components/ui/Loader';

export const TeamDocsPage = () => {
  const { teamId } = useParams();
  const { data, isLoading } = useGetTeamQuery({ id: teamId ?? '' });

  return (
    <>
      <Layout>
        {isLoading ? (
          <Loader.FullPage>
            <Loader />
          </Loader.FullPage>
        ) : (
          <TeamDocs team={data?.data} />
        )}
      </Layout>
    </>
  );
};
