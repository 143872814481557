//  ui
import { Layout } from '../../components/Layout';

import { MainPage } from '../../components/pages/mainPage/MainPage';

export const IndexPage = () => {
  return (
    <Layout>
      <MainPage />
    </Layout>
  );
};
