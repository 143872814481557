import { useState } from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import { object, string } from 'yup';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

//  ui
import { Box } from '../../components/ui/Box';
import { RadioButton } from '../../components/ui/Form/RadioButton/RadioButton';
import { Canban } from './DragTest/Canban';
import { RuMap } from '../../components/ui/RuMap';

export const Ui = () => {
  const [isDataValid, setDataValid] = useState(false);
  const handleChange = () => {};

  const options = [
    { key: '1', value: 'A' },
    { key: '2', value: 'B' },
    { key: '3', value: 'C' },
    { key: '5', value: 'D' },
    { key: '6', value: 'B' },
    { key: '7', value: 'C' },
    { key: '8', value: 'D' },
  ];
  //TODO mock-data
  const question = 'Я считаю себя активным и энергичным человеком';
  const questionCheck =
    'Вам предложены пары противоположных утверждений. Вам необходимо выбрать одно из двух утверждений, которое, по Вашему мнению, больше соответствует действительности, и оценить его, пользуясь указанной шкалой, где 1 - скорее соответствует, 2 - соответствует, 3 - полностью соответствует (либо выберите 0, если оба утверждения, на Ваш взгляд, одинаково верны).';

  const formik = useFormik({
    initialValues: {
      test: '',
      test1: '',
    },
    validationSchema: object({
      test: string(),
      test1: string(),
    }),
    onSubmit: handleChange,
  });

  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <div className="container ">
          <h1>UI components</h1>
          <h2>Universal radio button components</h2>
          <RadioButton
            values={options}
            name={'test'}
            isRow={true}
            withoutVisibleValue
            number={options.length}
          />
          <br />
          <RadioButton values={options} name="test1" number={options.length} />
          <Box mt={30} />
          <RadioButton
            values={[
              { key: '1', value: 'Да' },
              { key: '0', value: 'Нет' },
            ]}
            name="test2"
            isRow={true}
            number={
              [
                { key: '1', value: 'Да' },
                { key: '0', value: 'Нет' },
              ].length
            }
          />
        </div>
        {/* DnD test */}
        <DndProvider backend={HTML5Backend}>
          <Box mt={100} ml={150}>
            <Canban />
          </Box>
        </DndProvider>
        <Box mt={50}>
          <RuMap setRegion={() => {}} />
        </Box>
      </Form>
    </FormikContext.Provider>
  );
};
