import { useNavigate } from 'react-router-dom';
import styles from './LinkTo.module.scss';

type LinkToTypes = {
  label: string;
  link: string;
};

export const LinkTo = ({ label, link }: LinkToTypes) => {
  const navigate = useNavigate();
  return (
    <div className={styles.link} onClick={() => navigate(link)}>
      {label}
    </div>
  );
};
