import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { frontendRoutes } from '../../../utils/router/routes';
import { useGetTagsQuery } from '../../../store/VbudusheeAPI';
import { useAuthorized } from '../../../hooks/useAuthorized';

//  ui
import { Breadcrumbs } from '../../ui/Breadcrumbs';
import { CreateEventForm } from './CreateEventForm';

import styles from './CreateEvent.module.scss';
import { useDispatch } from 'react-redux';
import { TransformTag } from '../../../models/tags/tag';
import { getTags } from '../../../store/tagsSlice';

export const CreateEvent = () => {
  const navigate = useNavigate();

  const { isAuthorized } = useAuthorized();

  const { data: tagsData } = useGetTagsQuery({});
  const location = useLocation();
  const dispatch = useDispatch();
  const isMaterialsPage = location.pathname === frontendRoutes.materials.events;

  const transformedTagsData: TransformTag[] | undefined = tagsData?.map(
    function (obj) {
      const result = {
        option: obj.name,
        value: obj.id.toString(),
      };
      return result;
    }
  );

  useEffect(() => {
    if (tagsData) {
      dispatch(getTags(tagsData));
    }
  }, [isMaterialsPage, dispatch, tagsData]);

  return (
    <div className={styles.createEvent}>
      <div className="local-container">
        <Breadcrumbs
          links={[{ title: 'Создание мероприятия' }]}
          homeLink={frontendRoutes.mainPage}
        />
      </div>
      <div className={styles.createEvent__header}>Создание мероприятия</div>

      <div className="container">
        <CreateEventForm transformedTagsData={transformedTagsData} />
      </div>
    </div>
  );
};
