import styles from './Expanded.module.scss';
import { useMemo, useRef, useState } from 'react';

type ExpandedProps = {
  innerTextTitle?: string;
  innerText?: string;
  items?: {
    itemTitle: string;
    itemData: string;
    fullWidth?: boolean;
    textEllipsis?: boolean;
    scroll?: boolean;
  }[];
};

export const Expanded = ({
  innerTextTitle,
  innerText,
  items,
}: ExpandedProps): JSX.Element => {
  const [ellipsis, setEllipsis] = useState(true);

  // const divRef = useRef(null);

  const onClick = () => {
    // console.log(divRef.current);
    // if (divRef.current) {
    //   divRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    // }
    setEllipsis(!ellipsis);
  };

  return (
    <div
      className={`${styles.inner} ${
        !(innerTextTitle || innerText) ? styles.itemsGap : ''
      }`}
    >
      {innerTextTitle && (
        <span className={styles.textTitle}>{innerTextTitle}</span>
      )}
      {innerText && <span className={styles.inner__text}>{innerText}</span>}
      {items &&
        items.map((item, i) => (
          <div
            className={`${styles.item} ${
              !innerTextTitle && !innerText ? styles.itemNoMargin : ''
            }
            ${item.fullWidth ? styles.fullWidth : ''}`}
            key={`${item?.itemTitle}_{${i}}`}
          >
            <span className={styles.item__header}>{item?.itemTitle}</span>
            <div
              className={`${styles.item__info} ${
                item.itemData.length > 79 && item.textEllipsis && ellipsis
                  ? styles.item__info_ellipsis
                  : ''
              }
              ${
                item.itemData.length > 79 && item.scroll
                  ? styles.item__info_scroll
                  : ''
              }`}
            >
              {item?.itemData}
            </div>
            {item.itemData.length > 79 && item.textEllipsis && (
              <span
                className={styles.button__ellipsis}
                onClick={() => onClick()}
              >
                {ellipsis ? 'Раскрыть' : 'Скрыть'}
              </span>
            )}
          </div>
        ))}
    </div>
  );
};
