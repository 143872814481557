import { DictItem } from '../models/basic';

export const RESEARCH_TYPES = [
  { key: 'longitudinal', value: 'Лонгитюдное' },
  { key: 'recurring', value: 'Повторяющееся' },
];

export const RESEARCH_EDUCATION_LEVEL = [
  { key: 'preschool', value: 'Дошкольное' },
  { key: 'primary_school', value: 'Начальное общее' },
  { key: 'secondary_school', value: 'Основное общее' },
  { key: 'high_school', value: 'Cреднее общее' },
  { key: 'professional', value: 'Среднее профессиональное' },
  { key: 'higher', value: 'Высшее' },
];

export const RESEARCH_TARGET_AUDIENCE = [
  { key: 'officials', value: 'Административный сотрудник' },
  { key: 'teachers', value: 'Педагоги' },
  { key: 'parents', value: 'Родители' },
  { key: 'students', value: 'Учащийся' },
];

export const RESEARCH_FREQUENCY = [
  { key: '1', value: '1' },
  { key: '2', value: '2' },
  { key: '3', value: '3' },
  { key: '4', value: '4' },
  { key: '5', value: '5' },
  { key: '6', value: '6' },
  { key: '7', value: '7' },
  { key: '8', value: '8' },
  { key: '9', value: '9' },
  { key: '10', value: '10' },
];

export const generateResearchFrequencySelectList = (): DictItem[] => {
  const result = [];
  for (let i = 0; i <= 100; i++) {
    const obj = {
      key: `${i}`,
      value: `${i}`,
    };
    result.push(obj);
  }
  return result;
};
