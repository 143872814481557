import { useState, useEffect, useRef } from 'react';

import { useIsVisible } from '../../../../hooks/useIsVisible';

import styles from './HalfDonutChart.module.scss';

type HalfDonutChartProps = {
  percents: number;
  width?: string;
  height?: string;
};

export const HalfDonutChart = ({
  percents = 0,
  width,
  height,
}: HalfDonutChartProps): JSX.Element => {
  const speedRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState(0);
  const isVisible = useIsVisible(speedRef);

  useEffect(() => {
    const validatedValue = percents > 0 && percents <= 100 ? percents : 0;
    // if (validatedValue && isVisible) {
    setValue(validatedValue);
    // }
  }, [isVisible, percents]);

  return (
    <div className={styles.wrapper} style={{ height, width }} ref={speedRef}>
      <div className={styles.speedChart}>
        <div className={styles.gauge}>
          <div className={styles.gBody}>
            <div className={styles.gBack}></div>
            <div
              className={styles.gFill}
              style={{ transform: `rotate(${value * 0.005}turn)` }}
            ></div>
            <div className={styles.gCover}></div>
            <div className={styles.gCoverFill}></div>
          </div>
        </div>
      </div>
    </div>
  );
};
