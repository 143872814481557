// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DropdownBlock_dropBlock__zSfQH {
  width: 100%;
  margin-bottom: 6px;
}
.DropdownBlock_dropBlock__zSfQH .DropdownBlock_header__pPiYs {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0;
}
.DropdownBlock_dropBlock__zSfQH .DropdownBlock_header__pPiYs .DropdownBlock_icon__1SpMT {
  margin-left: 12px;
  cursor: pointer;
  transition: all ease 0.3s;
}
.DropdownBlock_dropBlock__zSfQH .DropdownBlock_header__pPiYs .DropdownBlock_icon_open__zHWWE {
  transform: rotate(180deg);
}
.DropdownBlock_dropBlock__zSfQH .DropdownBlock_body__nftW8 {
  display: none;
  padding: 6px 0;
}
.DropdownBlock_dropBlock__zSfQH .DropdownBlock_body_open__u3ILM {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/DropdownBlock/DropdownBlock.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;AACJ;AACI;EACE,iBAAA;EACA,eAAA;EACA,yBAAA;AACN;AACM;EACE,yBAAA;AACR;AAIE;EACE,aAAA;EACA,cAAA;AAFJ;AAII;EACE,cAAA;AAFN","sourcesContent":[".dropBlock {\n  width: 100%;\n  margin-bottom: 6px;\n\n  .header {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    padding: 10px 0;\n\n    .icon {\n      margin-left: 12px;\n      cursor: pointer;\n      transition: all ease .3s;\n\n      &_open {\n        transform: rotate(180deg);\n      }\n    }\n  }\n\n  .body {\n    display: none;\n    padding: 6px 0;\n\n    &_open {\n      display: block;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropBlock": `DropdownBlock_dropBlock__zSfQH`,
	"header": `DropdownBlock_header__pPiYs`,
	"icon": `DropdownBlock_icon__1SpMT`,
	"icon_open": `DropdownBlock_icon_open__zHWWE`,
	"body": `DropdownBlock_body__nftW8`,
	"body_open": `DropdownBlock_body_open__u3ILM`
};
export default ___CSS_LOADER_EXPORT___;
