export const IconDatePickerArrow = (): JSX.Element => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.46967 9.52959L0.469669 5.52959C0.176776 5.2367 0.176776 4.76183 0.469669 4.46893L4.46967 0.468933L5.53033 1.52959L2.80895 4.25097L11.9997 4.24926L12 5.74926L2.81237 5.75097L5.53033 8.46893L4.46967 9.52959Z"
        fill="#333F48"
      />
    </svg>
  );
};
