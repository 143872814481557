import { useNavigate } from 'react-router-dom';

//  ui
import { Layout } from '../../components/Layout';
import { CreateEvent } from '../../components/pages/createEvent/CreateEvent';
import { Loader } from '../../components/ui/Loader';
import React, { useEffect } from 'react';
import { useTypedSelector } from '../../store';
import { getActualUser } from '../../store/userSlice/selectors';
import { frontendRoutes } from '../../utils/router/routes';
import { useGetCurrentUserQuery } from '../../store/VbudusheeAPI';

export const CreateEventPage = () => {
  const navigate = useNavigate();
  const { data: user, isLoading, isError } = useGetCurrentUserQuery({});

  useEffect(() => {
    if (isError || user?.user.role?.name === 'Респондент') {
      navigate(frontendRoutes.mainPage);
    }
  }, [user, isError]);

  return (
    <>
      {isLoading ? (
        <Loader.FullPage>
          <Loader />
        </Loader.FullPage>
      ) : (
        <Layout>
          <CreateEvent />
        </Layout>
      )}
      ;
    </>
  );
};
