export const IconUncnouDoc = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7365 2.76172H8.08449C6.02449 2.76172 4.25049 4.43072 4.25049 6.49072V17.3397C4.25049 19.5157 5.90849 21.1147 8.08449 21.1147H16.0725C18.1325 21.1147 19.8025 19.3997 19.8025 17.3397V8.03772L14.7365 2.76172Z"
        stroke="#851F82"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4738 2.75V5.659C14.4738 7.079 15.6228 8.231 17.0428 8.234C18.3588 8.237 19.7058 8.238 19.7968 8.232"
        stroke="#851F82"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
