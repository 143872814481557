import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import { Form, FormikContext, useFormik } from 'formik';

import {
  changePasswordInitialValues,
  changePasswordValidationSchema,
} from '../../../../models/validations/schemas/validations';
import { VALIDATION_ERRORS } from '../../../../models/validations/errors/errors';
import { ServerErrors } from '../../../../constants/errors';
import { useUserValidatePasswordMutation } from '../../../../store/VbudusheeAPI';
import { frontendRoutes } from '../../../../utils/router/routes';

//  ui
import { Button } from '../../../ui/Button';
import { InputPassword } from '../../../ui/Form/InputPassword';

import styles from './ChangeEmailForm.module.scss';
const cx = classNames.bind(styles);

type ChangePasswordFormProps = {
  setIsChangeEmail: (b: boolean) => void;
  setPassword: (password: string) => string | void;
};

export const ChangeEmailForm = (props: ChangePasswordFormProps) => {
  const { setIsChangeEmail, setPassword } = props;

  const [userValidatePassword, { isLoading, error, isSuccess }] =
    useUserValidatePasswordMutation();
  const [serverError, setServerError] = useState<string | undefined>();
  const navigate = useNavigate();

  const handleSubmit = async (values: typeof changePasswordInitialValues) => {
    await userValidatePassword({ password: values.password });
    setPassword(values.password);
  };

  const formik = useFormik({
    initialValues: changePasswordInitialValues,
    validationSchema: changePasswordValidationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (isSuccess) {
      setIsChangeEmail(true);
      navigate(frontendRoutes.user.confirmMail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (error && 'data' in error) {
      const serverError = error.data as { message: string };
      if (serverError.message === ServerErrors.INVALID_PASSWORD) {
        setServerError(VALIDATION_ERRORS.password.notRightPassword);
      } else {
        setServerError(VALIDATION_ERRORS.server.error);
      }
    }
  }, [error]);

  useEffect(() => {
    if (serverError) {
      formik.setErrors({ password: serverError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverError]);

  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <div className={styles.form}>
          <InputPassword name="password" placeholder="Текущий пароль" />
          <div className={styles.nav}>
            <div className={styles.form__submit}>
              <Button
                label="Далее"
                type="submit"
                gradient={true}
                size={'large'}
              />
            </div>
          </div>
        </div>
      </Form>
    </FormikContext.Provider>
  );
};
