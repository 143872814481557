import React from 'react';
import { IconCheck } from '../../Icons/Form/IconCheck';
import classNames from 'classnames/bind';
import styles from './CheckBox.module.scss';
import { CheckboxSurvey } from './CheckboxSurvey/CheckboxSurvey';

const cx = classNames.bind(styles);

type CheckBoxTypes = {
  checked?: boolean;
  onChange?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | ((value: boolean) => void);
  type?: 'survey';
  values?: any;
  name?: string;
  themeGradient?: boolean;
  disabled?: boolean;
  required?: boolean;
  checkedColor?: string;
  big?: boolean;
};

export const CheckBox = ({
  checked = false,
  onChange = () => {},
  type,
  values,
  name,
  themeGradient,
  disabled,
  required = true,
  checkedColor,
  big,
}: CheckBoxTypes) => {
  switch (type) {
    case 'survey':
      return (
        <CheckboxSurvey
          values={values}
          name={name ? name : ''}
          disabled={disabled}
          required={required}
        />
      );
      break;
    default:
      return (
        <div
          className={cx('check', {
            check__active: checked,
            check__big: big,
            check__active_gradient: checked && themeGradient,
          })}
          style={
            checked
              ? {
                  backgroundColor: checkedColor,
                  borderColor: checkedColor,
                }
              : {}
          }
          onClick={() => onChange(!checked)}
        >
          {checked && <IconCheck />}
        </div>
      );
  }
  return (
    <div
      className={cx(
        'check',
        {
          check__active: checked,
        },
        { check__active_gradient: checked && themeGradient }
      )}
      onClick={() => onChange(!checked)}
    >
      {checked && <IconCheck />}
    </div>
  );
};
