import React, { useEffect } from 'react';
import { Layout } from '../../components/Layout';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { frontendRoutes } from '../../utils/router/routes';
import { Loader } from '../../components/ui/Loader';
import {
  VbudusheeAPI,
  useActivateEventInviteMutation,
} from '../../store/VbudusheeAPI';
import { useAuthorized } from '../../hooks/useAuthorized';

export const InviteToEvent = (): JSX.Element => {
  const { isAuthorized } = useAuthorized();
  const { invite } = useParams();
  const navigate = useNavigate();
  // eslint-disable-next-line no-console
  console.log(invite);

  const [activateMutation] = useActivateEventInviteMutation();

  const requestActivateInvite = async () => {
    activateMutation({
      invite,
    })
      .unwrap()
      .then((res) => {
        if (res.result) {
          navigate(
            `${frontendRoutes.publicEventsCatalog.events}/${res.event_id}`
          );
        }
      })
      .catch((error) => {
        if (error.data.message === 'Unauthenticated.') {
          navigate(`${frontendRoutes.user.login}`);
        } else {
          navigate(`${frontendRoutes.mainPage}`);
        }
      });
  };

  useEffect(() => {
    if (invite) {
      requestActivateInvite();
    }
  }, [invite]);

  return (
    <Layout>
      <Loader.FullPage>
        <Loader />
      </Loader.FullPage>
    </Layout>
  );
};
