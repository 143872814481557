// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ElementFile_container__KneyJ {
  display: grid;
  align-items: center;
  grid-template-columns: 24px auto 16px;
  gap: 6px;
  padding: 8px 6px;
  border-radius: 6px;
}
.ElementFile_container__KneyJ:hover {
  background-color: rgb(244, 244, 244);
}
.ElementFile_container__KneyJ:hover .ElementFile_close__bmdgu {
  opacity: 0.3;
}
.ElementFile_container__KneyJ:hover .ElementFile_close__bmdgu:hover {
  opacity: 1;
}

.ElementFile_containerText__UpE1v {
  display: grid;
  grid-template-columns: auto 1fr;
  cursor: pointer;
}
.ElementFile_containerText__UpE1v:hover {
  text-decoration: underline;
}

.ElementFile_name__TNb4d {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ElementFile_close__bmdgu {
  cursor: pointer;
  position: relative;
  width: 16px;
  height: 16px;
  opacity: 0;
}

.ElementFile_close__bmdgu:before, .ElementFile_close__bmdgu:after {
  position: absolute;
  left: 7px;
  top: 0;
  content: " ";
  height: 16px;
  width: 2px;
  background-color: #333;
  border-radius: 1.5px;
}

.ElementFile_close__bmdgu:before {
  transform: rotate(45deg);
}

.ElementFile_close__bmdgu:after {
  transform: rotate(-45deg);
}`, "",{"version":3,"sources":["webpack://./src/components/ui/ElementFile/ElementFile.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,qCAAA;EACA,QAAA;EACA,gBAAA;EACA,kBAAA;AACF;AACE;EACE,oCAAA;AACJ;AACI;EACE,YAAA;AACN;AACM;EACE,UAAA;AACR;;AAKA;EACE,aAAA;EACA,+BAAA;EACA,eAAA;AAFF;AAIE;EACE,0BAAA;AAFJ;;AAMA;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;AAHF;;AAMA;EACE,eAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;AAHF;;AAMA;EACE,kBAAA;EACA,SAAA;EACA,MAAA;EACA,YAAA;EACA,YAAA;EACA,UAAA;EACA,sBAAA;EACA,oBAAA;AAHF;;AAKA;EACE,wBAAA;AAFF;;AAIA;EACE,yBAAA;AADF","sourcesContent":[".container {\n  display: grid;\n  align-items: center;\n  grid-template-columns: 24px auto 16px;\n  gap: 6px;\n  padding: 8px 6px;\n  border-radius: 6px;\n\n  &:hover {\n    background-color: rgba(244, 244, 244, 1);\n\n    .close {\n      opacity: 0.3;\n\n      &:hover {\n        opacity: 1;\n      }\n    }\n  }\n}\n\n.containerText {\n  display: grid;\n  grid-template-columns: auto 1fr;\n  cursor: pointer;\n\n  &:hover {\n    text-decoration: underline;\n  }\n}\n\n.name {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.close {\n  cursor: pointer;\n  position: relative;\n  width: 16px;\n  height: 16px;\n  opacity: 0;\n}\n\n.close:before, .close:after {\n  position: absolute;\n  left:7px;\n  top: 0;\n  content: ' ';\n  height: 16px;\n  width: 2px;\n  background-color: #333;\n  border-radius: 1.5px;\n}\n.close:before {\n  transform: rotate(45deg);\n}\n.close:after {\n  transform: rotate(-45deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ElementFile_container__KneyJ`,
	"close": `ElementFile_close__bmdgu`,
	"containerText": `ElementFile_containerText__UpE1v`,
	"name": `ElementFile_name__TNb4d`
};
export default ___CSS_LOADER_EXPORT___;
