import { Form, FormikContext, useFormik } from 'formik';
import { InputText } from '../../../ui/Form/InputText';
import styles from './CodeConfirm.module.scss';
import { Button } from '../../../ui/Button';
import {
  useSendVerifyEmailMutation,
  useVerifyEmailMutation,
} from '../../../../store/VbudusheeAPI';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ModalError } from '../../../ui/ModalError';
import { verifyEmailValidationSchema } from '../../../../models/validations/schemas/validations';
import { InfoModal } from '../../../ui/InfoModal';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const CodeConfirm = () => {
  const initialValues = {
    code: '',
  };
  const navigate = useNavigate();
  const [serverError, setServerError] = useState<string | undefined>();
  const [serverInfo, setServerInfo] = useState<string | undefined>();
  const [seconds, setSeconds] = useState<number>(0);
  const [localInterval, setLocalInterval] = useState<any>();

  const sendCode = async () => {
    if (seconds === 0) {
      setSeconds(60);
      await sendVerifyCodeAgain({});
      setServerInfo('Код отправлен на почту');
    }
  };

  useEffect(() => {
    if (!localInterval && seconds > 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setLocalInterval(
        setInterval(() => {
          setSeconds((sec) => sec - 1);
        }, 1000)
      );
    } else if (seconds === 0) {
      clearInterval(localInterval);
      setLocalInterval(null);
    }
  }, [localInterval, seconds]);

  const handleSubmit = async () => {
    const result = await verify({
      code: formik.values.code,
    });
    if ('data' in result) {
      if (localStorage.getItem('servey')) {
        const sur = localStorage.getItem('servey');
        localStorage.removeItem('servey');
        navigate(`/survey/${sur}`, {
          replace: true,
        });
      } else {
        navigate(`/profile`, {
          replace: true,
        });
      }
    } else {
      // eslint-disable-next-line no-console
      setServerError('Неверный код');
    }
  };

  const [verify, { isLoading, error, isSuccess }] = useVerifyEmailMutation();
  const [sendVerifyCodeAgain] = useSendVerifyEmailMutation();

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: verifyEmailValidationSchema,
  });

  return (
    <>
      <FormikContext.Provider value={formik}>
        <Form>
          <div className={styles.confirmWrapper}>
            <div className={styles.confirmTitle}>
              Подтверждение учётной записи
            </div>
            <div className={styles.preTitle}>
              На адрес электронной почты отправлен код для подтверждения
              регистрации.
            </div>
            <InputText placeholder="Ввод кода" name="code" />
            <Button label="Подтвердить" type="submit" gradient={true} />
            {seconds === 0 ? (
              <div onClick={sendCode} className={cx('resend_code')}>
                Отправить код повторно
              </div>
            ) : (
              <div>
                Следующая отправка через{' '}
                {Math.floor(seconds / 60) < 10
                  ? `0${Math.floor(seconds / 60)}`
                  : Math.floor(seconds / 60)}
                : {seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60}
              </div>
            )}
          </div>
        </Form>
      </FormikContext.Provider>
      <ModalError
        isOpen={!!serverError}
        errorText={serverError}
        onClose={() => {
          setServerError('');
        }}
      />
      <InfoModal
        isOpen={!!serverInfo}
        message={serverInfo || ''}
        onAccept={() => {
          setServerInfo('');
        }}
        titleText={'Успех'}
        onReject={() => {
          setServerInfo('');
        }}
      />
    </>
  );
};
