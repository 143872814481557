import React from 'react';

import styles from './Options.module.scss';
import { OptionItems } from '../../../../../constants/mainPage/options';

export const Options = () => {
  return (
    <section className={styles.options}>
      <div className={styles.blocksOption}>
        {OptionItems.map((item, index) => {
          return (
            <div key={index} className={styles.option}>
              <div className={styles.option__wrapper}>
                <img
                  className={styles.option__wrapper_image}
                  src={item.img}
                  alt={item.img}
                />
              </div>

              <div className={styles.option__title}>
                {item.title.split(' ').slice(0, 1)}
                <div>{item.title.split(' ').slice(1).join(' ')}</div>
              </div>

              <div className={styles.option__description}>
                {item.description}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};
