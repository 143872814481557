import { useNavigate } from 'react-router-dom';
import { TeamWithParticipants } from '../../../../models/pages/teams';
import { frontendRoutes } from '../../../../utils/router/routes';
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { HMenu } from '../../../ui/HMenu';
import { ParticipantCard } from '../../../ui/ParticipantCard/ParticipantCard';
import { TeamDescription } from '../TeamDescription/TeamDescription';

import styles from './TeamParticipants.module.scss';

import defaultTeamLogo from '../../../../assets/images/defaultTeamLogo.svg';

type TeamParticipantsTypes = {
  team?: TeamWithParticipants;
};

export const TeamParticipants = ({ team }: TeamParticipantsTypes) => {
  const participants = team?.team_members ?? [];

  const navigate = useNavigate();

  const menuLinks = [
    {
      label: 'Мероприятия команды',
      onClick: () =>
        navigate(`${frontendRoutes.teams.team.events}/${team?.team_id}`),
      active: false,
      access: true,
    },
    {
      label: 'Исследования команды',
      onClick: () =>
        navigate(`${frontendRoutes.teams.team.research}/${team?.team_id}`),
      active: false,
      access: true,
    },
    {
      label: 'Документы команды',
      onClick: () =>
        navigate(`${frontendRoutes.teams.team.docs}/${team?.team_id}`),
      active: false,
      access: true,
    },
    {
      label: 'Участники команды',
      onClick: () =>
        navigate(`${frontendRoutes.teams.team.participants}/${team?.team_id}`),
      active: true,
      access: true,
    },
  ];

  return (
    <>
      <div className={styles.team}>
        <div className="local-container">
          <Breadcrumbs
            links={[
              { title: 'Каталог команд', link: frontendRoutes.teams.teams },
              { title: team?.team_name ?? '' },
            ]}
            homeLink="/"
          />
        </div>
        <TeamDescription
          team={team}
          team_id={team?.team_id}
          team_name={team?.team_name}
          team_description={team?.team_description}
          team_creator={{
            last_name: team?.team_creator.last_name,
            middle_name: team?.team_creator.middle_name,
            first_name: team?.team_creator.first_name,
            organization_name: team?.team_creator.organization_name,
          }}
          team_user_role={team?.team_user_role}
          team_creation_date={team?.team_creation_date}
          team_members_count={team?.team_members_count}
          team_members={[]}
          team_icon_path={
            team?.team_icon_path !== '' ? team?.team_icon_path : defaultTeamLogo
          }
        />
        <div className={styles.team__navigation}>
          <HMenu links={menuLinks} />
        </div>
        <div className={styles.team_list_wrapper}>
          {participants.length > 0 &&
            participants?.map((el) => {
              return (
                <>
                  <ParticipantCard
                    key={el.email}
                    email={el.email}
                    last_name={el.last_name}
                    first_name={el.first_name}
                    middle_name={el.middle_name}
                  />
                </>
              );
            })}
        </div>
      </div>
    </>
  );
};
