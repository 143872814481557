import React from 'react';
import { Button } from '../../ui/Button';
import { Typography } from '../../ui/Typography';
import styles from './ConfirmEmailPopup.module.scss';
import { useNavigate } from 'react-router-dom';

//ui

type ProfilePopupContentTypes = {
  openPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ConfirmEmailPopup = ({
  setModalOpen,
}: ProfilePopupContentTypes) => {
  const email = localStorage.getItem('email');

  const navigate = useNavigate();

  const handleAccess = () => {
    setModalOpen(false);
    navigate('/registration/code', {
      replace: true,
    });
  };

  return (
    <div className={styles.popup}>
      <div className={styles.text}>
        <Typography
          color="black2"
          size="m"
          font={'SFPRoDisplay'}
          customLineHeight={22}
        >
          Мы отправили письмо для подтверждения регистрации на адрес {email}
        </Typography>
      </div>
      <div onClick={handleAccess}>
        <Button label="Проверить почту" gradient />
      </div>
    </div>
  );
};
