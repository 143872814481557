import React from 'react';
import { Form, FormikContext, useFormik } from 'formik';

import { defaultFormikOptions } from '../../../../models/formik';
import {
  teamsFiltersInitialValues,
  teamsFiltersValidationSchema,
} from '../../../../models/validations/schemas/validations';
import { OCCUPATION } from '../../../../constants/user';

//  ui
import { Button } from '../../../ui/Button';
import { Filters } from '../../../ui/Filters';
import { InputText } from '../../../ui/Form/InputText';
import { Select } from '../../../ui/Form/Select';
import { InputDate } from '../../../ui/Form/InputDate';

import styles from './TeamsFilters.module.scss';

type TeamsFiltersTypes = {
  setFilters: React.Dispatch<
    React.SetStateAction<typeof teamsFiltersInitialValues>
  >;
};

export const TeamsFilters = ({ setFilters }: TeamsFiltersTypes) => {
  const handleFiltersSubmit = (values: typeof teamsFiltersInitialValues) => {
    setFilters(values);
  };

  const formik = useFormik({
    initialValues: teamsFiltersInitialValues,
    validationSchema: teamsFiltersValidationSchema,
    onSubmit: handleFiltersSubmit,
    ...defaultFormikOptions,
  });

  const handleFiltersReset = () => {
    setFilters(teamsFiltersInitialValues);
    formik.resetForm();
  };

  return (
    <div className={styles.filter}>
      <Filters>
        <FormikContext.Provider value={formik}>
          <Form>
            <div className={styles.form}>
              <div className={styles.form__item}>
                <InputText
                  name="organization_name"
                  placeholder="Название команды"
                  fullWidth={true}
                />
              </div>
              <div className={styles.form__item}>
                <Select
                  fullWidth={true}
                  name="team_creator"
                  label="Создатель команды"
                  options={OCCUPATION}
                />
              </div>
              <div className={styles.form__item}>
                <Select
                  fullWidth={true}
                  name="team_name"
                  label="Организация создателя"
                  options={OCCUPATION}
                />
              </div>
              <div className={styles.form__item}>
                <InputDate
                  isRange
                  fullWidth={true}
                  name="dates"
                  placeholder="Дата создания"
                  align="right"
                />
              </div>
            </div>
            <div className={styles.buttons}>
              <div onClick={handleFiltersReset}>
                <Button label="Сбросить" withoutBackground={true} />
              </div>
              <div onClick={() => formik.handleSubmit()}>
                <Button label="Применить" gradient={true} />
              </div>
            </div>
          </Form>
        </FormikContext.Provider>
      </Filters>
    </div>
  );
};
