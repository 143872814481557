import { useState, RefObject, useEffect } from 'react';

export const useIsVisible = (ref: RefObject<HTMLElement> | undefined) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    let observerRefValue: HTMLElement | null = null;
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setState(entry.isIntersecting);
        if (ref?.current) {
          observer.unobserve(ref.current);
          observerRefValue = ref.current;
        }
      }
    });

    ref?.current && observer.observe(ref.current);

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isVisible;
};
