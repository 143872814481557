import { allowedClientMIMETypes, ALLOWED_TYPES } from '../../constants/files';
import { API_URL } from '../api';

export async function uploadWithProgress<Response>(
  endpoint: string,
  formData: FormData,
  onProgress: (percent: number) => void
) {
  const xhr = new XMLHttpRequest();
  xhr.open('POST', `${API_URL}${endpoint}`);
  xhr.setRequestHeader(
    'Authorization',
    `Bearer ${localStorage.getItem('token') ?? ''}`
  );
  xhr.setRequestHeader('Accept', 'application/json');

  xhr.upload.onprogress = (event) => {
    if (event.lengthComputable) {
      const percentage = Math.round((event.loaded / event.total) * 100);
      onProgress(Math.round(percentage));
    }
  };
  return new Promise<Response>((res, rej) => {
    xhr.onload = () => {
      const ok = xhr.status >= 200 && xhr.status <= 299;
      if (ok) {
        const resp = JSON.parse(xhr.responseText);
        res(resp);
      } else {
        rej(xhr.responseText);
      }
    };
    xhr.onabort = (evt) => rej(evt);
    xhr.onerror = (evt) => rej(evt);
    xhr.send(formData);
  });
}

export const fileSize = (size: number | string): string => {
  const UNITS = ['Б', 'Кб', 'Мб', 'Гб'];
  let l = 0;
  let n = typeof size === 'string' ? parseInt(size, 10) : size;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + UNITS[l];
};

export const defineFileMIMEType = (type: string): string => {
  let definedType = '';
  for (const key in allowedClientMIMETypes) {
    if (allowedClientMIMETypes[key] === type) {
      definedType = ALLOWED_TYPES[key];
    }
  }
  return definedType;
};
