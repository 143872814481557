import { Form, FormikContext, useFormik } from 'formik';

import { researchPermissionsSchema } from '../../../../../models/validations/schemas/research';
import { MODAL_TEXT_CONSTANT } from '../../../../../constants/modal';

//  ui
import { Typography } from '../../../../ui/Typography';
import { Box } from '../../../../ui/Box';
import { Button } from '../../../../ui/Button';
import { InputText } from '../../../../ui/Form/InputText';
import { Select } from '../../../../ui/Form/Select';
import { ResearchMemberCard } from './ResearchMemberCard/ResearchMemberCard';
import {
  useAddMemberResearchMutation,
  useAddTeamResearchMutation,
  useDeleteResearchMutation,
  useRequestForPublicationMutation,
  useGetTeamsQuery,
  useGetRequestInviteQuery,
  useTransferUserMutation,
  useDeleteInviteUrlMutation,
  useDeleteResearchLongitudinalMutation,
} from '../../../../../store/VbudusheeAPI';
import {
  ResearchMember,
  ResearchSurvey,
  ResearchTeam,
} from '../../../../../models/research';
import React, { useEffect, useMemo, useState } from 'react';
import { ResearchTeamCard } from './ResearchTeamCard/ResearchTeamCard';
import { frontendRoutes } from '../../../../../utils/router/routes';
import { useNavigate } from 'react-router-dom';
import { ContentModal } from '../../../../ui/ContentModal';
import { ModalError } from '../../../../ui/ModalError';

import linkIcon from '../../../../../assets/images/svg/link.svg';

type ResearchEditPermissionsTypes = {
  researchId: string;
  teams: ResearchTeam[];
  members: ResearchMember[];
  researchSurvey?: { data: ResearchSurvey } | undefined;
  researchType?: { longitudinal: boolean; step: number };
  publicationStatus?: string;
  interviewOpen?: boolean;
  startDate?: string;
  endDate?: string;
  clickedCopy?: () => void;
  isTheAuthor?: boolean;
};

export const ResearchEditPermissions = ({
  researchId,
  teams,
  members,
  researchSurvey,
  publicationStatus,
  interviewOpen,
  startDate,
  endDate,
  clickedCopy,
  isTheAuthor,
  researchType,
}: ResearchEditPermissionsTypes) => {
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL?.slice(0, -3);

  const [researchMembers, setResearchMembers] = useState(members ?? []);
  const [researchTeams, setResearchTeams] = useState(teams ?? []);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isDeleteStep, setIsDeleteStep] = useState<string>('');
  const [isOpenModalTransfer, setIsOpenModalTransfer] =
    useState<boolean>(false);
  const [isOpenModalTransferConfirmation, setIsOpenModalTransferConfirmation] =
    useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('');
  const [modalError, setModalError] = useState<string>('');
  const [addRespondentResearch, { error: isAddRespondentError }] =
    useAddMemberResearchMutation();
  const [TransferUser, { error: isTransferUserError }] =
    useTransferUserMutation();
  const [addTeamResearch, { error: isAddTeamError }] =
    useAddTeamResearchMutation();
  const [deleteResearch, { error: isDeleteResearchError }] =
    useDeleteResearchMutation();

  const [deleteResearchLongitudinal] = useDeleteResearchLongitudinalMutation();

  const [deleteInviteUrl] = useDeleteInviteUrlMutation();

  const { data: invite } = useGetRequestInviteQuery({
    id: researchId,
    type: 'member',
  });
  const [requestForPublication, { error: isResearchPublicationError }] =
    useRequestForPublicationMutation();
  const { data: rawTeamsList } = useGetTeamsQuery({});

  const teamList: any = [];
  rawTeamsList?.data.map((team) => {
    teamList.push({ key: team.team_id, value: team.team_name });
  });

  const formik = useFormik({
    initialValues: {
      login: '',
      joinTeam: '',
    },
    validationSchema: researchPermissionsSchema,
    onSubmit: () => {},
  });

  const formikTransfer = useFormik({
    initialValues: {
      userLogin: '',
    },
    validationSchema: researchPermissionsSchema,
    onSubmit: () => {},
  });

  const addRespondentResearchHandler = async () => {
    const result = await addRespondentResearch({
      research_id: researchId,
      body: {
        email: formik.values.login,
        role: 'member',
      },
    });

    if ('data' in result) {
      formik.setFieldValue('login', '');
    }
  };

  const transferUserResearchHandler = async () => {
    const result = await TransferUser({
      research_id: researchId,
      body: {
        new_owner_email: formikTransfer.values.userLogin,
      },
    });

    if ('data' in result) {
      formik.setFieldValue('login', '');
    }
  };

  const addTeamResearchHandler = async () => {
    const result = await addTeamResearch({
      research_id: researchId,
      body: {
        team_id: `${formik.values.joinTeam}`,
        access: 1,
      },
    });

    if ('data' in result) {
      formik.setFieldValue('joinTeam', '');
    }
  };

  const convertModelText = useMemo(() => {
    if (modalType) {
      if (modalType === 'delete') {
        if (researchType?.longitudinal && researchType?.step > 1) {
          if (isDeleteStep === 'all') {
            return {
              title: MODAL_TEXT_CONSTANT.TITLE.DELETE_STUDY_LONGITUDINAL,
              content:
                MODAL_TEXT_CONSTANT.TEXT_CONTENT
                  .DELETE_STUDY_LONGITUDINAL_ALL_STEP,
            };
          } else if (isDeleteStep === 'step') {
            return {
              title: MODAL_TEXT_CONSTANT.TITLE.DELETE_STUDY_STEP,
              content: `Вы уверены, что хотите удалить ${researchType?.step} этап лонгитюдного исследования?`,
            };
          } else {
            return {
              title: MODAL_TEXT_CONSTANT.TITLE.DELETE_STUDY,
              content: `Ваше исследование является лонгитюдным и разделено на этапы. Вы хотите удалить исследование целиком или только его ${researchType?.step} этап?`,
            };
          }
        } else if (researchType?.longitudinal && researchType?.step === 1) {
          return {
            title: MODAL_TEXT_CONSTANT.TITLE.DELETE_STUDY,
            content: MODAL_TEXT_CONSTANT.TEXT_CONTENT.DELETE_STUDY_LONGITUDINAL,
          };
        } else {
          return {
            title: MODAL_TEXT_CONSTANT.TITLE.DELETE_STUDY,
            content: MODAL_TEXT_CONSTANT.TEXT_CONTENT.DELETE_STUDY,
          };
        }
      } else if (modalType === 'publication') {
        return {
          title: MODAL_TEXT_CONSTANT.TITLE.PUBLICATION_REQUEST,
          content:
            MODAL_TEXT_CONSTANT.TEXT_CONTENT
              .SUBMIT_A_RESEARCH_PUBLICATION_REQUEST,
        };
      } else {
        return {
          title: MODAL_TEXT_CONSTANT.TITLE.ERROR,
          content: MODAL_TEXT_CONSTANT.TEXT_CONTENT.ERROR_DEFAULT,
        };
      }
    }
    return {
      title: MODAL_TEXT_CONSTANT.TITLE.ERROR,
      content: MODAL_TEXT_CONSTANT.TEXT_CONTENT.ERROR_DEFAULT,
    };
  }, [modalType, researchType, isDeleteStep]);

  // const requestPublication = async (researchId: string) => {
  //   trigger({ researchId })
  //     .unwrap()
  //     .then((res) => {
  //       // if (res.result) {
  //       // }
  //     })
  //     .catch(() => {});
  // };

  const publicationOrDeleteHandler = () => {
    if (modalType) {
      if (modalType === 'publication') {
        requestForPublicationHandler();
        setIsOpenModal(false);
        setModalType('');
      } else if (modalType === 'delete') {
        deleteResearchHandler();
        setIsOpenModal(false);
        setModalType('');
      } else {
        setIsOpenModal(false);
        setModalType('');
      }
    } else {
      setIsOpenModal(false);
      setModalType('');
    }
  };

  const deleteResearchHandler = async (force = false) => {
    let result;
    if (isDeleteStep === 'all') {
      result = await deleteResearchLongitudinal({
        id: researchId,
        force,
      });
    } else {
      result = await deleteResearch({ id: researchId, force });
    }

    if ('data' in result) {
      navigate(frontendRoutes.materials.research);
    }
  };

  const requestForPublicationHandler = async () => {
    const result = await requestForPublication({ researchId: researchId });
    if ('data' in result) {
      navigate(frontendRoutes.materials.research);
    }
  };

  const teamInviteHandler = () => {
    if (invite?.invite) {
      const el = document.createElement('input');
      el.value = `${API_URL}research/invite/permissions/${invite.invite}`;
      // el.value = `http://localhost:3000/research/invite/permissions/${invite.invite}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (clickedCopy) {
        clickedCopy();
      }
    }
  };

  const disablePublicationButton = useMemo(() => {
    if (publicationStatus !== 'unpublished' && publicationStatus !== 'rework') {
      return true;
    }
    if (researchSurvey) {
      if (
        researchSurvey.data.research_methods.length > 0 ||
        researchSurvey.data.research_questionnaires.length > 0
      ) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }, [publicationStatus, researchSurvey]);

  const disableDeleteButton = useMemo(() => {
    if (publicationStatus === 'published') {
      return true;
    }

    if (!isTheAuthor) {
      return true;
    }

    return false;
  }, [publicationStatus, isTheAuthor]);

  const handleDeleteInviteUrl = async () => {
    const result = await deleteInviteUrl({
      researchId: researchId,
      type: 'member',
    });
    if ('data' in result) {
      // eslint-disable-next-line no-console
      console.log('done');
    }
  };

  useEffect(() => {
    if (members) {
      setResearchMembers(members);
    }
  }, [members]);

  useEffect(() => {
    if (teams) {
      setResearchTeams(teams);
    }
  }, [teams]);

  useEffect(() => {
    if (isDeleteResearchError) {
      setModalError(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isDeleteResearchError?.data.error
      );
    }
  }, [isDeleteResearchError]);

  useEffect(() => {
    if (isResearchPublicationError) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setModalError(isResearchPublicationError?.data.error);
    }
  }, [isResearchPublicationError]);

  useEffect(() => {
    if (isAddRespondentError) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formik.setErrors({ login: isAddRespondentError?.data.message });
    }
  }, [isAddRespondentError]);

  useEffect(() => {
    if (isAddTeamError) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formik.setErrors({ joinTeam: isAddTeamError?.data.message });
    }
  }, [isAddTeamError]);

  useEffect(() => {
    if (isTransferUserError) {
      setModalError(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isTransferUserError?.data.message
      );
    }
  }, [isTransferUserError]);

  return (
    <>
      <FormikContext.Provider value={formik}>
        <Form autoComplete="off">
          <div className="grid-row-2 vm-20">
            <div>
              <Box mb={32}>
                <Typography color="main" fontWeight={600} size="s" upperCase>
                  Совместный доступ к исследованию
                </Typography>
              </Box>
              <Box flex>
                <Box mb={32} mr={24}>
                  <Button
                    label="Копировать ссылку-приглашение"
                    withoutBackground
                    disabled={!invite}
                    clicked={teamInviteHandler}
                  />
                </Box>
                <div
                  style={{
                    cursor: 'pointer',
                    marginBottom: 32,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={handleDeleteInviteUrl}
                >
                  <img
                    src={linkIcon}
                    alt="delete_icon"
                    style={{ marginRight: 8 }}
                  />
                  <Typography
                    color="main"
                    fontWeight={400}
                    size="s"
                    customLineHeight={26}
                  >
                    Удалить ссылку
                  </Typography>
                </div>
              </Box>
            </div>
            <div>
              <Box mb={32}>
                <Typography color="main" fontWeight={600} size="s" upperCase>
                  Передать права на исследование
                </Typography>
              </Box>
              <Box mb={32}>
                <Button
                  label="Выбрать пользователя"
                  withoutBackground
                  disabled={researchMembers.length <= 0 || !isTheAuthor}
                  clicked={() => setIsOpenModalTransfer(true)}
                />
              </Box>
            </div>
          </div>
          <div className="divider"></div>
          <Box mb={32}>
            <Typography color="main" fontWeight={600} size="s" upperCase>
              Команда исследования
            </Typography>
          </Box>

          <div className="grid-row-2 vm-20">
            <div>
              <InputText
                name="login"
                placeholder="Пригласить по логину"
                fullWidth
                // disabled={!isTheAuthor}
              />
              <Box mt={24} mb={24}>
                <Button
                  label="Открыть доступ"
                  gradient
                  disabled={
                    !formik.isValid || !formik.dirty || !formik.values.login
                    // !isTheAuthor
                  }
                  clicked={() => {
                    researchMembers.filter(
                      (el) => el.member_email === formik.values.login
                    ).length === 0
                      ? addRespondentResearchHandler()
                      : setModalError('This email is invited');
                  }}
                />
              </Box>
              {/* TODO: replace with formik array */}
              {researchMembers &&
                researchMembers.length > 0 &&
                researchMembers.map((el) => {
                  return (
                    <ResearchMemberCard
                      key={el.member_id}
                      member_id={el.member_id}
                      member_email={el.member_email}
                      member_last_name={el.member_last_name}
                      member_first_name={el.member_first_name}
                      member_middle_name={el.member_middle_name}
                      researchId={researchId}
                    />
                  );
                })}
            </div>

            <div>
              <Select
                name="joinTeam"
                label="Пригласить команду"
                options={teamList}
                fullWidth
                // disabled={!isTheAuthor}
              />
              <Box mt={24} mb={24}>
                <Button
                  label="Открыть доступ"
                  gradient
                  disabled={!formik.dirty || !formik.values.joinTeam}
                  clicked={() => {
                    researchTeams.filter(
                      (el) => el.team_id === formik.values.joinTeam
                    ).length === 0
                      ? addTeamResearchHandler()
                      : setModalError('This team is invited');
                  }}
                />
              </Box>
              {/* TODO: replace with formik array */}
              {researchTeams &&
                researchTeams.length > 0 &&
                researchTeams.map((el: ResearchTeam) => {
                  return (
                    <ResearchTeamCard
                      key={el.team_id}
                      team_id={el.team_id}
                      team_name={el.team_name}
                      team_description={el.team_description}
                      researchId={researchId}
                    />
                  );
                })}
            </div>
          </div>
          <div className="divider"></div>
          <Box flex>
            <Button
              label="Отправить запрос на публикацию исследования"
              color="gradient"
              disabled={disablePublicationButton || !isTheAuthor}
              clicked={() => {
                setModalType('publication');
                setIsOpenModal(true);
              }}
            />
            <Box mr={16} />
            <Button
              label="Удалить исследование"
              color="gradientDanger"
              disabled={disableDeleteButton}
              clicked={() => {
                setModalType('delete');
                setIsOpenModal(true);
              }}
            />
          </Box>
        </Form>
      </FormikContext.Provider>
      <ContentModal
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
          setModalType('');
          setIsDeleteStep('');
        }}
        titleText={convertModelText.title}
      >
        <>
          <div>{convertModelText.content}</div>
          <div>
            <div className="divider"></div>
            {researchType?.longitudinal &&
            researchType?.step > 1 &&
            !isDeleteStep ? (
              <Box flex>
                <Button
                  label="Удалить исследование целиком"
                  gradient={true}
                  color="gradientDanger"
                  clicked={() => {
                    setIsDeleteStep('all');
                  }}
                />
                <Box mr={16} />
                <Button
                  label="Удалить этап исследования"
                  gradient={true}
                  color="gradientOrange"
                  clicked={() => {
                    setIsDeleteStep('step');
                  }}
                />
                <Box mr={16} />
                <Button
                  label={MODAL_TEXT_CONSTANT.BUTTON.CANCEL}
                  withoutBackground
                  clicked={() => {
                    setIsOpenModal(false);
                    setIsDeleteStep('');
                    setModalType('');
                  }}
                />
              </Box>
            ) : (
              <Box flex>
                <Button
                  label={MODAL_TEXT_CONSTANT.BUTTON.CONFIRM}
                  gradient={true}
                  clicked={() => {
                    setIsOpenModal(false);
                    publicationOrDeleteHandler();
                    setModalType('');
                    setIsDeleteStep('');
                  }}
                />
                <Box mr={16} />
                <Button
                  label={MODAL_TEXT_CONSTANT.BUTTON.CANCEL}
                  withoutBackground
                  clicked={() => {
                    setIsOpenModal(false);
                    setModalType('');
                    setIsDeleteStep('');
                  }}
                />
              </Box>
            )}
          </div>
        </>
      </ContentModal>
      <ContentModal
        isOpen={isOpenModalTransfer}
        onClose={() => setIsOpenModalTransfer(false)}
        titleText="Передача прав на исследование"
      >
        <>
          <FormikContext.Provider value={formikTransfer}>
            <Form autoComplete="off">
              <div>
                <Select
                  name="userLogin"
                  label="Выбрать пользователя"
                  options={researchMembers.map((el) => {
                    return { key: el.member_email, value: el.member_email };
                  })}
                  fullWidth
                />
                <Box mt={24} mb={24} />
              </div>
            </Form>
          </FormikContext.Provider>
          <div className="divider" />
          <Box flex>
            <Button
              label="Сохранить"
              gradient={true}
              disabled={!formikTransfer.isValid || !formikTransfer.dirty}
              clicked={() => {
                setIsOpenModalTransfer(false);
                setIsOpenModalTransferConfirmation(true);
              }}
            />
            <Box mr={16} />
            <Button
              label="Отмена"
              withoutBackground
              clicked={() => {
                setIsOpenModalTransfer(false);
              }}
            />
          </Box>
        </>
      </ContentModal>
      <ContentModal
        isOpen={isOpenModalTransferConfirmation}
        onClose={() => setIsOpenModalTransferConfirmation(false)}
        titleText="Подтверждение передачи прав"
      >
        <>
          <div>
            Вы уверены что хотите передать права на исследование пользователю{' '}
            <b>{formikTransfer.values.userLogin}</b>?
          </div>
          <Box flex mt={32}>
            <Button
              label="Подтвердить"
              gradient={true}
              type="button"
              clicked={() => {
                setIsOpenModalTransferConfirmation(false);
                transferUserResearchHandler();
              }}
            />
            <Box mr={16} />
            <Button
              label="Отменить"
              withoutBackground={true}
              clicked={() => {
                setIsOpenModalTransferConfirmation(false);
              }}
            />
          </Box>
        </>
      </ContentModal>
      <ModalError
        isOpen={!!modalError}
        titleText={
          modalError.substring(0, modalError.indexOf('есть') - 1) ===
          'У исследования'
            ? 'Внимание'
            : 'Ошибка'
        }
        errorText={
          modalError.substring(0, modalError.indexOf('есть') - 1) ===
          'У исследования'
            ? ''
            : modalError
        }
        onClose={() => {
          setModalError('');
        }}
      >
        <>
          {modalError.substring(0, modalError.indexOf('есть') - 1) ===
            'У исследования' && (
            <>
              <div style={{ marginBottom: 20 }}>
                <Typography color="main" size="m" vMargin={10}>
                  В Вашем исследовании присутствуют уже собранные ответы.
                  <br /> Удаление исследования приведет к их потере.
                  <br />
                  Вы действительно хотите его удалить?
                </Typography>
              </div>

              <Box flex mt={32}>
                <Button
                  label="Удалить"
                  type="button"
                  gradient={true}
                  color="gradientDanger"
                  clicked={() => deleteResearchHandler(true)}
                />
                <Box mr={16} />
                <Button
                  label="Отменить"
                  withoutBackground={true}
                  clicked={() => {
                    setModalError('');
                  }}
                />
              </Box>
            </>
          )}
        </>
      </ModalError>
    </>
  );
};
