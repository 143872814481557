// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PublicEventDocuments_blockButton__XbLbW {
  display: flex;
}
.PublicEventDocuments_blockButton__settingsButton__OV-KS {
  margin-left: 16px;
}

.PublicEventDocuments_iconDown__152Y\\+ {
  transform: rotate(180deg);
  cursor: pointer;
}

.PublicEventDocuments_team__filters__JVQdM {
  margin: 40px 0 24px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/publicEventsCatalog/PublicEventComponent/PublicEventDocuments/PublicEventDocuments.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AACE;EACE,iBAAA;AACJ;;AAGA;EACE,yBAAA;EACA,eAAA;AAAF;;AAGA;EACE,qBAAA;AAAF","sourcesContent":[".blockButton {\n  display: flex;\n\n  &__settingsButton{\n    margin-left: 16px;\n  }\n}\n\n.iconDown {\n  transform: rotate(180deg);\n  cursor: pointer;\n}\n\n.team__filters {\n  margin: 40px 0 24px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blockButton": `PublicEventDocuments_blockButton__XbLbW`,
	"blockButton__settingsButton": `PublicEventDocuments_blockButton__settingsButton__OV-KS`,
	"iconDown": `PublicEventDocuments_iconDown__152Y+`,
	"team__filters": `PublicEventDocuments_team__filters__JVQdM`
};
export default ___CSS_LOADER_EXPORT___;
