export const IconFooterOdnoklassniki = () => {
  return (
    <svg
      width="11"
      height="19"
      viewBox="0 0 11 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.12923 0C2.32485 0 0.0220664 2.30278 0.0220664 5.10717C0.0220664 7.91155 2.32485 10.2143 5.12923 10.2143C7.93362 10.2143 10.2364 7.91155 10.2364 5.10717C10.2364 2.30278 7.93362 0 5.12923 0ZM5.12923 2.91838C6.35473 2.91838 7.31802 3.88167 7.31802 5.10717C7.31802 6.33266 6.35473 7.29595 5.12923 7.29595C3.90374 7.29595 2.94045 6.33266 2.94045 5.10717C2.94045 3.88167 3.90374 2.91838 5.12923 2.91838ZM1.44706 10.7672C0.774462 10.7729 0.193066 11.2403 0.0391669 11.9015C-0.109033 12.557 0.210165 13.2296 0.808662 13.5317C1.30456 13.7882 1.83466 13.9877 2.38185 14.1587L0.364064 16.5755C0.0106672 16.9745 -0.0919326 17.5331 0.0847667 18.029C0.261466 18.5306 0.694663 18.8897 1.21906 18.9809C1.74346 19.0664 2.27355 18.8612 2.59845 18.4451L5.12923 15.4127L7.66002 18.4451C7.98492 18.8612 8.51501 19.0664 9.03941 18.9809C9.56381 18.8897 9.99701 18.5306 10.1737 18.029C10.3504 17.5331 10.2478 16.9745 9.8944 16.5755L7.87662 14.1587C8.42381 13.9877 8.95391 13.7882 9.44411 13.5317C10.1623 13.1612 10.4416 12.2834 10.0711 11.5652C9.70631 10.847 8.82281 10.5677 8.10462 10.9382C7.19832 11.4056 6.20083 11.6735 5.12923 11.6735C4.05764 11.6735 3.06015 11.4056 2.15385 10.9382C1.93725 10.8242 1.69216 10.7615 1.44706 10.7672Z"
        fill="#EC8225"
      />
    </svg>
  );
};
