import { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames/bind';

import { isSameCalendarDate } from '../../../utils/components/calendar';
import { getStartOfDay, getEndOfDay } from '../../../utils/dates';

//  ui
import { Calendar } from './Calendar';
import { Button } from '../Button';
import { IconDatePickerArrow } from '../Icons/IconDatePickerArrow';

import styles from './DatePicker.module.scss';
const cx = classNames.bind(styles);

type DatePickerProps = {
  isRange?: boolean;
  minDate?: Date;
  startDate: Date | undefined;
  setStartDate: (date: Date | undefined) => void;
  finishDate: Date | undefined;
  setFinishDate: (date: Date | undefined) => void;
  setOpen: (value: boolean) => void;
  handleChange: () => void;
};

export const DatePicker = ({
  isRange,
  startDate,
  setStartDate,
  finishDate,
  setFinishDate,
  setOpen,
  handleChange,
  minDate,
}: DatePickerProps): JSX.Element => {
  const [calendarDate, setCalendarDate] = useState<Date>(
    startDate ? new Date(startDate) : new Date()
  );
  const [nextCalendarDate, setNextCalendarDate] = useState<Date>(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)
  );

  const actualMonth: number = startDate
    ? startDate.getMonth()
    : new Date().getMonth();
  const actualNextMonth: number = finishDate
    ? finishDate.getMonth()
    : new Date().getMonth();
  const actualYear = startDate
    ? startDate.getFullYear()
    : new Date().getFullYear();
  const actualNextYear = finishDate
    ? finishDate.getFullYear()
    : new Date().getFullYear();
  const [month, setMonth] = useState<number>(actualMonth);
  const [nextMonth, setNextMonth] = useState<number>(actualNextMonth);
  const [year, setYear] = useState<number>(actualYear);
  const [nextYear, setNextYear] = useState<number>(actualNextYear);

  const handlePrevMonth = useCallback((): void => {
    const newDate = new Date(calendarDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setCalendarDate(newDate);
  }, [calendarDate]);

  const handleNextMonth = useCallback((): void => {
    const newDate = new Date(nextCalendarDate);
    newDate.setMonth(newDate.getMonth() + 1);
    setNextCalendarDate(newDate);
  }, [nextCalendarDate]);

  const handleDateChange = useCallback(
    (date: Date) => {
      if (isRange) {
        if (startDate) {
          if (date < startDate) {
            /* 
              If finish date been chosen before start date,
              remove finish date and set up start date again.
            */
            setStartDate(getStartOfDay(date));
            setFinishDate(undefined);
          } else if (date > startDate) {
            setFinishDate(getEndOfDay(date));
          }
        } else {
          setStartDate(getStartOfDay(date));
          setFinishDate(undefined);
        }
      } else {
        setStartDate(getStartOfDay(date));
        setOpen(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [calendarDate, finishDate, isRange, nextCalendarDate, startDate]
  );

  const handleReset = (): void => {
    const newDate = new Date();
    newDate.setMonth(new Date().getMonth() + 1);
    setStartDate(undefined);
    setFinishDate(undefined);
    setCalendarDate(new Date());
    setNextCalendarDate(newDate);
    setMonth(actualMonth);
    setNextMonth(actualNextMonth);
    setYear(actualYear);
    setNextYear(actualNextYear);
  };

  const handleApply = (): void => {
    setOpen(false);
    handleChange();
  };

  // Sync main calendar date state with calendar's year and month selects
  useEffect(() => {
    const newDate = new Date();
    const newNextDate = new Date();
    newDate.setMonth(month);
    newDate.setFullYear(+year);
    newNextDate.setMonth(nextMonth);
    newNextDate.setFullYear(nextYear);

    //  Guard against wrong date via selects
    if (newNextDate <= newDate) {
      const nextDate = new Date(
        new Date(newDate).getFullYear(),
        new Date(newDate).getMonth() + 1,
        1
      );

      setCalendarDate(newDate);
      setNextCalendarDate(nextDate);
      setNextMonth(month + 1);
      setNextYear(nextDate.getFullYear());

      //  If everything is correct in the selects
    } else {
      setCalendarDate(newDate);
      setNextCalendarDate(newNextDate);
    }
  }, [month, nextMonth, year, nextYear]);

  //  Prevent pick start date from right calendar
  useEffect(() => {
    if (startDate && !finishDate) {
      const isSameCalendar = isSameCalendarDate(calendarDate, startDate);
      if (!isSameCalendar) {
        const nextDate = new Date(startDate);
        nextDate.setMonth(startDate.getMonth() + 1);
        setCalendarDate(startDate);
        setNextCalendarDate(nextDate);
      }
    }
  }, [calendarDate, finishDate, startDate]);

  return (
    <div className={styles.wrapper}>
      <div className={cx('calendarBlock')}>
        <div className={cx('leftArrow')} onClick={handlePrevMonth}>
          <IconDatePickerArrow />
        </div>
        {isRange ? (
          <div className={styles.rangeWrapper}>
            <Calendar
              startDate={startDate}
              handleChange={handleDateChange}
              finishDate={finishDate}
              actualDate={calendarDate}
              isRange={isRange}
              setMonth={setMonth}
              setYear={setYear}
              minDate={minDate}
            />
            <Calendar
              startDate={startDate}
              handleChange={handleDateChange}
              finishDate={finishDate}
              actualDate={nextCalendarDate}
              isRange={isRange}
              setMonth={setNextMonth}
              setYear={setNextYear}
              minDate={minDate}
            />
          </div>
        ) : (
          <Calendar
            startDate={startDate}
            handleChange={handleDateChange}
            actualDate={calendarDate}
            setMonth={setMonth}
            setYear={setYear}
            minDate={minDate}
          />
        )}
        <div className={styles.rightArrow} onClick={handleNextMonth}>
          <IconDatePickerArrow />
        </div>
      </div>
      {!isRange && <div className={cx('empty')}></div>}
      {isRange && (
        <div
          className={cx('buttonsBlock', {
            single: !isRange,
            range: isRange,
          })}
        >
          <div className={cx('btns')}>
            <Button
              label="Сбросить"
              size="small"
              withoutBackground
              clicked={handleReset}
            />
            <Button
              label="Применить"
              size="small"
              gradient
              clicked={handleApply}
            />
          </div>
        </div>
      )}
    </div>
  );
};
