import React from 'react';
import classNames from 'classnames/bind';

//ui
import styles from './SelectType.module.scss';

import { PROF_CATEGORIES } from '../../../../constants/survey/survey';
import { Select } from '../../Form/Select';
import { Survey } from '../../../../models/survey/survey';

const cx = classNames.bind(styles);

type SelectProps = {
  questionTitle: string;
  options: Survey[];
  name: string;
  disabled?: boolean;
};

export const SelectType = (props: SelectProps) => {
  const { questionTitle, name, disabled } = props;

  return (
    <div className={cx('selectType')}>
      <div className={cx('selectType__title')}>{questionTitle}</div>
      <div className={cx('selectType__item')}>
        <Select
          name={name}
          label={'Выбор значения из выпадающего списка'}
          options={PROF_CATEGORIES}
          fullWidth
          disabled={disabled}
        />
      </div>
    </div>
  );
};
