import { TableExpanded } from '../TableExpanded';

type NoTableProps = {
  isError: boolean;
  errorTitle?: string;
  errorText?: string;
  dataLength: number;
  dataLengthTitle?: string;
  dataLengthText?: string;
};

export const NoTable = ({
  isError,
  errorText,
  errorTitle,
  dataLength = 0,
  dataLengthTitle,
  dataLengthText,
}: NoTableProps): JSX.Element => {
  if (isError) {
    return <TableExpanded.Error title={errorTitle} text={errorText} />;
  } else if (dataLength === 0) {
    return (
      <TableExpanded.Empty title={dataLengthTitle} text={dataLengthText} />
    );
  }
  return <TableExpanded.Error />;
};
