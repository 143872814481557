export const frontendRoutes = {
  mainPage: '/',
  ui: '/ui',
  user: {
    login: '/login',
    confirmCode: '/registration/code',
    chooseRegType: '/registration-type',
    registration: '/sign-up',
    registrationCompany: '/company-sign-up',
    registrationRespondent: '/respondent-sign-up',
    restorePass: '/restore-password',
    restorePassAccept: '/forgot/new-password',
    profile: {
      index: '/profile',
    },
    changePassword: '/profile/change-password',
    confirmPassword: '/profile/confirm-password',
    changeMail: '/profile/change-email',
    confirmMail: '/profile/confirm-email',
    verifyEmail: 'verify-email',
  },
  teams: {
    teams: '/teams',
    invite: 'api/teams/invite/activate?invite=',
    team: {
      events: '/team/events',
      research: '/team/research',
      docs: '/team/docs',
      participants: '/team/participants',
      new: '/team/new',
      edit: '/team/edit',
    },
  },
  materials: {
    events: '/materials/events',
    research: '/materials/research',
    docs: '/materials/docs',
    feedback: '/materials/feedback',
  },
  research: {
    researches: '/researches',
    details: '/research/details',
    edit: {
      settings: '/research/settings',
      permissions: '/research/permissions',
      respondents: '/research/respondents',
      survey: '/research/survey',
    },
    new: '/research/new',
    publication: '/research/publication',
    invite: '/research/invite',
    comparison: '/research/comparison',
    requestsOnPublish: '/research/requests-on-publish',
  },
  quiz: {
    quizzes: '/quizzes',
    quiz: '/quiz',
  },
  publicEventsCatalog: {
    events: '/events',
    eventsRequest: '/events/requests',
    requestsOnPublish: '/events/requests-on-publish',
  },
  createEvent: {
    createEvent: '/create-event',
  },
  settingsEvent: {
    settingsEvent: '/settings-event',
    accessesAndRole: '/accesses-roles',
    gradingSystem: '/grading-system',
    invite: '/events/invite/activate',
  },
  document: {
    edit: '/document/edit',
    modal: 'modal',
    expert: '/document/expert',
    inner: '/document',
    automaticAnalysisResults: '/document/automatic-results',
  },
  survey: {
    survey: '/survey',
    complete: '/survey-complete',
    login: '/survey-login',
    questionnaire: '/survey/questionnaire',
    methods: '/survey/methods',
    feedback: '/survey/feedback',
  },
  map: {
    interactiveMap: '/interactive-map',
  },
};
