// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFoundPage_wrapper__Cqii0 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  margin-top: 180px;
  margin-bottom: 220px;
}
.NotFoundPage_wrapper__text_block__jXPzt {
  display: flex;
  flex-direction: column;
  margin: 0 25px;
}
.NotFoundPage_wrapper__number__\\+OgpK {
  display: flex;
  flex-grow: 0;
  width: 37px;
  height: 28px;
  margin-bottom: 24px;
}
.NotFoundPage_wrapper__header_text__Vgelq {
  flex-grow: 0;
  width: 400px;
  margin-bottom: 14px;
}
.NotFoundPage_wrapper__main_text__\\+vEeX {
  flex-grow: 0;
  width: 416px;
}
.NotFoundPage_wrapper__button__JhrwU {
  display: flex;
  flex-grow: 0;
  margin-top: 32px;
}`, "",{"version":3,"sources":["webpack://./src/pages/NotFoundPage/NotFoundPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,6BAAA;EACA,YAAA;EACA,iBAAA;EACA,oBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,cAAA;AACJ;AAEE;EACE,aAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;AAAJ;AAGE;EACE,YAAA;EACA,YAAA;EACA,mBAAA;AADJ;AAIE;EACE,YAAA;EACA,YAAA;AAFJ;AAKE;EACE,aAAA;EACA,YAAA;EACA,gBAAA;AAHJ","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-evenly;\n  height: 100%;\n  margin-top: 180px;\n  margin-bottom: 220px;\n\n  &__text_block {\n    display: flex;\n    flex-direction: column;\n    margin: 0 25px;\n  }\n\n  &__number {\n    display: flex;\n    flex-grow: 0;\n    width: 37px;\n    height: 28px;\n    margin-bottom: 24px;\n  }\n\n  &__header_text {\n    flex-grow: 0;\n    width: 400px;\n    margin-bottom: 14px;\n  }\n\n  &__main_text {\n    flex-grow: 0;\n    width: 416px;\n  }\n\n  &__button {\n    display: flex;\n    flex-grow: 0;\n    margin-top: 32px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `NotFoundPage_wrapper__Cqii0`,
	"wrapper__text_block": `NotFoundPage_wrapper__text_block__jXPzt`,
	"wrapper__number": `NotFoundPage_wrapper__number__+OgpK`,
	"wrapper__header_text": `NotFoundPage_wrapper__header_text__Vgelq`,
	"wrapper__main_text": `NotFoundPage_wrapper__main_text__+vEeX`,
	"wrapper__button": `NotFoundPage_wrapper__button__JhrwU`
};
export default ___CSS_LOADER_EXPORT___;
