import React, { useEffect, useMemo, useState } from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import styles from './GradingSystemForm.module.scss';
import classNames from 'classnames/bind';

//  ui
import { Button } from '../../../../ui/Button';
import { Toggle } from '../../../../ui/Toggle';

import { Textarea } from '../../../../ui/Form/Textarea/Textarea';
import { InputText } from '../../../../ui/Form/InputText';
import { CriteriaItem } from './CriteriaItem/CriteriaItem';
import { object, string } from 'yup';
import {
  useGetEventDocumentsQuery,
  useGetEventQuery,
  useUpdateEventEstimateMutation,
} from '../../../../../store/VbudusheeAPI';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from '../../../../../hooks/useToasts';
import { ContentModal } from '../../../../ui/ContentModal';
import { Box } from '../../../../ui/Box';

const cx = classNames.bind(styles);

export type CriteriaType = {
  id: number;
  category_name: string;
  remove?: boolean;
};

export const GradingSystemForm = () => {
  const { onShowToast } = useToasts();
  const [serverError] = useState<string | undefined>();
  const [toggle, setToggle] = useState(false);
  const [isCriteriaAdding, setCriteriaAdding] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [editCriteriaModal, setEditCriteriaModal] = useState<boolean>(false);
  const [visibleModal, setVisibleModal] = useState<boolean>(true);

  const [editCriteria, setEditCriteria] = useState<number | null>(null);

  const [criteries, setCriteries] = useState<CriteriaType[]>([]);

  const { eventId } = useParams();

  const navigate = useNavigate();

  const { data, refetch } = useGetEventQuery({ id: eventId || '0' });

  const {
    data: dataDocuments,
    isFetching: isFetchingDefault,
    isError: isErrorDefault,
  } = useGetEventDocumentsQuery(
    {
      id: eventId || '0',
    },
    { skip: !eventId }
  );

  const [updateEventEstimate] = useUpdateEventEstimateMutation();

  const gradingSystemInitialValues = {
    rules_load_document: data?.data.event_document_requirements || '',
  };

  const gradingCriteriaSystemInitialValues = {
    description_criteria: '',
  };

  const gradingSystemValidationSchema = object({
    rules_load_document: string().max(
      2000,
      'Поле "Требование к загрузке документа" должно содержать не более меньше 2000 символов'
    ),
  });

  const gradingCriteriaSystemValidationSchema = object({
    description_criteria: string()
      .required('Заполните поле')
      .max(255, 'Длина названия должна быть не более 255 символов')
      .min(5, 'Длина названия должна быть больше 5 символа'),
  });

  const handleSubmit = async (values: typeof gradingSystemInitialValues) => {
    await updateEventEstimate({
      eventId,
      data: {
        event_estimate_on: toggle,
        event_document_requirements: values.rules_load_document,
        event_estimate_categories: criteries,
      },
    });

    refetch();

    if (eventId) {
      navigate(`/events/${eventId}`);
    }
  };

  function clearData() {
    navigate('/events/' + eventId);
  }
  const addCriteriaItem = () => {
    const newCreteria = {
      id: 0,
      category_name: formikCriteria.values.description_criteria,
    };
    setCriteries((criteries) => [...criteries, newCreteria]);
    formikCriteria.resetForm();
    setCriteriaAdding(false);
  };

  const formik = useFormik({
    initialValues: gradingSystemInitialValues,
    validationSchema: gradingSystemValidationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const formikCriteria = useFormik({
    initialValues: gradingCriteriaSystemInitialValues,
    validationSchema: gradingCriteriaSystemValidationSchema,
    onSubmit: addCriteriaItem,
  });

  useEffect(() => {
    setToggle(data?.data.event_estimate_on || false);

    setCriteries(data?.data.event_estimate_categories || []);
  }, [data]);

  const removeCriteriaItem = (id: number) => {
    setCriteries((prev) =>
      prev.map((el) => {
        if (el.id === id) {
          return { ...el, remove: true };
        } else {
          return el;
        }
      })
    );
  };

  const isEditable = !(
    data?.data.event_publication_status !== 'new' &&
    data?.data.event_publication_status !== 'rework' &&
    data?.data.event_publication_status !== 'rejected'
  );

  const documentStatus = [
    'published',
    'published_for_member',
    'rejected',
    'approved',
    'rework',
  ];

  const comparisonInitialValues = useMemo(() => {
    return (
      JSON.stringify(data?.data.event_estimate_categories) ===
        JSON.stringify(criteries) && data?.data.event_estimate_on === toggle
    );
  }, [data, criteries, toggle]);

  const verifiedDocuments = useMemo(() => {
    return !!dataDocuments?.data.filter((el) =>
      documentStatus.includes(el.document_status)
    ).length;
  }, [dataDocuments]);

  useEffect(() => {
    if (!criteries.filter((el) => !el.remove).length && toggle) {
      onShowToast({
        type: 'danger',
        name: 'criteries',
        description:
          'Для работы системы оценивания необходимо добавить минимум один критерий.',
        timer: 9000,
      });
    }
  }, [criteries, toggle]);

  return (
    <>
      <FormikContext.Provider value={formik}>
        <Form>
          <div className={styles.form}>
            <div className={styles.form__block}>
              <Textarea
                name="rules_load_document"
                placeholder={'Требования к загрузке документа'}
                editable={isEditable}
                height={120}
              />
              <div className={styles.toggle__wrapper}>
                <div className={cx('toggle', { toggle__modified: toggle })}>
                  <Toggle
                    name="toggle"
                    clicked={() => {
                      if (
                        verifiedDocuments &&
                        toggle === data?.data.event_estimate_on
                      ) {
                        setIsOpenModal(true);
                      } else {
                        setToggle(!toggle);
                      }
                    }}
                    disable={!isEditable}
                    isActive={toggle}
                  />
                  <div className={styles.toggle__text}>Система оценивания</div>
                </div>
                <p>
                  Нажмите на кнопку «Система оценивания», если для проверки
                  работ предполагается использовать балльную систему. Затем
                  сформулируйте критерии оценивания. Соответствие работы каждому
                  критерию будет оцениваться по шкале от 0 до 10.
                </p>
              </div>
              {criteries.map((item, i) => {
                if (!item.remove)
                  return (
                    <div key={i}>
                      <CriteriaItem
                        id={item.id}
                        idx={i}
                        description={item.category_name}
                        onClick={() => {
                          if (verifiedDocuments && visibleModal) {
                            setIsOpenModal(true);
                            setEditCriteriaModal(true);
                          } else {
                            removeCriteriaItem(item.id);
                          }
                        }}
                        editCriteria={editCriteria}
                        setEditCriteria={(value) => {
                          if (verifiedDocuments && visibleModal) {
                            setIsOpenModal(true);
                            setEditCriteriaModal(true);
                          } else {
                            setEditCriteria(value);
                          }
                        }}
                        setCriteries={setCriteries}
                        disabled={!isEditable}
                        maxLength={255}
                      />
                    </div>
                  );
              })}
              {isCriteriaAdding && (
                <FormikContext.Provider value={formikCriteria}>
                  <Form>
                    <div className={styles.criteria__form}>
                      <div className={styles.criteries}>{`критерий ${
                        criteries.length + 1
                      }`}</div>
                      <InputText
                        name="description_criteria"
                        placeholder="Описание критерия"
                        fullWidth
                      />
                      <div
                        style={{
                          display: 'flex',
                          columnGap: '16px',
                        }}
                      >
                        <div>
                          <Button
                            label="Добавить"
                            type="button"
                            clicked={() => addCriteriaItem()}
                            gradient
                            size={'medium'}
                            disabled={
                              !formikCriteria.dirty || !formikCriteria.isValid
                            }
                          />
                        </div>
                        <Button
                          label="Отмена"
                          type="button"
                          gradient
                          clicked={() => {
                            formikCriteria.resetForm();
                            setCriteriaAdding(false);
                          }}
                          withoutBackground
                        />
                      </div>
                    </div>
                  </Form>
                </FormikContext.Provider>
              )}
            </div>
            {serverError && (
              <div className={styles.form__error}>{serverError}</div>
            )}
            <div className={styles.add__btn}>
              <Button
                label="Добавить критерий"
                withoutBackground
                clicked={() => {
                  if (verifiedDocuments && visibleModal) {
                    setIsOpenModal(true);
                    setEditCriteriaModal(true);
                  } else {
                    setCriteriaAdding(true);
                  }
                }}
                disabled={isCriteriaAdding || !toggle || !isEditable}
              />
            </div>
            {<div className={styles.form__block_divider} />}
            <div
              style={{
                display: 'flex',
                columnGap: '16px',
              }}
            >
              <div className={styles.gradingSystem__header_submit}>
                <Button
                  disabled={!isEditable}
                  type="button"
                  label="Отмена"
                  gradient
                  clicked={clearData}
                  withoutBackground
                />
              </div>
              <Button
                disabled={
                  !isEditable ||
                  (!formik.dirty && comparisonInitialValues) ||
                  !formik.isValid ||
                  (!criteries.filter((el) => !el.remove).length && toggle)
                }
                label="Сохранить"
                type="submit"
                gradient
              />
            </div>
          </div>
        </Form>
      </FormikContext.Provider>
      <ContentModal
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
          setEditCriteriaModal(false);
        }}
        titleText="Внимание!"
      >
        <>
          {editCriteriaModal ? (
            <div>
              Некоторые собранные в рамках мероприятия документы уже были
              <br />
              проверены экспертами текущим вариантом балльной системы
              <br />
              оценивания. При ее редактировании эти документы будут повторно
              <br />
              направлены экспертам на проверку.
              <br />
              Вы уверены, что хотите внести изменения?
            </div>
          ) : toggle ? (
            <div>
              Некоторые собранные в рамках мероприятия документы уже были
              <br />
              проверены экспертами с применением балльной системой оценивания.
              <br />
              При ее деактивации полученные авторами документов оценки будут
              <br />
              удалены. При этом комментарии к работам от экспертов сохранятся.
              <br />
              Вы уверены, что хотите внести изменения?
            </div>
          ) : (
            <div>
              Некоторые собранные в рамках мероприятия документы уже были
              <br />
              проверены экспертами без применения балльной системы оценивания.
              <br />
              При ее активации эти документы будут повторно направлены экспертам
              <br />
              на проверку.
              <br />
              Вы уверены, что хотите внести изменения?
            </div>
          )}
          <div>
            <Box flex mt={32}>
              <Button
                label="Подтвердить"
                gradient={true}
                clicked={() => {
                  if (editCriteriaModal) {
                    setVisibleModal(false);
                  } else {
                    setToggle(!toggle);
                  }
                  setIsOpenModal(false);
                  setEditCriteriaModal(false);
                }}
              />
              <Box mr={16} />
              <Button
                label="Отмена"
                withoutBackground
                clicked={() => {
                  setIsOpenModal(false);
                  setEditCriteriaModal(false);
                }}
              />
            </Box>
          </div>
        </>
      </ContentModal>
    </>
  );
};
