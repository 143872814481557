import React from 'react';
import classNames from 'classnames/bind';

//ui
import styles from './CheckboxButtonType.module.scss';
import { CheckboxSurvey } from '../../Form/CheckBox/CheckboxSurvey/CheckboxSurvey';

import { Survey } from '../../../../models/survey/survey';

const cx = classNames.bind(styles);

type RadiobuttonProps = {
  questionTitle: string;
  questions: Survey[];
  name: string;
  numberOfResponses: number;
  disabled?: boolean;
};

export type QuestionItemsProps = {
  key: string;
  value: string;
};

export const CheckboxButtonType = (props: RadiobuttonProps) => {
  const { questionTitle, questions, name, numberOfResponses, disabled } = props;

  const listQuestion = Array(numberOfResponses)
    .fill(0)
    .map((e, i) => {
      return { key: i + 1, value: i + 1 };
    });

  const values = listQuestion.map((option, index) => {
    return option.value;
  });

  return (
    <div className={cx('checkboxButtonType')}>
      <div className={cx('checkboxButtonType__title')}>{questionTitle}</div>
      <div className={cx('checkboxButtonType__item')}>
        <CheckboxSurvey values={values} name={name} disabled={disabled} />
      </div>
    </div>
  );
};
