import React from 'react';

//  ui
import { Typography } from '../Typography';

import styles from './TooltipComponent.module.scss';

type TooltipProps = {
  text: string;
  children: React.ReactNode;
};

export const TooltipComponent = ({
  text,
  children,
}: TooltipProps): JSX.Element => {
  return (
    <div className={styles.container}>
      {children}
      <div className={styles.tooltip}>
        <div className={styles.message}>
          <Typography color="main" size="s" customLineHeight={22}>
            {text}
          </Typography>
        </div>
      </div>
    </div>
  );
};
