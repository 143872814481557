import React from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import styles from './DocumentExpertPopupEdit.module.scss';
import {
  useApproveExpertDocumentMutation,
  useApproveExpertDocumentStatusMutation,
} from '../../../../../store/VbudusheeAPI';
import { object } from 'yup';

//ui
import { Textarea } from '../../../../ui/Form/Textarea/Textarea';
import { Button } from '../../../../ui/Button';
import { useNavigate } from 'react-router-dom';
import { frontendRoutes } from '../../../../../utils/router/routes';

type ProfilePopupContentTypes = {
  openPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setModalStatus: React.Dispatch<React.SetStateAction<string>>;
  documentId?: number;
  documentStatus: string;
  routId?: string | number;
};

export const DocumentExpertPopupEdit = ({
  setModalOpen,
  documentId,
  documentStatus,
  setModalStatus,
  routId,
}: ProfilePopupContentTypes) => {
  const [approveDocument, { isLoading, error, isSuccess }] =
    useApproveExpertDocumentStatusMutation();

  const navigate = useNavigate();

  const handleCancel = async () => {
    setModalOpen(false);
    setModalStatus('');
  };
  //TODO необходимо добавить нужный id документа + правильно собирать estimates
  const handleAccess = async (message: string) => {
    await approveDocument({
      id: documentId,
      body: {
        document_status: documentStatus,
        document_comment: formik.values.message,
      },
    });
    navigate(`${frontendRoutes.publicEventsCatalog.events}/${routId}`);

    setModalOpen(false);
    setModalStatus('');
  };
  const handleSubmit = async (values: typeof initialValues) => {
    await handleAccess(values.message);
  };

  const initialValues = {
    message: '',
  };
  const documentValidationSchema = object({
    // text: string(),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: documentValidationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <div className={styles.popup}>
          <div className={styles.divider} />
          <Textarea
            name={'message'}
            height={250}
            placeholder={'Введите текст'}
            maxLength={2000}
          />
          <div className={styles.buttons}>
            <div>
              <Button label="Подтвердить" color={'gradient'} type="submit" />
            </div>
            <div className={styles.buttons__cancel} onClick={handleCancel}>
              <Button label="Назад" withoutBackground />
            </div>
          </div>
        </div>
      </Form>
    </FormikContext.Provider>
  );
};
