import { useField } from 'formik';
import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import styles from './InputTeamInformation.module.scss';

const cx = classNames.bind(styles);

type InputTeamInformationTypes = {
  name: string;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  modifiedClassName?: boolean;
  value?: string;
  className?: string;
  onChange?: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
};

export const InputTeamInformation = ({
  name,
  placeholder,
  type,
  disabled,
  fullWidth,
  value,
  className,
  onChange,
}: InputTeamInformationTypes) => {
  const [field, { error, touched }] = useField<string>(name);

  const isInvalid = useMemo(() => {
    return Boolean(error) && touched;
  }, [error, touched]);

  return (
    <div
      className={cx('input', {
        input_invalid: isInvalid,
        input_disabled: disabled,
        input_fullwidth: fullWidth,
      })}
    >
      <input
        {...field}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
        className={className}
      />
      {isInvalid && <div className={cx('input__error')}>{error}</div>}
    </div>
  );
};
