import React, { useMemo, useState } from 'react';
import classNames from 'classnames/bind';

import { MethodDictItemSurvey } from '../../../../../../models/research';
import { declOfNum } from '../../../../../../utils/basic';

//  ui
import { Typography } from '../../../../../ui/Typography';
import { Box } from '../../../../../ui/Box';
import { IconResearchArrow } from '../../../../../ui/Icons/Research/IconResearchArrow';

import styles from './QuestionBlockList.module.scss';
import { SurveyQuestion } from '../../../../../ui/SurveyQuestion/SurveyQuestion';
import {
  GroupQuestions,
  Survey,
  SurveyQuestionType,
} from '../../../../../../models/survey/survey';
import { FieldArray, Formik, useFormik } from 'formik';
const cx = classNames.bind(styles);

type QuestionBlockListProps = {
  survey: MethodDictItemSurvey;
  required: boolean;
};

export const QuestionBlockList = ({
  survey,
  required,
}: QuestionBlockListProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  const surveyToRender = useMemo(() => {
    if (!survey?.survey) return;
    return Object.values(survey.survey);
  }, [survey]);

  const groupQuestion: Survey[] = [];
  const singleQuestion: Survey[] = [];

  Object.values(survey.survey).filter((question, index) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (question.group || question.field.min_label) {
      if (!question.group) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return groupQuestion.push({
          ...question,
          group: question.text,
          index: index + 1,
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return groupQuestion.push({ ...question, index: index + 1 });
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return singleQuestion.push(question);
    }
  });

  const groupQuestions: GroupQuestions[] = [];

  const groupsStr = groupQuestion
    .map((x) => x.group)
    .filter((v, i, a) => a.indexOf(v) === i);

  for (let i = 0; i < groupsStr.length; i++) {
    const arrWithGroup = groupQuestion.filter(
      (item) => item.group === groupsStr[i]
    );
    const res: GroupQuestions = {
      group: groupsStr[i],
      items: arrWithGroup,
    };
    groupQuestions.push(res);
  }

  const singleQuestionToGroup: GroupQuestions[] = [
    {
      group: 'single_to_group',
      items: singleQuestion.filter(
        (item) => item.field.type_label === 'integer_from_to'
      ),
    },
  ];

  return (
    <Formik initialValues={{}} onSubmit={() => {}}>
      {() => (
        <div className={styles.wrapper}>
          <div className={styles.header} onClick={() => setOpen(!open)}>
            <div className={styles.left}>
              <Typography size="m" color="black" fontWeight={600}>
                {survey.name}
                {required && <span className={styles.requiredMarker}>*</span>}
              </Typography>

              {!open && surveyToRender?.length ? (
                <Box mt={4}>
                  <Typography color="gray" size="s">
                    {surveyToRender.length}{' '}
                    {declOfNum(surveyToRender.length, [
                      'вопрос',
                      'вопроса',
                      'вопросов',
                    ])}
                  </Typography>
                </Box>
              ) : (
                <Box />
              )}
            </div>
            <div />
            <div className={styles.right}>
              <div
                className={cx('icon', {
                  icon_open: open,
                })}
              >
                <IconResearchArrow />
              </div>
            </div>
          </div>
          <div
            className={cx('body', {
              body_open: open,
            })}
          >
            <p className={styles.methodInstruction}>
              {Object.values(survey?.instruction)[0]?.text}
            </p>

            <div className={styles.questions}>
              {/*одиночный вопрос объединенный в групповой*/}
              {singleQuestionToGroup.map((question, index) => {
                if (question.items.find((i) => !i.field.min_label)) {
                  return (
                    <SurveyQuestion
                      name={`${index}`}
                      type={'group'}
                      questionTitle={''}
                      questions={question.items}
                      key={index}
                      position={'vertical'}
                      disabled
                    />
                  );
                }
              })}
              {/*одиночный вопрос*/}
              {singleQuestion.map((question, index) => {
                if (question.field.choices) {
                  return (
                    <SurveyQuestion
                      name={`${index}`}
                      type={question.field.type_label as SurveyQuestionType}
                      questionTitle={question.text}
                      question={question}
                      questions={singleQuestion}
                      key={index}
                      position={'vertical'}
                      max={question.field.max}
                      min={question.field.min}
                      disabled
                    />
                  );
                }
              })}

              {/*групповой вопрос */}
              {groupQuestions.map((question, index) => {
                if (
                  question.items.find(
                    (i) => !i.field.min_label && !i.field.choices
                  )
                ) {
                  return (
                    <SurveyQuestion
                      name={`${index}`}
                      type={'group'}
                      questionTitle={question.group || ''}
                      questions={question.items}
                      key={index}
                      position={'vertical'}
                      disabled
                    />
                  );
                }
              })}
              {/*групповой вопрос */}
              {groupQuestions.map((question, index) => {
                if (
                  question.items.find(
                    (i) => i.field.min_label && i.field.choices
                  )
                ) {
                  return question.items.map((i) => {
                    return (
                      <SurveyQuestion
                        name={`${index}`}
                        type={'choice'}
                        questionTitle={question.group || ''}
                        question={i}
                        key={index}
                        position={'vertical'}
                        disabled
                        questions={[]}
                      />
                    );
                  });
                }
              })}
              {groupQuestions.map((question, index) => {
                if (
                  question.items.find(
                    (i) => !i.field.min_label && i.field.choices
                  )
                ) {
                  return question.items.map((i, iIndex) => {
                    return (
                      <SurveyQuestion
                        name={`${index}`}
                        type={'choice'}
                        questionTitle={
                          question.items[iIndex].text || question.group || ''
                        }
                        question={i}
                        key={iIndex + index}
                        position={'vertical'}
                        disabled
                        questions={[]}
                      />
                    );
                  });
                }
              })}
              {/*групповой вопрос со шкалой от и до*/}
              {groupQuestions.map((question, index) => {
                if (
                  question.items.find(
                    (i, iIndex) => i.field.min_label && !i.field.choices
                  )
                ) {
                  return (
                    <SurveyQuestion
                      name={`${index}`}
                      type={'scale_group'}
                      questionTitle={question.group || ''}
                      questions={question.items}
                      key={index}
                      position={'horizontal'}
                      disabled
                    />
                  );
                }
              })}
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};
