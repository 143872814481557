import { useMemo, useState, useRef, useEffect } from 'react';
import { useField } from 'formik';
import classNames from 'classnames/bind';

//  ui
import { IconArrow } from '../../Icons/IconArrow';
import { CheckBox } from '../CheckBox';

import styles from './SelectSearchRegions.module.scss';
import { Simulate } from 'react-dom/test-utils';
import { IconCross } from '../../Icons/IconCross';
const cx = classNames.bind(styles);

type optionProps = {
  region_number: string;
  region_name: string;
};

type SelectSearchProps = {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  options: optionProps[] | [];
  fullWidth?: boolean;
  redRequired?: boolean;
};

export const SelectSearchRegions = ({
  name,
  placeholder,
  disabled,
  options,
  fullWidth,
  redRequired,
}: SelectSearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [expanded, setExpanded] = useState(false);
  const [search, setSearch] = useState('');
  const [field, { error, touched }, { setValue, setTouched }] =
    useField<any[]>(name);

  const isInvalid = useMemo(() => {
    return Boolean(error && touched);
  }, [error, touched]);

  const rootEl = useRef<any>(null);

  const allRegions = useMemo(() => {
    return field.value.length === options.length ? true : false;
  }, [field.value, options]);

  const handleBlur = () => {
    setTouched(true);
  };

  const handleClick = (value: optionProps) => {
    field.value.find((item) => item.region_number === value.region_number)
      ? setValue(
          field.value.filter((el) => el.region_number !== value.region_number),
          true
        )
      : setValue([...field.value, value], true);
  };

  const filteredOptions =
    search === ''
      ? options
      : options.filter((option) => {
          const searchExp = new RegExp(search, 'gmi');
          return option.region_name.search(searchExp) !== -1;
        });

  useEffect(() => {
    if (!expanded) {
      const res = options.find((option) => option.region_number === search);

      if (!res) {
        setSearch('');
      }
    }
    const clickOutsideElement = (e: any) => {
      if (!rootEl.current) return;
      if (!rootEl.current.contains(e.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener('click', clickOutsideElement);

    return () => {
      document.removeEventListener('click', clickOutsideElement);
    };
  }, [expanded]);

  return (
    <div className={cx('container')}>
      <div
        className={cx('input', {
          input_invalid: isInvalid,
          input_disabled: disabled,
          input_fullwidth: fullWidth,
        })}
        ref={rootEl}
        onFocus={() => setExpanded(true)}
      >
        <input
          placeholder={!redRequired ? placeholder : ''}
          disabled={disabled}
          ref={inputRef}
          onChange={(e) => setSearch(e.target.value)}
          onBlur={handleBlur}
          value={
            options.find((option) => option.region_number === search)
              ?.region_name ?? search
          }
        />
        {redRequired && !search && (
          <span className={styles.input__required}>
            {placeholder?.slice(0, placeholder.length - 1)}
            <span className={styles.input__required__sign}>
              {placeholder?.slice(placeholder.length - 1, placeholder.length)}
            </span>
          </span>
        )}
        {isInvalid && <div className={cx('input__error')}>{error}</div>}
        <div
          className={cx('select', {
            select_hidden: filteredOptions.length === 0 || !expanded,
          })}
        >
          <div className={styles.select__buttons}>
            <button
              type="button"
              className={cx('select__button', {
                select__button_active: allRegions,
              })}
              onClick={() => {
                allRegions ? setValue([], true) : setValue(options, true);
              }}
            >
              Выбрать все
              {allRegions && (
                <div style={{ marginLeft: '9px' }}>
                  <IconCross />
                </div>
              )}
            </button>
          </div>
          <div className={styles.select__options}>
            {filteredOptions.map((option) => (
              <div
                className={styles.select__item}
                key={option.region_number}
                onClick={() => {
                  handleClick(option);
                }}
              >
                <CheckBox
                  checked={
                    field.value.length
                      ? Boolean(
                          field.value.find(
                            (item) =>
                              item.region_number === option.region_number
                          )
                        )
                      : false
                  }
                  onChange={() => {
                    handleClick(option);
                  }}
                  themeGradient={true}
                />
                {option.region_name}
              </div>
            ))}
          </div>
        </div>
        <div
          className={cx('select__arrow', {
            select__arrow_expanded: expanded,
          })}
        >
          <div
            className={cx('', {
              select__arrow_expanded: expanded,
            })}
            onClick={() => {
              setExpanded(true);
              if (inputRef.current != null) {
                inputRef.current.focus();
              }
            }}
          >
            <IconArrow />
          </div>
        </div>
      </div>
      {field.value.length > 0 && (
        <div className={styles.select__regions}>
          {field.value.map((item) => (
            <div className={styles.select__region} key={item.region_number}>
              {item.region_name}
              <div
                style={{ marginLeft: '9px', cursor: 'pointer' }}
                onClick={() => {
                  !disabled &&
                    setValue(
                      field.value.filter(
                        (el) => el.region_number !== item.region_number
                      ),
                      true
                    );
                }}
              >
                {!disabled && <IconCross />}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
