import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableHeader } from '../../../../models/components/table';
import { Team } from '../../../../models/pages/teams';
import { teamResearchFiltersInitialValues } from '../../../../models/validations/schemas/validations';
import { frontendRoutes } from '../../../../utils/router/routes';
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { HMenu } from '../../../ui/HMenu';
import { TeamDescription } from '../TeamDescription/TeamDescription';
import { TeamResearchFilters } from './TeamResearchFilters';

import styles from './TeamResearch.module.scss';

import defaultTeamLogo from '../../../../assets/images/defaultTeamLogo.svg';

type TeamResearchTypes = {
  team?: Team;
};

export const TeamResearch = ({ team }: TeamResearchTypes) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<
    typeof teamResearchFiltersInitialValues
  >(teamResearchFiltersInitialValues);
  const paginationOptions = useMemo(() => {
    return { ...filters, page };
  }, [filters, page]);

  const headers: TableHeader[] = [
    { label: 'Название исследования', width: '29%' },
    { label: 'Создатель исследования', width: '28%' },
    { label: 'Сроки проведения', width: '22%' },
    { label: 'Статус исследования', width: '21%' },
  ];

  const menuLinks = [
    {
      label: 'Мероприятия команды',
      onClick: () =>
        navigate(`${frontendRoutes.teams.team.events}/${team?.team_id}`),
      active: false,
      access: true,
    },
    {
      label: 'Исследования команды',
      onClick: () =>
        navigate(`${frontendRoutes.teams.team.research}/${team?.team_id}`),
      active: true,
      access: true,
    },
    {
      label: 'Документы команды',
      onClick: () =>
        navigate(`${frontendRoutes.teams.team.docs}/${team?.team_id}`),
      active: false,
      access: true,
    },
    {
      label: 'Участники команды',
      onClick: () =>
        navigate(`${frontendRoutes.teams.team.participants}/${team?.team_id}`),
      active: false,
      access: true,
    },
  ];

  return (
    <div className={styles.team}>
      <div className="local-container">
        <Breadcrumbs
          links={[
            { title: 'Каталог команд', link: frontendRoutes.teams.teams },
            { title: team?.team_name ?? '' },
          ]}
          homeLink="/"
        />
      </div>
      <TeamDescription
        team={team}
        team_id={team?.team_id}
        team_name={team?.team_name}
        team_description={team?.team_description}
        team_creator={{
          last_name: team?.team_creator.last_name,
          middle_name: team?.team_creator.middle_name,
          first_name: team?.team_creator.first_name,
          organization_name: team?.team_creator.organization_name,
        }}
        team_user_role={team?.team_user_role}
        team_creation_date={team?.team_creation_date}
        team_members_count={team?.team_members_count}
        team_members={[]}
        team_icon_path={
          team?.team_icon_path !== '' ? team?.team_icon_path : defaultTeamLogo
        }
      />
      <div className={styles.team__navigation}>
        <HMenu links={menuLinks} />
      </div>
      <div className={styles.team__filters}>
        <TeamResearchFilters setFilters={setFilters} />
      </div>
    </div>
  );
};
