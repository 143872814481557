import { Box } from '../../../../../ui/Box';

import styles from './SurveyCard.module.scss';
import { useDeleteSurveyRequestMutation } from '../../../../../../store/VbudusheeAPI';

type Props = {
  surveyId: number;
  surveyName: string;
};

export const SurveyCard = ({ surveyId, surveyName }: Props) => {
  const [deleteSurvey, { isLoading: loading, error }] =
    useDeleteSurveyRequestMutation();

  const deleteParticipantHandler = async () => {
    const result = await deleteSurvey({ id: String(surveyId) });
    if ('data' in result) {
      const a = 0;
    }
  };

  return (
    <div>
      <div className={styles.card_wraper__description}>{surveyName}</div>
      <Box flex>
        <a
          className={styles.card_wraper__link}
          onClick={deleteParticipantHandler}
        >
          Удалить
        </a>
      </Box>
      <Box mb={20} />
    </div>
  );
};
