export function downloadBlobFile(blob: Blob, fileName: string): void {
  const url = URL.createObjectURL(blob);
  const aHTMLElement = document.createElement('a');
  aHTMLElement.href = url;
  aHTMLElement.download = fileName;

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 150);
  };

  aHTMLElement.addEventListener('click', clickHandler, false);

  aHTMLElement.click();

  aHTMLElement.removeEventListener('click', clickHandler);
  aHTMLElement.remove();
}

export function downloadDetailsStatsRow(
  researchId: string,
  fileName?: string
): Promise<void> {
  const myHeaders = new Headers();

  myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };

  return fetch(`/api/research/${researchId}/stats/raw-excel`, requestOptions)
    .then((response) => response.blob())
    .then((blob) => {
      downloadBlobFile(
        blob,
        fileName ? `${fileName}.xlsx` : 'первичные данные.xlsx'
      );
    });
}

export function downloadResearchComparisonFile(
  researches: number[],
  fileName?: string
): Promise<void> {
  const myHeaders = new Headers();

  myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
  };

  return fetch(
    `/api/versus/raw-excel?researches[]=${researches[0]}&researches[]=${researches[1]}`,
    requestOptions
  )
    .then((response) => response.blob())
    .then((blob) => {
      downloadBlobFile(
        blob,
        fileName ? `${fileName}.xlsx` : 'первичные данные.xlsx'
      );
    });
}
