import styles from './LoaderFullPage.module.scss';

type LoaderFullPageProps = {
  children: JSX.Element;
};

export const LoaderFullPage = ({
  children,
}: LoaderFullPageProps): JSX.Element => {
  return <div className={styles.fullPage}>{children}</div>;
};
