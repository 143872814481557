export const ORGANIZATION_CATEGORY = [
  { key: '85.1', value: 'Общее образование' },
  { key: '85.21', value: 'Среднее профессиональное образование' },
  { key: '85.22', value: 'Высшее образование' },
  { key: '85.3', value: 'Профессиональное обучение' },
  { key: '85.4', value: 'Дополнительное образование' },
  { key: '00', value: 'Другое' },
];

export const OCCUPATION = [
  { key: 'administrator', value: 'Руководитель / администратор' },
  { key: 'teacher', value: 'Преподаватель / педагог / психолог' },
  { key: 'student', value: 'Обучающийся' },
  { key: 'official', value: 'Государственный / муниципальный служащий' },
  { key: 'parent', value: 'Родитель' },
  { key: 'other', value: 'Другое' },
];
