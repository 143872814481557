import classNames from 'classnames/bind';

//ui
import { RadioButton } from '../../Form/RadioButton';
import { Survey } from '../../../../models/survey/survey';
import './index.css';
import styles from './RadioButtonGroupType.module.scss';
import { FieldArray } from 'formik';
const cx = classNames.bind(styles);

type RadioButtonGroupProps = {
  questionTitle: string;
  questions: Survey[];
  name: string;
  disabled?: boolean;
};

export const RadioButtonGroupType = (props: RadioButtonGroupProps) => {
  const { questionTitle, questions, name, disabled } = props;

  const numberOfResponses =
    questions[0].field.max! - questions[0].field.min! + 1;

  const scale: any[] = [];
  for (let i = 1; i <= numberOfResponses; i++) {
    scale.push(i);
  }
  // eslint-disable-next-line array-callback-return
  questions.map((question) => {
    // eslint-disable-next-line no-console
    // console.log(question, 1);
  });

  return (
    <FieldArray name={name}>
      {(fieldArrayProps) => {
        const { push, remove, name } = fieldArrayProps;
        // const { values } = form;
        // const { question_input_type, question_answers } = values;

        return (
          <div className={cx('radioButtonGroupType')}>
            <div className={cx('radioButtonGroupType__title')}>
              {questionTitle}
            </div>
            <div className={cx('radioButtonGroupType__items')}>
              <div className={cx('divider')}>
                {scale.map((i, index) => (
                  <div key={index} className={cx('divider__item')}>
                    {`${index + questions[0].field.min!}`}
                  </div>
                ))}
              </div>
              <div className={cx('line')} />

              {questions.map((question, index) => {
                return (
                  <div key={index} className={cx('item')}>
                    <div className={cx('item__text')}>
                      <p className={cx('item__text_numberQuestion')}>
                        {`${question.index ? question.index : index + 1}`}
                      </p>
                      <p className={cx('item__text_title')}>{question.text}</p>
                    </div>
                    <RadioButton
                      name={`${name}[${index}]`}
                      isRow={true}
                      withoutVisibleValue
                      number={question.field.max! - question.field.min! + 1}
                      disabled={disabled}
                      minValue={question.field.min!}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    </FieldArray>
  );
};
