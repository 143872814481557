// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResearchDetailsReportNoChosen_container__od7yA {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ResearchDetailsReportNoChosen_wrapper__8Jlcq {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  text-align: center;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/research/ResearchDetails/ResearchDetailsReport/ResearchDetailsReportNoChosen/ResearchDetailsReportNoChosen.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,uBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,SAAA;AACJ","sourcesContent":[".container {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n}\n\n.wrapper {\n    margin-top: 50px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    max-width: 400px;\n    text-align: center;\n    gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ResearchDetailsReportNoChosen_container__od7yA`,
	"wrapper": `ResearchDetailsReportNoChosen_wrapper__8Jlcq`
};
export default ___CSS_LOADER_EXPORT___;
