// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Popup_popup__71Aws {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  z-index: 3;
  overflow-y: scroll;
}
.Popup_popup__overlay__P5qI2 {
  position: fixed;
  background-color: hsla(0, 0%, 0%, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}
.Popup_popup__content__8OfXW {
  padding: 30px;
  z-index: 3;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Popup/Popup.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,UAAA;EACA,kBAAA;AACF;AACE;EACE,eAAA;EACA,sCAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;AACJ;AAEE;EACE,aAAA;EACA,UAAA;EACA,YAAA;AAAJ","sourcesContent":[".popup {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  min-height: 100vh;\n  display: flex;\n  justify-content: center;\n  z-index: 3;\n  overflow-y: scroll;\n\n  &__overlay {\n    position: fixed;\n    background-color: hsla(0, 0%, 0%, 0.5);\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    z-index: 3;\n  }\n\n  &__content {\n    padding: 30px;\n    z-index: 3;\n    margin: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `Popup_popup__71Aws`,
	"popup__overlay": `Popup_popup__overlay__P5qI2`,
	"popup__content": `Popup_popup__content__8OfXW`
};
export default ___CSS_LOADER_EXPORT___;
