import React from 'react';
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { RegForm } from './RegForm';
import styles from './Registration.module.scss';

export const RegistrationCompany = () => {
  return (
    <div className={styles.reg}>
      <div className="outer-container">
        <Breadcrumbs links={[{ title: 'Регистрация' }]} homeLink="/" />
      </div>
      <span className={styles.reg__header}>Регистрация</span>
      <div className={styles.reg__form}>
        <RegForm />
      </div>
    </div>
  );
};
