import { useMemo, useState } from 'react';

import {
  EventDocuments,
  EventDocumentsRequest,
  EventDocumentsResponse,
  Expert,
} from '../../../../../models/pages/events';
import {
  formatDateStr,
  validateDateFromCalendar,
} from '../../../../../utils/dates';
import styles from './PublicEventDocuments.module.scss';

//  ui
import { useNavigate } from 'react-router-dom';
import { frontendRoutes } from '../../../../../utils/router/routes';
import { TableExpanded } from '../../../../ui/Table';
import { Loader } from '../../../../ui/Loader';
import { Pagination } from '../../../../ui/Pagination';
import {
  useGetCurrentUserQuery,
  useGetEventDocumentsExpertQuery,
  useGetEventDocumentsQuery,
} from '../../../../../store/VbudusheeAPI';
import { TableHeader } from '../../../../../models/components/table';
import { Box } from '../../../../ui/Box';
import { Typography } from '../../../../ui/Typography';
import { IconBigArrow } from '../../../../ui/Icons/IconBigArrow';
import { PublicEventDocumentsFilter } from '../PublicEventDocumentsFilter';
import { GetDictionaryResponse } from '../../../../../models/research';
import { PublicEventDocumentsFilterMember } from '../PublicEventDocumentsFilterMember';
import { materialDocsExpertFiltersInitialValues } from '../../../../../models/validations/schemas/validations';

type Expanded = {
  itemTitle: string;
  itemData: string;
  fullWidth?: boolean;
  textEllipsis?: boolean;
  scroll?: boolean;
};

type PublicEventDocumentsProps = {
  eventId: string;
  role: string[] | null;
  dictionary?: GetDictionaryResponse;
};

export const PublicEventDocuments = ({
  eventId,
  role,
  dictionary,
}: PublicEventDocumentsProps): JSX.Element => {
  const [page, setPage] = useState(1);
  const [isHide, setIsHide] = useState(false);
  const [filters, setFilters] = useState<
    typeof materialDocsExpertFiltersInitialValues
  >(materialDocsExpertFiltersInitialValues);
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore

  const paginationOptions: EventDocumentsRequest = useMemo(() => {
    return {
      // document_creator: !role?.includes('expert') ? user.id : undefined,
      document_status: undefined,
      document_upload_date_start: filters.dates
        ? validateDateFromCalendar(filters.dates.split(';')[0])
        : undefined,
      document_upload_date_end: filters.dates
        ? validateDateFromCalendar(filters.dates.split(';')[1])
        : undefined,
      document_name: filters.search || undefined,
      document_type: filters.typeDocument || undefined,
      document_creator: filters.author || undefined,
      professional_category: filters.prof || undefined,
      page,
    };
  }, [page, filters]);

  const {
    data: dataDefault,
    isFetching: isFetchingDefault,
    isError: isErrorDefault,
  } = useGetEventDocumentsQuery(
    {
      id: eventId,
      params: paginationOptions,
    },
    { skip: role?.includes('expert') }
  );

  const {
    data: dataExpert,
    isFetching: isFetchingExpert,
    isError: isErrorExpert,
  } = useGetEventDocumentsExpertQuery(
    {
      id: eventId,
      params: paginationOptions,
    },
    { skip: !role?.includes('expert') }
  );

  const { data: user } = useGetCurrentUserQuery({});

  const data: EventDocumentsResponse | undefined = useMemo(() => {
    if (dataDefault || dataExpert) {
      return dataExpert ? dataExpert : dataDefault;
    }
  }, [dataDefault, dataExpert]);

  const eventsData = data?.data || [];
  const totalPages = data?.meta?.last_page || 1;
  const currentPage = data?.meta?.current_page;

  // const handleSettingEvent = (): void => {
  //   navigate(
  //     `${frontendRoutes.settingsEvent.settingsEvent}/${event?.event_id}`
  //   );
  // };

  const expertName = (expert: Expert) => {
    if (expert) {
      return (
        `${expert.last_name ?? ''}` +
        ' ' +
        `${expert.first_name ?? ''}` +
        ' ' +
        `${expert.middle_name ?? ''}`
      );
    } else {
      return '';
    }
  };

  const author = (name: string) => {
    if (user) {
      if (
        name ===
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        `${user?.user.first_name || ''} ${user?.user.last_name || ''} ${
          user?.user.middle_name || ''
        }`
      ) {
        return 'Вы';
      } else {
        return name;
      }
    } else {
      return name;
    }
  };

  const expanded = (pDocument: EventDocuments) => {
    let res: Expanded[] | [];
    if (role && role.includes('owner')) {
      res = [
        {
          itemTitle: 'Организация автора документа',
          itemData: `${
            pDocument.document_creator.organization_name || 'Нет данных'
          }`,
        },
        {
          itemTitle: 'Профессиональная категория автора',
          itemData: `${
            dictionary?.user.professional_category[
              pDocument.document_creator.professional_category || ''
            ] || 'Нет данных'
          }`,
        },
        {
          itemTitle: 'Имя эксперта',
          itemData: pDocument?.document_expert
            ? expertName(pDocument?.document_expert)
            : '—',
        },
        {
          itemTitle: 'Тематические теги',
          itemData: `${
            pDocument.document_theme.length > 0
              ? pDocument.document_theme.join(', ')
              : 'Нет данных'
          }`,
          textEllipsis: true,
          scroll: true,
        },
        {
          itemTitle: 'Оценка',
          itemData: `${pDocument.total_estimate || '0'}`,
        },
      ];
    } else {
      res = [
        {
          itemTitle: 'Организация автора документа',
          itemData: `${
            pDocument.document_creator.organization_name || 'Нет данных'
          }`,
        },
        {
          itemTitle: 'Профессиональная категория автора',
          itemData: `${
            dictionary?.user.professional_category[
              pDocument.document_creator.professional_category || ''
            ] || 'Нет данных'
          }`,
        },
        {
          itemTitle: 'Оценка',
          itemData: `${pDocument.total_estimate || '0'}`,
        },
        {
          itemTitle: 'Тематические теги',
          itemData: `${
            pDocument.document_theme.length > 0
              ? pDocument.document_theme.join(', ')
              : 'Нет данных'
          }`,
          textEllipsis: true,
          scroll: true,
        },
      ];
    }

    if (!role?.includes('expert')) {
      res.push({
        itemTitle: 'Комментарий эксперта',
        itemData: `${pDocument.document_comment || '—'}`,
        fullWidth: true,
      });
    }

    return res;
  };

  const headers: TableHeader[] = useMemo(() => {
    if (role?.includes('owner')) {
      return [
        { label: 'Название документа', width: '30%' },
        { label: 'Автор документа', width: '30%' },
        { label: 'Дата загрузки', width: '20%' },
        { label: 'Статус документа', width: '20%' },
      ];
    }
    return [
      { label: 'Название документа', width: '40%' },
      { label: 'Автор документа', width: '40%' },
      { label: 'Дата получения', width: '20%' },
    ];
  }, [role]);

  return (
    <div>
      <Box flex justifyContent={'space-between'} mt={10}>
        <Typography color="main" fontWeight={600} size="xxxl">
          {role?.includes('expert')
            ? 'Документы на проверку'
            : 'Список документов мероприятия'}
        </Typography>
        <div
          className={`${styles.icon} ${isHide ? styles.iconDown : ''}`}
          onClick={() => setIsHide(!isHide)}
        >
          <IconBigArrow />
        </div>
      </Box>
      {!isHide && (
        <>
          <div className={styles.team__filters}>
            {role?.includes('expert') ? (
              <PublicEventDocumentsFilter setFilters={setFilters} />
            ) : (
              <PublicEventDocumentsFilterMember />
            )}
          </div>
          <div>
            <TableExpanded headers={headers}>
              {isFetchingExpert || isFetchingDefault ? (
                <Loader.FullPage>
                  <Loader />
                </Loader.FullPage>
              ) : isErrorDefault || isErrorExpert || eventsData.length < 1 ? (
                <TableExpanded.NoTable
                  isError={isErrorExpert || isErrorDefault}
                  errorTitle="Ошибка загрузки данных"
                  errorText="Мы не смогли загрузить данные таблицы. Попробуйте перезагрузить страницу или выбрать другие фильтры."
                  dataLength={eventsData.length || 0}
                  dataLengthTitle="Данных пока нет"
                  dataLengthText={
                    role?.includes('expert')
                      ? 'Документы на проверку отсутствую'
                      : 'Загруженные документы отсутствуют'
                  }
                />
              ) : (
                <>
                  {data?.data.map((pDocument: EventDocuments) => (
                    <TableExpanded.Row
                      key={pDocument.document_id}
                      headers={headers}
                    >
                      <TableExpanded.Col>
                        <span
                          className="long-string"
                          onClick={() =>
                            navigate(
                              `${frontendRoutes.document.inner}/${pDocument.document_id}`
                            )
                          }
                        >
                          {pDocument.document_name || 'Нет данных'}
                        </span>
                      </TableExpanded.Col>
                      <TableExpanded.Col>
                        <span className="long-string">
                          {author(`
                                ${pDocument?.document_creator?.first_name} 
                                ${pDocument?.document_creator?.middle_name} 
                                ${pDocument?.document_creator?.last_name}
                              `)}
                        </span>
                      </TableExpanded.Col>
                      <TableExpanded.Col>
                        <span className="long-string">
                          {formatDateStr(pDocument?.document_date, {
                            isDateOnly: true,
                          })}
                        </span>
                      </TableExpanded.Col>
                      <>
                        {role && role.includes('owner') && (
                          <TableExpanded.Col>
                            <span className="long-string">
                              {dictionary?.documents.document_status[
                                pDocument?.document_status || ''
                              ] || 'Нет данных'}
                            </span>
                          </TableExpanded.Col>
                        )}
                      </>
                      <TableExpanded.Expanded items={expanded(pDocument)} />
                    </TableExpanded.Row>
                  ))}
                </>
              )}
            </TableExpanded>
          </div>
          <div className="pagination">
            {(!isFetchingExpert || !isFetchingDefault) && totalPages > 1 && (
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                setPage={setPage}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
