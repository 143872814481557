import React, { useState } from 'react';

import styles from './NumberFilter.module.scss';

type NumberFilterProps = {
  placeholder?: string;
  disabled?: boolean;
  setOuterValue?: (value: string) => void;
};

export const NumberFilter = ({
  placeholder,
  disabled,
  setOuterValue,
}: NumberFilterProps): JSX.Element => {
  const [value, setValue] = useState('');
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    setValue(value);
    if (setOuterValue) setOuterValue(value);
  };
  return (
    <input
      type="number"
      placeholder={placeholder}
      disabled={disabled}
      onChange={handleChange}
      value={value}
      className={styles.numberFilter}
    />
  );
};
