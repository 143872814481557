import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { frontendRoutes } from '../../../utils/router/routes';
import { useGetEventQuery, useGetTagsQuery } from '../../../store/VbudusheeAPI';
import { useAuthorized } from '../../../hooks/useAuthorized';

//  ui
import { Breadcrumbs } from '../../ui/Breadcrumbs';
import { HMenu } from '../../ui/HMenu';
import { SettingsEventForm } from './SettingsEventForm';

import styles from './SettingsEvent.module.scss';
import { useDispatch } from 'react-redux';
import { TransformTag } from '../../../models/tags/tag';
import { getTags } from '../../../store/tagsSlice';

export const SettingsEvent = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { isAuthorized } = useAuthorized();

  const { data: tagsData } = useGetTagsQuery({});

  const { data: event } = useGetEventQuery({
    id: params.eventId ?? '',
  });

  const location = useLocation();
  const dispatch = useDispatch();
  const isMaterialsPage = location.pathname === frontendRoutes.materials.events;

  const transformedTagsData: TransformTag[] | undefined = tagsData?.map(
    function (obj) {
      const result = {
        option: obj.name,
        value: obj.id.toString(),
      };
      return result;
    }
  );

  useEffect(() => {
    if (tagsData) {
      dispatch(getTags(tagsData));
    }
  }, [isMaterialsPage, dispatch, tagsData]);

  const menuLinks = [
    {
      label: 'Редактирование мероприятия',
      onClick: () =>
        navigate(
          `${frontendRoutes.settingsEvent.settingsEvent}/${params.eventId}`
        ),
      active: true,
      access: true,
    },
    {
      label: 'Доступы и роли',
      onClick: () =>
        navigate(
          `${frontendRoutes.settingsEvent.accessesAndRole}/${params.eventId}`
        ),
      active: false,
      access: true,
    },
    {
      label: 'Система оценивания',
      onClick: () =>
        navigate(
          `${frontendRoutes.settingsEvent.gradingSystem}/${params.eventId}`
        ),
      active: false,
      access: true,
    },
  ];

  return (
    <div className={styles.settingsEvent}>
      <div className="local-container">
        <Breadcrumbs
          links={[
            {
              title: 'Мероприятие',
              link: `${frontendRoutes.publicEventsCatalog.events}/${params.eventId}`,
            },
            { title: 'Настройки мероприятия' },
          ]}
          homeLink={frontendRoutes.mainPage}
        />
      </div>
      <div className={styles.settingsEvent__header}>Настройки мероприятия</div>
      <div className={styles.settingsEvent__navigation}>
        <HMenu links={menuLinks} />
      </div>

      <div className="container">
        <SettingsEventForm
          transformedTagsData={transformedTagsData}
          event={event}
        />
      </div>
    </div>
  );
};
