// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ComparisonContent_content__bl6da {
  width: calc(100% - 420px);
  margin-left: auto;
  padding: 32px 40px;
}
.ComparisonContent_contentTitle__1aCn4 {
  font-size: 32px;
  font-weight: 600;
}
.ComparisonContent_contentTop__1JemF {
  margin-top: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/researchComparison/ComparisonContent/ComparisonContent.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;AACI;EACI,eAAA;EACA,gBAAA;AACR;AAEI;EACI,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAAR","sourcesContent":[".content {\n    width: calc(100% - 420px);\n    margin-left: auto;\n    padding: 32px 40px;\n\n    &Title {\n        font-size: 32px;\n        font-weight: 600;\n    }\n\n    &Top {\n        margin-top: 64px;\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `ComparisonContent_content__bl6da`,
	"contentTitle": `ComparisonContent_contentTitle__1aCn4`,
	"contentTop": `ComparisonContent_contentTop__1JemF`
};
export default ___CSS_LOADER_EXPORT___;
