import React, { useEffect, useState, useMemo } from 'react';
import { Form, FormikContext, useFormik } from 'formik';

import { researchSettingsSchema } from '../../../../../models/validations/schemas/research';
import {
  addResearchRequest,
  EditResearchRequest,
  ResearchDetails as ResearchDetailsType,
  ResearchSurvey,
} from '../../../../../models/research';
import {
  useAddResearchMutation,
  useEditResearchMutation,
  useGetDictionaryEducationQuery,
  useGetDictionaryQuery,
  useGetRegionDataQuery,
  VbudusheeAPI,
} from '../../../../../store/VbudusheeAPI';

//  ui
import { Box } from '../../../../ui/Box';
import { Typography } from '../../../../ui/Typography';
import { Toggle } from '../../../../ui/Toggle';
import { InputText } from '../../../../ui/Form/InputText';
import { InputDate } from '../../../../ui/Form/InputDate';
import { Select } from '../../../../ui/Form/Select';
import { SelectSearchRegions } from '../../../../ui/Form/SelectSearchRegions';
import { Textarea } from '../../../../ui/Form/Textarea/Textarea';
import { RadioButton } from '../../../../ui/Form/RadioButton/RadioButton';
import { formatDateStr } from '../../../../../utils/dates';
import { defaultFormikOptions } from '../../../../../models/formik';
import { frontendRoutes } from '../../../../../utils/router/routes';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../ui/Button';
import { ContentModal } from '../../../../ui/ContentModal';
import { ModalError } from '../../../../ui/ModalError';
import styles from './ResearchEditSettings.module.scss';
import { useToasts } from '../../../../../hooks/useToasts';

import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

type ResearchEditSettingsTypes = {
  researchId?: string;
  details?: ResearchDetailsType;
  researchSurvey?: { data: ResearchSurvey } | undefined;
  refetch?: () => void;
  isTheAuthor?: boolean;
};

export const ResearchEditSettings = ({
  researchId,
  details,
  researchSurvey,
  refetch,
  isTheAuthor,
}: ResearchEditSettingsTypes) => {
  const navigate = useNavigate();
  const [detailsData, setDetailsData] = useState<
    ResearchDetailsType | undefined
  >(details);
  const [isOptionsEducationLoading, setIsOptionsEducationLoading] =
    useState<boolean>(false);
  const [isLoginReq, setIsLogReq] = useState<boolean>(
    Boolean(detailsData?.data.research_login_requirement)
  );
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>('');
  const [modalButton, setModalButton] = useState<boolean>(false);
  const [modalButtonSurvey, setModalButtonSurvey] = useState<boolean>(false);
  const [isErrorDate, setErrorDate] = useState<string>('');
  const [isError, setIsError] = useState<string>('');
  const [interviewOpen, setInterviewOpen] = useState<boolean>(false);
  const [modalLongitudinalInfo, setModalLongitudinalInfo] =
    useState<boolean>(false);

  const [triggerStart] = VbudusheeAPI.endpoints.getStartResearch.useLazyQuery();
  const [triggerStop] = VbudusheeAPI.endpoints.getStopResearch.useLazyQuery();
  const { data: regions } = useGetRegionDataQuery({});
  const {
    data: requestDictionary,
    isLoading: isDictionaryLoading,
    error: isDictionaryResearchError,
  } = useGetDictionaryEducationQuery({});
  const { data: dictionary } = useGetDictionaryQuery({});
  const [addResearch, { data: dataResearch, isLoading, error }] =
    useAddResearchMutation();
  const [
    editResearch,
    { isLoading: isEditResearchLoading, error: isEditResearchError },
  ] = useEditResearchMutation();

  const { onShowToast } = useToasts();

  const requestStart = async (researchId: string) => {
    triggerStart({ researchId })
      .unwrap()
      .then((res) => {
        if (res.result === 'success') {
          setInterviewOpen(true);
          // formik.setFieldValue('research_interview_open', `${interviewOpen}`);
          refetch?.();
        }
      })
      .catch(() => {
        // if (reason) {
        //   setIsError(error.message);
        // }
      });
  };

  const requestStop = async (researchId: string) => {
    triggerStop({ researchId })
      .unwrap()
      .then((res) => {
        if (res.result === 'success') {
          setInterviewOpen(false);
          // formik.setFieldValue('research_interview_open', `${interviewOpen}`);
          setModalText('Опрос приостановлен');
          setIsOpenModal(true);
          refetch?.();
        }
      })
      .catch(() => {
        // if (error) {
        //   setIsError(error.message);
        // }
      });
  };

  const changeInterviewOpen = () => {
    if (formik.dirty) {
      setModalText(
        'В форме присутствуют измененные поля. Данное действие приведет к их потере'
      );
      setModalButton(true);
      setIsOpenModal(true);
    } else {
      if (!interviewOpen && !disableField) {
        setModalText(
          'После запуска опроса вам не будет доступно \n редактирование анкеты'
        );
        setModalButton(true);
        setIsOpenModal(true);
      } else {
        requestStartOrStopInterview();
      }
    }
  };

  const requestStartOrStopInterview = () => {
    if (researchId) {
      if (interviewOpen) {
        requestStop(researchId);
      } else {
        // if (detailsData) {
        //   if (
        //     new Date(detailsData.data.research_start_date).getTime() <
        //     new Date(new Date().toLocaleDateString('en-US')).getTime()
        //   ) {
        //     setModalText(
        //       'Дата запуска опроса должна предшествовать дате начала исследования'
        //     );
        //     setIsOpenModal(true);
        //   } else {
        if (researchSurvey) {
          if (
            researchSurvey.data.research_methods.length ||
            researchSurvey.data.research_questionnaires.length
          ) {
            requestStart(researchId);
          } else {
            setModalText(
              'Чтобы запустить опрос необходимо сформировать опросник'
            );
            setModalButtonSurvey(true);
            setIsOpenModal(true);
          }
        }
        //     }
        //   }
      }
    }
  };

  useEffect(() => {
    setDetailsData(details);
  }, [details]);

  const initialValues = {
    research_name: detailsData?.data.research_name || '',
    date:
      researchId && detailsData
        ? `${new Date(detailsData.data.research_start_date)};${new Date(
            detailsData.data.research_end_date
          )}`
        : '',
    research_type: detailsData?.data.research_type || '',
    research_description: detailsData?.data.research_description || '',
    research_region: detailsData?.data.research_region || [],
    research_target: detailsData?.data.research_target || '',
    research_education: detailsData?.data.research_education || '',
    research_frequency:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      detailsData?.data.research_frequency === '0'
        ? ''
        : detailsData?.data.research_frequency || '',
    research_login_requirement:
      detailsData?.data.research_login_requirement || false,
    research_start_date: detailsData?.data.research_start_date || '',
    research_end_date: detailsData?.data.research_end_date || '',
    research_interview_open: interviewOpen,
    // research_notification_frequency:
    //   detailsData?.data.research_notification_frequency || '',
    research_method_feedback:
      detailsData?.data.research_method_feedback || false,
    research_steps_count: detailsData?.research_steps_count || '',
  };

  const submitHandler = async () => {
    if (!formik.isValid) return;

    if (!researchId) {
      const result = await addResearch(validAddResearch);
      if ('data' in result) {
        if (formik.values.research_type === 'longitudinal') {
          setModalLongitudinalInfo(true);
        } else {
          navigate(
            `${frontendRoutes.research.details}/${result.data.research.id}`
          );
        }
      }
    } else {
      const result = await editResearch(validEditResearch);
      if ('data' in result) {
        navigate(
          `${frontendRoutes.research.details}/${result.data.research.id}`
        );
      }
    }
  };

  const navigateToResearchHandler = () => {
    researchId
      ? navigate(`${frontendRoutes.research.details}/${researchId}`)
      : navigate(`${frontendRoutes.mainPage}`);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: researchSettingsSchema,
    onSubmit: submitHandler,
    enableReinitialize: true,
    ...defaultFormikOptions,
  });

  const validAddResearch = useMemo(() => {
    const result: addResearchRequest = {
      research_name: formik.values.research_name,
      research_description: formik.values.research_description,
      research_type: formik.values.research_type,
      research_region: formik.values.research_region.map((el) => {
        return el.region_number;
      }),
      research_login_requirement: isLoginReq,
      research_target: formik.values.research_target,
      research_education: formik.values.research_education,
      research_method_feedback: formik.values.research_method_feedback,
      research_start_date: formik.values.research_start_date,
      research_end_date: formik.values.research_end_date,
      research_steps_count: +formik.values.research_steps_count,
    };
    if (formik.values) {
      for (const el in formik.values) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (formik.values[el] && el === 'research_frequency') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          result[el] = formik.values[el];
        }
      }
    }
    return result;
  }, [formik.values, isLoginReq]);

  const validEditResearch = useMemo(() => {
    const result: EditResearchRequest = {
      research_id: Number(researchId),
      research_name: formik.values.research_name,
      research_description: formik.values.research_description,
      research_type: formik.values.research_type,
      research_region: formik.values.research_region.map((el) => {
        return el.region_number;
      }),
      research_login_requirement: isLoginReq,
      research_method_feedback: formik.values.research_method_feedback,
      research_start_date: formik.values.research_start_date,
      research_end_date: formik.values.research_end_date,
    };
    if (formik.values) {
      for (const el in formik.values) {
        switch (el) {
          case 'research_education':
            if (formik.values.research_type !== 'longitudinal') {
              result[el] = formik.values[el];
            }
            break;

          case 'research_frequency':
            if (formik.values.research_type !== 'longitudinal') {
              result[el] = formik.values[el];
            }
            break;

          case 'research_target':
            if (formik.values.research_type !== 'longitudinal') {
              result[el] = formik.values[el];
            }
            break;
        }
      }
    }
    return result;
  }, [formik.values, isLoginReq]);

  const convertObjectToArray = (dictionary: { [key: string]: string }) => {
    return Object.entries(dictionary).map((el: string[]) => {
      return {
        key: el[0],
        value: el[1],
      };
    });
  };

  const convertToDate = (el: string) => {
    return el.slice(0, 10).split('.').reverse().join('-');
  };

  const logReq = (value: boolean) => {
    if (!interviewOpen && formik.values.research_type !== 'longitudinal') {
      setIsLogReq(value);
    }
  };

  const optionsFrequencyResearch = useMemo(() => {
    if (dictionary?.research.research_frequency) {
      return convertObjectToArray(dictionary.research.research_frequency);
    }
    return [];
  }, [dictionary]);

  const optionsTypeResearch = useMemo(() => {
    if (dictionary?.research.research_type) {
      return convertObjectToArray(dictionary.research.research_type);
    }
    return [];
  }, [dictionary]);

  const optionsTargetResearch = useMemo(() => {
    if (dictionary?.research.research_target) {
      return convertObjectToArray(dictionary.research.research_target);
    }
    return [];
  }, [dictionary]);

  const visibleTodos = useMemo(() => {
    setIsOptionsEducationLoading(true);
    if (dictionary) {
      // if (formik.values.research_target && requestDictionary) {
      setIsOptionsEducationLoading(false);
      return convertObjectToArray(
        dictionary?.research.research_education
        // requestDictionary[formik.values.research_target]
      );
    }
    setIsOptionsEducationLoading(false);
    return [];
  }, [dictionary]);

  const stepText = useMemo(() => {
    if (dataResearch) {
      if (
        +String(dataResearch.research.research_steps_count).charAt(
          String(dataResearch.research.research_steps_count).length - 1
        ) === 1
      ) {
        return 'этап';
      } else if (
        +String(dataResearch.research.research_steps_count).charAt(
          String(dataResearch.research.research_steps_count).length - 1
        ) > 1 &&
        +String(dataResearch.research.research_steps_count).charAt(
          String(dataResearch.research.research_steps_count).length - 1
        ) < 5
      ) {
        return 'этапа';
      } else {
        return 'этапов';
      }
    }
    return '';
  }, [dataResearch]);

  const dateText = useMemo(() => {
    if (dataResearch) {
      if (
        +String(dataResearch.research.research_frequency).charAt(
          String(dataResearch.research.research_frequency).length - 1
        ) === 1
      ) {
        return 'день';
      } else if (
        +String(dataResearch.research.research_frequency).charAt(
          String(dataResearch.research.research_frequency).length - 1
        ) > 1 &&
        +String(dataResearch.research.research_frequency).charAt(
          String(dataResearch.research.research_frequency).length - 1
        ) < 5
      ) {
        return 'дня';
      } else {
        return 'дней';
      }
    }
    return '';
  }, [dataResearch]);

  // }, [formik.values.research_target, requestDictionary]);

  const disableField = useMemo(() => {
    if (researchId) {
      if (isTheAuthor) {
        if (detailsData?.data.research_publication_status !== 'unpublished') {
          if (detailsData?.data.research_publication_status === 'rework') {
            return false;
          }
          if (detailsData?.data.research_publication_status === 'rejected') {
            return false;
          }
          return true;
        }
        return false;
      }
      return true;
    }
    return false;
  }, [detailsData, isTheAuthor]);

  const touch = useMemo(() => {
    let count = 0;
    if (!researchId) {
      for (const key in formik.touched) {
        count++;
      }
      return !!count;
    }
    return true;
  }, [formik.touched]);

  useEffect(() => {
    if (formik.values.research_type !== 'longitudinal') {
      formik.setFieldValue('research_login_requirement', isLoginReq);
      setTimeout(() => formik.setFieldTouched('research_frequency', false));
    } else {
      formik.setFieldValue('research_login_requirement', true);
      setTimeout(() => formik.setFieldTouched('research_frequency', false));
    }
  }, [isLoginReq]);

  useEffect(() => {
    if (formik.values.research_type === 'longitudinal') {
      setIsLogReq(true);
    }
    if (formik.values.research_type === 'once') {
      formik.setFieldValue('research_frequency', '');
      setTimeout(() => formik.setFieldTouched('research_frequency', false));
    }
  }, [formik.values.research_type]);

  useEffect(() => {
    if (formik.values.research_target === 'other') {
      formik.setFieldValue('research_education', '');
      setTimeout(() => formik.setFieldTouched('research_education', true));
    }
  }, [formik.values.research_target]);

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setIsError(error.data.message);
    }
    if (isEditResearchError) {
      setIsError(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isEditResearchError.data.message || isEditResearchError.data.error
      );
    }
  }, [error, isEditResearchError]);

  useEffect(() => {
    if (formik.values.date !== '') {
      const dates: string[] = [];
      const rawDates = formik.values.date.split(';');
      rawDates.map((date) => dates.push(date));

      // if (
      //   `${convertToDate(formatDateStr(dates[0]))}` !==
      //   detailsData?.data.research_start_date.split(' ')[0]
      // ) {
      if (dates[0] && dates[0] !== 'undefined') {
        formik.setFieldValue(
          'research_start_date',
          `${convertToDate(formatDateStr(dates[0]))}`
        );
      } else {
        formik.setFieldValue('research_start_date', '');
      }
      setTimeout(() => formik.setFieldTouched('research_start_date', true));
      // }
      // if (
      //   `${convertToDate(formatDateStr(dates[1]))}` !==
      //   detailsData?.data.research_end_date.split(' ')[0]
      // ) {
      if (dates[1] && dates[1] !== 'undefined') {
        formik.setFieldValue(
          'research_end_date',
          `${convertToDate(formatDateStr(dates[1]))}`
        );
      } else {
        formik.setFieldValue('research_end_date', '');
      }
      setTimeout(() => formik.setFieldTouched('research_end_date', true));
      // }
    }
  }, [formik.values.date]);

  useEffect(() => {
    if (
      formik.errors.research_start_date &&
      formik.touched.research_start_date
    ) {
      setErrorDate(formik.errors.research_start_date);
    } else if (
      formik.errors.research_end_date &&
      formik.touched.research_end_date
    ) {
      setErrorDate(formik.errors.research_end_date);
    } else {
      setErrorDate('');
    }
  }, [formik.errors]);

  useEffect(() => {
    if (details) {
      setInterviewOpen(details.data.research_interview_open);
    }
  }, [details]);

  useEffect(() => {
    if (
      details?.data.research_quiz_has_answers &&
      formik.values &&
      formik.dirty
    ) {
      onShowToast({
        type: 'danger',
        name: 'research_quiz_has_answers',
        title: 'Ошибка',
        description:
          'Редактирование настроек опросника возможно только до начала сбора ответов респондентов. В Вашем опросе присутствуют уже собранные ответы, поэтому редактирование вопросов недоступно.',
        maxCount: 1,
        timer: 9000,
      });
    }
  }, [details?.data.research_quiz_has_answers, formik.dirty, formik.values]);

  return (
    <>
      <FormikContext.Provider value={formik}>
        <Form>
          {researchId && (
            <Box flex mb={22} disabled={!isTheAuthor}>
              <Box mr={12}>
                <Toggle
                  isActive={interviewOpen}
                  clicked={() => changeInterviewOpen()}
                />
              </Box>
              <Typography size="sm" color="main">
                Запуск опроса
              </Typography>
            </Box>
          )}
          {researchId && <div className="divider"></div>}
          <div>
            <InputText
              name="research_name"
              fullWidth
              placeholder="Название исследования*"
              disabled={interviewOpen || disableField}
              redRequired
            />
          </div>
          <div className="grid-row-2 vm-20">
            <div>
              <InputDate
                name="date"
                initialDate={detailsData?.data.research_start_date}
                initialFinishDate={detailsData?.data.research_end_date}
                fullWidth
                placeholder="Дата начала и окончания исследования*"
                isRange
                minDate={new Date()}
                disabled={interviewOpen || disableField}
                required
              />
              {isErrorDate && (
                <div style={{ color: '#ee505a' }}>{isErrorDate}</div>
              )}
            </div>
            <Select
              name="research_type"
              label="Тип исследования*"
              options={optionsTypeResearch}
              fullWidth
              disabled={interviewOpen || disableField || !!researchId}
              redRequired
            />
          </div>
          <Textarea
            name="research_description"
            placeholder="Описание исследования*"
            height={120}
            editable={!interviewOpen && !disableField}
            redRequired
          />
          <Box mt={28} mb={28}>
            <Typography size="sm" color="main">
              Необходимость авторизации респондентов
            </Typography>
            <Box mt={12}>
              <RadioButton
                name="research_login_requirement"
                isRow
                initialValue={formik.values.research_login_requirement}
                values={[
                  { key: '1', value: true, title: 'Да' },
                  { key: '0', value: false, title: 'Нет' },
                ]}
                onChangeSetter={(value) => {
                  if (details?.data.research_quiz_has_answers) {
                    onShowToast({
                      type: 'danger',
                      name: 'research_quiz_has_answers',
                      title: 'Ошибка',
                      description:
                        'Редактирование настроек опросника возможно только до начала сбора ответов респондентов. В Вашем опросе присутствуют уже собранные ответы, поэтому редактирование вопросов недоступно.',
                      maxCount: 1,
                      timer: 9000,
                    });
                  } else {
                    logReq(value);
                  }
                }}
                disabled={
                  formik.values.research_type === 'longitudinal' ||
                  interviewOpen ||
                  disableField
                }
                noActive={details?.data.research_quiz_has_answers}
              />
            </Box>
          </Box>
          <div className="grid-row-2 vm-20">
            <SelectSearchRegions
              name="research_region"
              placeholder="Регион*"
              options={
                regions
                  ? regions.map((region) => {
                      return {
                        region_name: region.region_name,
                        region_number: region.region_number,
                      };
                    })
                  : []
              }
              fullWidth
              disabled={interviewOpen || disableField}
              redRequired
            />
            {/* TODO: no such value from the BE
            need to find out about this field */}

            <Select
              name="research_education"
              label="Исследуемый уровень образования*"
              options={visibleTodos}
              disabled={
                !formik.values.research_target ||
                (!!researchId &&
                  detailsData &&
                  detailsData.data.research_type === 'longitudinal' &&
                  formik.values.research_type !== 'longitudinal') ||
                interviewOpen ||
                disableField ||
                formik.values.research_target === 'other'
              }
              isOptionsLoading={
                isDictionaryLoading && isOptionsEducationLoading
              }
              fullWidth
              redRequired
            />
          </div>
          <div className="grid-row-2 vm-20">
            <Select
              name="research_target"
              label="Целевая аудитория респондентов*"
              options={optionsTargetResearch}
              fullWidth
              disabled={
                interviewOpen ||
                disableField ||
                (!!researchId &&
                  detailsData &&
                  detailsData.data.research_type === 'longitudinal' &&
                  formik.values.research_type !== 'longitudinal')
              }
              redRequired
            />
            {formik.values.research_type === 'longitudinal' && (
              <div className={cx('research_frequency')}>
                Периодичность проведения исследования раз в
                <InputText
                  name="research_frequency"
                  type="string"
                  placeholder="365"
                  absolute
                  disabled={
                    interviewOpen ||
                    disableField ||
                    (!!researchId &&
                      detailsData &&
                      detailsData.data.research_type === 'longitudinal' &&
                      formik.values.research_type !== 'longitudinal')
                  }
                />
                дней.<span style={{ color: 'red ' }}>*</span>
              </div>
            )}
          </div>
          <div className="grid-row-2 vm-20">
            <div>
              <div className={cx('research_step')}>
                Напомнить мне о начале исследования за
                <InputText
                  name="remind_time"
                  type="string"
                  placeholder="5"
                  absolute
                />
                дней.
              </div>
            </div>
            {formik.values.research_type === 'longitudinal' && (
              <div className={cx('research_step')}>
                Количество этапов исследования
                <span style={{ color: 'red ' }}>*</span>
                <InputText
                  name="research_steps_count"
                  type="string"
                  placeholder="1"
                  absolute
                  disabled={
                    interviewOpen ||
                    disableField ||
                    (!!researchId &&
                      detailsData &&
                      detailsData.data.research_type === 'longitudinal' &&
                      formik.values.research_type !== 'longitudinal')
                  }
                />
              </div>
            )}
          </div>
          {/*<div className="grid-row-2 vm-20">*/}
          {/*  <Select*/}
          {/*    name="research_notification_frequency"*/}
          {/*    label="Частота напоминаний о проводимом исследовании"*/}
          {/*    options={optionsFrequencyResearch}*/}
          {/*    fullWidth*/}
          {/*  />*/}
          {/*  <Box />*/}
          {/*</div>*/}
        </Form>
        <div className="divider"></div>
        <Box flex>
          <Button
            label="Отмена"
            withoutBackground
            clicked={navigateToResearchHandler}
          />
          <Box mr={16} />
          <Button
            label={researchId ? 'Сохранить' : 'Создать'}
            gradient={true}
            disabled={
              !formik.isValid ||
              !formik.dirty ||
              !touch ||
              details?.data.research_quiz_has_answers ||
              interviewOpen ||
              disableField
            }
            clicked={submitHandler}
          />
        </Box>
      </FormikContext.Provider>
      <ContentModal
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
          setModalText('');
          setModalButton(false);
        }}
        titleText="Запуск опроса"
      >
        <>
          <div>{modalText}</div>
          {modalButton && (
            <div>
              <Box flex mt={32}>
                <Button
                  label="Подтвердить"
                  gradient={true}
                  clicked={() => {
                    setIsOpenModal(false);
                    setModalText('');
                    setModalButton(false);
                    requestStartOrStopInterview();
                  }}
                />
                <Box mr={16} />
                <Button
                  label="Отмена"
                  withoutBackground
                  clicked={() => {
                    setIsOpenModal(false);
                    setModalText('');
                    setModalButton(false);
                  }}
                />
              </Box>
            </div>
          )}
          {modalButtonSurvey && (
            <div>
              <div className="divider"></div>
              <Box flex>
                <Button
                  label="Перейти в конструктор опроса"
                  gradient={true}
                  clicked={() => {
                    setIsOpenModal(false);
                    setModalText('');
                    setModalButtonSurvey(false);
                    navigate(
                      `${frontendRoutes.research.edit.survey}/${researchId}`
                    );
                  }}
                />
                <Box mr={16} />
                <Button
                  label="Отмена"
                  withoutBackground
                  clicked={() => {
                    setIsOpenModal(false);
                    setModalText('');
                    setModalButtonSurvey(false);
                  }}
                />
              </Box>
            </div>
          )}
        </>
      </ContentModal>
      {dataResearch && (
        <ContentModal
          isOpen={modalLongitudinalInfo}
          onClose={() => {
            setModalLongitudinalInfo(false);
            navigate(
              `${frontendRoutes.research.details}/${dataResearch.research.id}`
            );
          }}
          titleText="Лонгитюдное исследование успешно создано!"
        >
          <>
            <div>
              Оно будет проведено в{' '}
              <span>{dataResearch.research.research_steps_count}</span>{' '}
              {stepText} с промежутком в{' '}
              <span>{dataResearch.research.research_frequency}</span> {dateText}{' '}
              между ними. Этапы исследования
              <br />
              созданы автоматически и находятся в разделе
              <b
                onClick={() => {
                  navigate(`${frontendRoutes.materials.research}`);
                }}
              >
                “Мои материалы”
              </b>
              .
              <br />
              <br />
              Даты проведения этапов исследования с учетом Ваших настроек:
              <br />
              <br />
              <div className={cx('dateScroll__container')}>
                {dataResearch.research.steps.map((s, i: number) => (
                  <div key={i}>{`${s.research_step} этап: ${formatDateStr(
                    s.research_start_date,
                    {
                      isDateOnly: true,
                    }
                  )} - ${formatDateStr(s.research_end_date, {
                    isDateOnly: true,
                  })}`}</div>
                ))}
              </div>
              <br />
              Чтобы запустить сбор ответов в рамках отдельного этапа
              исследования, перейдите на
              <br />
              страницу этого этапа, нажмите <b>“Редактировать”</b> и во вкладке{' '}
              <b
                onClick={() => {
                  navigate(
                    `${frontendRoutes.research.edit.settings}/${dataResearch.research.id}`
                  );
                }}
              >
                “Настройки исследования”
              </b>
              <br />
              нажмите на переключатель <b>“Запуск опроса”</b>.
            </div>
            <div>
              <Box flex mt={32}>
                <Button
                  label="Ок"
                  gradient={true}
                  clicked={() => {
                    setModalLongitudinalInfo(false);
                    if (dataResearch) {
                      navigate(
                        `${frontendRoutes.research.details}/${dataResearch.research.id}`
                      );
                    }
                  }}
                />
              </Box>
            </div>
          </>
        </ContentModal>
      )}
      <ModalError
        isOpen={!!isError}
        errorText={isError}
        onClose={() => {
          setIsError('');
        }}
      />
    </>
  );
};
