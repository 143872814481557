// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PopupMessage_popup__mK4Ru {
  position: fixed;
  right: 5px;
  bottom: 10%;
  display: flex;
  justify-content: center;
  z-index: 3;
  width: 15%;
  border-radius: 35px;
}
.PopupMessage_popup__overlay__mroZP {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  border-radius: 35px;
}
.PopupMessage_popup__content__CmoUN {
  padding-top: 135px;
  z-index: 3;
  width: 100%;
  border-radius: 35px;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/PopupMessage/PopupMessage.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EAGA,UAAA;EACA,WAAA;EAEA,aAAA;EACA,uBAAA;EACA,UAAA;EAEA,UAAA;EACA,mBAAA;AAHF;AAKE;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;EACA,mBAAA;AAHJ;AAME;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,mBAAA;AAJJ","sourcesContent":[".popup {\n  position: fixed;\n  // top: 0;\n  // left: 0;\n  right: 5px;\n  bottom: 10%;\n  // min-height: 100vh;\n  display: flex;\n  justify-content: center;\n  z-index: 3;\n  // overflow-y: scroll;\n  width: 15%;\n  border-radius: 35px;\n\n  &__overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    z-index: 3;\n    border-radius: 35px;\n  }\n\n  &__content {\n    padding-top: 135px;\n    z-index: 3;\n    width: 100%;\n    border-radius: 35px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": `PopupMessage_popup__mK4Ru`,
	"popup__overlay": `PopupMessage_popup__overlay__mroZP`,
	"popup__content": `PopupMessage_popup__content__CmoUN`
};
export default ___CSS_LOADER_EXPORT___;
