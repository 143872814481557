// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CheckboxButtonType_checkboxButtonType__Lbo5E {
  display: flex;
  flex-direction: column;
}
.CheckboxButtonType_checkboxButtonType__title__tiaJN {
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
}
.CheckboxButtonType_checkboxButtonType__item__2eWi6 {
  display: flex;
  flex-direction: column;
}
.CheckboxButtonType_checkboxButtonType__item_title__nl4ga {
  display: flex;
  margin-left: 10px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/SurveyQuestion/CheckboxButtonType/CheckboxButtonType.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;AAAJ;AAEI;EACE,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAAN","sourcesContent":[".checkboxButtonType {\n  display: flex;\n  flex-direction: column;\n\n  &__title {\n    font-weight: 600;\n    font-size: 12px;\n    line-height: 22px;\n  }\n\n  &__item {\n    display: flex;\n    flex-direction: column;\n\n    &_title {\n      display: flex;\n      margin-left: 10px;\n      align-items: center;\n      font-weight: 400;\n      font-size: 14px;\n      line-height: 22px;\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxButtonType": `CheckboxButtonType_checkboxButtonType__Lbo5E`,
	"checkboxButtonType__title": `CheckboxButtonType_checkboxButtonType__title__tiaJN`,
	"checkboxButtonType__item": `CheckboxButtonType_checkboxButtonType__item__2eWi6`,
	"checkboxButtonType__item_title": `CheckboxButtonType_checkboxButtonType__item_title__nl4ga`
};
export default ___CSS_LOADER_EXPORT___;
