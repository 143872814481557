import { useNavigate } from 'react-router-dom';

//  ui
import { Layout } from '../../../components/Layout';
import { MaterialsEvents } from '../../../components/pages/materials/MaterialsEvents';

export const MaterialsEventsPage = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <MaterialsEvents />
    </Layout>
  );
};
