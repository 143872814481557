import { Layout } from '../../../../../components/Layout';
import { ChangePassword } from '../../../../../components/Profile/ChangePassword/ChangePassword';

export const ChangePasswordPage = () => {
  return (
    <Layout>
      <ChangePassword />
    </Layout>
  );
};
