import React from 'react';
import styles from './Slider.module.scss';
import { Sliders } from '../../../../../../constants/mainPage/sliders';
// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

//ui
import { ArrowLeft } from '../../../../../ui/Icons/MainPage/ArrowLeft';
import { ArrowRight } from '../../../../../ui/Icons/MainPage/ArrowRight';

// Import Swiper styles
// eslint-disable-next-line
import 'swiper/css';
// eslint-disable-next-line
import 'swiper/css/navigation';
// eslint-disable-next-line
import 'swiper/css/pagination';
// eslint-disable-next-line
import 'swiper/css/bundle';
import './styles.css';

import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const Slider = () => {
  return (
    <>
      <Swiper
        // mousewheel
        keyboard
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
        pagination={{
          clickable: true,
        }}
      >
        {Sliders.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className={styles.slide}>
              <div className={styles.wrapper}>
                <div className={styles.wrapper__title}>{slide.title}</div>
                <div className={styles.wrapper__description}>
                  {slide.description}
                </div>
                <div
                  className={styles.wrapper__text}
                  dangerouslySetInnerHTML={{ __html: slide.text }}
                />
              </div>
              <img
                className={cx(`slide__image${index + 1}`)}
                src={slide.image}
                alt={`image${index + 1}`}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="swiper-button-next">
        <ArrowRight />
      </div>
      <div className="swiper-button-prev">
        <ArrowLeft />
      </div>
    </>
  );
};
