import { Layout } from '../../../../components/Layout';
import { TeamNew } from '../../../../components/pages/team/TeamNew';

export const TeamNewPage = () => {
  return (
    <Layout>
      <TeamNew />
    </Layout>
  );
};
