export const IconDoc = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7365 2.76172H8.08449C6.02449 2.76172 4.25049 4.43072 4.25049 6.49072V17.3397C4.25049 19.5157 5.90849 21.1147 8.08449 21.1147H16.0725C18.1325 21.1147 19.8025 19.3997 19.8025 17.3397V8.03772L14.7365 2.76172Z"
        stroke="#185AC5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4739 2.75V5.659C14.4739 7.079 15.6229 8.231 17.0429 8.234C18.3589 8.237 19.7059 8.238 19.7969 8.232"
        stroke="#185AC5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="12" y="11" width="13" height="7" fill="white" />
      <path
        d="M8 11.7196C8.16533 11.7089 8.32267 11.7009 8.472 11.6956C8.62133 11.6849 8.77067 11.6796 8.92 11.6796C9.06933 11.6743 9.224 11.6716 9.384 11.6716C9.54933 11.6663 9.73067 11.6636 9.928 11.6636C10.4987 11.6636 10.9733 11.7249 11.352 11.8476C11.736 11.9649 12.04 12.1383 12.264 12.3676C12.4933 12.5969 12.656 12.8796 12.752 13.2156C12.848 13.5516 12.896 13.9383 12.896 14.3756C12.896 14.8823 12.8347 15.3249 12.712 15.7036C12.5947 16.0769 12.408 16.3863 12.152 16.6316C11.9013 16.8769 11.5787 17.0609 11.184 17.1836C10.7947 17.3009 10.3307 17.3596 9.792 17.3596C9.616 17.3596 9.45333 17.3569 9.304 17.3516C9.15467 17.3516 9.01067 17.3489 8.872 17.3436C8.73333 17.3383 8.592 17.3303 8.448 17.3196C8.30933 17.3143 8.16 17.3063 8 17.2956V11.7196ZM9.312 16.1996C9.408 16.2103 9.496 16.2183 9.576 16.2236C9.66133 16.2289 9.75467 16.2316 9.856 16.2316C10.1547 16.2316 10.4107 16.1996 10.624 16.1356C10.8373 16.0663 11.0107 15.9596 11.144 15.8156C11.2827 15.6716 11.384 15.4876 11.448 15.2636C11.512 15.0343 11.544 14.7623 11.544 14.4476C11.544 14.1543 11.5147 13.9036 11.456 13.6956C11.4027 13.4823 11.312 13.3089 11.184 13.1756C11.0613 13.0423 10.8987 12.9463 10.696 12.8876C10.4933 12.8236 10.2453 12.7916 9.952 12.7916C9.82933 12.7916 9.71733 12.7943 9.616 12.7996C9.52 12.8049 9.41867 12.8129 9.312 12.8236V16.1996Z"
        fill="#185AC5"
      />
      <path
        d="M13.6954 14.5116C13.6954 13.4983 13.9221 12.7596 14.3754 12.2956C14.8341 11.8316 15.5354 11.5996 16.4794 11.5996C16.9808 11.5996 17.4048 11.6583 17.7514 11.7756C18.1034 11.8876 18.3888 12.0636 18.6074 12.3036C18.8261 12.5383 18.9834 12.8396 19.0794 13.2076C19.1808 13.5703 19.2314 14.0049 19.2314 14.5116C19.2314 15.0396 19.1728 15.4876 19.0554 15.8556C18.9381 16.2236 18.7621 16.5249 18.5274 16.7596C18.2981 16.9889 18.0101 17.1569 17.6634 17.2636C17.3221 17.3703 16.9274 17.4236 16.4794 17.4236C15.5728 17.4236 14.8821 17.1943 14.4074 16.7356C13.9328 16.2716 13.6954 15.5303 13.6954 14.5116ZM15.0874 14.5196C15.0874 14.8663 15.1194 15.1543 15.1834 15.3836C15.2528 15.6129 15.3488 15.7943 15.4714 15.9276C15.5941 16.0609 15.7408 16.1543 15.9114 16.2076C16.0874 16.2609 16.2848 16.2876 16.5034 16.2876C16.7221 16.2876 16.9141 16.2609 17.0794 16.2076C17.2501 16.1489 17.3914 16.0529 17.5034 15.9196C17.6154 15.7809 17.7008 15.5996 17.7594 15.3756C17.8181 15.1463 17.8474 14.8609 17.8474 14.5196C17.8474 14.1783 17.8181 13.8929 17.7594 13.6636C17.7008 13.4343 17.6128 13.2503 17.4954 13.1116C17.3781 12.9729 17.2314 12.8769 17.0554 12.8236C16.8794 12.7649 16.6768 12.7356 16.4474 12.7356C15.9888 12.7356 15.6474 12.8716 15.4234 13.1436C15.1994 13.4103 15.0874 13.8689 15.0874 14.5196Z"
        fill="#185AC5"
      />
      <path
        d="M24.3992 13.1916C24.2339 13.0956 24.0339 13.0049 23.7992 12.9196C23.5699 12.8343 23.2739 12.7916 22.9112 12.7916C22.6605 12.7916 22.4419 12.8209 22.2552 12.8796C22.0685 12.9383 21.9139 13.0343 21.7912 13.1676C21.6685 13.3009 21.5779 13.4769 21.5192 13.6956C21.4605 13.9089 21.4312 14.1729 21.4312 14.4876C21.4312 14.8183 21.4632 15.0956 21.5272 15.3196C21.5912 15.5436 21.6872 15.7249 21.8152 15.8636C21.9432 15.9969 22.1032 16.0929 22.2952 16.1516C22.4872 16.2049 22.7139 16.2316 22.9752 16.2316C23.3272 16.2316 23.6285 16.1863 23.8792 16.0956C24.1299 16.0049 24.3245 15.9089 24.4632 15.8076V17.1116C24.3192 17.1809 24.1112 17.2476 23.8392 17.3116C23.5725 17.3809 23.2499 17.4156 22.8712 17.4156C22.3699 17.4156 21.9405 17.3596 21.5832 17.2476C21.2259 17.1303 20.9325 16.9543 20.7032 16.7196C20.4739 16.4849 20.3059 16.1889 20.1992 15.8316C20.0925 15.4743 20.0392 15.0503 20.0392 14.5596C20.0392 14.0583 20.0925 13.6236 20.1992 13.2556C20.3112 12.8823 20.4819 12.5729 20.7112 12.3276C20.9405 12.0823 21.2339 11.9009 21.5912 11.7836C21.9485 11.6663 22.3779 11.6076 22.8792 11.6076C23.2579 11.6076 23.5725 11.6396 23.8232 11.7036C24.0739 11.7623 24.2659 11.8129 24.3992 11.8556V13.1916Z"
        fill="#185AC5"
      />
    </svg>
  );
};
