import { useRef, useState } from 'react';
import { TableHeader } from '../../../../models/components/table';
import { IconQuestionMark } from '../../../ui/Icons/IconQuestionMark';
import { TableExpanded } from '../../../ui/Table';
import { AnalysisResultsContentChart } from './AnalysisResultsContentChart';
import { ClickTooltip } from '../../../ui/ClickTooltip';
import { MathExpression } from '../../../ui/MathExpression';
import styles from './AnalysisResultsContent.module.scss';
import { DocumentAnalyseBlocksData } from '../../../../models/document';

const lettersData = [
  { key: 'А', value: 'Развитие' },
  { key: 'Б', value: 'Субъектность и личность' },
  { key: 'В', value: 'Смысл и мудрость' },
  { key: 'Г', value: 'Неопределенность и преадаптивность' },
  { key: 'Д', value: 'Возможность и потенциальность' },
  { key: 'Е', value: 'Саморегуляция и личностный потенциал' },
  { key: 'Ж', value: 'Самодетерминация и мотивация' },
  { key: 'З', value: 'Жизнестойкость' },
  { key: 'И', value: 'Психологическое благополучие и позитивное образование' },
];

type AnalysisResultsContentProps = {
  documentActivePart: string;
  blockData: DocumentAnalyseBlocksData;
};

export const AnalysisResultsContent = ({
  blockData,
  documentActivePart,
}: AnalysisResultsContentProps) => {
  const [chartTooltip, setChartTooltip] = useState(false);
  const [pointsTooltip, setPointsTooltip] = useState(false);
  const [densityTooltip, setDensityTooltip] = useState(false);

  const chartTooltipRef = useRef<HTMLDivElement>(null);
  const pointsTooltipRef = useRef<HTMLDivElement>(null);
  const densityTooltipRef = useRef<HTMLDivElement>(null);

  const activeBlockData = blockData[documentActivePart];

  const pointsMathExp = `
  \\sum_{i=1}^{N} \\omega_{\\text{полное}} +
  \\sum_{i=1}^{N} \\omega_{\\text{частичное}} +
  \\sum_{i=1}^{N} \\omega_{\\text{синоним}} +
  \\sum_{i=1}^{N} \\omega_{
    \\begin{subarray}{c}
      \\text{синоним} \\\\
      \\text{частичное}
    \\end{subarray}
  }
`;

  const chartMathExp =
    '\\mathrm{\\upsilon}_{пропорциональный} = \\mathrm{\\upsilon}_{абсолютный} / max(\\mathrm{\\upsilon}_{абсолютный})';

  const headers: TableHeader[] = [
    { label: 'Название тега', width: '36%' },
    {
      label: 'Очки',
      width: '32%',
      titleIcon: (
        <div className={styles.tooltipWrapper} ref={pointsTooltipRef}>
          <span
            className={styles.tooltipWrapperIcon}
            onClick={() => setPointsTooltip((prev) => !prev)}
          >
            <IconQuestionMark />
          </span>
          <ClickTooltip
            isOpen={pointsTooltip}
            handleCloseTooltip={() => setPointsTooltip(false)}
            parentRef={pointsTooltipRef}
          >
            <p className={styles.tooltipWrapperText}>
              <b>Очки тега</b> – определяют количество совпадений тега, а также
              его синонимов согласно тезаурусу, в тексте.
              <br /> <b>Метод подсчета:</b> Очки тега =
            </p>
            <MathExpression expression={pointsMathExp} />
            <div className={styles.tooltipWrapperTextDesc}>
              <MathExpression expression="\small \omega\ – \ ценность\ совпадения" />
            </div>
          </ClickTooltip>
        </div>
      ),
    },
    {
      label: 'Плотность',
      width: '32%',
      titleIcon: (
        <div className={styles.tooltipWrapper} ref={densityTooltipRef}>
          <span
            className={styles.tooltipWrapperIcon}
            onClick={() => setDensityTooltip((prev) => !prev)}
          >
            <IconQuestionMark />
          </span>
          <ClickTooltip
            isOpen={densityTooltip}
            handleCloseTooltip={() => setDensityTooltip(false)}
            parentRef={densityTooltipRef}
            width={520}
          >
            Плотность тега - вероятность встречаемости тега (и других связанных
            с ним тегов) в тексте. Плотность рассчитывается отдельно для каждого
            тега, присутствующего в тексте. Пороговое значение, отделяющее
            низкую плотность от достаточной, равно 0.001. <b>Метод расчета:</b>
            <br />
            <br /> Плотность тега = Очки тега / Количество слов в обозначенном
            тегом текстовом блоке. <br />
            <br />
            Достаточная плотность тега {'>'}= 0.001; низкая плотность тега {'<'}{' '}
            0.001.
          </ClickTooltip>
        </div>
      ),
    },
  ];

  const tags = Object.entries(activeBlockData?.score_tag || {}).map((tag) => ({
    name: tag[0].charAt(0).toUpperCase() + tag[0].slice(1),
    score: tag[1],
    density: activeBlockData?.tag_density[tag[0]],
  }));

  const chartData = Object.entries(activeBlockData?.score_verts || {}).map(
    (item) => ({
      label: item[0],
      value: Math.round(item[1] * 100),
    })
  );

  return (
    <div className={styles.introductionWrapper}>
      <h2 className={styles.introductionTitle}>{documentActivePart}</h2>

      <div className={styles.introductionTags}>
        <h4 className={styles.introductionTagsTitle}>
          Добавленные теги автором
        </h4>
        <TableExpanded headers={headers} notExpandable>
          {tags.map((tag) => (
            <TableExpanded.Row
              headers={headers}
              notExpandable
              notClickable
              key={tag.name}
            >
              <TableExpanded.Col>
                <span className="long-string" title={tag.name}>
                  {tag.name}
                </span>
              </TableExpanded.Col>
              <TableExpanded.Col>
                <span className="long-string">{tag.score}</span>
              </TableExpanded.Col>
              <TableExpanded.Col>
                <span className="long-string">{tag.density}</span>
              </TableExpanded.Col>
              <div></div>
            </TableExpanded.Row>
          ))}
        </TableExpanded>
      </div>

      <div className={styles.introductionChart}>
        <h4 className={styles.introductionChartTitle}>
          Пропорция вертикалей ключевых принципов развития
          <div className={styles.tooltipWrapper} ref={chartTooltipRef}>
            <span
              className={styles.tooltipWrapperIcon}
              onClick={() => setChartTooltip((prev) => !prev)}
            >
              <IconQuestionMark />
            </span>
            <ClickTooltip
              isOpen={chartTooltip}
              handleCloseTooltip={() => setChartTooltip(false)}
              parentRef={chartTooltipRef}
            >
              Пропорция вертикалей ключевых принципов развития - относительная
              характеристика, вычисляемая на основании очков тегов, где у
              каждого тега есть свой ключевой принцип развития. Позволяет
              понять, какие принципы сильнее выражены в данном блоке.{' '}
              <b>Метод подсчета:</b>
              <div className={styles.chartMathExp}>
                <MathExpression expression={chartMathExp} />
              </div>
              <MathExpression expression="\small \upsilon\ – \ вектор \ вертикалей \ ключевых \ принципов \ развития" />
            </ClickTooltip>
          </div>
        </h4>
        <div className={styles.introductionChartWrapper}>
          <div className={styles.introductionChartItem}>
            <AnalysisResultsContentChart data={chartData} />
          </div>
          <ul className={styles.introductionChartList}>
            {lettersData.map((item) => (
              <li key={item.key} className={styles.introductionChartListItem}>
                {item.key} - {item.value}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
