import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';

import { HMenuLink } from '../../../../../models/components/menu';
import {
  MethodDictItem,
  QuestionItemType,
  QuestionnaireDictItem,
  SurveyConstructorType,
} from '../../../../../models/research';

//  ui
import { HMenu } from '../../../../ui/HMenu';
import { Box } from '../../../../ui/Box';
import { TextFilter } from '../../../../ui/Filters/TextFilter';
import { Button } from '../../../../ui/Button';
import { IconSurveyMenu } from '../../../../ui/Icons/Research/IconSurveyMenu';
import { ResearchSurveyMenuItem } from './ResearchSurveyMenuItem';

import styles from './ResearchSurveyMenu.module.scss';
import { log } from 'util';
import { ModalCustomQuestion } from '../../../../ui/ModalCustomQuestion';

const cx = classNames.bind(styles);

type ResearchSurveyMenuProps = {
  methods: MethodDictItem[];
  questions: {
    questionnaires: QuestionnaireDictItem[];
    questionnaires_custom: QuestionnaireDictItem[];
  };
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
  saveTemplate: (value: boolean) => void;
  downloadTemplate: (value: boolean) => void;
  disable?: boolean;
  requiredQuestion: number[];
  setRequiredQuestion: () => void;
  chosenQuestion: QuestionItemType[];
  setChosenQuestion: () => void;
};

export const ResearchSurveyMenu = ({
  methods = [],
  questions = {
    questionnaires: [],
    questionnaires_custom: [],
  },
  isMenuOpen,
  setIsMenuOpen,
  saveTemplate,
  downloadTemplate,
  disable,
  requiredQuestion,
  setRequiredQuestion,
  chosenQuestion,
  setChosenQuestion,
}: ResearchSurveyMenuProps): JSX.Element => {
  const [isMethodsActive, setMethodsIsActive] = useState(true);
  const [methodsState, setMethodsState] = useState<MethodDictItem[]>(methods);
  const [questionsState, setQuestionsState] = useState<QuestionnaireDictItem[]>(
    questions.questionnaires
  );
  const [customQuestionsState, setCustomQuestionsState] = useState<
    QuestionnaireDictItem[]
  >(questions.questionnaires_custom);
  const [selectedItem, setSelectedItem] = useState<{
    id: number;
    title: string;
    description?: string[];
    methodDescription?: any;
    questionType?: string;
    questionOrder?: string | null;
  } | null>(null);

  const menuLinks: HMenuLink[] = [
    {
      label: 'Методики',
      active: isMethodsActive,
      access: true,
      onClick: () => setMethodsIsActive(true),
    },
    {
      label: 'Анкетные вопросы',
      active: !isMethodsActive,
      access: true,
      onClick: () => setMethodsIsActive(false),
    },
  ];

  const visibleQuestionsState = useMemo(
    () =>
      questionsState.filter(
        (q) => q.survey.question_input_type !== 'dadata.hidden'
      ),
    [questionsState]
  );

  const handleSearchMethods = (value: string) => {
    const result = methods.filter((method: MethodDictItem) => {
      return method?.survey?.name
        .toLocaleLowerCase()
        .includes(value.toLocaleLowerCase());
    });
    if (value) {
      setMethodsState(result);
    } else {
      setMethodsState(methods);
    }
  };

  useEffect(() => {
    if (methods) {
      setMethodsState(methods);
    }
  }, [methods]);

  useEffect(() => {
    if (questions?.questionnaires) {
      setQuestionsState(questions.questionnaires);
    }
    if (questions?.questionnaires_custom) {
      setCustomQuestionsState(questions.questionnaires_custom);
    }
  }, [questions]);

  const handleSearchQuestions = (value: string) => {
    const result = visibleQuestionsState.filter(
      (question: QuestionnaireDictItem) => {
        return question?.survey?.question_name
          ?.toLocaleLowerCase()
          .includes(value.toLowerCase());
      }
    );
    if (value) {
      setQuestionsState(result);
    } else {
      setQuestionsState(questions.questionnaires);
    }
  };

  return (
    <div
      className={cx('wrapper', {
        wrapper__closed: !isMenuOpen,
        wrapper__disable: disable,
      })}
    >
      {isMenuOpen ? (
        <>
          <Box mb={20}>
            <Button
              display="service"
              icon={<IconSurveyMenu />}
              clicked={() => setIsMenuOpen(false)}
            />
          </Box>
          <HMenu links={menuLinks} />
          {isMethodsActive ? (
            <>
              <Box mt={24} mb={32}>
                <TextFilter
                  placeholder="Поиск по названию методики"
                  setOuterValue={handleSearchMethods}
                />
              </Box>
              <div className={styles.list}>
                {Object.values(methodsState).map((m: MethodDictItem) => (
                  <ResearchSurveyMenuItem
                    key={m.id}
                    id={m.id}
                    type={SurveyConstructorType.METHOD}
                    title={m.survey.name}
                    methodDescription={m.survey}
                    methodLink={m.link}
                    isMethod
                    disable={disable}
                    isSavedQuestion={false}
                  />
                ))}
              </div>
              {!disable && (
                <>
                  <Box mt={32}>
                    <Button
                      label="Загрузить опрос из сохраненных шаблонов"
                      display="service"
                      color="primary"
                      size="sm"
                      clicked={() => downloadTemplate(true)}
                    />
                  </Box>
                  <Box mt={24} mb={32}>
                    <Button
                      label="Сохранить опросник в виде шаблона"
                      display="empty"
                      color="black"
                      clicked={() => saveTemplate(true)}
                    />
                  </Box>
                </>
              )}
            </>
          ) : (
            <>
              <Box mt={24} mb={32}>
                <TextFilter
                  placeholder="Поиск по названию вопроса"
                  setOuterValue={handleSearchQuestions}
                />
              </Box>
              <div className={styles.list}>
                {visibleQuestionsState.map((q: QuestionnaireDictItem) => (
                  <ResearchSurveyMenuItem
                    key={q.id}
                    id={q.id}
                    type={SurveyConstructorType.QUESTION}
                    title={q.survey.question_name}
                    questionType={q.survey.question_input_type}
                    description={q.survey.question_answers}
                    questionOrder={q.survey.question_order}
                    disable={disable}
                    isSavedQuestion={false}
                  />
                ))}
                <Box mt={32} mb={32}>
                  <div>Сохраненные вопросы</div>
                </Box>
                {customQuestionsState.map((q: QuestionnaireDictItem) => (
                  <ResearchSurveyMenuItem
                    key={q.id}
                    id={q.id}
                    type={SurveyConstructorType.QUESTION}
                    title={q.survey.question_name}
                    questionType={q.survey.question_input_type}
                    description={q.survey.question_answers}
                    questionRequired={q.survey.question_required}
                    disable={disable}
                    isSavedQuestion
                    requiredQuestion={requiredQuestion}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setRequiredQuestion={setRequiredQuestion}
                    chosenQuestion={chosenQuestion}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setChosenQuestion={setChosenQuestion}
                  />
                ))}
              </div>
            </>
          )}
        </>
      ) : (
        <div className={styles.openIcon}>
          <Button
            display="service"
            icon={<IconSurveyMenu />}
            clicked={() => setIsMenuOpen(true)}
          />
        </div>
      )}
    </div>
  );
};
