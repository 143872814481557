import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';

import { UploadableFile } from '../../../../models/files';
import { defineFileMIMEType } from '../../../../utils/files';

//  ui
import { Button } from '../../Button';

import styles from './UploadField.module.scss';
const cx = classNames.bind(styles);

type UploadFieldProps = {
  title?: string;
  isSingleFile?: boolean;
  fileExtensions?: string[];
  sizeLimit: number; // bytes
  onFileSelected: (files: UploadableFile[]) => void;
  align?: 'left' | 'center' | 'right';
  gradient: boolean;
  isLoading: boolean;
};

export const UploadField = ({
  title,
  isSingleFile,
  fileExtensions = ['.docx'],
  sizeLimit,
  onFileSelected,
  align = 'left',
  gradient,
  isLoading,
}: UploadFieldProps): JSX.Element => {
  const [files, setFiles] = useState<UploadableFile[]>([]);
  const fileRef = useRef<HTMLInputElement>(null);
  const handleClick = (): void => {
    fileRef.current?.click();
  };

  const handleChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const files = (e.target as HTMLInputElement).files || [];
    const acceptedFiles = Array.from(files);

    const detectErrors = (file: File) => {
      const fileType = defineFileMIMEType(file.type);
      const errors = [];
      if (sizeLimit && file.size > sizeLimit) {
        errors.push('size');
      }
      if (fileExtensions && !fileExtensions.includes(fileType)) {
        errors.push('type');
      }
      return errors;
    };

    const mappedAcceptedFiles: UploadableFile[] = acceptedFiles.map(
      (file: File, idx) => ({
        file,
        id: `${file.name}_${idx}`,
        errors: detectErrors(file),
      })
    );

    if (isSingleFile) {
      onFileSelected(mappedAcceptedFiles);
    } else {
      setFiles((prev: UploadableFile[]) => [...prev, ...mappedAcceptedFiles]);
    }
  };

  useEffect(() => {
    onFileSelected(files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    <div className={styles.wrapper}>
      <div
        className={cx('btn', {
          btn_left: align === 'left',
          btn_center: align === 'center',
          btn_right: align === 'right',
        })}
      >
        <Button
          withoutBackground={!gradient}
          gradient={gradient}
          label={title ? title : 'Загрузить документ'}
          clicked={handleClick}
          disabled={isLoading}
        />
      </div>
      <input
        ref={fileRef}
        type="file"
        accept={fileExtensions.join(',')}
        multiple={!isSingleFile}
        onChange={handleChange}
      />
    </div>
  );
};
