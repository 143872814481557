import { Form, FormikContext, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { VALIDATION_ERRORS } from '../../../../../models/validations/errors/errors';
import {
  restorePassInitialValues,
  restorePassValidationSchema,
} from '../../../../../models/validations/schemas/validations';
import { useForgotPassMutation } from '../../../../../store/VbudusheeAPI';
import { Button } from '../../../../ui/Button';
import { InputText } from '../../../../ui/Form/InputText';
import { InfoModal } from '../../../../ui/InfoModal';
import styles from './RestoreForm.module.scss';
import { ServerErrors } from '../../../../../constants/errors';

export const RestoreForm = () => {
  const [forgot, { isLoading, error }] = useForgotPassMutation();
  const [serverError, setServerError] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>(undefined);

  const handleSubmit = async (values: typeof restorePassInitialValues) => {
    if (isLoading) {
      return;
    }

    setServerError(undefined);
    const result = await forgot(values);

    if ('data' in result) {
      setEmail(values.email);
    }
  };

  useEffect(() => {
    if (error && 'data' in error) {
      const serverError = error.data as { error: string; message: string };
      if (
        serverError.error === ServerErrors.PASSWORD_FORM_NOT_REGISTERED ||
        serverError.message === ServerErrors.SELECTED_EMAIL_IS_INVALID
      ) {
        setServerError(VALIDATION_ERRORS.server.emailIncorrect);
      } else {
        setServerError(serverError.message);
      }
    }
  }, [error]);

  const formik = useFormik({
    initialValues: restorePassInitialValues,
    validationSchema: restorePassValidationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (serverError) {
      formik.setErrors({ email: serverError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverError]);

  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <div className={styles.form}>
          <InputText name="email" placeholder="Email" />
        </div>
        <div className={styles.form__submit}>
          <Button
            label={isLoading ? 'Загрузка...' : 'Отправить'}
            type="submit"
            disabled={isLoading}
            gradient={true}
          />
        </div>
      </Form>
      <InfoModal
        isOpen={Boolean(email)}
        titleText="Восстановление пароля"
        // message={`Мы отправили письмо со ссылкой для изменения пароля по адресу ${email}`}
        message={
          'Мы отправили письмо со ссылкой для изменения пароля по адресу'
        }
        email={email}
        buttonText="Ок"
        onAccept={() => setEmail(undefined)}
        onReject={() => setEmail(undefined)}
      />
    </FormikContext.Provider>
  );
};
