// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ParticipantCard_card_wraper__name__nPGG\\+ {
  font: "SB Sans Text";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #808185;
}
.ParticipantCard_card_wraper__email__ewNp8 {
  font: "SB Sans Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}
.ParticipantCard_card_wraper__link__jCiP- {
  margin-top: 8px;
  font: "SB Sans Display";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #EC1B28;
}
.ParticipantCard_card_wraper__link__jCiP-:hover {
  text-decoration: none;
  color: #EC1B28;
}
.ParticipantCard_card_wraper__moderator_delete__REjKx {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-family: "SB Sans Text";
  color: #000000;
  background-color: #F2F4F7;
  border-radius: 16px;
  height: 24px;
  width: 130px;
  padding: 2px 8px 2px 12px;
  gap: 7px;
}
.ParticipantCard_card_wraper__cross__1oHkW:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/ParticipantCard/ParticipantCard.module.scss"],"names":[],"mappings":"AAEC;EACC,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADF;AAIC;EACC,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAFF;AAKC;EACC,eAAA;EACA,uBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AAHF;AAKE;EACC,qBAAA;EACA,cAAA;AAHH;AAOC;EACC,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,2BAAA;EACA,cAAA;EACA,yBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;EACA,QAAA;AALF;AASE;EACC,eAAA;AAPH","sourcesContent":[".card_wraper {\n\n\t&__name {\n\t\tfont: 'SB Sans Text';\n\t\tfont-style: normal;\n\t\tfont-weight: 400;\n\t\tfont-size: 12px;\n\t\tline-height: 18px;\n\t\tcolor: #808185;\n\t}\n\n\t&__email {\n\t\tfont: 'SB Sans Text';\n\t\tfont-style: normal;\n\t\tfont-weight: 400;\n\t\tfont-size: 14px;\n\t\tline-height: 22px;\n\t\tcolor: #000000;\n\t}\n\n\t&__link {\n\t\tmargin-top: 8px;\n\t\tfont: 'SB Sans Display';\n\t\tfont-style: normal;\n\t\tfont-weight: 400;\n\t\tfont-size: 12px;\n\t\tline-height: 26px;\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tcolor: #EC1B28;\n\n\t\t&:hover {\n\t\t\ttext-decoration: none;\n\t\t\tcolor: #EC1B28;\n\t\t}\n\t}\n\n\t&__moderator_delete {\n\t\tdisplay: flex;\n\t\tflex-direction: row;\n\t\talign-items: center;\n\t\tmargin-top: 8px;\n\t\tfont-style: normal;\n\t\tfont-weight: 400;\n\t\tfont-size: 12px;\n\t\tline-height: 16px;\n\t\tfont-family: 'SB Sans Text';\n\t\tcolor: #000000;\n\t\tbackground-color: #F2F4F7;\n\t\tborder-radius: 16px;\n\t\theight: 24px;\n\t\twidth: 130px;\n\t\tpadding: 2px 8px 2px 12px;\n\t\tgap: 7px;\n\t}\n\n\t&__cross {\n\t\t&:hover {\n\t\t\tcursor: pointer;\n\t\t}\n\t}\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card_wraper__name": `ParticipantCard_card_wraper__name__nPGG+`,
	"card_wraper__email": `ParticipantCard_card_wraper__email__ewNp8`,
	"card_wraper__link": `ParticipantCard_card_wraper__link__jCiP-`,
	"card_wraper__moderator_delete": `ParticipantCard_card_wraper__moderator_delete__REjKx`,
	"card_wraper__cross": `ParticipantCard_card_wraper__cross__1oHkW`
};
export default ___CSS_LOADER_EXPORT___;
