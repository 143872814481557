import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './SurveyForm.module.scss';
import classNames from 'classnames/bind';

import { Company, REGIONS } from '../../../models/daData';
import { defaultFormikOptions } from '../../../models/formik';
import { GetKladrByRegionResponse } from '../../../models/user/registration';
import {
  AGE_CATEGORIES,
  PROF_CATEGORIES,
} from '../../../constants/survey/survey';
import { VALIDATION_ERRORS } from '../../../models/validations/errors/errors';

import {
  useGetKladrByRegionMutation,
  useRegistrationMutation,
} from '../../../store/VbudusheeAPI';

//  ui
import { Button } from '../../ui/Button';
import { CheckBox } from '../../ui/Form/CheckBox';
import { InputText } from '../../ui/Form/InputText';
import { Select } from '../../ui/Form/Select';
import { SelectSearch } from '../../ui/Form/SelectSearch';

import {
  startSurveyInitialValues,
  startSurveyValidationSchema,
} from '../../../models/validations/schemas/validations';
import { frontendRoutes } from '../../../utils/router/routes';
import { InputCompanyName } from '../../ui/Form/InputCompanyName';
import {
  ResearchDetails as ResearchDetailsType,
  ResearchSurvey,
} from '../../../models/research';
import { downloadPolicy } from '../../../utils/api/downloadFiles';

const cx = classNames.bind(styles);

type PropsTypes = {
  setStep: (value: string) => void;
};

export const SurveyForm = ({ setStep }: PropsTypes) => {
  const { researchId } = useParams();
  // const navigate = useNavigate();
  // const [getKladr] = useGetKladrByRegionMutation();
  // const [serverError, setServerError] = useState<string | undefined>();
  // const [company, setCompany] = useState<Company | undefined>(undefined);
  // const [kladrs, setKladrs] = useState<GetKladrByRegionResponse>([]);

  // const [isApproveOnResearch, setIsApproveOnResearch] = useState(false);
  const [isApprovePersonalData, setIsApprovePersonalData] = useState(false);

  const validHref = useMemo(() => {
    const API_URL = process.env.REACT_APP_API_URL?.slice(0, -3);
    return `${API_URL}files/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%B2_%D0%BE%D1%82%D0%BD%D0%BE%D1%88%D0%B5%D0%BD%D0%B8%D0%B8_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8_%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_1.pdf`;
  }, []);

  // const regions: string[] = [];
  // for (const region in REGIONS) regions.push(region);

  // const handleSubmit = () => {
  // if (!isApprovePersonalData) {
  //   setServerError(VALIDATION_ERRORS.policy);
  // } else if (!isApproveOnResearch) {
  //   setServerError(VALIDATION_ERRORS.policy2);
  // } else {
  //   //TODO added request for server
  //   navigate(frontendRoutes.survey.questionnaire);
  // }
  // // eslint-disable-next-line no-console
  // console.log(values);
  // };

  // const formik = useFormik({
  //   initialValues: startSurveyInitialValues,
  //   validationSchema: startSurveyValidationSchema,
  //   onSubmit: handleSubmit,
  //   ...defaultFormikOptions,
  // });

  // const handleRegionChange = useCallback(async () => {
  //   formik.setFieldValue('organization_name', '');
  //   formik.setFieldValue('locality', '');
  //   const region = formik.values.region;
  //
  //   if (region !== '') {
  //     const regionKladr =
  //       region.toString().length > 1
  //         ? region.toString()
  //         : `0${region.toString()}`;
  //
  //     const result = await getKladr({ region_number: regionKladr });
  //     if ('data' in result) {
  //       setKladrs(result.data);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formik.values.region, getKladr]);
  //
  // useEffect(() => {
  //   handleRegionChange();
  // }, [handleRegionChange]);
  //
  // useEffect(() => {
  //   formik.setFieldValue('organization_name', '');
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formik.values.organization_category]);
  //
  // useEffect(() => {
  //   formik.setFieldValue('organization_name', company?.value);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [company]);

  return (
    <div>
      <div className={styles.form}>
        {/*<div className={styles.form__block}>*/}
        {/*  <div className={styles.form__header}>Организация</div>*/}
        {/*  <SelectSearch*/}
        {/*    name="region"*/}
        {/*    placeholder="Регион*"*/}
        {/*    options={regions.map((region) => ({*/}
        {/*      option: REGIONS[parseInt(region)],*/}
        {/*      value: region,*/}
        {/*    }))}*/}
        {/*  />*/}

        {/*  <InputCompanyName*/}
        {/*    name="organization_name"*/}
        {/*    placeholder="К какой организации вы относитесь?*"*/}
        {/*    setCompany={setCompany}*/}
        {/*    localityKladr={formik.values.locality}*/}
        {/*    organization_category={formik.values.organization_category}*/}
        {/*    disabled={formik.values.locality === ''}*/}
        {/*  />*/}

        {/*  <SelectSearch*/}
        {/*    name="locality"*/}
        {/*    placeholder="Населенный пункт*"*/}
        {/*    options={kladrs.map((kladr) => {*/}
        {/*      return { option: kladr.address, value: kladr.kladr_id };*/}
        {/*    })}*/}
        {/*    disabled={!formik.values.region}*/}
        {/*  />*/}

        {/*  <div className={styles.form__header}>Основная информация</div>*/}
        {/*  <div className={cx('form__item', 'form__item_33')}>*/}
        {/*    <Select*/}
        {/*      name="prof_categories"*/}
        {/*      label="Ваша профкатегория*"*/}
        {/*      options={PROF_CATEGORIES}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*  <div className={cx('form__item', 'form__item_33')}>*/}
        {/*    <Select*/}
        {/*      name="age_categories"*/}
        {/*      label="Ваша возрастная категория*"*/}
        {/*      options={AGE_CATEGORIES}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*{serverError && <div className={styles.form__error}>{serverError}</div>}*/}

        {/*<div className={styles.form__policy_wrapper}>*/}
        {/*  <CheckBox*/}
        {/*    name={'consent_to_study'}*/}
        {/*    checked={isApproveOnResearch}*/}
        {/*    onChange={() => setIsApproveOnResearch(!isApproveOnResearch)}*/}
        {/*  />*/}

        {/*  <div className={styles.form__policy}>*/}
        {/*    <div>Согласие на прохождение исследования</div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className={styles.form__policy_wrapper}>
          <CheckBox
            name={'processing_of_personal_data'}
            checked={isApprovePersonalData}
            onChange={() => setIsApprovePersonalData(!isApprovePersonalData)}
          />

          <div className={styles.form__policy}>
            <div>
              Я даю
              <span
                onClick={() => {
                  if (researchId) {
                    downloadPolicy(researchId, 'Согласие');
                  }
                }}
              >
                &nbsp;согласие&nbsp;
              </span>
              на обработку персональных данных в соответствии с{/*<span*/}
              {/*  onClick={() => {*/}
              {/*    if (researchId) {*/}
              {/*      downloadPolicy(*/}
              {/*        researchId,*/}
              {/*        'Политика по обработке персональных данных'*/}
              {/*      );*/}
              {/*    }*/}
              {/*  }}*/}
              {/*>*/}
              {/*  &nbsp;Политикой&nbsp;*/}
              {/*</span>*/}
              <a href={validHref}>&nbsp;Политикой&nbsp;</a>
              по обработке персональных данных
            </div>
          </div>
        </div>

        <div className={styles.form__submit}>
          <Button
            label="Далее"
            type="button"
            disabled={!isApprovePersonalData}
            width={152}
            gradient
            clicked={() => {
              setStep('quiz');
            }}
          />
        </div>
      </div>
    </div>
  );
};
