export const IconCloseRound = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="15.5" stroke="#F6F6F6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1175 10.1514C21.3194 9.94953 21.6467 9.94953 21.8486 10.1514C22.0505 10.3533 22.0505 10.6806 21.8486 10.8825L16.7312 15.9999L21.8488 21.1175C22.0507 21.3194 22.0507 21.6467 21.8488 21.8486C21.6469 22.0505 21.3196 22.0505 21.1177 21.8486L16.0001 16.731L10.8825 21.8486C10.6806 22.0505 10.3533 22.0505 10.1514 21.8486C9.94953 21.6467 9.94953 21.3194 10.1514 21.1175L15.269 15.9999L10.1516 10.8825C9.94969 10.6806 9.94969 10.3533 10.1516 10.1514C10.3535 9.94953 10.6808 9.94953 10.8826 10.1514L16.0001 15.2689L21.1175 10.1514Z"
        fill="#333F48"
      />
    </svg>
  );
};
