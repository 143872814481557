import { DictItem } from '../../models/basic';

export const uniqueArr = <T extends number | string>(arr: T[]): T[] => {
  if (arr) {
    return Array.from(new Set(arr));
  }
  return [];
};

export const getValueFromDictionary = (
  arr: DictItem[] | undefined,
  k: string | undefined
): string => {
  if (arr && arr.length > 0 && k) {
    const targetValue = arr.find(
      (item: DictItem) => item['key'] === k.trim()
    )?.value;
    if (targetValue) {
      return targetValue;
    } else {
      return 'Нет данных';
    }
  } else {
    return 'Нет данных';
  }
};

export const getKeyValueObjFromDictionary = (
  dictionary: DictItem[] | undefined,
  key: string
) => {
  if (dictionary && Array.isArray(dictionary) && dictionary.length > 0) {
    const target = dictionary.filter((item) => item.key === key);
    return target[0];
  }
  return null;
};

export const generateOptionsArr = (
  optionsArr: string[] | undefined
): DictItem[] => {
  const result: DictItem[] = [];
  if (Array.isArray(optionsArr) && optionsArr.length > 0) {
    optionsArr.forEach((item, i) => {
      const obj = {
        key: item,
        value: item,
      };
      result.push(obj);
    });
  }
  return result;
};

export const toKeyAndValue = (obj?: Record<string, any>) => {
  if (!obj) return [];

  return Object.entries(obj).map((item) => ({ key: item[0], value: item[1] }));
};

export const sumObjValues = (obj?: Record<string, any>) => {
  if (!obj) return 0;

  return Object.values(obj).reduce((acc, val) => acc + val, 0);
};
