// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResearchDetailsReportCheckboxList_checkboxes__IGVD5 {
  margin-left: 73px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 45px;
  max-height: 170px;
  overflow: auto;
  padding-right: 15px;
}

.ResearchDetailsReportCheckboxList_loaderWrapper__09QED {
  padding-top: 60px;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/research/ResearchDetails/ResearchDetailsReport/ResearchDetailsReportCheckboxList/ResearchDetailsReportCheckboxList.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,aAAA;EACA,qCAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AACJ;;AAEA;EACI,iBAAA;EACA,cAAA;AACJ","sourcesContent":[".checkboxes {\n    margin-left: 73px;\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    column-gap: 45px;\n    max-height: 170px;\n    overflow: auto;\n    padding-right: 15px;\n}\n\n.loaderWrapper {\n    padding-top: 60px;\n    margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxes": `ResearchDetailsReportCheckboxList_checkboxes__IGVD5`,
	"loaderWrapper": `ResearchDetailsReportCheckboxList_loaderWrapper__09QED`
};
export default ___CSS_LOADER_EXPORT___;
