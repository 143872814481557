import { ReactElement, useState } from 'react';
import classNames from 'classnames/bind';

//  ui
import styles from './Filters.module.scss';
const cx = classNames.bind(styles);

type FiltersProps = {
  children: ReactElement;
  hiddenButton?: boolean;
  isOpen?: boolean;
};

export const Filters = ({ children, hiddenButton, isOpen }: FiltersProps) => {
  const [isHidden, setHidden] = useState(false);

  return (
    <div className={styles.filter}>
      {!hiddenButton && (
        <div className={styles.filter__hide}>
          <span onClick={() => setHidden((prev) => !prev)}>
            {isHidden ? 'Показать фильтры' : 'Скрыть фильтры'}
          </span>
        </div>
      )}
      <div
        className={cx('container', {
          container_hidden: isHidden || isOpen,
        })}
      >
        {children}
      </div>
    </div>
  );
};
