//  ui
import { Layout } from '../../../components/Layout';
import { Registration } from '../../../components/pages/auth/Registration';

export const RegistrationPage = () => {
  return (
    <Layout>
      <Registration />
    </Layout>
  );
};
