import { Layout } from '../../components/Layout';
import { AutomaticAnalysisResults } from '../../components/pages/automaticAnalysisResults';

export const AutomaticAnalysisResultsPage = () => {
  return (
    <Layout>
      <AutomaticAnalysisResults />
    </Layout>
  );
};
