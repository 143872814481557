export enum PublicEventStatus {
  REFUSED = 'REFUSED',
  ACCEPTED = 'ACCEPTED',
  CHECKING = 'CHECKING',
  NEW = 'NEW',
  INSUFFICIENT = 'INSUFFICIENT',
}

export const PUBLIC_EVENT_STATUSES = {
  REFUSED: 'Отклонено',
  ACCEPTED: 'Допущено',
  CHECKING: 'На проверке',
  NEW: 'Новая заявка',
  INSUFFICIENT: 'Требуется доработка',
};

export const EVENT_STATUSES = {
  planned: 'Ожидается',
  ongoing: 'Активно',
  completed: 'Завершено',
};

export const EVENT_PUBLICATION_STATUSES = {
  published: 'Опубликовано',
  unpublished: 'Не опубликовано',
};
