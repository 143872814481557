import ImageOne from '../../assets/images/MainPage/options/ImageOne.png';
import ImageTwo from '../../assets/images/MainPage/options/ImageTwo.png';
import ImageFree from '../../assets/images/MainPage/options/ImageFree.png';
import ImageFour from '../../assets/images/MainPage/options/ImageFour.png';
import ImageFive from '../../assets/images/MainPage/options/ImageFive.png';
import ImageSix from '../../assets/images/MainPage/options/ImageSix.png';

export const OptionItems = [
  {
    img: ImageOne,
    title: 'Бесплатный доступ',
    description: 'Для использования всех инструментов достаточно регистрации',
  },
  {
    img: ImageTwo,
    title: 'Научная обоснованность',
    description:
      'Научные подходы в сфере развития личности и трансформации образования',
  },
  {
    img: ImageFree,
    title: 'Открытые данные',
    description:
      'Возможность пользоваться базой обобщенных результатов исследований',
  },
  {
    img: ImageFour,
    title: 'Гибкие решения',
    description:
      'Самостоятельная настройка инструментов под решение индивидуальных задач',
  },
  {
    img: ImageFive,
    title: 'Командная работа',
    description:
      'Возможность совместного доступа к материалам и результатам исследований',
  },
  {
    img: ImageSix,
    title: 'Простота в использовании',
    description:
      'Проведение исследования стало легче с автоматизацией рутинных процессов',
  },
];
