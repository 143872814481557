import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TableHeader } from '../../../../models/components/table';
import { materialFeedbackFiltersInitialValues } from '../../../../models/validations/schemas/validations';
import { frontendRoutes } from '../../../../utils/router/routes';

//  ui
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { HMenu } from '../../../ui/HMenu';
import { MaterialsFeedbackFilters } from './MaterialsFeedbackFilters';

import styles from './MaterialsFeedback.module.scss';
import { useGetMyFeedbackQuery } from '../../../../store/VbudusheeAPI';
import {
  formatDateStr,
  validateDateFromCalendar,
} from '../../../../utils/dates';
import { Loader } from '../../../ui/Loader';
import { TableExpanded } from '../../../ui/Table';
import { IFeedback } from '../../../../models/feedback';
import { Pagination } from '../../../ui/Pagination';

export const MaterialsFeedback = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<
    typeof materialFeedbackFiltersInitialValues
  >(materialFeedbackFiltersInitialValues);

  const paginationOptions = useMemo(() => {
    return {
      research_name: filters.search || undefined,
      research_creator: filters.author || undefined,
      organization_name: filters.organization_name || undefined,
      feedback_start_date: filters.dates
        ? validateDateFromCalendar(filters.dates.split(';')[0])
        : undefined,
      feedback_end_date: filters.dates
        ? validateDateFromCalendar(filters.dates.split(';')[1])
        : undefined,
      page,
    };
  }, [filters, page]);

  const { data, isFetching, isError } = useGetMyFeedbackQuery(
    paginationOptions,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const feedbackData = data?.data || [];

  const totalEvents = data?.meta.total || 0;
  const eventsPerPage = data?.meta.per_page || 1;
  const totalPages = Math.ceil(totalEvents / eventsPerPage);

  const currentPage = data?.meta.current_page || 0;

  const researchAuthorsFilters = data?.filters.research_creator || [];
  const organizationsFilters = data?.filters.organization_name || [];

  const handleResetPage = () => {
    setPage(1);
  };

  const headers: TableHeader[] = [
    { label: 'Название исследования', width: '29%' },
    { label: 'Автор исследования', width: '23%' },
    { label: 'Название организации', width: '20%' },
    { label: 'Дата предоставления', width: '20%' },
  ];

  const menuLinks = [
    {
      label: 'Мероприятия',
      onClick: () => navigate(frontendRoutes.materials.events),
      active: false,
      access: true,
    },
    {
      label: 'Исследования',
      onClick: () => navigate(frontendRoutes.materials.research),
      active: false,
      access: true,
    },
    {
      label: 'Документы',
      onClick: () => navigate(frontendRoutes.materials.docs),
      active: false,
      access: true,
    },
    {
      label: 'Обратная связь',
      onClick: () => navigate(frontendRoutes.materials.feedback),
      active: true,
      access: true,
    },
  ];

  return (
    <div className={styles.team}>
      <div className="local-container">
        <Breadcrumbs links={[{ title: 'Мои материалы' }]} homeLink="/" />
      </div>
      <div className={styles.team__header}>Мои материалы</div>
      <div className={styles.team__navigation}>
        <HMenu links={menuLinks} />
      </div>
      <div className={styles.team__filters}>
        <MaterialsFeedbackFilters
          setFilters={setFilters}
          researchAuthors={researchAuthorsFilters}
          organizations={organizationsFilters}
          handleResetPage={handleResetPage}
        />
      </div>
      <div className="container">
        <TableExpanded headers={headers} notExpandable>
          {isFetching ? (
            <Loader.FullPage>
              <Loader />
            </Loader.FullPage>
          ) : isError || feedbackData?.length < 1 ? (
            <TableExpanded.NoTable
              isError={isError}
              dataLength={feedbackData.length}
            />
          ) : (
            <>
              {feedbackData.map((feedback: IFeedback) => (
                <TableExpanded.Row
                  key={feedback.id}
                  headers={headers}
                  notExpandable
                >
                  <TableExpanded.Col>
                    <span
                      className="long-string"
                      title={feedback?.research_name}
                      onClick={() =>
                        navigate(
                          `${frontendRoutes.survey.feedback}/${feedback.quiz_answer_id}`
                        )
                      }
                    >
                      {feedback?.research_name}
                    </span>
                  </TableExpanded.Col>
                  <TableExpanded.Col>
                    <span className="long-string">
                      {`
                              ${feedback.research_creator.first_name} 
                              ${feedback.research_creator.middle_name} 
                              ${feedback.research_creator.last_name}
                            `}
                    </span>
                  </TableExpanded.Col>
                  <TableExpanded.Col>
                    <span className="long-string">
                      {feedback.research_creator.organization_name}
                    </span>
                  </TableExpanded.Col>
                  <TableExpanded.Col>
                    <span className="long-string">
                      {formatDateStr(feedback.answer_date, {
                        isDateOnly: true,
                      })}
                    </span>
                  </TableExpanded.Col>
                  <div></div>
                </TableExpanded.Row>
              ))}
            </>
          )}
        </TableExpanded>
        <div className={styles.pagination}>
          {!isFetching && (
            <Pagination
              setPage={setPage}
              currentPage={currentPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>
    </div>
  );
};
