import React from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import classNames from 'classnames/bind';

import { defaultFormikOptions } from '../../../../../models/formik';
import {
  teamEventsFiltersInitialValues,
  teamEventsFiltersValidationSchema,
} from '../../../../../models/validations/schemas/validations';
import { Roles, roles } from '../../../../../constants/roles';
import { OCCUPATION } from '../../../../../constants/user';

//  ui
import { Button } from '../../../../ui/Button';
import { Filters } from '../../../../ui/Filters';
import { InputText } from '../../../../ui/Form/InputText';
import { InputDate } from '../../../../ui/Form/InputDate';
import { Select } from '../../../../ui/Form/Select';

import styles from './TeamEventFilters.module.scss';
const cx = classNames.bind(styles);

type TeamEventFiltersTypes = {
  setFilters: React.Dispatch<
    React.SetStateAction<typeof teamEventsFiltersInitialValues>
  >;
};

export const TeamEventFilters = ({ setFilters }: TeamEventFiltersTypes) => {
  const handleFiltersSubmit = (
    values: typeof teamEventsFiltersInitialValues
  ) => {
    setFilters(values);
  };

  const formik = useFormik({
    initialValues: teamEventsFiltersInitialValues,
    validationSchema: teamEventsFiltersValidationSchema,
    onSubmit: handleFiltersSubmit,
    ...defaultFormikOptions,
  });

  const handleFiltersReset = () => {
    setFilters(teamEventsFiltersInitialValues);
    formik.resetForm();
  };

  return (
    <div className={styles.filter}>
      <Filters>
        <FormikContext.Provider value={formik}>
          <Form>
            <div className={styles.form}>
              <div className={cx('form__item', 'form__item_search')}>
                <InputText name="search" placeholder="Поиск" fullWidth={true} />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <Select
                  fullWidth={true}
                  name="role"
                  label="Роль"
                  options={[
                    { value: roles.owner, key: Roles.OWNER },
                    { value: roles.expert, key: Roles.EXPERT },
                    { value: roles.member, key: Roles.MEMBER },
                  ]}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <InputDate
                  isRange
                  name="dates"
                  fullWidth={true}
                  placeholder="Сроки проведения"
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <Select
                  fullWidth={true}
                  name="status"
                  label="Статус проведения"
                  options={OCCUPATION}
                />
              </div>
              <div className={styles.form__item}>
                <Select
                  fullWidth={true}
                  name="publicaction"
                  label="Заявлено на публикацию"
                  options={OCCUPATION}
                />
              </div>
              <div className={styles.form__item}>
                <Select
                  fullWidth={true}
                  name="tags"
                  label="Тематические теги"
                  options={OCCUPATION}
                />
              </div>
            </div>
            <div className={styles.buttons}>
              <div onClick={handleFiltersReset}>
                <Button label="Сбросить" withoutBackground={true} />
              </div>
              <div onClick={() => formik.handleSubmit()}>
                <Button label="Применить" gradient={true} />
              </div>
            </div>
          </Form>
        </FormikContext.Provider>
      </Filters>
    </div>
  );
};
