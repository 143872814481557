import React from 'react';
import { Layout } from '../../components/Layout';
import { Breadcrumbs } from '../../components/ui/Breadcrumbs';

export const RequestOnPublishResearch = (): JSX.Element => {
  return (
    <Layout>
      <div className="outer-container">
        <Breadcrumbs
          links={[
            { title: 'Реестр заявок на публикацию исследований', link: '#' },
          ]}
          homeLink="/"
        />
      </div>
    </Layout>
  );
};
