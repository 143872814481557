import React from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import classNames from 'classnames/bind';

import { defaultFormikOptions } from '../../../../../models/formik';
import {
  teamDocsFiltersInitialValues,
  teamDocsFiltersValidationSchema,
} from '../../../../../models/validations/schemas/validations';
import { OCCUPATION } from '../../../../../constants/user';

//  ui
import { Filters } from '../../../../ui/Filters';
import { InputText } from '../../../../ui/Form/InputText';
import { Select } from '../../../../ui/Form/Select';
import { Button } from '../../../../ui/Button';
import { InputDate } from '../../../../ui/Form/InputDate';

import styles from './TeamDocsFilters.module.scss';
const cx = classNames.bind(styles);

type TeamDocsFiltersTypes = {
  setFilters: React.Dispatch<
    React.SetStateAction<typeof teamDocsFiltersInitialValues>
  >;
};

export const TeamDocsFilters = ({ setFilters }: TeamDocsFiltersTypes) => {
  const handleFiltersSubmit = (values: typeof teamDocsFiltersInitialValues) => {
    setFilters(values);
  };

  const formik = useFormik({
    initialValues: teamDocsFiltersInitialValues,
    validationSchema: teamDocsFiltersValidationSchema,
    onSubmit: handleFiltersSubmit,
    ...defaultFormikOptions,
  });

  const handleFiltersReset = () => {
    setFilters(teamDocsFiltersInitialValues);
    formik.resetForm();
  };

  return (
    <div className={styles.filter}>
      <Filters>
        <FormikContext.Provider value={formik}>
          <Form>
            <div className={styles.form}>
              <div className={cx('form__item', 'form__item_search')}>
                <InputText name="search" placeholder="Поиск" fullWidth={true} />
              </div>
              <div className={cx('form__item')}>
                <Select
                  fullWidth={true}
                  name="owner"
                  label="Владелец документа"
                  options={OCCUPATION}
                />
              </div>
              <div className={cx('form__item')}>
                <Select
                  fullWidth={true}
                  name="event_name"
                  label="Название мероприятия"
                  options={OCCUPATION}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <Select
                  fullWidth={true}
                  name="oo_name"
                  label="Название организации"
                  options={OCCUPATION}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <Select
                  fullWidth={true}
                  name="oo_type"
                  label="Тип организации"
                  options={OCCUPATION}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <InputDate
                  isRange
                  name="dates"
                  fullWidth={true}
                  placeholder="Даты загрузки документа"
                  align="right"
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <Select
                  fullWidth={true}
                  name="tags"
                  label="Тематические теги"
                  options={OCCUPATION}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <Select
                  fullWidth={true}
                  name="type"
                  label="Тип документа"
                  options={OCCUPATION}
                />
              </div>
            </div>
            <div className={styles.buttons}>
              <div onClick={handleFiltersReset}>
                <Button label="Сбросить" withoutBackground={true} />
              </div>
              <div onClick={() => formik.handleSubmit()}>
                <Button label="Применить" gradient={true} />
              </div>
            </div>
          </Form>
        </FormikContext.Provider>
      </Filters>
    </div>
  );
};
