// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResearchComparison_wrapper__2afmU {
  position: relative;
  display: flex;
  border-top: 1px solid #d0d7dd;
  min-height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/pages/research/ResearchComparison/ResearchComparison.module.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,aAAA;EACA,6BAAA;EACA,iBAAA;AADJ","sourcesContent":["@import '../../../assets/helpers/colors.scss';\n\n.wrapper {\n    position: relative;\n    display: flex;\n    border-top: 1px solid $gray5;\n    min-height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ResearchComparison_wrapper__2afmU`
};
export default ___CSS_LOADER_EXPORT___;
