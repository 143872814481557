import React from 'react';

//ui
import { Layout } from '../../../components/Layout';
import { SurveyLogin } from '../../../components/Survey/SurveyLogin/SurveyLogin';

export const SurveyLoginPage = () => {
  return (
    <Layout>
      <SurveyLogin />
    </Layout>
  );
};
