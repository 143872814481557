import { useNavigate } from 'react-router-dom';

//  ui
import { Layout } from '../../../components/Layout';
import { PublicEventsCatalog } from '../../../components/pages/publicEventsCatalog/PublicEventsCatalog';

export const PublicEventsPage = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <PublicEventsCatalog />
    </Layout>
  );
};
