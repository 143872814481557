// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { scaleBand, scaleLinear, max, range } from 'd3';
import classNames from 'classnames/bind';
import styles from './BarChartComparison.module.scss';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ComparisonResearchResponse,
  ResearchStatsSurveyStatisticsAggr,
} from '../../../../models/research';
import { Typography } from '../../Typography';
import { Box } from '../../Box';
import line from '../../../../assets/images/line.svg';
const cx = classNames.bind(styles);

type ChartData = {
  x: string;
  y: string;
};

type HorizontalBarChartProps = {
  data?: ChartData[] | undefined;
  defaultMax?: number;
  statComparisonResearch: ComparisonResearchResponse;
  compareWithPublic: boolean;
  question: number | string;
};

const COLORS_BY_INDEX = ['ORANGE_RGB', 'GREEN_RGB'] as const;

export const BarChartComparison = ({
  data,
  defaultMax = 100,
  statComparisonResearch,
  compareWithPublic,
  question,
}: HorizontalBarChartProps) => {
  const [sizeWidth, setSizeWidth] = useState<number>(0);
  const [sizeHeight, setSizeHeight] = useState<number>(0);
  const ref = useRef();

  const fallBackData = Array.from(Array(defaultMax + 1).keys())
    .slice(1)
    .map((value) => ({ x: String(value), y: '' }));

  const dataToRender = useMemo(() => {
    if (!data || data.length === 0) return fallBackData;
    return data;
  }, [data, fallBackData]);

  const [marksWidth, setMarksWidth] = useState(() => dataToRender.map(() => 0));

  const resizeHandler = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const width = ref?.current?.clientWidth || {};
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const height = ref?.current?.clientHeight || {};
    setSizeWidth(width);
    setSizeHeight(height);
  };

  const minPercentDomain = (min: number | null) => {
    if (typeof min === 'number') {
      return (+min / maxValue) * (sizeWidth - 40) < 0
        ? 0
        : (+min / maxValue) * (sizeWidth - 40);
    } else {
      return 0;
    }
  };

  const maxPercentDomain = (maxP: number | null) => {
    if (typeof maxP === 'number') {
      if (maxP > maxValue) {
        return sizeWidth - 40;
      } else {
        return (+maxP / maxValue) * (sizeWidth - 40);
      }
    } else {
      return 0;
    }
  };

  const correctSubscales = (value, i: number, questName: string) => {
    let arr = [];

    if (!!value && typeof value.result !== 'string') {
      value.result.survey_statistics_aggr.map((el) => {
        if (el.name === 'meth_key_q_aggr') {
          if (
            // eslint-disable-next-line no-prototype-builtins
            Object.values(Object.values(el.data)[0])[0]?.hasOwnProperty('max')
          ) {
            arr = Object.values(Object.values(el.data)[0]);
          } else {
            Object.values(Object.values(el.data)[0]).map((el) => {
              Object.values(el).map((el) => {
                if (questName) {
                  if (el[questName]) {
                    arr.push(el[questName]);
                  } else {
                    arr.push({
                      confint95_max: 0,
                      confint95_min: 0,
                      max: 0,
                      mean: 0,
                    });
                  }
                } else {
                  arr.push(Object.values(el)[0]);
                }
              });
            });
          }
        }
      });
    }
    return arr[i];
  };

  const arrayCheck = (value) => {
    if (Array.isArray(value)) {
      return value.slice(0, 2);
    } else {
      return Object.values(value).slice(0, 1);
    }
  };

  const yValue = (d: ChartData) => d.y;
  const xValue = useCallback((d: ChartData) => Number(d.x), []);

  const list = useMemo(() => {
    let res;
    res = [];
    const arr = Object.values(statComparisonResearch?.data?.researches);

    if (arr) {
      for (let i = 0; i < arr.length; i++) {
        if (typeof arr[i].result !== 'string') {
          const aggr = arr[i].result.survey_statistics_aggr;

          // eslint-disable-next-line no-loop-func
          aggr.forEach((el) => {
            if (
              Array.isArray(Object.values(el.data)?.[0]) ||
              (!Object.keys(Object.values(el.data)?.[0]).filter((i) =>
                Number.isNaN(+i)
              ).length &&
                !question)
            ) {
              return;
            }

            if (el.name === 'meth_key_q_aggr') {
              if (question) {
                if (compareWithPublic) {
                  const isNType = !Object.keys(
                    Object.values(el.data)?.[0]
                  ).filter((i) => Number.isNaN(+i)).length;
                  res = isNType
                    ? Object.keys(
                        Object.values(Object.values(el.data)?.[0])?.[0]
                      )
                    : Object.keys(Object.values(el.data)?.[0]);
                  return;
                }

                res = Object.keys(
                  Object.values(Object.values(el.data)?.[0])?.[0]
                );
                return;
              }

              res = Object.keys?.(Object.values?.(el?.data)?.[0]);
            }
          });
        }
      }
    }
    return res;
  }, [statComparisonResearch]);

  // // eslint-disable-next-line no-console
  // console.log(list);

  const questions = useMemo(() => {
    const res = [];

    if (question && statComparisonResearch?.question_selected) {
      const localRes = Object?.values?.(
        statComparisonResearch.data.researches
      ).map((i) => {
        if (typeof i.result !== 'string' && question) {
          const question_data = Object.values?.(
            i?.result?.survey_statistics_aggr?.[0]?.data
          )?.[0]?.[question];
          question_data &&
            Object.values?.(question_data).forEach((i) =>
              res.push(...Object.keys(i))
            );
        }
      });
    }
    return [...new Set(res)];
  }, [statComparisonResearch, question]);

  const correctResearch = useMemo(() => {
    if (questions.length > 0) {
      return questions;
    }
    return [''];
  }, [questions]);

  const maxValue = useMemo(() => {
    let res = 0;

    if (statComparisonResearch?.data?.researches) {
      // eslint-disable-next-line no-console
      const arr = Object.values(statComparisonResearch?.data?.researches);

      for (let i = 0; i < arr.length; i++) {
        if (typeof arr[i].result !== 'string') {
          let aggr = [];
          arr[i].result.survey_statistics_aggr.map((el) => {
            if (el.name === 'meth_key_q_aggr') {
              aggr = Object.values(Object.values(el.data)[0]);
            }
          });

          aggr.map((el) => {
            // eslint-disable-next-line no-prototype-builtins
            if (el.hasOwnProperty('max')) {
              if (el.max > res) {
                res = el.max;
              }
            } else {
              Object.values(el).map((el) => {
                if (Object.values(el)[0]?.max > res) {
                  res = Object.values(el)[0].max;
                }
              });
            }
          });
        }
      }
    }
    return res;
  }, [statComparisonResearch]);

  const publicResearch = useMemo(() => {
    let res = [];
    if (
      compareWithPublic &&
      statComparisonResearch?.data?.researches[2] &&
      typeof statComparisonResearch.data.researches[2].result !== 'string'
    ) {
      const aggr =
        statComparisonResearch.data.researches[2].result.survey_statistics_aggr;
      aggr.map((el) => {
        if (el.name === 'meth_key_q_aggr') {
          if (Array.isArray(Object.values(el.data)[0])) {
            res = Object.values(Object.values(el.data)[0][0]);
          } else {
            res = Object.values(Object.values(el.data)[0]);
          }
        }
      });
    }
    return res;
  }, [statComparisonResearch, compareWithPublic]);

  const xScale = scaleLinear()
    .domain([0, maxValue])
    .range([0, sizeWidth - 45]);

  const format10 = xScale.tickFormat(10);
  const customTicks = range(0, maxValue + maxValue / 6, maxValue / 6).map(
    (i, index) => (index === 0 || index === 6 ? i.toFixed(0) : i.toFixed(1))
  );

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <div className={styles.containerAll}>
      <Box flex mb={12}>
        <Box ml={18} flex>
          <Box mr={8}>
            <div className={styles.radio__orange} />
          </Box>
          <Typography size="s" color="main">
            Исследование 1
          </Typography>
        </Box>
        <Box ml={30} flex>
          <Box mr={8}>
            <div className={styles.radio__green} />
          </Box>
          <Typography size="s" color="main">
            Исследование 2
          </Typography>
        </Box>
        <Box ml={30} flex>
          <Box mr={8}>
            <div className={styles.radio__blue} />
          </Box>
          <Typography size="s" color="main">
            Все исследования
          </Typography>
        </Box>
        <Box ml={30} flex>
          <Box mr={8}>
            <img src={line} alt="line" style={{ transform: 'rotate(90deg)' }} />
          </Box>
          <Typography size="s" color="main">
            Доверительный интервал
          </Typography>
        </Box>
      </Box>
      <div className={styles.legend}>
        {questions.length > 0 &&
          questions.map((el, i) => (
            <div className={styles.legendText} key={i}>
              <Box ml={18} flex>
                <Box mr={8}>
                  <div className={styles.methNumber}>{i + 1}</div>
                </Box>
                <Typography size="s" color="main">
                  {el}
                </Typography>
              </Box>
            </div>
          ))}
      </div>
      <div className={styles.containerBar}>
        <Box flex justifyContent={'center'} mb={20} mt={24} mr={24} ml={24}>
          <Typography size="sm" color="main" fontWeight={600} align={'center'}>
            Средние значения по методике «
            {statComparisonResearch.method_selected.method_name}»
          </Typography>
        </Box>
        {list.map((el, i) => (
          <div key={i}>
            <div className={styles.container}>
              <div className={styles.containerText}>
                {correctResearch.map((el, x) => (
                  <div key={x} className={styles.container}>
                    <div className={styles.meth}>
                      {el && (
                        <div
                          className={cx('methNumber', {
                            methNumber__full: el,
                          })}
                        >
                          {x + 1}
                        </div>
                      )}
                    </div>
                    <div className={styles.methResult}>
                      {arrayCheck(
                        Object.values(statComparisonResearch.data.researches)
                      ).map((values, y) => (
                        <div key={y} className={styles.methResultText}>
                          <div className={styles.lineSVG}>
                            <svg height="100%" width={`${sizeWidth - 40}px`}>
                              <g key={'dsd'}>
                                <defs>
                                  <linearGradient
                                    id="ORANGE_RGB"
                                    x1="124.741"
                                    y1="-9.182"
                                    x2="124.866"
                                    y2="25.2781"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stopColor="#FDD771" />
                                    <stop offset="1" stopColor="#F5BF92" />
                                  </linearGradient>

                                  <linearGradient
                                    id="BLUE_RGB"
                                    x1="-462.412"
                                    y1="-6.72421"
                                    x2="-460.814"
                                    y2="53.7566"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stopColor="#A9E6D9" />
                                    <stop
                                      offset="0.444295"
                                      stopColor="#A1D8DE"
                                    />
                                    <stop offset="1" stopColor="#A6CDEF" />
                                  </linearGradient>

                                  <linearGradient
                                    id="GREEN_RGB"
                                    x1="497"
                                    y1="25"
                                    x2="494.491"
                                    y2="-24.8738"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stopColor="#9EE5B1" />
                                    <stop offset="1" stopColor="#BEED9D" />
                                  </linearGradient>
                                </defs>
                                <rect
                                  fill={`url(#${COLORS_BY_INDEX[y]})`}
                                  className={styles.mark}
                                  rx={7}
                                  ry={7}
                                  x={-6}
                                  y={0}
                                  width={
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    correctSubscales(values, i, el)?.mean &&
                                    maxValue
                                      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        (correctSubscales(values, i, el)?.mean /
                                          maxValue) *
                                        (sizeWidth - 40)
                                      : 0
                                  }
                                  height={25}
                                />
                                {minPercentDomain(
                                  correctSubscales(values, i, el)?.confint95_min
                                ) -
                                  String(
                                    minPercentDomain(
                                      correctSubscales(values, i, el)?.mean
                                    )?.toFixed(1) || 0
                                  ).length *
                                    5 <
                                (minPercentDomain(
                                  correctSubscales(values, i, el)?.mean
                                ) ?? 0) /
                                  2 ? (
                                  <>
                                    {minPercentDomain(
                                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore
                                      correctSubscales(values, i, el)
                                        ?.confint95_min
                                    ) -
                                      String(
                                        correctSubscales(
                                          values,
                                          i,
                                          el
                                        )?.mean?.toFixed(1) || 0
                                      ).length *
                                        3 -
                                      (minPercentDomain(
                                        correctSubscales(values, i, el)?.mean
                                      ) ?? 0) /
                                        2 <
                                    0 ? (
                                      <line
                                        className={styles.line}
                                        y1={13}
                                        y2={13}
                                        x1={minPercentDomain(
                                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                          // @ts-ignore
                                          correctSubscales(values, i, el)
                                            ?.confint95_min
                                        )}
                                        x2={
                                          (minPercentDomain(
                                            correctSubscales(values, i, el)
                                              ?.mean
                                          ) ?? 0) /
                                            2 -
                                          String(
                                            Number(
                                              correctSubscales(values, i, el)
                                                ?.mean
                                            ).toFixed(1)
                                          ).length
                                        }
                                        style={{
                                          strokeDasharray: '5 5',
                                          stroke: '#000',
                                        }}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                    <line
                                      className={styles.line}
                                      y1={13}
                                      y2={13}
                                      x1={
                                        (correctSubscales(values, i, el)
                                          ?.mean && maxValue
                                          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            ((correctSubscales(values, i, el)
                                              ?.mean /
                                              maxValue) *
                                              (sizeWidth - 40)) /
                                            2
                                          : 0) +
                                          (correctSubscales(
                                            values,
                                            i,
                                            el
                                          )?.mean?.toFixed(1) || 0
                                            ? String(
                                                correctSubscales(
                                                  values,
                                                  i,
                                                  el
                                                )?.mean?.toFixed(1) || 0
                                              ).length * 6
                                            : 0) <
                                        maxPercentDomain(
                                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                          // @ts-ignore
                                          correctSubscales(values, i, el)
                                            ?.confint95_max
                                        )
                                          ? (correctSubscales(values, i, el)
                                              ?.mean && maxValue
                                              ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                ((correctSubscales(
                                                  values,
                                                  i,
                                                  el
                                                )?.mean /
                                                  maxValue) *
                                                  (sizeWidth - 40)) /
                                                2
                                              : 0) +
                                            (correctSubscales(
                                              values,
                                              i,
                                              el
                                            )?.mean?.toFixed(1) || 0
                                              ? String(
                                                  correctSubscales(
                                                    values,
                                                    i,
                                                    el
                                                  )?.mean?.toFixed(1) || 0
                                                ).length * 6
                                              : 0)
                                          : maxPercentDomain(
                                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                              // @ts-ignore
                                              correctSubscales(values, i, el)
                                                ?.confint95_max
                                            )
                                      }
                                      x2={maxPercentDomain(
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        correctSubscales(values, i, el)
                                          ?.confint95_max
                                      )}
                                      style={{
                                        strokeDasharray: '5 5',
                                        stroke: '#000',
                                      }}
                                    />
                                  </>
                                ) : (
                                  <line
                                    className={styles.line}
                                    y1={13}
                                    y2={13}
                                    x1={minPercentDomain(
                                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore
                                      correctSubscales(values, i, el)
                                        ?.confint95_min
                                    )}
                                    x2={maxPercentDomain(
                                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore
                                      correctSubscales(values, i, el)
                                        ?.confint95_max
                                    )}
                                    style={{
                                      strokeDasharray: '5 5',
                                      stroke: '#000',
                                    }}
                                  />
                                )}
                                {(((correctSubscales(values, i, el)?.mean ||
                                  0) /
                                  maxValue) *
                                  (sizeWidth - 40)) /
                                  2 <
                                  minPercentDomain(
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    correctSubscales(values, i, el)
                                      ?.confint95_min
                                  ) &&
                                (((correctSubscales(values, i, el)?.mean || 0) /
                                  maxValue) *
                                  (sizeWidth - 40)) /
                                  2 +
                                  String(
                                    Number(
                                      correctSubscales(values, i, el)?.mean
                                    ).toFixed(1)
                                  ).length *
                                    5 >
                                  minPercentDomain(
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    correctSubscales(values, i, el)
                                      ?.confint95_min
                                  ) ? (
                                  // Splited
                                  <>
                                    <line
                                      className={styles.line}
                                      y1={2}
                                      y2={8}
                                      x1={minPercentDomain(
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        correctSubscales(values, i, el)
                                          ?.confint95_min
                                      )}
                                      x2={minPercentDomain(
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        correctSubscales(values, i, el)
                                          ?.confint95_min
                                      )}
                                      style={{ stroke: '#000000' }}
                                    />
                                    <line
                                      className={styles.line}
                                      y1={18}
                                      y2={24}
                                      x1={minPercentDomain(
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        correctSubscales(values, i, el)
                                          ?.confint95_min
                                      )}
                                      x2={minPercentDomain(
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        correctSubscales(values, i, el)
                                          ?.confint95_min
                                      )}
                                      style={{ stroke: '#000000' }}
                                    />
                                  </>
                                ) : (
                                  <line
                                    className={styles.line}
                                    y1={10}
                                    y2={16}
                                    x1={minPercentDomain(
                                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore
                                      correctSubscales(values, i, el)
                                        ?.confint95_min
                                    )}
                                    x2={minPercentDomain(
                                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore
                                      correctSubscales(values, i, el)
                                        ?.confint95_min
                                    )}
                                    style={{ stroke: '#000000' }}
                                  />
                                )}
                                <line
                                  className={styles.line}
                                  y1={10}
                                  y2={16}
                                  x1={maxPercentDomain(
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    correctSubscales(values, i, el)
                                      ?.confint95_max
                                  )}
                                  x2={maxPercentDomain(
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    correctSubscales(values, i, el)
                                      ?.confint95_max
                                  )}
                                  style={{ stroke: '#000000' }}
                                />
                                <text
                                  className={cx(styles.markLabel, {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    [styles.isReady]: correctSubscales(
                                      values,
                                      i,
                                      el
                                    )?.mean_percent_from_max,
                                  })}
                                  fill="#000"
                                  x={
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    correctSubscales(values, i, el)?.mean &&
                                    maxValue
                                      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        ((correctSubscales(values, i, el).mean /
                                          maxValue) *
                                          (sizeWidth - 40)) /
                                        2
                                      : 4
                                  }
                                  y={13}
                                >
                                  {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                                  {/*@ts-ignore*/}
                                  {correctSubscales(
                                    values,
                                    i,
                                    el
                                  )?.mean?.toFixed(1) || 0}
                                </text>
                              </g>
                            </svg>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
                {compareWithPublic && (
                  <div className={styles.container}>
                    <div className={styles.meth}></div>
                    <div className={styles.methResult}>
                      {publicResearch.length > 0 && (
                        <div className={styles.methResultText}>
                          <div className={styles.lineSVG}>
                            <svg height="100%" width={`${sizeWidth - 40}px`}>
                              <g key={'dsd'}>
                                <defs>
                                  <linearGradient
                                    id="BLUE_RGB"
                                    x1="-462.412"
                                    y1="-6.72421"
                                    x2="-460.814"
                                    y2="53.7566"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stopColor="#A4EEDC" />
                                    <stop
                                      offset="0.444295"
                                      stopColor="#5BCAB5"
                                    />
                                    <stop offset="1" stopColor="#6BAAE4" />
                                  </linearGradient>
                                </defs>
                                <rect
                                  fill={`url(#BLUE_RGB)`}
                                  className={styles.mark}
                                  rx={7}
                                  ry={7}
                                  x={-6}
                                  y={0}
                                  width={
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    publicResearch[i]?.mean && maxValue
                                      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        (publicResearch[i].mean / maxValue) *
                                        (sizeWidth - 40)
                                      : 0
                                  }
                                  height={25}
                                />
                                <text
                                  className={cx(styles.markLabel, {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    [styles.isReady]:
                                      publicResearch[i]?.mean_percent_from_max,
                                  })}
                                  x={
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    publicResearch[i]?.mean && maxValue
                                      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        ((publicResearch[i].mean / maxValue) *
                                          (sizeWidth - 40)) /
                                        2
                                      : 4
                                  }
                                  y={13}
                                >
                                  {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                                  {/*@ts-ignore*/}
                                  {publicResearch[i]?.mean?.toFixed(1) || 0}
                                </text>

                                {publicResearch[i]?.confint95_min <
                                publicResearch[i].mean / 2 ? (
                                  <>
                                    <line
                                      className={styles.line}
                                      y1={13}
                                      y2={13}
                                      x1={maxPercentDomain(
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        publicResearch[i]?.confint95_min
                                      )}
                                      x2={
                                        (publicResearch[i]?.mean && maxValue
                                          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            ((publicResearch[i].mean /
                                              maxValue) *
                                              (sizeWidth - 40)) /
                                            2
                                          : 0) -
                                        String(
                                          publicResearch[i]?.mean?.toFixed(1) ||
                                            0
                                        ).length *
                                          0.8
                                      }
                                      style={{
                                        strokeDasharray: '5 5',
                                        stroke: '#000',
                                      }}
                                    />
                                    <line
                                      className={styles.line}
                                      y1={13}
                                      y2={13}
                                      x1={
                                        (publicResearch[i]?.mean && maxValue
                                          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            ((publicResearch[i].mean /
                                              maxValue) *
                                              (sizeWidth - 40)) /
                                            2
                                          : 0) +
                                          String(
                                            publicResearch[i]?.mean?.toFixed(
                                              1
                                            ) || 0
                                          ).length *
                                            6 >
                                        maxPercentDomain(
                                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                          // @ts-ignore
                                          publicResearch[i]?.confint95_max
                                        )
                                          ? (publicResearch[i]?.mean && maxValue
                                              ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                // @ts-ignore
                                                ((publicResearch[i].mean /
                                                  maxValue) *
                                                  (sizeWidth - 40)) /
                                                2
                                              : 0) +
                                            String(
                                              publicResearch[i]?.mean?.toFixed(
                                                1
                                              ) || 0
                                            ).length *
                                              6
                                          : maxPercentDomain(
                                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                              // @ts-ignore
                                              publicResearch[i]?.confint95_max
                                            )
                                      }
                                      x2={maxPercentDomain(
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        publicResearch[i]?.confint95_max
                                      )}
                                      style={{
                                        strokeDasharray: '5 5',
                                        stroke: '#000',
                                      }}
                                    />
                                  </>
                                ) : (
                                  <line
                                    className={styles.line}
                                    y1={13}
                                    y2={13}
                                    x1={minPercentDomain(
                                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore
                                      publicResearch[i]?.confint95_min
                                    )}
                                    x2={maxPercentDomain(
                                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore
                                      publicResearch[i]?.confint95_max
                                    )}
                                    style={{
                                      strokeDasharray: '5 5',
                                      stroke: '#000',
                                    }}
                                  />
                                )}
                                {((publicResearch[i].mean / maxValue) *
                                  (sizeWidth - 40)) /
                                  2 <
                                  minPercentDomain(
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    publicResearch[i]?.confint95_min
                                  ) &&
                                ((publicResearch[i].mean / maxValue) *
                                  (sizeWidth - 40)) /
                                  2 +
                                  String(
                                    Number(publicResearch[i].mean).toFixed(1)
                                  ).length *
                                    5 >
                                  minPercentDomain(
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    publicResearch[i]?.confint95_min
                                  ) ? (
                                  // Splited
                                  <>
                                    <line
                                      className={styles.line}
                                      y1={2}
                                      y2={8}
                                      x1={minPercentDomain(
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        publicResearch[i]?.confint95_min
                                      )}
                                      x2={minPercentDomain(
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        publicResearch[i]?.confint95_min
                                      )}
                                      style={{ stroke: '#000000' }}
                                    />
                                    <line
                                      className={styles.line}
                                      y1={18}
                                      y2={24}
                                      x1={minPercentDomain(
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        publicResearch[i]?.confint95_min
                                      )}
                                      x2={minPercentDomain(
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        publicResearch[i]?.confint95_min
                                      )}
                                      style={{ stroke: '#000000' }}
                                    />
                                  </>
                                ) : (
                                  <line
                                    className={styles.line}
                                    y1={10}
                                    y2={16}
                                    x1={minPercentDomain(
                                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore
                                      publicResearch[i]?.confint95_min
                                    )}
                                    x2={minPercentDomain(
                                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore
                                      publicResearch[i]?.confint95_min
                                    )}
                                    style={{ stroke: '#000000' }}
                                  />
                                )}
                                <line
                                  className={styles.line}
                                  y1={10}
                                  y2={16}
                                  x1={maxPercentDomain(
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    publicResearch[i]?.confint95_max
                                  )}
                                  x2={maxPercentDomain(
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    publicResearch[i]?.confint95_max
                                  )}
                                  style={{ stroke: '#000000' }}
                                />
                              </g>
                            </svg>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/*@ts-ignore*/}
              <div className={styles.containerSVG} ref={ref}>
                <svg width="100%" height="100%" style={{ overflow: 'visible' }}>
                  <g>
                    {/* Axis bottom */}
                    {customTicks.map((tickValue) => (
                      <g
                        className={styles.tick}
                        key={tickValue}
                        transform={`translate(${xScale(tickValue)},0)`}
                      >
                        <line className={styles.line} y2={sizeHeight - 20} />
                        <text
                          className={styles.xAxisLabel}
                          style={{ textAnchor: 'middle' }}
                          dy="1em"
                          y={sizeHeight - 10}
                        >
                          {tickValue}
                        </text>
                      </g>
                    ))}

                    {data?.map((d, i) => (
                      <g key={yValue(d)}></g>
                    ))}
                  </g>
                </svg>
              </div>
            </div>
            <Box flex justifyContent={'center'} mb={24} mt={24}>
              <Typography size="s" color="gray6" fontWeight={400}>
                {el}
              </Typography>
            </Box>
          </div>
        ))}
      </div>
    </div>
  );
};
