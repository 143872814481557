export const IconArrowUp = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3895 8.81103C16.0467 9.11812 15.5198 9.08919 15.2127 8.74639L13.3089 6.62126C12.7227 5.96691 11.5231 4.68332 10.6008 3.70146C10.2746 3.35417 9.72554 3.35418 9.39932 3.70146C8.47703 4.68333 7.27752 5.96691 6.69132 6.62126L4.78742 8.7464C4.48031 9.08919 3.95347 9.11812 3.61068 8.81102C3.26789 8.50392 3.23896 7.97707 3.54606 7.63428L5.44994 5.50916C5.44994 5.50916 5.44993 5.50917 5.44994 5.50916C6.05195 4.83717 7.26614 3.53809 8.18454 2.56038C9.16901 1.51232 10.8311 1.51231 11.8156 2.56037C12.734 3.53809 13.9482 4.83716 14.5502 5.50916L16.4541 7.63429C16.7612 7.97708 16.7323 8.50393 16.3895 8.81103Z"
        fill="#AAB1BA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99992 18.1907C9.53968 18.1907 9.16658 17.8176 9.16658 17.3573L9.16658 3.19067C9.16658 2.73044 9.53968 2.35734 9.99992 2.35734C10.4602 2.35734 10.8333 2.73044 10.8333 3.19067L10.8333 17.3573C10.8333 17.8176 10.4602 18.1907 9.99992 18.1907Z"
        fill="#AAB1BA"
      />
    </svg>
  );
};
