export const IconFilterMenuClose = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="13.5" stroke="#F6F6F6" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4779 8.88248C18.6545 8.70584 18.9409 8.70584 19.1175 8.88248C19.2942 9.05913 19.2942 9.34553 19.1175 9.52218L14.6398 13.9999L19.1177 18.4779C19.2943 18.6545 19.2943 18.9409 19.1177 19.1175C18.941 19.2942 18.6546 19.2942 18.478 19.1175L14.0001 14.6396L9.52218 19.1175C9.34553 19.2942 9.05913 19.2942 8.88248 19.1175C8.70584 18.9409 8.70584 18.6545 8.88248 18.4779L13.3604 13.9999L8.88262 9.52218C8.70597 9.34553 8.70597 9.05913 8.88262 8.88248C9.05927 8.70584 9.34567 8.70584 9.52231 8.88248L14.0001 13.3603L18.4779 8.88248Z"
        fill="#333F48"
      />
    </svg>
  );
};
