// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResearchSurveyMenuItem_dragItem__I96IF {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 24px;
  cursor: pointer;
}
.ResearchSurveyMenuItem_dragItem__drag__QHVXA {
  cursor: move;
}
.ResearchSurveyMenuItem_dragItem__disable__JHDKo {
  pointer-events: none;
}
.ResearchSurveyMenuItem_dragItem__I96IF .ResearchSurveyMenuItem_p__a1dDh {
  margin: 0 24px 0 8px;
}
.ResearchSurveyMenuItem_dragItemContent__pPyaQ {
  display: flex;
  align-items: start;
}
.ResearchSurveyMenuItem_dragItemLink__Grdel {
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 10px;
  pointer-events: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/research/ResearchForm/ResearchSurveyMenu/ResearchSurveyMenuItem/ResearchSurveyMenuItem.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;AACF;AACE;EACE,YAAA;AACJ;AAEE;EACE,oBAAA;AAAJ;AAGE;EACE,oBAAA;AADJ;AAIE;EACE,aAAA;EACA,kBAAA;AAFJ;AAKE;EACE,uBAAA;EAAA,kBAAA;EACA,kBAAA;EACA,oBAAA;AAHJ","sourcesContent":[".dragItem {\n  display: flex;\n  align-items: start;\n  justify-content: space-between;\n  margin-bottom: 24px;\n  cursor: pointer;\n\n  &__drag {\n    cursor: move;\n  }\n\n  &__disable {\n    pointer-events: none;\n  }\n\n  .p {\n    margin: 0 24px 0 8px;\n  }\n  \n  &Content {\n    display: flex;\n    align-items: start;\n  }\n\n  &Link {\n    width: fit-content;\n    margin-right: 10px;\n    pointer-events: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dragItem": `ResearchSurveyMenuItem_dragItem__I96IF`,
	"dragItem__drag": `ResearchSurveyMenuItem_dragItem__drag__QHVXA`,
	"dragItem__disable": `ResearchSurveyMenuItem_dragItem__disable__JHDKo`,
	"p": `ResearchSurveyMenuItem_p__a1dDh`,
	"dragItemContent": `ResearchSurveyMenuItem_dragItemContent__pPyaQ`,
	"dragItemLink": `ResearchSurveyMenuItem_dragItemLink__Grdel`
};
export default ___CSS_LOADER_EXPORT___;
