import { FormikContext, useFormik, Form } from 'formik';
import {
  MethodDictItem,
  AddResearchVersus,
} from '../../../../../models/research';
import { Box } from '../../../../ui/Box';
import { Filters } from '../../../../ui/Filters';
import { CheckBox } from '../../../../ui/Form/CheckBox';
import { InputDate } from '../../../../ui/Form/InputDate';
import { InputText } from '../../../../ui/Form/InputText';
import { SelectSearch } from '../../../../ui/Form/SelectSearch';
import { SelectSearchRegions } from '../../../../ui/Form/SelectSearchRegions';
import classNames from 'classnames/bind';
import { Select } from '../../../../ui/Form/Select';
import { Typography } from '../../../../ui/Typography';
import { Button } from '../../../../ui/Button';
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { formatDateStr } from '../../../../../utils/dates';
import {
  useGetDictionaryQuery,
  useGetRegionDataQuery,
  useGetResearchMethodsQuery,
} from '../../../../../store/VbudusheeAPI';
import { defaultFormikOptions } from '../../../../../models/formik';
import { researchVersusFiltersInitialValues } from '../../../../../models/validations/schemas/validations';
import styles from './ComparisonSidePanelFilter.module.scss';
const cx = classNames.bind(styles);

type ComparisonSidePanelFilterProps = {
  isOpenFilter: boolean;
  isOpen: boolean;
  filterRef: RefObject<HTMLDivElement>;
  updateResearch: (filters: any) => void;
  setFilterHeight: Dispatch<SetStateAction<number>>;
  researchSteps?: number[];
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
};

export const ComparisonSidePanelFilter = ({
  isOpenFilter,
  isOpen,
  filterRef,
  researchSteps,
  updateResearch,
  setFilterHeight,
  page,
  setPage,
}: ComparisonSidePanelFilterProps) => {
  const [filterStep, setFilterStep] = useState<number[]>([]);

  const { data: regions } = useGetRegionDataQuery({});
  const { data: dictionary } = useGetDictionaryQuery({});
  const {
    data: methodsData,
    isLoading: isMethodsLoading,
    isError: isMethodsError,
  } = useGetResearchMethodsQuery({});

  const handleFiltersSubmit = async () => {
    updateResearch(argument);
  };

  const formikFilter = useFormik({
    initialValues: researchVersusFiltersInitialValues,
    onSubmit: handleFiltersSubmit,
    ...defaultFormikOptions,
  });

  const argument: AddResearchVersus = useMemo(() => {
    const arg: AddResearchVersus = {};

    for (const key in formikFilter.values) {
      if (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formikFilter.values[key] &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        key !== 'research_region' &&
        key !== 'date'
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        arg[key] = formikFilter.values[key];
      }

      if (formikFilter.values.research_region.length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        arg['research_region'] = formikFilter.values.research_region.map(
          (el: { region_name: number; region_number: string }) => {
            return +el.region_number;
          }
        );
      }

      arg['page'] = page;
    }
    return arg;
  }, [formikFilter.values, page]);

  useEffect(() => {
    updateResearch(argument);
  }, [page]);

  useEffect(() => {
    setPage(1);
  }, [formikFilter.values]);

  useEffect(() => {
    setFilterHeight(filterRef.current?.offsetHeight ?? 0);
  }, [filterRef.current?.offsetHeight, isOpenFilter]);

  useEffect(() => {
    setTimeout(() => {
      setFilterHeight(filterRef.current?.offsetHeight ?? 0);
    }, 200);
  }, [isOpen]);

  const handleFiltersReset = () => {
    formikFilter.resetForm();
    updateResearch({});
  };

  const convertToDate = (el: string) => {
    return el.slice(0, 10).split('.').reverse().join('-');
  };

  const convertObjectToArray = (dictionary: { [key: string]: string }) => {
    return Object.entries(dictionary).map((el: string[]) => {
      return {
        key: el[0],
        value: el[1],
      };
    });
  };

  const visibleTodos = useMemo(() => {
    if (dictionary) {
      return convertObjectToArray(dictionary?.research.research_education);
    }
    return [];
  }, [dictionary]);

  const optionsTargetResearch = useMemo(() => {
    if (dictionary?.research.research_target) {
      return convertObjectToArray(dictionary.research.research_target);
    }
    return [];
  }, [dictionary]);

  useEffect(() => {
    if (formikFilter.values.date !== '') {
      const dates: string[] = [];
      const rawDates = formikFilter.values.date.split(';');
      rawDates.map((date: any) => dates.push(date));

      if (dates[0] && dates[0] !== 'undefined') {
        formikFilter.setFieldValue(
          'research_start_date',
          `${convertToDate(formatDateStr(dates[0]))}`
        );
      } else {
        formikFilter.setFieldValue('research_start_date', '');
      }
      setTimeout(() =>
        formikFilter.setFieldTouched('research_start_date', true)
      );
      if (dates[1] && dates[1] !== 'undefined') {
        formikFilter.setFieldValue(
          'research_end_date',
          `${convertToDate(formatDateStr(dates[1]))}`
        );
      } else {
        formikFilter.setFieldValue('research_end_date', '');
      }
      setTimeout(() => formikFilter.setFieldTouched('research_end_date', true));
    }
  }, [formikFilter.values.date]);

  useEffect(() => {
    if (researchSteps) {
      const sortArr = [...researchSteps];
      sortArr.sort((a, b) => a - b);
      setFilterStep(sortArr);
    }
  }, [researchSteps]);

  return (
    <div ref={filterRef}>
      <Filters hiddenButton isOpen={!isOpenFilter}>
        <FormikContext.Provider value={formikFilter}>
          <Form>
            <div className={styles.form}>
              <div className={cx('form__item', 'form__item_search')}>
                <div className={styles.input}>
                  <InputText
                    name="research_name"
                    placeholder="Название исследования"
                    fullWidth={true}
                  />
                </div>
                <div className={styles.filterContainer}>
                  <div>
                    <Select
                      name="research_target"
                      label="Аудитория"
                      options={[
                        { key: 0, value: 'Не выбрано' },
                        ...optionsTargetResearch,
                      ]}
                      fullWidth
                    />
                  </div>
                  <div>
                    <Select
                      name="research_education"
                      label="Уровень образования"
                      options={[
                        { key: 0, value: 'Не выбрано' },
                        ...visibleTodos,
                      ]}
                      fullWidth
                    />
                  </div>
                  <div>
                    <SelectSearch
                      name="method_id"
                      placeholder="Методика"
                      options={
                        methodsData?.methods
                          ? methodsData.methods.map(
                              (methods: MethodDictItem) => {
                                return {
                                  option: methods.survey.name,
                                  value: String(methods.id),
                                };
                              }
                            )
                          : []
                      }
                      fullWidth
                    />
                  </div>
                  <div>
                    <SelectSearchRegions
                      name="research_region"
                      placeholder="Регион"
                      options={
                        regions
                          ? regions.map((region) => {
                              return {
                                region_name: region.region_name,
                                region_number: region.region_number,
                              };
                            })
                          : []
                      }
                      fullWidth
                    />
                  </div>
                  <div>
                    <InputDate
                      name="date"
                      fullWidth
                      placeholder="Период проведения исследования"
                      isRange
                    />
                  </div>
                  <div>
                    <Select
                      name="research_step"
                      label="Этап исследования"
                      options={
                        // Array.from(
                        //   { length: 100 },
                        //   (_, index) => index + 1
                        // )
                        filterStep.length
                          ? [
                              { key: 0, value: 'Не выбрано' },
                              ...filterStep.map((el) => {
                                return {
                                  key: String(el),
                                  value: String(el),
                                };
                              }),
                            ]
                          : []
                      }
                      disabled={!filterStep.length}
                      fullWidth
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.buttons}>
              <div>
                <Box flex mt={8}>
                  <CheckBox
                    checked={formikFilter.values.is_longitudinal}
                    onChange={() =>
                      formikFilter.setFieldValue(
                        'is_longitudinal',
                        !formikFilter.values.is_longitudinal
                      )
                    }
                    themeGradient
                  />
                  <Box flex ml={8}>
                    <Typography color="gray6" fontWeight={400} size="sm">
                      Лонгитюдные исследования
                    </Typography>
                  </Box>
                </Box>
              </div>
              <div>
                <Box flex justifyContent={'flex-end'}>
                  <div onClick={handleFiltersReset}>
                    <Button
                      label="Сбросить"
                      withoutBackground={true}
                      disabled={!formikFilter.dirty}
                    />
                  </div>
                  <div
                    onClick={() => formikFilter.handleSubmit()}
                    style={{ marginLeft: '8px' }}
                  >
                    <Button
                      label="Применить"
                      gradient={true}
                      disabled={!formikFilter.dirty}
                    />
                  </div>
                </Box>
              </div>
            </div>
          </Form>
        </FormikContext.Provider>
      </Filters>
    </div>
  );
};
