import React from 'react';
import { Layout } from '../../../components/Layout';
import { RegistrationRespondent } from '../../../components/pages/auth/RegistrationRespondent';

export const RespondentRegistrationPage = () => {
  return (
    <Layout>
      <RegistrationRespondent />
    </Layout>
  );
};
