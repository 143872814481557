import { ReactElement } from 'react';

//  ui
import { TableHeader } from '../../../models/components/table';
import { Col } from './Col';
import { Row } from './Row';
import { Error } from './Error';
import { Empty } from './Empty';
import { NoTable } from './NoTable';
import { Expanded } from './Expanded';
import classNames from 'classnames/bind';

import styles from './Table.module.scss';
const cx = classNames.bind(styles);

type TableTypes = {
  headers: TableHeader[];
  children: (ReactElement | null)[] | ReactElement | null;
  notExpandable?: boolean;
};

export const TableExpanded = ({
  headers,
  notExpandable,
  children,
}: TableTypes) => {
  return (
    <div className={styles.table}>
      <div className={styles.headers}>
        {!notExpandable && <div className={styles.headers__empty} />}
        {headers.map((h, key) => (
          <div
            className={styles.headers__item}
            key={key}
            style={{ width: h.width }}
          >
            <span
              className={cx({
                headers__label: h.titleIcon,
              })}
            >
              {h.label}
            </span>
            {h.titleIcon && h.titleIcon}
          </div>
        ))}
      </div>

      {children}
    </div>
  );
};

TableExpanded.Col = Col;
TableExpanded.Row = Row;
TableExpanded.Error = Error;
TableExpanded.Empty = Empty;
TableExpanded.NoTable = NoTable;
TableExpanded.Expanded = Expanded;
