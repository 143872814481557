import { QuestionnaireDictItem } from '../../../../../../models/research';
import cx from 'classnames';
import { QuestionItem } from '../../../../../ui/QuestionItem';
import { Typography } from '../../../../../ui/Typography';
import { Box } from '../../../../../ui/Box';
import { FormikContext, useFormik } from 'formik';

type QuestionnaireBlockListProps = {
  questions: QuestionnaireDictItem[];
  questionnairesAnswerRequirement?: number[];
};

export const QuestionnaireBlockList = ({
  questions,
  questionnairesAnswerRequirement,
}: QuestionnaireBlockListProps) => {
  const formik = useFormik({
    initialValues: {
      a: '',
    },
    onSubmit: () => {},
  });

  return (
    <FormikContext.Provider value={formik}>
      <div>
        {questions.map((q, i: number) => (
          <QuestionItem
            showSwitch={false}
            key={i}
            id={q?.id || 12}
            questionType={q.survey.question_input_type}
            title={q.survey.question_name}
            questionNumber={i + 1}
            sizeTitle="s"
            upperCase={true}
            questionOrder={q.survey.question_order}
            disable={true}
            active={false}
            required={
              questionnairesAnswerRequirement
                ? questionnairesAnswerRequirement.map((el) => el).includes(q.id)
                : false
            }
            description={q.survey.question_answers}
            isStructure
          />
        ))}
      </div>
    </FormikContext.Provider>
  );
};
