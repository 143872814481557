import { Navigate, Outlet } from 'react-router-dom';

import { useAuthorized } from '../../hooks/useAuthorized';
import { frontendRoutes } from './routes';

export const ProtectedAuthRoutes = () => {
  const { isAuthorized } = useAuthorized();

  return !isAuthorized ? <Outlet /> : <Navigate to={frontendRoutes.mainPage} />;
};
