import React from 'react';
import { Layout } from '../../../components/Layout';
import { CodeConfirm } from '../../../components/pages/auth/CodeConfirm';
import { Breadcrumbs } from '../../../components/ui/Breadcrumbs';

export const RegistrationCode = () => {
  return (
    <Layout>
      <div>
        <div className="outer-container">
          <Breadcrumbs
            links={[{ title: 'Подтверждение учётной записи' }]}
            homeLink="/"
          />
        </div>
        <CodeConfirm />
      </div>
    </Layout>
  );
};
