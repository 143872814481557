/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';

import { PublicEventDetails } from '../../../../models/pages/events';
import { formatDateStr } from '../../../../utils/dates';
import styles from './PublicEventComponent.module.scss';

//  ui
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { Button } from '../../../ui/Button';
import { ContentModal } from '../../../ui/ContentModal';
import { AccessDeniedModal } from '../../../ui/AccessDeniedModal';
import { Typography } from '../../../ui/Typography';
import { Box } from '../../../ui/Box';
import { useNavigate } from 'react-router-dom';
import { frontendRoutes } from '../../../../utils/router/routes';
import {
  VbudusheeAPI,
  useGetDictionaryQuery,
  useGetEventDocumentsQuery,
  useGetMediaFilesQuery,
} from '../../../../store/VbudusheeAPI';
import { PublicEventDocuments } from './PublicEventDocuments';
import { GetDictionaryResponse } from '../../../../models/research';
import png from '../../../../assets/images/svg/files/png.svg';
import doc from '../../../../assets/images/svg/files/doc.svg';
import jpg from '../../../../assets/images/svg/files/jpg.svg';
import zip from '../../../../assets/images/svg/files/zip.svg';
import rar from '../../../../assets/images/svg/files/rar.svg';
import pdf from '../../../../assets/images/svg/files/pdf.svg';
import txt from '../../../../assets/images/svg/files/txt.svg';
import xsl from '../../../../assets/images/svg/files/xsl.svg';
import ppt from '../../../../assets/images/svg/files/ppt.svg';
import { downloadBlobFile } from '../../../../utils/api/downloadPDF';
import { InfoModal } from '../../../ui/InfoModal';

const icons: any = {
  png,
  doc,
  jpg,
  zip,
  rar,
  pdf,
  txt,
  xsl,
  ppt,
  xlsx: xsl,
  pptx: ppt,
  docx: doc,
};

type PublicEventComponentProps = {
  event?: PublicEventDetails;
  isAuthorized?: boolean;
  eventId?: string;
  setState: (value: 'info' | 'upload') => void;
  owner?: boolean;
  dictionary?: GetDictionaryResponse;
};

export const PublicEventComponent = ({
  event,
  isAuthorized = false,
  eventId,
  setState,
  owner,
  dictionary,
}: PublicEventComponentProps): JSX.Element => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isFilesOpen, setIsFilesOpen] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const navigate = useNavigate();

  const { data: files } = useGetMediaFilesQuery(`${eventId}`);
  const [triggerStart]: any =
    VbudusheeAPI.endpoints.getMediaFile.useLazyQuery();

  const handleParticipate = (): void => {
    if (isAuthorized) {
      setState('upload');
    } else {
      setIsErrorOpen(true);
    }
  };

  const getFile = async (id: any, name: any) => {
    const myHeaders = new Headers();

    myHeaders.append(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );
    myHeaders.append('Content-Type', 'application/json');

    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };
    return fetch(`/api/mediafiles/${id}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        downloadBlobFile(blob, name ? name : '');
      });
  };

  const handleSendEvent = (): void => {
    //TODO отправка мероприятя на публикацию
  };

  const handleSettingEvent = (): void => {
    navigate(
      `${frontendRoutes.settingsEvent.settingsEvent}/${event?.event_id}`
    );
  };

  return (
    <div className="details">
      <div className="local-container">
        <Breadcrumbs
          links={[
            { title: 'Мероприятия команды', link: '/events' },
            { title: 'Мероприятие' },
          ]}
          homeLink="/"
        />
      </div>
      <div className="details__header">
        <Typography
          color="main"
          size="xxxl"
          font="SFPRoDisplay"
          fontWeight={600}
        >
          {event?.event_name}
        </Typography>

        {/*if user is owner event*/}
        {owner ? (
          <div className={styles.blockButton}>
            <div className={styles.blockButton__settingsButton}>
              <Button
                label="Настройки мероприятия"
                gradient
                clicked={handleSettingEvent}
              />
            </div>
          </div>
        ) : (
          <div>
            <Button
              width={235}
              label="Участие в мероприятии"
              gradient={true}
              clicked={handleParticipate}
            />
          </div>
        )}
      </div>
      <div className="details__body">
        <Typography color="main" fontWeight={600} size="s" upperCase>
          Информация о мероприятии
        </Typography>
        <Box mb={33} />
        <div className="row">
          <div className="detail detail_full">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Описание
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {event?.event_description}
              </Typography>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Сроки проведения
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {formatDateStr(event?.event_start_date, { isDateOnly: true })}{' '}
                {` - `}
                {formatDateStr(event?.event_end_date, { isDateOnly: true })}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Типы собираемых документов
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {dictionary?.events.event_document_type[
                  event?.event_document_type || ''
                ] || 'Нет данных'}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Тематические теги
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {event?.event_tags.map((tag) => tag.name).join(', ') ||
                  'Нет тегов'}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Количество участников
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {event?.event_members}
              </Typography>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            style={{
              width: '100%',
            }}
            className="detail"
          >
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Требования к загрузке документа
                </Typography>
              </div>
              <Typography color="main" size="sm">
                При создании документа на двух и более страницах вторую и
                последующие страницы нумеруют. Номера страниц проставляются
                посередине верхнего поля документа на расстоянии не менее 10 мм
                от верхнего края листа. Допускается создание документов на
                лицевой и оборотной сторонах листа. При двустороннем создании
                документов ширина левого поля на лицевой стороне листа и правого
                поля на оборотной стороне листа должны быть равны.
              </Typography>
            </div>
          </div>
          <div
            style={{
              width: '100%',
            }}
            className="detail"
          >
            <div className="detail__content">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
                className="detail__content-title"
              >
                <Typography color="gray" size="s">
                  Прикрепленные файлы
                </Typography>
                <button
                  style={{
                    color: '#00809C',
                    display: 'inline',
                    width: 'auto',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={() => setIsFilesOpen(!isFilesOpen)}
                >
                  {isFilesOpen ? 'Скрыть' : 'Все файлы'}
                </button>
              </div>
              <div
                className={`${styles.files} ${
                  isFilesOpen ? styles.isOpen : ''
                }`}
              >
                {files?.data?.map((file: any) => (
                  <div
                    className={`${styles[file.mediafile_extension]} ${
                      styles.file
                    }`}
                    key={file.mediafile_id}
                    onClick={() =>
                      getFile(
                        file.mediafile_id,
                        `${file.mediafile_name}.${file.mediafile_extension}`
                      )
                    }
                  >
                    <img src={icons[file.mediafile_extension]} alt="" />
                    <div>
                      <span>{file.mediafile_name}</span>.
                      {file.mediafile_extension}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="details__divider" />
        <Typography color="main" fontWeight={600} size="s" upperCase>
          Автор мероприятия
        </Typography>
        <Box mb={33} />
        <div className="row">
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Фамилия
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {event?.event_creator?.last_name}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Имя
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {event?.event_creator?.first_name}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Отчество
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {event?.event_creator?.middle_name}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Электронная почта
                </Typography>
              </div>
              <Typography color="main" size="sm">
                Нет данных
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Организация
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {event?.event_creator?.organization_name}
              </Typography>
            </div>
          </div>
        </div>
        <div className="details__divider" />
        {event && dictionary && (
          <PublicEventDocuments
            role={event?.event_user_role}
            eventId={String(event?.event_id)}
            dictionary={dictionary}
          />
        )}
      </div>
      <ContentModal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <AccessDeniedModal />
      </ContentModal>
      <ContentModal
        titleText="Ошибка участия"
        isOpen={isErrorOpen}
        onClose={() => setIsErrorOpen(false)}
      >
        <div>
          <span
            style={{
              display: 'block',
              paddingBottom: '26px',
              marginTop: '-12px',
              fontSize: '16px',
            }}
          >
            Для участия вы должны{' '}
            <a
              style={{
                width: 'auto',
                display: 'inline',
                color: '#00809C',
                fontSize: '16px',
              }}
              href="#"
            >
              зарегистрироваться
            </a>{' '}
            как исследователь
          </span>
          <Button
            label="Ок"
            gradient={true}
            clicked={() => setIsErrorOpen(false)}
          />
        </div>
      </ContentModal>
    </div>
  );
};
