import React, { useMemo } from 'react';
import { useField } from 'formik';

import classNames from 'classnames/bind';
import styles from './InputSearch.module.scss';

const cx = classNames.bind(styles);

type InputSearchTypes = {
  name: string;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  modifiedClassName?: boolean;
  value?: string;
  // setFilter: React.Dispatch<React.SetStateAction<any | undefined>>;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
};

export const InputSearch = ({
  name,
  placeholder,
  value,
  setFilter,
  type,
  disabled,
  fullWidth,
}: InputSearchTypes) => {
  const [field, { error, touched }] = useField<string>(name);

  const isInvalid = useMemo(() => {
    return Boolean(error) && touched;
  }, [error, touched]);

  const handleClick = (e: string) => {
    setFilter(e);
  };

  return (
    <div
      className={cx('input', {
        input_invalid: isInvalid,
        input_disabled: disabled,
        input_fullwidth: fullWidth,
      })}
    >
      <input
        {...field}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={(e) => handleClick(e.target.value)}
      />
      {isInvalid && <div className={cx('input__error')}>{error}</div>}
    </div>
  );
};
