import { ResearchMember } from '../../../../../../models/research';
import { useDeleteMemberResearchMutation } from '../../../../../../store/VbudusheeAPI';
import styles from './ResearchMemberCard.module.scss';

export const ResearchMemberCard = (
  member: ResearchMember,
  researchId: string
) => {
  const [deleteMemberResearch] = useDeleteMemberResearchMutation();

  const deleteMemberResearchHandler = async () => {
    const result = await deleteMemberResearch({
      research_id: member.researchId ?? '',
      body: {
        email: member.member_email,
        role: '',
      },
    });
  };

  return (
    <div>
      <div className={styles.card_wraper__email}>{member.member_email}</div>
      {member.member_last_name && member.member_first_name && (
        <div
          className={styles.card_wraper__name}
        >{`${member.member_last_name} ${member.member_first_name} ${member.member_middle_name}`}</div>
      )}
      <a
        className={styles.card_wraper__link}
        onClick={deleteMemberResearchHandler}
      >
        Удалить
      </a>
    </div>
  );
};
