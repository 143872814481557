export const IconCheck = () => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6381 1.87372L4.63808 8.87372C4.37773 9.13407 3.95562 9.13407 3.69527 8.87372L0.361938 5.54038L1.30475 4.59757L4.16668 7.4595L10.6953 0.930908L11.6381 1.87372Z"
        fill="white"
      />
    </svg>
  );
};
