export const NewItems = [
  {
    date: '16 сентября',
    title:
      'Развитие личностного потенциала как ценность современного образования',
    description:
      'Добро пожаловать на IV Всероссийскую научно-практическую конференцию участников Программы по развития личностного потенциала!',
    link: 'Читать полностью',
  },
  {
    date: '16 сентября',
    title:
      'Развитие личностного потенциала как ценность современного образования',
    description:
      'Добро пожаловать на IV Всероссийскую научно-практическую конференцию участников Программы по развития личностного потенциала!',
    link: 'Читать полностью',
  },
  {
    date: '16 сентября',
    title:
      'Развитие личностного потенциала как ценность современного образования',
    description:
      'Добро пожаловать на IV Всероссийскую научно-практическую конференцию участников Программы по развития личностного потенциала!',
    link: 'Читать полностью',
  },
];
