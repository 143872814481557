import React from 'react';
import { Layout } from '../../../components/Layout';
import { RegistrationChoose } from '../../../components/pages/auth/RegistrationChoose';

export const RegistrationChoosePage = () => {
  return (
    <Layout>
      <RegistrationChoose />
    </Layout>
  );
};
