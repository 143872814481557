import { scaleBand, scaleLinear, max } from 'd3';
import classNames from 'classnames/bind';
import styles from './BarChartMethods.module.scss';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ResearchStatsSurveyStatisticsAggr } from '../../../../models/research';
import { Typography } from '../../Typography';
const cx = classNames.bind(styles);

type ChartData = {
  x: string;
  y: string;
};

type HorizontalBarChartProps = {
  data: ChartData[] | undefined;
  defaultMax?: number;
  statMeth: ResearchStatsSurveyStatisticsAggr;
};

export const BarChartMethods = ({
  data,
  defaultMax = 100,
  statMeth,
}: HorizontalBarChartProps) => {
  const [sizeWidth, setSizeWidth] = useState<number>(0);
  const [sizeHeight, setSizeHeight] = useState<number>(0);
  const ref = useRef();

  const fallBackData = Array.from(Array(defaultMax + 1).keys())
    .slice(1)
    .map((value) => ({ x: String(value), y: '' }));

  const dataToRender = useMemo(() => {
    if (!data || data.length === 0) return fallBackData;
    return data;
  }, [data, fallBackData]);

  const [marksWidth, setMarksWidth] = useState(() => dataToRender.map(() => 0));

  const resizeHandler = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const width = ref?.current?.clientWidth || {};
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const height = ref?.current?.clientHeight || {};
    setSizeWidth(width);
    setSizeHeight(height);
  };

  const minPercentDomain = (min: number | null, max: number | null) => {
    if (typeof min === 'number' && typeof max === 'number') {
      return (+min / +max) * (sizeWidth - 40) < 0
        ? 0
        : (+min / +max) * (sizeWidth - 40);
    } else {
      return 0;
    }
  };

  const maxPercentDomain = (maxP: number | null, max: number | null) => {
    if (typeof maxP === 'number' && typeof max === 'number') {
      if (maxP > max) {
        return sizeWidth - 50 < 0 ? 0 : sizeWidth - 40;
      } else {
        return (+maxP / +max) * (sizeWidth - 40) < 0
          ? 0
          : (+maxP / +max) * (sizeWidth - 40);
      }
    } else {
      return 0;
    }
  };

  const yValue = (d: ChartData) => d.y;
  const xValue = useCallback((d: ChartData) => Number(d.x), []);

  const xScale = scaleLinear()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    .domain([0, 100])
    .range([0, sizeWidth - 40]);

  useEffect(() => {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        setMarksWidth(() => dataToRender.map((d) => xScale(xValue(d)) + 2.5));
      });
    });
  }, [dataToRender, xValue, xScale]);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.containerText}>
        {Object.entries(statMeth).map(([key, value], i) => (
          // <div key={i} className={styles.container}>
          <>
            <div className={styles.meth}>
              <Typography
                size="s"
                color="main"
                fontWeight={500}
                customLineHeight={20}
              >
                &laquo;{key.split("'")[1]}&raquo;
              </Typography>
            </div>
            <div className={styles.methResult}>
              {Object.entries(value).map(([key, values], i) => (
                <div key={i} className={styles.methResultText}>
                  <Typography
                    size="xs"
                    color="gray6"
                    align="right"
                    customLineHeight={12}
                    className={styles.textR}
                  >
                    {key}
                  </Typography>
                  <div className={styles.lineSVG}>
                    <svg height="100%" width={`${sizeWidth}px`}>
                      <g key={'dsd'}>
                        <defs>
                          <linearGradient
                            id="BLUE_RGB"
                            x1="280"
                            y1="12.8049"
                            x2="1.72959e-06"
                            y2="12.805"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#A5CFEC" />
                            <stop offset="0.501635" stopColor="#A1D6E1" />
                            <stop offset="1" stopColor="#A0E1D4" />
                          </linearGradient>
                        </defs>
                        <rect
                          fill={`url(#BLUE_RGB)`}
                          className={styles.mark}
                          rx={7}
                          ry={7}
                          x={-6}
                          y={0}
                          width={
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            values?.mean_percent_from_max
                              ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                (sizeWidth - 31) * values.mean_percent_from_max
                              : 0
                          }
                          height={25}
                        />
                        <text
                          className={cx(styles.markLabel, {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            [styles.isReady]: values?.mean_percent_from_max,
                          })}
                          x={
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            values?.mean_percent_from_max
                              ? ((sizeWidth - 40) *
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  values.mean_percent_from_max) /
                                2
                              : 0
                          }
                          y={13}
                        >
                          {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                          {/*@ts-ignore*/}
                          {values?.mean?.toFixed(1) || 0}
                        </text>
                        {minPercentDomain(
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          values?.confint95_min,
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          values?.max
                        ) < // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        (values?.mean_percent_from_max
                          ? ((sizeWidth - 40) *
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              values.mean_percent_from_max) /
                            2
                          : 0) ? (
                          <>
                            <line
                              className={styles.line}
                              y1={13}
                              y2={13}
                              x1={minPercentDomain(
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                values?.confint95_min,
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                values?.max
                              )}
                              x2={
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                (values?.mean_percent_from_max
                                  ? ((sizeWidth - 40) *
                                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore
                                      values.mean_percent_from_max) /
                                    2
                                  : 0) -
                                String(
                                  Number(
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    values.mean_percent_from_max
                                  ).toFixed(1)
                                ).length
                              }
                              style={{
                                strokeDasharray: '5 5',
                                stroke: '#838383',
                              }}
                            />
                            <line
                              className={styles.line}
                              y1={13}
                              y2={13}
                              x1={
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                (values?.mean_percent_from_max
                                  ? ((sizeWidth - 40) *
                                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore
                                      values.mean_percent_from_max) /
                                    2
                                  : 0) +
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                20
                              }
                              x2={maxPercentDomain(
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                values?.confint95_max,
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                values?.max
                              )}
                              style={{
                                strokeDasharray: '5 5',
                                stroke: '#838383',
                              }}
                            />
                          </>
                        ) : (
                          <line
                            className={styles.line}
                            y1={13}
                            y2={13}
                            x1={
                              Math.abs(
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                (values?.mean_percent_from_max
                                  ? ((sizeWidth - 40) *
                                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore
                                      values.mean_percent_from_max) /
                                    2
                                  : 0) -
                                  minPercentDomain(
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    values?.confint95_min,
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    values?.max
                                  )
                              ) < 10
                                ? minPercentDomain(
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    values?.confint95_min,
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    values?.max
                                  ) +
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  String(values?.mean_percent_from_max).length
                                : minPercentDomain(
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    values?.confint95_min,
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    values?.max
                                  )
                            }
                            x2={maxPercentDomain(
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              values?.confint95_max,
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              values?.max
                            )}
                            style={{
                              strokeDasharray: '5 5',
                              stroke: '#838383',
                            }}
                          />
                        )}
                        {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          (values?.mean_percent_from_max
                            ? ((sizeWidth - 40) *
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                values.mean_percent_from_max) /
                              2
                            : 0) <
                            minPercentDomain(
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              values?.confint95_min,
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              values?.max
                            ) && // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          (values?.mean_percent_from_max
                            ? ((sizeWidth - 40) *
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                values.mean_percent_from_max) /
                              2
                            : 0) +
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            String(values.mean_percent_from_max).length >
                            minPercentDomain(
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              values?.confint95_min,
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              values?.max
                            ) ? (
                            <>
                              <line
                                className={styles.line}
                                y1={2}
                                y2={8}
                                x1={minPercentDomain(
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  values?.confint95_min,
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  values?.max
                                )}
                                x2={minPercentDomain(
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  values?.confint95_min,
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  values?.max
                                )}
                                style={{ stroke: '#838383' }}
                              />
                              <line
                                className={styles.line}
                                y1={18}
                                y2={24}
                                x1={minPercentDomain(
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  values?.confint95_min,
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  values?.max
                                )}
                                x2={minPercentDomain(
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  values?.confint95_min,
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  values?.max
                                )}
                                style={{ stroke: '#838383' }}
                              />
                            </>
                          ) : (
                            <line
                              className={styles.line}
                              y1={10}
                              y2={16}
                              x1={minPercentDomain(
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                values?.confint95_min,
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                values?.max
                              )}
                              x2={minPercentDomain(
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                values?.confint95_min,
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                values?.max
                              )}
                              style={{ stroke: '#838383' }}
                            />
                          )
                        }
                        <line
                          className={styles.line}
                          y1={10}
                          y2={16}
                          x1={maxPercentDomain(
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            values?.confint95_max,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            values?.max
                          )}
                          x2={maxPercentDomain(
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            values?.confint95_max,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            values?.max
                          )}
                          style={{ stroke: '#838383' }}
                        />
                        <text
                          className={cx(styles.markLabel, {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            [styles.isReady]: values?.max,
                          })}
                          x={sizeWidth - 24}
                          y={13}
                        >
                          {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                          {/*@ts-ignore*/}
                          {values?.max || 0}
                        </text>
                      </g>
                    </svg>
                  </div>
                </div>
              ))}
            </div>
          </>
          // </div>
        ))}
      </div>
      {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
      {/*@ts-ignore*/}
      <div className={styles.containerSVG} ref={ref}>
        <svg width="100%" height="100%">
          <g transform={`translate(10,0)`}>
            {/* Axis bottom */}
            {xScale.ticks(11).map((tickValue) => (
              <g
                className={styles.tick}
                key={tickValue}
                transform={`translate(${xScale(tickValue)},0)`}
              >
                <line className={styles.line} y2={sizeHeight - 20} />
                <text
                  className={styles.xAxisLabel}
                  style={{ textAnchor: 'middle' }}
                  dy="1em"
                  y={sizeHeight - 10}
                >
                  {tickValue}%
                </text>
              </g>
            ))}

            {data?.map((d, i) => (
              <g key={yValue(d)}></g>
            ))}
          </g>
        </svg>
      </div>
    </div>
  );
};
