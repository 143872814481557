import React, { useState } from 'react';

import { frontendRoutes } from '../../../utils/router/routes';

//ui
import { Breadcrumbs } from '../../ui/Breadcrumbs';
import { ChangePasswordForm } from './ChangePasswordForm';
import { ConfirmPasswordForm } from './ConfirmPassword';

import styles from './ChangePassword.module.scss';

export const ChangePassword = () => {
  const [isChangePassword, setIsChangePassword] = useState(false);

  const titleOldPassword = 'Изменение пароля';
  const titleNewPassword = 'Введите новый пароль';

  const changePasswordTitle = !isChangePassword
    ? titleOldPassword
    : titleNewPassword;

  return (
    <div className={styles.changePassword}>
      <div className="local-container">
        <Breadcrumbs
          links={[
            {
              title: 'Управление профилем',
              link: frontendRoutes.user.profile.index,
            },
            { title: 'Изменение пароля' },
          ]}
          homeLink={frontendRoutes.mainPage}
        />
      </div>
      <span className={styles.changePassword__header}>
        {changePasswordTitle}
      </span>
      <div className={styles.changePassword__form}>
        {isChangePassword ? (
          <ConfirmPasswordForm />
        ) : (
          <ChangePasswordForm setIsChangePassword={setIsChangePassword} />
        )}
      </div>
    </div>
  );
};
