import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import { defaultFormikOptions } from '../../../../../models/formik';
import { RegistrationErrors } from '../../../../../models/user/registration';
import { VALIDATION_ERRORS } from '../../../../../models/validations/errors/errors';
import {
  respondentRegistrationInitialValues,
  respondentRegistrationValidationSchema,
} from '../../../../../models/validations/schemas/validations';
import { useRegistrationRespondentMutation } from '../../../../../store/VbudusheeAPI';
import { frontendRoutes } from '../../../../../utils/router/routes';
import { OCCUPATION } from '../../../../../constants/user';

//  ui
import { Button } from '../../../../ui/Button';
import { CheckBox } from '../../../../ui/Form/CheckBox';
import { InputPassword } from '../../../../ui/Form/InputPassword';
import { InputText } from '../../../../ui/Form/InputText';
import { Select } from '../../../../ui/Form/Select';
import { LinkTo } from '../../../../ui/LinkTo';
import { REGIONS } from '../../../../../models/daData';

import styles from './RegForm.module.scss';
import { Box } from '../../../../ui/Box';
import debounce from 'lodash.debounce';
const cx = classNames.bind(styles);

export const RegForm = () => {
  const navigate = useNavigate();
  const [registration, { error, isSuccess }] =
    useRegistrationRespondentMutation();
  const [serverError, setServerError] = useState<string | undefined>();
  const [isDataValid, setDataValid] = useState(false);
  const regions = [];
  for (const region in REGIONS) regions.push(region);

  const validHref = useMemo(() => {
    const API_URL = process.env.REACT_APP_API_URL?.slice(0, -3);
    return `${API_URL}files/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%B2_%D0%BE%D1%82%D0%BD%D0%BE%D1%88%D0%B5%D0%BD%D0%B8%D0%B8_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8_%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_1.pdf`;
  }, []);

  const handleSubmit = async (
    values: typeof respondentRegistrationInitialValues
  ) => {
    setServerError(undefined);

    if (!isDataValid) {
      setServerError(VALIDATION_ERRORS.policy);
    } else {
      const url = process.env.REACT_APP_API_URL;

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({ email: formik.values.email }),
      };

      const res = await fetch(`${url}/validate-email`, options);
      const resJson = await res.json();
      if (!res.ok) {
        formik.errors.email = resJson.message;
        return;
      }
      const result: any = await registration(formik.values);
      if (result?.error?.status === 500) {
        navigate('/500');
      }

      localStorage.setItem('email', values.email);
      if ('data' in result) {
        localStorage.setItem('token', result.data.access_token);
        setTimeout(() => {
          navigate(frontendRoutes.user.confirmCode);
        }, 0);
      }
    }
  };

  const formik = useFormik({
    initialValues: respondentRegistrationInitialValues,
    validationSchema: respondentRegistrationValidationSchema,
    onSubmit: handleSubmit,
    ...defaultFormikOptions,
  });

  useEffect(() => {
    if (error && 'data' in error) {
      const serverErrors = error.data as {
        errors: RegistrationErrors;
        message: string;
      };

      if (
        serverErrors.message.search(/The email has already been taken/i) !==
          -1 ||
        serverErrors.message.search(/Email already registred/i) !== -1
      ) {
        formik.setErrors({
          email: VALIDATION_ERRORS.server.emailAlreadyTaken,
        });
      } else if (
        serverErrors.message.search(/Required data are not filled/i) !== -1
      ) {
        setServerError(VALIDATION_ERRORS.server.requiredData);
      } else if (serverErrors.message.search(/Wrong data type/i) !== -1) {
        setServerError(VALIDATION_ERRORS.server.wrongData);
      } else if (
        serverErrors.message.search(
          /organizationTin was already created in database/i
        ) !== -1
      ) {
        setServerError(VALIDATION_ERRORS.server.innExists);
      } else if (
        serverErrors.message.search(/email must be a valid email address/i) !==
        -1
      ) {
        setServerError(VALIDATION_ERRORS.server.invalidEmail);
      } else {
        setServerError(serverErrors.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <div className={styles.form}>
          <div className={styles.form__block}>
            <div className={styles.form__header}>Пользователь</div>
            <InputText name="email" placeholder="Email*" />
            <InputPassword name="password" placeholder="Пароль*" />
            <div style={{ marginLeft: 'auto', marginRight: '0px' }}>
              <InputPassword
                name="confirmPassword"
                placeholder="Введите пароль повторно*"
                disabled={!formik.values.password}
              />
            </div>
          </div>

          <div className={styles.form__block}>
            <div className={styles.form__header}>Основная информация</div>
            <InputText name="last_name" placeholder="Фамилия*" />
            <Box />
            {/*<Select*/}
            {/*  name="professional_category"*/}
            {/*  label="Сфера профессиональной деятельности*"*/}
            {/*  options={OCCUPATION}*/}
            {/*/>*/}
            <InputText name="first_name" placeholder="Имя*" />
            <Box />
            <InputText name="middle_name" placeholder="Отчество" />
          </div>

          {serverError && (
            <div className={styles.form__error}>{serverError}</div>
          )}

          <div className={styles.form__policy_wrapper}>
            <CheckBox
              checked={isDataValid}
              onChange={() => setDataValid(!isDataValid)}
            />

            <div className={styles.form__policy}>
              <div>
                Я даю согласие на обработку персональных данных в соответствии с
                {/*<span*/}
                <a
                  href={validHref}
                  style={{ display: 'inline', width: 'auto' }}
                >
                  &nbsp;Политикой&nbsp;
                </a>
                по обработке персональных данных
              </div>
            </div>
          </div>

          <div className={styles.form__submit}>
            <Button
              label="Зарегистрироваться"
              type="submit"
              disabled={!formik.dirty || !formik.isValid || !isDataValid}
              gradient={true}
            />
          </div>

          <div className={styles.form__divider} />
          <LinkTo
            label="Регистрация исследователя от своего имени"
            link={frontendRoutes.user.registration}
          />
          <LinkTo
            label="Регистрация исследователя от имени организации"
            link={frontendRoutes.user.registrationCompany}
          />
        </div>
      </Form>
    </FormikContext.Provider>
  );
};
