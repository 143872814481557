export const IconTableEmpty = (): JSX.Element => {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="44" height="44" rx="22" fill="#30BA9A" />
      <path
        d="M25.2367 12.762H18.5837C16.5247 12.762 14.7497 14.431 14.7497 16.491V27.204C14.7497 29.38 16.4087 31.115 18.5837 31.115H26.5727C28.6327 31.115 30.3017 29.265 30.3017 27.204V18.038L25.2367 12.762Z"
        stroke="white"
      />
      <path
        d="M24.974 12.7502V15.6592C24.974 17.0792 26.123 18.2312 27.542 18.2342C28.859 18.2372 30.206 18.2382 30.297 18.2322"
        stroke="white"
      />
      <path d="M24.784 25.5579H19.387" stroke="white" />
      <path d="M22.7425 20.6056H19.3865" stroke="white" />
    </svg>
  );
};
