import { Provider } from 'react-redux';
import { createStore } from './store';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import { ProtectedRoutes } from './utils/router/ProtectedRoutes';
import { frontendRoutes } from './utils/router/routes';

//  pages
import { IndexPage } from './pages/index/IndexPage';
import { NotFoundPage } from './pages/NotFoundPage';
import {
  CompanyRegistrationPage,
  LoginPage,
  RegistrationChoosePage,
  RegistrationPage,
  RestorePassword,
  RestorePasswordAcceptPage,
  ProfilePage,
} from './pages/user';
import {
  MaterialsEventsPage,
  MaterialsResearchPage,
  MaterialsDocsPage,
  MaterialsFeedbackPage,
} from './pages/materials';
import {
  TeamsPage,
  TeamDocsPage,
  TeamEventsPage,
  TeamResearchPage,
  TeamParticipantsPage,
  TeamNewPage,
  TeamEditPage,
} from './pages/teams';
import {
  PublicEventsPage,
  PublicEventsRequestsPage,
  PublicEventPage,
} from './pages/publicEvents';
import {
  ResearchPage,
  ResearchEditPage,
  ResearchComparisonPage,
} from './pages/research';
import { DocumentPage, EditDocument } from './pages/document';
import { Ui } from './pages/ui/ui';

import './assets/fonts/style.css';
import './assets/global.scss';
import { ChangePasswordPage } from './pages/user/PersonalAccount/ProfilePage/ChangePasswordPage/ChangePasswordPage';
import { ChangeEmailPage } from './pages/user/PersonalAccount/ProfilePage/ChangeEmailPage/ChangeEmailPage';
import {
  // SurveyCompletePage,
  // StartSurveyPage,
  SurveyLoginPage,
} from './pages/survey/SurveyLoginPage';
import { QuestionnairePage } from './pages/survey/QuestionnairePage/Questionnaire';
import { SettingsEventPage } from './pages/settingsEvent/NewEventPage/SettingsEventPage';
import { InteractiveMapPage } from './pages/interactiveMap/InteractiveMapPage';
import { AccessesRolesPage } from './pages/settingsEvent/AccessesRolesPage/AccessesRolesPage';
import { GradingSystemPage } from './pages/settingsEvent/GradingSystemPage/GradingSystemPage';
import { ScrollToTop } from './utils/pages/ScrollToTop/ScrollToTop';
import { ExpertDocument } from './pages/document/ExpertDocument/ExpertDocument';
import { CreateEventPage } from './pages/createEvent/CreateEventPage';
import { ResearchCreatePage } from './pages/research/ResearchCreatePage/ResearchCreatePage';
import { VerifyEmailPage } from './pages/user/PersonalAccount/ProfilePage/VerifyEmailPage/VerifyEmailPage';
import { ProtectedAuthRoutes } from './utils/router/ProtectedAuthRoutes';
import { InviteToResearch } from './pages/InviteToResearch/InviteToResearch';
import { SurveyPage } from './pages/survey/SurveyPage';
import { RespondentRegistrationPage } from './pages/user/RegistrationPage/RespondentRegistrationPage';
import { FeedbackPage } from './pages/survey/FeedbackPage';
import { AutomaticAnalysisResultsPage } from './pages/automaticAnalysisResults';
import { RegistrationCode } from './pages/user/RegistrationCode/RegistrationCode';
import { InviteToEvent } from './pages/InviteToEvent';
import { ServerErrorPage } from './pages/ServerErrorPage';
import { Toast } from './components/ui/Toast';
import { RequestOnPublishEvents } from './pages/RequestOnPublishEvents';
import { RequestOnPublishResearch } from './pages/RequestOnPublishResearch';

const store = createStore(); // It is possible to add an optional params to store init func
const persistor = persistStore(store);

export function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ScrollToTop />
          <Toast />
          <Routes>
            {/* Common routes (allowed for the unauthorized and authorized users) */}
            <Route path="500" element={<ServerErrorPage />} />
            {/* Auth routes */}
            <Route element={<ProtectedAuthRoutes />}>
              <Route path={frontendRoutes.user.login} element={<LoginPage />} />
              <Route
                path={frontendRoutes.user.chooseRegType}
                element={<RegistrationChoosePage />}
              />
              <Route
                path={frontendRoutes.user.registration}
                element={<RegistrationPage />}
              />
              <Route
                path={frontendRoutes.user.registrationCompany}
                element={<CompanyRegistrationPage />}
              />
              <Route
                path={frontendRoutes.user.registrationRespondent}
                element={<RespondentRegistrationPage />}
              />
              <Route
                path={frontendRoutes.user.restorePass}
                element={<RestorePassword />}
              />
              <Route
                path={frontendRoutes.user.restorePassAccept}
                element={<RestorePasswordAcceptPage />}
              />
            </Route>

            <Route
              path={frontendRoutes.user.confirmCode}
              element={<RegistrationCode />}
            />

            {/* Index/main page */}
            <Route path={frontendRoutes.mainPage} element={<IndexPage />} />

            {/*Settings event*/}
            <Route
              path={`${frontendRoutes.settingsEvent.settingsEvent}/:eventId`}
              element={<SettingsEventPage />}
            />
            {/*Create event*/}
            <Route
              path={frontendRoutes.createEvent.createEvent}
              element={<CreateEventPage />}
            />

            {/*invite event*/}
            <Route
              path={`${frontendRoutes.settingsEvent.invite}/:invite`}
              element={<InviteToEvent />}
            />

            {/*invite event*/}
            <Route
              path={frontendRoutes.publicEventsCatalog.requestsOnPublish}
              element={<RequestOnPublishEvents />}
            />

            <Route
              path={`${frontendRoutes.settingsEvent.accessesAndRole}/:eventId`}
              element={<AccessesRolesPage />}
            />

            <Route
              path={`${frontendRoutes.settingsEvent.gradingSystem}/:eventId`}
              element={<GradingSystemPage />}
            />

            {/* map */}
            <Route
              path={frontendRoutes.map.interactiveMap}
              element={<InteractiveMapPage />}
            />

            {/*invite research*/}
            <Route
              path={`${frontendRoutes.research.invite}/:type/:token`}
              element={<InviteToResearch />}
            />

            <Route
              path={`${frontendRoutes.survey.survey}/:token`}
              element={<SurveyPage />}
            />

            <Route
              path={`${frontendRoutes.survey.feedback}/:token`}
              element={<FeedbackPage />}
            />

            {/* Allowed only if authorized */}
            <Route element={<ProtectedRoutes />}>
              <Route
                path={`${frontendRoutes.research.details}/:researchId`}
                element={<ResearchPage />}
              />

              {/* create research */}
              <Route
                path={`${frontendRoutes.research.new}`}
                element={<ResearchCreatePage />}
              />

              <Route
                path={`${frontendRoutes.research.requestsOnPublish}`}
                element={<RequestOnPublishResearch />}
              />

              <Route
                path={frontendRoutes.publicEventsCatalog.events}
                element={<PublicEventsPage />}
              />
              <Route
                path={`${frontendRoutes.publicEventsCatalog.events}/:eventId`}
                element={<PublicEventPage />}
              />

              {/* edit research */}
              <Route
                path={`${frontendRoutes.research.edit.settings}/:researchId`}
                element={<ResearchEditPage />}
              />
              <Route
                path={`${frontendRoutes.research.edit.permissions}/:researchId`}
                element={<ResearchEditPage />}
              />
              <Route
                path={`${frontendRoutes.research.edit.respondents}/:researchId`}
                element={<ResearchEditPage />}
              />
              <Route
                path={`${frontendRoutes.research.edit.survey}/:researchId`}
                element={<ResearchEditPage />}
              />

              {/* compare research */}
              <Route
                path={`${frontendRoutes.research.comparison}`}
                element={<ResearchComparisonPage />}
              />

              <Route
                path={`${frontendRoutes.research.comparison}/:researchId`}
                element={<ResearchComparisonPage />}
              />

              {/* publication research */}
              <Route
                path={`${frontendRoutes.research.publication}`}
                // element={<ResearchEditPage />}
              />

              {/* profile */}
              <Route
                path={frontendRoutes.user.profile.index}
                element={<ProfilePage />}
              />
              <Route
                path={frontendRoutes.user.changePassword}
                element={<ChangePasswordPage />}
              />
              <Route
                path={frontendRoutes.user.confirmPassword}
                element={<ChangePasswordPage />}
              />
              <Route
                path={frontendRoutes.user.changeMail}
                element={<ChangeEmailPage />}
              />
              <Route
                path={frontendRoutes.user.confirmMail}
                element={<ChangeEmailPage />}
              />

              {/* verify-email */}
              <Route
                path={`${frontendRoutes.user.verifyEmail}/:id/:hash`}
                element={<VerifyEmailPage />}
              />

              {/* team */}
              <Route
                path={frontendRoutes.teams.teams}
                element={<TeamsPage />}
              />
              <Route
                path={`${frontendRoutes.teams.team.docs}/:teamId`}
                element={<TeamDocsPage />}
              />
              <Route
                path={`${frontendRoutes.teams.team.events}/:teamId`}
                element={<TeamEventsPage />}
              />
              <Route
                path={`${frontendRoutes.teams.team.research}/:teamId`}
                element={<TeamResearchPage />}
              />
              <Route
                path={`${frontendRoutes.teams.team.participants}/:teamId`}
                element={<TeamParticipantsPage />}
              />
              <Route
                path={`${frontendRoutes.teams.team.new}`}
                element={<TeamNewPage />}
              />
              <Route
                path={`${frontendRoutes.teams.team.edit}/:teamId`}
                element={<TeamEditPage />}
              />

              {/* materials */}
              <Route
                path={frontendRoutes.materials.events}
                element={<MaterialsEventsPage />}
              />
              <Route
                path={frontendRoutes.materials.research}
                element={<MaterialsResearchPage />}
              />
              <Route
                path={frontendRoutes.materials.docs}
                element={<MaterialsDocsPage />}
              />
              <Route
                path={frontendRoutes.materials.feedback}
                element={<MaterialsFeedbackPage />}
              />

              {/* public events catalog */}
              <Route
                path={frontendRoutes.publicEventsCatalog.eventsRequest}
                element={<PublicEventsRequestsPage />}
              />

              {/* document */}
              <Route
                path={`${frontendRoutes.document.inner}/:token`}
                element={<DocumentPage />}
              />

              <Route
                path={`${frontendRoutes.document.edit}/:id`}
                element={<EditDocument />}
              />

              {/* document/expert */}
              <Route
                path={`${frontendRoutes.document.expert}/:id`}
                element={<ExpertDocument />}
              />

              {/* survey */}
              {/*<Route*/}
              {/*  path={frontendRoutes.survey.survey}*/}
              {/*  element={<StartSurveyPage />}*/}
              {/*/>*/}
              <Route
                path={frontendRoutes.survey.questionnaire}
                element={<QuestionnairePage />}
              />
              {/*<Route*/}
              {/*  path={frontendRoutes.survey.methods}*/}
              {/*  element={<QuestionsForMethods />}*/}
              {/*/>*/}
              {/*<Route*/}
              {/*  path={frontendRoutes.survey.complete}*/}
              {/*  element={<SurveyCompletePage />}*/}
              {/*/>*/}
              <Route
                path={frontendRoutes.survey.login}
                element={<SurveyLoginPage />}
              />

              {/* ui-components */}
              <Route path={frontendRoutes.ui} element={<Ui />} />
              {/* Default */}
              <Route
                path={frontendRoutes.mainPage}
                element={<Navigate to={frontendRoutes.mainPage} />}
              />
              <Route path="*" element={<NotFoundPage />} />

              {/* automatic analysis */}
              <Route
                path={`${frontendRoutes.document.automaticAnalysisResults}/:documentId`}
                element={<AutomaticAnalysisResultsPage />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}
