import { useNavigate } from 'react-router-dom';

//  ui
import { Layout } from '../../../components/Layout';
import { SettingsEvent } from '../../../components/pages/settingsEvent/SettingsEvent';

export const SettingsEventPage = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <SettingsEvent />
    </Layout>
  );
};
