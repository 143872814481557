import { useState, MouseEvent, useEffect } from 'react';
import styles from './Tag.module.scss';
import classNames from 'classnames/bind';

//  ui
import { Typography } from '../Typography';
import { IconTagClose } from '../Icons/IconTagClose';
import { useField } from 'formik';

const cx = classNames.bind(styles);

type TagProps = {
  className?: string;
  name: string;
  title: string;
  onChange: (e: MouseEvent | string) => void;
  deleteTag: (e: string) => void;
  active?: boolean;
  readonly?: boolean;
  variant?: 'white' | 'gray';
};

export const Tag = (props: TagProps): JSX.Element => {
  const {
    title,
    name,
    onChange,
    deleteTag,
    active,
    className,
    readonly = false,
    variant = 'gray',
  } = props;
  const [isActive, setIsActive] = useState(active);
  const [field, { error, touched }] = useField<string>(name);

  const onActiveTag = (e: string | MouseEvent) => {
    setIsActive(true);
    onChange(e);
  };

  const offActiveTag = (e: MouseEvent) => {
    deleteTag(title);
    setIsActive(false);
    e.stopPropagation();
  };

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  return (
    <div
      {...field}
      className={cx(
        'tag',
        {
          tag__active: isActive,
          tag__readonly: readonly,
        },
        `tag--${variant}`,
        className
      )}
      onClick={() => {
        if (readonly) return;
        onActiveTag(title);
      }}
    >
      <Typography
        size="s"
        color="black"
        className={cx('tag__text', { isActive: isActive })}
      >
        {title}
      </Typography>
      <div className={styles.tag__icon} onClick={offActiveTag}>
        {isActive && <IconTagClose />}
      </div>
    </div>
  );
};
