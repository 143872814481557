import { useGetCurrentUserQuery } from '../../../../store/VbudusheeAPI';

//  ui
import { Layout } from '../../../../components/Layout';
import { Profile } from '../../../../components/Profile';
import { Loader } from '../../../../components/ui/Loader';

export const ProfilePage = () => {
  const { data, isLoading, isError } = useGetCurrentUserQuery({});
  return (
    <Layout>
      {isLoading ? (
        <Loader.FullPage>
          <Loader />
        </Loader.FullPage>
      ) : (
        <Profile user={data} />
      )}
    </Layout>
  );
};
