import React from 'react';

import classNames from 'classnames/bind';

//ui
import styles from './TextareaType.module.scss';
import { Textarea } from '../../Form/Textarea/Textarea';

const cx = classNames.bind(styles);

type TextareaProps = {
  questionTitle: string;
  name: string;
  disabled?: boolean;
};

export const TextareaType = (props: TextareaProps) => {
  const { questionTitle, name, disabled } = props;
  const handleChange = (e: any) => {};

  return (
    <div className={cx('textareaType')}>
      <div className={cx('textareaType__title')}>{questionTitle}</div>
      <div className={cx('textareaType__item')}>
        <Textarea
          name={name}
          placeholder={'Ввод значения'}
          editable={!disabled}
        />
      </div>
    </div>
  );
};
