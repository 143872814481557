import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, FormikContext, useFormik } from 'formik';

//  ui
import { Layout } from '../../components/Layout';
import { Loader } from '../../components/ui/Loader';
import {
  useConfirmQuizMutation,
  useGetQuizIdToInviteQuery,
} from '../../store/VbudusheeAPI';
import { Breadcrumbs } from '../../components/ui/Breadcrumbs';
import { frontendRoutes } from '../../utils/router/routes';
import styles from './SurveyPage.module.scss';
import { SurveyForm } from '../../components/Survey/SurveyForm';
import { QuestionnaireForm } from '../../components/Survey/Questionnaire/QuestionnaireForm';
import { QuestionsForMethods } from '../../components/Survey/QuestionsForMethods/QuestionsForMethods';
import { questionnaireValidationSchema } from '../../models/validations/schemas/validations';
import { SurveyComplete } from '../../components/Survey/SurveyComplete/SurveyComplete';

export const SurveyPage = (): JSX.Element => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [step, setStep] = useState('start');
  const [paginationQuestionStep, setPaginationQuestionStep] = useState(0);
  const [paginationMethodStep, setPaginationMethodStep] = useState(0);
  const [confirmQuiz, { isLoading: isLoadingConfirm, isError: errorConfirm }] =
    useConfirmQuizMutation();

  const { data, isLoading, error } = useGetQuizIdToInviteQuery({
    invite: token,
  });

  useEffect(() => {
    if (error) {
      if (token) {
        localStorage.setItem('servey', token);
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (error.data.message === 'This action is unauthorized.') {
        navigate(`${frontendRoutes.user.login}`);
      } else {
        localStorage.removeItem('servey');
        navigate(`${frontendRoutes.mainPage}`);
      }
    }
  }, [error]);

  useEffect(() => {
    if (localStorage.getItem('servey')) {
      localStorage.removeItem('servey');
    }
  }, []);

  const handleSubmit = async () => {
    // //TODO fixed after update
    // if (values) {
    //   navigate(frontendRoutes.survey.methods);
    // }
    // // const result = await login(values);
  };

  const initialValuesQuestion = () => {
    const result: { [key: string]: string } = {};
    if (data) {
      data.research_questionnaires.forEach((el) => {
        if (data.research_questionnaires_answer_requirement.includes(el.id)) {
          result[el.id] = '';
        }
      });
    }
    return result;
  };

  const initialValuesMethod = useMemo(() => {
    const result: { [key: string]: [] } = {};
    if (data) {
      data.research_methods.forEach((el) => {
        result[el.id] = [];
      });
    }
    return result;
  }, [data]);

  const regionId = useMemo(() => {
    let result = undefined;
    if (data) {
      data.research_questionnaires.forEach((el) => {
        if (el.survey.question_slug === 'region') {
          result = el.id;
        }
      });
    }
    return result;
  }, [data]);

  const formikQuestion = useFormik({
    initialValues: initialValuesQuestion(),
    validationSchema: questionnaireValidationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const formikMethods = useFormik({
    initialValues: initialValuesMethod,
    validationSchema: questionnaireValidationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const validQuestion = useMemo(() => {
    const result: { [key: string]: any } = {};
    if (formikQuestion.values) {
      for (const el in formikQuestion.values) {
        if (formikQuestion.values[el]) {
          result[el] = formikQuestion.values[el];
        }
      }
    }
    return result;
  }, [formikQuestion.values]);

  const objectConstructor = (obj: { [key: string]: any }, array: any[]) => {
    for (let i = 0; i < array.length; i++) {
      if (Array.isArray(array[i])) {
        objectConstructor(obj[`${i + 1}`], array[i]);
      } else {
        obj[`${i + 1}`] = array[i];
      }
    }
  };

  function arrToObj(arr: any[] | string) {
    if (!Array.isArray(arr)) {
      return Number(arr);
    }
    const result = {};
    for (const key in arr) {
      if (Number.isInteger(arrToObj(arr[key]))) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        result[`${Number(key) + 1}`] = arrToObj(arr[key]);
      } else {
        continue;
      }
    }
    return result;
  }

  const validMethod = useMemo(() => {
    const result: { [key: string]: any } = {};
    if (formikMethods.values) {
      for (const el in formikMethods.values) {
        if (formikMethods.values[el].length > 0) {
          result[el] = arrToObj(
            formikMethods.values[el].flat(Infinity)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // .filter((el) => el !== undefined)
          );
          // result[el] = arrToObj(formikMethods.values[el]);
        }
      }
    }
    return result;
  }, [formikMethods.values]);

  const submitHandler = async () => {
    if (data?.research_method_feedback) {
      const result = await confirmQuiz({
        quiz_answer_id: data?.quiz_answer_id,
        research_questionnaires_answers: validQuestion,
        research_methods_answers: validMethod,
      });
    } else {
      const result = await confirmQuiz({
        quiz_answer_id: data?.quiz_answer_id,
        research_questionnaires_answers: validQuestion,
        research_methods_answers: validMethod,
      });
    }
    // if ('data' in result) {
    //   console.log('good')
    // }
  };

  useEffect(() => {
    if (formikQuestion.dirty || formikMethods.dirty) {
      submitHandler();
    }
  }, [formikQuestion.values, formikMethods.values]);

  return (
    <Layout>
      {isLoading ? (
        <Loader.FullPage>
          <Loader />
        </Loader.FullPage>
      ) : (
        <div className={styles.survey}>
          <div className="local-container">
            <Breadcrumbs
              links={[{ title: 'Прохождение опроса' }]}
              homeLink={frontendRoutes.mainPage}
            />
          </div>
          <div className={styles.survey__layout}>
            <div className={styles.survey__form}>
              {step === 'start' && (
                <div>
                  <div className={styles.survey__header}>
                    Прежде чем приступить к опросу, пожалуйста подтвердите своё
                    согласие на обработку персональных данных
                  </div>
                  <SurveyForm setStep={setStep} />
                </div>
              )}
              <FormikContext.Provider value={formikQuestion}>
                <Form>
                  {step === 'quiz' && (
                    <div>
                      <div className={styles.survey__title}>
                        Прохождение опроса
                      </div>
                      <QuestionnaireForm
                        questionnaireDescription={data?.research_greeting_text}
                        questionnaires={data?.research_questionnaires}
                        setStep={setStep}
                        regionId={regionId}
                        requirement={
                          data!.research_questionnaires_answer_requirement
                        }
                        paginationQuestionStep={paginationQuestionStep}
                        setPaginationQuestionStep={setPaginationQuestionStep}
                        errorConfirm={errorConfirm}
                      />
                    </div>
                  )}
                </Form>
              </FormikContext.Provider>
              <FormikContext.Provider value={formikMethods}>
                <Form>
                  {step === 'method' && (
                    <QuestionsForMethods
                      methods={data?.research_methods}
                      setStep={setStep}
                      requirement={data?.research_methods_answer_requirement}
                      paginationMethodStep={paginationMethodStep}
                      setPaginationMethodStep={setPaginationMethodStep}
                      errorConfirm={errorConfirm}
                    />
                  )}
                </Form>
              </FormikContext.Provider>
              {step === 'complete' && (
                <SurveyComplete
                  feedback={data?.research_method_feedback}
                  gratitudeText={data?.research_gratitude}
                  setStep={setStep}
                  answerId={data?.quiz_answer_id}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};
