export const IconFilerBtn = (): JSX.Element => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.74479 10.9911H0.922043"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0786 10.991C13.0786 12.0514 12.219 12.911 11.1586 12.911C10.0982 12.911 9.23859 12.0514 9.23859 10.991C9.23859 9.92987 10.0982 9.07104 11.1586 9.07104C12.219 9.07104 13.0786 9.92987 13.0786 10.991Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25513 3.17475H13.0787"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.921722 3.17476C0.921722 4.23594 1.78133 5.09476 2.84172 5.09476C3.90211 5.09476 4.76172 4.23594 4.76172 3.17476C4.76172 2.11437 3.90211 1.25476 2.84172 1.25476C1.78133 1.25476 0.921722 2.11437 0.921722 3.17476Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
