import React from 'react';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

import { frontendRoutes } from '../../../utils/router/routes';

//ui
import { Breadcrumbs } from '../../ui/Breadcrumbs';
import { Button } from '../../ui/Button';

import styles from './SurveyComplete.module.scss';

const cx = classNames.bind(styles);

type ISurveyCompete = {
  feedback?: boolean;
  downloadPdf?: boolean;
  gratitudeText?: string;
  setStep: (value: string) => void;
  answerId?: string;
};

export const SurveyComplete = (props: ISurveyCompete) => {
  const { feedback, downloadPdf, gratitudeText, answerId } = props;

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(frontendRoutes.mainPage);
  };

  return (
    <div className={cx('survey')}>
      <span className={styles.survey__header}>Опрос пройден</span>
      <span className={styles.survey__header_subHeader}>
        {gratitudeText ? gratitudeText : 'Спасибо за Ваши ответы.'}
      </span>
      {downloadPdf && (
        <span className={styles.survey__header_pdf}>
          Вы можете скачать опрос в PDF
        </span>
      )}
      <div className={cx('button')}>
        <Button
          label={'Перейти на главную'}
          type={'button'}
          gradient
          clicked={handleClick}
        />
        {feedback && (
          <Button
            label={'Получить обратную связь'}
            type={'button'}
            gradient
            clicked={() =>
              navigate(`${frontendRoutes.survey.feedback}/${answerId}`)
            }
          />
        )}
      </div>
    </div>
  );
};
