import { useTypedSelector } from '../../../../../store';
import { uploadDocument } from '../../../../../store/documentSlice/selectors';
import { Tag } from '../../../../ui/Tag';
import { Typography } from '../../../../ui/Typography';

import styles from './DocumentSemanticsPreview.module.scss';

export const DocumentSemanticsPreview = () => {
  const markedUpDocument = useTypedSelector(uploadDocument);
  return (
    <div className={styles.wrapper}>
      {markedUpDocument.map(({ title, text, tags }, index) => (
        <div className={styles.element} key={index}>
          <Typography
            className={styles.title}
            vMargin={5}
            fontWeight={600}
            size="s"
            color="black"
          >
            {title}
          </Typography>

          <p className={styles.text}>{text}</p>

          <Typography vMargin={5} fontWeight={600} size="s" color="black">
            Тематические теги
          </Typography>
          <div className={styles.tags}>
            {tags?.map((tag, index) => {
              return (
                <Tag
                  active={false}
                  key={index}
                  name={'tags'}
                  deleteTag={() => {}}
                  onChange={() => {}}
                  title={tag}
                  readonly
                  variant="white"
                />
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};
