import { useEffect, useMemo, useState } from 'react';
import { FormikContext, Form, useFormik } from 'formik';
import { useDrop } from 'react-dnd';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

import {
  researchSaveTemplate,
  researchSurveySchema,
} from '../../../../../models/validations/schemas/research';
import {
  SurveyConstructorType,
  MethodItemType,
  ResearchDetails as ResearchDetailsType,
  QuestionnaireDictItem,
  QuestionItemType,
  ResearchSurvey,
  GetQuestionnairesResponse,
  MethodDictItem,
} from '../../../../../models/research';
import { downloadPDF } from '../../../../../utils/api/downloadPDF';

//  ui
import { Box } from '../../../../ui/Box';
import { Typography } from '../../../../ui/Typography';
import { Textarea } from '../../../../ui/Form/Textarea/Textarea';
import { Button } from '../../../../ui/Button';
import { RadioButton } from '../../../../ui/Form/RadioButton';

import styles from './ResearchEditSurvey.module.scss';
import {
  useEditSurveyRequestMutation,
  useGetResearchQuizzesQuery,
  usePutSurveyRequestMutation,
  VbudusheeAPI,
} from '../../../../../store/VbudusheeAPI';
import { frontendRoutes } from '../../../../../utils/router/routes';
import { ModalCustomQuestion } from '../../../../ui/ModalCustomQuestion';
import { ContentModal } from '../../../../ui/ContentModal';
import { InputText } from '../../../../ui/Form/InputText';
import { SurveyCard } from './SurveyCard/SurveyCard';
import { QuestionItem } from '../../../../ui/QuestionItem';
import { QuestionsForMethodsForm } from '../../../../Survey/QuestionsForMethods/QuestionsForMethodsForm';
import { downloadPolicy } from '../../../../../utils/api/downloadFiles';
import { Toggle } from '../../../../ui/Toggle';
import { SurveyPageLayout } from './SurveyPageLayout';
import { useToasts } from '../../../../../hooks/useToasts';

const cx = classNames.bind(styles);

type ResearchEditSurveyTypes = {
  researchId: string;
  details: ResearchDetailsType;
  survey?: { data: ResearchSurvey } | undefined;
  setSaveTemplate: (value: boolean) => void;
  saveTemplate: boolean;
  setDownloadTemplate: (value: boolean) => void;
  downloadTemplate?: boolean;
  setIsMenu: (value: boolean) => void;
  isTheAuthor?: boolean;
  requiredQuestion: number[];
  setRequiredQuestion: (v: any) => void;
  chosenQuestion: QuestionItemType[];
  setChosenQuestion: (v: any) => void;
};

export const ResearchEditSurvey = ({
  researchId,
  details,
  survey,
  setSaveTemplate,
  saveTemplate,
  setDownloadTemplate,
  downloadTemplate,
  setIsMenu,
  isTheAuthor,
  requiredQuestion,
  setRequiredQuestion,
  chosenQuestion,
  setChosenQuestion,
}: ResearchEditSurveyTypes) => {
  // required fields
  const [requiredMethod, setRequiredMethod] = useState<number[]>([]);
  const [chosenMethod, setChosenMethod] = useState<MethodItemType[]>([]);
  const [customQuestion, setCustomQuestion] = useState<boolean>(false);
  const [surveyData, setSurveyData] = useState<
    { data: ResearchSurvey } | undefined
  >(survey);
  const [modalDownload, setModalDownload] = useState<boolean>(false);
  const [modalSave, setModalSave] = useState<boolean>(false);
  const [modalError, setModalError] = useState<string>('');
  const navigate = useNavigate();
  const { onShowToast } = useToasts();

  const [{ isOver: isMethodOver }, methodDrop] = useDrop({
    accept: SurveyConstructorType.METHOD,
    drop: (item: MethodItemType) => {
      const result = chosenMethod.filter((el) => el?.id === item?.id);
      if (result.length === 0) {
        if (details?.data.research_quiz_has_answers) {
          onShowToast({
            type: 'danger',
            name: 'research_quiz_has_answers',
            title: 'Ошибка',
            description:
              'Редактирование опросника возможно только до начала сбора ответов респондентов. В Вашем опросе присутствуют уже собранные ответы, поэтому редактирование вопросов недоступно.',
            maxCount: 1,
            timer: 9000,
          });
        } else {
          setChosenMethod((prev) => [...prev, ...[item]]);
        }
      } else {
        onShowToast({
          type: 'danger',
          name: `repeat_method_${item.id}`,
          title: 'Ошибка',
          description: `Методика "${item.title}" уже присутствует в опросе.`,
          timer: 3000,
          autocorrect: true,
        });
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const [trigger] = VbudusheeAPI.endpoints.getTemplateQuizzes.useLazyQuery();
  // const [triggerPDF] = VbudusheeAPI.endpoints.getPdf.useLazyQuery();
  //
  // const getPDF = async (researchId: string) => {
  //   trigger({ researchId })
  //     .unwrap()
  //     .then((res) => {
  //       setSurveyData(res);
  //       // if (res.result === 'success') {
  //       //   setInterviewOpen(true);
  //       //   formik.setFieldValue('research_interview_open', `${interviewOpen}`);
  //       //   refetch?.();
  //       // }
  //     })
  //     .catch(() => {
  //       // if (reason) {
  //       //   setIsError(error.message);
  //       // }
  //     });
  // };

  const getTemplate = async (researchId: string) => {
    trigger({
      templateId: formikSave.values.template_quiz,
      params: { research_id: researchId },
    })
      .unwrap()
      .then((res) => {
        setSurveyData(res);
        // if (res.result === 'success') {
        //   setInterviewOpen(true);
        //   formik.setFieldValue('research_interview_open', `${interviewOpen}`);
        //   refetch?.();
        // }
      })
      .catch(() => {
        // if (reason) {
        //   setIsError(error.message);
        // }
      });
  };

  const [{ isOver: isQuestionOver }, questionDrop] = useDrop({
    accept: SurveyConstructorType.QUESTION,
    drop: (item: QuestionItemType) => {
      const result = chosenQuestion.filter((el) => el?.id === item?.id);
      if (result.length === 0) {
        if (details?.data.research_quiz_has_answers) {
          onShowToast({
            type: 'danger',
            name: 'research_quiz_has_answers',
            title: 'Ошибка',
            description:
              'Редактирование опросника возможно только до начала сбора ответов респондентов. В Вашем опросе присутствуют уже собранные ответы, поэтому редактирование вопросов недоступно.',
            maxCount: 1,
            timer: 9000,
          });
        } else {
          setChosenQuestion((prev: any) => [...prev, ...[item]]);
          if (item.questionRequired) {
            setRequiredQuestion((prev: any) => [...prev, item.id]);
          }
        }
      } else {
        onShowToast({
          type: 'danger',
          name: `repeat_question_${item.id}`,
          title: 'Ошибка',
          description: `Вопрос "${item.title}" уже присутствует в опросе.`,
          timer: 3000,
          autocorrect: true,
        });
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const [EditSurvey, { error: isEditSurveyError }] =
    useEditSurveyRequestMutation();

  const [PutSurvey, { error: isPutSurveyError }] =
    usePutSurveyRequestMutation();

  const { data: quizzes } = useGetResearchQuizzesQuery({});

  const submitHandlerTemplate = async () => {
    if (!formik.isValid) return;

    const result = await EditSurvey({
      quizzes_name: formikSave.values.quizzes_name,
      research_method_sociality: formik.values.research_method_sociality,
      research_questionnaires_answer_requirement: requiredQuestion,
      research_greeting_text: formik.values.research_greeting_text,
      research_gratitude_text: formik.values.research_gratitude_text,
      research_method_feedback: formik.values.research_method_feedback,
      research_methods_answer_requirement: requiredMethod,
      research_methods: formik.values.research_methods,
      research_questionnaires: chosenQuestion?.map((m) => m?.id) || null,
    });
    if ('data' in result) {
      onShowToast({
        type: 'success',
        name: 'save_quiz_template',
        title: 'Успех!',
        description: 'Шаблон опросника успешно сохранен',
        maxCount: 1,
        timer: 3000,
      });
    }
  };

  const submitHandler = async () => {
    if (!formik.isValid || !survey) return;

    const result = await PutSurvey({
      id: survey.data.id,
      body: {
        research_id: Number(researchId),
        research_method_sociality: formik.values.research_method_sociality,
        research_questionnaires_answer_requirement: requiredQuestion,
        research_greeting_text: formik.values.research_greeting_text,
        research_gratitude_text: formik.values.research_gratitude_text,
        research_method_feedback: formik.values.research_method_feedback,
        research_methods: formik.values.research_methods,
        research_methods_answer_requirement: requiredMethod,
        research_questionnaires: chosenQuestion?.map((m) => m?.id) || null,
      },
    });
    if ('data' in result) {
      onShowToast({
        type: 'success',
        name: 'save_quiz',
        title: 'Успех!',
        description: 'Опросник успешно сохранен',
        maxCount: 1,
        timer: 3000,
      });
    }
  };

  const addCustomQuestion = (value: { data: QuestionnaireDictItem }) => {
    const question = {
      id: value.data?.id,
      title: value.data.survey.question_name,
      description: value.data.survey.question_answers,
      questionType: value.data.survey.question_input_type,
      questionOrder: value.data.survey.question_order,
      questionRequired: value.data.survey.question_required,
    };

    setChosenQuestion((prev: any) => [...prev, ...[question]]);
    if (question.questionRequired) {
      setRequiredQuestion((prev: any) => [...prev, question.id]);
    }
  };

  const disableField = useMemo(() => {
    if (isTheAuthor) {
      if (details.data.research_publication_status !== 'unpublished') {
        if (details.data.research_publication_status === 'rework') {
          return false;
        }
        return details.data.research_publication_status !== 'rejected';
      }
      return false;
    }
    return true;
  }, [details, isTheAuthor]);

  const chosenQuestionFilter = useMemo(() => {
    return chosenQuestion.filter((el) => !el.questionType.includes('hidden'));
  }, [chosenQuestion]);

  const deleteQuestion = (id: string | number) => {
    if (details?.data.research_quiz_has_answers) {
      onShowToast({
        type: 'danger',
        name: 'research_quiz_has_answers',
        title: 'Ошибка',
        description:
          'Редактирование опросника возможно только до начала сбора ответов респондентов. В Вашем опросе присутствуют уже собранные ответы, поэтому редактирование вопросов недоступно.',
        maxCount: 1,
        timer: 9000,
      });
    } else {
      setChosenQuestion(chosenQuestion.filter((el) => el?.id !== id));
      setRequiredQuestion(requiredQuestion.filter((el) => el !== id));
    }
  };

  const validHref = useMemo(() => {
    const API_URL = process.env.REACT_APP_API_URL?.slice(0, -3);
    return `${API_URL}files/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%B2_%D0%BE%D1%82%D0%BD%D0%BE%D1%88%D0%B5%D0%BD%D0%B8%D0%B8_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8_%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_1.pdf`;
  }, []);

  useEffect(() => {
    if (surveyData && surveyData.data.research_methods?.length) {
      const value = surveyData.data.research_methods.map(
        (el: MethodDictItem) => {
          return {
            id: el.id,
            title: el.survey.name,
            methodDescription: el.survey,
          };
        }
      );
      if (surveyData.data.research_method_feedback) {
        formik.setFieldValue('research_method_feedback', true);
      }
      setChosenMethod(value);
    }
    if (surveyData && surveyData?.data.research_questionnaires?.length) {
      const value = surveyData.data.research_questionnaires.map(
        (el: QuestionnaireDictItem) => {
          return {
            id: el?.id,
            title: el?.survey?.question_name,
            description: el?.survey?.question_answers,
            questionType: el?.survey?.question_input_type,
            questionOrder: el?.survey?.question_order,
            questionRequired: el?.survey?.question_required,
          };
        }
      );
      setChosenQuestion(value);
    }

    if (
      surveyData &&
      surveyData?.data.research_methods_answer_requirement?.length
    ) {
      setRequiredMethod(surveyData?.data.research_methods_answer_requirement);
    }
    if (
      surveyData &&
      surveyData?.data.research_questionnaires_answer_requirement?.length
    ) {
      setRequiredQuestion(
        surveyData?.data.research_questionnaires_answer_requirement
      );
    }
  }, [surveyData]);

  useEffect(() => {
    if (isEditSurveyError) {
      setModalError(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isEditSurveyError.data.message || isEditSurveyError.data.error
      );
    }
    if (isPutSurveyError) {
      setModalError(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        isPutSurveyError.data.message || isPutSurveyError.data.error
      );
    }
  }, [isPutSurveyError, isEditSurveyError]);

  useEffect(() => {
    if (chosenMethod.length) {
      formik.setFieldValue(
        'research_methods',
        chosenMethod.map((m) => m?.id)
      );
      formik.setFieldValue(
        'research_method_feedback',
        surveyData?.data.research_method_feedback
      );
    } else {
      formik.setFieldValue('research_methods', []);
      formik.setFieldValue('research_method_feedback', false);
    }
  }, [chosenMethod]);

  useEffect(() => {
    if (chosenQuestion.length) {
      formik.setFieldValue(
        'research_questionnaires',
        chosenQuestion.map((m) => m?.id)
      );
    } else {
      formik.setFieldValue('research_questionnaires', []);
    }
  }, [chosenQuestion]);

  useEffect(() => {
    if (saveTemplate) {
      const errors = () => {
        let count = 0;
        for (const key in formik.errors) {
          count++;
        }
        return !!count;
      };
      if (errors()) {
        setSaveTemplate(false);
        setModalError(
          'Чтобы сохранить шаблон конструктор опроса должен  быть корректно заполнен'
        );
      } else {
        setModalSave(true);
      }
    }
  }, [saveTemplate]);

  useEffect(() => {
    if (downloadTemplate) {
      setModalDownload(true);
    }
  }, [downloadTemplate]);

  useEffect(() => {
    chosenQuestion.forEach((el) => {
      if (el.questionOrder) {
        if (!requiredQuestion.includes(el?.id)) {
          setRequiredQuestion((prev: any) => [...prev, el?.id]);
        }
      }
    });
  }, [chosenQuestion]);

  useEffect(() => {
    setSurveyData(survey);
  }, [survey]);

  useEffect(() => {
    if (!modalDownload) {
      formikSave.setFieldValue('quizzes_search', '');
    }
  }, [modalDownload]);

  const initialValues = {
    research_method_sociality:
      surveyData?.data.research_method_sociality || true,
    research_greeting_text: surveyData?.data.research_greeting_text || '',
    research_gratitude_text: surveyData?.data.research_gratitude || '',
    research_methods: surveyData?.data.research_methods
      ? surveyData?.data.research_methods[0]?.id
        ? surveyData?.data.research_methods.map((el) => {
            return el.id;
          })
        : []
      : [],
    research_method_feedback:
      surveyData?.data.research_method_feedback || false,
    research_questionnaires:
      surveyData?.data.research_questionnaires.map((el) => {
        return el.id;
      }) || [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: researchSurveySchema,
    onSubmit: submitHandler,
    enableReinitialize: true,
  });

  const comparisonInitialValues = useMemo(() => {
    return JSON.stringify(initialValues) === JSON.stringify(formik.values);
  }, [initialValues, formik.values]);

  const comparisonResearchRequirement = useMemo(() => {
    const first =
      requiredQuestion.toString() ===
      surveyData?.data.research_questionnaires_answer_requirement.toString();
    const second =
      requiredMethod.toString() ===
      surveyData?.data.research_methods_answer_requirement.toString();
    return first && second;
  }, [requiredQuestion, requiredMethod, surveyData]);

  const formikSave = useFormik({
    initialValues: {
      quizzes_name: '',
      template_quiz: '',
      quizzes_search: '',
    },
    validationSchema: researchSaveTemplate,
    onSubmit: submitHandlerTemplate,
  });

  const questionsSplitByPage = useMemo(() => {
    const PAGE_SIZE = 10;
    const total = chosenQuestionFilter.length;
    const pageCount = Math.ceil(total / PAGE_SIZE);

    const res: QuestionItemType[][] = [];

    let start = 0;

    while (res.length < pageCount) {
      res.push(chosenQuestionFilter.slice(start, start + PAGE_SIZE));
      start += PAGE_SIZE;
    }

    return res;
  }, [chosenQuestionFilter]);

  return (
    <>
      <FormikContext.Provider value={formik}>
        <Form>
          <div className={styles.wrapper}>
            <SurveyPageLayout pageNumber={1}>
              <Box mb={24}>
                <Typography color="main" fontWeight={600} size="s" upperCase>
                  Согласие на обработку персональных данных
                </Typography>
              </Box>
              <Box mb={24}>
                <div
                  className={cx(styles.coloredBlock, styles.coloredBlock_text)}
                >
                  Я даю
                  <span
                    className={styles.coloredBlock_a}
                    onClick={() => {
                      if (researchId) {
                        downloadPolicy(researchId, 'Согласие');
                      }
                    }}
                  >
                    &nbsp;согласие&nbsp;
                  </span>
                  на обработку персональных данных в соответствии с
                  <a
                    href={validHref}
                    style={{ display: 'inline', color: '#00809C' }}
                  >
                    &nbsp;Политикой&nbsp;
                  </a>
                  по обработке персональных данных
                </div>
              </Box>
            </SurveyPageLayout>

            <Box>
              <>
                {questionsSplitByPage.map((page, pageIndex) => (
                  <SurveyPageLayout key={pageIndex} pageNumber={2 + pageIndex}>
                    {pageIndex === 0 ? (
                      <div className={styles.block}>
                        <div className={styles.block__header}>
                          <Typography
                            color="main"
                            fontWeight={600}
                            size="s"
                            upperCase
                          >
                            Приветствие для респондентов
                          </Typography>
                          {!details.data.research_interview_open &&
                            !disableField && (
                              <Button
                                label="Очистить"
                                display="service"
                                color="danger"
                                type={'button'}
                                size="s"
                                clicked={() => {
                                  formik.setFieldValue(
                                    'research_greeting_text',
                                    ''
                                  );
                                  setTimeout(() =>
                                    formik.setFieldTouched(
                                      'research_greeting_text',
                                      true
                                    )
                                  );
                                }}
                              />
                            )}
                        </div>
                        <Textarea
                          name="research_greeting_text"
                          placeholder="Укажите информацию о целях исследования, а также о времени и правилах его прохождения"
                          editable={
                            !details.data.research_interview_open &&
                            !disableField
                          }
                        />
                      </div>
                    ) : null}

                    <div
                      className={styles.block}
                      ref={(el) => {
                        if (!el) return;
                        if (pageIndex === questionsSplitByPage.length - 1) {
                          return questionDrop(el);
                        }
                      }}
                    >
                      <div className={styles.block__header}>
                        <Typography
                          color="main"
                          fontWeight={600}
                          size="s"
                          upperCase
                        >
                          Анкетные вопросы
                        </Typography>
                        {/*{!details.data.research_interview_open && !disableField && (*/}
                        {/*  <Button*/}
                        {/*    label="Удалить все"*/}
                        {/*    display="service"*/}
                        {/*    color="black"*/}
                        {/*    size="s"*/}
                        {/*    icon={<IconBin />}*/}
                        {/*    clicked={() => setChosenQuestion([])}*/}
                        {/*  />*/}
                        {/*)}*/}
                      </div>
                      <div>
                        {page && (
                          <div className={cx('block__body')}>
                            {page.map((q, i: number) => (
                              <QuestionItem
                                key={i}
                                id={q?.id || 12}
                                questionType={q.questionType}
                                description={q.description}
                                title={q.title}
                                questionNumber={i + 1 + pageIndex * 10}
                                sizeTitle="s"
                                upperCase={true}
                                questionOrder={q.questionOrder}
                                setIsDelete={
                                  !q.questionOrder ? deleteQuestion : undefined
                                }
                                disable={
                                  disableField ||
                                  details.data.research_interview_open
                                }
                                active={false}
                                required={requiredQuestion
                                  .map((el) => el)
                                  .includes(q?.id)}
                                setRequired={() => {
                                  if (details?.data.research_quiz_has_answers) {
                                    onShowToast({
                                      type: 'danger',
                                      name: 'research_quiz_has_answers',
                                      title: 'Ошибка',
                                      description:
                                        'Редактирование опросника возможно только до начала сбора ответов респондентов. В Вашем опросе присутствуют уже собранные ответы, поэтому редактирование вопросов недоступно.',
                                      maxCount: 1,
                                      timer: 9000,
                                    });
                                  } else {
                                    if (
                                      requiredQuestion
                                        .map((el) => el)
                                        .includes(q?.id)
                                    ) {
                                      setRequiredQuestion((prev: any[]) =>
                                        prev.filter(
                                          (el: number) => el !== q?.id
                                        )
                                      );
                                      setChosenQuestion(
                                        (perv: QuestionItemType[]) =>
                                          perv.map((n) =>
                                            n.id === q.id
                                              ? {
                                                  ...n,
                                                  questionRequired: false,
                                                }
                                              : n
                                          )
                                      );
                                    } else {
                                      setRequiredQuestion((prev: any[]) => [
                                        ...prev,
                                        q?.id,
                                      ]);
                                      setChosenQuestion(
                                        (perv: QuestionItemType[]) =>
                                          perv.map((n) =>
                                            n.id === q.id
                                              ? { ...n, questionRequired: true }
                                              : n
                                          )
                                      );
                                    }
                                  }
                                }}
                              />
                            ))}
                          </div>
                        )}
                        {!details.data.research_interview_open &&
                          !disableField &&
                          pageIndex === questionsSplitByPage.length - 1 && (
                            <div
                              className={cx('coloredBlock', {
                                coloredBlock_centered: true,
                                coloredBlock_drop: isQuestionOver,
                              })}
                            >
                              <span className={styles.coloredBlock_text}>
                                Перетащите анкетные вопросы в эту зону из меню
                                слева <br />
                                или{` `}
                                <span
                                  className={styles.coloredBlock_a}
                                  onClick={() => {
                                    if (
                                      details?.data.research_quiz_has_answers
                                    ) {
                                      onShowToast({
                                        type: 'danger',
                                        name: 'research_quiz_has_answers',
                                        title: 'Ошибка',
                                        description:
                                          'Редактирование опросника возможно только до начала сбора ответов респондентов. В Вашем опросе присутствуют уже собранные ответы, поэтому редактирование вопросов недоступно.',
                                        maxCount: 1,
                                        timer: 9000,
                                      });
                                    } else {
                                      setCustomQuestion(true);
                                    }
                                  }}
                                >
                                  добавьте свои
                                </span>
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                  </SurveyPageLayout>
                ))}
              </>

              {/* Methods */}
              <div ref={methodDrop}>
                {chosenMethod.length === 0 && (
                  <div className={styles.block__header}>
                    <Typography
                      color="main"
                      fontWeight={600}
                      size="s"
                      upperCase
                    >
                      МЕТОДИКИ
                    </Typography>
                  </div>
                )}

                {chosenMethod.length === 0 &&
                  !details.data.research_interview_open &&
                  !disableField && (
                    <div
                      className={cx('coloredBlock', {
                        coloredBlock_centered: true,
                        coloredBlock_drop: isMethodOver,
                      })}
                    >
                      <span className={styles.coloredBlock_text}>
                        Перетащите выбранную методику в эту зону из меню слева
                      </span>
                    </div>
                  )}
                {chosenMethod.length > 0 &&
                  chosenMethod.map((method, index) => (
                    <SurveyPageLayout
                      pageNumber={2 + questionsSplitByPage.length + index}
                      key={`method${method?.id}`}
                    >
                      <div className={styles.block}>
                        <Box mb={38}>
                          <div className={styles.block__header}>
                            <Box flex>
                              <Box mr={20}>
                                <Typography
                                  color="main"
                                  fontWeight={600}
                                  size="s"
                                  upperCase
                                >
                                  {method.title}
                                  {requiredMethod
                                    .map((el) => el)
                                    .includes(method?.id) && '*'}
                                </Typography>
                              </Box>
                              <Box flex>
                                <Toggle
                                  isActive={requiredMethod
                                    .map((el) => el)
                                    .includes(method?.id)}
                                  clicked={() => {
                                    if (
                                      details?.data.research_quiz_has_answers
                                    ) {
                                      onShowToast({
                                        type: 'danger',
                                        name: 'research_quiz_has_answers',
                                        title: 'Ошибка',
                                        description:
                                          'Редактирование опросника возможно только до начала сбора ответов респондентов. В Вашем опросе присутствуют уже собранные ответы, поэтому редактирование вопросов недоступно.',
                                        maxCount: 1,
                                        timer: 9000,
                                      });
                                    } else {
                                      if (
                                        requiredMethod
                                          .map((el) => el)
                                          .includes(method?.id)
                                      ) {
                                        setRequiredMethod((prev) =>
                                          prev.filter((el) => el !== method?.id)
                                        );
                                      } else {
                                        setRequiredMethod((prev) => [
                                          ...prev,
                                          method?.id,
                                        ]);
                                      }
                                    }
                                  }}
                                  disable={
                                    disableField ||
                                    details.data.research_interview_open
                                  }
                                />
                                <Box ml={10}>
                                  <span>Обязательная методика</span>
                                </Box>
                              </Box>
                            </Box>
                            {!details.data.research_interview_open &&
                              !disableField && (
                                <Button
                                  label="Удалить"
                                  display="service"
                                  type={'button'}
                                  color="danger"
                                  size="s"
                                  clicked={() => {
                                    if (
                                      details?.data.research_quiz_has_answers
                                    ) {
                                      onShowToast({
                                        type: 'danger',
                                        name: 'research_quiz_has_answers',
                                        title: 'Ошибка',
                                        description:
                                          'Редактирование опросника возможно только до начала сбора ответов респондентов. В Вашем опросе присутствуют уже собранные ответы, поэтому редактирование вопросов недоступно.',
                                        maxCount: 1,
                                        timer: 9000,
                                      });
                                    } else {
                                      setChosenMethod(
                                        chosenMethod.filter(
                                          (el) => el?.id !== method?.id
                                        )
                                      );
                                      setRequiredMethod(
                                        requiredMethod.filter(
                                          (el) => el !== method?.id
                                        )
                                      );
                                    }
                                  }}
                                />
                              )}
                          </div>
                          {method && (
                            <div className={cx('block__body')}>
                              <Box mb={36} key={`method${method?.id}`}>
                                <QuestionsForMethodsForm
                                  id={method?.id}
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  methodsList={method.methodDescription}
                                  methodInstruction={
                                    Object.values(
                                      method.methodDescription.instruction
                                    )[0].text
                                  }
                                  disabled={true}
                                />
                              </Box>
                            </div>
                          )}
                          <div className="divider" />
                        </Box>

                        {index === chosenMethod.length - 1 &&
                          !details.data.research_interview_open &&
                          !disableField && (
                            <div
                              className={cx('coloredBlock', {
                                coloredBlock_centered: true,
                                coloredBlock_drop: isMethodOver,
                              })}
                            >
                              <span className={styles.coloredBlock_text}>
                                Перетащите выбранную методику в эту зону из меню
                                слева
                              </span>
                            </div>
                          )}
                      </div>
                    </SurveyPageLayout>
                  ))}
              </div>
            </Box>
            <SurveyPageLayout
              pageNumber={2 + questionsSplitByPage.length + chosenMethod.length}
            >
              <div className={styles.block}>
                <div className={styles.block__header}>
                  <Typography color="main" fontWeight={600} size="s" upperCase>
                    Благодарность за участие
                  </Typography>
                  {!details.data.research_interview_open && !disableField && (
                    <Button
                      label="Очистить"
                      display="service"
                      color="danger"
                      type={'button'}
                      size="s"
                      clicked={() => {
                        formik.setFieldValue('research_gratitude_text', '');
                        setTimeout(() =>
                          formik.setFieldTouched(
                            'research_gratitude_text',
                            true
                          )
                        );
                      }}
                    />
                  )}
                </div>
                <Textarea
                  name="research_gratitude_text"
                  placeholder="Введите текст"
                  editable={
                    !details.data.research_interview_open && !disableField
                  }
                />
              </div>
            </SurveyPageLayout>

            <SurveyPageLayout
              pageNumber={3 + questionsSplitByPage.length + chosenMethod.length}
            >
              <Typography color="main" fontWeight={600} size="s" upperCase>
                Обратная связь
              </Typography>
              <Box mt={12} mb={32}>
                <RadioButton
                  name="research_method_feedback"
                  isRow
                  disabled={
                    details.data.research_interview_open ||
                    disableField ||
                    chosenMethod.length === 0
                  }
                  initialValue={formik.values.research_method_feedback}
                  values={[
                    { key: '1', value: true, title: 'Да' },
                    { key: '0', value: false, title: 'Нет' },
                  ]}
                  onChangeSetter={(value) => {
                    formik.setFieldValue(
                      'research_method_feedback',
                      Boolean(value)
                    );
                    if (details?.data.research_quiz_has_answers) {
                      onShowToast({
                        type: 'warning',
                        name: 'research_method_feedback',
                        title: 'Внимание!',
                        description: formik.values.research_method_feedback
                          ? 'У респондентов, которые ранее прошли опросник по исследованию, обратная связь не будет доступна.'
                          : 'Респонденты, которые ранее прошли опрос в рамках исследования, получат обратную связь в разделе “Мои материалы” реестра обратной связи.',
                        autocorrect: true,
                        timer: 3000,
                      });
                    }
                  }}
                />
              </Box>
            </SurveyPageLayout>
          </div>
        </Form>
        <div className="divider"></div>
        <Box flex justifyContent={'space-between'}>
          <Box flex>
            <Button
              label="Отмена"
              withoutBackground
              clicked={() => {
                navigate(`${frontendRoutes.research.details}/${researchId}`);
              }}
            />
            <Box mr={16} />
            <Button
              label="Сохранить"
              gradient={true}
              disabled={
                details.data.research_interview_open ||
                disableField ||
                !formik.isValid ||
                (comparisonInitialValues && comparisonResearchRequirement)
              }
              clicked={submitHandler}
            />
          </Box>
          <Box>
            <Button
              label="Скачать бланк опросника в PDF"
              withoutBackground
              clicked={() => {
                if (researchId) {
                  downloadPDF(
                    `/api/research/${researchId}/quiz/pdf`,
                    'Опросник.pdf'
                  );
                }
              }}
            />
          </Box>
        </Box>
      </FormikContext.Provider>
      <ModalCustomQuestion
        isOpen={customQuestion}
        onClose={() => {
          setCustomQuestion(false);
        }}
        addQuestion={addCustomQuestion}
      />
      <ContentModal
        isOpen={modalDownload || modalSave}
        onClose={() => {
          setModalSave(false);
          setModalDownload(false);
          setSaveTemplate(false);
          setDownloadTemplate(false);
        }}
        titleText={modalSave ? 'Сохранить опросник' : 'Загрузить опросник'}
      >
        <>
          <FormikContext.Provider value={formikSave}>
            <Form>
              {modalSave && (
                <InputText name="quizzes_name" placeholder="Введите название" />
              )}
              {modalDownload && (
                <InputText
                  name="quizzes_search"
                  placeholder="Введите название"
                />
              )}
              <Box mt={20}>
                <div style={{ overflowY: 'scroll', maxHeight: '30vh' }}>
                  {modalSave &&
                    quizzes &&
                    quizzes.data.length > 0 &&
                    quizzes.data.map((quiz: GetQuestionnairesResponse) => {
                      return (
                        <div key={`Card-${quiz?.id}`}>
                          <SurveyCard
                            surveyId={quiz?.id}
                            surveyName={quiz.quizzes_name}
                          />
                        </div>
                      );
                    })}
                  {modalDownload && quizzes && quizzes.data.length > 0 && (
                    <RadioButton
                      name="template_quiz"
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      values={quizzes.data
                        .filter((el) =>
                          el.quizzes_name
                            .toLowerCase()
                            .includes(
                              formikSave.values.quizzes_search.toLowerCase()
                            )
                        )
                        .map((el) => {
                          return {
                            key: `${el.id}`,
                            value: el.id,
                            title: el.quizzes_name,
                          };
                        })}
                      // onChangeSetter={(value) => {
                      //   formik.setFieldValue(
                      //     'research_method_feedback',
                      //     Boolean(value)
                      //   );
                      // }}
                    />
                  )}
                </div>
              </Box>
            </Form>
          </FormikContext.Provider>
          {modalSave && (
            <Box flex mb={32} mt={20}>
              <Button
                label="Сохранить"
                gradient={true}
                disabled={
                  !formikSave.isValid || !formikSave.dirty || !isTheAuthor
                }
                clicked={() => {
                  submitHandlerTemplate();
                  setModalSave(false);
                  setModalDownload(false);
                  setSaveTemplate(false);
                }}
              />
              <Box mr={16} />
              <Button
                label="Отменить"
                withoutBackground
                clicked={() => {
                  setModalSave(false);
                  setModalDownload(false);
                  setSaveTemplate(false);
                }}
              />
            </Box>
          )}
          {modalDownload && (
            <Box flex mb={32} mt={20}>
              <Button
                label="Загрузить"
                gradient={true}
                clicked={() => {
                  setModalSave(false);
                  setModalDownload(false);
                  setDownloadTemplate(false);
                  getTemplate(researchId);
                }}
              />
              <Box mr={16} />
              <Button
                label="Отменить"
                withoutBackground
                clicked={() => {
                  setModalSave(false);
                  setModalDownload(false);
                  setDownloadTemplate(false);
                }}
              />
            </Box>
          )}
        </>
      </ContentModal>
    </>
  );
};
