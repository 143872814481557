import React from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from '../../../components/Layout';
import { SurveyFeedback } from '../../../components/Survey/SurveyFeedback';
import { frontendRoutes } from '../../../utils/router/routes';
import { Breadcrumbs } from '../../../components/ui/Breadcrumbs';
import styles from './FeedbackPage.module.scss';

export const FeedbackPage = (): JSX.Element => {
  const { token } = useParams();

  return (
    <Layout>
      <div className={styles.survey}>
        <div className="local-container">
          <Breadcrumbs
            links={[{ title: 'Прохождение опроса' }]}
            homeLink={frontendRoutes.mainPage}
          />
        </div>
        <div className={styles.survey__layout}>
          <div className={styles.survey__form}>
            <SurveyFeedback answerId={token || ''} />
          </div>
        </div>
      </div>
    </Layout>
  );
};
