export const Methods = {
  name: 'Шкала самоконтроля',
  survey: {
    '3': {
      text: 'Мне удается ставить и достигать долгосрочные цели',
      field: {
        type: 'int',
        min: 1,
        max: 5,
        type_label: 'integer_from_to',
      },
    },
    '4': {
      text: 'Иногда я делаю что-то, хотя и знаю, что этого делать не стоит',
      field: {
        type: 'int',
        min: 1,
        max: 5,
        type_label: 'integer_from_to',
      },
    },
    '5': {
      text: 'Мне нравится делать уроки',
      field: {
        type: 'int',
        min: 1,
        max: 5,
        type_label: 'integer_from_to',
      },
      group: 'А. Я делаю домашние задания, потому что...',
    },
    '6': {
      text: 'Мне стыдно получать плохие отметки',
      field: {
        type: 'int',
        min: 1,
        max: 5,
        type_label: 'integer_from_to',
      },
      group: 'А. Я делаю домашние задания, потому что...',
    },
    '7': {
      text: 'Мне нравится знать и уметь все больше и больше',
      field: {
        type: 'int',
        min: 1,
        max: 4,
        type_label: 'integer_from_to',
      },
      group: 'Я делаю домашние задания, потому что',
    },
    '8': {
      text: 'Мне нравится знать',
      field: {
        type: 'int',
        min: 1,
        max: 4,
        type_label: 'integer_from_to',
      },
      group: 'Я делаю домашние задания, потому что',
    },
    '11': {
      text: 'Ситуация 1. Вы заработали крупную сумму денег. Это произошло потому, что:{textbox}Причина, по которой это случилось:',
      field: {
        type: 'int',
        min: 1,
        max: 6,
        max_label: 'Будет существовать всегда',
        min_label: 'Больше никогда не возникнет',
        type_label: 'labeled_integer_from_to',
      },
      group:
        'Ситуация 1. Вы заработали крупную сумму денег. Это произошло потому, что:{textbox}Причина, по которой это случилось:',
    },
    '12': {
      text: 'Ситуация 2. Вы заработали крупную сумму денег. Это произошло потому, что:{textbox}Причина, по которой это случилось:',
      field: {
        type: 'int',
        min: 1,
        max: 6,
        max_label: 'Будет существовать всегда',
        min_label: 'Больше никогда не возникнет',
        type_label: 'labeled_integer_from_to',
      },
      group:
        'Ситуация 1. Вы заработали крупную сумму денег. Это произошло потому, что:{textbox}Причина, по которой это случилось:',
    },
    '13': {
      text: 'Ты получил(а) "отлично" за контрольную работу.',
      field: {
        type: 'choice',
        choices: {
          1: {
            text: 'А) Я хорошо соображаю.',
            value: 1,
          },
          2: {
            text: 'Б) Я хорошо разбираюьс в вопросе, которому была посвящена контрольная работа.',
            value: 1,
          },
        },
        type_label: 'choice',
      },
    },
  },
  key: {
    '1': {
      type: 'sum',
      args: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
      ],
      return_to: 'Итог',
    },
  },
  interpretation: {
    '1': {
      type: 'interval_bin',
      key_name: 'Итог',
      interval_text_map: {
        '1': {
          interval: [null, 27],
          text: 'Неспособность воздерживаться от импульсивного поведения. Склонность часто совершать поступки под давлением эмоций и ситуативных желаний. Подверженность стрессу и тревоге. Слабые навыки планирования, целеполагания и самоорганизации',
        },
        '2': {
          interval: [28, 43],
          text: 'В большинстве ситуаций способность управлять собой, совладав со своими эмоциями и ситуативными импульсами. Трудные жизненные ситуации провоцируют неконтролируемые всплески эмоций, и способность воздерживаться от импульсивного поведения слабеет',
        },
        '3': {
          interval: [44, null],
          text: 'Способность управлять своим поведением, эмоциями, желаниями и действовать продуманно, воздерживаясь от импульсивного поведения',
        },
      },
    },
  },
};
