import React from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import classNames from 'classnames/bind';

import { defaultFormikOptions } from '../../../../../models/formik';
import {
  materialDocsFiltersInitialValues,
  materialDocsFiltersValidationSchema,
} from '../../../../../models/validations/schemas/validations';

//  ui
import { Filters } from '../../../../ui/Filters';
import { InputText } from '../../../../ui/Form/InputText';
import { Select } from '../../../../ui/Form/Select';
import { Button } from '../../../../ui/Button';
import { InputDate } from '../../../../ui/Form/InputDate';

import styles from './MaterialsDocsFilters.module.scss';
import { toKeyAndValue } from '../../../../../utils/array';
import { SelectSearchRegions } from '../../../../ui/Form/SelectSearchRegions';
import { useGetDocumentTagsQuery } from '../../../../../store/VbudusheeAPI';
import { SelectSearch } from '../../../../ui/Form/SelectSearch';
const cx = classNames.bind(styles);

type MaterialsDocsFiltersTypes = {
  eventsNames: Record<string, string>;
  documentStatus: Record<string, string>;
  documentTypes: Record<string, string>;
  setFilters: React.Dispatch<
    React.SetStateAction<typeof materialDocsFiltersInitialValues>
  >;
  handleResetPage: () => void;
};

export const MaterialsDocsFilters = ({
  setFilters,
  documentStatus,
  eventsNames,
  documentTypes,
  handleResetPage,
}: MaterialsDocsFiltersTypes) => {
  const { data: tagsData } = useGetDocumentTagsQuery({});

  const tagsSelectOptions = tagsData
    ? tagsData.map((tags) => ({
        region_name: tags.text,
        region_number: tags.text,
      }))
    : [];

  const handleFiltersSubmit = (
    values: typeof materialDocsFiltersInitialValues
  ) => {
    setFilters(values);
    handleResetPage();
  };

  const formik = useFormik({
    initialValues: materialDocsFiltersInitialValues,
    validationSchema: materialDocsFiltersValidationSchema,
    onSubmit: handleFiltersSubmit,
    ...defaultFormikOptions,
  });

  const handleFiltersReset = () => {
    setFilters(materialDocsFiltersInitialValues);
    formik.resetForm();
    handleResetPage();
  };

  const eventsNamesOption = Object.entries(eventsNames).map((event) => ({
    value: event[0],
    option: event[1],
  }));

  return (
    <div className={styles.filter}>
      <Filters>
        <FormikContext.Provider value={formik}>
          <Form>
            <div className={styles.form}>
              <div className={cx('form__item', 'form__item_search')}>
                <InputText
                  name="search"
                  placeholder="Поиск по названию"
                  fullWidth={true}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <SelectSearch
                  fullWidth={true}
                  name="event_name"
                  placeholder="Название мероприятия"
                  options={eventsNamesOption}
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <InputDate
                  isRange
                  name="dates"
                  fullWidth={true}
                  placeholder="Сроки загрузки документа"
                />
              </div>
              <div className={cx('form__item', 'form__item_33')}>
                <Select
                  fullWidth={true}
                  name="status"
                  label="Статус публикации"
                  options={toKeyAndValue(documentStatus)}
                />
              </div>
              <div className={cx('form__item', 'form__item_50')}>
                <Select
                  fullWidth={true}
                  name="type"
                  label="Тип документа"
                  options={toKeyAndValue(documentTypes)}
                />
              </div>
              <div className={cx('form__item', 'form__item_50')}>
                <SelectSearchRegions
                  fullWidth
                  name="tags"
                  placeholder="Тематические теги"
                  options={tagsSelectOptions}
                />
              </div>
            </div>
            <div className={styles.buttons}>
              <div onClick={handleFiltersReset}>
                <Button label="Сбросить" withoutBackground={true} />
              </div>
              <div onClick={() => formik.handleSubmit()}>
                <Button label="Применить" gradient={true} />
              </div>
            </div>
          </Form>
        </FormikContext.Provider>
      </Filters>
    </div>
  );
};
