// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResearchEditRespondentsItem_respondent__REeis {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ResearchEditRespondentsItem_respondent-remove__btn__tbwoH {
  font: "SB Sans Display";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #ec1b28;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.ResearchEditRespondentsItem_respondent-remove__btn__tbwoH:hover {
  text-decoration: none;
  color: #ec1b28;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/research/ResearchForm/ResearchEditRespondents/ResearchEditRespondentsItem/ResearchEditRespondentsItem.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AACF;AACE;EACE,uBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;AACJ;AACI;EACE,qBAAA;EACA,cAAA;AACN","sourcesContent":[".respondent {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n\n  &-remove__btn {\n    font: 'SB Sans Display';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 26px;\n    color: #ec1b28;\n    background: none;\n    border: none;\n    outline: none;\n    cursor: pointer;\n\n    &:hover {\n      text-decoration: none;\n      color: #ec1b28;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"respondent": `ResearchEditRespondentsItem_respondent__REeis`,
	"respondent-remove__btn": `ResearchEditRespondentsItem_respondent-remove__btn__tbwoH`
};
export default ___CSS_LOADER_EXPORT___;
