import {
  Survey,
  SurveyQuestionEnum,
  SurveyQuestionType,
} from '../../../models/survey/survey';

//  ui
import { CheckboxButtonType } from './CheckboxButtonType/CheckboxButtonType';
import { RadioButtonType } from './RadioButtonType/RadioButtonType';
import { SelectType } from './SelectType/SelectType';
import { TextareaType } from './TextareaType/TextareaType';
import { RadioButtonGroupType } from './RadioButtonGroupType/RadioButtonGroupType';
import { RadioButtonChoiceType } from './RadioButtonChoiceType/RadioButtonChoiceType';
import { RadioButtonScaleType } from './RadioButtonScaleType/RadioButtonScaleType';

type SurveyQuestionProps = {
  type: SurveyQuestionType;
  questionTitle: string;
  questions: Survey[];
  question?: Survey;
  name: string;
  max?: number;
  min?: number;
  position?: 'vertical' | 'horizontal';
  disabled?: boolean;
};

export const SurveyQuestion = (props: SurveyQuestionProps) => {
  const {
    type,
    questionTitle,
    questions = [],
    question,
    position = 'vertical',
    name,
    max = 0,
    min = 0,
    disabled = false,
  } = props;

  const numberOfResponses = max - min + 1;

  switch (type) {
    case SurveyQuestionEnum.INT_FROM_TO:
      return (
        <RadioButtonType
          question={questionTitle}
          questions={questions}
          position={position}
          name={name}
          numberOfResponses={numberOfResponses}
          disabled={disabled}
        />
      );
    case SurveyQuestionEnum.GROUP:
      return (
        <RadioButtonGroupType
          questions={questions}
          name={name}
          questionTitle={questionTitle}
          disabled={disabled}
        />
      );

    case SurveyQuestionEnum.CHECKBOX:
      return (
        <CheckboxButtonType
          questionTitle={questionTitle}
          questions={questions}
          name={name}
          numberOfResponses={numberOfResponses}
          disabled={disabled}
        />
      );
    case SurveyQuestionEnum.SCALE_GROUP:
      return (
        <RadioButtonScaleType
          questionTitle={questionTitle}
          position={position}
          questions={questions}
          name={name}
          disabled={disabled}
        />
      );
    case SurveyQuestionEnum.CHOICE:
      return (
        <RadioButtonChoiceType
          question={question!}
          name={name}
          questionTitle={questionTitle}
          disabled={disabled}
        />
      );
    case SurveyQuestionEnum.SELECT:
      return (
        <SelectType
          questionTitle={questionTitle}
          options={questions}
          name={name}
          disabled={disabled}
        />
      );
    case SurveyQuestionEnum.INPUT:
      return (
        <TextareaType
          questionTitle={questionTitle}
          name={name}
          disabled={disabled}
        />
      );
    default:
      return <></>;
  }
};
