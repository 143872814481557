import { TaskType } from '../types';

//  ui
import { Card } from '../../../../components/ui/Card';
import { Box } from '../../../../components/ui/Box';
import { Task } from '../Task/Task';

type DeskProps = {
  title: string;
  tasks?: TaskType[];
};

export const Desk = ({ title, tasks = [] }: DeskProps) => {
  return (
    <Card>
      <div
        style={{
          width: '100%',
          minWidth: 300,
          minHeight: 400,
          overflowY: 'auto',
        }}
      >
        <Box mb={20}>
          <h1>{title}</h1>
        </Box>
        {tasks.map((el: TaskType) => (
          <Box key={el.id} mb={10}>
            <Task id={el.id} name={el.name} />
          </Box>
        ))}
      </div>
    </Card>
  );
};
