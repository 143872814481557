import { Radio, Space } from 'antd';
import type { RadioChangeEvent } from 'antd';

import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';

import { Option } from '../../../../models/basic';

//  ui
import 'antd/dist/antd.min.css';
import './index.css';

import styles from '../../SurveyQuestion/RadioButtonType/RadioButtonType.module.scss';
import { useField } from 'formik';
import { Choice } from '../../../../models/survey/survey';

const cx = classNames.bind(styles);

type RadioButtonProps = {
  name?: string;
  initialValue?: string | number | boolean;
  values?: Option[];
  isRow?: boolean;
  withoutVisibleValue?: boolean;
  number?: number;
  onChangeSetter?: (value: boolean) => void;
  disabled?: boolean;
  minValue?: number;
  questions?: { key: string; value: Choice }[];
  hideLabel?: boolean;
  noActive?: boolean;
};

export const RadioButton = (props: RadioButtonProps) => {
  const {
    name,
    initialValue,
    values,
    isRow = false,
    withoutVisibleValue = false,
    number,
    onChangeSetter,
    disabled,
    minValue = 1,
    questions,
    hideLabel,
    noActive,
  } = props;
  const [field, { error, touched }, { setValue }] = useField<
    string | number | boolean
  >(name || '');
  const [valueState, setValueState] = useState(initialValue);

  useEffect(() => {
    if (initialValue) {
      setValueState(initialValue);
    } else {
      setValueState(field.value);
    }
  }, [initialValue]);

  const numberedList = Array(number)
    .fill(0)
    .map((_, i) => {
      return { key: `${i + 1}`, value: `${i + minValue}` };
    });

  const optionsList: Option[] = values ? values : numberedList;

  const onChange = (e: RadioChangeEvent) => {
    const { value } = e.target;

    if (!noActive) {
      setValueState(value);
    }

    const result = Boolean(values?.findIndex((ans) => ans.value !== value));
    if (onChangeSetter) {
      onChangeSetter(result);
    }
  };

  useEffect(() => {
    if (valueState) {
      setValue(valueState);
    }
  }, [valueState]);

  return (
    <Radio.Group
      name={name}
      onChange={onChange}
      value={valueState}
      className={cx('groupRadioButton', {
        groupRadioButtonDisabled: disabled,
      })}
    >
      <Space direction={isRow ? 'horizontal' : 'vertical'}>
        {!questions &&
          optionsList.map((radio: Option, index: number) => {
            return (
              <Radio value={radio.value} key={index} name={name}>
                {withoutVisibleValue
                  ? ''
                  : radio.title
                  ? radio.title
                  : radio.value}
              </Radio>
            );
          })}
        {questions &&
          questions.map((item, index) => {
            return (
              <Radio
                value={index + minValue}
                key={index}
                name={name}
                className={'groupQuestions'}
              >
                <div key={index} className={cx('radioButtonChoice__item')}>
                  <div className={cx('radioButtonChoice__item_text')}>
                    <span>
                      {hideLabel
                        ? item.value.text.slice(0, 2).toUpperCase()
                        : item.key.toUpperCase()}
                    </span>

                    {hideLabel ? item.value.text.slice(2) : item.value.text}
                  </div>
                </div>
              </Radio>
            );
          })}
      </Space>
    </Radio.Group>
  );
};
