import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  EditErrors,
  Participant,
  TeamWithParticipants,
} from '../../../../models/pages/teams';
import { frontendRoutes } from '../../../../utils/router/routes';
import { Box } from '../../../ui/Box';
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { Button } from '../../../ui/Button';
import { Typography } from '../../../ui/Typography';
import { InputTeamInformation } from '../../../ui/Form/InputTeamInformation';
import { ParticipantCard } from '../../../ui/ParticipantCard/ParticipantCard';

import { Form, FormikContext, useFormik } from 'formik';

import styles from './TeamEdit.module.scss';
import defaultTeamLogo from '../../../../assets/images/defaultTeamLogo.svg';

import {
  teamEditInitialValues,
  teamEditSchema,
} from '../../../../models/validations/schemas/validations';

import {
  useAddMemberParticipantMutation,
  useAddModeratorParticipantMutation,
  useDeleteTeamMutation,
  useEditTeamMutation,
  useGetTeamInviteQuery,
} from '../../../../store/VbudusheeAPI';

import { defaultFormikOptions } from '../../../../models/formik';
import { VALIDATION_ERRORS } from '../../../../models/validations/errors/errors';
import { Textarea } from '../../../ui/Form/Textarea/Textarea';
import { CopyLink } from '../../../ui/CopyLink/CopyLink';
import { PopupMessage } from '../../../ui/PopupMessage';

type TeamEditTypes = {
  team: TeamWithParticipants | undefined;
};

export const TeamEdit = ({ team }: TeamEditTypes) => {
  const navigate = useNavigate();
  const { teamId } = useParams();
  const API_URL = process.env.REACT_APP_API_URL?.substring(0, 25);
  const [serverError, setServerError] = useState<string | undefined>();
  const [isCopyLink, setIsCopyLink] = useState(false);

  const { data } = useGetTeamInviteQuery({ team_id: teamId ?? '' });

  const teamInviteHandler = () => {
    if (data) {
      const el = document.createElement('input');
      el.value = `${API_URL}/api/teams/invite/activate?invite=${data?.invite}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      showLinkCopiedTip();
    }
  };

  // const [isDataValid, setDataValid] = useState(false);
  const [participants, setParticipants] = useState(team?.team_members ?? []);
  const [emailMemberParticipant, setEmailMemberParticipant] = useState('');
  const [emailModeratorParticipant, setEmailModeratorParticipant] =
    useState('');
  const [logoForReq, setLogoForReq] = useState<any>(''); // change type
  const [teamLogo, setTeamLogo] = useState<any>( // change type
    team?.team_icon_path !== '' && !(team?.team_icon_path === null)
      ? `${API_URL}${team?.team_icon_path}`
      : defaultTeamLogo
  );

  useEffect(() => {
    setParticipants(team?.team_members ?? []);
  }, [team]);

  const [editTeam, { isLoading, error }] = useEditTeamMutation();
  const [deleteTeam] = useDeleteTeamMutation();
  const [addMemberParticipant, { error: isAddMemberError }] =
    useAddMemberParticipantMutation();
  const [addModeratorParticipant, { error: isAddModeratorError }] =
    useAddModeratorParticipantMutation();

  const onSelectImageHandler = (element: any) => {
    const file = element[0];
    if (file) {
      setLogoForReq(file);
      const reader = new FileReader();
      reader.onloadend = function () {
        setTeamLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const navigateToTeamListHandler = () => {
    navigate(frontendRoutes.teams.teams);
  };

  const formatingDate = (rawDate: string): string => {
    const formatedDate = new Date(rawDate);
    let month = '' + (formatedDate.getMonth() + 1);
    let day = '' + formatedDate.getDate();
    const year = formatedDate.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('.');
  };

  const getLocalRoleName = (role: string) => {
    switch (role) {
      case 'owner':
        return 'Создатель';
      case 'member':
        return 'Участник';
      case 'moderator':
        return 'Модератор';
      default:
        return role;
    }
  };

  const formData = new FormData();

  const handleSubmit = async (values: typeof teamEditInitialValues) => {
    if (isLoading) {
      return;
    }

    if (team) {
      formData.append('team_name', values.team_name);
      formData.append('team_description', values.team_description);

      logoForReq !== ''
        ? formData.append('team_icon_image', logoForReq)
        : team.team_icon_path && teamLogo !== defaultTeamLogo
        ? formData.append('team_icon_path', team.team_icon_path)
        : formData.append('team_icon_path', '');

      const editedTeam = await editTeam({
        id: teamId ?? '',
        data: formData,
      });

      if ('data' in editedTeam) {
        navigate(frontendRoutes.teams.teams);
      }
    }
  };

  useEffect(() => {
    if (error && 'data' in error) {
      const serverErrors = error.data as {
        errors: EditErrors;
        message: string;
      };
      if (
        serverErrors.message.search(/The team name field is required/i) !== -1
      ) {
        setServerError(VALIDATION_ERRORS.teams.requiredTeamName);
      } else if (
        serverErrors.message.search(
          /The team description field is required/i
        ) !== -1
      ) {
        setServerError(VALIDATION_ERRORS.teams.requiredTeamDescription);
      } else if (serverErrors.message.search(/Not permitted/i) !== -1) {
        setServerError(VALIDATION_ERRORS.teams.permitted);
      } else if (
        serverErrors.message.search(
          /Attempt to read property "role" on null/i
        ) !== -1
      ) {
        setServerError(VALIDATION_ERRORS.teams.invalidUserLogin);
      } else {
        setServerError(VALIDATION_ERRORS.server.error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (isAddMemberError && 'data' in isAddMemberError) {
      const serverErrors = isAddMemberError.data as {
        errors: EditErrors;
        message: string;
      };
      if (
        serverErrors.message.search(
          /Attempt to read property "role" on null/i
        ) !== -1
      ) {
        setServerError(VALIDATION_ERRORS.teams.invalidUserLogin);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddMemberError]);

  useEffect(() => {
    if (isAddModeratorError && 'data' in isAddModeratorError) {
      const serverErrors = isAddModeratorError.data as {
        errors: EditErrors;
        message: string;
      };
      if (
        serverErrors.message.search(
          /Attempt to read property "role" on null/i
        ) !== -1
      ) {
        setServerError(VALIDATION_ERRORS.teams.invalidUserLogin);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddModeratorError]);

  const formik = useFormik({
    initialValues: teamEditInitialValues,
    validationSchema: teamEditSchema,
    onSubmit: handleSubmit,
    ...defaultFormikOptions,
  });

  useEffect(() => {
    formik.setFieldValue('team_name', team?.team_name);
    formik.setFieldValue('team_description', team?.team_description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formik.setFieldValue('member', emailMemberParticipant);
    formik.setFieldValue('moderator', emailModeratorParticipant);
  }, [emailMemberParticipant, emailModeratorParticipant]);

  const deleteTeamHandler = async () => {
    const result = await deleteTeam({ id: teamId });

    if ('data' in result) {
      navigate(frontendRoutes.teams.teams);
    }
  };

  const addMemberParticipantHandler = async () => {
    const result = await addMemberParticipant({
      team_id: teamId ?? '',
      body: {
        email: formik.values.member,
        role: 'member',
      },
    });

    if ('data' in result) {
      setEmailMemberParticipant('');
    }
  };

  const addModeratorParticipantHandler = async () => {
    const result = await addModeratorParticipant({
      team_id: teamId ?? '',
      body: {
        email: emailModeratorParticipant,
        role: 'moderator',
      },
    });

    if ('data' in result) {
      setEmailModeratorParticipant('');
    }
  };

  const showLinkCopiedTip = () => {
    setIsCopyLink(true);
    setTimeout(setIsCopyLink, 3000, false);
  };

  return (
    <>
      <FormikContext.Provider value={formik}>
        <Form
          autoComplete="off"
          onKeyPress={(e) => {
            e.which === 13 && e.preventDefault();
          }}
        >
          <PopupMessage isOpen={isCopyLink}>
            <CopyLink />
          </PopupMessage>
          <div>
            <div className="local-container">
              <Breadcrumbs
                links={[
                  {
                    title: 'Редактирование команды',
                    link: frontendRoutes.teams.teams,
                  },
                ]}
                homeLink="/"
              />
            </div>

            <div className={styles.headblock}>
              <div>
                <Typography
                  color="main"
                  size="xxxl"
                  font="SFPRoDisplay"
                  fontWeight={600}
                >
                  {team?.team_name}
                </Typography>
                {serverError && (
                  <div className={styles.error}>{serverError}</div>
                )}
              </div>
              <div className={styles.buttons}>
                <div className={styles.buttons__elements}>
                  <Button
                    label="Каталог команд"
                    withoutBackground={true}
                    clicked={navigateToTeamListHandler}
                  />
                </div>
                <div className={styles.buttons__elements}>
                  <Button type="submit" label="Сохранить" gradient={true} />
                </div>
              </div>
            </div>

            <div className="details__body">
              <Typography color="main" fontWeight={600} size="s" upperCase>
                Основная информация о команде
              </Typography>
              <Box mb={33} />

              <div className="row">
                <div className="detail">
                  <div className="detail__content">
                    <div className="detail__content-title">
                      <Typography color="gray" size="s">
                        Фотография
                      </Typography>
                    </div>
                    <div className={styles.logo_wraper}>
                      <img
                        src={teamLogo}
                        className={styles.logo_wraper__logo}
                        alt="Team logo"
                      />
                      <div className={styles.logo_wraper__links}>
                        <label className="input-file">
                          <input
                            onChange={(e) => {
                              onSelectImageHandler(e.target.files);
                            }}
                            name="team_icon_image"
                            type="file"
                            accept="image/*"
                            className={styles.logo_wraper__hider_input}
                          />
                          <span className={styles.logo_wraper__edit}>
                            Изменить
                          </span>
                        </label>
                        <span
                          className={styles.logo_wraper__delete}
                          onClick={() => {
                            setTeamLogo(defaultTeamLogo);
                          }}
                        >
                          Удалить
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detail">
                  <div className="detail__content">
                    <div className="detail__content-title">
                      <Typography color="gray" size="s">
                        Роль пользователя в команде
                      </Typography>
                    </div>
                    <Typography color="main" size="sm">
                      {getLocalRoleName(team?.team_user_role ?? '')}
                    </Typography>
                  </div>
                </div>
                <div className="detail">
                  <div className="detail__content">
                    <div className="detail__content-title">
                      <Typography color="gray" size="s">
                        Количество участников
                      </Typography>
                    </div>
                    <Typography color="main" size="sm">
                      {team?.team_members_count ?? 0}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className={styles.input_wraper__name}>
                <Textarea
                  name="team_name"
                  placeholder="Название команды"
                  height={62}
                />
              </div>
              <div className="row">
                <div className="detail">
                  <div className="detail__content">
                    <div className="detail__content-title">
                      <Typography color="gray" size="s">
                        Создатель команды
                      </Typography>
                    </div>
                    <Typography color="main" size="sm">
                      {`${team?.team_creator.last_name} ${team?.team_creator.first_name} ${team?.team_creator.middle_name}`}
                    </Typography>
                  </div>
                </div>
                <div className="detail">
                  <div className="detail__content">
                    <div className="detail__content-title">
                      <Typography color="gray" size="s">
                        Организация создателя
                      </Typography>
                    </div>
                    <Typography color="main" size="sm">
                      {team?.team_creator.organization_name}
                    </Typography>
                  </div>
                </div>
                <div className="detail">
                  <div className="detail__content">
                    <div className="detail__content-title">
                      <Typography color="gray" size="s">
                        Дата создания
                      </Typography>
                    </div>
                    <Typography color="main" size="sm">
                      {formatingDate(team?.team_creation_date ?? '')}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={styles.input_wraper__description}>
                <Textarea
                  name="team_description"
                  placeholder="Описание команды"
                />
              </div>
              <Box mb={24} />
              <div>
                <Button
                  clicked={deleteTeamHandler}
                  label="Удалить команду"
                  withoutBackground={true}
                />
              </div>
              <Box mb={20} />
              <div className={styles.divider} />
              <div>
                <Button
                  label="Копировать ссылку-приглашение"
                  withoutBackground={true}
                  clicked={teamInviteHandler}
                />
              </div>
              <Box mb={20} />
              {/*{isCopyLink && (*/}
              {/*  <>*/}
              {/*    <CopyLink />*/}
              {/*    <Box mb={20} />*/}
              {/*  </>*/}
              {/*)}*/}
              <div className={styles.participants_block}>
                <div>
                  <div className={styles.input_wraper}>
                    <InputTeamInformation
                      name="member"
                      type="email"
                      value={emailMemberParticipant}
                      placeholder="Пригласить участника по логину"
                      fullWidth={true}
                      modifiedClassName
                      onChange={(e) => {
                        setEmailMemberParticipant(e.target.value);
                      }}
                    />
                  </div>
                  <Button
                    label="Открыть доступ"
                    gradient={true}
                    clicked={addMemberParticipantHandler}
                    disabled={formik.values.member === '' ? true : false}
                  />
                  <div className={styles.team_list_wrapper}>
                    {participants.length > 0 &&
                      participants
                        .filter(
                          (participant) => participant.pivot?.role === 'member'
                        )
                        .map((el: Participant) => {
                          return (
                            <ParticipantCard
                              team_id={teamId}
                              key={el.email}
                              email={el.email}
                              last_name={el.last_name}
                              first_name={el.first_name}
                              middle_name={el.middle_name}
                              pivot={el.pivot}
                            />
                          );
                        })}
                  </div>
                </div>
                <div>
                  <div className={styles.input_wraper}>
                    <InputTeamInformation
                      name="moderator"
                      type="email"
                      value={emailModeratorParticipant}
                      placeholder="Передать права администратора"
                      fullWidth={true}
                      modifiedClassName
                      onChange={(e) => {
                        setEmailModeratorParticipant(e.target.value);
                      }}
                    />
                  </div>
                  <Button
                    label="Открыть доступ"
                    gradient={true}
                    clicked={addModeratorParticipantHandler}
                    disabled={formik.values.moderator === '' ? true : false}
                  />
                  <div className={styles.team_list_wrapper}>
                    {participants.length > 0 &&
                      participants
                        .filter(
                          (participant) =>
                            participant.pivot?.role === 'moderator'
                        )
                        .map((el: Participant) => {
                          return (
                            <ParticipantCard
                              team_id={teamId}
                              key={el.email}
                              email={el.email}
                              last_name={el.last_name}
                              first_name={el.first_name}
                              middle_name={el.middle_name}
                              pivot={el.pivot}
                            />
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </FormikContext.Provider>
    </>
  );
};
