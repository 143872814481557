// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ComparisonDownloadButton_contentTopButton__fHIGl {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ComparisonDownloadButton_contentTopButtonText__EBbfM {
  font-size: 14px;
  font-weight: 500;
  margin-right: 8px;
}
.ComparisonDownloadButton_contentTopButtonWrapper__icPx- {
  position: relative;
}
.ComparisonDownloadButton_contentTopButtonIcon__PCtAi {
  transition: 0.2s;
}
.ComparisonDownloadButton_contentTopButtonIconActive__IfT-o {
  transform: rotate(-180deg) translateY(-3px);
}`, "",{"version":3,"sources":["webpack://./src/components/pages/researchComparison/ComparisonContent/ComparisonDownloadButton/ComparisonDownloadButton.module.scss"],"names":[],"mappings":"AAEQ;EACI,aAAA;EACA,mBAAA;EAEA,eAAA;AAFZ;AAIY;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;AAFhB;AAKY;EACI,kBAAA;AAHhB;AAMY;EACI,gBAAA;AAJhB;AAMgB;EACI,2CAAA;AAJpB","sourcesContent":[".content {\n    &Top {\n        &Button {\n            display: flex;\n            align-items: center;\n\n            cursor: pointer;\n\n            &Text {\n                font-size: 14px;\n                font-weight: 500;\n                margin-right: 8px;\n            }\n\n            &Wrapper {\n                position: relative;\n            }\n\n            &Icon {\n                transition: 0.2s;\n\n                &Active {\n                    transform: rotate(-180deg) translateY(-3px);\n\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentTopButton": `ComparisonDownloadButton_contentTopButton__fHIGl`,
	"contentTopButtonText": `ComparisonDownloadButton_contentTopButtonText__EBbfM`,
	"contentTopButtonWrapper": `ComparisonDownloadButton_contentTopButtonWrapper__icPx-`,
	"contentTopButtonIcon": `ComparisonDownloadButton_contentTopButtonIcon__PCtAi`,
	"contentTopButtonIconActive": `ComparisonDownloadButton_contentTopButtonIconActive__IfT-o`
};
export default ___CSS_LOADER_EXPORT___;
