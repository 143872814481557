// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocumentPage_survey__OGEer {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "SFProDisplay", sans-serif;
}
.DocumentPage_survey__layout__Tc-7A {
  width: 100%;
}
.DocumentPage_survey__form__wLzQ0 {
  margin-top: 32px;
  padding: 0 204px;
}`, "",{"version":3,"sources":["webpack://./src/pages/document/DocumentPage.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uCAAA;AACJ;AACI;EACI,WAAA;AACR;AAGI;EACI,gBAAA;EACA,gBAAA;AADR","sourcesContent":[".survey {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    font-family: 'SFProDisplay', sans-serif;\n\n    &__layout {\n        width: 100%;\n    }\n\n\n    &__form {\n        margin-top: 32px;\n        padding: 0 204px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"survey": `DocumentPage_survey__OGEer`,
	"survey__layout": `DocumentPage_survey__layout__Tc-7A`,
	"survey__form": `DocumentPage_survey__form__wLzQ0`
};
export default ___CSS_LOADER_EXPORT___;
