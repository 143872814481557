import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';

import { SelectOption } from '../../../../models/basic';

//  ui
import { IconArrow } from './IconArrow';
import { IconCross } from '../../Icons/IconCross';

import styles from './DropdownFilter.module.scss';
const cx = classNames.bind(styles);

type DropdownFilterProps = {
  label?: string;
  options: SelectOption<number | string>[];
  option?: SelectOption<number | string> | undefined;
  setValue: (val: number | string) => void;
  cb?: () => void;
  isSearchable?: boolean;
};

export const DropdownFilter = ({
  label,
  options,
  option,
  setValue,
  cb,
  isSearchable = false,
}: DropdownFilterProps): JSX.Element => {
  const selectRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [actualOptions, setActualOptions] = useState(options);
  const [expanded, setExpanded] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleSelectClick = (): void => {
    setExpanded(!expanded);
  };

  const handleOptionClick = (value: SelectOption<number | string>): void => {
    if (value && value.key !== undefined && value.value !== undefined) {
      setValue(value?.key);
    }
    if (cb) cb();
  };

  const handleSelectSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setInputValue(value);
  };

  const handleClear = (): void => {
    setInputValue('');
    setActualOptions(options);
    setValue(0);
  };

  useEffect(() => {
    if (isSearchable) {
      if (option?.value) {
        setInputValue(option?.value);
      }
    }
  }, [isSearchable, option]);

  useEffect(() => {
    const onClick = (e: MouseEvent) =>
      selectRef?.current?.contains(e.target as Node) || setExpanded(false);
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  useEffect(() => {
    if (isSearchable) {
      if (inputValue) {
        const suitableOptions = options.filter((opt) => {
          const searchExp = new RegExp(inputValue, 'gmi');
          return opt?.value.search(searchExp) !== -1;
        });
        setActualOptions(suitableOptions);
      }
    }
  }, [inputValue, isSearchable, options]);

  useEffect(() => {
    setActualOptions(options);
  }, [options]);

  return (
    <div className={cx('wrapper')}>
      <div className={cx('select')} onClick={handleSelectClick} ref={selectRef}>
        <div
          className={cx('select__value', {
            select__value_active: option?.value,
          })}
        >
          {isSearchable ? (
            <input
              type="text"
              className={styles.searchSelect}
              placeholder={label ? label : ''}
              value={inputValue}
              onChange={(e) => handleSelectSearch(e)}
              ref={inputRef}
            />
          ) : (
            <>{option?.value ? option?.value : label}</>
          )}
        </div>
        <div
          className={cx('select__arrow', {
            select__arrow_expanded: expanded,
            select__arrow_hidden: !!inputValue,
          })}
        >
          <IconArrow />
        </div>
        {isSearchable && (
          <div
            className={cx('select__clear', {
              select__clear_active: !!inputValue,
            })}
            onClick={handleClear}
          >
            <IconCross />
          </div>
        )}

        <div
          className={cx('select__options', {
            select__options_expanded: expanded,
          })}
        >
          {actualOptions
            .filter(
              (option) => Number(option?.key) > 66 || Number(option?.key) < 64
            )
            .map((option) => (
              <div
                className={cx('select__option', {
                  select__option_expanded: expanded,
                })}
                key={option?.key}
                onClick={() => handleOptionClick(option)}
                ref={option?.key === 11 ? scrollRef : null}
              >
                {option?.value}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
