import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { AuthorizationResponse } from '../../models/user/auth';
import { VbudusheeAPI } from '../VbudusheeAPI';

export type InitialState = {
  authorization?: {
    access_token?: string;
    refresh_token?: string;
  };
};

const initialState: InitialState = {};

const authSlice = createSlice({
  name: 'authorization',
  initialState: initialState,
  reducers: {
    updateAuth: (state, { payload }: PayloadAction<AuthorizationResponse>) => {
      state.authorization = payload;
    },
    logOut: (state) => {
      state.authorization = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      VbudusheeAPI.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.authorization = payload;
      }
    );
    builder.addMatcher(
      VbudusheeAPI.endpoints.registration.matchFulfilled,
      (state, { payload }) => {
        state.authorization = payload;
      }
    );
    builder.addMatcher(
      VbudusheeAPI.endpoints.logOut.matchFulfilled,
      (state) => {
        state.authorization = undefined;
      }
    );
  },
});

export const { reducer: authReducer } = authSlice;
export const { updateAuth, logOut } = authSlice.actions;
export * from './selectors';
