import React from 'react';
import classNames from 'classnames/bind';

//ui
import { RadioButton } from '../../Form/RadioButton/RadioButton';
import styles from './RadioButtonType.module.scss';
import { Survey } from '../../../../models/survey/survey';

const cx = classNames.bind(styles);

type RadioButtonProps = {
  question: string;
  questions: Survey[];
  position: 'vertical' | 'horizontal';
  name: string;
  numberOfResponses: number;
  disabled: boolean;
};

export const RadioButtonType = (props: RadioButtonProps) => {
  const { question, questions, position, name, numberOfResponses, disabled } =
    props;

  const listQuestion = Array(numberOfResponses)
    .fill(0)
    .map((e, i) => {
      return { key: i + 1, value: i + 1 };
    });

  const values = listQuestion.map((option, index) => {
    return option.value;
  });

  return (
    <div className={cx('radiobuttonType')}>
      <div className={cx('radiobuttonType__title')}>{question}</div>
      <div className={cx('radiobuttonType__item')}>
        <RadioButton
          name={name}
          number={numberOfResponses}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
