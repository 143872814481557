// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ComparisonSidePanelFilter_form__Xk3Uy {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.ComparisonSidePanelFilter_form__item__xrro\\+ {
  width: calc(50% - 8px);
}
.ComparisonSidePanelFilter_form__item_search__irkEz {
  width: 100%;
}
.ComparisonSidePanelFilter_form__item_33__DpXN- {
  width: calc(33% - 8px);
}

.ComparisonSidePanelFilter_input__N0tZY {
  margin-bottom: 16px;
}

.ComparisonSidePanelFilter_filterContainer__biXZf {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.ComparisonSidePanelFilter_filterContainer__biXZf > div {
  flex: 1 1;
  min-width: 323px;
  max-width: 364px;
}

.ComparisonSidePanelFilter_buttons__RR\\+lL {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 20px;
}
.ComparisonSidePanelFilter_buttons__RR\\+lL > div {
  flex: 1 1;
  min-width: 323px;
  max-width: 364px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/researchComparison/ComparisonSidePanel/ComparisonSidePanelFilter/ComparisonSidePanelFilter.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,SAAA;AACJ;AACI;EACI,sBAAA;AACR;AACQ;EACI,WAAA;AACZ;AAEQ;EACI,sBAAA;AAAZ;;AAKA;EACI,mBAAA;AAFJ;;AAKA;EACI,aAAA;EACA,eAAA;EACA,SAAA;AAFJ;AAII;EACI,SAAA;EACA,gBAAA;EACA,gBAAA;AAFR;;AAMA;EACI,aAAA;EACA,8BAAA;EACA,SAAA;EACA,eAAA;EACA,gBAAA;AAHJ;AAKI;EACI,SAAA;EACA,gBAAA;EACA,gBAAA;AAHR","sourcesContent":[".form {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 16px;\n\n    &__item {\n        width: calc(50% - 8px);\n\n        &_search {\n            width: 100%;\n        }\n\n        &_33 {\n            width: calc(33% - 8px);\n        }\n    }\n}\n\n.input {\n    margin-bottom: 16px;\n}\n\n.filterContainer {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 16px;\n\n    & > div {\n        flex: 1 1;\n        min-width: 323px;\n        max-width: 364px;\n    }\n}\n\n.buttons {\n    display: flex;\n    justify-content: space-between;\n    gap: 16px;\n    flex-wrap: wrap;\n    margin-top: 20px;\n\n    & > div {\n        flex: 1 1;\n        min-width: 323px;\n        max-width: 364px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `ComparisonSidePanelFilter_form__Xk3Uy`,
	"form__item": `ComparisonSidePanelFilter_form__item__xrro+`,
	"form__item_search": `ComparisonSidePanelFilter_form__item_search__irkEz`,
	"form__item_33": `ComparisonSidePanelFilter_form__item_33__DpXN-`,
	"input": `ComparisonSidePanelFilter_input__N0tZY`,
	"filterContainer": `ComparisonSidePanelFilter_filterContainer__biXZf`,
	"buttons": `ComparisonSidePanelFilter_buttons__RR+lL`
};
export default ___CSS_LOADER_EXPORT___;
