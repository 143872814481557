import React from 'react';
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { RestoreForm } from './RestoreForm';
import styles from './RestorePass.module.scss';
import { LinkTo } from '../../../ui/LinkTo';
import { frontendRoutes } from '../../../../utils/router/routes';

export const RestorePass = () => {
  return (
    <div className={styles.restore}>
      <div className="outer-container">
        <Breadcrumbs
          links={[{ title: 'Восстановление пароля' }]}
          homeLink={frontendRoutes.mainPage}
        />
      </div>
      <span className={styles.restore__header}>Восстановление пароля</span>
      <span className={styles.restore__description}>
        Укажите электронный адрес вашего аккаунта, и мы отправим вам инструкцию
        по&nbsp;изменению&nbsp;пароля
      </span>
      <div className={styles.restore__form}>
        <RestoreForm />
        <LinkTo label="Назад" link={frontendRoutes.user.login} />
      </div>
    </div>
  );
};
