import { useEffect, useMemo, useState } from 'react';
import { useFormik, FormikContext } from 'formik';
import styles from './DocumentEditor.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetDocumentQuery,
  useGetEventQuery,
  useGetSemanticStructQuery,
  useUploadMarkedUpDocumentMutation,
} from '../../../../store/VbudusheeAPI';
import { Docs } from '../../../../models/document';
import { object } from 'yup';
import { useTypedSelector } from '../../../../store';
import { uploadDocument } from '../../../../store/documentSlice/selectors';

//  ui
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { Button } from '../../../ui/Button';
import { Typography } from '../../../ui/Typography';
import { Box } from '../../../ui/Box';
import { Tooltip } from '../../../ui/Tooltip';
import { DocumentSettingsBlock } from '../DocumentSettingsBlock';
import { StructureMenuItem } from '../StructureMenuItem';
import { DocumentRating } from '../DocumentRating';
import { ContentModal } from '../../../ui/ContentModal';
import { DocumentPopupCancel } from './DocumentPopupCancel';
import { DocumentPopupAccess } from './DocumentPopupAccess';
import { ModalDocumentEdit } from '../../../ui/ModalDocumentEdit/ModalDocumentEdit';
import { IconLoader } from '../../../ui/Icons/IconLoader';
import { frontendRoutes } from '../../../../utils/router/routes';
import { useDispatch } from 'react-redux';
import { resetMarkup } from '../../../../store/documentSlice';
import { DocumentPreview } from './DocumentPreview';
import { DocumentSemanticsPreview } from './DocomentSemanticsPreview';

export const DocumentEditor = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const documentId = useParams();

  const [uploadMarkedDocument, { isLoading: isUploadMarkedDocumentLoading }] =
    useUploadMarkedUpDocumentMutation();

  const markedUpDocument = useTypedSelector(uploadDocument);

  const { data: documentData } = useGetDocumentQuery({
    id: documentId.id ?? '',
  });

  const { data: event } = useGetEventQuery(
    {
      id: String(documentData?.data.event_id) ?? '',
    },
    { skip: !documentData?.data.event_id }
  );

  const { data: semanticStructure } = useGetSemanticStructQuery(
    { id: String(documentData?.data.event_id) },
    { skip: !documentData?.data.event_id }
  );
  const [isOpenModalCancel, setIsOpenModalCancel] = useState<boolean>(false);
  const [_, setIsDocumentCancel] = useState<boolean>(false);
  const [isOpenModalAccess, setIsOpenModalAccess] = useState<boolean>(false);

  const documentStructures = useMemo(() => {
    if (!semanticStructure) return [];
    return Object.entries(semanticStructure);
  }, [semanticStructure]);

  const API_URL = process.env.REACT_APP_API_URL?.replace('/api', '/');
  const docs: Docs = {
    file_url: `${API_URL}${documentData?.data.document_path}`,
    fileType: 'docx',
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<null | {
    title: string;
    index: number;
  }>(null);

  const handleSubmit = async () => {
    if (!documentId.id || isUploadMarkedDocumentLoading) return;
    const data = markedUpDocument.filter((el) => el !== null);

    await uploadMarkedDocument({
      id: documentId.id,
      body: { parts: data, completed: true },
    })
      .unwrap()
      .then(() => {
        dispatch(resetMarkup());
        setIsOpenModalAccess(false);
        if (documentData?.data.event_id) {
          navigate(
            `${frontendRoutes.publicEventsCatalog.events}/${documentData?.data.event_id}`
          );
        }
      });
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema: object({}),
    onSubmit: handleSubmit,
  });

  const isSubmitDisabled = () => {
    const requiredStructures = documentStructures.filter(
      (el) => el[1].required === true
    );

    const markedUpDocumentTitles = markedUpDocument.map((el) => el.title);

    const isCompleted = requiredStructures.every((el) =>
      markedUpDocumentTitles.includes(el[0])
    );

    return !isCompleted || isUploadMarkedDocumentLoading;
  };

  const openModal = (title: string, index: number) => {
    setModalData({ title, index });
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [markedUpDocument]);

  return (
    <FormikContext.Provider value={formik}>
      <div className="details">
        <div className="local-container">
          <Breadcrumbs
            links={[
              {
                title: 'Мероприятие',
                link: `/events`,
              },
              { title: 'Разметка документа' },
            ]}
            homeLink="/"
          />
        </div>
        <div className="details__header">
          <Box flex>
            <Typography
              color="main"
              size="xxxl"
              font="SFPRoDisplay"
              fontWeight={600}
            >
              Разметка документа
            </Typography>
            <Box mt={8} ml={12}>
              <Tooltip title="Как разметить документ?">
                {event?.data.event_document_requirements
                  ? event.data.event_document_requirements
                  : 'Требования не установлены'}
              </Tooltip>
            </Box>
          </Box>
          <Box flex>
            <Box mr={16}>
              <div onClick={() => setIsOpenModalCancel(true)}>
                <Button label="Сбросить разметку" withoutBackground />
              </div>
            </Box>
            <div onClick={() => setIsOpenModalAccess(true)}>
              <Button
                disabled={isSubmitDisabled()}
                type="submit"
                label="Подтвердить разметку"
                gradient={true}
                icon={
                  isUploadMarkedDocumentLoading ? <IconLoader /> : undefined
                }
              />
            </div>
          </Box>
        </div>
        <div className="details__body">
          <Box mb={18}>
            <Typography color="main" fontWeight={700} size="sm">
              Требования к загрузке документа
            </Typography>
          </Box>
          <div className="row">
            <div className="full-width">
              <div className="detail__content">
                <Typography color="main" size="sm">
                  {event?.data.event_document_requirements
                    ? event.data.event_document_requirements
                    : 'Требования не установлены'}
                </Typography>
              </div>
            </div>
          </div>
          <Box mb={26} />
          <div className="details__divider" />
          <div className={styles.editor}>
            <div className={styles.panelWrapper}>
              <>
                <DocumentSettingsBlock
                  title="Отметьте структурные элементы"
                  height={200}
                  stepNumber={1}
                >
                  <>
                    {documentStructures.map(([label, { required }], idx) => {
                      return (
                        <StructureMenuItem
                          key={idx}
                          label={label}
                          required={required}
                          onClick={() => openModal(label, idx)}
                          tags={
                            markedUpDocument[idx]
                              ? markedUpDocument[idx].tags
                              : undefined
                          }
                          isActive={
                            markedUpDocument[idx] &&
                            (markedUpDocument[idx]?.text !== '' ||
                              markedUpDocument[idx]?.tags?.length > 0)
                          }
                        />
                      );
                    })}
                  </>
                </DocumentSettingsBlock>
                <DocumentSettingsBlock
                  title="Ознакомьтесь с критериями оценивания"
                  stepNumber={2}
                >
                  <>
                    {event?.data.event_estimate_on &&
                      documentData?.event_estimate.map((el, i) => (
                        <DocumentRating
                          name={'rating'}
                          key={el.id}
                          id={el.id}
                          number={i + 1}
                          description={el.category_name}
                          // withRating
                          onChange={() => {}}
                        />
                      ))}
                  </>
                </DocumentSettingsBlock>
              </>
            </div>
            {/* Word Documents view part */}
            <div>
              {documentData?.data.document_path && (
                <DocumentPreview
                  width="100%"
                  height="250"
                  id={documentData?.data.document_id}
                />
              )}
              <DocumentSemanticsPreview />
            </div>

            {isModalOpen && modalData && (
              <ModalDocumentEdit
                data={documentData}
                docs={docs}
                index={modalData.index}
                title={modalData.title}
                onClose={onModalClose}
              />
            )}
          </div>
        </div>
      </div>
      <ContentModal
        isOpen={isOpenModalCancel}
        titleText="Подтверждение"
        onClose={() => setIsOpenModalCancel(false)}
      >
        <DocumentPopupCancel
          openEdit={setIsDocumentCancel}
          setModalOpen={setIsOpenModalCancel}
        />
      </ContentModal>

      <ContentModal
        isOpen={isOpenModalAccess}
        titleText="Подтверждение"
        onClose={() => setIsOpenModalAccess(false)}
      >
        <DocumentPopupAccess handleAccess={handleSubmit} />
      </ContentModal>
    </FormikContext.Provider>
  );
};
