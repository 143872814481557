import { useMemo, useState } from 'react';

import { PublicEventDetails } from '../../../../models/pages/events';

//  ui
import { PublicEventParticipate } from '../PublicEventParticipate';
import { PublicEventComponent } from '../PublicEventComponent';
import { useGetDictionaryQuery } from '../../../../store/VbudusheeAPI';

type PublicEventContainerProps = {
  event?: PublicEventDetails;
  isAuthorized?: boolean;
  eventId?: string;
};

export const PublicEventContainer = ({
  event,
  isAuthorized = false,
  eventId,
}: PublicEventContainerProps): JSX.Element => {
  const [state, setState] = useState<'info' | 'upload'>('info');
  //TODO fixed types for event
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { data: dictionary } = useGetDictionaryQuery({});

  const ownerEvent = useMemo(() => {
    return !!event?.event_user_role?.find((i) => i === 'owner');
  }, [event]);

  const documentType = useMemo(() => {
    return (
      dictionary?.events.event_document_type[
        event?.event_document_type || ''
      ] || 'Нет данных'
    );
  }, [dictionary, event]);

  return (
    <>
      {ownerEvent ? (
        <PublicEventComponent
          event={event}
          isAuthorized={isAuthorized}
          eventId={eventId}
          setState={setState}
          owner={ownerEvent}
          dictionary={dictionary}
        />
      ) : state === 'info' ? (
        <PublicEventComponent
          event={event}
          isAuthorized={isAuthorized}
          eventId={eventId}
          setState={setState}
          dictionary={dictionary}
        />
      ) : (
        <PublicEventParticipate
          eventId={eventId}
          eventEstimateOn={event?.event_estimate_on}
          setState={setState}
          dictionary={dictionary}
          documentRequirements={event?.event_document_requirements}
          documentType={documentType}
          eventCriterias={event?.event_estimate_categories}
        />
      )}
    </>
  );
};
