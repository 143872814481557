import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';

import { frontendRoutes } from '../../../utils/router/routes';
// import { logOut } from '../../../store/authSlice';
// import { removeCurrentUser } from '../../../store/userSlice';

//  ui
import { IconArrow } from '../../ui/Icons/IconArrow';

import styles from './UserBar.module.scss';
import {
  useGetCurrentUserQuery,
  useLogOutMutation,
  VbudusheeAPI,
} from '../../../store/VbudusheeAPI';
import { removeCurrentUser } from '../../../store/userSlice';
import { logOut } from '../../../store/authSlice';
const cx = classNames.bind(styles);

type UserBarProps = {
  withPanel: boolean;
};

export const UserBar = ({ withPanel }: UserBarProps) => {
  const [isDroplist, setDroplist] = useState(false);
  const [fullName, setFullName] = useState<string>('Нет данных');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [trigerLogOut] = useLogOutMutation();
  const { data, isLoading, isError } = useGetCurrentUserQuery({});

  const userbarRef = useRef(document.createElement('div'));
  useEffect(() => {
    //Обрабатываем клик вне компонента
    const onClick = (e: MouseEvent) =>
      userbarRef?.current?.contains(e.target as Node) || setDroplist(false);
    document.addEventListener('click', onClick);
    return () => document.removeEventListener('click', onClick);
  }, []);

  const handleLogout = () => {
    dispatch(removeCurrentUser());
    dispatch(logOut());
    localStorage.setItem('token', '');
    dispatch(VbudusheeAPI.util.resetApiState());
    // trigerLogOut('');
  };

  useEffect(() => {
    const fullName = data
      ? `${data.user.last_name || ''} ${data.user.first_name || ''} ${
          data.user.middle_name || ''
        }`
      : 'Нет данных';

    setFullName(fullName);
  }, [data]);

  return (
    <div className={styles.bar}>
      <div
        className={styles.bar__userWrapper}
        onClick={() => setDroplist(!isDroplist)}
        ref={userbarRef}
      >
        <div className={styles.bar__avatar}>
          {data ? `${data.user?.first_name[0]}${data?.user.last_name[0]}` : ''}
        </div>
        <div
          className={cx('bar__expandIcon', {
            bar__expandIcon_active: isDroplist,
          })}
        >
          <IconArrow />
        </div>
        <div
          className={cx('droplist', {
            droplist_hidden: !isDroplist,
            droplist_withPanel: withPanel,
          })}
        >
          <div className={styles.droplist__item}>{fullName}</div>
          <div
            className={styles.droplist__item}
            onClick={() => navigate(frontendRoutes.materials.events)}
          >
            Мои материалы
          </div>
          <div
            className={styles.droplist__item}
            onClick={() => navigate(frontendRoutes.user.profile.index)}
          >
            Управление профилем
          </div>
          <div className={styles.droplist__item} onClick={handleLogout}>
            Выход
          </div>
        </div>
      </div>
    </div>
  );
};
