import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { frontendRoutes } from '../../../../../utils/router/routes';
import { Button } from '../../../../ui/Button';
import styles from './DocumentContentActions.module.scss';
import { useUploadDocumentWithProgressMutation } from '../../../../../store/VbudusheeAPI';
import { DocumentResponse } from '../../../../../models/document';
import { UploadableFile } from '../../../../../models/files';
import { UploadFile } from '../../../../ui/UploadFile';
import { useTypedSelector } from '../../../../../store';
import { FormikContext, useFormik } from 'formik';
import { downloadPDF } from '../../../../../utils/api/downloadPDF';

type DocumentContentProps = {
  data: DocumentResponse;
  documentId: string;
  isDelete: (value: boolean) => void;
  userRole: 'participant' | 'expert' | 'author';
  expertName?: string;
  setIsOpenModalExpert: (value: boolean) => void;
};

export const DocumentContentActions = ({
  documentId,
  userRole,
  data,
  isDelete,
  expertName,
  setIsOpenModalExpert,
}: DocumentContentProps) => {
  const navigate = useNavigate();

  const [files, setFiles] = useState<UploadableFile[]>([]);
  const [uploadedFileId, setUploadedFileId] = useState<string | null>(null);

  const [uploadFile, { isLoading: isUploadLoading }] =
    useUploadDocumentWithProgressMutation();

  const uploadProgress = useTypedSelector(
    (state) => state.uploadDoc.uploadProgress
  );

  const emptyStringArray = '["null"]';

  const handleSubmit = async () => {
    const formData = new FormData();

    formData.append('document', files[0].file);
    formData.append('event', String(data?.data.event_id) || '');
    formData.append('tags', emptyStringArray);

    const result = await uploadFile(formData);

    if ('data' in result) {
      setUploadedFileId(result.data.data.document_id.toString());
    }
  };

  const disabledStatus = useMemo(() => {
    return (
      data?.data?.document_status === 'auto_check' ||
      data?.data?.document_status === 'new' ||
      data?.data?.document_status === 'review'
    );
  }, [data]);

  const hidenParticipantBtn = useMemo(() => {
    return !(
      data?.data.document_status === 'auto_check' ||
      data?.data.document_status === 'review'
    );
  }, [data]);

  const finalStatus = useMemo(() => {
    return (
      data?.data.document_status === 'rejected' ||
      data?.data.document_status === 'approved' ||
      data?.data.document_status === 'published' ||
      data?.data.document_status === 'published_for_member'
    );
  }, [data]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (files.length > 0) {
      handleSubmit();
    }
  }, [files]);

  return (
    <div className={styles.actions}>
      <h4 className={styles.actionsTitle}>Действия с документом</h4>
      {userRole === 'participant' ? (
        <div>
          <div className={styles.actionsBtns}>
            {data && hidenParticipantBtn && !finalStatus && (
              <Button
                label={
                  data?.data?.document_status === 'new'
                    ? 'Продолжить разметку документа'
                    : 'Изменить разметку документа'
                }
                gradient
                color="gradient"
                type="button"
                clicked={() =>
                  navigate(`${frontendRoutes.document.edit}/${documentId}`)
                }
                disabled={files.length > 0}
              />
            )}
            {(disabledStatus || finalStatus) && (
              <>
                <Button
                  label="Отчёт по документу"
                  withoutBackground
                  type="button"
                  clicked={() =>
                    navigate(
                      `${frontendRoutes.document.automaticAnalysisResults}/${documentId}`
                    )
                  }
                  disabled={
                    data?.data?.document_status === 'new' ||
                    data?.data.document_status === 'auto_check'
                  }
                />
                <div>
                  <Button
                    label="Скачать оригинал"
                    type="button"
                    withoutBackground
                    clicked={() => {
                      downloadPDF(
                        `/api/documents/${documentId}/original`,
                        `${data?.data.document_name}`
                      );
                    }}
                  />
                </div>
              </>
            )}
            {data?.data?.document_status === 'rework' && (
              <>
                {files.length === 0 ? (
                  <FormikContext.Provider value={formik}>
                    <UploadFile
                      uploadProgress={uploadProgress}
                      isLoading={isUploadLoading}
                      setUploadableFiles={setFiles}
                      isSingleFile
                      align="left"
                      title="Загрузить отредактированный документ"
                      label="Готово, перейти к разметке"
                      info={false}
                      gradient={files.length > 0}
                    />
                  </FormikContext.Provider>
                ) : (
                  <Button
                    label="Готово, перейти к разметке"
                    gradient
                    color="gradient"
                    type="button"
                    clicked={() =>
                      navigate(`${frontendRoutes.document.edit}/${documentId}`)
                    }
                  />
                )}
              </>
            )}
          </div>
          <div className={styles.actionsBtns}>
            <Button
              label={
                data?.data?.document_status === 'new'
                  ? 'Удалить документ'
                  : 'Отправить запрос на удаление'
              }
              gradient
              type="button"
              color="gradientDanger"
              clicked={() => isDelete(true)}
            />
            {!disabledStatus && !finalStatus && (
              <>
                <Button
                  label="Отчёт по документу"
                  withoutBackground
                  type="button"
                  clicked={() =>
                    navigate(
                      `${frontendRoutes.document.automaticAnalysisResults}/${documentId}`
                    )
                  }
                  disabled={
                    data?.data?.document_status === 'new' ||
                    data?.data.document_status === 'auto_check'
                  }
                />
                <div>
                  <Button
                    label="Скачать оригинал"
                    type="button"
                    withoutBackground
                    clicked={() => {
                      downloadPDF(
                        `/api/documents/${documentId}/original`,
                        `${data?.data.document_name}`
                      );
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.actionsBtns}>
          {userRole === 'expert' ? (
            <Button
              label={
                !data?.data.total_estimate
                  ? 'Начать проверку'
                  : 'Продолжить проверку'
              }
              type="button"
              gradient
              color="gradient"
              clicked={() =>
                navigate(`${frontendRoutes.document.expert}/${documentId}`)
              }
            />
          ) : (
            <Button
              label={
                expertName ? 'Выбрать другого эксперта' : 'Назначить эксперта'
              }
              gradient
              type="button"
              color="gradient"
              clicked={() => setIsOpenModalExpert(true)}
            />
          )}
          <Button
            label="Отчёт по документу"
            withoutBackground
            type="button"
            clicked={() =>
              navigate(
                `${frontendRoutes.document.automaticAnalysisResults}/${documentId}`
              )
            }
            disabled={
              data?.data?.document_status === 'new' ||
              data?.data.document_status === 'auto_check'
            }
          />
          <div>
            <Button
              label="Скачать оригинал"
              type="button"
              withoutBackground
              clicked={() => {
                downloadPDF(
                  `/api/documents/${documentId}/original`,
                  `${data?.data.document_name}`
                );
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
