import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { frontendRoutes } from '../../../../utils/router/routes';

//  ui
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { HMenu } from '../../../ui/HMenu';
import { AccessesRolesForm } from './AccessesRolesForm';

import styles from './AccessesRoles.module.scss';
import { CopyLink } from '../../../ui/CopyLink/CopyLink';
import {
  useGetEventQuery,
  useGetEventsInviteQuery,
} from '../../../../store/VbudusheeAPI';
import { PopupMessage } from '../../../ui/PopupMessage';

export const AccessesRoles = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const params = useParams();
  const [isCopyLink, setIsCopyLink] = useState(false);

  const eventId = params.eventId;

  const { data } = useGetEventsInviteQuery({
    id: eventId ?? '',
  });

  //copy invite link
  const teamInviteHandler = () => {
    if (eventId) {
      const el = document.createElement('input');
      el.value = `${API_URL}/events/invite/activate?invite=${data?.invite}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      showLinkCopiedTip();
    }
  };

  const menuLinks = [
    {
      label: 'Редактирование мероприятия',
      onClick: () =>
        navigate(
          `${frontendRoutes.settingsEvent.settingsEvent}/${params.eventId}`
        ),
      active: false,
      access: true,
    },
    {
      label: 'Доступы и роли',
      onClick: () =>
        navigate(
          `${frontendRoutes.settingsEvent.accessesAndRole}/${params.eventId}`
        ),
      active: true,
      access: true,
    },
    {
      label: 'Система оценивания',
      onClick: () =>
        navigate(
          `${frontendRoutes.settingsEvent.gradingSystem}/${params.eventId}`
        ),
      active: false,
      access: true,
    },
  ];

  const showLinkCopiedTip = () => {
    setIsCopyLink(true);
    setTimeout(setIsCopyLink, 4000, false);
  };

  return (
    <div className={styles.accessesRoles}>
      <div className="local-container">
        <Breadcrumbs
          links={[
            {
              title: 'Мероприятие',
              link: `${frontendRoutes.publicEventsCatalog.events}/${params.eventId}`,
            },
            { title: 'Настройки мероприятия' },
          ]}
          homeLink={frontendRoutes.mainPage}
        />
      </div>
      <PopupMessage isOpen={isCopyLink}>
        <CopyLink />
      </PopupMessage>
      <div className={styles.accessesRoles__header}>Настройки мероприятия</div>
      <div className={styles.accessesRoles__navigation}>
        <HMenu links={menuLinks} />
      </div>
      <div className="container">
        <AccessesRolesForm onClick={teamInviteHandler} data={data} />
      </div>
    </div>
  );
};
