import React from 'react';
import classNames from 'classnames/bind';

//ui
import styles from './RadioButtonChoiceType.module.scss';
import { RadioButton } from '../../Form/RadioButton';
import { Survey } from '../../../../models/survey/survey';

const cx = classNames.bind(styles);

type RadioButtonChoiceProps = {
  questionTitle: string;
  question: Survey;
  name: string;
  disabled?: boolean;
};

export const RadioButtonChoiceType = (props: RadioButtonChoiceProps) => {
  const { name, questionTitle, question, disabled } = props;

  const objectArray = Object.entries(question.field.choices);

  const result = objectArray.map(([key, value]) => {
    return { key, value };
  });

  return (
    <div className={cx('radioButtonChoice')}>
      <div className={cx('radioButtonChoice__title')}>{questionTitle}</div>
      <div className={cx('groupRadioButton')}>
        <RadioButton
          name={name}
          withoutVisibleValue
          number={result.length}
          disabled={disabled}
          questions={result}
          hideLabel={question.field.hide_label}
        />
      </div>
    </div>
  );
};
