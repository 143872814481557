export const IconArrowFilled = () => {
  return (
    <svg
      width="7"
      height="4"
      viewBox="0 0 7 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.10188 0C0.672305 0 0.442716 0.505961 0.725596 0.829252L3.12371 3.56996C3.32292 3.79762 3.67708 3.79762 3.87629 3.56996L6.27441 0.829252C6.55728 0.50596 6.3277 0 5.89812 0L1.10188 0Z"
        fill="black"
      />
    </svg>
  );
};
