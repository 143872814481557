import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { Tag } from '../../models/tags/tag';
import {
  DictionaryResearch,
  MethodDictItem,
  QuestionnaireDictItem,
} from '../../models/research';

type GetMethodForEvents = {
  method: MethodDictItem[] | null;
  quiz: QuestionnaireDictItem[] | null;
  education: DictionaryResearch | null;
};

const initialState: GetMethodForEvents = {
  method: null,
  quiz: null,
  education: null,
};

const surveySlice = createSlice({
  name: 'survey',
  initialState: initialState,
  reducers: {
    getMethod: (state, action: PayloadAction<MethodDictItem[]>) => {
      state.method = action.payload;
    },
    getQuiz: (state, action: PayloadAction<QuestionnaireDictItem[]>) => {
      state.quiz = action.payload;
    },
    getDictionaryEducation: (
      state,
      action: PayloadAction<DictionaryResearch>
    ) => {
      state.education = action.payload;
    },
  },
});

export const { getMethod, getQuiz, getDictionaryEducation } =
  surveySlice.actions;
export const { reducer: surveyReducer } = surveySlice;
