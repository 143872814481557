import { useState } from 'react';
import { useDrop } from 'react-dnd';

import { TaskType, cardTypes } from './types';

//  ui
import { Box } from '../../../components/ui/Box';
import { Desk } from './Desk/Desk';

const initialTasks: TaskType[] = [
  {
    id: '1',
    name: 'One',
    done: false,
  },
  {
    id: '2',
    name: 'Two',
    done: false,
  },
  {
    id: '3',
    name: 'Three',
    done: false,
  },
  {
    id: '4',
    name: 'Four',
    done: false,
  },
  {
    id: '5',
    name: 'Five',
    done: false,
  },
];

export const Canban = (): JSX.Element => {
  const [tasks, setTasks] = useState<TaskType[]>(initialTasks);
  const [{ isOver }, drop] = useDrop({
    accept: cardTypes.CARD,
    drop: (item: { id: string }) => {
      const droppedTask = tasks.filter((el: TaskType) => el.id === item.id);
      droppedTask[0].done = true;
      if (droppedTask) {
        const prev = tasks.filter((el: TaskType) => el.id !== item.id);
        setTasks([...prev, ...droppedTask]);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
      }}
    >
      <Desk
        title="Left"
        tasks={tasks.filter((t: TaskType) => t.done === false)}
      />
      <Box mr={20} />
      <div
        ref={drop}
        style={{
          opacity: isOver ? 0.5 : 1,
          border: isOver ? '1px solid blue' : undefined,
        }}
      >
        <Desk
          title="Right"
          tasks={tasks.filter((t: TaskType) => t.done === true)}
        />
      </div>
    </div>
  );
};
