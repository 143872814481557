export const IconPdf = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7365 2.76172H8.08449C6.02449 2.76172 4.25049 4.43072 4.25049 6.49072V17.3397C4.25049 19.5157 5.90849 21.1147 8.08449 21.1147H16.0725C18.1325 21.1147 19.8025 19.3997 19.8025 17.3397V8.03772L14.7365 2.76172Z"
        stroke="#FF3B30"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4739 2.75V5.659C14.4739 7.079 15.6229 8.231 17.0429 8.234C18.3589 8.237 19.7059 8.238 19.7969 8.232"
        stroke="#FF3B30"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="12" y="11" width="12" height="7" fill="white" />
      <path
        d="M8 11.6636C8.176 11.6583 8.33333 11.6529 8.472 11.6476C8.616 11.6369 8.76 11.6289 8.904 11.6236C9.048 11.6183 9.19733 11.6156 9.352 11.6156C9.512 11.6103 9.696 11.6076 9.904 11.6076C10.3467 11.6076 10.72 11.6449 11.024 11.7196C11.3333 11.7889 11.584 11.9009 11.776 12.0556C11.968 12.2103 12.1067 12.4129 12.192 12.6636C12.2827 12.9089 12.328 13.2103 12.328 13.5676C12.328 14.2929 12.1333 14.8263 11.744 15.1676C11.3547 15.5089 10.736 15.6796 9.888 15.6796C9.78133 15.6796 9.68533 15.6796 9.6 15.6796C9.51467 15.6743 9.41867 15.6663 9.312 15.6556V17.2476H8V11.6636ZM9.312 14.5756C9.41867 14.5863 9.512 14.5943 9.592 14.5996C9.67733 14.5996 9.776 14.5996 9.888 14.5996C10.2933 14.5996 10.576 14.5196 10.736 14.3596C10.896 14.1996 10.976 13.9569 10.976 13.6316C10.976 13.4716 10.96 13.3329 10.928 13.2156C10.896 13.0983 10.8373 12.9996 10.752 12.9196C10.6667 12.8396 10.552 12.7809 10.408 12.7436C10.2693 12.7063 10.0933 12.6876 9.88 12.6876C9.77333 12.6876 9.67733 12.6903 9.592 12.6956C9.512 12.6956 9.41867 12.7009 9.312 12.7116V14.5756Z"
        fill="#FF3B30"
      />
      <path
        d="M13.4141 11.6556C13.5794 11.6449 13.7367 11.6369 13.8861 11.6316C14.0354 11.6209 14.1847 11.6156 14.3341 11.6156C14.4834 11.6103 14.6381 11.6076 14.7981 11.6076C14.9634 11.6023 15.1447 11.5996 15.3421 11.5996C15.9127 11.5996 16.3874 11.6609 16.7661 11.7836C17.1501 11.9009 17.4541 12.0743 17.6781 12.3036C17.9074 12.5329 18.0701 12.8156 18.1661 13.1516C18.2621 13.4876 18.3101 13.8743 18.3101 14.3116C18.3101 14.8183 18.2487 15.2609 18.1261 15.6396C18.0087 16.0129 17.8221 16.3223 17.5661 16.5676C17.3154 16.8129 16.9927 16.9969 16.5981 17.1196C16.2087 17.2369 15.7447 17.2956 15.2061 17.2956C15.0301 17.2956 14.8674 17.2929 14.7181 17.2876C14.5687 17.2876 14.4247 17.2849 14.2861 17.2796C14.1474 17.2743 14.0061 17.2663 13.8621 17.2556C13.7234 17.2503 13.5741 17.2423 13.4141 17.2316V11.6556ZM14.7261 16.1356C14.8221 16.1463 14.9101 16.1543 14.9901 16.1596C15.0754 16.1649 15.1687 16.1676 15.2701 16.1676C15.5687 16.1676 15.8247 16.1356 16.0381 16.0716C16.2514 16.0023 16.4247 15.8956 16.5581 15.7516C16.6967 15.6076 16.7981 15.4236 16.8621 15.1996C16.9261 14.9703 16.9581 14.6983 16.9581 14.3836C16.9581 14.0903 16.9287 13.8396 16.8701 13.6316C16.8167 13.4183 16.7261 13.2449 16.5981 13.1116C16.4754 12.9783 16.3127 12.8823 16.1101 12.8236C15.9074 12.7596 15.6594 12.7276 15.3661 12.7276C15.2434 12.7276 15.1314 12.7303 15.0301 12.7356C14.9341 12.7409 14.8327 12.7489 14.7261 12.7596V16.1356Z"
        fill="#FF3B30"
      />
      <path
        d="M19.4375 11.6476H23.1815V12.7836H20.7495V14.0236H23.0775V15.1596H20.7495V17.2476H19.4375V11.6476Z"
        fill="#FF3B30"
      />
    </svg>
  );
};
