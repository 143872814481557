import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ModalDocumentStatus.module.scss';
import classNames from 'classnames/bind';
import { Form, FormikContext, useFormik } from 'formik';
import { useGetDocumentTagsQuery } from '../../../store/VbudusheeAPI';
import { object } from 'yup';

//  ui
import { Textarea } from '../Form/Textarea/Textarea';
import { Button } from '../Button';
import { IconCross } from '../Icons/IconCross';
import { InputSearch } from '../Form/InputSearch';
import { Tag } from '../Tag';
import { DocType, Docs, DocumentResponse } from '../../../models/document';
import { uploadDocument } from '../../../store/documentSlice';

const cx = classNames.bind(styles);

type ModalDocumentEditProps = {
  title: string;
  onClose: () => void;
  onChange?: (e: any) => void;
  data: DocumentResponse | undefined;
  docs: Docs;
};

export const ModalDocumentStatus = (props: ModalDocumentEditProps) => {
  const { onClose } = props;
  return (
    <div className={styles.modalDocumentEdit}>
      <div className={styles.content}>
        <div className={styles.content__header}>
          <div className={styles.content__header_title}>
            Подробные результаты нейроанализа
          </div>
          <div className={styles.content__header_modalClose} onClick={onClose}>
            <IconCross />
          </div>
        </div>
      </div>
    </div>
  );
};
