import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconArrow } from '../Icons/IconArrow';
import { IconHome } from '../Icons/IconHome';
import styles from './Breadcrumbs.module.scss';

type BreadcrumbsTypes = {
  home?: string;
  homeLink: string;
  links: {
    title: string;
    link?: string;
    cb?: () => void;
  }[];
};

export const Breadcrumbs = ({ home, links, homeLink }: BreadcrumbsTypes) => {
  const navigate = useNavigate();

  return (
    <div className={styles.bc}>
      <div className={styles.bc__home} onClick={() => navigate(homeLink)}>
        {home ? home : <IconHome />}
      </div>
      {links.map((link) => (
        <div className={styles.bc__item} key={link.title}>
          <div className={styles.bc__arrow}>
            <IconArrow />
          </div>
          <div
            className={styles.bc__current}
            onClick={() =>
              link.link ? navigate(link.link) : link.cb && link.cb()
            }
          >
            {link.title}
          </div>
        </div>
      ))}
    </div>
  );
};
