import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ResearchDetailsProgress,
  ResearchDetails as ResearchDetailsType,
} from '../../../../models/research';
import { isBoolean } from '../../../../utils/basic';
import { frontendRoutes } from '../../../../utils/router/routes';

//  ui
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { Button } from '../../../ui/Button';
import { Box } from '../../../ui/Box';
import { Typography } from '../../../ui/Typography';
import { HMenu } from '../../../ui/HMenu';
import { ResearchDetailsReport } from './ResearchDetailsReport';
import { ResearchDetailsStructure } from './ResearchDetailsStructure';
import {
  useGetDictionaryQuery,
  useGetCurrentUserQuery,
  useGetResearchProgressMutation,
  useGetResearchSurveyQuery,
  useGetResearchDetailsMutation,
} from '../../../../store/VbudusheeAPI';
import { ORGANIZATION_CATEGORY } from '../../../../constants/user';
import { formatDateStr } from '../../../../utils/dates';

type ResearchDetailsProps = {
  researchId?: string;
  details: ResearchDetailsType;
};

type ResearchDetailsArg = {
  id: string;
  body?: {
    organization_inn?: string;
    organization_region_number?: string;
  };
};

export const ResearchDetails = ({
  researchId,
  details,
}: ResearchDetailsProps): JSX.Element => {
  const navigate = useNavigate();
  const [researchDetailsProgress, setResearchDetailsProgress] = useState<
    ResearchDetailsProgress | undefined
  >(undefined);

  const [ResearchStats, setResearchStats] = useState<
    ResearchDetailsProgress | undefined
  >(undefined);

  const [inn, setInn] = useState<string>('');
  const [regionNumber, setRegionNumber] = useState<string>('');

  const [tabState, setTabState] = useState<'report' | 'structure'>('report');
  const { data: dictionary } = useGetDictionaryQuery({});
  const { data: user } = useGetCurrentUserQuery({});

  const {
    data: survey,
    isLoading: isSurveyLoading,
    error: isSurveyError,
  } = useGetResearchSurveyQuery(
    { researchId: researchId },
    { skip: !researchId }
  );

  const [
    researchDetails,
    {
      isLoading: isResearchDetailsLoading,
      error: isResearchDetailsError,
      data: researchProgress,
    },
  ] = useGetResearchProgressMutation();

  const [getResearchStats, { data: researchStats, isLoading }] =
    useGetResearchDetailsMutation();

  const submitHandler = async () => {
    const arg: ResearchDetailsArg = {
      id: researchId!,
      body: {},
    };
    if (inn) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      arg.body['organization_inn'] = inn;
    }
    if (regionNumber) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      arg.body['organization_region_number'] = regionNumber;
    }

    const result = await researchDetails(arg);

    if ('data' in result) {
      if (typeof result.data.result !== 'string') {
        setResearchDetailsProgress(result.data.result);
      } else {
        setResearchDetailsProgress(undefined);
      }
    }
  };

  const tabLinks = [
    {
      label: 'Отчет по исследованию',
      onClick: () => setTabState('report'),
      active: tabState === 'report',
      access: true,
    },
    {
      label: 'Структура опроса',
      onClick: () => setTabState('structure'),
      active: tabState === 'structure',
      access: true,
    },
  ];

  const handleEdit = (): void => {
    if (researchId) {
      navigate(`${frontendRoutes.research.edit.settings}/${researchId}`);
    }
  };

  const filterResearchData = (
    sortArray: { [key: string]: string }[],
    value: string,
    comparisonKey: string
  ) => {
    return sortArray.find((el) => el[comparisonKey] === value);
  };

  const region = useMemo(() => {
    if (details.data.research_region) {
      const result = details.data.research_region
        .map((el) => {
          return el.region_name;
        })
        .join(', ');
      // filterResearchData(
      //   regions,
      //   details.data.research_region,
      //   'region_number'
      // );
      return result ? result : 'Не указано';
    }
    return 'Не указано';
  }, [details.data.research_region]);

  const target = useMemo(() => {
    if (details?.data.research_target && dictionary?.research.research_target) {
      return dictionary.research.research_target[details.data.research_target]
        ? dictionary.research.research_target[details.data.research_target]
        : 'Не указано';
    }
    return 'Не указано';
  }, [details?.data.research_target, dictionary]);

  const type = useMemo(() => {
    if (details?.data.research_type && dictionary?.research.research_type) {
      return dictionary.research.research_type[details.data.research_type]
        ? dictionary.research.research_type[details.data.research_type]
        : 'Не указано';
    }
    return 'Не указано';
  }, [details?.data.research_type, dictionary]);

  useEffect(() => {
    submitHandler();
  }, [inn, regionNumber]);

  const getDayWord = (num: number) => {
    const lastDigit = num % 10;
    const secondLastDigit = Math.floor(num / 10) % 10;

    if (secondLastDigit === 1) {
      return 'дней';
    }
    if (lastDigit === 1) {
      return 'день';
    }
    if (lastDigit >= 2 && lastDigit <= 4) {
      return 'дня';
    }
    return 'дней';
  };

  return (
    <div className="details">
      <div className="local-container">
        <Breadcrumbs
          links={[{ title: 'Страница исследования' }]}
          homeLink="/"
        />
      </div>
      <div className="details__header">
        <Typography
          color="main"
          size="xxxl"
          font="SFPRoDisplay"
          fontWeight={600}
        >
          Страница исследования
        </Typography>
        <Box flex>
          <Button
            label="Сравнение исследований"
            withoutBackground
            clicked={() =>
              navigate(`${frontendRoutes.research.comparison}/${researchId}`)
            }
          />
          <Box mr={16} />
          <Button label="Редактировать" gradient={true} clicked={handleEdit} />
        </Box>
      </div>
      <div className="details__body">
        <Typography color="main" fontWeight={600} size="s" upperCase>
          Основная информация
        </Typography>
        <Box mb={33} />
        <div className="row">
          <div className="detail detail_full">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Описание
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {details?.data.research_description ?? 'Не указано'}
              </Typography>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Название исследования
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {details?.data.research_name ?? 'Не указано'}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Дата начала и окончания исследования
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {formatDateStr(details?.data.research_start_date, {
                  isDateOnly: true,
                })}{' '}
                -{' '}
                {formatDateStr(details?.data.research_end_date, {
                  isDateOnly: true,
                })}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Тип исследования
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {type}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Регион исследования
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {region}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Целевая аудитория респондентов
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {target}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Исследуемый уровень образования
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {dictionary?.research?.research_education[
                  details?.data?.research_education
                ] ?? 'Не указано'}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Периодичность исследования
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {details?.data.research_type === 'longitudinal'
                  ? `Раз в ${details?.data.research_frequency} ${getDayWord(
                      details?.data.research_frequency
                    )}`
                  : 'Не указано'}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Необходимость авторизации респондентов
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {isBoolean(details?.data.research_login_requirement)
                  ? details?.data.research_login_requirement
                    ? 'Требуется'
                    : 'Не требуется'
                  : 'Не указано'}
              </Typography>
            </div>
          </div>
        </div>
        <div className="details__divider" />
        <Typography color="main" fontWeight={600} size="s" upperCase>
          Инициатор исследования
        </Typography>
        <Box mb={33} />
        <div className="row">
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Фамилия
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {details?.data.research_creator
                  ? `${details?.data.research_creator?.last_name}`
                  : 'Не указано'}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Имя
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {details?.data.research_target
                  ? `${details?.data.research_creator?.first_name}`
                  : 'Не указано'}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Отчество
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {details?.data.research_target
                  ? `${details?.data.research_creator?.middle_name}`
                  : 'Не указано'}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Тип организации инициатора
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {ORGANIZATION_CATEGORY.find(
                  (el) =>
                    el.key ===
                    details?.data.research_creator?.organization_category
                )?.value ?? 'Не указано'}
              </Typography>
            </div>
          </div>
          <div className="detail">
            <div className="detail__content">
              <div className="detail__content-title">
                <Typography color="gray" size="s">
                  Название организации инициатора
                </Typography>
              </div>
              <Typography color="main" size="sm">
                {details?.data.research_creator?.organization_name ??
                  'Не указано'}
              </Typography>
            </div>
          </div>
        </div>
        <div className="details__divider" />
        <Typography color="main" fontWeight={600} size="s" upperCase>
          Общая сводка
        </Typography>
        <Box mb={33} />
        <HMenu links={tabLinks} />
        <Box mt={32}>
          {tabState === 'report' ? (
            <ResearchDetailsReport
              researchId={researchId}
              excelDownload={details.data.excel_download}
              researchDetailsProgress={researchDetailsProgress}
              submitHandler={submitHandler}
              setInn={setInn}
              setRegionNumber={setRegionNumber}
            />
          ) : (
            <ResearchDetailsStructure
              researchId={researchId}
              isSurveyError={!!isSurveyError}
              isSurveyLoading={isSurveyLoading}
              survey={survey?.data}
            />
          )}
        </Box>
      </div>
    </div>
  );
};
