import React from 'react';

import styles from './DocumentPopupCancel.module.scss';
import { Button } from '../../../../ui/Button';
import { Typography } from '../../../../ui/Typography';
import { useDispatch } from 'react-redux';
import { resetMarkup } from '../../../../../store/documentSlice';

type ProfilePopupContentTypes = {
  openEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DocumentPopupCancel = ({
  setModalOpen,
}: ProfilePopupContentTypes) => {
  const dispatch = useDispatch();

  const handleAccess = () => {
    dispatch(resetMarkup());
    setModalOpen(false);
  };

  return (
    <div className={styles.popup}>
      <div className={styles.text}>
        <Typography
          color="black2"
          size="m"
          font={'SFPRoDisplay'}
          customLineHeight={22}
        >
          Вы уверены, что хотите сбросить разметку документа?
        </Typography>
      </div>
      <div onClick={handleAccess}>
        <Button label="Подтвердить" gradient />
      </div>
    </div>
  );
};
