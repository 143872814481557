import { Loader } from '../../ui/Loader';
import styles from '../../../pages/survey/SurveyPage.module.scss';
import { frontendRoutes } from '../../../utils/router/routes';
import React, { useEffect, useState } from 'react';
import {
  useGetQuizFeedbackFoEmailMutation,
  useGetQuizFeedbackQuery,
} from '../../../store/VbudusheeAPI';
import { Box } from '../../ui/Box';
import { Button } from '../../ui/Button';
import { useNavigate } from 'react-router-dom';
import { Typography } from '../../ui/Typography';
import image from '../../../assets/images/404_image.svg';
import { IconDownload } from '../../ui/Icons/Research/IconDownload';
import { formatDateStr } from '../../../utils/dates';
import { downloadPDF } from '../../../utils/api/downloadPDF';
import { Form, FormikContext, useFormik } from 'formik';
import { InputText } from '../../ui/Form/InputText';
import { ContentModal } from '../../ui/ContentModal';
import { researchRespondentsSchema } from '../../../models/validations/schemas/research';
import { IconMessage } from '../../ui/Icons/IconMessage';
import { useAuthorized } from '../../../hooks/useAuthorized';
import { TooltipComponent } from '../../ui/TooltipComponent';

type IProps = {
  answerId: string;
  downloadPdf?: boolean;
  gratitudeText?: string;
};

export const SurveyFeedback = (props: IProps): JSX.Element => {
  const { answerId } = props;
  const navigate = useNavigate();
  const { isAuthorized } = useAuthorized();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data, isLoading, error } = useGetQuizFeedbackQuery({
    answerId,
  });

  const [feedbackFoEmail] = useGetQuizFeedbackFoEmailMutation();

  const handleClick = () => {
    navigate(frontendRoutes.mainPage);
  };

  const submitHandler = async () => {
    setIsOpen(false);
    const result = await feedbackFoEmail({
      answerId: answerId,
      email: formik.values.login,
    });

    if ('data' in result) {
      formik.resetForm();
    }
  };

  const formik = useFormik({
    initialValues: {
      login: '',
    },
    validationSchema: researchRespondentsSchema,
    onSubmit: submitHandler,
  });

  // useEffect(() => {
  //   if (!isAuthorized) {
  //     navigate(frontendRoutes.mainPage);
  //   }
  // }, [isAuthorized]);

  return (
    <>
      {isLoading ? (
        <Loader.FullPage>
          <Loader />
        </Loader.FullPage>
      ) : error ? (
        <Box flex>
          <Box>
            <Typography
              className={styles.wrapper__header_text}
              // font={'SBSansDisplay'}
              size={'xxxxl'}
              fontWeight={600}
              customLineHeight={56}
              color={'totalBlack'}
            >
              Что-то пошло не так
            </Typography>
            <Box mt={30}>
              <Button
                label="На главную"
                type={'button'}
                color={'gradient'}
                size={'medium'}
                clicked={() => navigate(frontendRoutes.mainPage)}
              ></Button>
            </Box>
          </Box>
          <div>
            <img src={image} alt="Lifebuoy custom image" />
          </div>
        </Box>
      ) : (
        <div>
          <Box flex justifyContent={'space-between'} mb={40}>
            <div className={styles.survey__title}>
              Обратная связь по результатам диагностики
            </div>
            <Box flex>
              <Box mr={10}>
                <TooltipComponent text="Отправить на электронную почту">
                  <Button
                    icon={<IconMessage />}
                    withoutBackground
                    circle
                    hover
                    clicked={() => setIsOpen(true)}
                  />
                </TooltipComponent>
              </Box>
              <TooltipComponent text="Скачать обратную связь в формате PDF">
                <Button
                  icon={<IconDownload />}
                  withoutBackground
                  circle
                  hover
                  clicked={() => {
                    downloadPDF(
                      `/api/feedback/${answerId}/pdf`,
                      'Обратная связь.pdf'
                    );
                  }}
                />
              </TooltipComponent>
            </Box>
          </Box>
          <Box mb={32}>
            <span>
              Уважаемый участник исследования! Спасибо, что уделили нам время и
              завершили прохождение анкеты. Ниже представлены Ваши результаты по
              каждой из пройденных диагностических методик.
            </span>
          </Box>
          <Box mb={20}>
            <span>
              <b>Название исследования:&nbsp;</b>
              {data?.data.research_name
                ? data.data.research_name
                : 'нет данных'}
            </span>
          </Box>
          <Box mb={24}>
            <span>
              <b>Дата прохождения:&nbsp;</b>
              {data?.data.answer_date
                ? formatDateStr(data.data.answer_date, {
                    isDateOnly: true,
                  })
                : 'нет данных'}
            </span>
          </Box>
          <div className="divider"></div>
          {data?.data.feedback && data?.data.feedback.length > 0 ? (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data?.data.feedback.map((q, i: number) => (
              <div key={i}>
                <Box mb={32}>
                  <Typography
                    size={'s'}
                    fontWeight={700}
                    customLineHeight={16}
                    color={'totalBlack'}
                    upperCase
                  >
                    {q.method_name}
                  </Typography>
                </Box>
                {Object.entries(q.data).map(
                  (d: [string, unknown], index: number) => (
                    <div key={index}>
                      <Box mb={20}>
                        <span>
                          <b>Субшкала:&nbsp;</b>
                          {d[0] ? d[0] : 'нет данных'}
                        </span>
                      </Box>
                      <Box mb={20}>
                        <span>
                          <b>Набранный балл:&nbsp;</b>
                          {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                          {/*@ts-ignore*/}
                          {d[1].points ? d[1].points : '0'}
                        </span>
                      </Box>
                      <Box mb={20}>
                        <span>
                          {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                          {/*@ts-ignore*/}
                          {d[1].verbose_text ? d[1].verbose_text : 'нет данных'}
                        </span>
                      </Box>
                    </div>
                  )
                )}
                <div className="divider"></div>
              </div>
            ))
          ) : (
            <Box mb={20}>
              <div>
                Вы не ответили на все вопросы в методике, поэтому обратная связь
                по ней не предоставлена.
              </div>
            </Box>
          )}

          <Box mb={40}>
            <span>
              Чтобы обратная связь по пройденным Вами исследованиям была всегда
              под рукой, Bы можете завести{' '}
              <a
                style={{ display: 'inline' }}
                onClick={() => {
                  isAuthorized
                    ? navigate(frontendRoutes.user.profile.index)
                    : navigate(frontendRoutes.user.registrationRespondent);
                }}
              >
                свой личный кабинет
              </a>
              .
            </span>
          </Box>

          <Button
            label={'Перейти на главную'}
            type={'button'}
            gradient
            clicked={handleClick}
          />
          <ContentModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            titleText="Отправить на электронную почту"
          >
            <FormikContext.Provider value={formik}>
              <Form autoComplete="off">
                <div>
                  <div
                    className="divider"
                    style={{ marginBottom: '24px' }}
                  ></div>
                  <Box mb={20}>
                    <div>Введите адрес электронной почты и нажмите</div>
                    <div>«Отправить»</div>
                  </Box>
                  <InputText name="login" placeholder="Email" fullWidth />
                  <Box flex mt={24}>
                    <Button
                      label="Отправить"
                      gradient={true}
                      disabled={!formik.isValid || !formik.dirty}
                      clicked={submitHandler}
                    />
                    <Box mr={16} />
                    <Button
                      label="Отмена"
                      withoutBackground
                      clicked={() => {
                        setIsOpen(false);
                      }}
                    />
                  </Box>
                </div>
              </Form>
            </FormikContext.Provider>
          </ContentModal>
        </div>
      )}
    </>
  );
};
