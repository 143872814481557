import { useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { IconQuestionMark } from '../../../ui/Icons/IconQuestionMark';
import { ClickTooltip } from '../../../ui/ClickTooltip';
import styles from './AnalysisResultsStatus.module.scss';
const cx = classNames.bind(styles);

type AnalysisResultsStatusProps = {
  status: 'done' | 'rejected' | 'irrelevant';
};

export const AnalysisResultsStatus = ({
  status,
}: AnalysisResultsStatusProps) => {
  const [isStatusOpen, setStatusOpen] = useState(false);
  const statusTooltipRef = useRef<HTMLDivElement>(null);

  return (
    <div className={styles.statusWrapper}>
      <h4 className={styles.statusTitle}>
        Статус проверки{' '}
        <div className={styles.statusTooltipWrapper} ref={statusTooltipRef}>
          <span
            onClick={() => setStatusOpen((prev) => !prev)}
            className={styles.statusTooltipIcon}
          >
            <IconQuestionMark />
          </span>
          <ClickTooltip
            isOpen={isStatusOpen}
            handleCloseTooltip={() => setStatusOpen(false)}
            parentRef={statusTooltipRef}
          >
            <p>
              {status === 'done' &&
                'Статус присваивается документу в случае, если текст включает в себя ключевые понятия (теги), а также слова, семантически связанные с ними, которые автор указал при разметке.'}
              {status === 'rejected' &&
                'Статус присваивается документу в случае, если в тексте не встречаются ключевые понятия (теги), а также слова, семантически связанные с ними, которые автор указал при разметке, либо в тексте отсутствуют теги.'}
              {status === 'irrelevant' && (
                <>
                  Автоматическая проверка документа могла быть не проведена по
                  следующим причинам:
                  <br />
                  <br /> - в тексте отсутствуют теги;
                  <br /> - возникла техническая ошибка при чтении, отображении,
                  разметке или проверке документа. <br />
                  <br />
                  Кнопка-гиперссылка “Обратиться в техподдержку”
                </>
              )}
            </p>
          </ClickTooltip>
        </div>
      </h4>
      <span
        className={cx('statusText', {
          statusTextDone: status === 'done',
          statusTextRejected: status === 'rejected',
          statusTextIrrelevant: status === 'irrelevant',
        })}
      >
        {status === 'done' &&
          'Содержание работы соответствует заявленной ее автором теме'}
        {status === 'rejected' &&
          'Содержание работы не соответствует заявленной ее автором теме'}
        {status === 'irrelevant' && 'Автоматическая проверка не проведена'}
      </span>
    </div>
  );
};
