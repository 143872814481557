import React, { useCallback, useEffect, useState } from 'react';
import { Form, FormikContext, useFormik } from 'formik';

import { Company, REGIONS } from '../../../models/daData';
import { GetKladrByRegionResponse } from '../../../models/user/registration';
import { updateProfileValidationSchema } from '../../../models/validations/schemas/validations';
import {
  useGetCurrentUserQuery,
  useGetKladrByRegionMutation,
  useGetRegionDataQuery,
  useUserProfileUpdateMutation,
} from '../../../store/VbudusheeAPI';
import { OCCUPATION, ORGANIZATION_CATEGORY } from '../../../constants/user';
import { defaultFormikOptions } from '../../../models/formik';

//  ui
import { Button } from '../../ui/Button';
import { InputCompanyName } from '../../ui/Form/InputCompanyName';
import { InputText } from '../../ui/Form/InputText';
import { Select } from '../../ui/Form/Select';
import { SelectSearch } from '../../ui/Form/SelectSearch';

import styles from './ProfileEditForm.module.scss';
import { AddressType } from '../Profile';
import { InputTin } from '../../ui/Form/InputTin';

type ProfileEditFormTypes = {
  user?: string;
  address?: AddressType;
  onReject: React.Dispatch<React.SetStateAction<boolean>>;
};

export type initialDataCompanyCategoryType = {
  organization_category_code: string;
  organization_category_value: string;
};

export const ProfileEditForm = ({
  onReject,
  user,
  address,
}: ProfileEditFormTypes) => {
  const { data: userData } = useGetCurrentUserQuery({});
  const [userProfileUpdate, { isLoading, error }] =
    useUserProfileUpdateMutation();
  const [getKladr] = useGetKladrByRegionMutation();
  const { data: regions } = useGetRegionDataQuery({});
  const [kladrs, setKladrs] = useState<GetKladrByRegionResponse>([]);
  const [company, setCompany] = useState<Company | undefined>(undefined);

  const [serverError, setServerError] = useState<string | undefined>(undefined);

  const handleSubmit = async (values: typeof updateProfileInitialValues) => {
    setServerError(undefined);
    if (isLoading) {
      return;
    }

    const result = await userProfileUpdate({
      ...values,
    });

    if ('data' in result) {
      onReject(false);
    }
  };

  const handleReject = () => {
    onReject(false);
  };

  const updateProfileInitialValues = {
    last_name: userData?.user.last_name || '',
    first_name: userData?.user.first_name || '',
    middle_name: userData?.user.middle_name || '',
    professional_category: userData?.user.professional_category || '',
    position_name: userData?.user.position_name || '',
    locality: userData?.user.locality || '',
    organization_name: userData?.user.organization_name || '',
    region: userData?.user.region || '',
    organization_category: userData?.user.organization_category || '',
  };

  const formik = useFormik({
    initialValues: updateProfileInitialValues,
    validationSchema: updateProfileValidationSchema,
    onSubmit: handleSubmit,
    ...defaultFormikOptions,
  });

  // const handleRegionChange = useCallback(async () => {
  //   const region = formik.values.region;
  //   if (region !== '') {
  //     const regionKladr =
  //       region.toString().length > 1
  //         ? region.toString()
  //         : `0${region.toString()}`;
  //
  //     const result = await getKladr({ region_number: regionKladr });
  //     if ('data' in result) {
  //       setKladrs(result.data);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formik.values.region, getKladr, formik.values.locality]);
  //
  // useEffect(() => {
  //   handleRegionChange();
  // }, [handleRegionChange]);

  // useEffect(() => {
  //   if (kladrs.length > 0) {
  //     formik.setFieldValue('locality', userData?.user.locality);
  //   }
  // }, [kladrs]);

  // useEffect(() => {
  //   if (formik.values.region === '') {
  //     formik.setFieldValue('locality', '');
  //   }
  // }, [formik.values.region]);

  useEffect(() => {
    if (formik.dirty) {
      formik.setFieldValue(
        'locality',
        company?.data.address.data.city ? company?.data.address.data.city : '',
        true
      );
      formik.setFieldValue(
        'region',
        company?.data.address.data.region
          ? company?.data.address.data.region
          : '',
        true
      );
    }
  }, [company, formik.values.organization_name]);

  useEffect(() => {
    formik.validateField('locality');
    formik.validateField('region');
  }, [formik.values.region, formik.values.locality]);

  return (
    <FormikContext.Provider value={formik}>
      <Form autoComplete="off">
        <div className={styles.form}>
          <div className={styles.form__block}>
            <div className={styles.form__header}>Основная информация</div>
            <InputText name="last_name" placeholder="Фамилия*" />
            <Select
              name="professional_category"
              label="Деятельность*"
              options={OCCUPATION}
            />
            <InputText name="first_name" placeholder="Имя*" />
            <InputText name="position_name" placeholder="Название должности*" />
            <InputText name="middle_name" placeholder="Отчество" />
          </div>

          <div className={styles.form__block}>
            <div className={styles.form__header}>Организация</div>
            {/*<SelectSearch*/}
            {/*  name="region"*/}
            {/*  placeholder="Регион*"*/}
            {/*  options={*/}
            {/*    regions*/}
            {/*      ? regions.map((region) => ({*/}
            {/*          option: region.region_name,*/}
            {/*          value: region.region_number,*/}
            {/*        }))*/}
            {/*      : []*/}
            {/*  }*/}
            {/*/>*/}
            {/*<SelectSearch*/}
            {/*  name="locality"*/}
            {/*  placeholder="Населенный пункт*"*/}
            {/*  options={*/}
            {/*    kladrs.map((kladr) => ({*/}
            {/*      option: kladr.address,*/}
            {/*      value: kladr.kladr_id,*/}
            {/*    })) ?? {*/}
            {/*      option: '',*/}
            {/*      value: '',*/}
            {/*    }*/}
            {/*  }*/}
            {/*  disabled={!formik.values.region}*/}
            {/*/>*/}
            <Select
              name="organization_category"
              label="Категория организации*"
              options={ORGANIZATION_CATEGORY}
            />
            <InputCompanyName
              name="organization_name"
              placeholder="Название организации*"
              setCompany={setCompany}
              localityKladr={formik.values.locality}
              organization_category={formik.values.organization_category}
            />
            <InputText name="region" placeholder="Регион*" disabled={true} />
            <InputText
              name="locality"
              placeholder="Населенный пункт*"
              disabled={true}
            />
          </div>

          {serverError && (
            <div className={styles.form__error}>{serverError}</div>
          )}
        </div>
        <div className={styles.buttons}>
          <div>
            <Button
              label="Сохранить"
              gradient={true}
              type="submit"
              disabled={!formik.dirty || !formik.isValid}
            />
          </div>
          <div onClick={handleReject}>
            <Button label="Отменить" withoutBackground={true} type="submit" />
          </div>
        </div>
      </Form>
    </FormikContext.Provider>
  );
};
