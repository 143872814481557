import React, { useState } from 'react';

import { frontendRoutes } from '../../../utils/router/routes';

//  ui
import { ConfirmEmailForm } from './ConfirmEmail';
import { Breadcrumbs } from '../../ui/Breadcrumbs';
import { ChangeEmailForm } from './ChangeEmailForm';
import styles from './ChangeEmail.module.scss';

export type IPassword = {
  password: string;
};

export const ChangeEmail = () => {
  const [isChangeEmail, setIsChangeEmail] = useState(false);
  const [password, setPassword] = useState('');

  const enterPasswordTitle = 'Введите пароль';
  const enterNewEmailTitle = 'Введите новую электронную почту';

  const changeEmailTitle = !isChangeEmail
    ? enterPasswordTitle
    : enterNewEmailTitle;

  return (
    <div className={styles.changeEmail}>
      <div className="local-container">
        <Breadcrumbs
          links={[
            {
              title: 'Управление профилем',
              link: frontendRoutes.user.profile.index,
            },
            { title: 'Изменение электронной почты' },
          ]}
          homeLink={frontendRoutes.mainPage}
        />
      </div>
      <span className={styles.changeEmail__header}>{changeEmailTitle}</span>
      <div className={styles.changeEmail__form}>
        {isChangeEmail ? (
          <ConfirmEmailForm password={password} />
        ) : (
          <ChangeEmailForm
            setIsChangeEmail={setIsChangeEmail}
            setPassword={setPassword}
          />
        )}
      </div>
    </div>
  );
};
