import React, { useEffect, useMemo, useState } from 'react';
import { Form, FormikContext, FieldArray, useFormik } from 'formik';
import { ContentModal } from '../ContentModal';
import { CheckBox } from '../Form/CheckBox';
import { researchQuestion } from '../../../models/validations/schemas/research';
import { defaultFormikOptions } from '../../../models/formik';
import { Button } from '../Button';
import { Box } from '../Box';
import { Select } from '../Form/Select';
import { Textarea } from '../Form/Textarea/Textarea';
import { Typography } from '../Typography';
import {
  useAddCustomQuestionMutation,
  useDeleteResearchQuestionnairesMutation,
} from '../../../store/VbudusheeAPI';
import {
  QuestionItemType,
  QuestionnaireDictItem,
} from '../../../models/research';
import { isError } from 'util';
import { ModalError } from '../ModalError';

type ContentModalTypes = {
  isOpen?: boolean;
  onClose: () => void;
  addQuestion: (value: { data: QuestionnaireDictItem }) => void;
  item?: any;
  isSavedQuestion?: boolean;
  requiredQuestion?: number[];
  setRequiredQuestion?: (e: any) => void;
  chosenQuestion?: QuestionItemType[];
  setChosenQuestion?: (e: any) => void;
};

export const ModalCustomQuestion = ({
  isOpen,
  onClose,
  addQuestion,
  item,
  isSavedQuestion = false,
  requiredQuestion,
  setRequiredQuestion,
  chosenQuestion,
  setChosenQuestion,
}: ContentModalTypes) => {
  const [inputType, setInputType] = useState<string>('');
  const [modal, setModal] = useState<boolean>(false);
  const [
    addCustomQuestion,
    { isLoading: isAddCustomQuestionLoading, error: isAddCustomQuestionError },
  ] = useAddCustomQuestionMutation();

  const [deleteResearchQuestionnaires] =
    useDeleteResearchQuestionnairesMutation();

  const initialValues = {
    question_name: '',
    question_required: true,
    question_input_type: '',
    question_answers: [],
  };

  const optionsQuestionType = [
    {
      key: 'checkbox',
      value: 'Вопрос с несколькими вариантами ответа',
    },
    {
      key: 'select',
      value: 'Вопрос с одним ответом',
    },
    {
      key: 'input',
      value: 'Вопрос с ответом в свободной форме',
    },
  ];

  const submitHandler = async () => {
    const result = await addCustomQuestion({
      question_name: formik.values.question_name,
      question_required: formik.values.question_required,
      question_input_type: formik.values.question_input_type,
      question_answers: formik.values.question_answers,
    });
    if ('data' in result) {
      addQuestion(result.data);
      onClose();
      formik.resetForm();
    }
  };

  const deletHandler = async () => {
    const result = await deleteResearchQuestionnaires({
      id: item.id,
    });
    if ('data' in result) {
      onClose();
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: researchQuestion,
    onSubmit: submitHandler,
    enableReinitialize: true,
    ...defaultFormikOptions,
  });

  const touch = useMemo(() => {
    let count = 0;
    for (const key in formik.touched) {
      count++;
    }
    return !!count;
  }, [formik.touched]);

  useEffect(() => {
    if (formik.values.question_input_type) {
      if (
        formik.values.question_input_type === 'checkbox' ||
        formik.values.question_input_type === 'select'
      ) {
        formik.setFieldValue('question_answers', ['', '']);
      }
      if (formik.values.question_input_type === 'input') {
        formik.setFieldValue('question_answers', []);
      }
      setInputType(formik.values.question_input_type);
    }
  }, [formik.values.question_input_type]);

  const deleteQuestion = (id: string | number) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setChosenQuestion(chosenQuestion.filter((el) => el?.id !== id));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setRequiredQuestion(requiredQuestion.filter((el) => el !== id));
  };

  return (
    <ContentModal
      isOpen={isOpen}
      onClose={() => onClose()}
      titleText={item?.id ? 'Удаление вопроса' : 'Добавление анкетного вопроса'}
    >
      <>
        <div
          className="divider"
          style={{
            marginBottom: '24px',
          }}
        ></div>
        {isSavedQuestion ? (
          <>
            <div
              style={{
                marginBottom: '24px',
                fontSize: 18,
              }}
            >
              {item?.title}
            </div>
            <Box flex>
              <Button
                label="Удалить"
                gradient={true}
                color="gradientDanger"
                clicked={() => setModal(true)}
              />
              <Box mr={16} />
              <Button
                label="Отмена"
                withoutBackground
                clicked={() => onClose()}
              />
            </Box>
            <ContentModal
              isOpen={modal}
              onClose={() => {
                setModal(false);
                onClose();
              }}
              titleText="Подтверждение удаление вопроса"
            >
              <>
                <div>Вы уверены что хотите удалить вопрос?</div>
                <br />
                <div>
                  Удаление кастомного вопроса может повлечь ошибку в других
                  опросниках
                </div>
                <Box flex mt={32}>
                  <Button
                    label="Подтвердить удаление"
                    gradient={true}
                    color="gradientDanger"
                    clicked={() => {
                      deleteQuestion(item?.id);
                      deletHandler();
                    }}
                  />
                  <Box mr={16} />
                  <Button
                    label="Отмена"
                    withoutBackground
                    clicked={() => {
                      onClose();
                      setModal(false);
                    }}
                  />
                </Box>
              </>
            </ContentModal>
          </>
        ) : (
          <FormikContext.Provider value={formik}>
            <Form>
              <Box flex mb={20}>
                <>
                  <CheckBox
                    checked={formik.values.question_required}
                    onChange={() => {
                      formik.setFieldValue(
                        'question_required',
                        !formik.values.question_required
                      );
                    }}
                    themeGradient={true}
                  />
                  <Box ml={10}>
                    <span>Обязательный вопрос</span>
                  </Box>
                </>
              </Box>
              <Box mb={24}>
                <Select
                  name="question_input_type"
                  label="Тип анкетного вопроса*"
                  options={optionsQuestionType}
                  fullWidth
                />
              </Box>
              <Typography size="s" color="main" fontWeight={600}>
                ВОПРОС
              </Typography>
              <Box mt={12} mb={20}>
                <Textarea
                  name="question_name"
                  placeholder="Ваш вопрос*"
                  height={80}
                  maxLength={1000}
                />
              </Box>
              <FieldArray name="question_answers">
                {(fieldArrayProps) => {
                  const { push, remove, form } = fieldArrayProps;
                  const { values } = form;
                  const { question_input_type, question_answers } = values;
                  return (
                    <div>
                      {question_input_type && question_input_type !== 'input' && (
                        <Box mb={12}>
                          <Typography size="s" color="main" fontWeight={600}>
                            ОТВЕТЫ
                          </Typography>
                        </Box>
                      )}
                      {question_input_type &&
                        question_answers.map(
                          (question: string, index: number) => (
                            <div key={`question-${index + 1}`}>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  marginBottom: '12px',
                                }}
                              >
                                <>
                                  <Box>
                                    <Typography
                                      size="s"
                                      color="main"
                                      fontWeight={500}
                                    >
                                      Ответ {index + 1}
                                    </Typography>
                                  </Box>
                                  {index > 1 && (
                                    <div
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => remove(index)}
                                    >
                                      <Typography size="s" color="danger">
                                        Удалить
                                      </Typography>
                                    </div>
                                  )}
                                </>
                              </div>
                              <Box mb={16}>
                                <Textarea
                                  name={`question_answers[${index}]`}
                                  placeholder="Вариант ответа*"
                                  height={80}
                                  maxLength={100}
                                />
                              </Box>
                            </div>
                          )
                        )}
                      {question_input_type && question_input_type !== 'input' && (
                        <Box mb={32}>
                          <span
                            style={{
                              cursor: 'pointer',
                              display: 'inline-block',
                            }}
                            onClick={() => push('')}
                          >
                            <Typography size="s" color="prGreen">
                              Добавить вариант
                            </Typography>
                          </span>
                        </Box>
                      )}
                    </div>
                  );
                }}
              </FieldArray>
            </Form>
            <Box flex>
              <Button
                label="Добавить"
                gradient={true}
                disabled={!formik.isValid || !formik.dirty || !touch}
                clicked={submitHandler}
              />
              <Box mr={16} />
              <Button
                label="Отмена"
                withoutBackground
                clicked={() => onClose()}
              />
            </Box>
          </FormikContext.Provider>
        )}
      </>
    </ContentModal>
  );
};
