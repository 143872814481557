import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';

import { frontendRoutes } from '../../../utils/router/routes';
import { useAuthorized } from '../../../hooks/useAuthorized';
import {
  ItemMenuType,
  researchMenu,
  teamsMenu,
} from '../../../constants/header/constants';

//  ui
import { IconLogo } from '../../ui/Icons/Header/IconLogo';
import { GuestBar } from '../GuestBar';
import { UserBar } from '../UserBar';
import { RollMenu } from '../../ui/RollMenu';

import styles from './Header.module.scss';
import { useMemo } from 'react';
import { useTypedSelector } from '../../../store';
import { getActualUser } from '../../../store/userSlice/selectors';
const cx = classNames.bind(styles);

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useTypedSelector(getActualUser);
  const { isAuthorized } = useAuthorized();
  const isAuthPage = location.pathname === frontendRoutes.user.login;
  const isResearchSurveyPath = /\/research\/survey\//.test(location.pathname);
  const isInteractiveMapPath = /\/interactive-map/.test(location.pathname);

  const eventsMenu: ItemMenuType[] = useMemo(() => {
    return [
      {
        title: 'Новое мероприятие',
        url: '/create-event',
        disabled: !!user?.role?.name && user.role.name !== 'Респондент',
      },
      {
        title: 'Каталог публичных мероприятий',
        url: '/events',
        disabled: !!user?.role?.slug && user.role.slug !== 'fund_user',
      },
      {
        title: 'Заявки на публикацию мероприятий',
        url: '/events/requests-on-publish',
        condition: ['user.role.slug', 'fund_user'],
      },
    ];
  }, [user]);

  const researchMenu: ItemMenuType[] = useMemo(() => {
    return [
      { title: 'Новое исследование', url: '/research/new' },
      { title: 'Анализ результатов', url: '/research/comparison' },
      { title: 'Карта открытых данных', url: '/interactive-map' },
      {
        title: 'Заявки на публикацию исследования',
        url: '/research/requests-on-publish',
        condition: ['user.role.slug', 'fund_user'],
      },
    ];
  }, [user]);

  return (
    <div
      className={cx('header', {
        header_withPanel: isResearchSurveyPath || isInteractiveMapPath,
      })}
    >
      <div
        className={styles.header__logo}
        onClick={() => navigate(frontendRoutes.mainPage)}
      >
        <IconLogo />
      </div>
      <nav className={cx('header__rollMenu')}>
        <RollMenu
          title={'Исследования'}
          option={researchMenu}
          isAuthorized={isAuthorized}
        />

        <RollMenu
          title={'Мероприятия'}
          option={eventsMenu}
          isAuthorized={isAuthorized}
        />
        {isAuthorized ? (
          <RollMenu title={'Команды'} option={teamsMenu} />
        ) : (
          <RollMenu title={'Команды'} option={teamsMenu} disabled />
        )}
      </nav>
      {isAuthorized ? (
        <div className={styles.header__userbar}>
          <UserBar withPanel={isResearchSurveyPath || isInteractiveMapPath} />
        </div>
      ) : (
        <div
          className={cx('header__userbar', {
            header__userbar_withPanel:
              isResearchSurveyPath || isInteractiveMapPath,
          })}
        >
          {isAuthPage || <GuestBar />}
        </div>
      )}
    </div>
  );
};
