export const IconQuestionMark = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99996 1.66669C5.39759 1.66669 1.66663 5.39765 1.66663 10C1.66663 14.6024 5.39759 18.3334 9.99996 18.3334C14.6023 18.3334 18.3333 14.6024 18.3333 10C18.3333 5.39765 14.6023 1.66669 9.99996 1.66669ZM7.49996 8.1295C7.51461 7.43736 7.75265 6.88621 8.21407 6.47605C8.69015 6.04759 9.342 5.83335 10.1696 5.83335C10.9497 5.83335 11.5814 6.03111 12.0648 6.42662C12.5409 6.81846 12.7789 7.32933 12.7789 7.95921C12.7789 8.73924 12.387 9.35997 11.6034 9.82139C11.2371 10.0338 10.99 10.2242 10.8618 10.3927C10.7336 10.5611 10.6695 10.7809 10.6695 11.0519V11.343H9.21383L9.20284 11.0244C9.16622 10.5996 9.22664 10.2462 9.38411 9.96421C9.53792 9.69688 9.80709 9.44969 10.1916 9.22264C10.5468 9.01023 10.7885 8.8198 10.9167 8.65135C11.0485 8.48289 11.1145 8.26866 11.1145 8.00865C11.1145 7.74498 11.0192 7.52891 10.8288 7.36045C10.6347 7.192 10.382 7.10777 10.0708 7.10777C9.75582 7.10777 9.5013 7.20115 9.30721 7.38792C9.11312 7.57102 9.00692 7.81822 8.98861 8.1295H7.49996ZM11.0416 13.125C11.0416 13.7003 10.5753 14.1667 9.99996 14.1667C9.42466 14.1667 8.95829 13.7003 8.95829 13.125C8.95829 12.5497 9.42466 12.0834 9.99996 12.0834C10.5753 12.0834 11.0416 12.5497 11.0416 13.125Z"
        fill="#D0D7DD"
      />
    </svg>
  );
};
