import { useNavigate, useSearchParams } from 'react-router-dom';
import { Layout } from '../../../components/Layout';
import { RestorePasswordAccept } from '../../../components/pages/auth/RestorePasswordAccept';
import { frontendRoutes } from '../../../utils/router/routes';

export const RestorePasswordAcceptPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  if (!token) {
    navigate(frontendRoutes.user.login);
  }

  return (
    <Layout>{token ? <RestorePasswordAccept token={token} /> : <></>}</Layout>
  );
};
