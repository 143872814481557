import React from 'react';
import { Layout } from '../../../components/Layout';
import { Login } from '../../../components/pages/auth/Login';

export const LoginPage = () => {
  return (
    <Layout>
      <Login />
    </Layout>
  );
};
