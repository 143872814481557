export const IconCross = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1175 0.151411C11.3194 -0.0504703 11.6467 -0.0504703 11.8486 0.151411C12.0505 0.353292 12.0505 0.680606 11.8486 0.882487L6.73117 5.99994L11.8488 11.1175C12.0507 11.3194 12.0507 11.6467 11.8488 11.8486C11.6469 12.0505 11.3196 12.0505 11.1177 11.8486L6.0001 6.73101L0.882487 11.8486C0.680606 12.0505 0.353292 12.0505 0.151411 11.8486C-0.0504703 11.6467 -0.0504703 11.3194 0.151411 11.1175L5.26902 5.99994L0.151572 0.882487C-0.0503094 0.680606 -0.0503094 0.353292 0.151572 0.151411C0.353453 -0.0504703 0.680766 -0.0504703 0.882647 0.151411L6.0001 5.26886L11.1175 0.151411Z"
        fill="currentColor"
      />
    </svg>
  );
};
