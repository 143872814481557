import styles from './ComparisonDownloadBar.module.scss';

type ComparisonDownloadBarProps = {
  handleToggleBar: () => void;
  handleDownloadComparisonFile: () => void;
};

export const ComparisonDownloadBar = ({
  handleToggleBar,
  handleDownloadComparisonFile,
}: ComparisonDownloadBarProps) => {
  return (
    <div className={styles.bar} onClick={handleToggleBar}>
      <div className={styles.barOption} onClick={handleDownloadComparisonFile}>
        Скачать первичные данные сравниваемых исследований
      </div>
      <div className={styles.barOption}>Скачать сравнение в PDF</div>
    </div>
  );
};
