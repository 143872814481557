import { Question } from '../../models/research';

export const MOCK_SELECT_DATA = [
  { key: '0', value: 'a' },
  { key: '1', value: 'b' },
  { key: '2', value: 'c' },
  { key: '3', value: 'd' },
  { key: '4', value: 'e' },
];

export const MOCK_QUESTIONS: Question[] = [
  {
    title: 'Методика векторного моделирования среды развития личности',
    questions: [
      {
        id: 1,
        data: `Сведения обо всех объектах, которыми когда-либо владел конкретный человек или организация, и сведения об объектах недвижимости, находящихся на территории других субьектов, если такие есть у правообладателя. Можно получить сведения
        на определённую дату или за период`,
      },
      {
        id: 2,
        data: `Сведения обо всех объектах, которыми когда-либо владел конкретный человек или организация, и сведения об объектах недвижимости, находящихся на территории других субьектов, если такие есть у правообладателя. Можно получить сведения
        на определённую дату или за период`,
      },
    ],
  },
  {
    title:
      'Методика экспертной оценки количественных параметров образовательной среды',
    questions: [
      {
        id: 1,
        data: `Сведения обо всех объектах, которыми когда-либо владел конкретный человек или организация, и сведения об объектах недвижимости, находящихся на территории других субьектов, если такие есть у правообладателя. Можно получить сведения
        на определённую дату или за период`,
      },
      {
        id: 2,
        data: `Сведения обо всех объектах, которыми когда-либо владел конкретный человек или организация, и сведения об объектах недвижимости, находящихся на территории других субьектов, если такие есть у правообладателя. Можно получить сведения
        на определённую дату или за период`,
      },
      {
        id: 3,
        data: `Сведения обо всех объектах, которыми когда-либо владел конкретный человек или организация, и сведения об объектах недвижимости, находящихся на территории других субьектов, если такие есть у правообладателя. Можно получить сведения
        на определённую дату или за период`,
      },
      {
        id: 4,
        data: `Сведения обо всех объектах, которыми когда-либо владел конкретный человек или организация, и сведения об объектах недвижимости, находящихся на территории других субьектов, если такие есть у правообладателя. Можно получить сведения
        на определённую дату или за период`,
      },
    ],
  },
  {
    title:
      'Методика экспертной оценки количественных параметров образовательной среды',
    questions: [
      {
        id: 1,
        data: `Сведения обо всех объектах, которыми когда-либо владел конкретный человек или организация, и сведения об объектах недвижимости, находящихся на территории других субьектов, если такие есть у правообладателя. Можно получить сведения
        на определённую дату или за период`,
      },
      {
        id: 2,
        data: `Сведения обо всех объектах, которыми когда-либо владел конкретный человек или организация, и сведения об объектах недвижимости, находящихся на территории других субьектов, если такие есть у правообладателя. Можно получить сведения
        на определённую дату или за период`,
      },
    ],
  },
];

export const MOCK_BAR_PROGRESS_CHART_DATA = [
  { key: 1, value: 20 },
  { key: 2, value: 30 },
  { key: 3, value: 10 },
  { key: 4, value: 0 },
  { key: 5, value: 0 },
  { key: 6, value: 0 },
  { key: 7, value: 0 },
  { key: 8, value: 0 },
];

export const MOCKED_RESEARCH_DETAILS = {
  survey_statistics_multiple: [[]],
  survey_statistics_aggr: [
    {
      name: 'compl_progress',
      data: {
        other_stats: {
          survey_mean_time: 34,
          pass_percent: 1,
          members: 1,
        },
        completion_progress: {
          total: {
            'Прошли полностью': 1,
            'Прошли частично': 1,
            'Перешли по ссылке-приглашению': 1,
          },
          detailed: {
            resp_data_cnts: {
              '1': 1,
              '6': 1,
              '7': 1,
              '8': 1,
              '9': 1,
              '10': 1,
              '1040': 1,
            },
            'Методика оценки социально-эмоциональных навыков (ESP)': 1,
          },
        },
      },
    },
  ],
};

export const PIE_COLORS = [
  '#67b3d8',
  '#9de455',
  '#f5a732',
  '#f2c94c',
  '#6d3eca',
  '#ae3ec3',
  '#ffce8f',
];

export const RESPONDENTS = [
  {
    id: 1,
    email: 'test@test.ru',
  },
  {
    id: 2,
    email: 'test@test.ru',
  },
  {
    id: 3,
    email: 'test@test.ru',
  },
  {
    id: 4,
    email: 'test@test.ru',
  },
  {
    id: 5,
    email: 'test@test.ru',
  },
  {
    id: 6,
    email: 'test@test.ru',
  },
  {
    id: 7,
    email: 'test@test.ru',
  },
];
