// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResearchEditSettings_research_frequency__Av1wU {
  display: flex;
  align-items: center;
}
.ResearchEditSettings_research_frequency__Av1wU > div {
  width: 60px;
  margin: 0 5px;
}
.ResearchEditSettings_research_frequency__Av1wU > div > input {
  width: 60px;
}

.ResearchEditSettings_research_step__H7Bk4 {
  display: flex;
  align-items: center;
}
.ResearchEditSettings_research_step__H7Bk4 > div {
  width: 60px;
  margin: 0 5px;
}
.ResearchEditSettings_research_step__H7Bk4 > div > input {
  width: 60px;
  text-align: center;
}

.ResearchEditSettings_dateScroll__container__YC6\\+U {
  overflow-y: scroll;
  max-height: 100px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/research/ResearchForm/ResearchEditSettings/ResearchEditSettings.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,WAAA;EACA,aAAA;AACJ;AACI;EACE,WAAA;AACN;;AAIA;EACE,aAAA;EACA,mBAAA;AADF;AAGE;EACE,WAAA;EACA,aAAA;AADJ;AAGI;EACE,WAAA;EACA,kBAAA;AADN;;AAMA;EACE,kBAAA;EACA,iBAAA;AAHF","sourcesContent":[".research_frequency {\n  display: flex;\n  align-items: center;\n\n  & > div {\n    width: 60px;\n    margin: 0 5px;\n\n    & > input {\n      width: 60px;\n    }\n  }\n}\n\n.research_step {\n  display: flex;\n  align-items: center;\n\n  & > div {\n    width: 60px;\n    margin: 0 5px;\n\n    & > input {\n      width: 60px;\n      text-align: center;\n    }\n  }\n}\n\n.dateScroll__container {\n  overflow-y: scroll;\n  max-height: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"research_frequency": `ResearchEditSettings_research_frequency__Av1wU`,
	"research_step": `ResearchEditSettings_research_step__H7Bk4`,
	"dateScroll__container": `ResearchEditSettings_dateScroll__container__YC6+U`
};
export default ___CSS_LOADER_EXPORT___;
