import React, { useEffect, useMemo, useState } from 'react';
import { Form, FormikContext, useFormik, useFormikContext } from 'formik';
import styles from './QuestionnaireForm.module.scss';
import classNames from 'classnames/bind';

//  ui
import { Button } from '../../../ui/Button';
import { useNavigate } from 'react-router-dom';

import { Methods } from '../../../../mocks/methodics/methodics';
import { QuestionnaireDictItem } from '../../../../models/research';
import { QuestionItem } from '../../../ui/QuestionItem';
import { Box } from '../../../ui/Box';
import { QuestionsForMethodsForm } from '../../QuestionsForMethods/QuestionsForMethodsForm';
import { Typography } from '../../../ui/Typography';

const cx = classNames.bind(styles);

type QuestionnaireFormProps = {
  questionnaireDescription?: string;
  questionnaires?: QuestionnaireDictItem[];
  setStep: (value: string) => void;
  regionId?: number | undefined;
  paginationQuestionStep: number;
  setPaginationQuestionStep: (value: number) => void;
  requirement: number[];
  errorConfirm?: boolean;
};

export const QuestionnaireForm = (props: QuestionnaireFormProps) => {
  const {
    questionnaireDescription,
    questionnaires,
    regionId,
    setStep,
    paginationQuestionStep,
    setPaginationQuestionStep,
    requirement,
    errorConfirm = false,
  } = props;
  const navigate = useNavigate();
  //TODO mock data
  const questionsList: any[] = Object.values(Methods.survey);

  const formikContext = useFormikContext();

  const paginationQuestionEnd = useMemo(() => {
    if (questionnaires) {
      const arr = questionnaires
        .map((el) => {
          if (!el.survey.question_input_type.includes('hidden')) {
            return el;
          }
        })
        .filter((el) => el);
      return Math.ceil(arr.length / 10) - 1;
    }
    return 0;
  }, [questionnaires]);

  const paginationQuestion = useMemo(() => {
    let countQuestionHidden = 0;
    const start = paginationQuestionStep * 10;
    const end = start + 9;
    if (questionnaires) {
      const res = [];
      for (let i = 0; i < questionnaires.length; i++) {
        if (!questionnaires[i].survey.question_input_type.includes('hidden')) {
          if (i >= start + countQuestionHidden && res.length < 10) {
            res.push(questionnaires[i]);
          } else {
            continue;
          }
        } else {
          countQuestionHidden++;
          continue;
        }
      }
      return res;
    }
    return questionnaires;
  }, [questionnaires, paginationQuestionStep]);

  const validQuestion = useMemo(() => {
    if (paginationQuestion) {
      const questionRequired = paginationQuestion.filter((el) =>
        requirement.includes(el.id)
      );
      if (questionRequired.length > 0) {
        if (
          questionRequired.filter((el) =>
            Object.keys(formikContext.errors).includes(String(el.id))
          ).length
        ) {
          return !formikContext.isValid;
        }
        return false;
      }
      return false;
    }
    return false;
  }, [paginationQuestion, formikContext]);

  const daDataId = useMemo(() => {
    const res: { [x: string]: number } = {};
    if (questionnaires) {
      questionnaires.map((el) => {
        if (el.survey.question_input_type.includes('dadata')) {
          if (el.survey.question_slug === 'locality') {
            res['city'] = el.id;
          }
          res[el.survey.question_slug] = el.id;
        }
      });
    }
    return res;
  }, [questionnaires]);

  const validatPagination = () => {
    // formikContext.validateForm();
    if (formikContext.dirty && !validQuestion) {
      if (paginationQuestionStep >= paginationQuestionEnd) {
        setStep('method');
      } else {
        setPaginationQuestionStep(paginationQuestionStep + 1);
        formikContext.setTouched({});
      }
    }
  };

  useEffect(() => {
    if (!paginationQuestion) {
      setStep('method');
    }
  }, [paginationQuestion]);

  return (
    <div className={styles.form}>
      <div className={styles.form__block}>
        {paginationQuestionStep === 0 && (
          <>
            <div className={styles.form__header}>
              <b>Информация об исследовании</b>
            </div>
            <div className={styles.form__description}>
              {questionnaireDescription}
            </div>
          </>
        )}
        <Typography color="danger">* - вопрос является обязательным</Typography>
        {paginationQuestion &&
          paginationQuestion.map((q, i: number) => (
            <div
              key={`question-${i}`}
              className={cx({
                question__hidden:
                  q.survey.question_input_type.includes('hidden'),
              })}
            >
              <Box mb={36}>
                <QuestionItem
                  id={q.id}
                  questionType={q.survey.question_input_type}
                  description={q.survey.question_answers}
                  title={q.survey.question_name}
                  sizeTitle="s"
                  upperCase={true}
                  required={
                    !!q.survey.question_order || requirement.includes(q.id)
                  }
                  questionSlug={q.survey.question_slug}
                  daDataId={daDataId}
                />
              </Box>
            </div>
            // <SurveyQuestion
            // key={`question-${i}`}
            // name={`question-${q.id}`}
            //   type={q.survey.question_input_type}
            //   questionTitle={q.survey.question_name}
            //   question={questionsList[0].field}
            //   questions={questionsList}
            //   position={'vertical'}
            //   max={questionsList[0].field.max}
            //   min={questionsList[0].field.min}
            // />
          ))}

        {/*TODO mock data*/}
        {/*<SurveyQuestion*/}
        {/*  name={'radiobutton'}*/}
        {/*  type={'integer_from_to'}*/}
        {/*  questionTitle={questionsList[0].text}*/}
        {/*  question={questionsList[0].field}*/}
        {/*  questions={questionsList}*/}
        {/*  position={'vertical'}*/}
        {/*  max={questionsList[0].field.max}*/}
        {/*  min={questionsList[0].field.min}*/}
        {/*/>*/}

        {/*<SurveyQuestion*/}
        {/*  name={'checkbox'}*/}
        {/*  type={'checkbox'}*/}
        {/*  questionTitle={questionsList[1].text}*/}
        {/*  question={questionsList[1].field}*/}
        {/*  questions={questionsList}*/}
        {/*  position={'vertical'}*/}
        {/*  max={questionsList[1].field.max}*/}
        {/*  min={questionsList[1].field.min}*/}
        {/*/>*/}

        {/*<SurveyQuestion*/}
        {/*  name={'textarea'}*/}
        {/*  type={'textarea'}*/}
        {/*  questionTitle={questionsList[2].text}*/}
        {/*  question={questionsList[2].field}*/}
        {/*  questions={questionsList}*/}
        {/*/>*/}

        {/*<SurveyQuestion*/}
        {/*  name={'select'}*/}
        {/*  type={'select'}*/}
        {/*  questionTitle={questionsList[3].text}*/}
        {/*  question={questionsList[3].field}*/}
        {/*  questions={questionsList}*/}
        {/*/>*/}

        <div className={styles.form__submit}>
          {paginationQuestionStep > 0 && (
            <>
              <Button
                label="Назад"
                withoutBackground
                clicked={() => {
                  setPaginationQuestionStep(paginationQuestionStep - 1);
                }}
              />
              <Box mr={16} />
            </>
          )}
          <Button
            label="Далее"
            type="submit"
            gradient
            disabled={errorConfirm}
            // disabled={!formikContext.dirty || validQuestion}
            clicked={() => validatPagination()}
          />
        </div>
      </div>
    </div>
  );
};
