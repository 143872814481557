import { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';

import type { UploadableFile } from '../../../models/files';
import { DEFAULT_FILE_SIZE_LIMIT } from '../../../constants/files';

//  ui
import { UploadField } from './UploadField';
import { FileItem } from './FileItem';

type UploadFileProps = {
  title?: string;
  label?: string;
  isLoading?: boolean;
  uploadProgress?: number;
  uploadableFiles?: UploadableFile[];
  setUploadableFiles: (files: UploadableFile[]) => void;
  isSingleFile?: boolean;
  sizeLimit?: number; // bytes
  fileExtensions?: string[]; // MIME types
  align?: 'left' | 'center' | 'right'; // button alignment
  setModalIsOpen?: (value: boolean) => void;
  info?: boolean;
  gradient?: boolean;
};

export const UploadFile = ({
  title,
  label = 'Готово',
  isLoading = false,
  uploadProgress,
  uploadableFiles,
  setUploadableFiles,
  isSingleFile,
  sizeLimit = DEFAULT_FILE_SIZE_LIMIT,
  fileExtensions,
  align,
  setModalIsOpen,
  info = true,
  gradient = false,
}: UploadFileProps): JSX.Element => {
  const { submitForm } = useFormikContext<any>();
  const [files, setFiles] = useState<UploadableFile[]>([]);
  const [error, setError] = useState(false);
  const FILES_LENGTH = isSingleFile ? 1 : 10;

  const handleDelete = (file: File) => {
    setFiles((prev) => prev.filter((f) => f.file !== file));
  };

  const handleClick = (): void => {
    if (setModalIsOpen) {
      setModalIsOpen(false);
    }
    submitForm();
  };

  useEffect(() => {
    setUploadableFiles(files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    if (files.length) {
      handleClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadableFiles]);

  return (
    <>
      <div>
        <UploadField
          fileExtensions={fileExtensions}
          isSingleFile={isSingleFile}
          sizeLimit={sizeLimit}
          onFileSelected={setFiles}
          align={align}
          gradient={gradient}
          title={title}
          isLoading={isLoading}
        />
      </div>
      {info && (
        <>
          {files.length > 0 && (
            <div>
              {files.map((file: UploadableFile) => (
                <FileItem
                  isLoading={isLoading}
                  uploadProgress={uploadProgress}
                  key={file.id}
                  file={file.file}
                  onDelete={handleDelete}
                  errors={file.errors}
                />
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};
