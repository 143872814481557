import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

//  ui
import { Layout } from '../../../components/Layout';
import { ResearchFormCreateAndEdit } from '../../../components/pages/research/ResearchForm/ResearchFormCreateAndEdit';

export const ResearchCreatePage = () => {
  const [isMenu, setIsMenu] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [isSaveTemplate, setIsSaveTemplate] = useState(false);
  const [isDownloadTemplate, setIsDownloadTemplate] = useState(false);

  return (
    <Layout>
      <DndProvider backend={HTML5Backend}>
        <div>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*@ts-ignore*/}
          {/* !!!!!! TODO */}
          <ResearchFormCreateAndEdit
            isMenu={isMenu}
            setIsMenu={setIsMenu}
            isMenuOpen={isMenuOpen}
            setSaveTemplate={setIsSaveTemplate}
            saveTemplate={isSaveTemplate}
            setDownloadTemplate={setIsDownloadTemplate}
            downloadTemplate={isDownloadTemplate}
          />
        </div>
      </DndProvider>
    </Layout>
  );
};
