// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SelectType_selectType__SVbeZ {
  display: flex;
  flex-direction: column;
}
.SelectType_selectType__title__\\+X\\+lK {
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
}
.SelectType_selectType__item__G1VyL {
  margin-top: 12px;
}
.SelectType_selectType__item_title__sNFXv {
  display: flex;
  margin-left: 10px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/SurveyQuestion/SelectType/SelectType.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;AAEI;EACE,aAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AAAN","sourcesContent":[".selectType {\n  display: flex;\n  flex-direction: column;\n\n  &__title {\n    font-weight: 600;\n    font-size: 12px;\n    line-height: 22px;\n  }\n\n  &__item {\n    margin-top: 12px;\n\n    &_title {\n      display: flex;\n      margin-left: 10px;\n      align-items: center;\n      font-weight: 400;\n      font-size: 14px;\n      line-height: 22px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectType": `SelectType_selectType__SVbeZ`,
	"selectType__title": `SelectType_selectType__title__+X+lK`,
	"selectType__item": `SelectType_selectType__item__G1VyL`,
	"selectType__item_title": `SelectType_selectType__item_title__sNFXv`
};
export default ___CSS_LOADER_EXPORT___;
