import { Layout } from '../../components/Layout';
import { Typography } from '../../components/ui/Typography';
import { Button } from '../../components/ui/Button';
import { useNavigate } from 'react-router-dom';
import { frontendRoutes } from '../../utils/router/routes';
import styles from './ServerErrorPage.module.scss';
import image from '../../assets/images/500_page.svg';

export const ServerErrorPage = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Layout>
      <div className={styles.wrapper}>
        <div className={styles.wrapper__text_block}>
          <Typography
            className={styles.wrapper__number}
            font={'TTCommons'}
            size={'xxl'}
            fontWeight={500}
            customLineHeight={28}
            color={'gray5'}
          >
            500
          </Typography>
          <Typography
            className={styles.wrapper__header_text}
            // font={'SBSansDisplay'}
            size={'xxxxl'}
            fontWeight={600}
            customLineHeight={56}
            color={'totalBlack'}
          >
            Сервис недоступен
          </Typography>
          <Typography
            font={'SBSansText'}
            size={'m'}
            fontWeight={400}
            customLineHeight={24}
            color={'black'}
          >
            Мы знаем о проблеме и работаем над её решением.
            <br />
            Приносим извинения за неудобства.
          </Typography>
        </div>
        <div>
          <img src={image} alt="Lifebuoy custom image" />
        </div>
      </div>
    </Layout>
  );
};
