import { useNavigate } from 'react-router-dom';

//  ui
import { Layout } from '../../../components/Layout';
import { MaterialsDocs } from '../../../components/pages/materials/MaterialsDocs';

export const MaterialsDocsPage = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <MaterialsDocs />
    </Layout>
  );
};
