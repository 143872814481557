import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TableHeader } from '../../../../models/components/table';
import { materialDocsFiltersInitialValues } from '../../../../models/validations/schemas/validations';
import { frontendRoutes } from '../../../../utils/router/routes';

//  ui
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { HMenu } from '../../../ui/HMenu';
import { MaterialsDocsFilters } from './MaterialsDocsFilters';

import styles from './MaterialsDocs.module.scss';
import {
  useGetDictionaryQuery,
  useGetMyDocumentsQuery,
} from '../../../../store/VbudusheeAPI';
import { IFeedback } from '../../../../models/feedback';
import {
  formatDateStr,
  validateDateFromCalendar,
} from '../../../../utils/dates';
import { Loader } from '../../../ui/Loader';
import { TableExpanded } from '../../../ui/Table';
import { MyDocumentsParams } from '../../../../models/document';
import { Region } from '../../../../models/research';
import { Pagination } from '../../../ui/Pagination';
import { getAmount } from '../../../../utils/components/materialsCard';

export const MaterialsDocs = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<
    typeof materialDocsFiltersInitialValues
  >(materialDocsFiltersInitialValues);
  const paginationOptions = useMemo<MyDocumentsParams>(() => {
    return {
      document_name: filters.search || undefined,
      document_status: filters.status || undefined,
      document_type: filters.type || undefined,
      document_upload_date_start: filters.dates
        ? validateDateFromCalendar(filters.dates.split(';')[0])
        : undefined,
      document_upload_date_end: filters.dates
        ? validateDateFromCalendar(filters.dates.split(';')[1])
        : undefined,
      event_id: Number(filters.event_name) || undefined,
      document_tags: filters.tags.length
        ? filters.tags.map((tag: Region) => tag.region_number)
        : undefined,
      page,
    };
  }, [filters, page]);

  const { data, isFetching, isError } = useGetMyDocumentsQuery(
    paginationOptions,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: dictinary } = useGetDictionaryQuery({});

  const documentsData = data?.data || [];

  const totalEvents = data?.meta.total || 0;
  const eventsPerPage = data?.meta.per_page || 1;
  const totalPages = Math.ceil(totalEvents / eventsPerPage);

  const currentPage = data?.meta.current_page || 0;

  const handleResetPage = () => {
    setPage(1);
  };

  const headers: TableHeader[] = [
    { label: 'Название документа', width: '29%' },
    { label: 'Тип документа', width: '28%' },
    { label: 'Статус публикации', width: '22%' },
    { label: 'Загружено в рамках мероприятия', width: '21%' },
  ];

  const menuLinks = [
    {
      label: 'Мероприятия',
      onClick: () => navigate(frontendRoutes.materials.events),
      active: false,
      access: true,
    },
    {
      label: 'Исследования',
      onClick: () => navigate(frontendRoutes.materials.research),
      active: false,
      access: true,
    },
    {
      label: 'Документы',
      onClick: () => navigate(frontendRoutes.materials.docs),
      active: true,
      access: true,
    },
    {
      label: 'Обратная связь',
      onClick: () => navigate(frontendRoutes.materials.feedback),
      active: false,
      access: true,
    },
  ];

  return (
    <div className={styles.team}>
      <div className="local-container">
        <Breadcrumbs links={[{ title: 'Мои материалы' }]} homeLink="/" />
      </div>
      <div className={styles.team__header}>Мои материалы</div>
      <div className={styles.team__navigation}>
        <HMenu links={menuLinks} />
      </div>
      <div className={styles.team__filters}>
        <MaterialsDocsFilters
          documentStatus={dictinary?.documents.document_status || {}}
          documentTypes={data?.filters.document_type || {}}
          eventsNames={data?.filters.event_id || {}}
          setFilters={setFilters}
          handleResetPage={handleResetPage}
        />
      </div>
      <div className="container">
        <TableExpanded headers={headers}>
          {isFetching ? (
            <Loader.FullPage>
              <Loader />
            </Loader.FullPage>
          ) : isError || documentsData?.length < 1 ? (
            <TableExpanded.NoTable
              isError={isError}
              dataLength={documentsData.length}
            />
          ) : (
            <>
              {documentsData.map((document) => (
                <TableExpanded.Row key={document.document_id} headers={headers}>
                  <TableExpanded.Col>
                    <span
                      className="long-string"
                      title={document?.document_name}
                      onClick={() =>
                        navigate(
                          `${frontendRoutes.document.inner}/${document.document_id}`
                        )
                      }
                    >
                      {document?.document_name}
                    </span>
                  </TableExpanded.Col>
                  <TableExpanded.Col>
                    <span className="long-string">
                      {
                        dictinary?.documents.document_type[
                          document?.document_type
                        ]
                      }
                    </span>
                  </TableExpanded.Col>
                  <TableExpanded.Col>
                    <span className="long-string">
                      {
                        dictinary?.documents.document_status[
                          document?.document_status
                        ]
                      }
                    </span>
                  </TableExpanded.Col>
                  <TableExpanded.Col>
                    <span
                      className="long-string"
                      title={data?.filters.event_id[document?.event_id]}
                    >
                      {data?.filters.event_id[document?.event_id]}
                    </span>
                  </TableExpanded.Col>
                  <TableExpanded.Expanded
                    items={[
                      {
                        itemTitle: 'Тематические теги',
                        itemData: `${
                          document?.document_theme
                            ?.filter((_, i) => i < 3)
                            .join(', ') || 'Нет тегов'
                        } ${getAmount(
                          'тег',
                          document.document_theme.length - 2
                        )}`,
                      },
                    ]}
                  />
                </TableExpanded.Row>
              ))}
            </>
          )}
        </TableExpanded>
        <div className={styles.pagination}>
          {!isFetching && (
            <Pagination
              setPage={setPage}
              currentPage={currentPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>
    </div>
  );
};
