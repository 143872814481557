import { useNavigate } from 'react-router-dom';

import { frontendRoutes } from '../../../utils/router/routes';

//  ui
import { Button } from '../../ui/Button';
import { IconUser } from '../../ui/Icons/Form/IconUser';

import styles from './GuestBar.module.scss';

export const GuestBar = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.bar}>
      <div className={styles.login}>
        <Button
          label="Войти в систему"
          icon={<IconUser />}
          clicked={() => navigate(frontendRoutes.user.login)}
        />
      </div>
    </div>
  );
};
