import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TableHeader } from '../../../../models/components/table';
import { materialResearchFiltersInitialValues } from '../../../../models/validations/schemas/validations';
import { frontendRoutes } from '../../../../utils/router/routes';
import {
  useGetResearchesQuery,
  useGetDictionaryQuery,
  useGetRegionDataQuery,
} from '../../../../store/VbudusheeAPI';
import { Research, GetResearchesRequest } from '../../../../models/research';
import {
  formatDateStr,
  validateDateFromCalendar,
} from '../../../../utils/dates';

//  ui
import { Loader } from '../../../ui/Loader';
import { MaterialsResearchFilters } from './MaterialsResearchFilters';
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { HMenu } from '../../../ui/HMenu';
import { TableExpanded } from '../../../ui/Table';
import { Pagination } from '../../../ui/Pagination';

import styles from './MaterialsResearch.module.scss';
import { getAmount } from '../../../../utils/components/materialsCard';

export const MaterialsResearch = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [researchesData, setResearchesData] = useState<Research[] | never[]>(
    []
  );
  const [filters, setFilters] = useState<
    typeof materialResearchFiltersInitialValues
  >(materialResearchFiltersInitialValues);

  const paginationOptions: GetResearchesRequest = useMemo(() => {
    return {
      research_publication_status: filters.publication || undefined,
      research_name: filters.search || undefined,
      research_type: filters.type || undefined,
      research_start_date: filters.dates
        ? validateDateFromCalendar(filters.dates.split(';')[0])
        : undefined,
      research_end_date: filters.dates
        ? validateDateFromCalendar(filters.dates.split(';')[1])
        : undefined,
      research_status: filters.status || undefined,
      research_target: filters.target || undefined,
      research_region: filters.region.length
        ? filters.region.map((region) => region.region_number)
        : undefined,
      page,
    };
  }, [filters, page]);
  const { data, isFetching, isError } = useGetResearchesQuery(
    paginationOptions,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: dictionary } = useGetDictionaryQuery({});
  const { data: regions } = useGetRegionDataQuery({});

  const totalEvents = data?.meta.total || 0;
  const eventsPerPage = data?.meta.per_page || 1;
  const totalPages = Math.ceil(totalEvents / eventsPerPage);

  const currentPage = data?.meta.current_page || 1;

  const handleResetPage = () => {
    setPage(1);
  };

  useEffect(() => {
    if (data) {
      setResearchesData(data.data);
    }
  }, [data]);

  const headers: TableHeader[] = [
    { label: 'Название исследования', width: '21%' },
    { label: 'Номер этапа', width: '16%' },
    { label: 'Статус исследования', width: '21%' },
    { label: 'Тип исследования', width: '21%' },
    { label: 'Целевая аудитория', width: '21%' },
  ];

  const menuLinks = [
    {
      label: 'Мероприятия',
      onClick: () => navigate(frontendRoutes.materials.events),
      active: false,
      access: true,
    },
    {
      label: 'Исследования',
      onClick: () => navigate(frontendRoutes.materials.research),
      active: true,
      access: true,
    },
    {
      label: 'Документы',
      onClick: () => navigate(frontendRoutes.materials.docs),
      active: false,
      access: true,
    },
    {
      label: 'Обратная связь',
      onClick: () => navigate(frontendRoutes.materials.feedback),
      active: false,
      access: true,
    },
  ];

  return (
    <div className={styles.team}>
      <div className="local-container">
        <Breadcrumbs links={[{ title: 'Мои материалы' }]} homeLink="/" />
      </div>
      <div className={styles.team__header}>Мои материалы</div>
      <div className={styles.team__navigation}>
        <HMenu links={menuLinks} />
      </div>
      <div className={styles.team__filters}>
        <MaterialsResearchFilters
          dictionary={dictionary}
          regions={regions}
          setFilters={setFilters}
          handleResetPage={handleResetPage}
        />
      </div>
      <div className="container">
        <TableExpanded headers={headers}>
          {isFetching ? (
            <Loader.FullPage>
              <Loader />
            </Loader.FullPage>
          ) : isError || researchesData?.length < 1 ? (
            <TableExpanded.NoTable
              isError={isError}
              dataLength={researchesData.length}
            />
          ) : (
            <>
              {researchesData.map((research: Research) => (
                <TableExpanded.Row key={research.research_id} headers={headers}>
                  <TableExpanded.Col>
                    <span
                      className="long-string"
                      title={research?.research_name}
                      onClick={() =>
                        navigate(
                          `${frontendRoutes.research.details}/${research?.research_id}`
                        )
                      }
                    >
                      {research?.research_name}
                    </span>
                  </TableExpanded.Col>
                  <TableExpanded.Col>
                    <span className="long-string">
                      {research?.research_type === 'longitudinal'
                        ? research?.research_step
                        : '-'}
                    </span>
                  </TableExpanded.Col>
                  <TableExpanded.Col>
                    <span className="long-string">
                      {
                        dictionary?.research.research_status[
                          research?.research_status
                        ]
                      }
                    </span>
                  </TableExpanded.Col>
                  <TableExpanded.Col>
                    <span className="long-string">
                      {
                        dictionary?.research.research_type[
                          research?.research_type
                        ]
                      }
                    </span>
                  </TableExpanded.Col>
                  <TableExpanded.Col>
                    <span>
                      {
                        dictionary?.research.research_target[
                          research?.research_target
                        ]
                      }
                    </span>
                  </TableExpanded.Col>

                  <TableExpanded.Expanded
                    items={[
                      {
                        itemTitle: 'Регион',
                        itemData: `${
                          research.research_region[0]?.region_name ||
                          'Нет данных'
                        } ${getAmount(
                          'регион',
                          research.research_region.length
                        )}`,
                      },
                      {
                        itemTitle: 'Статус публикации',
                        itemData:
                          dictionary?.research.research_publication_status[
                            research?.research_publication_status
                          ] || 'Нет данных',
                      },
                      {
                        itemTitle: 'Сроки проведения',
                        itemData: `${formatDateStr(
                          research?.research_start_date,
                          {
                            isDateOnly: true,
                          }
                        )} - ${formatDateStr(research?.research_end_date, {
                          isDateOnly: true,
                        })}`,
                      },
                    ]}
                  />
                </TableExpanded.Row>
              ))}
            </>
          )}
        </TableExpanded>
        <div className={styles.pagination}>
          {!isFetching && (
            <Pagination
              setPage={setPage}
              currentPage={currentPage}
              totalPages={totalPages}
            />
          )}
        </div>
      </div>
    </div>
  );
};
