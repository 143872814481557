import React from 'react';

//ui
import { Layout } from '../../../components/Layout';
import { Questionnaire } from '../../../components/Survey/Questionnaire/Questionnaire';

export const QuestionnairePage = () => {
  return (
    <Layout>
      <div />
      {/*<Questionnaire />*/}
    </Layout>
  );
};
