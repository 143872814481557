import React, { useEffect, useState } from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './AccessesRolesForm.module.scss';
import {
  VbudusheeAPI,
  useChangeEventOwnerMutation,
  useChangeTeamAccessMutation,
  useCreateEventsInviteMutation,
  useDeactivateEventsInviteMutation,
  useEditRoleEventMutation,
  useGetEventQuery,
  useGetEventsInviteQuery,
  useDeleteEventMutation,
  useGetExpertDocumentsQuery,
  useGetMembersByEventQuery,
  useGetTeamsQuery,
  usePublishEventMutation,
} from '../../../../../store/VbudusheeAPI';

//  ui
import { InputText } from '../../../../ui/Form/InputText';
import { Select } from '../../../../ui/Form/Select';
import { Button } from '../../../../ui/Button';
import { UserInfo } from './UserInfo/UserInfo';
import { TeamInfo } from './TeamInfo/TeamInfo';
import { Typography } from '../../../../ui/Typography';
import {
  accessTeamEvent,
  researchEventRole,
} from '../../../../../models/validations/schemas/research';
import { ModalError } from '../../../../ui/ModalError';
import copyIcon from '../../../../../assets/images/copy_icon.svg';
import { ContentModal } from '../../../../ui/ContentModal';
import { SelectSearch } from '../../../../ui/Form/SelectSearch';
import { Box } from '../../../../ui/Box';
import { Opt } from '../../../../../models/components/form';
import { frontendRoutes } from '../../../../../utils/router/routes';
import { SelectOption } from '../../../../../models/basic';
import { PopupMessage } from '../../../../ui/PopupMessage';
import { CopyLink } from '../../../../ui/CopyLink/CopyLink';

type AccessesRolesFormProps = {
  data?: any;
  onClick: () => void;
};
export const AccessesRolesForm = (props: AccessesRolesFormProps) => {
  const { data, onClick } = props;
  const params = useParams();
  const [isOpenModalDeleteConfirmation, setIsOpenModalDeleteConfirmation] =
    useState(false);
  const [isOpenModalPublicConfirmation, setIsOpenModalPublicConfirmation] =
    useState(false);
  const [isDisableBtnDelete, setIsDisableBtnDelete] = useState<boolean>(false);
  const [deleteEvent] = useDeleteEventMutation();
  const [publicEvent] = usePublishEventMutation();
  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isCopyLink, setIsCopyLink] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const [addRole, { isLoading, isError, error }] = useEditRoleEventMutation();
  const [changeOwner] = useChangeEventOwnerMutation();
  const [createInvite] = useCreateEventsInviteMutation();
  const [changeAccess] = useChangeTeamAccessMutation();

  const { data: members } = useGetExpertDocumentsQuery({
    id: params.eventId || '',
  });

  const { data: inviteData, isError: isInviteError } = useGetEventsInviteQuery({
    id: params.eventId || '',
  });

  const [deleteInvite] = useDeactivateEventsInviteMutation();

  const destoryInviteHandler = async () => {
    deleteInvite({
      invite: inviteData?.invite || '',
    });
  };

  const API_URL = process.env.REACT_APP_API_URL?.slice(0, -3);

  const createInviteHandler = async () => {
    if (!inviteData?.invite || isInviteError) {
      // eslint-disable-next-line no-console
      const invite: any = await createInvite({
        id: params.eventId || '',
      });
      const el = document.createElement('input');
      el.value = `${API_URL}events/invite/activate/${invite.data.invite}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      showLinkCopiedTip();
    } else {
      const el = document.createElement('input');
      el.value = `${API_URL}events/invite/activate/${inviteData?.invite}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      showLinkCopiedTip();
    }
  };
  const showLinkCopiedTip = () => {
    setIsCopyLink(true);
    setTimeout(setIsCopyLink, 4000, false);
  };
  const deleteEventHandler = async (force = false) => {
    deleteEvent({ id: params.eventId });
    navigate(frontendRoutes.mainPage);
  };

  const publicHandler = () => {
    publicEvent({
      id: params.eventId || '',
    });
    navigate(frontendRoutes.materials.events);
  };

  const { data: rawTeamsList } = useGetTeamsQuery({});

  const [serverError, setServerError] = useState<string | undefined>();

  const handleSubmit = async (values: typeof accessesRolesInitialValues) => {
    //TODO
    // const result = await addRole(values);
  };

  const { data: eventData } = useGetEventQuery({
    id: `${params.eventId}`,
  });

  const startDate = new Date(`${eventData?.data.event_start_date}`);
  const nowDate = new Date();
  const endDate = new Date(`${eventData?.data.event_end_date}`);

  useEffect(() => {
    if (startDate < nowDate && endDate > nowDate) {
      setIsDisableBtnDelete(true);
    }
  }, [startDate, endDate]);

  const addUserRole = async (role = 'member', email?: string) => {
    const member = members?.members.find(
      (member: { email: string | undefined }) =>
        member.email === email || member.email === formik.values.user_login
    );

    const isRepeat = !!(
      member &&
      member.event_role === 'expert' &&
      role === 'member'
    );

    if ((member && member.event_role === role) || isRepeat) {
      return setServerError('Данный пользователь уже добавлен в мероприятие');
    }
    setServerError('');
    const arg = {
      event_id: params.eventId || '',
      data: {
        email: email || formik.values.user_login,
        role: role,
      },
    };
    const result = await addRole(arg);
    if ('data' in result) {
      // eslint-disable-next-line no-console
      console.log('Успех!');
    }

    formik.resetForm();
  };

  const handleTeamSubmit = () => {
    if (
      members?.teams.find((i) => +i.team_id === +formikTeam.values.access_team)
    ) {
      setServerError('Данная команда уже добавлена в мероприятие');
      formikTeam.values.access_team = '';
      formikTeam.validateForm();
      return;
    }
    changeAccess({
      id: params.eventId || '',
      data: {
        access: +!members?.teams.find(
          (i) => +i.team_id === +formikTeam.values.access_team
        ),
        team_id: formikTeam.values.access_team,
      },
    });
    formikTeam.values.access_team = '';
    formikTeam.validateForm();
  };

  const handleAccessSubmit = () => {
    changeOwner({
      id: params.eventId || '',
      data: {
        new_owner_email: formikAccess.values.email,
      },
    });
    navigate(frontendRoutes.materials.events);
  };

  const accessesRolesInitialValues = {
    user_login: '',
  };

  const formik = useFormik({
    initialValues: accessesRolesInitialValues,
    validationSchema: researchEventRole,
    onSubmit: handleSubmit,
  });

  const formikAccess = useFormik({
    initialValues: { email: '' },
    onSubmit: handleAccessSubmit,
  });

  const formikTeam = useFormik({
    initialValues: { access_team: '' },
    validationSchema: accessTeamEvent,
    onSubmit: handleTeamSubmit,
  });

  useEffect(() => {
    if (isError) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setServerError(
        'Нельзя добавить email незарегистрированного пользователя в мероприятие.'
      );
    }
  }, [isError]);

  return (
    <>
      <FormikContext.Provider value={formik}>
        <Form>
          <div className={styles.form}>
            <div className={styles.form__block}>
              <div className={styles.form__block_flex}>
                <div className={styles.option}>
                  <div className={styles.option__title}>
                    Совместный доступ к мероприятию
                  </div>
                  <div className={styles.copy_button}>
                    <Button
                      label="Копировать ссылку-приглашение"
                      clicked={createInviteHandler}
                      size="medium"
                      width={308}
                      gradient
                      withoutBackground={!inviteData?.invite || isInviteError}
                      type="button"
                    />
                    {inviteData?.invite && !isInviteError ? (
                      <div
                        style={{
                          cursor: 'pointer',
                          width: 40,
                          height: 40,
                          border: '1px solid #000',
                          borderRadius: '50%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={createInviteHandler}
                      >
                        <img src={copyIcon} alt="copy icon" />
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  {inviteData?.invite && !isInviteError ? (
                    <div
                      onClick={destoryInviteHandler}
                      className={styles.destroy_button}
                    >
                      Деактивировать ссылку
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className={styles.option}>
                  <div className={styles.option__title}>
                    Передать права на мероприятие
                  </div>
                  <div className={styles.copy_button}>
                    <Button
                      label="Выбрать пользователя"
                      clicked={() => setIsOpenModal(true)}
                      disabled={!members?.members.length}
                      size="medium"
                      width={308}
                      gradient
                      withoutBackground
                      type="button"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.form__block_divider} />
              <div className={styles.option}>
                <div className={styles.option__title}>Открыть доступ</div>
              </div>
              <div className={styles.setting}>
                <div className={styles.setting__item}>
                  <div className={styles.setting__item_select}>
                    <InputText
                      name="user_login"
                      placeholder="Пригласить по логину"
                      fullWidth
                    />
                  </div>
                  <div className={styles.setting__item_submit}>
                    <Button
                      label="Открыть доступ"
                      gradient
                      type="button"
                      size={'medium'}
                      disabled={!formik.isValid || !formik.dirty}
                      clicked={() => addUserRole()}
                    />
                  </div>

                  {members && members.members.length > 0 ? (
                    <>
                      {members.members.map((el, i) => (
                        <UserInfo
                          key={i}
                          email={el.email}
                          fullName={`${el.last_name || ''} ${
                            el.first_name || ''
                          } ${el.middle_name || ''}`}
                          userRole={el.event_role}
                          setEditExpert={addUserRole}
                        />
                      ))}
                    </>
                  ) : (
                    <Typography color="main" fontWeight={400} size="sm">
                      Нет пользователей
                    </Typography>
                  )}
                </div>

                <FormikContext.Provider value={formikTeam}>
                  <div className={styles.setting__item}>
                    <Form>
                      <div className={styles.setting__item_select}>
                        <Select
                          name="access_team"
                          label={
                            rawTeamsList && rawTeamsList.data.length
                              ? 'Доступ команде'
                              : 'Нет доступных команд'
                          }
                          options={
                            rawTeamsList
                              ? rawTeamsList.data.map((el) => ({
                                  key: el.team_id,
                                  value: el.team_name,
                                }))
                              : []
                          }
                          disabled={!(rawTeamsList && rawTeamsList.data.length)}
                          fullWidth
                        />
                      </div>

                      <div className={styles.setting__item_submit}>
                        <Button
                          label="Открыть доступ"
                          gradient
                          type="button"
                          size={'medium'}
                          clicked={handleTeamSubmit}
                          disabled={!formikTeam.isValid || !formikTeam.dirty}
                        />
                      </div>

                      {members?.teams.map((i) => (
                        <TeamInfo
                          deleteClicked={() =>
                            changeAccess({
                              id: params.eventId || '',
                              data: {
                                access: 0,
                                team_id: i.team_id,
                              },
                            })
                          }
                          teamName={i.team_name}
                          key={i.team_id}
                          teamInfo={''}
                        />
                      ))}
                    </Form>
                  </div>
                </FormikContext.Provider>
              </div>
            </div>
            <div className={styles.form__block_divider} />
            <div className={styles.form__block_button}>
              <Button
                label="Отправить запрос на публикацию мероприятия"
                gradient
                disabled={
                  eventData &&
                  eventData.data.event_publication_status !== 'new' &&
                  eventData.data.event_publication_status !== 'rework'
                }
                clicked={() => setIsOpenModalPublicConfirmation(true)}
              />
              <Button
                disabled={isDisableBtnDelete}
                label="Удалить мероприятие"
                color="gradientDanger"
                clicked={() => setIsOpenModalDeleteConfirmation(true)}
              />
            </div>
          </div>
        </Form>
      </FormikContext.Provider>
      <ContentModal
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
        titleText="Передача прав на мероприятие"
      >
        <FormikContext.Provider value={formikAccess}>
          <Form autoComplete="off">
            <div
              style={{ marginBottom: 24, marginTop: -6 }}
              className="divider"
            ></div>
            <div style={{ marginBottom: 33 }}>
              <SelectSearch
                name="email"
                placeholder="Выбор пользователя"
                options={
                  members?.members.map((i) => ({
                    option: i.email,
                    value: i.email,
                  })) as Opt[]
                }
                fullWidth
              />
            </div>
            <Box flex>
              <Button
                label="Добавить"
                gradient={true}
                disabled={!formikAccess.isValid || !formikAccess.dirty}
                clicked={() => {
                  setIsOpenModal(false);
                  setIsConfirmModalOpen(true);
                }}
              />
              <Box mr={16} />
              <Button
                label="Отмена"
                withoutBackground
                clicked={() => {
                  setIsOpenModal(false);
                  formikAccess.resetForm();
                }}
              />
            </Box>
          </Form>
        </FormikContext.Provider>
      </ContentModal>
      <ContentModal
        isOpen={isConfirmModalOpen}
        onClose={() => {
          setIsConfirmModalOpen(false);
        }}
        titleText="Подтверждение передачи прав"
      >
        <div>
          <div
            style={{
              width: 408,
              fontSize: 16,
              color: '#333F48',
              marginTop: -8,
              marginBottom: 32,
            }}
          >
            Вы уверены что хотите передать права на мероприятие пользователю{' '}
            <b>{formikAccess.values.email}</b>?
          </div>
          <Box flex>
            <Button
              label="Подтвердить"
              gradient={true}
              clicked={() => {
                handleAccessSubmit();
                setIsConfirmModalOpen(false);
              }}
            />
            <Box mr={16} />
            <Button
              label="Отмена"
              withoutBackground
              clicked={() => {
                setIsConfirmModalOpen(false);
                formikAccess.resetForm();
              }}
            />
          </Box>
        </div>
      </ContentModal>
      <ModalError
        isOpen={!!serverError}
        errorText={serverError}
        onClose={() => {
          setServerError('');
        }}
      />
      <ContentModal
        isOpen={isOpenModalPublicConfirmation}
        onClose={() => setIsOpenModalPublicConfirmation(false)}
        titleText="Запрос на публикацию"
      >
        <>
          <div>
            Вы уверены, что хотите отправить запрос на публикацию мероприятия?
            Редактирование мероприятия будет ограничено
          </div>
          <Box flex mt={32}>
            <Button
              label="Подтвердить"
              gradient={true}
              type="button"
              clicked={() => {
                setIsOpenModalPublicConfirmation(false);
                publicHandler();
              }}
            />
            <Box mr={16} />
            <Button
              label="Отменить"
              withoutBackground={true}
              clicked={() => {
                setIsOpenModalPublicConfirmation(false);
              }}
            />
          </Box>
        </>
      </ContentModal>
      <ContentModal
        isOpen={isOpenModalDeleteConfirmation}
        onClose={() => setIsOpenModalDeleteConfirmation(false)}
        titleText="Удаление мероприятия"
      >
        <>
          <div>Вы уверены, что хотите удалить мероприятие?</div>
          <Box flex mt={32}>
            <Button
              label="Подтвердить"
              gradient={true}
              type="button"
              clicked={() => {
                setIsOpenModalDeleteConfirmation(false);
                deleteEventHandler();
              }}
            />
            <Box mr={16} />
            <Button
              label="Отменить"
              withoutBackground={true}
              clicked={() => {
                setIsOpenModalDeleteConfirmation(false);
              }}
            />
          </Box>
        </>
      </ContentModal>
      <PopupMessage isOpen={isCopyLink}>
        <CopyLink />
      </PopupMessage>
    </>
  );
};
