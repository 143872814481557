import { useMemo, useState, useRef, useEffect } from 'react';
import { useField } from 'formik';
import classNames from 'classnames/bind';

import styles from './SelectSearch.module.scss';
import { Opt } from '../../../../models/components/form';
const cx = classNames.bind(styles);

type SelectSearchProps = {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  options: Opt[];
  fullWidth?: boolean;
  required?: boolean;
};

export const SelectSearch = ({
  name,
  placeholder,
  disabled,
  options,
  fullWidth,
  required = false,
}: SelectSearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [expanded, setExpanded] = useState(false);
  const [field, { error, touched, value }, { setValue, setTouched }] =
    useField<string>(name);

  const isInvalid = useMemo(() => {
    return Boolean(error && touched);
  }, [error, touched]);

  const rootEl = useRef<any>(null);

  const handleBlur = () => {
    setTouched(true);
  };

  const handleClick = (value: string) => {
    setValue(value, true);
    setExpanded(false);
  };

  const filtredOptions =
    field.value === ''
      ? options
      : options.filter((option) => {
          try {
            const searchExp = new RegExp(field.value, 'gmi');
            return option.option.search(searchExp) !== -1;
          } catch (e) {
            const searchExp = new RegExp('', 'gmi');
            return option.option.search(searchExp) !== -1;
          }
        });

  useEffect(() => {
    if (!expanded) {
      const res = options.find((option) => option.value === field.value);

      if (!res && options.length > 0) {
        setValue('');
      }
    }
    const clickOutsideElement = (e: any) => {
      // console.log(rootEl);
      if (!rootEl.current) return;
      if (!rootEl.current.contains(e.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener('mousedown', clickOutsideElement);

    return () => {
      document.removeEventListener('click', clickOutsideElement);
    };
  }, [expanded]);

  return (
    <div
      className={cx('input', {
        input_invalid: isInvalid,
        input_disabled: disabled,
        input_fullwidth: fullWidth,
      })}
      ref={rootEl}
      onFocus={() => setExpanded(true)}
    >
      <input
        {...field}
        placeholder={!required ? placeholder : ''}
        disabled={disabled}
        ref={inputRef}
        onBlur={handleBlur}
        value={
          options.find((option) => option.value === field.value)?.option ??
          field.value
        }
        title={
          options.find((option) => option.value === field.value)?.option ??
          field.value
        }
      />
      {!field.value && required && (
        <span className={styles.input__required}>
          {placeholder?.slice(0, placeholder.length - 1)}
          <span className={styles.input__required__sign}>
            {placeholder?.slice(placeholder.length - 1, placeholder.length)}
          </span>
        </span>
      )}
      {isInvalid && <div className={cx('input__error')}>{error}</div>}

      <div
        className={cx('select', {
          select_hidden: filtredOptions.length === 0 || !expanded,
        })}
      >
        {filtredOptions.map((option, index) => (
          <div
            className={styles.select__item}
            key={index}
            onClick={() => {
              handleClick(option.value);
            }}
          >
            <p className={styles.select__item__text} title={option.option}>
              {option.option}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
