export const IconClip = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.5876 1.5C11.0904 1.5 10.6306 1.67984 10.274 2.02405L3.42112 8.63878C2.86519 9.17539 2.55556 9.89141 2.55556 10.6518C2.55556 11.4122 2.8652 12.1282 3.42112 12.6648C3.97702 13.2014 4.71845 13.5 5.50551 13.5C6.29256 13.5 7.03399 13.2014 7.58989 12.6648L12.9644 7.47934C13.268 7.18636 13.7605 7.18623 14.0643 7.47904C14.3682 7.77185 14.3683 8.24672 14.0646 8.5397L8.69039 13.725C7.84488 14.5411 6.70844 15 5.50551 15C4.30257 15 3.16613 14.5411 2.32062 13.725C1.47515 12.9089 1 11.8123 1 10.6518C1 9.49135 1.47515 8.39475 2.32062 7.57865L9.17348 0.963927C9.82588 0.334188 10.6833 0 11.5876 0C12.495 0 13.3655 0.349839 14.0017 0.963924C14.6541 1.59363 15 2.42094 15 3.29309C15 4.16825 14.6379 5.00819 14.0017 5.62225L8.01159 11.4051C7.16609 12.2212 5.66258 12.4285 4.68527 11.5066L4.67916 11.5008C3.97812 10.8248 3.52588 9.35133 4.67919 8.2392C5.0615 7.87055 5.85056 7.10605 6.62611 6.35458L6.72375 6.25997C7.46404 5.54266 8.17579 4.85299 8.48707 4.55284C8.79081 4.25995 9.28327 4.25995 9.58701 4.55284C9.89075 4.84573 9.89075 5.32061 9.58701 5.6135C9.27728 5.91217 8.56696 6.60044 7.82563 7.31876L7.82545 7.31893L7.72871 7.41267C6.95394 8.16339 6.16314 8.92958 5.77914 9.29986C5.5694 9.50211 5.51688 9.71268 5.5339 9.90782C5.55289 10.1256 5.66114 10.3247 5.77609 10.4371C6.03308 10.6759 6.55278 10.6908 6.91108 10.3449L12.9009 4.5624C13.2457 4.22957 13.4444 3.77063 13.4444 3.29309C13.4444 2.81253 13.2578 2.36829 12.9012 2.02405C12.5564 1.69124 12.0816 1.5 11.5876 1.5Z"
        fill="#00809C"
      />
    </svg>
  );
};
