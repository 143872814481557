import React, { useEffect } from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import classNames from 'classnames/bind';

import { defaultFormikOptions } from '../../../../../models/formik';
import {
  publicEventsFiltersInitialValues,
  publicEventsFiltersValidationSchema,
} from '../../../../../models/validations/schemas/validations';
import { Roles, roles } from '../../../../../constants/roles';
import { OCCUPATION } from '../../../../../constants/user';

//  ui
import { Button } from '../../../../ui/Button';
import { Filters } from '../../../../ui/Filters';
import { InputText } from '../../../../ui/Form/InputText';
import { Select } from '../../../../ui/Form/Select';
import { InputDate } from '../../../../ui/Form/InputDate';

import styles from './PublicEventDocumentsFilterMember.module.scss';
import { useGetTagsQuery } from '../../../../../store/VbudusheeAPI';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { frontendRoutes } from '../../../../../utils/router/routes';
import { getTags } from '../../../../../store/tagsSlice';
import { SelectSearchRegions } from '../../../../ui/Form/SelectSearchRegions';
import { toKeyAndValue } from '../../../../../utils/array';
import { EVENT_STATUSES } from '../../../../../constants/events';
const cx = classNames.bind(styles);

type PublicEventsCatalogFiltersTypes = {
  setFilters?: React.Dispatch<
    React.SetStateAction<typeof publicEventsFiltersInitialValues>
  >;
};

export const PublicEventDocumentsFilterMember = ({
  setFilters,
}: PublicEventsCatalogFiltersTypes) => {
  const handleFiltersSubmit = () =>
    // values: typeof publicEventsFiltersInitialValues
    {
      // setFilters(values);
    };

  const { data: tagsData } = useGetTagsQuery({});
  const location = useLocation();
  const dispatch = useDispatch();
  const isPublicEventsCatalogPage =
    location.pathname === frontendRoutes.publicEventsCatalog.events;

  const tagsSelectOptions = tagsData
    ? tagsData.map((tags) => ({
        region_name: tags.name,
        region_number: tags.id.toString(),
      }))
    : [];

  useEffect(() => {
    if (tagsData) {
      dispatch(getTags(tagsData));
    }
  }, [isPublicEventsCatalogPage, dispatch, tagsData]);

  const initialValues = {
    search: '',
    typeDocument: '',
    author: '',
    prof: '',
    reg: '',
    puf: '',
    tags: '',
    organisatyon: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: publicEventsFiltersValidationSchema,
    onSubmit: handleFiltersSubmit,
    ...defaultFormikOptions,
  });

  const handleFiltersReset = () => {
    // setFilters(publicEventsFiltersInitialValues);
    formik.resetForm();
  };

  return (
    <div className={styles.filter}>
      <Filters>
        <FormikContext.Provider value={formik}>
          <Form>
            <div className={styles.form}>
              <div className={cx('form__item', 'form__item_search')}>
                <InputText
                  name="search"
                  placeholder="Поиск по названию"
                  fullWidth={true}
                />
              </div>
              <div className={cx('optionsContainer')}>
                <Select
                  name="typeDocument"
                  fullWidth
                  label="Профессиональная категория автора"
                  options={[
                    { value: roles.owner, key: Roles.OWNER },
                    { value: roles.expert, key: Roles.EXPERT },
                    { value: roles.member, key: Roles.MEMBER },
                  ]}
                />
                <Select
                  name="organisatyon"
                  fullWidth
                  label="Автор документа"
                  options={[
                    { value: roles.owner, key: Roles.OWNER },
                    { value: roles.expert, key: Roles.EXPERT },
                    { value: roles.member, key: Roles.MEMBER },
                  ]}
                />
                <Select
                  name="author"
                  fullWidth
                  label="Организация автора документа"
                  options={[
                    { value: roles.owner, key: Roles.OWNER },
                    { value: roles.expert, key: Roles.EXPERT },
                    { value: roles.member, key: Roles.MEMBER },
                  ]}
                />

                <Select
                  name="prof"
                  fullWidth
                  label="Тип документа"
                  options={[
                    { value: roles.owner, key: Roles.OWNER },
                    { value: roles.expert, key: Roles.EXPERT },
                    { value: roles.member, key: Roles.MEMBER },
                  ]}
                />
                <Select
                  name="tags"
                  fullWidth
                  label="Тематические теги"
                  options={[
                    { value: roles.owner, key: Roles.OWNER },
                    { value: roles.expert, key: Roles.EXPERT },
                    { value: roles.member, key: Roles.MEMBER },
                  ]}
                />
                <Select
                  name="reg"
                  fullWidth
                  label="Статус документа"
                  options={[
                    { value: roles.owner, key: Roles.OWNER },
                    { value: roles.expert, key: Roles.EXPERT },
                    { value: roles.member, key: Roles.MEMBER },
                  ]}
                />
                <InputDate
                  isRange
                  name="dates"
                  fullWidth
                  placeholder="Дата загрузки"
                  align="right"
                />
                <InputText
                  name="puf"
                  placeholder="Поиск по названию"
                  fullWidth={true}
                />
              </div>
            </div>
            <div className={styles.buttons}>
              <div onClick={handleFiltersReset}>
                <Button label="Сбросить" withoutBackground={true} />
              </div>
              <div onClick={() => formik.handleSubmit()}>
                <Button label="Применить" gradient={true} />
              </div>
            </div>
          </Form>
        </FormikContext.Provider>
      </Filters>
    </div>
  );
};
