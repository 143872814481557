// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResearchTeamCard_card_wraper__name__kWK6u {
  font: "SB Sans Text";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #808185;
}
.ResearchTeamCard_card_wraper__description__5X00w {
  font: "SB Sans Text";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}
.ResearchTeamCard_card_wraper__link__McuFf {
  font: "SB Sans Display";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #EC1B28;
}
.ResearchTeamCard_card_wraper__link__McuFf:hover {
  text-decoration: none;
  color: #EC1B28;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/research/ResearchForm/ResearchEditPermissions/ResearchTeamCard/ResearchTeamCard.module.scss"],"names":[],"mappings":"AAEC;EACC,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AADF;AAIC;EACC,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAFF;AAKC;EACC,uBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AAHF;AAKE;EACC,qBAAA;EACA,cAAA;AAHH","sourcesContent":[".card_wraper {\n\n\t&__name {\n\t\tfont: 'SB Sans Text';\n\t\tfont-style: normal;\n\t\tfont-weight: 400;\n\t\tfont-size: 12px;\n\t\tline-height: 18px;\n\t\tcolor: #808185;\n\t}\n\n\t&__description {\n\t\tfont: 'SB Sans Text';\n\t\tfont-style: normal;\n\t\tfont-weight: 400;\n\t\tfont-size: 14px;\n\t\tline-height: 22px;\n\t\tcolor: #000000;\n\t}\n\n\t&__link {\n\t\tfont: 'SB Sans Display';\n\t\tfont-style: normal;\n\t\tfont-weight: 400;\n\t\tfont-size: 12px;\n\t\tline-height: 26px;\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tcolor: #EC1B28;\n\n\t\t&:hover {\n\t\t\ttext-decoration: none;\n\t\t\tcolor: #EC1B28;\n\t\t}\n\t}\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card_wraper__name": `ResearchTeamCard_card_wraper__name__kWK6u`,
	"card_wraper__description": `ResearchTeamCard_card_wraper__description__5X00w`,
	"card_wraper__link": `ResearchTeamCard_card_wraper__link__McuFf`
};
export default ___CSS_LOADER_EXPORT___;
