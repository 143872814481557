import React from 'react';

import styles from './About.module.scss';

//image
import ImageOne from '../../../../../assets/images/MainPage/about/ImageOne.png';
import ImageTwo from '../../../../../assets/images/MainPage/about/ImageTwo.png';
import ImageFree from '../../../../../assets/images/MainPage/about/ImageFree.png';

export const About = () => {
  return (
    <section className={styles.about}>
      <div className={styles.aboutBlock}>
        <img
          className={styles.aboutBlock__imageOne}
          src={ImageOne}
          alt="about-block"
        />
        <div className={styles.aboutBlock__title}>Что</div>
        <div className={styles.aboutBlock__description}>
          «Конструктор исследовательских сервисов» включает в себя
          инструментарий, позволяющий проводить сбор, автоматическую обработку,
          анализ и визуализацию количественных и качественных данных о развитии
          личностного потенциала и формировании личностно-развивающей
          образовательной среды.
        </div>
      </div>

      <div className={styles.aboutBlock}>
        <img
          className={styles.aboutBlock__imageTwo}
          src={ImageTwo}
          alt="about-block"
        />
        <div className={styles.aboutBlock__title}>Для кого</div>
        <div className={styles.aboutBlock__description}>
          Сервис предназначен для использования отдельными представителями или
          командами от образовательных организаций, региональных и всероссийских
          проектов развития образования, НКО, вузов и научных институтов.
        </div>
      </div>

      <div className={styles.aboutBlock}>
        <img
          className={styles.aboutBlock__imageFree}
          src={ImageFree}
          alt="about-block"
        />
        <div className={styles.aboutBlock__title}>Зачем</div>
        <div className={styles.aboutBlock__description}>
          Сервис помогает в реализации доказательного подхода в образовании:
          проектировании и проведении самостоятельных психолого-педагогических
          исследований в сфере личностного развития и воспитания и принятии
          решений с опорой на объективные данные, а также обратную связь от
          экспертов.
        </div>
      </div>
    </section>
  );
};
