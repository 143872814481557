import { Layout } from '../../../components/Layout';
import { Teams } from '../../../components/Profile/Teams';

export const TeamsPage = () => {
  return (
    <Layout>
      <Teams />
    </Layout>
  );
};
