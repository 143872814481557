import { ToastAction } from '../models/components/toast';
import { useTypedSelector } from '../store';
import { getToast } from '../store/toast/selectors';
import { useDispatch } from 'react-redux';
import { showToast } from '../store/toast';

type ToastParamsEvent = {
  type: 'success' | 'danger' | 'warning';
  name?: string;
  title?: string;
  description?: string;
  maxCount?: number;
  position?: 'top-center' | 'bottom-right';
  timer?: number;
  autocorrect?: boolean;
};

export const useToasts = () => {
  const dispatch = useDispatch();
  const onShowToast = ({
    type,
    name,
    maxCount,
    position,
    title,
    description = 'This is a success toast component',
    timer,
    autocorrect,
  }: ToastParamsEvent) => {
    const toastProperties = {
      name,
      type,
      maxCount,
      title,
      description,
      position,
      timer,
      autocorrect,
    };

    if (toastProperties) {
      dispatch(showToast(toastProperties));
    }
  };
  return { onShowToast };
};
