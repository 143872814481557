export const IconPng = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7365 2.76172H8.08449C6.02449 2.76172 4.25049 4.43072 4.25049 6.49072V17.3397C4.25049 19.5157 5.90849 21.1147 8.08449 21.1147H16.0725C18.1325 21.1147 19.8025 19.3997 19.8025 17.3397V8.03772L14.7365 2.76172Z"
        stroke="#16A086"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4739 2.75V5.659C14.4739 7.079 15.6229 8.231 17.0429 8.234C18.3589 8.237 19.7059 8.238 19.7969 8.232"
        stroke="#16A086"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="12" y="11" width="13" height="7" fill="white" />
      <path
        d="M8 11.7196C8.176 11.7143 8.33333 11.7089 8.472 11.7036C8.616 11.6929 8.76 11.6849 8.904 11.6796C9.048 11.6743 9.19733 11.6716 9.352 11.6716C9.512 11.6663 9.696 11.6636 9.904 11.6636C10.3467 11.6636 10.72 11.7009 11.024 11.7756C11.3333 11.8449 11.584 11.9569 11.776 12.1116C11.968 12.2663 12.1067 12.4689 12.192 12.7196C12.2827 12.9649 12.328 13.2663 12.328 13.6236C12.328 14.3489 12.1333 14.8823 11.744 15.2236C11.3547 15.5649 10.736 15.7356 9.888 15.7356C9.78133 15.7356 9.68533 15.7356 9.6 15.7356C9.51467 15.7303 9.41867 15.7223 9.312 15.7116V17.3036H8V11.7196ZM9.312 14.6316C9.41867 14.6423 9.512 14.6503 9.592 14.6556C9.67733 14.6556 9.776 14.6556 9.888 14.6556C10.2933 14.6556 10.576 14.5756 10.736 14.4156C10.896 14.2556 10.976 14.0129 10.976 13.6876C10.976 13.5276 10.96 13.3889 10.928 13.2716C10.896 13.1543 10.8373 13.0556 10.752 12.9756C10.6667 12.8956 10.552 12.8369 10.408 12.7996C10.2693 12.7623 10.0933 12.7436 9.88 12.7436C9.77333 12.7436 9.67733 12.7463 9.592 12.7516C9.512 12.7516 9.41867 12.7569 9.312 12.7676V14.6316Z"
        fill="#16A086"
      />
      <path
        d="M13.4141 11.7036H14.8301L16.6781 14.6316C16.7634 14.7703 16.8407 14.9089 16.9101 15.0476C16.9794 15.1863 17.0487 15.3196 17.1181 15.4476C17.1074 15.3089 17.0967 15.1623 17.0861 15.0076C17.0807 14.8529 17.0781 14.6983 17.0781 14.5436V11.7036H18.3581V17.3036H16.9901L15.1261 14.3436C15.0407 14.2156 14.9581 14.0716 14.8781 13.9116C14.7981 13.7516 14.7207 13.5996 14.6461 13.4556C14.6567 13.5943 14.6647 13.7409 14.6701 13.8956C14.6807 14.0449 14.6861 14.1969 14.6861 14.3516V17.3036H13.4141V11.7036Z"
        fill="#16A086"
      />
      <path
        d="M22.2045 14.1436H24.3645V16.9836C24.2738 17.0316 24.1618 17.0796 24.0285 17.1276C23.8952 17.1756 23.7405 17.2209 23.5645 17.2636C23.3938 17.3063 23.2018 17.3409 22.9885 17.3676C22.7805 17.3943 22.5592 17.4076 22.3245 17.4076C21.3485 17.4076 20.6312 17.1809 20.1725 16.7276C19.7138 16.2743 19.4845 15.5489 19.4845 14.5516C19.4845 14.0503 19.5378 13.6156 19.6445 13.2476C19.7565 12.8743 19.9298 12.5649 20.1645 12.3196C20.4045 12.0743 20.7112 11.8929 21.0845 11.7756C21.4632 11.6583 21.9192 11.5996 22.4525 11.5996C22.8472 11.5996 23.1698 11.6263 23.4205 11.6796C23.6712 11.7329 23.8605 11.7889 23.9885 11.8476V13.1036C23.7965 12.9863 23.5805 12.8956 23.3405 12.8316C23.1005 12.7623 22.8338 12.7276 22.5405 12.7276C22.2312 12.7276 21.9672 12.7623 21.7485 12.8316C21.5352 12.8956 21.3592 12.9969 21.2205 13.1356C21.0872 13.2743 20.9885 13.4556 20.9245 13.6796C20.8658 13.9036 20.8365 14.1703 20.8365 14.4796C20.8365 15.1303 20.9645 15.5943 21.2205 15.8716C21.4765 16.1436 21.8792 16.2796 22.4285 16.2796C22.6098 16.2796 22.7618 16.2689 22.8845 16.2476C23.0125 16.2263 23.1245 16.2023 23.2205 16.1756V15.1996H22.2045V14.1436Z"
        fill="#16A086"
      />
    </svg>
  );
};
