export const getAmount = (word: string, amount?: number): string => {
  if (!amount || amount < 2) {
    return '';
  }

  const reducedAmount = amount - 1;

  if (reducedAmount === 1) {
    return `+ ${reducedAmount} ${word}`;
  } else if (reducedAmount > 10 && reducedAmount < 20) {
    return `+ ${reducedAmount} ${word}ов`;
  } else if (
    [2, 3, 4].includes(
      Number(reducedAmount.toString()[reducedAmount.toString().length - 1])
    )
  ) {
    return `+ ${reducedAmount} ${word}а`;
  } else {
    return `+ ${reducedAmount} ${word}ов`;
  }
};

export const getAmountWord = (word: string, amount?: number): string => {
  if (amount === 0) {
    return `${word}ов`;
  }

  if (!amount) {
    return '';
  }

  if (amount === 1) {
    return `${word}`;
  } else if (amount > 10 && amount < 20) {
    return `${word}ов`;
  } else if (
    [2, 3, 4].includes(Number(amount.toString()[amount.toString().length - 1]))
  ) {
    return `${word}а`;
  } else {
    return `${word}ов`;
  }
};
