import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Form, FormikContext, useFormik } from 'formik';

import { VALIDATION_ERRORS } from '../../../../models/validations/errors/errors';
import {
  confirmEmailInitialValues,
  confirmEmailValidationSchema,
} from '../../../../models/validations/schemas/validations';

import { useUserChangeEmailMutation } from '../../../../store/VbudusheeAPI';
import { frontendRoutes } from '../../../../utils/router/routes';

//  ui
import { Button } from '../../../ui/Button';
import { InputText } from '../../../ui/Form/InputText';

import styles from './ConfirmEmailForm.module.scss';

const cx = classNames.bind(styles);

export type ConfirmEmailForm = {
  password: string;
};

export const ConfirmEmailForm = (props: ConfirmEmailForm) => {
  const { password } = props;
  const [userChangePassword, { isLoading, error, isSuccess }] =
    useUserChangeEmailMutation();
  const [serverError, setServerError] = useState<string | undefined>();
  const massageEmailReserve = 'The email has already been taken.';
  const navigate = useNavigate();

  const handleSubmit = async (values: typeof confirmEmailInitialValues) => {
    await userChangePassword({
      password: password,
      email: values.email,
      email_confirmation: values.email_confirmation,
    });
  };
  const formik = useFormik({
    initialValues: confirmEmailInitialValues,
    validationSchema: confirmEmailValidationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (isSuccess) {
      navigate(frontendRoutes.user.profile.index);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error && 'data' in error) {
      const serverError = error.data as { message: string };
      if (serverError.message === massageEmailReserve) {
        setServerError(VALIDATION_ERRORS.email.isEmailReserve);
      } else {
        setServerError(VALIDATION_ERRORS.server.error);
      }
    }
  }, [error]);

  useEffect(() => {
    if (serverError) {
      formik.setErrors({ email_confirmation: serverError });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverError]);

  return (
    <FormikContext.Provider value={formik}>
      <Form>
        <div className={styles.form}>
          <InputText name="email" placeholder="Email" modifiedClassName />
          <InputText name="email_confirmation" placeholder="Подтверждение" />
          <div className={styles.nav}>
            <div className={styles.form__submit}>
              <Button
                label="Подтвердить"
                type="submit"
                gradient={true}
                size={'large'}
              />
            </div>
          </div>
        </div>
      </Form>
    </FormikContext.Provider>
  );
};
