import React, { useState } from 'react';
import styles from '../RollMenu.module.scss';
import { useNavigate } from 'react-router-dom';
import { Condition, ItemSubMenu } from '../../../../constants/header/constants';

import classNames from 'classnames/bind';
import { useGetCurrentUserQuery } from '../../../../store/VbudusheeAPI';

const cx = classNames.bind(styles);

type ItemMenu = {
  url: string;
  title: string;
  disabled?: boolean;
  submenu?: ItemSubMenu[] | null;
  isAuthorized?: boolean;
  condition?: Condition;
};

export const ItemMenu = (props: ItemMenu) => {
  const { url, title, submenu, disabled, isAuthorized, condition } = props;

  const [isSelectActive, setIsSelectActive] = useState<boolean>(false);
  const navigate = useNavigate();
  const { data: userData } = useGetCurrentUserQuery({}, {});

  const onSelectOver = () => {
    setIsSelectActive(true);
  };
  const onSelectLeave = (): void => {
    setIsSelectActive(false);
  };

  if (condition) {
    const keys = condition[0].split('.');
    switch (keys[0]) {
      case 'user':
        // eslint-disable-next-line no-case-declarations
        let data: any = userData;
        for (const key of keys) {
          data = data?.[key];
        }
        if (data !== condition[1]) {
          return <></>;
        }
        break;

      default:
        break;
    }
  }

  return (
    <li
      className={cx('droplist__item', {
        droplist__item_disabled:
          ((title === 'Новое мероприятие' ||
            title === 'Новое исследование' ||
            title === 'Анализ результатов') &&
            !isAuthorized) ||
          disabled,
      })}
      onClick={() => navigate(url)}
    >
      {title}
    </li>
  );
};
