import React from 'react';
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import styles from './Login.module.scss';
import { LoginForm } from './LoginForm';

export const Login = () => {
  return (
    <div className={styles.login}>
      <div className="outer-container">
        <Breadcrumbs links={[{ title: 'Вход' }]} homeLink="/" />
      </div>
      <span className={styles.login__header}>Вход</span>
      <div className={styles.login__form}>
        <LoginForm />
      </div>
    </div>
  );
};
