// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UploadField_wrapper__OtiV5 {
  width: 100%;
}
.UploadField_wrapper__OtiV5 input {
  width: 0;
  height: 0;
  visibility: hidden;
  position: absolute;
}
.UploadField_wrapper__OtiV5 .UploadField_btn__o\\+vIZ {
  width: 100%;
  display: flex;
}
.UploadField_wrapper__OtiV5 .UploadField_btn_left__IMCT7 {
  justify-content: start;
}
.UploadField_wrapper__OtiV5 .UploadField_btn_center__Zdij- {
  justify-content: center;
}
.UploadField_wrapper__OtiV5 .UploadField_btn_right__K-wO- {
  justify-content: end;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/UploadFile/UploadField/UploadField.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,QAAA;EACA,SAAA;EACA,kBAAA;EACA,kBAAA;AACJ;AAEE;EACE,WAAA;EACA,aAAA;AAAJ;AAEI;EACE,sBAAA;AAAN;AAGI;EACE,uBAAA;AADN;AAII;EACE,oBAAA;AAFN","sourcesContent":[".wrapper {\n  width: 100%;\n\n  input {\n    width: 0;\n    height: 0;\n    visibility: hidden;\n    position: absolute;\n  }\n\n  .btn {\n    width: 100%;\n    display: flex;\n\n    &_left {\n      justify-content: start;\n    }\n\n    &_center {\n      justify-content: center;\n    }\n\n    &_right {\n      justify-content: end;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `UploadField_wrapper__OtiV5`,
	"btn": `UploadField_btn__o+vIZ`,
	"btn_left": `UploadField_btn_left__IMCT7`,
	"btn_center": `UploadField_btn_center__Zdij-`,
	"btn_right": `UploadField_btn_right__K-wO-`
};
export default ___CSS_LOADER_EXPORT___;
