import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { AppState } from '../types';

const selectSelf = (state: AppState) => state.uploadDoc;

export const uploadDocument = createDraftSafeSelector(
  selectSelf,
  (store) => store.document.parts
);
