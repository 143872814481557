import React from 'react';
import { Breadcrumbs } from '../../../ui/Breadcrumbs';
import { RestoreForm } from './RestoreForm';
import styles from './RestorePasswordAccept.module.scss';
import { frontendRoutes } from '../../../../utils/router/routes';

type RestorePasswordAcceptProps = {
  token: string;
};

export const RestorePasswordAccept = ({
  token,
}: RestorePasswordAcceptProps) => {
  return (
    <div className={styles.restore}>
      <div className="outer-container">
        <Breadcrumbs
          links={[{ title: 'Создание нового пароля' }]}
          homeLink={frontendRoutes.mainPage}
        />
      </div>
      <span className={styles.restore__header}>Создание нового пароля</span>
      <div className={styles.restore__form}>
        <RestoreForm token={token} />
      </div>
    </div>
  );
};
