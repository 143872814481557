import {
  ResearchResultReg,
  ResearchResultRCity,
} from '../../../../models/interactiveMap';

//  ui
import { Typography } from '../../Typography';

import styles from './HorizontalBarChart.module.scss';

type HorizontalBarChartProps = {
  // TODO: replace any with proper server data type
  data: ResearchResultReg;
};

export const HorizontalBarChart = ({
  data,
}: HorizontalBarChartProps): JSX.Element => {
  const MAX = 20;
  const ONE_PERCENT = MAX / 100;
  return (
    <>
      {data?.data.length > 0 && (
        <div className={styles.horChart}>
          <div className={styles.left}>
            {data?.data.map((org: ResearchResultRCity) => (
              <div key={org.organization} className={styles.row}>
                <Typography size="s" color="main" className="long-string">
                  {org?.organization || 'Нет данных'}
                </Typography>
                <Typography size="xs" color="gray">
                  н/д
                  {/* {org?.hours ? `${org?.hours}ч.` : 'Нет данных'} */}
                </Typography>
              </div>
            ))}
          </div>
          <div className={styles.right}>
            <div className={styles.chart}>
              <div className={styles.chartAxis}>
                <div className={styles.chartAxisLine}></div>
                <div className={styles.chartAxisLine}>
                  <div className={styles.chartAxisLineLegend}>4</div>
                </div>
                <div className={styles.chartAxisLine}>
                  <div className={styles.chartAxisLineLegend}>7</div>
                </div>
                <div className={styles.chartAxisLine}>
                  <div className={styles.chartAxisLineLegend}>10</div>
                </div>
                <div className={styles.chartAxisLine}>
                  <div className={styles.chartAxisLineLegend}>13</div>
                </div>
                <div className={styles.chartAxisLine}>
                  <div className={styles.chartAxisLineLegend}>16</div>
                </div>
              </div>
              {/* data bars */}
              <>
                {data?.data.length > 1 ? (
                  <>
                    {data?.data.map((el: ResearchResultRCity, i: number) => {
                      // TODO: remove when max value for these kinds of chart will be known
                      // For now I assume it is 20
                      // const percent = el.value / ONE_PERCENT;
                      // const percent = 10;
                      return (
                        <div key={el.organization}>
                          {/* remove first margin row because of adding it with axis */}
                          {i !== 0 && (
                            <div className={styles.chartMargin}>
                              <div className={styles.chartMarginCell}></div>
                              <div className={styles.chartMarginCell}></div>
                              <div className={styles.chartMarginCell}></div>
                              <div className={styles.chartMarginCell}></div>
                              <div className={styles.chartMarginCell}></div>
                              <div className={styles.chartMarginCell}></div>
                            </div>
                          )}
                          <div className={styles.chartItem}>
                            <div className={styles.chartItemColor1}></div>
                            <div className={styles.chartItemColor2}></div>
                            <div className={styles.chartItemColor3}></div>
                            <div className={styles.chartItemColor4}></div>
                            <div className={styles.chartItemColor5}></div>
                            <div className={styles.chartItemColor6}></div>
                            <div
                              className={styles.chartItemSpaceLeft}
                              style={{
                                width: `${100 - parseInt(el.proportion)}%`,
                              }}
                            ></div>
                            <div className={styles.chartItemValue}>
                              <Typography size="s" color="gray">
                                {`${parseInt(el.proportion)}%`}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  // single data item
                  <>
                    {data?.data.map((el: ResearchResultRCity) => {
                      return (
                        <div key={el.organization} className={styles.chartItem}>
                          <div className={styles.chartItemColor1}></div>
                          <div className={styles.chartItemColor2}></div>
                          <div className={styles.chartItemColor3}></div>
                          <div className={styles.chartItemColor4}></div>
                          <div className={styles.chartItemColor5}></div>
                          <div className={styles.chartItemColor6}></div>
                          <div
                            className={styles.chartItemSpaceLeft}
                            style={{
                              width: `${100 - parseInt(el.proportion)}%`,
                            }}
                          ></div>
                          <div className={styles.chartItemValue}>
                            <Typography size="s" color="gray">
                              {`${parseInt(el.proportion)}%`}
                            </Typography>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
