import React from 'react';
import styles from './MainPage.module.scss';

//ui
import { Header } from './Header/Header';
import { Content } from './Content/Content';

export const MainPage = () => {
  return (
    <div className={styles.mainPage}>
      <Header />
      <Content />
    </div>
  );
};
