//  ui
import { Typography } from '../../../ui/Typography';
import { Box } from '../../../ui/Box';

import styles from './DocumentSettingsBlock.module.scss';

export type DocumentSettingsBlockProps = {
  withRow?: boolean;
  title: string;
  height?: number;
  stepNumber: number;
  children: JSX.Element;
};

export const DocumentSettingsBlock = ({
  withRow = false,
  title,
  height,
  stepNumber,
  children,
}: DocumentSettingsBlockProps): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Box mb={12}>
          <Typography color="main" size="sm" fontWeight={700}>
            {title}
          </Typography>
        </Box>
        <Box mt={6}>
          <Typography
            className={styles.steps}
            size="xs"
            fontWeight={700}
            upperCase
          >{`Шаг ${stepNumber}`}</Typography>
        </Box>
      </div>
      <div className={styles.body} style={{ height: height ? height : 'auto' }}>
        {withRow ? (
          <div className={styles.body__wrapper}>{children}</div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};
