import { Layout } from '../../../../../components/Layout';
import { ChangeEmail } from '../../../../../components/Profile/ChangeEmail/ChangeEmail';

export const ChangeEmailPage = () => {
  return (
    <Layout>
      <ChangeEmail />
    </Layout>
  );
};
