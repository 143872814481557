// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CodeConfirm_confirmWrapper__Bexne {
  max-width: 330px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-direction: column;
  text-align: center;
  align-items: center;
  row-gap: 24px;
  margin-top: 96px;
}
.CodeConfirm_confirmWrapper__Bexne input {
  width: 330px;
}

.CodeConfirm_resend_code__8pt5v {
  color: #00809c;
  cursor: pointer;
}
.CodeConfirm_resend_code__8pt5v.CodeConfirm_disabled__JyHm3 {
  opacity: 0.4;
}

.CodeConfirm_confirmTitle__ZzbVA {
  font-size: 32px;
  font-weight: 600;
  line-height: 41px;
}

.CodeConfirm_preTitle__kMtSq {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/auth/CodeConfirm/CodeConfirm.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,cAAA;EACA,sBAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,gBAAA;AACF;AACE;EACE,YAAA;AACJ;;AAGA;EACE,cAAA;EACA,eAAA;AAAF;AAEE;EACE,YAAA;AAAJ;;AAIA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AADF;;AAIA;EACE,eAAA;EACA,iBAAA;EACA,kBAAA;AADF","sourcesContent":[".confirmWrapper {\n  max-width: 330px;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  margin: 0 auto;\n  flex-direction: column;\n  text-align: center;\n  align-items: center;\n  row-gap: 24px;\n  margin-top: 96px;\n\n  input {\n    width: 330px;\n  }\n}\n\n.resend_code {\n  color: #00809c;\n  cursor: pointer;\n\n  &.disabled {\n    opacity: 0.4;\n  }\n}\n\n.confirmTitle {\n  font-size: 32px;\n  font-weight: 600;\n  line-height: 41px;\n}\n\n.preTitle {\n  font-size: 14px;\n  line-height: 20px;\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmWrapper": `CodeConfirm_confirmWrapper__Bexne`,
	"resend_code": `CodeConfirm_resend_code__8pt5v`,
	"disabled": `CodeConfirm_disabled__JyHm3`,
	"confirmTitle": `CodeConfirm_confirmTitle__ZzbVA`,
	"preTitle": `CodeConfirm_preTitle__kMtSq`
};
export default ___CSS_LOADER_EXPORT___;
