import { useNavigate, useParams } from 'react-router-dom';

import { useGetEventQuery } from '../../../store/VbudusheeAPI';
import { useAuthorized } from '../../../hooks/useAuthorized';

//  ui
import { Layout } from '../../../components/Layout';
import { Loader } from '../../../components/ui/Loader';
import { PublicEventContainer } from '../../../components/pages/publicEventsCatalog/PublicEventContainer';
import {
  CreateEventsResponse,
  PublicEventDetails,
} from '../../../models/pages/events';
import { useEffect, useState } from 'react';

export const PublicEventPage = () => {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const { isAuthorized } = useAuthorized();
  const [events, setEvents] = useState<PublicEventDetails | undefined>(
    undefined
  );
  const { data, isLoading, isError } = useGetEventQuery({ id: eventId ?? '' });
  useEffect(() => {
    if (data) {
      setEvents(data.data);
    }
  }, [data]);

  return (
    <Layout>
      {isLoading ? (
        <Loader.FullPage>
          <Loader />
        </Loader.FullPage>
      ) : (
        <PublicEventContainer
          event={events}
          isAuthorized={isAuthorized}
          eventId={eventId}
        />
      )}
    </Layout>
  );
};
