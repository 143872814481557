// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FeedbackPage_survey__jCNF9 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "SFProDisplay", sans-serif;
}
.FeedbackPage_survey__layout__0J5k3 {
  width: 100%;
}
.FeedbackPage_survey__form__wVWUX {
  margin-top: 32px;
  padding: 0 204px;
}`, "",{"version":3,"sources":["webpack://./src/pages/survey/FeedbackPage/FeedbackPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uCAAA;AACF;AACE;EACE,WAAA;AACJ;AAGE;EACE,gBAAA;EACA,gBAAA;AADJ","sourcesContent":[".survey {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  font-family: 'SFProDisplay', sans-serif;\n\n  &__layout {\n    width: 100%;\n  }\n\n\n  &__form {\n    margin-top: 32px;\n    padding: 0 204px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"survey": `FeedbackPage_survey__jCNF9`,
	"survey__layout": `FeedbackPage_survey__layout__0J5k3`,
	"survey__form": `FeedbackPage_survey__form__wVWUX`
};
export default ___CSS_LOADER_EXPORT___;
