//  ui
import { IconTableError } from './IconTableError';

import styles from './Error.module.scss';

type ErrorTypes = {
  title?: string;
  text?: string;
};

export const Error = ({
  title = 'Ошибка загрузки данных',
  text = 'Мы не смогли загрузить данные таблицы. Попробуйте перезагрузить страницу.',
}: ErrorTypes): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <div>
        <IconTableError />
      </div>
      <h3>{title}</h3>
      <p>{text}</p>
    </div>
  );
};
