import { IconTableEmpty } from '../../../../ui/Table/Empty/IconTableEmpty';
import styles from './ComparisonContentEmpty.module.scss';

export const ComparisonContentEmpty = () => {
  return (
    <div className={styles.wrapper}>
      <IconTableEmpty />
      <h2 className={styles.title}>Данных пока нет</h2>
      <p className={styles.subTitle}>
        Добавьте исследования, чтобы сравнить их
      </p>
    </div>
  );
};
