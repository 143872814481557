import { useNavigate } from 'react-router-dom';

//  ui
import { Layout } from '../../../components/Layout';
import { AccessesRoles } from '../../../components/pages/settingsEvent/AccessesRoles/AccessesRoles';

export const AccessesRolesPage = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <AccessesRoles />
    </Layout>
  );
};
