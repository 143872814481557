// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rating_rating__cUlyR {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/Rating/Rating.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;AACF","sourcesContent":[".rating {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rating": `Rating_rating__cUlyR`
};
export default ___CSS_LOADER_EXPORT___;
