import { useEffect, useState } from 'react';
import { Form, FormikContext, useFormik } from 'formik';
import {
  useAddMemberResearchMutation,
  useGetRequestInviteQuery,
  useSendInviteMutation,
} from '../../../../../store/VbudusheeAPI';
import { researchRespondentsSchema } from '../../../../../models/validations/schemas/research';

//  ui
import { Box } from '../../../../ui/Box';
import { Button } from '../../../../ui/Button';
import { Typography } from '../../../../ui/Typography';
import { ContentModal } from '../../../../ui/ContentModal';
import { InputText } from '../../../../ui/Form/InputText';
import { ModalError } from '../../../../ui/ModalError';

import { ResearchEditRespondentsItem } from './ResearchEditRespondentsItem';

type ResearchEditRespondentsTypes = {
  researchId: string;
  respondents: any[];
  clickedCopy?: () => void;
  isTheAuthor?: boolean;
  refetch?: () => void;
};

export const ResearchEditRespondents = ({
  researchId,
  respondents,
  clickedCopy,
  refetch,
}: ResearchEditRespondentsTypes) => {
  const API_URL = process.env.REACT_APP_API_URL?.slice(0, -3);
  const [data, setData] = useState<any[]>(respondents || []);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [modalError, setModalError] = useState<string>('');

  const { data: invite } = useGetRequestInviteQuery({
    id: researchId,
    type: 'respondent',
  });

  const [
    addRespondentResearch,
    { isLoading: isAddRespondentLoading, error: isAddRespondentError },
  ] = useAddMemberResearchMutation();

  // const [sendInvite] = VbudusheeAPI.endpoints.sendInvite.useLazyQuery();useSendInviteMutation

  const [
    sendInvite,
    { isLoading: isSendInviteLoading, error: isSendInviteError },
  ] = useSendInviteMutation();

  const addRespondentResearchHandler = async () => {
    const result = await addRespondentResearch({
      research_id: researchId,
      body: {
        email: formik.values.login,
        role: 'respondent',
      },
    });

    await sendInvite({
      researchId: researchId,
      body: { email: formik.values.login },
    });

    if ('data' in result) {
      formik.resetForm();
    }
  };

  const submitHandler = async () => {
    setIsOpen(false);
    if (
      data.filter((el) => el.respondent_email === formik.values.login)
        .length === 0
    ) {
      await addRespondentResearchHandler();

      if (refetch) {
        refetch();
      }
    } else {
      setModalError('This email is invited');
      formik.resetForm();
    }
  };

  const formik = useFormik({
    initialValues: {
      login: '',
    },
    validationSchema: researchRespondentsSchema,
    onSubmit: submitHandler,
  });

  const teamInviteHandler = () => {
    if (invite?.invite) {
      const el = document.createElement('input');
      el.value = `${API_URL}survey/${invite.invite}`;
      // el.value = `http://localhost:3000/research/invite/respondents/${invite.invite}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (clickedCopy) {
        clickedCopy();
      }
    }
  };

  useEffect(() => {
    setData(respondents);
  }, [respondents]);

  useEffect(() => {
    if (isAddRespondentError) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setModalError(isAddRespondentError.data.message);
    }
  }, [isAddRespondentError]);

  return (
    <div>
      <div>
        <Typography color="gray" size="s" customLineHeight={38}>
          Электронная почта
        </Typography>
        <div className="divider-0"></div>
        {data && data.length > 0 ? (
          data.map((r) => (
            <ResearchEditRespondentsItem
              key={r.respondent_id}
              item={r}
              researchId={researchId}
              refetch={refetch}
            />
          ))
        ) : (
          <div>
            <Typography color="main" size="m" vMargin={20} align="center">
              Нет данных
            </Typography>
          </div>
        )}
      </div>
      <Box flex mt={40}>
        <Button
          label="Копировать ссылку-приглашение"
          color="gradient"
          display="empty"
          disabled={!invite}
          clicked={teamInviteHandler}
        />
        <Box mr={16} />
        <Button
          label="Добавить аккаунты респондентов"
          color="gradient"
          clicked={() => setIsOpen(true)}
          // disabled={!isTheAuthor}
        />
      </Box>
      <ContentModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        titleText="Добавить аккаунты респондентов"
      >
        <>
          <FormikContext.Provider value={formik}>
            <Form autoComplete="off">
              <div>
                <InputText name="login" placeholder="Поиск" fullWidth />
                <Box mt={24} mb={24}></Box>
                {/* TODO: replace with formik array */}
                {data && data.length > 0 ? (
                  data.map((el) => {
                    return <p key={el.respondent_id}>{el.respondent_email}</p>;
                  })
                ) : (
                  <div>
                    <Typography
                      color="main"
                      size="m"
                      vMargin={20}
                      align="center"
                    >
                      Нет данных
                    </Typography>
                  </div>
                )}
              </div>
            </Form>
          </FormikContext.Provider>
          <div className="divider"></div>
          <Box flex>
            <Button
              label="Сохранить"
              gradient={true}
              disabled={!formik.isValid || !formik.dirty}
              clicked={submitHandler}
            />
            <Box mr={16} />
            <Button
              label="Отмена"
              withoutBackground
              clicked={() => {
                setIsOpen(false);
              }}
            />
          </Box>
        </>
      </ContentModal>

      <ModalError
        isOpen={!!modalError}
        errorText={modalError}
        onClose={() => {
          setModalError('');
        }}
      />
    </div>
  );
};
