import { useState } from 'react';
import { Participant } from '../../../models/pages/teams';
import { useDeleteParticipantMutation } from '../../../store/VbudusheeAPI';
import styles from './ParticipantCard.module.scss';
import crossPic from '../../../assets/images/Cross.svg';

export const ParticipantCard = (
  participants: Participant,
  team_id: string
): JSX.Element => {
  const currentUrl = window.location.pathname;
  const [participant, setParticipant] = useState<Participant>(participants);
  const isModerator = participants.pivot?.role === 'moderator';

  const [deleteParticipant] = useDeleteParticipantMutation();

  const deleteParticipantHandler = async () => {
    const result = await deleteParticipant({
      team_id: participants.team_id || '',
      body: {
        email: participants.email,
        role: '',
      },
    });
  };

  return (
    <div>
      <div className={styles.card_wraper__email}>{participant.email}</div>
      <div className={styles.card_wraper__name}>{`${participant.last_name} ${
        participant.first_name
      } ${participant.middle_name ?? ''}`}</div>
      {!currentUrl.includes('/team/participants') && (
        <>
          {isModerator ? (
            <>
              <div className={styles.card_wraper__moderator_delete}>
                <p>Администратор</p>
                <img
                  className={styles.card_wraper__cross}
                  src={crossPic}
                  alt="cross pic"
                  onClick={deleteParticipantHandler}
                />
              </div>
            </>
          ) : (
            <a
              className={styles.card_wraper__link}
              onClick={deleteParticipantHandler}
            >
              Удалить
            </a>
          )}
        </>
      )}
    </div>
  );
};
