import { useField } from 'formik';
import React, { useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './InputPassword.module.scss';
import { IconEye } from '../../Icons/Form/IconEye';
const cx = classNames.bind(styles);

type InputPasswordTypes = {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: () => void;
};

export const InputPassword = ({
  name,
  placeholder,
  disabled,
}: InputPasswordTypes) => {
  const [isVisible, setVisible] = useState(false);
  const [field, { error, touched }] = useField<string>(name);

  const isInvalid = useMemo(() => {
    return Boolean(error) && touched;
  }, [error, touched]);

  const fieldType = useMemo(
    () => (isVisible ? 'text' : 'password'),
    [isVisible]
  );

  return (
    <div className={cx('input', { input_invalid: isInvalid })}>
      <input
        {...field}
        type={fieldType}
        placeholder={placeholder}
        disabled={disabled}
      />
      {field.value && (
        <div
          className={cx('input__eye', {
            input__eye_active: isVisible,
          })}
          onClick={() => setVisible(!isVisible)}
        >
          <IconEye />
        </div>
      )}
      {isInvalid && <div className={cx('input__error')}>{error}</div>}
    </div>
  );
};
