export const lightenHexColor = (hexColor: string, amount: number) => {
  hexColor = hexColor.replace('#', '');

  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);

  const lighterR = Math.min(255, r + amount);
  const lighterG = Math.min(255, g + amount);
  const lighterB = Math.min(255, b + amount);

  const lighterHex = `#${lighterR.toString(16).padStart(2, '0')}${lighterG
    .toString(16)
    .padStart(2, '0')}${lighterB.toString(16).padStart(2, '0')}`;

  return lighterHex;
};
