import { createApi } from '@reduxjs/toolkit/query/react';

import { vbudusheeBaseQueryWithReAuth } from '../utils/api';

import {
  AuthorizationRequest,
  AuthorizationResponse,
  ForgotPassAcceptRequest,
  ForgotPassRequest,
  CurrentUser,
} from '../models/user/auth';
import {
  GetKladrByRegionRequest,
  GetKladrByRegionResponse,
  RegistrationCompanyRequest,
  RegistrationCompanyResponse,
  RegistrationRequest,
  RegistrationRespondentRequest,
  RegistrationResponse,
} from '../models/user/registration';
import {
  addModeratorParticipantRequest,
  addModeratorParticipantResponse,
  addMemberParticipantRequest,
  addMemberParticipantResponse,
  CreateTeamResponse,
  DeleteParticipantRequest,
  DeleteParticipantResponse,
  DeleteTeamRequest,
  DeleteTeamResponse,
  EditTeamResponse,
  GetTeamRequest,
  GetTeamResponse,
  GetTeamsRequest,
  GetTeamsResponse,
  Invite,
  GetTeamInviteRequest,
  GetTeamInviteResponse,
} from '../models/pages/teams';
import {
  AddUserRole,
  CreateEventsRequest,
  CreateEventsResponse,
  EventDocumentsResponse,
  GetEventDocumentsRequest,
  GetEventsRequest,
  GetEventsResponse,
  GetExpertResponse,
  GetPublicEventRequest,
  GetPublicEventResponse,
  SemanticStructure,
} from '../models/pages/events';
import {
  ChangeEmailRequest,
  ResetPasswordRequest,
  ValidatePasswordRequest,
} from '../models/user/change-password';
import {
  DocumentResponse,
  GetDocumentByIdRequest,
  IsExpertResponse,
  MarkupResponse,
  MyDocumentsParams,
  MyDocumentsResponse,
  UploadDocumentRequest,
  UploadDocumentResponse,
} from '../models/document';
import {
  GetResearchesRequest,
  GetResearchesResponse,
  ResearchDetails,
  addResearchRequest,
  GetQuestionnairesDictResponse,
  GetMethodsDictResponse,
  EditResearchResponse,
  EditResearchRequest,
  requestForPublicationResponse,
  addMemberResearchResponse,
  addMemberResearchRequest,
  deleteMemberResearchResponse,
  deleteMemberResearchRequest,
  addTeamResearchRequest,
  addTeamResearchResponse,
  deleteTeamResearchRequest,
  deleteTeamResearchResponse,
  deleteResearchResponse,
  deleteResearchRequest,
  getResearchRequest,
  DictionaryResearch,
  ResearchSurvey,
  editResearchSurvey,
  addCustomQuestionResponse,
  addCustomQuestionRequest,
  GetQuestionnairesResponse,
  deleteResearchQuestionnairesRequest,
  deleteResearchQuestionnairesResponse,
  GetDictionaryResponse,
  Region,
  ResearchFilterResponse,
  ResearchFilterRequest,
  ResearchStatsResponse,
  ResearchDetailsProgressResponse,
  ComparisonResearchResponse,
  CommonResponse,
  ComparisonResearchRequest,
  Research,
  ResearchData,
} from '../models/research';
import {
  GetInteractiveMapRequest,
  GetInteractiveMapResponse,
} from '../models/interactiveMap';
import { DocumentTag, Tag } from '../models/tags/tag';
import { ResponseMethods } from '../models/survey/survey';
import { setUploadProgress } from './documentSlice';
import { uploadWithProgress } from '../utils/files';
import { FeedbackResponse } from '../models/feedback';
import { showToast } from './toast';

export const VbudusheeAPI = createApi({
  reducerPath: 'VBUDUSHEE_REDUCER',
  baseQuery: vbudusheeBaseQueryWithReAuth,
  tagTypes: [
    'Users',
    'User',
    'Teams',
    'Team',
    'TeamInvite',
    'Events',
    'EventsInvite',
    'Event',
    'Document',
    'EventsDocuments',
    'MarkedUpDocument',
    'ApproveExpertDocument',
    'Tags',
    'Methods',
    'Researches',
    'Research',
    'Participants',
    'Education',
    'ResearchSurvey',
    'Questions',
    'Quizzes',
    'Quiz',
    'Expert',
  ],
  keepUnusedDataFor: 30,
  endpoints: (build) => ({
    //  Auth
    login: build.mutation<AuthorizationResponse, AuthorizationRequest>({
      query: (credentials) => ({
        url: '/login',
        method: 'post',
        body: credentials,
      }),
    }),

    registration: build.mutation<RegistrationResponse, RegistrationRequest>({
      query: (credentials) => ({
        url: '/register',
        method: 'post',
        body: credentials,
      }),
    }),

    registrationCompany: build.mutation<
      RegistrationCompanyResponse,
      RegistrationCompanyRequest
    >({
      query: (credentials) => ({
        url: '/register',
        method: 'post',
        body: credentials,
      }),
    }),

    registrationRespondent: build.mutation<
      RegistrationResponse,
      RegistrationRespondentRequest
    >({
      query: (credentials) => ({
        url: '/register-respondent',
        method: 'post',
        body: credentials,
      }),
    }),

    checkEmailRegistration: build.mutation<any, any>({
      query: (credentials) => ({
        url: '/validate-email',
        method: 'post',
        body: credentials,
      }),
    }),

    sendVerifyEmail: build.mutation<any, any>({
      query: () => ({
        url: '/send-verify-email',
        method: 'post',
      }),
    }),

    forgotPass: build.mutation<unknown, ForgotPassRequest>({
      query: (params) => ({
        url: '/forgot',
        method: 'post',
        body: params,
      }),
    }),

    forgotPassAccept: build.mutation<unknown, ForgotPassAcceptRequest>({
      query: (params) => ({
        url: '/forgot/new-password',
        method: 'post',
        body: params,
      }),
    }),

    VerifyEmail: build.mutation<any, any>({
      query: (params) => ({
        url: '/verify-email',
        method: 'post',
        body: params,
      }),
    }),

    getVerifyEmail: build.query<
      unknown,
      { id: string | undefined; params: any }
    >({
      query: ({ id, params }) => ({
        url: `/verify-email/${id}/${params}`,
        method: 'GET',
      }),
      // providesTags: ['User'],
    }),

    getKladrByRegion: build.mutation<
      GetKladrByRegionResponse,
      GetKladrByRegionRequest
    >({
      query: (params) => ({
        url: '/get-kladr-by-region',
        method: 'post',
        body: params,
      }),
    }),

    //  User
    getCurrentUser: build.query<CurrentUser, unknown>({
      query: () => ({
        url: '/user',
      }),
      providesTags: ['User'],
      keepUnusedDataFor: 0,
    }),

    logOut: build.mutation<unknown, unknown>({
      query: () => ({
        url: '/',
      }),
      invalidatesTags: ['User'],
    }),

    userProfileUpdate: build.mutation<unknown, unknown>({
      query: (credentials) => ({
        url: '/user/edit',
        method: 'post',
        body: credentials,
      }),
      invalidatesTags: ['User'],
    }),

    userValidatePassword: build.mutation<ValidatePasswordRequest, unknown>({
      query: (credentials) => ({
        url: '/validate-password',
        method: 'post',
        body: credentials,
      }),
    }),

    userResetPassword: build.mutation<ResetPasswordRequest, unknown>({
      query: (credentials) => ({
        url: '/reset-password',
        method: 'post',
        body: credentials,
      }),
    }),

    userChangeEmail: build.mutation<ChangeEmailRequest, unknown>({
      query: (credentials) => ({
        url: 'user/change-email',
        method: 'post',
        body: credentials,
      }),
    }),

    // Teams
    getTeams: build.query<GetTeamsResponse, GetTeamsRequest>({
      query: (params) => ({ url: '/teams', params }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ team_id }) => ({
                type: 'Teams' as const,
                team_id,
              })),
              { type: 'Teams', id: 'LIST' },
            ]
          : [{ type: 'Teams', id: 'LIST' }],
      keepUnusedDataFor: 0,
    }),

    getTeam: build.query<GetTeamResponse, GetTeamRequest>({
      query: ({ id }) => ({ url: `/teams/${id}` }),
      providesTags: (result, id) =>
        result ? [{ type: 'Teams' as const, id: result.data.team_id }] : [],
      keepUnusedDataFor: 0,
    }),

    getMembersByEvent: build.query<any, GetTeamRequest>({
      query: ({ id }) => ({ url: `/events/${id}/members` }),
      providesTags: (result, id) =>
        result ? [{ type: 'Teams' as const, id: result.data.team_id }] : [],
      keepUnusedDataFor: 0,
    }),

    createTeam: build.mutation<CreateTeamResponse, FormData>({
      query: (data) => ({
        url: '/teams',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Teams', id: 'LIST' }],
    }),

    changeTeamAccess: build.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/events/${id}/team_access`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Expert' }],
    }),

    editTeam: build.mutation<EditTeamResponse, { id: string; data: FormData }>({
      query: ({ id, data }) => ({
        url: `/teams/${id}?_method=PUT`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Teams'],
    }),

    deleteTeam: build.mutation<DeleteTeamResponse, DeleteTeamRequest>({
      query: ({ id }) => ({
        url: `/teams/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Teams', id: 'LIST' }],
    }),

    getTeamInvite: build.query<GetTeamInviteResponse, GetTeamInviteRequest>({
      query: ({ team_id }) => ({ url: `/teams/invite`, params: { team_id } }),
    }),

    deleteParticipant: build.mutation<
      DeleteParticipantResponse,
      { team_id: string; body: DeleteParticipantRequest }
    >({
      query: ({ team_id, body }) => ({
        url: `/teams/${team_id}/role`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Teams'],
    }),

    addMemberParticipant: build.mutation<
      addMemberParticipantResponse,
      { team_id: string; body: addMemberParticipantRequest }
    >({
      query: ({ team_id, body }) => ({
        url: `/teams/${team_id}/role`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Teams'],
    }),

    addModeratorParticipant: build.mutation<
      addModeratorParticipantResponse,
      { team_id: string; body: addModeratorParticipantRequest }
    >({
      query: ({ team_id, body }) => ({
        url: `/teams/${team_id}/role`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Teams'],
    }),

    getMyFeedback: build.query<FeedbackResponse, any>({
      query: (params) => ({
        url: '/feedback',
        params,
      }),
    }),

    //  Events
    getEvents: build.query<GetEventsResponse, GetEventsRequest>({
      query: (params) => {
        const { event_tags, isMyEvents, ...otherParams } = params;

        const param = 'event_tags[]=';
        const tagParam = event_tags
          ? `?${param}` + event_tags?.join(`&${param}`)
          : '';

        return {
          url: `/events${isMyEvents ? '/my' : ''}${tagParam}`,
          params: otherParams,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ event_id }) => ({
                type: 'Events' as const,
                event_id,
              })),
            ]
          : ['Events'],
      keepUnusedDataFor: 0,
    }),

    updateEventEstimate: build.mutation({
      query: ({ eventId, data }) => ({
        url: `/events/${eventId}/estimates`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['EventsDocuments'],
    }),

    getMediaFiles: build.query<any, any>({
      query: (id) => ({
        url: `/events/${id}/mediafiles`,
        method: 'GET',
      }),
    }),
    getMediaFile: build.query<any, any>({
      query: (id) => ({
        url: `/mediafiles/${id}`,
        method: 'GET',
      }),
    }),

    addMediafiles: build.mutation<CreateEventsResponse, FormData>({
      query: (formData) => ({
        url: `/mediafiles`,
        method: 'POST',
        body: formData,
        formData: true,
      }),
      transformResponse: (response: { data: CreateEventsResponse }) =>
        response.data,
      invalidatesTags: ['Document'],
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(
            showToast({
              type: 'success',
              name: 'success_create_event',
              title: 'Успех',
              position: 'bottom-right',
              description: 'Файлы успешно загружены',
              timer: 3000,
            })
          );
        } catch (error) {
          dispatch(
            showToast({
              type: 'danger',
              name: 'error_create_event',
              position: 'bottom-right',
              title: 'Ошибка',
              description: `При загрузке файлов, что-то пошло не так`,
              timer: 3000,
            })
          );
        }
      },
    }),

    createEvents: build.mutation<CreateEventsResponse, CreateEventsRequest>({
      query: ({ data }) => ({
        url: `/events`,
        method: 'POST',
        body: data,
      }),
      transformResponse: (response: { data: CreateEventsResponse }) =>
        response.data,
      invalidatesTags: ['Event'],
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
          dispatch(
            showToast({
              type: 'success',
              name: 'success_create_event',
              title: 'Успех',
              position: 'bottom-right',
              description: 'Мероприятие успешно создано',
              timer: 3000,
            })
          );
        } catch (error) {
          dispatch(
            showToast({
              type: 'danger',
              name: 'error_create_event',
              position: 'bottom-right',
              title: 'Ошибка',
              description: `При создании мероприятия, что-то пошло не так`,
              timer: 3000,
            })
          );
        }
      },
    }),

    deleteEvent: build.mutation<unknown, unknown>({
      query: ({ id }) => ({
        url: `/events/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Event'],
    }),
    //TODO fix request after fixed bugs on backend
    // edit settings event
    editingSettingsEvent: build.mutation<
      CreateEventsResponse,
      { id: number; data: unknown }
    >({
      query: ({ id, data }) => ({
        url: `/events/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Event'],
    }),
    // Added role for user
    editRoleEvent: build.mutation<
      unknown,
      { event_id: string; data: AddUserRole }
    >({
      query: ({ event_id, data }) => ({
        url: `/events/${event_id}/user_role`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Event', 'Expert'],
    }),

    changeEventOwner: build.mutation<unknown, { id: string; data: any }>({
      query: ({ id, data }) => ({
        url: `/events/${id}/owner`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Event'],
    }),

    getEvent: build.query<GetPublicEventResponse, GetPublicEventRequest>({
      query: ({ id }) => ({ url: `/events/${id}` }),
      providesTags: (result) =>
        result ? [{ type: 'Event' as const, id: result.data.event_id }] : [],
    }),

    getEventsInvite: build.query<Invite, GetTeamRequest>({
      query: ({ id }) => ({
        url: `/events/${id}/invite`,
      }),
      providesTags: (result: any) => [
        { type: 'EventsInvite' as const, id: result },
      ],
      keepUnusedDataFor: 0,
    }),

    createEventsInvite: build.mutation<Invite, any>({
      query: ({ id }) => ({
        url: `/events/${id}/invite`,
        method: 'POST',
      }),
      invalidatesTags: ['EventsInvite'],
    }),

    deactivateEventsInvite: build.mutation<any, any>({
      query: ({ invite }) => ({
        url: `/events/invite/deactivate?invite=${invite}`,
        method: 'POST',
      }),
      invalidatesTags: ['EventsInvite'],
    }),

    activateEventInvite: build.mutation<any, any>({
      query: ({ invite }) => ({
        url: `/events/invite/activate`,
        method: 'POST',
        body: {
          invite,
        },
      }),
      invalidatesTags: ['EventsInvite'],
    }),

    getSemanticStruct: build.query<SemanticStructure, { id: string }>({
      query: ({ id }) => ({
        url: `/events/${id}/semantic-struct`,
      }),
    }),

    publishEvent: build.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `/events/${id}/publish`,
        method: 'POST',
      }),
    }),

    getEventDocuments: build.query<
      EventDocumentsResponse,
      GetEventDocumentsRequest
    >({
      query: ({ id, params }) => ({
        url: `/events/${id}/documents`,
        params: params,
      }),
      providesTags: ['EventsDocuments'],
    }),

    getExpertDocuments: build.query<GetExpertResponse, { id: number | string }>(
      {
        query: ({ id }) => ({
          url: `/events/${id}/members`,
        }),
        providesTags: ['Expert'],
      }
    ),

    getEventDocumentsExpert: build.query<
      EventDocumentsResponse,
      GetEventDocumentsRequest
    >({
      query: ({ id, params }) => ({
        url: `/events/${id}/documents/expert`,
        params: params,
      }),
      providesTags: ['ApproveExpertDocument'],
    }),

    // Tags
    getTags: build.query<Tag[], unknown>({
      query: () => ({
        url: '/tags',
      }),
    }),

    //  Documents
    uploadDocument: build.mutation<DocumentResponse, FormData>({
      query: (body) => {
        return {
          url: '/documents',
          method: 'post',
          body,
        };
      },
      invalidatesTags: ['Document'],
    }),

    uploadDocumentWithProgress: build.mutation<DocumentResponse, FormData>({
      // couldn't find better approach
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      queryFn: async (formData, api) => {
        try {
          const data = await uploadWithProgress<DocumentResponse>(
            '/documents',
            formData,
            (percentage) => {
              api.dispatch(setUploadProgress(percentage));
            }
          );
          return { data };
        } catch (error) {
          return {
            error: {
              error,
            },
          };
        }
      },
      invalidatesTags: ['Document'],
    }),

    getDocument: build.query<DocumentResponse, GetDocumentByIdRequest>({
      query: ({ id }) => ({
        url: `/documents/${id}`,
      }),
      providesTags: ['Document'],
      keepUnusedDataFor: 0,
    }),

    getDocumentMarkup: build.query<MarkupResponse, { id: number | string }>({
      query: ({ id }) => ({
        url: `/documents/${id}/markup`,
      }),
      providesTags: ['Document'],
      keepUnusedDataFor: 0,
    }),

    getDocumentOriginal: build.query<unknown, { id: number | string }>({
      query: ({ id }) => ({
        url: `/documents/${id}/original`,
      }),
      providesTags: ['Document'],
      keepUnusedDataFor: 0,
    }),

    getMyDocuments: build.query<MyDocumentsResponse, MyDocumentsParams>({
      query: (params) => {
        const { document_tags, ...otherParams } = params;

        const param = 'document_theme=';
        const tagParam = document_tags
          ? `?${param}` + document_tags?.join(`&${param}`)
          : '';

        return {
          url: `/documents/my${tagParam}`,
          params: otherParams,
        };
      },
    }),

    addExpertDocument: build.mutation<unknown, IsExpertResponse>({
      query: ({ id, body }) => {
        return {
          url: `/documents/${id}/expert`,
          method: 'post',
          body,
        };
      },
      invalidatesTags: ['ApproveExpertDocument', 'Document', 'Expert'],
    }),

    // Upload marked up document
    uploadMarkedUpDocument: build.mutation<
      UploadDocumentResponse,
      UploadDocumentRequest
    >({
      query: ({ id, body }) => {
        return {
          url: `/documents/${id}`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['MarkedUpDocument'],
    }),

    //approve expert document
    approveExpertDocument: build.mutation<unknown, unknown>({
      query: ({ id, body }) => {
        return {
          url: `/documents/${id}/check`,
          method: 'post',
          body,
        };
      },
      invalidatesTags: ['ApproveExpertDocument', 'Document'],
    }),

    approveExpertDocumentStatus: build.mutation<unknown, unknown>({
      query: ({ id, body }) => {
        return {
          url: `/documents/${id}/status`,
          method: 'put',
          body,
        };
      },
      invalidatesTags: ['ApproveExpertDocument', 'Document'],
    }),

    //  Methods
    getMethods: build.query<ResponseMethods, unknown>({
      query: ({ id }) => ({
        url: `research/methods${id}`,
      }),
    }),

    getResearchSurvey: build.query<{ data: ResearchSurvey }, unknown>({
      query: ({ researchId }) => ({
        url: `research/${researchId}/quiz`,
      }),
      providesTags: ['ResearchSurvey'],
      keepUnusedDataFor: 0,
    }),

    // Document-tags
    getDocumentTags: build.query<DocumentTag[], unknown>({
      query: () => ({
        url: '/document-tags',
      }),
    }),

    //  Research
    getResearches: build.query<GetResearchesResponse, GetResearchesRequest>({
      query: (params) => {
        const { research_region, ...otherParams } = params;

        const param = 'research_region[]=';
        const regionParam = research_region
          ? `?${param}` + research_region?.join(`&${param}`)
          : '';

        return {
          url: `/research${regionParam}`,
          params: otherParams,
        };
      },
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(({ research_id }) => ({
                type: 'Research' as const,
                research_id,
              })),
              'Researches',
            ]
          : ['Researches'],
      keepUnusedDataFor: 0,
    }),

    getResearchInfo: build.query<ResearchDetails, unknown>({
      query: ({ researchId }) => ({
        url: `/research/${researchId}/show`,
      }),
      providesTags: (result) =>
        result
          ? [{ type: 'Research' as const, researchId: result.data.id }]
          : ['Researches'],
      keepUnusedDataFor: 0,
    }),

    getResearchId: build.query<ResearchDetails, unknown>({
      query: ({ researchId }) => ({
        url: `/research/${researchId}`,
      }),
      providesTags: (result) =>
        result
          ? [{ type: 'Research' as const, data: result.data.id }]
          : ['Researches'],
      keepUnusedDataFor: 0,
    }),

    getResearchEditId: build.query<ResearchDetails, unknown>({
      query: ({ researchId }) => ({
        url: `/research/${researchId}/edit`,
      }),
      providesTags: (result) =>
        result
          ? [{ type: 'Research' as const, data: result.data.id }]
          : ['Researches'],
      keepUnusedDataFor: 0,
    }),

    getResearchDetails: build.mutation<ResearchStatsResponse, unknown>({
      query: ({ researchId, body, questionId }) => ({
        url: `research/${researchId}/stats`,
        method: 'POST',
        body: body,
        params: { question_id: questionId },
      }),
    }),

    getResearchFilters: build.mutation<
      ResearchFilterResponse,
      ResearchFilterRequest
    >({
      query: ({ researchId, body }) => ({
        url: `research/${researchId}/filter`,
        method: 'POST',
        body: body,
      }),
    }),

    getQuizIdToInvite: build.query<GetQuestionnairesResponse, unknown>({
      query: ({ invite }) => ({
        url: `/quiz/load?invite=${invite}`,
      }),
      providesTags: ['Quiz'],
      keepUnusedDataFor: 0,
    }),

    getQuizFeedback: build.query<any, { answerId: string }>({
      query: ({ answerId }) => ({
        url: `feedback/${answerId}`,
      }),
      keepUnusedDataFor: 0,
    }),

    getQuizFeedbackFoEmail: build.mutation<
      unknown,
      { answerId: string; email: string }
    >({
      query: ({ answerId, email }) => ({
        url: `feedback/${answerId}/send`,
        method: 'POST',
        body: { email: email },
      }),
    }),

    getResearchProgress: build.mutation<
      ResearchDetailsProgressResponse,
      {
        id: string;
        body?: {
          organization_inn?: string;
          organization_region_number?: string;
        };
      }
    >({
      query: ({ id, body }) => ({
        url: `/research/${id}/progress`,
        method: 'POST',
        body: body,
      }),
    }),

    sendInvite: build.mutation<unknown, { researchId: string; body: unknown }>({
      query: ({ researchId, body }) => ({
        url: `/research/${researchId}/invite/send/respondent`,
        method: 'POST',
        body: body,
      }),
    }),

    deleteInviteUrl: build.mutation<unknown, unknown>({
      query: ({ researchId, type }) => ({
        url: `/research/${researchId}/invite/deactivate/${type}`,
        method: 'DELETE',
      }),
    }),

    getQuizId: build.query<GetQuestionnairesResponse, unknown>({
      query: ({ researchId }) => ({
        url: `/research/${researchId}/quiz/load`,
      }),
      providesTags: ['Quiz'],
      keepUnusedDataFor: 0,
    }),

    getPdf: build.query<
      {
        result: string;
      },
      unknown
    >({
      query: ({ researchId }) => ({
        url: `/research/${researchId}/quiz/pdf`,
      }),
      providesTags: ['Quiz'],
      keepUnusedDataFor: 0,
    }),

    addResearch: build.mutation<{ research: ResearchData }, addResearchRequest>(
      {
        query: (body) => ({
          url: `/research`,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['Research'],
      }
    ),

    addResearchVersus: build.mutation<
      ComparisonResearchResponse,
      ComparisonResearchRequest
    >({
      query: (body) => ({
        url: `/versus`,
        method: 'POST',
        body,
      }),
    }),

    getResearchVersusCommon: build.mutation<
      CommonResponse,
      { researches: number[] }
    >({
      query: (body) => ({
        url: `/versus/common`,
        method: 'POST',
        body,
      }),
    }),

    addResearchVersusFilter: build.mutation<
      { research: `any` },
      ComparisonResearchResponse
    >({
      query: (body) => ({
        url: `/versus/filter`,
        method: 'POST',
        body,
      }),
    }),

    confirmQuiz: build.mutation<any, any>({
      query: (body) => ({
        url: `/quiz/confirm`,
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            showToast({
              type: 'danger',
              name: 'error_confirm_quiz',
              title: 'Ошибка',
              description:
                'Что-то пошло не так. Пожалуйста, проверьте Интернет-соединение и повторно ответьте на вопрос. В противном случае данные Вами ответы сохранятся некорректно.',
              timer: 4000,
            })
          );
        }
      },
    }),

    transferUser: build.mutation<
      { research: any },
      {
        research_id: string;
        body: {
          new_owner_email: string;
        };
      }
    >({
      query: ({ research_id, body }) => ({
        url: `/research/${research_id}/owner`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Research'],
    }),

    requestForPublication: build.mutation<
      requestForPublicationResponse,
      { researchId: string }
    >({
      query: ({ researchId }) => ({
        url: `/research/${researchId}/publish`,
        method: 'POST',
      }),
      invalidatesTags: ['Research'],
    }),

    getDictionaryEducation: build.query<DictionaryResearch, unknown>({
      query: () => ({
        url: `/dictionary/education`,
      }),
      providesTags: ['Education'],
      keepUnusedDataFor: 0,
    }),

    getDictionary: build.query<GetDictionaryResponse, unknown>({
      query: () => ({
        url: `/dictionary`,
      }),
      keepUnusedDataFor: 0,
    }),

    getStartResearch: build.query<
      requestForPublicationResponse,
      { researchId: string }
    >({
      query: ({ researchId }) => ({
        url: `/research/${researchId}/start`,
      }),
      providesTags: (result) =>
        result
          ? [{ type: 'Research' as const, data: result.result }]
          : ['Researches'],
      keepUnusedDataFor: 0,
    }),

    getStopResearch: build.query<
      requestForPublicationResponse,
      { researchId: string }
    >({
      query: ({ researchId }) => ({
        url: `/research/${researchId}/stop`,
      }),
      providesTags: (result) =>
        result
          ? [{ type: 'Research' as const, data: result.result }]
          : ['Researches'],
      keepUnusedDataFor: 0,
    }),

    getRequestInvite: build.query<Invite, getResearchRequest>({
      query: ({ id, type }) => ({
        url: `/research/${id}/invite/${type}`,
      }),
      providesTags: (result: any) =>
        result ? [{ type: 'EventsInvite' as const, id: result }] : [],
      keepUnusedDataFor: 0,
    }),

    getRequestActivateInvite: build.query<Invite, unknown>({
      query: ({ token }) => ({
        url: `/research/invite/activate/${token}`,
      }),
      providesTags: (result: any) =>
        result ? [{ type: 'EventsInvite' as const, id: result }] : [],
      keepUnusedDataFor: 0,
    }),

    //  TODO: comment out when endpoint will be fixed
    // getResearchStat: build.mutation<any, any>({
    //   query: (params) => ({
    //     url: '/research/stats-multiple',
    //     method: 'post',
    //     params,
    //   }),
    // }),

    getResearchMethods: build.query<GetMethodsDictResponse, unknown>({
      query: () => ({
        url: `/research/methods`,
      }),
      keepUnusedDataFor: 0,
    }),

    getResearchQuestionnaires: build.query<
      GetQuestionnairesDictResponse,
      unknown
    >({
      query: ({ researchId }) => ({
        url: `research/${researchId}/questionnaires`,
        keepUnusedDataFor: 0,
      }),
      providesTags: ['Questions'],
    }),

    getResearchQuizzes: build.query<
      { data: GetQuestionnairesResponse[] },
      unknown
    >({
      query: () => ({
        url: `/quizzes`,
        keepUnusedDataFor: 0,
      }),
      providesTags: ['Quizzes'],
    }),

    editResearch: build.mutation<EditResearchResponse, EditResearchRequest>({
      query: ({ research_id, ...body }) => ({
        url: `/research/${research_id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Researches', 'Questions', 'ResearchSurvey'],
    }),

    addMemberResearch: build.mutation<
      addMemberResearchResponse,
      { research_id: string; body: addMemberResearchRequest }
    >({
      query: ({ research_id, body }) => ({
        url: `/research/${research_id}/user_role`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Research'],
    }),

    addCustomQuestion: build.mutation<
      { data: addCustomQuestionResponse },
      addCustomQuestionRequest
    >({
      query: (body) => ({
        url: `/research/questionnaires`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Questions'],
    }),

    deleteResearchQuestionnaires: build.mutation<
      deleteResearchQuestionnairesResponse,
      deleteResearchQuestionnairesRequest
    >({
      query: ({ id }) => ({
        url: `/research/questionnaires/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Questions'],
    }),

    deleteMemberResearch: build.mutation<
      deleteMemberResearchResponse,
      { research_id: string; body: deleteMemberResearchRequest }
    >({
      query: ({ research_id, body }) => ({
        url: `/research/${research_id}/user_role`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Research'],
    }),

    addTeamResearch: build.mutation<
      addTeamResearchResponse,
      { research_id: string; body: addTeamResearchRequest }
    >({
      query: ({ research_id, body }) => ({
        url: `/research/${research_id}/team_access`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Research'],
    }),

    deleteTeamResearch: build.mutation<
      deleteTeamResearchResponse,
      { research_id: string; body: deleteTeamResearchRequest }
    >({
      query: ({ research_id, body }) => ({
        url: `/research/${research_id}/team_access`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Research'],
    }),

    deleteResearch: build.mutation<
      deleteResearchResponse,
      deleteResearchRequest
    >({
      query: ({ id, force = false }) => ({
        url: `/research/${id}`,
        method: 'DELETE',
        body: {
          force,
        },
      }),
      invalidatesTags: ['Research'],
    }),

    deleteResearchLongitudinal: build.mutation<
      deleteResearchResponse,
      deleteResearchRequest
    >({
      query: ({ id, force = false }) => ({
        url: `/research/longitudinal/${id}`,
        method: 'DELETE',
        body: {
          force,
        },
      }),
      invalidatesTags: ['Research'],
    }),

    // Research comparison
    getComparisonResearch: build.mutation<ComparisonResearchResponse, unknown>({
      query: (body) => ({
        url: '/versus/filter',
        method: 'POST',
        body,
      }),
    }),

    editSurveyRequest: build.mutation<{ result: string }, editResearchSurvey>({
      query: (body) => ({
        url: `/quizzes`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ResearchSurvey', 'Quizzes'],
    }),

    putSurveyRequest: build.mutation<
      { result: string },
      { id: number; body: editResearchSurvey }
    >({
      query: ({ id, body }) => ({
        url: `/quizzes/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ResearchSurvey', 'Quizzes'],
    }),

    deleteSurveyRequest: build.mutation<
      { result: string },
      deleteResearchRequest
    >({
      query: ({ id }) => ({
        url: `/quizzes/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Quizzes'],
    }),

    getTemplateQuizzes: build.query<{ data: ResearchSurvey }, unknown>({
      query: ({ templateId, params }) => ({
        url: `/quizzes/${templateId}`,
        params,
        keepUnusedDataFor: 0,
      }),
    }),

    // Interactive map
    getInteractiveMapData: build.query<
      GetInteractiveMapResponse,
      GetInteractiveMapRequest
    >({
      query: ({ researchId, params }) => ({
        url: `/research/${researchId}/map`,
        params,
        keepUnusedDataFor: 0,
      }),
    }),
    getRegionData: build.query<Region[], unknown>({
      query: () => ({
        url: `/regions`,
        keepUnusedDataFor: 0,
      }),
    }),
  }),
});

export const {
  //  Auth
  useLoginMutation,
  useLogOutMutation,
  useRegistrationMutation,
  useRegistrationCompanyMutation,
  useRegistrationRespondentMutation,
  useCheckEmailRegistrationMutation,
  useGetKladrByRegionMutation,
  useForgotPassMutation,
  useForgotPassAcceptMutation,
  useGetCurrentUserQuery,
  useGetVerifyEmailQuery,
  useVerifyEmailMutation,
  useSendVerifyEmailMutation,
  // User
  useUserProfileUpdateMutation,
  useUserValidatePasswordMutation,
  useUserResetPasswordMutation,
  useUserChangeEmailMutation,
  // Teams
  useGetTeamsQuery,
  useGetTeamQuery,
  useGetTeamInviteQuery,
  useCreateTeamMutation,
  useEditTeamMutation,
  useDeleteTeamMutation,
  useDeleteParticipantMutation,
  useAddMemberParticipantMutation,
  useAddModeratorParticipantMutation,
  useChangeTeamAccessMutation,
  useGetMembersByEventQuery,

  //  Events
  useGetEventsQuery,
  useGetEventQuery,
  useChangeEventOwnerMutation,
  useGetEventDocumentsQuery,
  useGetMediaFilesQuery,
  useGetEventDocumentsExpertQuery,
  useGetEventsInviteQuery,
  useCreateEventsMutation,
  useDeleteEventMutation,
  useEditingSettingsEventMutation,
  useEditRoleEventMutation,
  useGetSemanticStructQuery,
  useGetExpertDocumentsQuery,
  usePublishEventMutation,
  useCreateEventsInviteMutation,
  useDeactivateEventsInviteMutation,
  useActivateEventInviteMutation,
  //  Tags
  useGetTagsQuery,
  //  Documents
  useUploadDocumentMutation,
  useUploadDocumentWithProgressMutation,
  useGetDocumentQuery,
  useGetDocumentMarkupQuery,
  useGetDocumentOriginalQuery,
  useLazyGetDocumentOriginalQuery,
  useAddExpertDocumentMutation,
  useGetMyDocumentsQuery,
  useUploadMarkedUpDocumentMutation,
  useApproveExpertDocumentMutation,
  useApproveExpertDocumentStatusMutation,
  // Document-tags
  useGetDocumentTagsQuery,
  //  Methods
  useGetMethodsQuery,
  //  Research
  useTransferUserMutation,
  useGetResearchesQuery,
  useGetResearchFiltersMutation,
  useGetResearchInfoQuery,
  useGetResearchIdQuery,
  useGetResearchEditIdQuery,
  useAddResearchMutation,
  useAddCustomQuestionMutation,
  useDeleteInviteUrlMutation,
  useGetComparisonResearchMutation,
  // useGetResearchStatQuery,
  useGetResearchDetailsMutation,
  useGetStartResearchQuery,
  useGetStopResearchQuery,
  useGetRequestInviteQuery,
  useRequestForPublicationMutation,
  useGetResearchMethodsQuery,
  useGetResearchQuestionnairesQuery,
  useEditResearchMutation,
  usePutSurveyRequestMutation,
  useAddMemberResearchMutation,
  useDeleteMemberResearchMutation,
  useAddTeamResearchMutation,
  useDeleteTeamResearchMutation,
  useDeleteResearchMutation,
  useDeleteResearchLongitudinalMutation,
  useDeleteResearchQuestionnairesMutation,
  useGetRequestActivateInviteQuery,
  useSendInviteMutation,
  useAddResearchVersusMutation,
  useAddResearchVersusFilterMutation,
  useGetResearchVersusCommonMutation,
  // Interactive map
  useGetInteractiveMapDataQuery,
  useGetRegionDataQuery,
  //quiz
  useGetQuizFeedbackQuery,
  useGetQuizFeedbackFoEmailMutation,
  useGetTemplateQuizzesQuery,
  useGetPdfQuery,
  useGetQuizIdQuery,
  useConfirmQuizMutation,
  useGetQuizIdToInviteQuery,
  useGetResearchSurveyQuery,
  useEditSurveyRequestMutation,
  useGetResearchProgressMutation,
  useGetResearchQuizzesQuery,
  useDeleteSurveyRequestMutation,
  useUpdateEventEstimateMutation,
  //   Dictionary
  useGetDictionaryQuery,
  useGetDictionaryEducationQuery,
  // My feedback
  useGetMyFeedbackQuery,
  useAddMediafilesMutation,
} = VbudusheeAPI;
