import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { IconCloseRound } from '../../ui/Icons/IconCloseRound';
import { AnalysisResultsContent } from './AnalysisResultsContent';
import { AnalysisResultsMenu } from './AnalysisResultsMenu';
import { AnalysisResultsStatus } from './AnalysisResultsStatus';
import { useGetDocumentQuery } from '../../../store/VbudusheeAPI';
import { DocumentAnalyse } from '../../../models/document';
import { Loader } from '../../ui/Loader';
import { LoaderFullPage } from '../../ui/Loader/LoaderFullPage';
import { frontendRoutes } from '../../../utils/router/routes';
import styles from './AutomaticAnalysisResults.module.scss';

export const AutomaticAnalysisResults = () => {
  const [documentActivePart, setDocumentActivePart] = useState<string>('');
  const { documentId } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useGetDocumentQuery({ id: documentId || '0' });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const documentAnalysis: DocumentAnalyse =
    data?.data?.document_analyse || '{}';

  useEffect(() => {
    setDocumentActivePart(
      Object.keys(documentAnalysis?.blocks_data || {})[0] || ''
    );
  }, [data]);

  const handleDocActivePart = (part: string) => {
    setDocumentActivePart(part);
  };

  if (isLoading) {
    return (
      <LoaderFullPage>
        <Loader />
      </LoaderFullPage>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <h1 className={styles.topTitle}>
          Подробные результаты автоматического анализа
        </h1>
        <span className={styles.topIcon} onClick={() => navigate(-1)}>
          <IconCloseRound />
        </span>
      </div>
      <AnalysisResultsStatus
        status={documentAnalysis?.total?.status === 0 ? 'done' : 'rejected'}
      />
      <div className={styles.menuWrapper}>
        <AnalysisResultsMenu
          availableParts={documentAnalysis.blocks_data || {}}
          handleDocActivePart={handleDocActivePart}
        />
        <AnalysisResultsContent
          documentActivePart={documentActivePart}
          blockData={documentAnalysis?.blocks_data || {}}
        />
      </div>
    </div>
  );
};
